import React from "react";
import { cls } from "../helpers/helper";

const InputUnit = ({
  label,
  holder,
  register,
  errors,
  clsName,
  noLable,
  type,
  iMaxlength,
  ...rest
}) => {
  const id = register.name;
  let placeholder;
  holder
    ? (placeholder = holder)
    : (placeholder = `${id.toString().toUpperCase()}를 입력해 주세요`);

  return (
    <div className="input-unit">
      <input
        id={id}
        type={type ? type : "text"}
        placeholder={placeholder}
        className={cls("input-hook", clsName, errors[id] && "error")}
        {...register}
        {...rest}
        maxLength={iMaxlength}
      />
      {errors[id] && (
        <span className="error-message">{errors[id].message}</span>
      )}
      {id === "user_pw_confirm" &&
        errors[id] &&
        errors[id].type === "required" && <p>비밀번호 확인을 해주세요.</p>}
      {id === "user_pw_confirm" &&
        errors[id] &&
        errors[id].type === "validate" && <p>비밀번호가 일치하지 않습니다.</p>}
    </div>
  );
};

export default InputUnit;
