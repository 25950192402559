import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isAdminState } from "recoil/atoms";
import { userInfoState } from "recoil/userStore";
import UserListPage from "pages/admin/admin/userListPage";
import DTypeReportListPage from "pages/admin/admin/dTypeReportListPage";
import ReportListPage from "pages/admin/admin/reportListPage";
import LoginHistoryListPage from "pages/admin/admin/loginHistoryListPage";
import ReportHistoryListPage from "pages/admin/admin/reportHistoryListPage";
import RequestHistoryListPage from "pages/admin/admin/requestHistoryListPage";
import AdminFaqPage from "pages/admin/admin/adminFaqPage";
import AdminInquiryPage from "pages/admin/admin/adminInquiryPage";
import OrderListPage from "pages/admin/admin/orderListPage";
import AdminItemManagementPage from "pages/admin/admin/adminItemManagementPage";
import ExitUserListPage from "./admin/exitUserListPage";
import ModalListPage from "./admin/modal/modalListPage";
import "assets/css/board.css";
import "assets/css/admin.css";
import "assets/css/newAdmin.css";

const AdminPage = () => {
  const [menuIndex, setMenuIndex] = useRecoilState(isAdminState);
  const userInfo = useRecoilValue(userInfoState);

  const adminList =
    userInfo.user_type === "A" || userInfo.user_type === "I"
      ? [
          { idx: 0, menuName: "회원목록", page: <UserListPage /> },
          { idx: 1, menuName: "리포트목록", page: <ReportListPage /> },
          { idx: 2, menuName: "접속이력", page: <LoginHistoryListPage /> },
          {
            idx: 3,
            menuName: "리포트열람이력",
            page: <ReportHistoryListPage />,
          },
          { idx: 4, menuName: "회수신청", page: <RequestHistoryListPage /> },
          { idx: 5, menuName: "FAQ관리", page: <AdminFaqPage /> },
          { idx: 6, menuName: "1:1문의관리", page: <AdminInquiryPage /> },
          { idx: 7, menuName: "주문상태관리", page: <OrderListPage /> },
          { idx: 8, menuName: "상품관리", page: <AdminItemManagementPage /> },
          { idx: 9, menuName: "팝업관리", page: <ModalListPage /> },
          { idx: 10, menuName: "탈퇴회원관리", page: <ExitUserListPage /> },
          // {
          //   idx: 11,
          //   menuName: "D타입리포트목록",
          //   page: <DTypeReportListPage />,
          // },
        ]
      : userInfo.user_type === "S"
      ? [
          { idx: 0, menuName: "회원목록", page: <UserListPage /> },
          { idx: 4, menuName: "회수신청", page: <RequestHistoryListPage /> },
          { idx: 5, menuName: "FAQ관리", page: <AdminFaqPage /> },
          { idx: 6, menuName: "1:1문의관리", page: <AdminInquiryPage /> },
          { idx: 7, menuName: "주문상태관리", page: <OrderListPage /> },
          { idx: 8, menuName: "상품관리", page: <AdminItemManagementPage /> },
          { idx: 9, menuName: "팝업관리", page: <ModalListPage /> },
        ]
      : [
          { idx: 1, menuName: "리포트목록", page: <ReportListPage /> },
          {
            idx: 3,
            menuName: "리포트열람이력",
            page: <ReportHistoryListPage />,
          },
          { idx: 6, menuName: "1:1문의관리", page: <AdminInquiryPage /> },
          // {
          //   idx: 11,
          //   menuName: "D타입리포트목록",
          //   page: <DTypeReportListPage />,
          // },
        ];

  const ChangeMenu = (e, select) => {
    e.preventDefault();
    setMenuIndex(select);
  };

  useEffect(() => {
    // setMenuIndex(adminList[0].idx);
  }, []);

  return (
    <>
      <div className="container">
        <div className="content-box5">
          {/* <img src="/assets/images/common/genomecheck.png" alt="" /> */}
          <div className="main-menu">
            {adminList.map((item) => (
              <div
                className="menu-title"
                onClick={(e) => ChangeMenu(e, item.idx)}
              >
                <span className={menuIndex === item.idx ? "isHit" : ""}>
                  {item.menuName}
                </span>
                {menuIndex === item.idx ? <div className="rt" /> : null}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="line" />
      </div>

      {adminList.find((menu) => menu.idx === menuIndex)?.page}
    </>
  );
};

export default AdminPage;
