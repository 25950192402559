import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { reportListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import "assets/css/board.css";
import moment from "moment";
import Select from "react-select";
import {
  ArticleOutlined,
  ForwardToInboxOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 15px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const ReportListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportList, setReportList] = useState([]);
  const [localReportList, setLocalReportList] = useState([]);
  const [reportResult, setReportResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const userInfo = useRecoilValue(userInfoState);
  const [reportListPage, setReportListPage] = useRecoilState(
    reportListPageIndexState
  );
  const navigate = useNavigate();

  const selectResultConfirmTypeOptions = [
    { value: 0, label: "부적합" },
    { value: 1, label: "적합" },
  ];

  const selectChiefApprovalTypeOptions = [
    { value: 0, label: "미승인" },
    { value: 1, label: "승인" },
  ];

  const handleCheckboxChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const selectedResultConfirmTypeHandler = (e, data) => {
    const updatedItems = reportList.map((item) => {
      if (item.row_num === data.row_num) {
        return { ...item, is_delivery_approval: e.value };
      }
      return item;
    });

    setReportList(updatedItems);
  };

  const selectedChiefApprovalTypeHandler = (e, data) => {
    const updatedItems = reportList.map((item) => {
      if (item.row_num === data.row_num) {
        return { ...item, is_chief_approval: e.value };
      }
      return item;
    });

    setReportList(updatedItems);
  };

  const getDateStyle = (receiptDate) => {
    if (!receiptDate) return {};
    const currentDate = moment();
    const date = moment(receiptDate);
    const diffDays = currentDate.diff(date, "days");

    if (diffDays >= 9 && diffDays <= 14) {
      return { backgroundColor: "yellow" };
    } else if (diffDays > 14) {
      return { backgroundColor: "pink" };
    } else {
      return {};
    }
  };

  const getAllReportList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getNewAllReportList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
      },
    })
      .then((res) => {
        if (res.success) {
          setReportResult(res.result);
          setReportList(res.reportList);
          setLocalReportList(res.reportList);
          setPageList(res.pageList);
          setReportListPage(reqData);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] getAllReportList : ", err);
      });
  };

  const goReport = (e, item) => {
    e.preventDefault();
    addOrderWorkHistoryWithKitnumber(item.kit_number, "리포트 열람");
    updateReportConfirm(item.report_confirm, item.kit_number);
    navigate("/newreportpage", {
      state: { reportInfo: item },
    });
  };

  const goSendMail = (e, item) => {
    e.preventDefault();

    if (item.kit_number && item.confirm_code) {
      const updatedItems = reportList.map((report) =>
        report.kit_number === item.kit_number
          ? { ...report, send_message_confirm: 1 }
          : report
      );
      setReportList(updatedItems);

      axiosFetchA({
        method: "post",
        url: apiUrls.sendMail,
        requestConfig: {
          kit_number: item.kit_number,
          confirm_code: item.confirm_code,
          customer_phone: item.user_phone,
        },
      })
        .then((res) => {
          if (res.success) {
            addOrderWorkHistoryWithKitnumber(
              item.kit_number,
              "키트번호 / 확인코드 안내 문자 발송"
            );
            alert("안내 문자가 발송되었습니다.");
          }
        })
        .catch((err) => {
          // console.log("[ERROR] goSendMail : ", err);
        });
    } else {
      alert("확인코드 없음. 프로그램으로 데이터를 입력해주세요.");
    }
  };

  const addOrderWorkHistoryWithKitnumber = (kit_number, order_work_content) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.addOrderWorkHistoryWithKitnumber,
      requestConfig: {
        kit_number: kit_number,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
        order_work_content: order_work_content,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  const updateReportConfirm = (reportConfirm, kit_number) => {
    let confirm = "";
    if (reportConfirm === null) {
      confirm = userInfo.user_id;
    } else {
      if (!reportConfirm.includes(userInfo.user_id)) {
        // 포함되어 있지 않다면 user_id를 추가
        confirm = `${reportConfirm},${userInfo.user_id}`;
      } else {
        confirm = reportConfirm;
      }
    }

    if (reportConfirm !== confirm) {
      axiosFetchA({
        method: "post",
        url: apiUrls.updateReportConfirm,
        requestConfig: {
          report_confirm: confirm,
          kit_number: kit_number,
        },
      })
        .then((res) => {})
        .catch((err) => {});
    }
  };

  const updateDeliveryApprovalStatus = (e, reqData) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.updateDeliveryApprovalStatus,
      requestConfig: {
        kit_number: reqData.kit_number,
        is_delivery_approval: reqData.is_delivery_approval,
        id_delivery_approval: userInfo.user_id,
        name_delivery_approval: userInfo.user_name,
      },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllReportList(reportListPage);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] updateDeliveryApprovalStatus : ", err);
      });
  };

  const updateChiefApprovalStatus = (e, reqData) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.updateChiefApprovalStatus,
      requestConfig: {
        kit_number: reqData.kit_number,
        is_chief_approval: reqData.is_chief_approval,
      },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllReportList(reportListPage);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] updateChiefApprovalStatus : ", err);
      });
  };

  useEffect(() => {
    getAllReportList(reportListPage);
  }, []);

  return (
    <>
      <div className="container-n-admin">
        <div className="content-area">
          <div className="board-info">
            <div className="board-total">
              <span>총 </span>
              {reportResult.totalrows}
              <span>건의 리포트</span>
            </div>
            <div className="board-search-area">
              <div className="check">
                <label>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "5px",
                      width: "16px",
                      height: "16px",
                    }}
                    checked={isDateRangeVisible}
                    onChange={handleCheckboxChange}
                  ></input>
                  기간설정
                </label>
              </div>

              {isDateRangeVisible && (
                <div className="date-range">
                  <div className="date-picker">
                    <label>
                      시작 날짜:
                      <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </label>
                    <label>
                      종료 날짜:
                      <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              )}
              <div className="board-search">
                <input
                  type="text"
                  placeholder="검색어를 입력해 주세요"
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      getAllReportList(1);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={(e) => {
                    getAllReportList(1);
                  }}
                >
                  검색
                </button>
              </div>
            </div>
          </div>

          <div>
            <table className="boardTable">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>키트번호</th>
                  <th>확인코드</th>
                  <th>Type</th>
                  <th>이름</th>
                  <th>성별</th>
                  <th>검체수령일</th>
                  <th>TAT</th>
                  <th>결과보고일</th>
                  <th>리포트확인</th>
                  <th>결과전달담당자 검토사항</th>
                  <th>총괄책임자 승인여부</th>
                  <th>문자발송</th>
                </tr>
              </thead>
              <tbody>
                {reportList?.map((item, index) => (
                  <tr key={item.row_num}>
                    <td>
                      <div>{reportResult.totalrows - item.row_num + 1}</div>
                    </td>
                    <td>
                      <div>{item.kit_number}</div>
                    </td>
                    <td>
                      <div>{item.confirm_code}</div>
                    </td>
                    <td>
                      <div>{item.report_type}</div>
                    </td>
                    <td>
                      {item.user_name ? (
                        <div>{item.user_name}</div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                    <td>
                      {item.user_gender ? (
                        <div>
                          {Number(item.user_gender) === 1 ? "남" : "여"}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                    <td>
                      {item.receipt_date ? (
                        <div>
                          {moment(item.receipt_date).format("YYYY/MM/DD")}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                    <td
                      style={
                        item.order_complete_status === 6
                          ? null
                          : getDateStyle(item.receipt_date)
                      }
                    >
                      <div className="date">
                        {item.receipt_date
                          ? moment(item.receipt_date)
                              .add(14, "days")
                              .format("YY.MM.DD")
                          : "-"}
                      </div>
                    </td>
                    <td>
                      {item.report_date ? (
                        <div>
                          {moment(item.report_date).format("YYYY/MM/DD")}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                    <td>
                      {item.confirm_code ? (
                        <div>
                          <button
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              goReport(e, item);
                            }}
                          >
                            <IconWrapper>
                              <ArticleOutlined fontSize="medium" />
                            </IconWrapper>
                            {typeof item.report_confirm === "string" &&
                              item.report_confirm.includes(
                                userInfo.user_id
                              ) && <div>확인완료</div>}
                          </button>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {item.confirm_code ? (
                        <div className="select-area">
                          <Select
                            className="select"
                            options={selectResultConfirmTypeOptions}
                            value={selectResultConfirmTypeOptions.filter(
                              (option) =>
                                option.value ===
                                Number(item.is_delivery_approval)
                            )}
                            onChange={(e) =>
                              selectedResultConfirmTypeHandler(e, item)
                            }
                            isDisabled={userInfo?.user_type === "I"}
                          ></Select>
                          <button
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              updateDeliveryApprovalStatus(e, item);
                            }}
                            disabled={userInfo?.user_type === "I"}
                          >
                            <IconWrapper>
                              <SaveOutlined fontSize="medium" />
                            </IconWrapper>
                          </button>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {item.confirm_code ? (
                        <div className="select-area">
                          <Select
                            className="select"
                            options={selectChiefApprovalTypeOptions}
                            value={selectChiefApprovalTypeOptions.filter(
                              (option) =>
                                option.value === Number(item.is_chief_approval)
                            )}
                            onChange={(e) =>
                              selectedChiefApprovalTypeHandler(e, item)
                            }
                            isDisabled={
                              userInfo?.user_type === "I" ||
                              userInfo?.user_type === "D"
                            }
                          ></Select>
                          <button
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              updateChiefApprovalStatus(e, item);
                            }}
                            disabled={
                              userInfo?.user_type === "I" ||
                              userInfo?.user_type === "D"
                            }
                          >
                            <IconWrapper>
                              <SaveOutlined fontSize="medium" />
                            </IconWrapper>
                          </button>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {item.confirm_code &&
                      localReportList[index].is_delivery_approval === 1 &&
                      localReportList[index].is_chief_approval === 1 ? (
                        <div>
                          <button
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              goSendMail(e, item);
                            }}
                            disabled={userInfo?.user_type === "I"}
                          >
                            <IconWrapper>
                              <ForwardToInboxOutlined fontSize="medium" />
                            </IconWrapper>
                            {Number(item.send_message_confirm) === 1 ? (
                              <div>발송완료</div>
                            ) : null}
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <ul className="paging">
              <li>
                <Link
                  onClick={() =>
                    getAllReportList(
                      reportResult.currentPage - 10 < 1
                        ? 1
                        : reportResult.currentPage - 10
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img src="/assets/images/common/boardFirst.png" alt="" />
                </Link>
              </li>
              {pageList?.map((item) => (
                <li
                  key={item}
                  className={item === reportResult.currentPage ? "on" : null}
                >
                  <Link
                    onClick={() => getAllReportList(item)}
                    style={{ cursor: "pointer" }}
                  >
                    {item}
                  </Link>
                </li>
              ))}

              <li>
                <Link
                  onClick={() =>
                    getAllReportList(
                      pageList[pageList.length - 1] + 1 < reportResult.maxPage
                        ? pageList[pageList.length - 1] + 1
                        : pageList[pageList.length - 1]
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img src="/assets/images/common/boardLast.png" alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="content-box">
          <div className="board-list">
            <div className="board-info">
              <div className="board-total">
                <span>총 </span>
                {reportResult.totalrows}
                <span>건의 리포트</span>
              </div>
              <div className="board-search-area">
                <div className="check">
                  <label>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "5px",
                        width: "16px",
                        height: "16px",
                      }}
                      checked={isDateRangeVisible}
                      onChange={handleCheckboxChange}
                    ></input>
                    기간설정
                  </label>
                </div>

                {isDateRangeVisible && (
                  <div className="date-range">
                    <div className="date-picker">
                      <label>
                        시작 날짜:
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </label>
                      <label>
                        종료 날짜:
                        <input
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div className="board-search">
                  <input
                    type="text"
                    placeholder="검색어를 입력해 주세요"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        getAllReportList(1);
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      getAllReportList(1);
                    }}
                  >
                    검색
                  </button>
                </div>
              </div>
            </div>
            <ul className="boardList">
              <li className="head">
                <div className="board-num">
                  <strong>번호</strong>
                </div>
                <div className="user-id">
                  <strong>키트번호</strong>
                </div>
                <div className="user-id">
                  <strong>확인코드</strong>
                </div>
                <div className="user-type">
                  <strong>Type</strong>
                </div>
                <div className="customer-name">
                  <strong>이름</strong>
                </div>
                <div className="user-sex">
                  <strong>성별</strong>
                </div>
                <div className="receipt-date">
                  <strong>검체수령일</strong>
                </div>
                <div className="receipt-date">
                  <strong>결과보고일</strong>
                </div>
                <div className="report-ad">
                  <strong>리포트확인</strong>
                </div>
                <div className="report-ad">
                  <strong>문자발송</strong>
                </div>
              </li>
              {reportList?.map((item, index) => (
                <>
                  <li key={item.rownum} className="mobile-board-li">
                    <div className="board-num">
                      {reportResult.totalrows - item.rownum}
                    </div>
                    <div className="user-id">{item.kit_number}</div>
                    <div className="user-id">{item.confirm_code}</div>
                    <div className="customer-name">{item.name}</div>
                    <div className="report-ad">
                      <button
                        onClick={(e) => {
                          goReport(e, item);
                        }}
                      >
                        <img src="/assets/images/common/document.png" alt="" />
                      </button>
                    </div>
                    <div className="report-ad">
                      <button
                        style={{ background: "transparent" }}
                        onClick={(e) => {
                          goSendMail(e, item);
                        }}
                      >
                        <img src="/assets/images/common/mail.png" alt="" />
                      </button>
                    </div>
                  </li>
                  <li key={item.kit_number + index} className="pc-board-li">
                    <div className="board-num">
                      {reportResult.totalrows - item.rownum}
                    </div>
                    <div className="user-id">{item.kit_number}</div>
                    <div className="user-id">{item.confirm_code}</div>
                    <div className="user-type">{item.customer_type}</div>
                    <div className="customer-name">{item.name}</div>
                    <div className="user-sex">
                      {item.gender === 1 ? "남" : "여"}
                    </div>
                    <div className="report-date">
                      {moment(item.receipt_date).format("YYYY/MM/DD")}
                    </div>
                    <div className="report-date">
                      {moment(item.report_date).format("YYYY/MM/DD")}
                    </div>
                    <div className="report-ad">
                      <button
                        style={{ background: "transparent" }}
                        onClick={(e) => {
                          goReport(e, item);
                        }}
                      >
                        <img src="/assets/images/common/report.png" alt="" />
                      </button>
                    </div>
                    <div className="report-ad">
                      <button
                        style={{ background: "transparent" }}
                        onClick={(e) => {
                          goSendMail(e, item);
                        }}
                      >
                        <img src="/assets/images/common/mail.png" alt="" />
                      </button>
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <ul className="paging">
        <li>
          <Link
            onClick={() =>
              getAllReportList(
                reportResult.currentPage - 10 < 1
                  ? 1
                  : reportResult.currentPage - 10
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/common/boardFirst.png" alt="" />
          </Link>
        </li>
        {pageList?.map((item) => (
          <li
            key={item}
            className={item === reportResult.currentPage ? "on" : null}
          >
            <Link
              onClick={() => getAllReportList(item)}
              style={{ cursor: "pointer" }}
            >
              {item}
            </Link>
          </li>
        ))}

        <li>
          <Link
            onClick={() =>
              getAllReportList(
                pageList[pageList.length - 1] + 1 < reportResult.maxPage
                  ? pageList[pageList.length - 1] + 1
                  : pageList[pageList.length - 1]
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/common/boardLast.png" alt="" />
          </Link>
        </li>
      </ul> */}
    </>
  );
};

export default ReportListPage;
