import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import FloatingBtn from "components/common/floatingBtn";
import ReportDAType1Page from "pages/report/datype/reportDAType1Page";
import ReportDAType2Page from "pages/report/datype/reportDAType2Page";
import ReportDAType3Page from "pages/report/datype/reportDAType3Page";
import ReportDAType4Page from "pages/report/datype/reportDAType4Page";
import ReportDAType5Page from "pages/report/datype/reportDAType5Page";
import ReportDAType8Page from "pages/report/datype/reportDAType8Page";
import ReportDAType9Page from "pages/report/datype/reportDAType9Page";
import ReportDAType10Page from "pages/report/datype/reportDAType10Page";
import ReportDAType11Page from "pages/report/datype/reportDAType11Page";
import ReportDAType12Page from "pages/report/datype/reportDAType12Page";
import ReportDAType13Page from "pages/report/datype/reportDAType13Page";
import ReportDAType14Page from "pages/report/datype/reportDAType14Page";
import ReportDAType15Page from "pages/report/datype/reportDAType15Page";
import ReportDAType16Page from "pages/report/datype/reportDAType16Page";
import {
  contentDBType,
  dtcDBDescription,
  solutionReferenceInfo,
} from "pages/report/datype/reportDATypeContent";
import "assets/css/report.css";
import useAxiosFunction from "hooks/useAxios";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";

const ReportDBTypePage = (props) => {
  const reportData = props.reportData;
  const reportInfo = props.reportInfo;

  const ref = useRef();
  const refToPrint = useRef();

  const vitaminCRef = useRef(null);
  const vitaminDRef = useRef(null);
  const vitaminARef = useRef(null);
  const vitaminB6Ref = useRef(null);
  const vitaminB12Ref = useRef(null);
  const vitaminERef = useRef(null);
  const vitaminKRef = useRef(null);
  const omega3Ref = useRef(null);
  const omega6Ref = useRef(null);
  const arginineRef = useRef(null);
  const calciumRef = useRef(null);
  const magnesiumRef = useRef(null);
  const zincRef = useRef(null);
  const ironRef = useRef(null);
  const tyrosineRef = useRef(null);
  const betaineRef = useRef(null);
  const seleniumRef = useRef(null);
  const luteinRef = useRef(null);

  const fatRef = useRef(null);
  const bodyFatPerRef = useRef(null);
  const bmiRef = useRef(null);
  const abdominalRef = useRef(null);
  const nFatConcentrationRef = useRef(null);
  const LDLRef = useRef(null);
  const HDLRef = useRef(null);
  const effectLosingWeightRef = useRef(null);
  const bloodSugarRef = useRef(null);
  const bloodPressureRef = useRef(null);
  const boneMassRef = useRef(null);
  const degenerativeRef = useRef(null);
  const uricAcidRef = useRef(null);
  const travelSicknessRef = useRef(null);

  const alcoholDependenceRef = useRef(null);
  const alcoholFlushRef = useRef(null);
  const nicotineMetabolismRef = useRef(null);
  const nicotineDependenceRef = useRef(null);
  const caffeineMetabolismRef = useRef(null);
  const caffeineDependenceRef = useRef(null);
  const insomniaRef = useRef(null);
  const morningPersonRef = useRef(null);
  const frecklesRef = useRef(null);
  const pigmentationRef = useRef(null);
  const acneRef = useRef(null);
  const skinAgingRef = useRef(null);
  const skinInflammationRef = useRef(null);
  const tanningReactionRef = useRef(null);
  const whiteHairRef = useRef(null);
  const circularHairLossRef = useRef(null);

  const muscleFitnessRef = useRef(null);
  const exerciseFitnessRef = useRef(null);
  const aerobicExerciseRef = useRef(null);
  const shortDistanceRunningRef = useRef(null);
  const recoverAfterExercisingRef = useRef(null);
  const muscleDevelopmentRef = useRef(null);

  const sensitivitySweetRef = useRef(null);
  const sensitivityBitterRef = useRef(null);
  const sensitivitySaltyRef = useRef(null);
  const feelingFullRef = useRef(null);

  const vitaminCDetailRef = useRef(null);
  const vitaminDDetailRef = useRef(null);
  const vitaminADetailRef = useRef(null);
  const vitaminB6DetailRef = useRef(null);
  const vitaminB12DetailRef = useRef(null);
  const vitaminEDetailRef = useRef(null);
  const vitaminKDetailRef = useRef(null);
  const omega3DetailRef = useRef(null);
  const omega6DetailRef = useRef(null);
  const arginineDetailRef = useRef(null);
  const calciumDetailRef = useRef(null);
  const magnesiumDetailRef = useRef(null);
  const zincDetailRef = useRef(null);
  const ironDetailRef = useRef(null);
  const tyrosineDetailRef = useRef(null);
  const betaineDetailRef = useRef(null);
  const seleniumDetailRef = useRef(null);
  const luteinDetailRef = useRef(null);

  const fatDetailRef = useRef(null);
  const bodyFatPerDetailRef = useRef(null);
  const bmiDetailRef = useRef(null);
  const abdominalDetailRef = useRef(null);
  const nFatConcentrationDetailRef = useRef(null);
  const LDLDetailRef = useRef(null);
  const HDLDetailRef = useRef(null);
  const effectLosingWeightDetailRef = useRef(null);
  const bloodSugarDetailRef = useRef(null);
  const bloodPressureDetailRef = useRef(null);
  const boneMassDetailRef = useRef(null);
  const degenerativeDetailRef = useRef(null);
  const uricAcidDetailRef = useRef(null);
  const travelSicknessDetailRef = useRef(null);

  const alcoholDependenceDetailRef = useRef(null);
  const alcoholFlushDetailRef = useRef(null);
  const nicotineMetabolismDetailRef = useRef(null);
  const nicotineDependenceDetailRef = useRef(null);
  const caffeineMetabolismDetailRef = useRef(null);
  const caffeineDependenceDetailRef = useRef(null);
  const insomniaDetailRef = useRef(null);
  const morningPersonDetailRef = useRef(null);
  const frecklesDetailRef = useRef(null);
  const pigmentationDetailRef = useRef(null);
  const acneDetailRef = useRef(null);
  const skinAgingDetailRef = useRef(null);
  const skinInflammationDetailRef = useRef(null);
  const tanningReactionDetailRef = useRef(null);
  const whiteHairDetailRef = useRef(null);
  const circularHairLossDetailRef = useRef(null);

  const muscleFitnessDetailRef = useRef(null);
  const exerciseFitnessDetailRef = useRef(null);
  const aerobicExerciseDetailRef = useRef(null);
  const shortDistanceRunningDetailRef = useRef(null);
  const recoverAfterExercisingDetailRef = useRef(null);
  const muscleDevelopmentDetailRef = useRef(null);

  const sensitivitySweetDetailRef = useRef(null);
  const sensitivityBitterDetailRef = useRef(null);
  const sensitivitySaltyDetailRef = useRef(null);
  const feelingFullDetailRef = useRef(null);

  const vitaminCReferenceRef = useRef(null);
  const vitaminDReferenceRef = useRef(null);
  const vitaminAReferenceRef = useRef(null);
  const vitaminB6ReferenceRef = useRef(null);
  const vitaminB12ReferenceRef = useRef(null);
  const vitaminEReferenceRef = useRef(null);
  const vitaminKReferenceRef = useRef(null);
  const omega3ReferenceRef = useRef(null);
  const omega6ReferenceRef = useRef(null);
  const arginineReferenceRef = useRef(null);
  const calciumReferenceRef = useRef(null);
  const magnesiumReferenceRef = useRef(null);
  const zincReferenceRef = useRef(null);
  const ironReferenceRef = useRef(null);
  const tyrosineReferenceRef = useRef(null);
  const betaineReferenceRef = useRef(null);
  const seleniumReferenceRef = useRef(null);
  const luteinReferenceRef = useRef(null);

  const fatReferenceRef = useRef(null);
  const bodyFatPerReferenceRef = useRef(null);
  const bmiReferenceRef = useRef(null);
  const abdominalReferenceRef = useRef(null);
  const nFatConcentrationReferenceRef = useRef(null);
  const LDLReferenceRef = useRef(null);
  const HDLReferenceRef = useRef(null);
  const effectLosingWeightReferenceRef = useRef(null);
  const bloodSugarReferenceRef = useRef(null);
  const bloodPressureReferenceRef = useRef(null);
  const boneMassReferenceRef = useRef(null);
  const degenerativeReferenceRef = useRef(null);
  const uricAcidReferenceRef = useRef(null);
  const travelSicknessReferenceRef = useRef(null);

  const alcoholMetabolismReferenceRef = useRef(null);
  const alcoholDependenceReferenceRef = useRef(null);
  const alcoholFlushReferenceRef = useRef(null);
  const nicotineMetabolismReferenceRef = useRef(null);
  const nicotineDependenceReferenceRef = useRef(null);
  const caffeineMetabolismReferenceRef = useRef(null);
  const caffeineDependenceReferenceRef = useRef(null);
  const insomniaReferenceRef = useRef(null);
  const morningPersonReferenceRef = useRef(null);
  const frecklesReferenceRef = useRef(null);
  const pigmentationReferenceRef = useRef(null);
  const acneReferenceRef = useRef(null);
  const skinAgingReferenceRef = useRef(null);
  const skinInflammationReferenceRef = useRef(null);
  const tanningReactionReferenceRef = useRef(null);
  const whiteHairReferenceRef = useRef(null);
  const hairLossReferenceRef = useRef(null);
  const circularHairLossReferenceRef = useRef(null);
  const hairThicknessReferenceRef = useRef(null);

  const muscleFitnessReferenceRef = useRef(null);
  const exerciseFitnessReferenceRef = useRef(null);
  const aerobicExerciseReferenceRef = useRef(null);
  const shortDistanceRunningReferenceRef = useRef(null);
  const recoverAfterExercisingReferenceRef = useRef(null);
  const muscleDevelopmentReferenceRef = useRef(null);
  const ankleInjuryReferenceRef = useRef(null);

  const sensitivitySweetReferenceRef = useRef(null);
  const sensitivityBitterReferenceRef = useRef(null);
  const sensitivitySaltyReferenceRef = useRef(null);
  const feelingFullReferenceRef = useRef(null);

  const { apiUrls, axiosFetch } = useAxiosFunction();
  const userInfo = useRecoilValue(userInfoState);
  let pageNum = 1;

  const clickName = (name) => {
    if (name === "비타민 C 농도") {
      scrollToRef(vitaminCRef, "start");
    } else if (name === "비타민 D 농도") {
      scrollToRef(vitaminDRef, "start");
    } else if (name === "비타민 A 농도") {
      scrollToRef(vitaminARef, "start");
    } else if (name === "비타민 B6 농도") {
      scrollToRef(vitaminB6Ref, "start");
    } else if (name === "비타민 B12 농도") {
      scrollToRef(vitaminB12Ref, "start");
    } else if (name === "비타민 E 농도") {
      scrollToRef(vitaminERef, "start");
    } else if (name === "비타민 K 농도") {
      scrollToRef(vitaminKRef, "start");
    } else if (name === "오메가-3 지방산 농도") {
      scrollToRef(omega3Ref, "start");
    } else if (name === "오메가-6 지방산 농도") {
      scrollToRef(omega6Ref, "start");
    } else if (name === "아르기닌 농도") {
      scrollToRef(arginineRef, "start");
    } else if (name === "칼슘 농도") {
      scrollToRef(calciumRef, "start");
    } else if (name === "마그네슘 농도") {
      scrollToRef(magnesiumRef, "start");
    } else if (name === "아연 농도") {
      scrollToRef(zincRef, "start");
    } else if (name === "철 저장 농도") {
      scrollToRef(ironRef, "start");
    } else if (name === "타이로신 농도") {
      scrollToRef(tyrosineRef, "start");
    } else if (name === "베타인 농도") {
      scrollToRef(betaineRef, "start");
    } else if (name === "셀레늄 농도") {
      scrollToRef(seleniumRef, "start");
    } else if (name === "루테인&지아잔틴 농도") {
      scrollToRef(luteinRef, "start");
    } else if (name === "비만") {
      scrollToRef(fatRef, "start");
    } else if (name === "체지방율") {
      scrollToRef(bodyFatPerRef, "start");
    } else if (name === "체질량지수") {
      scrollToRef(bmiRef, "start");
    } else if (name === "복부비만(엉덩이허리비율)") {
      scrollToRef(abdominalRef, "start");
    } else if (name === "중성지방 농도") {
      scrollToRef(nFatConcentrationRef, "start");
    } else if (name === "LDL 콜레스테롤 농도") {
      scrollToRef(LDLRef, "start");
    } else if (name === "HDL 콜레스테롤 농도") {
      scrollToRef(HDLRef, "start");
    } else if (name === "운동에 의한 체중감량효과") {
      scrollToRef(effectLosingWeightRef, "start");
    } else if (name === "혈당") {
      scrollToRef(bloodSugarRef, "start");
    } else if (name === "혈압") {
      scrollToRef(bloodPressureRef, "start");
    } else if (name === "골질량") {
      scrollToRef(boneMassRef, "start");
    } else if (name === "퇴행성관절염증감수성") {
      scrollToRef(degenerativeRef, "start");
    } else if (name === "요산치") {
      scrollToRef(uricAcidRef, "start");
    } else if (name === "멀미") {
      scrollToRef(travelSicknessRef, "start");
    } else if (name === "알코올의존성") {
      scrollToRef(alcoholDependenceRef, "start");
    } else if (name === "알코올홍조") {
      scrollToRef(alcoholFlushRef, "start");
    } else if (name === "니코틴대사") {
      scrollToRef(nicotineMetabolismRef, "start");
    } else if (name === "니코틴의존성") {
      scrollToRef(nicotineDependenceRef, "start");
    } else if (name === "카페인대사") {
      scrollToRef(caffeineMetabolismRef, "start");
    } else if (name === "카페인의존성") {
      scrollToRef(caffeineDependenceRef, "start");
    } else if (name === "불면증") {
      scrollToRef(insomniaRef, "start");
    } else if (name === "아침형, 저녁형인간") {
      scrollToRef(morningPersonRef, "start");
    } else if (name === "기미/주근깨") {
      scrollToRef(frecklesRef, "start");
    } else if (name === "색소침착") {
      scrollToRef(pigmentationRef, "start");
    } else if (name === "여드름발생") {
      scrollToRef(acneRef, "start");
    } else if (name === "피부노화") {
      scrollToRef(skinAgingRef, "start");
    } else if (name === "피부염증") {
      scrollToRef(skinInflammationRef, "start");
    } else if (name === "태양노출후 태닝반응") {
      scrollToRef(tanningReactionRef, "start");
    } else if (name === "새치") {
      scrollToRef(whiteHairRef, "start");
    } else if (name === "원형탈모") {
      scrollToRef(circularHairLossRef, "start");
    } else if (name === "근력운동적합성") {
      scrollToRef(muscleFitnessRef, "start");
    } else if (name === "지구력운동적합성") {
      scrollToRef(exerciseFitnessRef, "start");
    } else if (name === "유산소운동적합성") {
      scrollToRef(aerobicExerciseRef, "start");
    } else if (name === "단거리질주능력") {
      scrollToRef(shortDistanceRunningRef, "start");
    } else if (name === "운동후회복능력") {
      scrollToRef(recoverAfterExercisingRef, "start");
    } else if (name === "근육발달능력") {
      scrollToRef(muscleDevelopmentRef, "start");
    } else if (name === "단맛민감도") {
      scrollToRef(sensitivitySweetRef, "start");
    } else if (name === "쓴맛민감도") {
      scrollToRef(sensitivityBitterRef, "start");
    } else if (name === "짠맛민감도") {
      scrollToRef(sensitivitySaltyRef, "start");
    } else if (name === "포만감") {
      scrollToRef(feelingFullRef, "start");
    }
  };

  const clickDetailName = (name) => {
    if (name === "비타민 C 농도") {
      scrollToRef(vitaminCDetailRef, "center");
    } else if (name === "비타민 D 농도") {
      scrollToRef(vitaminDDetailRef, "center");
    } else if (name === "비타민 A 농도") {
      scrollToRef(vitaminADetailRef, "center");
    } else if (name === "비타민 B6 농도") {
      scrollToRef(vitaminB6DetailRef, "center");
    } else if (name === "비타민 B12 농도") {
      scrollToRef(vitaminB12DetailRef, "center");
    } else if (name === "비타민 E 농도") {
      scrollToRef(vitaminEDetailRef, "center");
    } else if (name === "비타민 K 농도") {
      scrollToRef(vitaminKDetailRef, "center");
    } else if (name === "오메가-3 지방산 농도") {
      scrollToRef(omega3DetailRef, "center");
    } else if (name === "오메가-6 지방산 농도") {
      scrollToRef(omega6DetailRef, "center");
    } else if (name === "아르기닌 농도") {
      scrollToRef(arginineDetailRef, "center");
    } else if (name === "칼슘 농도") {
      scrollToRef(calciumDetailRef, "center");
    } else if (name === "마그네슘 농도") {
      scrollToRef(magnesiumDetailRef, "center");
    } else if (name === "아연 농도") {
      scrollToRef(zincDetailRef, "center");
    } else if (name === "철 저장 농도") {
      scrollToRef(ironDetailRef, "center");
    } else if (name === "타이로신 농도") {
      scrollToRef(tyrosineDetailRef, "center");
    } else if (name === "베타인 농도") {
      scrollToRef(betaineDetailRef, "center");
    } else if (name === "셀레늄 농도") {
      scrollToRef(seleniumDetailRef, "center");
    } else if (name === "루테인&지아잔틴 농도") {
      scrollToRef(luteinDetailRef, "center");
    } else if (name === "비만") {
      scrollToRef(fatDetailRef, "center");
    } else if (name === "체지방율") {
      scrollToRef(bodyFatPerDetailRef, "center");
    } else if (name === "체질량지수") {
      scrollToRef(bmiDetailRef, "center");
    } else if (name === "복부비만(엉덩이허리비율)") {
      scrollToRef(abdominalDetailRef, "center");
    } else if (name === "중성지방 농도") {
      scrollToRef(nFatConcentrationDetailRef, "center");
    } else if (name === "LDL 콜레스테롤 농도") {
      scrollToRef(LDLDetailRef, "center");
    } else if (name === "HDL 콜레스테롤 농도") {
      scrollToRef(HDLDetailRef, "center");
    } else if (name === "운동에 의한 체중감량효과") {
      scrollToRef(effectLosingWeightDetailRef, "center");
    } else if (name === "혈당") {
      scrollToRef(bloodSugarDetailRef, "center");
    } else if (name === "혈압") {
      scrollToRef(bloodPressureDetailRef, "center");
    } else if (name === "골질량") {
      scrollToRef(boneMassDetailRef, "center");
    } else if (name === "퇴행성관절염증감수성") {
      scrollToRef(degenerativeDetailRef, "center");
    } else if (name === "요산치") {
      scrollToRef(uricAcidDetailRef, "center");
    } else if (name === "멀미") {
      scrollToRef(travelSicknessDetailRef, "center");
    } else if (name === "알코올의존성") {
      scrollToRef(alcoholDependenceDetailRef, "center");
    } else if (name === "알코올홍조") {
      scrollToRef(alcoholFlushDetailRef, "center");
    } else if (name === "니코틴대사") {
      scrollToRef(nicotineMetabolismDetailRef, "center");
    } else if (name === "니코틴의존성") {
      scrollToRef(nicotineDependenceDetailRef, "center");
    } else if (name === "카페인대사") {
      scrollToRef(caffeineMetabolismDetailRef, "center");
    } else if (name === "카페인의존성") {
      scrollToRef(caffeineDependenceDetailRef, "center");
    } else if (name === "불면증") {
      scrollToRef(insomniaDetailRef, "center");
    } else if (name === "아침형, 저녁형인간") {
      scrollToRef(morningPersonDetailRef, "center");
    } else if (name === "기미/주근깨") {
      scrollToRef(frecklesDetailRef, "center");
    } else if (name === "색소침착") {
      scrollToRef(pigmentationDetailRef, "center");
    } else if (name === "여드름발생") {
      scrollToRef(acneDetailRef, "center");
    } else if (name === "피부노화") {
      scrollToRef(skinAgingDetailRef, "center");
    } else if (name === "피부염증") {
      scrollToRef(skinInflammationDetailRef, "center");
    } else if (name === "태양노출후 태닝반응") {
      scrollToRef(tanningReactionDetailRef, "center");
    } else if (name === "새치") {
      scrollToRef(whiteHairDetailRef, "center");
    } else if (name === "원형탈모") {
      scrollToRef(circularHairLossDetailRef, "center");
    } else if (name === "근력운동적합성") {
      scrollToRef(muscleFitnessDetailRef, "center");
    } else if (name === "지구력운동적합성") {
      scrollToRef(exerciseFitnessDetailRef, "center");
    } else if (name === "유산소운동적합성") {
      scrollToRef(aerobicExerciseDetailRef, "center");
    } else if (name === "단거리질주능력") {
      scrollToRef(shortDistanceRunningDetailRef, "center");
    } else if (name === "운동후회복능력") {
      scrollToRef(recoverAfterExercisingDetailRef, "center");
    } else if (name === "근육발달능력") {
      scrollToRef(muscleDevelopmentDetailRef, "center");
    } else if (name === "단맛민감도") {
      scrollToRef(sensitivitySweetDetailRef, "center");
    } else if (name === "쓴맛민감도") {
      scrollToRef(sensitivityBitterDetailRef, "center");
    } else if (name === "짠맛민감도") {
      scrollToRef(sensitivitySaltyDetailRef, "center");
    } else if (name === "포만감") {
      scrollToRef(feelingFullDetailRef, "center");
    }
  };

  const clickReferenceName = (name) => {
    if (name === "비타민 C 농도") {
      scrollToRef(vitaminCReferenceRef, "center");
    } else if (name === "비타민 D 농도") {
      scrollToRef(vitaminDReferenceRef, "center");
    } else if (name === "비타민 A 농도") {
      scrollToRef(vitaminAReferenceRef, "center");
    } else if (name === "비타민 B6 농도") {
      scrollToRef(vitaminB6ReferenceRef, "center");
    } else if (name === "비타민 B12 농도") {
      scrollToRef(vitaminB12ReferenceRef, "center");
    } else if (name === "비타민 E 농도") {
      scrollToRef(vitaminEReferenceRef, "center");
    } else if (name === "비타민 K 농도") {
      scrollToRef(vitaminKReferenceRef, "center");
    } else if (name === "오메가-3 지방산 농도") {
      scrollToRef(omega3ReferenceRef, "center");
    } else if (name === "오메가-6 지방산 농도") {
      scrollToRef(omega6ReferenceRef, "center");
    } else if (name === "아르기닌 농도") {
      scrollToRef(arginineReferenceRef, "center");
    } else if (name === "칼슘 농도") {
      scrollToRef(calciumReferenceRef, "center");
    } else if (name === "마그네슘 농도") {
      scrollToRef(magnesiumReferenceRef, "center");
    } else if (name === "아연 농도") {
      scrollToRef(zincReferenceRef, "center");
    } else if (name === "철 저장 농도") {
      scrollToRef(ironReferenceRef, "center");
    } else if (name === "타이로신 농도") {
      scrollToRef(tyrosineReferenceRef, "center");
    } else if (name === "베타인 농도") {
      scrollToRef(betaineReferenceRef, "center");
    } else if (name === "셀레늄 농도") {
      scrollToRef(seleniumReferenceRef, "center");
    } else if (name === "루테인&지아잔틴 농도") {
      scrollToRef(luteinReferenceRef, "center");
    } else if (name === "비만") {
      scrollToRef(fatReferenceRef, "center");
    } else if (name === "체지방율") {
      scrollToRef(bodyFatPerReferenceRef, "center");
    } else if (name === "체질량지수") {
      scrollToRef(bmiReferenceRef, "center");
    } else if (name === "복부비만(엉덩이허리비율)") {
      scrollToRef(abdominalReferenceRef, "center");
    } else if (name === "중성지방 농도") {
      scrollToRef(nFatConcentrationReferenceRef, "center");
    } else if (name === "LDL 콜레스테롤 농도") {
      scrollToRef(LDLReferenceRef, "center");
    } else if (name === "HDL 콜레스테롤 농도") {
      scrollToRef(HDLReferenceRef, "center");
    } else if (name === "운동에 의한 체중감량효과") {
      scrollToRef(effectLosingWeightReferenceRef, "center");
    } else if (name === "혈당") {
      scrollToRef(bloodSugarReferenceRef, "center");
    } else if (name === "혈압") {
      scrollToRef(bloodPressureReferenceRef, "center");
    } else if (name === "골질량") {
      scrollToRef(boneMassReferenceRef, "center");
    } else if (name === "퇴행성관절염증감수성") {
      scrollToRef(degenerativeReferenceRef, "center");
    } else if (name === "요산치") {
      scrollToRef(uricAcidReferenceRef, "center");
    } else if (name === "멀미") {
      scrollToRef(travelSicknessReferenceRef, "center");
    } else if (name === "알코올대사") {
      scrollToRef(alcoholMetabolismReferenceRef, "center");
    } else if (name === "알코올의존성") {
      scrollToRef(alcoholDependenceReferenceRef, "center");
    } else if (name === "알코올홍조") {
      scrollToRef(alcoholFlushReferenceRef, "center");
    } else if (name === "니코틴대사") {
      scrollToRef(nicotineMetabolismReferenceRef, "center");
    } else if (name === "니코틴의존성") {
      scrollToRef(nicotineDependenceReferenceRef, "center");
    } else if (name === "카페인대사") {
      scrollToRef(caffeineMetabolismReferenceRef, "center");
    } else if (name === "카페인의존성") {
      scrollToRef(caffeineDependenceReferenceRef, "center");
    } else if (name === "불면증") {
      scrollToRef(insomniaReferenceRef, "center");
    } else if (name === "아침형, 저녁형인간") {
      scrollToRef(morningPersonReferenceRef, "center");
    } else if (name === "기미/주근깨") {
      scrollToRef(frecklesReferenceRef, "center");
    } else if (name === "색소침착") {
      scrollToRef(pigmentationReferenceRef, "center");
    } else if (name === "여드름발생") {
      scrollToRef(acneReferenceRef, "center");
    } else if (name === "피부노화") {
      scrollToRef(skinAgingReferenceRef, "center");
    } else if (name === "피부염증") {
      scrollToRef(skinInflammationReferenceRef, "center");
    } else if (name === "태양노출후 태닝반응") {
      scrollToRef(tanningReactionReferenceRef, "center");
    } else if (name === "새치") {
      scrollToRef(whiteHairReferenceRef, "center");
    } else if (name === "남성형탈모") {
      scrollToRef(hairLossReferenceRef, "center");
    } else if (name === "원형탈모") {
      scrollToRef(circularHairLossReferenceRef, "center");
    } else if (name === "모발굵기") {
      scrollToRef(hairThicknessReferenceRef, "center");
    } else if (name === "근력운동적합성") {
      scrollToRef(muscleFitnessReferenceRef, "center");
    } else if (name === "지구력운동적합성") {
      scrollToRef(exerciseFitnessReferenceRef, "center");
    } else if (name === "유산소운동적합성") {
      scrollToRef(aerobicExerciseReferenceRef, "center");
    } else if (name === "단거리질주능력") {
      scrollToRef(shortDistanceRunningReferenceRef, "center");
    } else if (name === "운동후회복능력") {
      scrollToRef(recoverAfterExercisingReferenceRef, "center");
    } else if (name === "근육발달능력") {
      scrollToRef(muscleDevelopmentReferenceRef, "center");
    } else if (name === "발목부상위험도") {
      scrollToRef(ankleInjuryReferenceRef, "center");
    } else if (name === "단맛민감도") {
      scrollToRef(sensitivitySweetReferenceRef, "center");
    } else if (name === "쓴맛민감도") {
      scrollToRef(sensitivityBitterReferenceRef, "center");
    } else if (name === "짠맛민감도") {
      scrollToRef(sensitivitySaltyReferenceRef, "center");
    } else if (name === "포만감") {
      scrollToRef(feelingFullReferenceRef, "center");
    }
  };

  const scrollToRef = (ref, position) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: position,
      });
    }
  };

  const downloadReportData = (e) => {
    e.preventDefault();

    if (userInfo.user_type !== "U") {
      axiosFetch({
        method: "post",
        url: apiUrls.addOrderWorkHistoryWithKitnumber,
        requestConfig: {
          kit_number: reportInfo.kit_number,
          user_id: userInfo.user_id,
          user_name: userInfo.user_name,
          user_type: userInfo.user_type,
          order_work_content: "리포트 다운로드",
        },
      })
        .then((res) => {
          refToPrint.current.handlePrint();
        })
        .catch((err) => {});
    }

    axiosFetch({
      method: "post",
      url: apiUrls.downloadReportData,
      requestConfig: {
        kit_number: reportInfo.kit_number,
        confirm_code: reportInfo.confirm_code,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
      },
    })
      .then((res) => {
        if (res.success) {
          refToPrint.current.handlePrint();
        } else {
          alert("리포트 다운로드 에러..관리자에게 문의하세요.");
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <button
        className="floating"
        onClick={(e) => downloadReportData(e)} // 클릭 이벤트 처리 후 인쇄 호출
      >
        <div>
          <img src="/assets/images/common/print.png" alt="" />
        </div>
      </button>

      <ReactToPrint ref={refToPrint} content={() => ref.current} />

      <div ref={ref}>
        <ReportDAType1Page
          reportType={"DB"}
          kit_number={reportData.kit_number}
          reqDate={reportInfo.report_date}
          name={reportInfo.user_name}
          gender={reportInfo.user_gender}
          pageNum={pageNum++}
          version={"DX-RR-06-01 (V01, 240920)"}
        />
        <ReportDAType2Page pageNum={pageNum++} />
        <ReportDAType3Page pageNum={pageNum++} />
        <ReportDAType15Page pageNum={pageNum++} />
        <ReportDAType4Page pageNum={pageNum++} />
        {/* Category 1.영양소 */}
        <ReportDAType5Page
          title={contentDBType.Nutrition.title}
          content={contentDBType.Nutrition.content}
          icon={contentDBType.Nutrition.icon}
          clickName={clickName}
          pageNum={pageNum++}
        />
        {/* 비타민 C 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminCRef}
          dtcDescription={dtcDBDescription.VitaminC}
          dctInfo={reportData.dtc_da.VitaminC}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비타민 D 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminDRef}
          dtcDescription={dtcDBDescription.VitaminD}
          dctInfo={reportData.dtc_da.VitaminD}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비타민 A 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminARef}
          dtcDescription={dtcDBDescription.VitaminA}
          dctInfo={reportData.dtc_da.VitaminA}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비타민 B6 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminB6Ref}
          dtcDescription={dtcDBDescription.VitaminB6}
          dctInfo={reportData.dtc_da.VitaminB6}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비타민 B12 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminB12Ref}
          dtcDescription={dtcDBDescription.VitaminB12}
          dctInfo={reportData.dtc_da.VitaminB12}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비타민 E 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminERef}
          dtcDescription={dtcDBDescription.VitaminE}
          dctInfo={reportData.dtc_da.VitaminE}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비타민 K 농도 */}
        <ReportDAType8Page
          forwardedRef={vitaminKRef}
          dtcDescription={dtcDBDescription.VitaminK}
          dctInfo={reportData.dtc_da.VitaminK}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Omega3 지방산 농도 */}
        <ReportDAType8Page
          forwardedRef={omega3Ref}
          dtcDescription={dtcDBDescription.Omega3}
          dctInfo={reportData.dtc_da.Omega3}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Omega6 지방산 농도 */}
        <ReportDAType8Page
          forwardedRef={omega6Ref}
          dtcDescription={dtcDBDescription.Omega6}
          dctInfo={reportData.dtc_da.Omega6}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 아르기닌 농도 */}
        <ReportDAType8Page
          forwardedRef={arginineRef}
          dtcDescription={dtcDBDescription.Arginine}
          dctInfo={reportData.dtc_da.Arginine}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 칼슘 농도 */}
        <ReportDAType8Page
          forwardedRef={calciumRef}
          dtcDescription={dtcDBDescription.Calcium}
          dctInfo={reportData.dtc_da.Calcium}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 마그네슘 농도 */}
        <ReportDAType8Page
          forwardedRef={magnesiumRef}
          dtcDescription={dtcDBDescription.Magnesium}
          dctInfo={reportData.dtc_da.Magnesium}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 아연 농도 */}
        <ReportDAType8Page
          forwardedRef={zincRef}
          dtcDescription={dtcDBDescription.Zinc}
          dctInfo={reportData.dtc_da.Zinc}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 철 저장 농도 */}
        <ReportDAType8Page
          forwardedRef={ironRef}
          dtcDescription={dtcDBDescription.Iron}
          dctInfo={reportData.dtc_da.Iron}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 타이로신 농도 */}
        <ReportDAType8Page
          forwardedRef={tyrosineRef}
          dtcDescription={dtcDBDescription.Tyrosine}
          dctInfo={reportData.dtc_da.Tyrosine}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 베타인 농도 */}
        <ReportDAType8Page
          forwardedRef={betaineRef}
          dtcDescription={dtcDBDescription.Betaine}
          dctInfo={reportData.dtc_da.Betaine}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 셀레늄 농도 */}
        <ReportDAType8Page
          forwardedRef={seleniumRef}
          dtcDescription={dtcDBDescription.Selenium}
          dctInfo={reportData.dtc_da.Selenium}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 루테인&지아잔틴 농도 */}
        <ReportDAType8Page
          forwardedRef={luteinRef}
          dtcDescription={dtcDBDescription.Lutein}
          dctInfo={reportData.dtc_da.Lutein}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Category 2.건강관리 */}
        <ReportDAType5Page
          title={contentDBType.Health.title}
          content={contentDBType.Health.content}
          icon={contentDBType.Health.icon}
          clickName={clickName}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 비만 */}
        <ReportDAType8Page
          forwardedRef={fatRef}
          dtcDescription={dtcDBDescription.Fat}
          dctInfo={reportData.dtc_da.Fat}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 체지방률 */}
        <ReportDAType8Page
          forwardedRef={bodyFatPerRef}
          dtcDescription={dtcDBDescription.BodyFatPer}
          dctInfo={reportData.dtc_da.BodyFatPer}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 체질량지수 */}
        <ReportDAType8Page
          forwardedRef={bmiRef}
          dtcDescription={dtcDBDescription.Bmi}
          dctInfo={reportData.dtc_da.Bmi}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 복부비만 */}
        <ReportDAType8Page
          forwardedRef={abdominalRef}
          dtcDescription={dtcDBDescription.Abdominal}
          dctInfo={reportData.dtc_da.Abdominal}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 중성지방 농도 */}
        <ReportDAType8Page
          forwardedRef={nFatConcentrationRef}
          dtcDescription={dtcDBDescription.NFatConcentration}
          dctInfo={reportData.dtc_da.NFatConcentration}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* LDL 콜레스테롤 */}
        <ReportDAType8Page
          forwardedRef={LDLRef}
          dtcDescription={dtcDBDescription.LDL}
          dctInfo={reportData.dtc_da.LDL}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* HDL 콜레스테롤 */}
        <ReportDAType8Page
          forwardedRef={HDLRef}
          dtcDescription={dtcDBDescription.HDL}
          dctInfo={reportData.dtc_da.HDL}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 운동에 의한 체중감량효과 */}
        <ReportDAType8Page
          forwardedRef={effectLosingWeightRef}
          dtcDescription={dtcDBDescription.EffectLosingWeight}
          dctInfo={reportData.dtc_da.EffectLosingWeight}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 혈당 */}
        <ReportDAType8Page
          forwardedRef={bloodSugarRef}
          dtcDescription={dtcDBDescription.BloodSugar}
          dctInfo={reportData.dtc_da.BloodSugar}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 혈압 */}
        <ReportDAType8Page
          forwardedRef={bloodPressureRef}
          dtcDescription={dtcDBDescription.BloodPressure}
          dctInfo={reportData.dtc_da.BloodPressure}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 골질량 */}
        <ReportDAType8Page
          forwardedRef={boneMassRef}
          dtcDescription={dtcDBDescription.BoneMass}
          dctInfo={reportData.dtc_da.BoneMass}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 퇴행성관절염증감수성 */}
        <ReportDAType8Page
          forwardedRef={degenerativeRef}
          dtcDescription={dtcDBDescription.Degenerative}
          dctInfo={reportData.dtc_da.Degenerative}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 요산치 */}
        <ReportDAType8Page
          forwardedRef={uricAcidRef}
          dtcDescription={dtcDBDescription.UricAcid}
          dctInfo={reportData.dtc_da.UricAcid}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 멀미 */}
        <ReportDAType8Page
          forwardedRef={travelSicknessRef}
          dtcDescription={dtcDBDescription.TravelSickness}
          dctInfo={reportData.dtc_da.TravelSickness}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Category 3. 개인특성 */}
        <ReportDAType5Page
          title={contentDBType.Personal.title}
          content={contentDBType.Personal.content}
          icon={contentDBType.Personal.icon}
          clickName={clickName}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 알코올의존성 */}
        <ReportDAType8Page
          forwardedRef={alcoholDependenceRef}
          dtcDescription={dtcDBDescription.AlcoholDependence}
          dctInfo={reportData.dtc_da.AlcoholDependence}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 알코올홍조 */}
        <ReportDAType8Page
          forwardedRef={alcoholFlushRef}
          dtcDescription={dtcDBDescription.AlcoholFlush}
          dctInfo={reportData.dtc_da.AlcoholFlush}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 니코틴대사 */}
        <ReportDAType8Page
          forwardedRef={nicotineMetabolismRef}
          dtcDescription={dtcDBDescription.NicotineMetabolism}
          dctInfo={reportData.dtc_da.NicotineMetabolism}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 니코틴의존성 */}
        <ReportDAType8Page
          forwardedRef={nicotineDependenceRef}
          dtcDescription={dtcDBDescription.NicotineDependence}
          dctInfo={reportData.dtc_da.NicotineDependence}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 카페인대사 */}
        <ReportDAType8Page
          forwardedRef={caffeineMetabolismRef}
          dtcDescription={dtcDBDescription.CaffeineMetabolism}
          dctInfo={reportData.dtc_da.CaffeineMetabolism}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 카페인의존성 */}
        <ReportDAType8Page
          forwardedRef={caffeineDependenceRef}
          dtcDescription={dtcDBDescription.CaffeineDependence}
          dctInfo={reportData.dtc_da.CaffeineDependence}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 불면증 */}
        <ReportDAType8Page
          forwardedRef={insomniaRef}
          dtcDescription={dtcDBDescription.Insomnia}
          dctInfo={reportData.dtc_da.Insomnia}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 아침형/저녁형 */}
        <ReportDAType8Page
          forwardedRef={morningPersonRef}
          dtcDescription={dtcDBDescription.MorningPerson}
          dctInfo={reportData.dtc_da.MorningPerson}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Category 4. 피부/모발 */}
        <ReportDAType5Page
          title={contentDBType.Skin.title}
          content={contentDBType.Skin.content}
          icon={contentDBType.Skin.icon}
          clickName={clickName}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 기미/주근깨 */}
        <ReportDAType8Page
          forwardedRef={frecklesRef}
          dtcDescription={dtcDBDescription.Freckles}
          dctInfo={reportData.dtc_da.Freckles}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 색소침착 */}
        <ReportDAType8Page
          forwardedRef={pigmentationRef}
          dtcDescription={dtcDBDescription.Pigmentation}
          dctInfo={reportData.dtc_da.Pigmentation}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 여드름발생 */}
        <ReportDAType8Page
          forwardedRef={acneRef}
          dtcDescription={dtcDBDescription.Acne}
          dctInfo={reportData.dtc_da.Acne}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 피부노화 */}
        <ReportDAType8Page
          forwardedRef={skinAgingRef}
          dtcDescription={dtcDBDescription.SkinAging}
          dctInfo={reportData.dtc_da.SkinAging}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 피부염증 */}
        <ReportDAType8Page
          forwardedRef={skinInflammationRef}
          dtcDescription={dtcDBDescription.SkinInflammation}
          dctInfo={reportData.dtc_da.SkinInflammation}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 태양노출후 태닝반응 */}
        <ReportDAType8Page
          forwardedRef={tanningReactionRef}
          dtcDescription={dtcDBDescription.TanningReaction}
          dctInfo={reportData.dtc_da.TanningReaction}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 새치 */}
        <ReportDAType8Page
          forwardedRef={whiteHairRef}
          dtcDescription={dtcDBDescription.WhiteHair}
          dctInfo={reportData.dtc_da.WhiteHair}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 원형탈모 */}
        <ReportDAType8Page
          forwardedRef={circularHairLossRef}
          dtcDescription={dtcDBDescription.CircularHairLoss}
          dctInfo={reportData.dtc_da.CircularHairLoss}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Category 5. 운동능력 */}
        <ReportDAType5Page
          title={contentDBType.Athletic.title}
          content={contentDBType.Athletic.content}
          icon={contentDBType.Athletic.icon}
          clickName={clickName}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 근력운동적합성 */}
        <ReportDAType8Page
          forwardedRef={muscleFitnessRef}
          dtcDescription={dtcDBDescription.MuscleFitness}
          dctInfo={reportData.dtc_da.MuscleFitness}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 지구력운동적합성 */}
        <ReportDAType8Page
          forwardedRef={exerciseFitnessRef}
          dtcDescription={dtcDBDescription.ExerciseFitness}
          dctInfo={reportData.dtc_da.ExerciseFitness}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 유산소운동적합성 */}
        <ReportDAType8Page
          forwardedRef={aerobicExerciseRef}
          dtcDescription={dtcDBDescription.AerobicExercise}
          dctInfo={reportData.dtc_da.AerobicExercise}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 단거리질주능력 */}
        <ReportDAType8Page
          forwardedRef={shortDistanceRunningRef}
          dtcDescription={dtcDBDescription.ShortDistanceRunning}
          dctInfo={reportData.dtc_da.ShortDistanceRunning}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 운동후회복능력 */}
        <ReportDAType8Page
          forwardedRef={recoverAfterExercisingRef}
          dtcDescription={dtcDBDescription.RecoverAfterExercising}
          dctInfo={reportData.dtc_da.RecoverAfterExercising}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 근육발달능력 */}
        <ReportDAType8Page
          forwardedRef={muscleDevelopmentRef}
          dtcDescription={dtcDBDescription.MuscleDevelopment}
          dctInfo={reportData.dtc_da.MuscleDevelopment}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* Category 6. 식습관 */}
        <ReportDAType5Page
          title={contentDBType.Eating.title}
          content={contentDBType.Eating.content}
          icon={contentDBType.Eating.icon}
          clickName={clickName}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 단맛민감도 */}
        <ReportDAType8Page
          forwardedRef={sensitivitySweetRef}
          dtcDescription={dtcDBDescription.SensitivitySweet}
          dctInfo={reportData.dtc_da.SensitivitySweet}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 쓴맛민감도 */}
        <ReportDAType8Page
          forwardedRef={sensitivityBitterRef}
          dtcDescription={dtcDBDescription.SensitivityBitter}
          dctInfo={reportData.dtc_da.SensitivityBitter}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 짠맛민감도 */}
        <ReportDAType8Page
          forwardedRef={sensitivitySaltyRef}
          dtcDescription={dtcDBDescription.SensitivitySalty}
          dctInfo={reportData.dtc_da.SensitivitySalty}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        {/* 포만감 */}
        <ReportDAType8Page
          forwardedRef={feelingFullRef}
          dtcDescription={dtcDBDescription.FeelingFull}
          dctInfo={reportData.dtc_da.FeelingFull}
          clickDetailName={clickDetailName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Nutrition.title}
          dtcData={[
            reportData.dtc_da.VitaminC,
            reportData.dtc_da.VitaminD,
            reportData.dtc_da.VitaminA,
            reportData.dtc_da.VitaminB6,
          ]}
          content={{
            VitaminC: dtcDBDescription.VitaminC.description,
            VitaminD: dtcDBDescription.VitaminD.description,
            VitaminA: dtcDBDescription.VitaminA.description,
            VitaminB6: dtcDBDescription.VitaminB6.description,
          }}
          refence={{
            VitaminC: dtcDBDescription.VitaminC.reference,
            VitaminD: dtcDBDescription.VitaminD.reference,
            VitaminA: dtcDBDescription.VitaminA.reference,
            VitaminB6: dtcDBDescription.VitaminB6.reference,
          }}
          forwardedRef={[
            vitaminCDetailRef,
            vitaminDDetailRef,
            vitaminADetailRef,
            vitaminB6DetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Nutrition.title}
          dtcData={[
            reportData.dtc_da.VitaminB12,
            reportData.dtc_da.VitaminE,
            reportData.dtc_da.VitaminK,
            reportData.dtc_da.Omega3,
          ]}
          content={{
            VitaminB12: dtcDBDescription.VitaminB12.description,
            VitaminE: dtcDBDescription.VitaminE.description,
            VitaminK: dtcDBDescription.VitaminK.description,
            Omega3: dtcDBDescription.Omega3.description,
          }}
          refence={{
            VitaminB12: dtcDBDescription.VitaminB12.reference,
            VitaminE: dtcDBDescription.VitaminE.reference,
            VitaminK: dtcDBDescription.VitaminK.reference,
            Omega3: dtcDBDescription.Omega3.reference,
          }}
          forwardedRef={[
            vitaminB12DetailRef,
            vitaminEDetailRef,
            vitaminKDetailRef,
            omega3DetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Nutrition.title}
          dtcData={[
            reportData.dtc_da.Omega6,
            reportData.dtc_da.Arginine,
            reportData.dtc_da.Calcium,
          ]}
          content={{
            Omega6: dtcDBDescription.Omega6.description,
            Arginine: dtcDBDescription.Arginine.description,
            Calcium: dtcDBDescription.Calcium.description,
          }}
          refence={{
            Omega6: dtcDBDescription.Omega6.reference,
            Arginine: dtcDBDescription.Arginine.reference,
            Calcium: dtcDBDescription.Calcium.reference,
          }}
          forwardedRef={[omega6DetailRef, arginineDetailRef, calciumDetailRef]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Nutrition.title}
          dtcData={[
            reportData.dtc_da.Magnesium,
            reportData.dtc_da.Zinc,
            reportData.dtc_da.Iron,
            reportData.dtc_da.Tyrosine,
            reportData.dtc_da.Betaine,
            reportData.dtc_da.Selenium,
            reportData.dtc_da.Lutein,
          ]}
          content={{
            Magnesium: dtcDBDescription.Magnesium.description,
            Zinc: dtcDBDescription.Zinc.description,
            Iron: dtcDBDescription.Iron.description,
            Tyrosine: dtcDBDescription.Tyrosine.description,
            Betaine: dtcDBDescription.Betaine.description,
            Selenium: dtcDBDescription.Selenium.description,
            Lutein: dtcDBDescription.Lutein.description,
          }}
          refence={{
            Magnesium: dtcDBDescription.Magnesium.reference,
            Zinc: dtcDBDescription.Zinc.reference,
            Iron: dtcDBDescription.Iron.reference,
            Tyrosine: dtcDBDescription.Tyrosine.reference,
            Betaine: dtcDBDescription.Betaine.reference,
            Selenium: dtcDBDescription.Selenium.reference,
            Lutein: dtcDBDescription.Lutein.reference,
          }}
          forwardedRef={[
            magnesiumDetailRef,
            zincDetailRef,
            ironDetailRef,
            tyrosineDetailRef,
            betaineDetailRef,
            seleniumDetailRef,
            luteinDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Health.title}
          dtcData={[
            reportData.dtc_da.Fat,
            reportData.dtc_da.BodyFatPer,
            reportData.dtc_da.Bmi,
            reportData.dtc_da.Abdominal,
          ]}
          content={{
            Fat: dtcDBDescription.Fat.description,
            BodyFatPer: dtcDBDescription.BodyFatPer.description,
            Bmi: dtcDBDescription.Bmi.description,
            Abdominal: dtcDBDescription.Abdominal.description,
          }}
          refence={{
            Fat: dtcDBDescription.Fat.reference,
            BodyFatPer: dtcDBDescription.BodyFatPer.reference,
            Bmi: dtcDBDescription.Bmi.reference,
            Abdominal: dtcDBDescription.Abdominal.reference,
          }}
          forwardedRef={[
            fatDetailRef,
            bodyFatPerDetailRef,
            bmiDetailRef,
            abdominalDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Health.title}
          dtcData={[
            reportData.dtc_da.NFatConcentration,
            reportData.dtc_da.LDL,
            reportData.dtc_da.HDL,
            reportData.dtc_da.EffectLosingWeight,
            reportData.dtc_da.BloodSugar,
          ]}
          content={{
            NFatConcentration: dtcDBDescription.NFatConcentration.description,
            LDL: dtcDBDescription.LDL.description,
            HDL: dtcDBDescription.HDL.description,
            EffectLosingWeight: dtcDBDescription.EffectLosingWeight.description,
            BloodSugar: dtcDBDescription.BloodSugar.description,
          }}
          refence={{
            NFatConcentration: dtcDBDescription.NFatConcentration.reference,
            LDL: dtcDBDescription.LDL.reference,
            HDL: dtcDBDescription.HDL.reference,
            EffectLosingWeight: dtcDBDescription.EffectLosingWeight.reference,
            BloodSugar: dtcDBDescription.BloodSugar.reference,
          }}
          forwardedRef={[
            nFatConcentrationDetailRef,
            LDLDetailRef,
            HDLDetailRef,
            effectLosingWeightDetailRef,
            bloodSugarDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Health.title}
          dtcData={[
            reportData.dtc_da.BloodPressure,
            reportData.dtc_da.BoneMass,
            reportData.dtc_da.Degenerative,
            reportData.dtc_da.UricAcid,
            reportData.dtc_da.TravelSickness,
          ]}
          content={{
            BloodPressure: dtcDBDescription.BloodPressure.description,
            BoneMass: dtcDBDescription.BoneMass.description,
            Degenerative: dtcDBDescription.Degenerative.description,
            UricAcid: dtcDBDescription.UricAcid.description,
            TravelSickness: dtcDBDescription.TravelSickness.description,
          }}
          refence={{
            BloodPressure: dtcDBDescription.BloodPressure.reference,
            BoneMass: dtcDBDescription.BoneMass.reference,
            Degenerative: dtcDBDescription.Degenerative.reference,
            UricAcid: dtcDBDescription.UricAcid.reference,
            TravelSickness: dtcDBDescription.TravelSickness.reference,
          }}
          forwardedRef={[
            bloodPressureDetailRef,
            boneMassDetailRef,
            degenerativeDetailRef,
            uricAcidDetailRef,
            travelSicknessDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Personal.title}
          dtcData={[
            reportData.dtc_da.AlcoholDependence,
            reportData.dtc_da.AlcoholFlush,
            reportData.dtc_da.NicotineMetabolism,
            reportData.dtc_da.NicotineDependence,
          ]}
          content={{
            AlcoholDependence: dtcDBDescription.AlcoholDependence.description,
            AlcoholFlush: dtcDBDescription.AlcoholFlush.description,
            NicotineMetabolism: dtcDBDescription.NicotineMetabolism.description,
            NicotineDependence: dtcDBDescription.NicotineDependence.description,
          }}
          refence={{
            AlcoholDependence: dtcDBDescription.AlcoholDependence.reference,
            AlcoholFlush: dtcDBDescription.AlcoholFlush.reference,
            NicotineMetabolism: dtcDBDescription.NicotineMetabolism.reference,
            NicotineDependence: dtcDBDescription.NicotineDependence.reference,
          }}
          forwardedRef={[
            alcoholDependenceDetailRef,
            alcoholFlushDetailRef,
            nicotineMetabolismDetailRef,
            nicotineDependenceDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Personal.title}
          dtcData={[
            reportData.dtc_da.CaffeineMetabolism,
            reportData.dtc_da.CaffeineDependence,
            reportData.dtc_da.Insomnia,
            reportData.dtc_da.MorningPerson,
          ]}
          content={{
            CaffeineMetabolism: dtcDBDescription.CaffeineMetabolism.description,
            CaffeineDependence: dtcDBDescription.CaffeineDependence.description,
            Insomnia: dtcDBDescription.Insomnia.description,
            MorningPerson: dtcDBDescription.MorningPerson.description,
          }}
          refence={{
            CaffeineMetabolism: dtcDBDescription.CaffeineMetabolism.reference,
            CaffeineDependence: dtcDBDescription.CaffeineDependence.reference,
            Insomnia: dtcDBDescription.Insomnia.reference,
            MorningPerson: dtcDBDescription.MorningPerson.reference,
          }}
          forwardedRef={[
            caffeineMetabolismDetailRef,
            caffeineDependenceDetailRef,
            insomniaDetailRef,
            morningPersonDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Skin.title}
          dtcData={[
            reportData.dtc_da.Freckles,
            reportData.dtc_da.Pigmentation,
            reportData.dtc_da.Acne,
            reportData.dtc_da.SkinAging,
            reportData.dtc_da.SkinInflammation,
          ]}
          content={{
            Freckles: dtcDBDescription.Freckles.description,
            Pigmentation: dtcDBDescription.Pigmentation.description,
            Acne: dtcDBDescription.Acne.description,
            SkinAging: dtcDBDescription.SkinAging.description,
            SkinInflammation: dtcDBDescription.SkinInflammation.description,
          }}
          refence={{
            Freckles: dtcDBDescription.Freckles.reference,
            Pigmentation: dtcDBDescription.Pigmentation.reference,
            Acne: dtcDBDescription.Acne.reference,
            SkinAging: dtcDBDescription.SkinAging.reference,
            SkinInflammation: dtcDBDescription.SkinInflammation.reference,
          }}
          forwardedRef={[
            frecklesDetailRef,
            pigmentationDetailRef,
            acneDetailRef,
            skinAgingDetailRef,
            skinInflammationDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Skin.title}
          dtcData={[
            reportData.dtc_da.TanningReaction,
            reportData.dtc_da.WhiteHair,
            reportData.dtc_da.CircularHairLoss,
          ]}
          content={{
            TanningReaction: dtcDBDescription.TanningReaction.description,
            WhiteHair: dtcDBDescription.WhiteHair.description,
            CircularHairLoss: dtcDBDescription.CircularHairLoss.description,
          }}
          refence={{
            TanningReaction: dtcDBDescription.TanningReaction.reference,
            WhiteHair: dtcDBDescription.WhiteHair.reference,
            CircularHairLoss: dtcDBDescription.CircularHairLoss.reference,
          }}
          forwardedRef={[
            tanningReactionDetailRef,
            whiteHairDetailRef,
            circularHairLossDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Athletic.title}
          dtcData={[
            reportData.dtc_da.MuscleFitness,
            reportData.dtc_da.ExerciseFitness,
            reportData.dtc_da.AerobicExercise,
            reportData.dtc_da.ShortDistanceRunning,
            reportData.dtc_da.RecoverAfterExercising,
            reportData.dtc_da.MuscleDevelopment,
          ]}
          content={{
            MuscleFitness: dtcDBDescription.MuscleFitness.description,
            ExerciseFitness: dtcDBDescription.ExerciseFitness.description,
            AerobicExercise: dtcDBDescription.AerobicExercise.description,
            ShortDistanceRunning:
              dtcDBDescription.ShortDistanceRunning.description,
            RecoverAfterExercising:
              dtcDBDescription.RecoverAfterExercising.description,
            MuscleDevelopment: dtcDBDescription.MuscleDevelopment.description,
          }}
          refence={{
            MuscleFitness: dtcDBDescription.MuscleFitness.reference,
            ExerciseFitness: dtcDBDescription.ExerciseFitness.reference,
            AerobicExercise: dtcDBDescription.AerobicExercise.reference,
            ShortDistanceRunning:
              dtcDBDescription.ShortDistanceRunning.reference,
            RecoverAfterExercising:
              dtcDBDescription.RecoverAfterExercising.reference,
            MuscleDevelopment: dtcDBDescription.MuscleDevelopment.reference,
          }}
          forwardedRef={[
            muscleFitnessDetailRef,
            exerciseFitnessDetailRef,
            aerobicExerciseDetailRef,
            shortDistanceRunningDetailRef,
            recoverAfterExercisingDetailRef,
            muscleDevelopmentDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType9Page
          reportType={"DB"}
          title={contentDBType.Eating.title}
          dtcData={[
            reportData.dtc_da.SensitivitySweet,
            reportData.dtc_da.SensitivityBitter,
            reportData.dtc_da.SensitivitySalty,
            reportData.dtc_da.FeelingFull,
          ]}
          content={{
            SensitivitySweet: dtcDBDescription.SensitivitySweet.description,
            SensitivityBitter: dtcDBDescription.SensitivityBitter.description,
            SensitivitySalty: dtcDBDescription.SensitivitySalty.description,
            FeelingFull: dtcDBDescription.FeelingFull.description,
          }}
          refence={{
            SensitivitySweet: dtcDBDescription.SensitivitySweet.reference,
            SensitivityBitter: dtcDBDescription.SensitivityBitter.reference,
            SensitivitySalty: dtcDBDescription.SensitivitySalty.reference,
            FeelingFull: dtcDBDescription.FeelingFull.reference,
          }}
          forwardedRef={[
            sensitivitySweetDetailRef,
            sensitivityBitterDetailRef,
            sensitivitySaltyDetailRef,
            feelingFullDetailRef,
          ]}
          clickName={clickName}
          clickReferenceName={clickReferenceName}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.VitaminC.name,
            dtcDBDescription.VitaminD.name,
            dtcDBDescription.VitaminA.name,
            dtcDBDescription.VitaminB6.name,
            dtcDBDescription.VitaminB12.name,
            dtcDBDescription.VitaminE.name,
            dtcDBDescription.VitaminK.name,
          ]}
          referenceInfo={[
            dtcDBDescription.VitaminC.referenceInfo,
            dtcDBDescription.VitaminD.referenceInfo,
            dtcDBDescription.VitaminA.referenceInfo,
            dtcDBDescription.VitaminB6.referenceInfo,
            dtcDBDescription.VitaminB12.referenceInfo,
            dtcDBDescription.VitaminE.referenceInfo,
            dtcDBDescription.VitaminK.referenceInfo,
          ]}
          forwardedRef={[
            vitaminCReferenceRef,
            vitaminDReferenceRef,
            vitaminAReferenceRef,
            vitaminB6ReferenceRef,
            vitaminB12ReferenceRef,
            vitaminEReferenceRef,
            vitaminKReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.Omega3.name,
            dtcDBDescription.Omega6.name,
            dtcDBDescription.Arginine.name,
            dtcDBDescription.Calcium.name,
            dtcDBDescription.Magnesium.name,
            dtcDBDescription.Zinc.name,
            dtcDBDescription.Iron.name,
            dtcDBDescription.Tyrosine.name,
          ]}
          referenceInfo={[
            dtcDBDescription.Omega3.referenceInfo,
            dtcDBDescription.Omega6.referenceInfo,
            dtcDBDescription.Arginine.referenceInfo,
            dtcDBDescription.Calcium.referenceInfo,
            dtcDBDescription.Magnesium.referenceInfo,
            dtcDBDescription.Zinc.referenceInfo,
            dtcDBDescription.Iron.referenceInfo,
            dtcDBDescription.Tyrosine.referenceInfo,
          ]}
          forwardedRef={[
            omega3ReferenceRef,
            omega6ReferenceRef,
            arginineReferenceRef,
            calciumReferenceRef,
            magnesiumReferenceRef,
            zincReferenceRef,
            ironReferenceRef,
            tyrosineReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.Betaine.name,
            dtcDBDescription.Selenium.name,
            dtcDBDescription.Lutein.name,
            dtcDBDescription.Fat.name,
            dtcDBDescription.Degenerative.name,
            dtcDBDescription.BodyFatPer.name,
            dtcDBDescription.Bmi.name,
            dtcDBDescription.Abdominal.name,
          ]}
          referenceInfo={[
            dtcDBDescription.Betaine.referenceInfo,
            dtcDBDescription.Selenium.referenceInfo,
            dtcDBDescription.Lutein.referenceInfo,
            dtcDBDescription.Fat.referenceInfo,
            dtcDBDescription.Degenerative.referenceInfo,
            dtcDBDescription.BodyFatPer.referenceInfo,
            dtcDBDescription.Bmi.referenceInfo,
            dtcDBDescription.Abdominal.referenceInfo,
          ]}
          forwardedRef={[
            betaineReferenceRef,
            seleniumReferenceRef,
            luteinReferenceRef,
            fatReferenceRef,
            degenerativeReferenceRef,
            bodyFatPerReferenceRef,
            bmiReferenceRef,
            abdominalReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.NFatConcentration.name,
            dtcDBDescription.LDL.name,
            dtcDBDescription.HDL.name,
            dtcDBDescription.EffectLosingWeight.name,
            dtcDBDescription.BloodSugar.name,
            dtcDBDescription.BloodPressure.name,
            dtcDBDescription.BoneMass.name,
          ]}
          referenceInfo={[
            dtcDBDescription.NFatConcentration.referenceInfo,
            dtcDBDescription.LDL.referenceInfo,
            dtcDBDescription.HDL.referenceInfo,
            dtcDBDescription.EffectLosingWeight.referenceInfo,
            dtcDBDescription.BloodSugar.referenceInfo,
            dtcDBDescription.BloodPressure.referenceInfo,
            dtcDBDescription.BoneMass.referenceInfo,
          ]}
          forwardedRef={[
            nFatConcentrationReferenceRef,
            LDLReferenceRef,
            HDLReferenceRef,
            effectLosingWeightReferenceRef,
            bloodSugarReferenceRef,
            bloodPressureReferenceRef,
            boneMassReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.UricAcid.name,
            dtcDBDescription.TravelSickness.name,
            dtcDBDescription.AlcoholDependence.name,
            dtcDBDescription.AlcoholFlush.name,
            dtcDBDescription.NicotineMetabolism.name,
            dtcDBDescription.NicotineDependence.name,
            dtcDBDescription.CaffeineMetabolism.name,
            dtcDBDescription.CaffeineDependence.name,
          ]}
          referenceInfo={[
            dtcDBDescription.UricAcid.referenceInfo,
            dtcDBDescription.TravelSickness.referenceInfo,
            dtcDBDescription.AlcoholDependence.referenceInfo,
            dtcDBDescription.AlcoholFlush.referenceInfo,
            dtcDBDescription.NicotineMetabolism.referenceInfo,
            dtcDBDescription.NicotineDependence.referenceInfo,
            dtcDBDescription.CaffeineMetabolism.referenceInfo,
            dtcDBDescription.CaffeineDependence.referenceInfo,
          ]}
          forwardedRef={[
            uricAcidReferenceRef,
            travelSicknessReferenceRef,
            alcoholDependenceReferenceRef,
            alcoholFlushReferenceRef,
            nicotineMetabolismReferenceRef,
            nicotineDependenceReferenceRef,
            caffeineMetabolismReferenceRef,
            caffeineDependenceReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.Insomnia.name,
            dtcDBDescription.MorningPerson.name,
            dtcDBDescription.Freckles.name,
            dtcDBDescription.Pigmentation.name,
            dtcDBDescription.Acne.name,
            dtcDBDescription.SkinAging.name,
            dtcDBDescription.SkinInflammation.name,
            dtcDBDescription.TanningReaction.name,
            dtcDBDescription.WhiteHair.name,
            dtcDBDescription.CircularHairLoss.name,
          ]}
          referenceInfo={[
            dtcDBDescription.Insomnia.referenceInfo,
            dtcDBDescription.MorningPerson.referenceInfo,
            dtcDBDescription.Freckles.referenceInfo,
            dtcDBDescription.Pigmentation.referenceInfo,
            dtcDBDescription.Acne.referenceInfo,
            dtcDBDescription.SkinAging.referenceInfo,
            dtcDBDescription.SkinInflammation.referenceInfo,
            dtcDBDescription.TanningReaction.referenceInfo,
            dtcDBDescription.WhiteHair.referenceInfo,
            dtcDBDescription.CircularHairLoss.referenceInfo,
          ]}
          forwardedRef={[
            insomniaReferenceRef,
            morningPersonReferenceRef,
            frecklesReferenceRef,
            pigmentationReferenceRef,
            acneReferenceRef,
            skinAgingReferenceRef,
            skinInflammationReferenceRef,
            tanningReactionReferenceRef,
            whiteHairReferenceRef,
            circularHairLossReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType13Page
          dtcName={[
            dtcDBDescription.MuscleFitness.name,
            dtcDBDescription.ExerciseFitness.name,
            dtcDBDescription.AerobicExercise.name,
            dtcDBDescription.ShortDistanceRunning.name,
            dtcDBDescription.RecoverAfterExercising.name,
            dtcDBDescription.MuscleDevelopment.name,
            dtcDBDescription.SensitivitySweet.name,
            dtcDBDescription.SensitivityBitter.name,
            dtcDBDescription.SensitivitySalty.name,
            dtcDBDescription.FeelingFull.name,
          ]}
          referenceInfo={[
            dtcDBDescription.MuscleFitness.referenceInfo,
            dtcDBDescription.ExerciseFitness.referenceInfo,
            dtcDBDescription.AerobicExercise.referenceInfo,
            dtcDBDescription.ShortDistanceRunning.referenceInfo,
            dtcDBDescription.RecoverAfterExercising.referenceInfo,
            dtcDBDescription.MuscleDevelopment.referenceInfo,
            dtcDBDescription.SensitivitySweet.referenceInfo,
            dtcDBDescription.SensitivityBitter.referenceInfo,
            dtcDBDescription.SensitivitySalty.referenceInfo,
            dtcDBDescription.FeelingFull.referenceInfo,
          ]}
          forwardedRef={[
            muscleFitnessReferenceRef,
            exerciseFitnessReferenceRef,
            aerobicExerciseReferenceRef,
            shortDistanceRunningReferenceRef,
            recoverAfterExercisingReferenceRef,
            muscleDevelopmentReferenceRef,
            sensitivitySweetReferenceRef,
            sensitivityBitterReferenceRef,
            sensitivitySaltyReferenceRef,
            feelingFullReferenceRef,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType16Page
          solutionReferenceInfo={solutionReferenceInfo.Nutrition}
          pageNum={pageNum++}
        />
        <ReportDAType16Page
          solutionReferenceInfo={solutionReferenceInfo.HealthCare}
          pageNum={pageNum++}
        />
        <ReportDAType16Page
          solutionReferenceInfo={solutionReferenceInfo.PersonalCharacteristics}
          pageNum={pageNum++}
        />
        <ReportDAType16Page
          solutionReferenceInfo={solutionReferenceInfo.SkinHair}
          pageNum={pageNum++}
        />
        <ReportDAType16Page
          solutionReferenceInfo={solutionReferenceInfo.AthleticAbility}
          pageNum={pageNum++}
        />
        <ReportDAType16Page
          solutionReferenceInfo={solutionReferenceInfo.EatingHabits}
          pageNum={pageNum++}
        />
        <ReportDAType12Page
          dtcName={[
            dtcDBDescription.VitaminC.name,
            dtcDBDescription.VitaminD.name,
            dtcDBDescription.VitaminA.name,
            dtcDBDescription.VitaminB6.name,
            dtcDBDescription.VitaminB12.name,
            dtcDBDescription.VitaminE.name,
            dtcDBDescription.VitaminK.name,
            dtcDBDescription.Omega3.name,
            dtcDBDescription.Omega6.name,
            dtcDBDescription.Arginine.name,
          ]}
          raceInfo={[
            dtcDBDescription.VitaminC.raceInfo,
            dtcDBDescription.VitaminD.raceInfo,
            dtcDBDescription.VitaminA.raceInfo,
            dtcDBDescription.VitaminB6.raceInfo,
            dtcDBDescription.VitaminB12.raceInfo,
            dtcDBDescription.VitaminE.raceInfo,
            dtcDBDescription.VitaminK.raceInfo,
            dtcDBDescription.Omega3.raceInfo,
            dtcDBDescription.Omega6.raceInfo,
            dtcDBDescription.Arginine.raceInfo,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType12Page
          dtcName={[
            dtcDBDescription.Calcium.name,
            dtcDBDescription.Magnesium.name,
            dtcDBDescription.Zinc.name,
            dtcDBDescription.Iron.name,
            dtcDBDescription.Tyrosine.name,
            dtcDBDescription.Betaine.name,
            dtcDBDescription.Selenium.name,
            dtcDBDescription.Lutein.name,
            dtcDBDescription.Fat.name,
          ]}
          raceInfo={[
            dtcDBDescription.Calcium.raceInfo,
            dtcDBDescription.Magnesium.raceInfo,
            dtcDBDescription.Zinc.raceInfo,
            dtcDBDescription.Iron.raceInfo,
            dtcDBDescription.Tyrosine.raceInfo,
            dtcDBDescription.Betaine.raceInfo,
            dtcDBDescription.Selenium.raceInfo,
            dtcDBDescription.Lutein.raceInfo,
            dtcDBDescription.Fat.raceInfo,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType12Page
          dtcName={[
            dtcDBDescription.Degenerative.name,
            dtcDBDescription.BodyFatPer.name,
            dtcDBDescription.Bmi.name,
            dtcDBDescription.Abdominal.name,
            dtcDBDescription.NFatConcentration.name,
            dtcDBDescription.LDL.name,
            dtcDBDescription.HDL.name,
            dtcDBDescription.EffectLosingWeight.name,
          ]}
          raceInfo={[
            dtcDBDescription.Degenerative.raceInfo,
            dtcDBDescription.BodyFatPer.raceInfo,
            dtcDBDescription.Bmi.raceInfo,
            dtcDBDescription.Abdominal.raceInfo,
            dtcDBDescription.NFatConcentration.raceInfo,
            dtcDBDescription.LDL.raceInfo,
            dtcDBDescription.HDL.raceInfo,
            dtcDBDescription.EffectLosingWeight.raceInfo,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType12Page
          dtcName={[
            dtcDBDescription.BloodSugar.name,
            dtcDBDescription.BloodPressure.name,
            dtcDBDescription.BoneMass.name,
            dtcDBDescription.UricAcid.name,
            dtcDBDescription.TravelSickness.name,
            dtcDBDescription.AlcoholDependence.name,
            dtcDBDescription.AlcoholFlush.name,
            dtcDBDescription.NicotineMetabolism.name,
            dtcDBDescription.NicotineDependence.name,
          ]}
          raceInfo={[
            dtcDBDescription.BloodSugar.raceInfo,
            dtcDBDescription.BloodPressure.raceInfo,
            dtcDBDescription.BoneMass.raceInfo,
            dtcDBDescription.UricAcid.raceInfo,
            dtcDBDescription.TravelSickness.raceInfo,
            dtcDBDescription.AlcoholDependence.raceInfo,
            dtcDBDescription.AlcoholFlush.raceInfo,
            dtcDBDescription.NicotineMetabolism.raceInfo,
            dtcDBDescription.NicotineDependence.raceInfo,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType12Page
          dtcName={[
            dtcDBDescription.CaffeineMetabolism.name,
            dtcDBDescription.CaffeineDependence.name,
            dtcDBDescription.Insomnia.name,
            dtcDBDescription.MorningPerson.name,
            dtcDBDescription.Freckles.name,
            dtcDBDescription.Pigmentation.name,
            dtcDBDescription.Acne.name,
            dtcDBDescription.SkinAging.name,
            dtcDBDescription.SkinInflammation.name,
            dtcDBDescription.TanningReaction.name,
            dtcDBDescription.WhiteHair.name,
            dtcDBDescription.CircularHairLoss.name,
          ]}
          raceInfo={[
            dtcDBDescription.CaffeineMetabolism.raceInfo,
            dtcDBDescription.CaffeineDependence.raceInfo,
            dtcDBDescription.Insomnia.raceInfo,
            dtcDBDescription.MorningPerson.raceInfo,
            dtcDBDescription.Freckles.raceInfo,
            dtcDBDescription.Pigmentation.raceInfo,
            dtcDBDescription.Acne.raceInfo,
            dtcDBDescription.SkinAging.raceInfo,
            dtcDBDescription.SkinInflammation.raceInfo,
            dtcDBDescription.TanningReaction.raceInfo,
            dtcDBDescription.WhiteHair.raceInfo,
            dtcDBDescription.CircularHairLoss.raceInfo,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType12Page
          dtcName={[
            dtcDBDescription.MuscleFitness.name,
            dtcDBDescription.ExerciseFitness.name,
            dtcDBDescription.AerobicExercise.name,
            dtcDBDescription.ShortDistanceRunning.name,
            dtcDBDescription.RecoverAfterExercising.name,
            dtcDBDescription.MuscleDevelopment.name,
            dtcDBDescription.SensitivitySweet.name,
            dtcDBDescription.SensitivityBitter.name,
            dtcDBDescription.SensitivitySalty.name,
            dtcDBDescription.FeelingFull.name,
          ]}
          raceInfo={[
            dtcDBDescription.MuscleFitness.raceInfo,
            dtcDBDescription.ExerciseFitness.raceInfo,
            dtcDBDescription.AerobicExercise.raceInfo,
            dtcDBDescription.ShortDistanceRunning.raceInfo,
            dtcDBDescription.RecoverAfterExercising.raceInfo,
            dtcDBDescription.MuscleDevelopment.raceInfo,
            dtcDBDescription.SensitivitySweet.raceInfo,
            dtcDBDescription.SensitivityBitter.raceInfo,
            dtcDBDescription.SensitivitySalty.raceInfo,
            dtcDBDescription.FeelingFull.raceInfo,
          ]}
          pageNum={pageNum++}
        />
        <ReportDAType10Page reportType={"DB"} pageNum={pageNum++} />
        <ReportDAType11Page
          reportType={"DB"}
          reportInfo={reportInfo}
          pageNum={pageNum++}
        />
      </div>
      <div>
        <ReportDAType14Page />
      </div>
      <FloatingBtn />
    </>
  );
};

export default ReportDBTypePage;
