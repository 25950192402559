import React from "react";

const TermsOfServicePage = () => {
  return (
    <div id="container" className="container">
      <div className="policy">
        <h2>이용약관</h2>

        <span className="title">제1조(목적) </span>
        <div className="inner">
          <span className="content">
            이 약관은 디엑스앤브이엑스 주식회사(이하 “회사”라 함)가 운영하는
            “지놈체크 플랫폼”(이하 “몰”이라 함)에서 제공하는 인터넷 관련
            서비스를 이용함에 있어 전자상거래 관련 서비스 및 기타 서비스(이하
            “서비스”라 한다)의 이용조건 및 절차와 몰과 이용자의 권리․의무 및
            책임사항을 규정함을 목적으로 합니다.
          </span>
        </div>

        <span className="title">제2조(정의)</span>
        <div className="inner">
          <span className="content">
            ① “몰”이란 회사가 운영하는 앱 또는 웹 기반의 전자상거래몰로
            유전자검사서비스를 판매하고 회원은 검사결과를 조회할 수 있는 가상의
            영업장을 말합니다.
          </span>
          <span className="content">
            ② “유전자검사서비스”는 지놈체크에서 제공하는 DTC 유전자 검사를
            의미하며, 19세 이상의 성인만이 회원으로 이용이 가능합니다.
          </span>
          <span className="content">
            ③ “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를
            받는 회원 및 비회원을 말합니다.
          </span>
          <span className="content">
            ④ ‘회원’이라 함은 제공하는 서비스를 이용하기 위하여 소정의 절차를
            거쳐 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는
            서비스를 이용할 수 있는 자를 말합니다.
          </span>
          <span className="content">
            ⑤ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를
            이용하는 자를 말합니다. 비회원은 구매 기능 외 다른 서비스를 이용할
            수 없습니다.
          </span>
        </div>

        <span className="title">제3조 (약관 등의 명시와 설명 및 개정)</span>
        <div className="inner">
          <span className="content">
            ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
            주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호,
            전자우편주소, 사업자등록번호, 통신판매업 신고번호,
            개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 “몰”의 서비스 내
            혹은 연결화면을 통해 게시합니다. 다만, 약관의 내용은 이용자가
            연결화면을 통하여 볼 수 있도록 할 수 있습니다.
          </span>
          <span className="content">
            ② 본 약관은 “몰”을 통해 서비스를 이용하고자 하는 모든 이용자에게
            적용됩니다.
          </span>
          <span className="content">
            ③ “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중
            청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할
            수 있도록 별도의 연결화면 또는 팝업 화면 등을 제공하여 이용자의
            확인을 구하여야 합니다.
          </span>
          <span className="content">
            ④ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의
            규제에 관한 법률」, 「전자문서 및 전자거래기본법」,
            「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
            정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
            「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을
            개정할 수 있습니다.
          </span>
          <span className="content">
            ⑤ “몰”은 영업상 주요 사유의 발생, 관련 법의 개정 등 사유가 있을 때,
            본 약관을 개정할 수 있으며, 약관을 개정할 때에는 적용 일자 및
            개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용 일자 7일
            이전부터 적용 일자 전일까지 공지합니다. 다만, 회원의 권리, 의무에
            중대한 영향을 주는 변경의 경우에는 최소한 30일 이상의 사전
            유예기간을 두고 공지합니다. 회원은 개정된 약관에 동의하지 않을 경우,
            회원탈퇴 또는 이용계약을 해지할 수 있으며, 개정된 약관 효력
            발생일로부터 7일 이내에 거부의사를 표시하지 아니하고 서비스를 계속
            사용할 경우 개정된 약관에 동의한 것으로 간주합니다.
          </span>
          <span className="content">
            ⑥ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용 일자 이후에
            체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
            개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한
            이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제5항에 의한
            개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는
            개정약관 조항이 적용됩니다.
          </span>
          <span className="content">
            ⑦ “몰”이 이용자에게 약관의 개정을 공지하였음에도 불구하고 이용자가
            이를 알지 못하여 발생하는 피해에 대해서는 회사가 책임을 지지
            않습니다.
          </span>
          <span className="content">
            ⑧ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
            전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한
            법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및
            관계 법령 또는 상관례에 따릅니다.
          </span>
        </div>

        <span className="title">제4조(서비스의 제공 및 변경)</span>
        <div className="inner">
          <span className="content">
            ① “몰”은 다음과 같은 업무를 수행합니다.
            <br />
            <br />
            1. 판매하는 상품 및 서비스의 정보 및 가격 제공 <br />
            2. 전자 상거래 및 이에 수반되는 서비스 <br />
            3. 기타 “몰”이 정하는 업무
            <br />
            <br />
          </span>
          <span className="content">
            ② “몰”은 상품 또는 서비스의 품절 또는 기술적 사양의 변경 등의
            경우에는 장차 체결되는 계약으로 제공할 상품 또는 서비스의 내용을
            변경할 수 있습니다. 이 경우에는 변경된 상품 또는 서비스의 내용 및
            제공일자를 명시하여 현재의 상품 또는 서비스의 내용을 게시한 곳에
            즉시 공지합니다.
          </span>
          <span className="content">
            ③ “몰” 제공하기로 이용자와 계약을 체결한 서비스의 내용을 상품 등의
            품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를
            이용자에게 통지 가능한 방법으로 즉시 통지합니다.
          </span>
          <span className="content">
            ④ 전항의 경우 회사는 이로 인하여 이용자가 입은 손해를 배상합니다.
            다만, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
            아니합니다.
          </span>
        </div>

        <span className="title">제5조(정보의 제공 및 광고의 게재)</span>
        <div className="inner">
          <span className="content">
            ① “몰”은 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를
            공지사항이나, 문자 등의 방법으로 회원에게 제공할 수 있습니다. 다만,
            회사는 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을
            제외한 정보에 대하여는 회원의 동의를 받아 제공할 수 있습니다.
          </span>
          <span className="content">
            ② “몰”은 서비스의 운영과 관련하여 서비스 화면, 문자 등에 광고를
            게재할 수 있습니다. 다만, 이는 광고 수신에 동의한 회원에 한합니다.
          </span>
          <span className="content">
            ③ 이용자는 “몰”이 제공하는 서비스와 관련하여 게시물 또는 기타 정보를
            변경, 수정, 제한하는 등의 행위를 하지 않습니다.
          </span>
        </div>

        <span className="title">제6조(서비스 기간 및 서비스의 중단 등)</span>
        <div className="inner">
          <span className="content">
            ① 본 약관에 따른 서비스 기간은 서비스 신청일로부터 이용계약의 해지
            시까지입니다.
          </span>
          <span className="content">
            ② “몰”은 업무상 또는 기술상 특별한 사정이 없는 한 연중무휴, 1일
            24시간 서비스를 제공합니다. 단 시스템 정기점검 및 교체, 통신의 두절,
            시스템 장애 또는 운영상의 상당한 사유가 있는 경우 “몰”이 정한 날이나
            시간에 서비스를 일시 중단할 수 있으며 예정된 작업으로 인한 서비스
            일시 중단은 “몰” 초기화면에 사전에 공지합니다.
          </span>
          <span className="content">
            ③ “몰”은 다음 각호와 같은 사유로 이용자에게 사전 통지나 제한없이 본
            서비스의 제공을 일시적 혹은 영구적으로 중단할 수 있습니다.
            <br />
            <br />
            1. 본 서비스와 관련된 컴퓨터 시스템의 점검 또는 정기적 또는 긴급한
            보안 작업을 실시할 경우
            <br />
            2. 통신회선, 통신수단, 컴퓨터 등의 장애로 인해 정지한 경우
            <br />
            3. 화재, 정전, 천재지변, 전쟁, 테러 행위 등 불가항력의 사유로 인하여
            본 서비스를 운영할 수 없는 경우
            <br />
            4. 해킹이나 이와 준하는 기타의 방법으로 회사의 자산을 도난당한 경우
            <br />
            5. 본 서비스를 제공하는데 필요한 시스템의 이상이 발생한 경우
            <br />
            6. 계정의 부정 사용 등에 대한 조사를 수행할 경우
            <br />
            7. 수사기관 등으로부터 본 서비스의 이용이 사기 등 범죄행위에
            이용되었다는 정보의 제공이 있거나 기타 사정을 감안하여 범죄행위가
            행해진 혐의가 있는 경우
            <br />
            8. 그 외 회사가 중지할 필요가 있다고 판단하는 경우
            <br />
            <br />
          </span>
          <span className="content">
            ④ “몰”은 전항의 사유로 본 서비스의 제공이 일시적으로 중단됨으로
            인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 회사의
            고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
          </span>

          <span className="content">
            ⑤ 이용자가 다음 각호에 해당하는 경우, “몰”은 이용자에 대한 “몰”의
            판단에 따라 본 서비스를 제공하지 않거나 중지할 수 있습니다.
            <br />
            <br />
            1. 의료목적으로 본 서비스를 이용한 경우 <br />
            2. 본 서비스 제공을 위해 반드시 필요한 동의를 하지 않은 경우 <br />
            3. 타인의 명의 사용 등 본인의 실명으로 신청하지 않은 경우 <br />
            4. 19세 미만에 해당하는 미성년자가 법정대리인의 동의없이 본 서비스를
            신청한 경우 <br />
            5. 사회의 질서 또는 공서양속을 저해할 목적으로 신청한 경우 <br />
            6. 본 약관을 위반하거나 그 외 본 서비스를 제공하기 곤란하다고 회사가
            판단하는 경우 <br />
            <br />
          </span>

          <span className="content">
            ⑥ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를
            제공할 수 없게 되는 경우에는, “몰”은 본 약관상 규정한 방법으로
            이용자에게 통지하고 당초 회사에서 제시한 조건에 따라 소비자에게
            보상합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는 이용
          </span>
        </div>

        <span className="title">제7조(회원가입 및 이용계약의 성립)</span>
        <div className="inner">
          <span className="content">
            ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이
            약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다. “몰”은
            이러한 신청에 대하여 승낙함으로써 이용계약이 체결됩니다. 회원으로
            등록하여 “몰”이 제공하는 서비스를 이용하고자 하는 이용자는 “몰”에서
            요청하는 제반정보(ID,비밀번호, 주소등) 및 기타 “몰”에서 요청하는
            개인 신상정보를 제공해야 합니다.
          </span>
          <span className="content">
            ② 회원 가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제
            데이터인 것으로 간주됩니다. 타인의 정보를 도용하거나 허위의 정보를
            등록하는 경우 “몰”의 서비스 이용과 관련하여 아무런 권리를 주장할 수
            없으며, 관계 법령에 따라 처벌 받을 수 있습니다.
          </span>
          <span className="content">
            ③ “몰”은 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로
            합니다. 다만 다음 각호에 해당하는 경우에는 승낙을 하지 않거나 사후에
            이용계약을 해지할 수 있습니다.
            <br />
            <br />
            1. 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는
            경우, 다만 “몰”의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.
            <br />
            2. 등록 내용에 허위, 기재누락, 오기가 있는 경우 또는 필수항목을
            기재하지 않는 경우 <br />
            3. 타인의 서비스 이용을 방해하거나 정보를 도용하는 등
            전자상거래질서를 위협하는 경우 <br />
            4. 미성년자가 가입하는 경우 <br />
            5. 다른 사람의 명의를 도용하여 신청한 경우 <br />
            6. 본인의 실명으로 신청하지 않은 경우
            <br />
            7. 같은 이용자가 다른 ID로 이중 등록을 한 경우
            <br />
            8. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고
            판단되는 경우
            <br />
            <br />
          </span>
          <span className="content">
            ④ “몰”은 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가
            있는 경우 또는 기타 필요한 경우 승낙을 유보할 수 있고 승낙을 유보한
            경우 가입신청자에게 이를 알립니다.
          </span>

          <span className="content">
            ⑤ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게 도달한 시점으로
            합니다.
          </span>

          <span className="content">
            ⑥ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간
            이내에 “몰”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을
            알려야 합니다. 변경하지 않음으로서 발생하는 모든 문제의 책임은
            회원에게 있습니다.
          </span>
        </div>

        <span className="title">제8조(회원 탈퇴 및 이용계약의 해지 등)</span>
        <div className="inner">
          <span className="content">
            ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시
            회원탈퇴를 처리합니다. 탈퇴로 인하여 발생하는 불이익에 대한 책임은
            회원 본인이 부담합니다.
          </span>
          <span className="content">
            ② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은 회원자격을 제한
            및 정지시킬 수 있습니다.
            <br />
            <br />
            1. 가입 신청 시에 허위 내용을 등록한 경우 <br /> 2. “몰”을 이용하여
            구입한 재화 등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는
            채무를 기일에 지급하지 않는 경우 <br /> 3. 다른 사람의 “몰” 이용을
            방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
            <br /> 4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
            반하는 행위를 하는 경우 <br /> 5. 회원이 사망한 경우 <br /> 6.
            “몰”의 정상적인 영업활동 및 서비스 운영을 고의로 방해하는 경우
            <br /> 7. 그 외 본 서비스의 이용이 적절하지 않다고 판단하는 경우
            <br />
            <br />
          </span>
          <span className="content">
            ③ “몰”이 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상
            반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “몰”은
            회원자격을 상실시킬 수 있습니다.
          </span>

          <span className="content">
            ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
            경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의
            기간을 정하여 소명할 기회를 부여합니다.
          </span>
        </div>

        <span className="title">제9조(회원에 대한 조치등)</span>
        <div className="inner">
          <span className="content">
            ① “몰”은 본 약관, 관련법령 또는 상거래의 일반원칙을 위반한 회원에
            대하여 다음과 같은 조치를 취할 수 있습니다.
            <br />
            <br />
            1. 회사가 부차적으로 제공한 혜택의 일부 또는 전부의 회수
            <br />
            2. 특정서비스의 제한 <br />
            3. 이용계약의 해지 <br />
            4. 손해배상의 청구
            <br />
            <br />
          </span>
          <span className="content">
            ② “몰”이 회원에게 조치를 취할 경우, “몰”은 회원에게 문자, 이메일
            혹은 서면으로 통보할 수 있고, 회원의 연락이 두절이 되거나 긴급을
            요하는 것과 같이 부득이한 경우는 선 조치 후 통보할 수 있습니다.
          </span>

          <span className="content">
            ③ 회원은 “몰”의 조치에 대해서 항변의 사유가 있는 경우, 이에 대하여
            항변할 수 있습니다.
          </span>
        </div>

        <span className="title">제10조(회원에 대한 통지)</span>
        <div className="inner">
          <span className="content">
            ① “몰”이 회원에게 통지하는 경우, 회원이 “몰”과 미리 약정하여 지정한
            전자우편 주소로 할 수 있습니다.
          </span>
          <span className="content">
            ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일 이상 “홈페이지”에
            게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의
            거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를
            합니다.
          </span>
        </div>

        <span className="title">제11조(금지행위)</span>
        <div className="inner">
          <span className="content">
            ① “몰”의 정상적인 운영을 고의적으로 방해하는 행위로 인하여 손해가
            발생한 경우 회원을 그 손해를 배상해야 합니다.
          </span>
          <span className="content">
            ② 이용자는 “몰”과의 정당한 계약 또는 동의없이 재화 또는 용역을
            재판매할 수 없고 이를 이용하여 영업활동을 할 수가 없습니다. 이용자의
            본 약관을 위반한 영업활동으로 인하여 발생하는 결과에 대해서 “몰”은
            책임지지 않습니다.
          </span>
          <span className="content">
            ③ 이용자가 비정상적인 다수의 거래를 하고 “몰”에서 이를 정상적이지
            않은 거래로 확인될 경우 거래를 취소하거나 거래 중지 등의 재재 조치를
            취할 수 있습니다.
          </span>
          <span className="content">
            ④ 이용자가 본 조에서 정한 내용을 위반한 경우 “몰”에 손해배상 의무를
            집니다.
          </span>
        </div>
        <span className="title">제12조(유전자검사서비스 결과의 제공)</span>
        <div className="inner">
          <span className="content">
            ① 검사서비스 소요기간이라 함은 “몰”에 유전자 검사서비스를 위한
            회원의 검체가 도착한 시간부터 회원에게 결과를 확인할 수 있는
            문자정보를 제공한 시점까지로 정의한다.
          </span>
          <span className="content">
            ② 검사서비스 지연사유가 발생할 시, “몰”은 회원에게 해당 사실을
            통보한다.
          </span>
        </div>

        <span className="title">
          제13조(유전자검사서비스 대상 샘플의 목적 외 이용)
        </span>
        <div className="inner">
          <span className="content">
            유전자검사를 위해 회사에 제공된 회원의 검체 관련 정보는 이용자의
            동의없이 유전자검사 외의 목적으로 이용되거나 제3자에게 제공될 수
            없으며, 이에 대한 책임은 “몰”에 있습니다. 다만 회원으로부터 별도의
            동의를 받는 경우에 한하여 회원이 제공한 검체를 사용하거나 제3자에게
            제공될 수 있습니다.
          </span>
        </div>

        <span className="title">
          제14조(유전자검사서비스 제공 관련 기타 사항)
        </span>
        <div className="inner">
          <span className="content">
            유전자검사서비스와 관련하여 본 약관에 규정되지 않은 기타 사항은
            “몰”에 게시한 자료 및 “몰”의 내부 규정, 정책 및 관계법령에 따른다.
          </span>
        </div>

        <span className="title">제15조(청약 철회 등)</span>
        <div className="inner">
          <span className="content">
            ① “몰”에서 유전자검사서비스를 신청한 이용자는 「전자상거래 등에서의
            소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을
            받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진
            경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을
            말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만,
            청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에
            달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
          </span>
          <span className="content">
            ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는
            경우에는 반품 및 교환을 할 수 없습니다.
            <br />
            <br />
            1. 택배 비용이 발생한 경우 <br /> 2. 이용자의 책임 있는 사유로 본
            서비스 이용을 위해 제공한 제품이 멸실 또는 훼손된 경우 (유전자
            검사를 위한 검체채취키트 특성 상 포장 비닐을 제거한 경우 키트가
            오염된 것으로 보아 훼손으로 간주) <br /> 3. 이용자의 사용 또는 일부
            소비에 의하여 재화 등의 가치가 현저히 감소한 경우 <br /> 4. 시간의
            경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한
            경우
            <br />
            <br />
          </span>
          <span className="content">
            ③ 이용자의 검체를 수령하여 검사과정이 진행중일 경우에는 환급이
            불가합니다.
          </span>

          <span className="content">
            ④ 검사과정이 진행중일 경우, 이용자의 철회 요청에 따라서 “몰”은
            이용자의 요청을 확인 후에 검사를 중단하고 개인정보를 삭제한다.
          </span>
        </div>

        <span className="title">16조(청약 철회 등의 효과)</span>
        <div className="inner">
          <span className="content">
            ① 청약 철회 등의 경우 이용자가 공급받은 본 서비스의 반환에 필요한
            비용은 이용자가 부담합니다. “몰”은 이용자에게 청약철회 등을 이유로
            위약금 또는 손해배상을 청구하지 않습니다. 다만, 재화 등의 내용이
            표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을
            하는 경우 재화 등의 반환에 필요한 비용은 “몰”이 부담합니다.
          </span>
          <span className="content">
            ② “몰”은 이용자의 청약 철회 등을 통한 서비스의 반환 요청 시 본
            서비스를 신청함으로써 이용자에게 민법 상 성년지급된 포인트 등 (각종
            쿠폰 포함)의 혜택이 있는 경우 이를 회수할 수 있습니다.
          </span>
        </div>

        <span className="title">제17조(개인정보보호)</span>
        <div className="inner">
          <span className="content">
            ① “몰”은 이용자의 개인정보 수집 시 서비스제공을 위하여 필요한
            범위에서 최소한의 개인정보를 수집합니다.
          </span>
          <span className="content">
            ② “몰”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그
            목적을 고지하고 동의를 받습니다.
          </span>
          <span className="content">
            ③ “몰”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운
            이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는
            이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를
            받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
          </span>
          <span className="content">
            ④ “몰”이 제2항에 의해 이용자의 동의를 받아야 하는 경우에는
            개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처),
            정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
            관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
            「개인정보보호법」 등 관련 규정한 사항을 미리 명시하거나 고지해야
            하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
          </span>
          <span className="content">
            ⑤ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람
            및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체 없이 필요한
            조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는
            “몰”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
          </span>
          <span className="content">
            ⑥ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를
            최소한으로 제한하여야 하며 회원의 개인정보의 분실, 도난, 유출, 동의
            없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을
            집니다.
          </span>
          <span className="content">
            ⑦ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적
            또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이
            파기합니다.
          </span>
          <span className="content">
            ⑧ “몰”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한
            것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한
            이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고,
            필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의
            거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지
            않습니다.
          </span>
          <span className="content">
            ⑨ “몰”은 이용자의 개인정보를 안전하게 처리하기 위하여 개인정보
            보호에 관한 사항을 정한 “개인정보처리방침”을 수립하고 게시합니다.
            “개인정보처리방침”은 “몰”에서 제공하는 서비스 범위내에서 적용됩니다.
          </span>
        </div>

        <span className="title">제18조(“몰“의 의무)</span>
        <div className="inner">
          <span className="content">
            ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지
            않으며, 시스템 점검 및 서비스 개발, 통신장애, 기타 불가항력적인
            사고등 특별한 사정이 없는 한, 본 약관이 정하는 바에 따라 지속적이고,
            안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
          </span>
          <span className="content">
            ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의
            개인정보보호를 위한 보안 시스템을 갖추어야 합니다.
          </span>
          <span className="content">
            ③ “몰은 서비스 제공과 관련하여 취득한 회원의 개인정보를 회원의
            동의없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스관련 업무
            이외의 상업적 목적으로 사용할 수 없습니다. 단 다음 각호에 해당하는
            경우는 예외입니다.
            <br />
            <br />
            1. 전기통신기본법등 법률의 규정에 의해 국가기관의 요구가 있는 경우,
            <br />
            2. 범죄에 대한 수사상의 목적이 있거나 방송통신심의위원회의 요청이
            있는 경우
            <br />
            3. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
            <br />
            <br />
          </span>
          <span className="content">
            ④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지
            않습니다.
          </span>
          <span className="content">
            ⑤ “몰”은 정보통신망 이용 촉진 및 정보보호 등에 관한 법률,
            통신비밀보호법등의 서비스 운영, 유지와 관련 있는 법규를 준수합니다.
          </span>
        </div>

        <span className="title">제19조(회원의 ID 및 비밀번호에 대한 의무)</span>
        <div className="inner">
          <span className="content">
            ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게
            있습니다.
          </span>
          <span className="content">
            ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
            회원의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은
            전적으로 회원에게 있습니다.
          </span>
          <span className="content">
            ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
            있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는
            경우에는 그에 따라야 합니다.
          </span>
          <span className="content">
            ④ “몰”의 귀책사유 없이 ID 및 비밀번호의 유출, 양도, 대여로 인하여
            발생하는 손실이나 손해에 대해서는 이용자 본인이 그에 대한 책임을
            부담합니다.
          </span>
        </div>

        <span className="title">제20조(이용자의 의무)</span>
        <div className="inner">
          <span className="content">
            ① 이용자는 본 약관에서 규정하는 사항과 “몰”에서 정한 제반 규정,
            공지사항 및 운영 정책 등 공지하는 사항과 관계 법령을 준수하여야
            합니다.
          </span>
          <span className="content">
            ② 이용자는 다음 각 호의 행위를 하여서는 안됩니다.
            <br />
            <br />
            1. 가입신청 또는 정보 변경 시 허위내용을 등록하거나 타인의
            개인정보를 무단으로 이용하는 행위
            <br />
            2. “몰”에 게시된 정보를 무단으로 변경, 편집, 수정, 복제하는 행위
            <br />
            3. 서비스와 관련하여 얻은 정보를 “몰”의 사전 승낙 없이 이용자의
            서비스 이용 이외의 목적으로 복제하거나 변경하여 출판 및 방송에
            사용하거나 타인에게 제공하는 행위.
            <br />
            4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는
            게시
            <br />
            5. “몰” 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
            <br />
            6. “몰” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            <br />
            7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
            정보를 몰에 공개 또는 게시하는 행위
            <br />
            8. 범죄와 결부된다고 객관적으로 판단되는 행위
            <br />
            9. 불특정 다수의 자를 대상으로 “몰”의 서비스를 이용하여 영리목적으로
            활동하는 행위
            <br />
            10. “몰”의 직원 또는 관계자를 사칭하는 행위
            <br />
            11. “몰”의 업무를 방해하는 행위
            <br />
            <br />
          </span>
        </div>

        <span className="title">제21조(저작권의 귀속 및 이용제한)</span>
        <div className="inner">
          <span className="content">
            ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에
            귀속합니다.
          </span>
          <span className="content">
            ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이
            귀속된 정보를 “몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송
            기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
            하여서는 안됩니다.
          </span>
          <span className="content">
            ③ 회원이 창작하여 “몰”외의 다른 플랫폼에 게재 또는 등록한 게시물에
            대한 저작권은 회원 본인에게 있으면 해당 게시물이 타인의 지적
            재산권을 침해하여 발생하는 모든 책임은 회원 본인에게 있습니다.
          </span>
        </div>

        <span className="title">제22조(손해배상 및 면책조항)</span>
        <div className="inner">
          <span className="content">
            ① “몰”과 회원은 본 약관을 위반함으로써 발생하는 모든 책임을 각자
            부담하여, 이로 인하여 상대방에 손해를 입힌 경우에는 지체없이 그
            손해를 배상하여야 합니다.
          </span>
          <span className="content">
            ② “몰”은 회원이 “몰”에 제공하는 서비스 제공으로부터 이익을 얻지
            못했거나 취사선택에 따른 이용으로 발생하는 손해등에 대해서는 책임이
            면제됩니다.
          </span>
          <span className="content">
            ③ “몰”과 이용자 또는 제휴사의 귀책사유로 인한 서비스 이용의 장애에
            대하여 책임을 지지 않습니다. 또한 이용자가 제휴사 또는 다른 이용자가
            게재한 정보, 자료, 사실의 정확성을 신뢰함으로써 입은 손해에 대해여
            책임을 지지 않습니다.
          </span>
          <span className="content">
            ④ “몰”은 회원이 자산의 개인정보를 타인에게 유출 또는 제공함으로써,
            발생하는 피해에 대해서는 일체의 책임을 지지 않습니다.
          </span>
          <span className="content">
            ⑤ “몰”은 회원이 저장, 게시 또는 전송한 자료와 관련하여 일체의 책임을
            지지 않습니다.
          </span>
          <span className="content">
            ⑥ “몰”과 회원의 귀책사유나 제3자의 고의 또는 과실로 인하여 서비스
            이용의 장애가 발생하거나 회원의 데이터가 훼손된 경우에는 책임을 지지
            않습니다.
          </span>
          <span className="content">
            ⑦ “몰”은 회원이 상호간 혹은 제3자 상호간 서비스를 매개로 거래를
            하거나 발생하는 일체의 활동(데이터 전송, 커뮤니티 활동 포함)으로
            발생하는 손해를 책임지거나 분쟁에 개입하지 않습니다.
          </span>
          <span className="content">
            ⑧ “몰”은 서버의 설비, 점검, 보수 , 교체 과정 또는 운영과정에서 고의
            또는 고의에 준하는 중대한 과실 없이 발생할 수 있는 시스템의 장애,
            제3자의 공격으로 인한 시스템의 장애, 국내외의 저명한 연구기관이나
            보안관련 업체에 의해 대응방법이 개발되지 아니한 컴퓨터 바이러스등의
            유포나 기타 “몰”에서 통제할 수 없는 불가항력적인 사유로 인한 회원의
            손해에 대하여 책임지지 않습니다.
          </span>
          <span className="content">
            ⑨ “몰”은 이용자가 제공한 정보에 대한 정확성, 유효성, 진위여부 확인에
            대한 책임을 지지 않으며 이용자가 제공한 정보의 오류로 인하여 발생한
            손해에 대하여 책임을 지지 않습니다. 또한, “몰”은 이용자에게 제공되는
            정보의 정확성, 유용성, 완전성, 이용자의 기대효과 등에 대해 일체
            보증하지 않습니다. 특히 유전자 검사결과는 검사시점의 연구성과를
            바탕으로 제공되며 향후 연구성과의 진전이 있는 등의 사유로 “몰”과
            회사에 의해 제공되는 서비스의 결과가 변경될 수 있습니다.
          </span>
        </div>

        <span className="title">제23조(재판권 및 준거법, 관할법원)</span>
        <div className="inner">
          <span className="content">
            ① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소
            당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는
            지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는
            거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의
            관할법원에 제기합니다.
          </span>
          <span className="content">
            ② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
          </span>
        </div>

        <span className="title">제24조(기타사항)</span>
        <div className="inner">
          <span className="content">
            ① 회사는 필요한 경우, “몰”에서 사전에 공지한 후, 특정 서비스(혹은 그
            일부)를 일시적 또는 영구적으로 중단할 수 있습니다.
          </span>
          <span className="content">
            ② 회사의 불가피한 사정으로 “몰”의 운영이 중단하게 될 경우, 회원에게
            사전 공지를 하고 게시물의 이전이 쉽도록 모든 조치를 하기 위해
            노력합니다.
          </span>
        </div>

        <span className="title">부칙. </span>
        <span className="title">이 약관은 2024년 5월 20일부터 적용됩니다.</span>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
