import React from "react";
import DaumPostcode from "react-daum-postcode";

function SearchAddress(props) {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    props.setAddr({
      user_post: data.zonecode,
      user_addr1: fullAddress,
    });
    props.searchAddr();
  };

  return (
    <div className="dialog-wrap">
      <div className="dialog-overlay" />
      <div className="dialog-box">
        <div className="btn-right">
          <button
            type="button"
            className="button-primary size-s"
            onClick={props.searchAddr}
          >
            <span>X</span>
          </button>
        </div>
        <DaumPostcode onComplete={handleComplete} {...props} />
      </div>
    </div>
  );
}

export default SearchAddress;

SearchAddress.defaultProps = {
  style: {
    width: "100%",
    height: "100%",
  },
};
