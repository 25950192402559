import React from "react";
import "assets/css/report.css";

const ReportDAType10Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page11">
        <h2 className="grui-title border">유전자 검사 안내</h2>
        <section>
          <h3 className="grui-title size2">1. 검사 결과의 한계</h3>
          <p>
            {props.reportType === "DA" ? (
              <>
                1. 본 검사는 영양, 생활습관 및 신체적 특징에 따른 질병의 예방을
                위한 유전자검사 및 유전적 혈통을 찾기 위한 유전자검사로, 개인의
                건강 및 특성 등에 대한 정보를 제공하고 이를 바탕으로 건강 상태나
                개인의 특성 등을 추정하는 검사이기에 DTC 유전자 검사 결과를
                바탕으로 특정 건강 상태나 질병을 진단 또는 치료를 위한 활용은
                불가합니다.
              </>
            ) : (
              <>
                1. 본 검사는 영양, 생활습관 및 신체적 특징에 따른 질병의 예방을
                위한 유전자검사로, 개인의 건강 및 특성 등에 대한 정보를 제공하고
                이를 바탕으로 건강 상태나 개인의 특성 등을 추정하는 검사이기에
                DTC 유전자 검사 결과를 바탕으로 특정 건강 상태나 질병을 진단
                또는 치료를 위한 활용은 불가합니다.
              </>
            )}
            <br />
            2. DTC 유전자검사는 건강이나 개인의 특성 등 웰니스 항목과 관련된
            유전자 변이 분석을 시행하지만, 관련 유전자를 모두 분석한 결과가
            아닙니다.
            <br />
            3. 개인의 건강 및 개인 특성 등에 영향을 미치는 환경적 요인이나 생활
            방식 (예: 식이요법 및 운동 등)을 포함한 유전 외적 요인들은 결과
            해석에 포함되지 않습니다.
            <br />
            4. 유전자 변이와 표현형의 뚜렷한 상관관계가 명확히 밝혀지지 않은
            경우도 포함되어 있을 수 있습니다.
            <br />
            5. 향후 결과와 해석은 새로운 정보가 추가됨에 따라 변경될 수
            있습니다.
            <br />
            6. 검사에 사용된 유전자 종류, 데이터베이스 등의 변화에 따라 다른
            유전자 검사기관의 결과와 해석이 다를 수 있습니다.
          </p>
        </section>
        <section>
          <h3 className="grui-title size2">
            2. 유전정보 및 개인정보에 대한 보호 및 관리 방안
          </h3>
          <p>
            1. 디엑스앤브이엑스㈜는 개인정보보호법, 동법 시행령 및 관련 고시,
            표준 개인정보보호지침에서 정의된 바를 준수하고 고객의 개인 정보를
            분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전하게 관리하고
            있습니다.
            <br />
            2. 고객의 유전정보를 포함한 개인 정보는 본 검사 목적 외에 사용하지
            않습니다.
            <br />
            3. 고객의 개인식별정보를 고유식별번호로 대체하고 해당 연결 파일에
            대한 암호키를 보안책임자가 가지고 있는 방식으로 익명화되며 보관 기간
            동안 유지됩니다.
            <br />
            4. 유전자검사기관의 폐업 등 검사대상물 등을 보존 할 수 없는 경우에는
            검사대상물 및 검사 동의서, 개인정보가 포함된 유전 정보에 관한
            기록물과 그 관리대장은 법에서 정한 절차에 따라 폐기됩니다. 다만,
            보존 중에 검사대상자가 다른 검사기관이나 질병관리청으로 이관을
            요청하는 경우 이관할 수 있습니다.
            <br />
            5. 유전자 검사 동의서에 따라 시행된 유전자검사는 10년간 동의서와
            함께 보존되며, 법 제52조 제2항에 따라 본인이나 법정대리인이 요청하는
            경우 열람할 수 있습니다.
            <br />
            6. 유전자 검사 동의서 외에 얻어진 유전 정보는 즉시 폐기 됩니다.
            <br />
            7. 당사 플랫폼은 최첨단 암호화 방법을 사용하여 무단 액세스로부터
            정보를 보호하고 있습니다. <br />
            8. 전담 보안 전문가가 시스템을 지속적으로 모니터링 하여 데이터에
            대한 최고 수준의 보호를 보장하고 있습니다. <br />
            9. 데이터에 접근하기 위한 엄격한 프로토콜을 갖추고 있으므로 권한이
            있는 개인만 테스트 결과를 볼 수 있습니다. <br />
            10. 유전정보 및 개인정보 처리에 동의 관련 사항의 변경 또는 철회 등을
            요청할 수 있으며, 이 관하여 궁금한 사항이 있거나 기타 문의는
            dtc@dxvx.com 으로 연락바랍니다.
          </p>
        </section>
        <section>
          <h3 className="grui-title size2">3. 주의사항</h3>
          <p>
            1. 본 검사 결과지는 대상자 본인에게만 제공되며, 본인 외의 자가
            결과지에 포함한 정보의 전부 또는 일부를 제3자에게 공개, 배포
            복사하는 등의 행위를 엄격히 금지하고 있습니다. <br />
            2. 본 검사 결과지가 검사 대상자 본인(지정된 수신인)이 아닌 경우
            발신인(검사 기관)에 고지해 주시기 바랍니다. <br />
            3. 생명윤리법 제46조에 따라 본 검사를 교육 승진 보험 등 사회활동에
            있어 차별의 근거로 사용할 수 없으며, 다른 법률에 특별한 규정이 있는
            경우를 제외하고는 누구든지 타인에게 유전자 검사를 받도록 강요하거나
            유전자 검사 결과를 제출하도록 강요할 수 없습니다. <br />
            4. 유전자검사 결과 등 유전정보를 근거로 타인을 차별한 자, 검사 실시
            또는 결과 제출을 강요한 자 또는 검사대상자 이외의 자에게 제공하는
            기록 등에 유전정보를 포함하는 경우에는, 2년 이하의 징역 또는 3천만원
            이하의 벌금에 처할 수 있습니다(법 제67조 제1항 제4호). <br />
          </p>
          <p style={{ fontWeight: "900" }}>
            5. 본 검사는 검사 결과가 갖는 임상적 의미가 확립되지 않았으며, 이에
            따르는 건강과 관련된 행위가 유용하다는 객관적 타당성이 아직
            부족합니다.
          </p>
        </section>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType10Page;
