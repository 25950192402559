import React, { useState } from "react";
import useAxiosFunction from "hooks/useAxios";
import { useDropzone } from "react-dropzone";
import DatePickerComponent from "components/common/datePickerComponent";
import Select from "react-select";

const ModalWritePage = (props) => {
  const [modalEndDate, setModalEndDate] = useState(() => {
    const futureDate = new Date();
    futureDate.setMonth(futureDate.getMonth() + 3);
    return futureDate;
  });
  const [modalStatus, setModalStatus] = useState();
  const [modalUrl, setModalUrl] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { apiUrls, axiosFetchForm } = useAxiosFunction();
  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: "58px !important",
    }),
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length + uploadedFiles.length > 1) {
      alert("첨부파일의 최대 갯수는 1개입니다.");
      return;
    }
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
    }));
    setUploadedFiles([...uploadedFiles, ...newFiles]); // 배열로 설정
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: false,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  const handleRemoveFile = (indexToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const setWriteClear = () => {
    props.setIsWrite(false);
    setUploadedFiles([]);
  };

  const writeModal = (e) => {
    e.preventDefault();

    if (modalStatus === undefined || modalStatus === null) {
      alert("구분을 선택해주세요.");
      return;
    }

    // 파일 정보를 FormData에 추가
    const formData = new FormData();
    formData.append("modal_url", modalUrl);
    formData.append("expire_date", modalEndDate);
    formData.append("is_active", modalStatus.value);

    // 업로드된 파일 추가
    uploadedFiles?.forEach((file) => {
      // console.log("file > ", file);
      formData.append("files", file.file);
    });

    axiosFetchForm({
      method: "post",
      url: apiUrls.addModalList,
      formData: formData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          setWriteClear();
          props.getModalList();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] writeBoard: ", err);
      });
  };

  return (
    <>
      <div className="admin-dialog">
        <div className="admin-dialog-box contract">
          <div className="admin-dialog-area" style={{ padding: "0" }}>
            <div className="dialog-content">
              <div className="admin-dialog-item">
                <h3 className="adi-title">URL</h3>
                <div className="adi-content">
                  <div style={{ display: "flex" }}>
                    <div className="input-unit">
                      <input
                        className="input-admin"
                        style={{
                          display: "inline-block",
                          width: "235px",
                        }}
                        noLable="true"
                        label="URL"
                        holder="URL을 입력해주세요"
                        value={modalUrl}
                        onChange={(e) => setModalUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">만료일</h3>
                <div className="adi-content">
                  <div style={{ display: "flex" }}>
                    <DatePickerComponent
                      selected={modalEndDate}
                      onChange={setModalEndDate}
                      withPortal={true}
                    />
                    <span style={{ margin: "0 20px" }}> 까지 </span>
                  </div>
                </div>
              </div>

              <div className="admin-dialog-item">
                <h3 className="adi-title">구분</h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={[
                      { value: 0, label: "진행중" },
                      { value: 1, label: "마감" },
                    ]}
                    style={customStyles}
                    value={modalStatus}
                    onChange={(selectedOption) =>
                      setModalStatus(selectedOption)
                    }
                  ></Select>
                </div>
              </div>

              <div className="admin-dialog-item">
                <h3 className="adi-title">첨부파일</h3>
                <div className="adi-content">
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => onDrop(e.target.files)}
                    />
                    {isDragActive ? (
                      <p>파일을 여기에 놓아주세요!</p>
                    ) : (
                      <>
                        <p>클릭하거나 파일을 끌어다 놓아주세요.</p>
                      </>
                    )}
                  </div>
                  <div className="uploaded-files">
                    {uploadedFiles?.length > 0 && (
                      <ul>
                        {uploadedFiles?.map((uploadedFile, index) => (
                          <li key={index}>
                            <img
                              src={URL.createObjectURL(uploadedFile.file)}
                              alt={`홍보-${index}`}
                            />
                            <button onClick={() => handleRemoveFile(index)}>
                              삭제
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="board-btn-area">
        <button
          type="submit"
          className="write-btn"
          onClick={(e) => writeModal(e)}
        >
          등록
        </button>
        <button
          type="submit"
          className="write-btn"
          onClick={(e) => props.setIsWrite(false)}
        >
          취소
        </button>
      </div>
    </>
  );
};

export default ModalWritePage;
