import React from "react";
import "assets/css/report.css";

const ReportDDType3Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page2">
        <h2 className="grui-title border">유전자 검사 이해하기</h2>
        <p className="grui-standfirst">
          나의 결과를 정확하게 이해하도록 결과 확인 전 꼭 읽어주세요!
        </p>
        <section>
          <h3 className="grui-title size2">용어 도움말</h3>
          <ul className="grui-icon-list">
            <li>
              <div
                className="grui-il-item"
                style={{
                  alignItems: "flex-start",
                  padding: "40px 0 40px 20px",
                }}
              >
                <div className="grui-il-icon">
                  <img src="../assets/images/page2/icon01.png" alt="" />
                </div>
                <div className="grui-il-text">
                  <h4>유전자와 유전율</h4>
                  <p>
                    유전자는 부모님으로부터 물려받은 우리 몸의 기본
                    설계도입니다.
                    <br />이 설계도에는 우리 몸이 어떻게 작동할지에 대한 정보가
                    들어있습니다.
                    <br />
                    개인의 건강 및 특성 등에 대한 정보를 제공하고 이를 바탕으로
                    건강 상태나 개인의 특성 등을 추정하는 검사 유전율은 이
                    설계도가 우리 몸의 특성에 얼마나 영향을 주는지를 보여줍니다.
                    <br />
                    유전율이 높으면 유전자가 그 특성에 많이 영향을 준다는
                    뜻이고, 낮으면 생활습관이 더 많은 영향을 준다는 뜻입니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div
                className="grui-il-item"
                style={{
                  alignItems: "flex-start",
                  padding: "40px 0 40px 20px",
                }}
              >
                <div className="grui-il-icon">
                  <img src="../assets/images/page2/icon02.png" alt="" />
                </div>
                <div className="grui-il-text">
                  <h4>대립 유전자형</h4>
                  <p>
                    우리는 부모님으로부터 각각 한 개씩 유전자를 물려받아 총 세
                    가지 조합을 가질 수 있습니다.
                    <br />
                    이를 대립 유전자형이라고 합니다.
                    <br />본 유전자 검사는 몇 가지 대립 유전자의 조합을 조사하여
                    특정 상태에 대한 경향성을 나타냅니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div
                className="grui-il-item"
                style={{
                  alignItems: "flex-start",
                  padding: "40px 0 40px 20px",
                }}
              >
                <div className="grui-il-icon">
                  <img src="../assets/images/page2/icon03.png" alt="" />
                </div>
                <div className="grui-il-text">
                  <h4>나의 유전형</h4>
                  <p>
                    나의 유전형은 내가 가지고 있는 특정 대립 유전자형을
                    나타냅니다.
                    <br />
                    이는 고유한 DNA 지문과 같으며 비만이나 특정 질병에 대한
                    감수성과 같은 개인 특성에 영향을 미칠 수 있습니다.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDDType3Page;
