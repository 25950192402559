import React, { useEffect, useState } from "react";
import AdminAddItemPage from "./item/adminAddItemPage";
import AdminItemListPage from "./item/adminItemListPage";
import "assets/css/board.css";

const AdminItemManagementPage = () => {
  const [isItemAdd, setIsItemAdd] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <div className="container">
        <div className="content-box">
          {!isItemAdd ? (
            <button className="add-btn" onClick={(e) => setIsItemAdd(true)}>
              상품추가
            </button>
          ) : null}
          {isItemAdd ? (
            <AdminAddItemPage setIsItemAdd={setIsItemAdd} />
          ) : (
            <AdminItemListPage />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminItemManagementPage;
