import React, { useEffect, useState } from "react";
import "assets/css/board.css";
import moment from "moment";
import {
  ModeEditOutlineOutlined,
  DrawOutlined,
  Close,
  CheckCircleOutline,
} from "@mui/icons-material";
import useAxiosAuthFunction from "hooks/useAxiosAuth";

import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 15px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const OrderSelectedInfoPage = (props) => {
  const userInfo = props.userInfo;
  const orderSelectedInfo = props.orderSelectedInfo;
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [isReceiptDateEditing, setIsReceiptDateEditing] = useState(false);
  const [receiptDate, setReceiptData] = useState();

  const getDateStyle = (receiptDate) => {
    if (!receiptDate) return {};
    const currentDate = moment();
    const date = moment(receiptDate);
    const diffDays = currentDate.diff(date, "days");

    if (diffDays >= 9 && diffDays <= 14) {
      return { backgroundColor: "yellow" };
    } else if (diffDays > 14) {
      return { backgroundColor: "pink" };
    } else {
      return {};
    }
  };

  const handleReceiptDateInputChange = (e) => {
    e.preventDefault();
    setReceiptData(e.target.value);
  };

  const isUpdateReceiptDate = (e) => {
    e.preventDefault();
    setIsReceiptDateEditing(true);
  };

  const updateReceiptDate = (e) => {
    e.preventDefault();

    if (
      orderSelectedInfo.kit_number === null ||
      orderSelectedInfo.kit_number?.length === 0
    ) {
      alert("키트번호를 먼저 입력해주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateReceiptDate,
      requestConfig: {
        complete_id: orderSelectedInfo.complete_id,
        kit_number: orderSelectedInfo.kit_number,
        receipt_date: receiptDate,
      },
    })
      .then((res) => {
        if (res.success) {
          setIsReceiptDateEditing(false);
        }

        alert(res.message);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setReceiptData(orderSelectedInfo.receipt_date);
  }, []);

  return (
    <>
      <div className="container-n-admin" style={{ margin: "0" }}>
        <div className="content-area">
          <div>
            <h3>구매자정보</h3>
            <table className="boardTable" style={{ marginTop: "10px" }}>
              <thead>
                <tr>
                  <th style={{ background: "#ffffe0" }}>ID</th>
                  <th style={{ background: "#ffffe0" }}>이름</th>
                  <th style={{ background: "#ffffe0" }}>연락처</th>
                  <th style={{ background: "#ffffe0" }}>주소</th>
                  <th style={{ background: "#ffffe0" }}>주문일시</th>
                  <th style={{ background: "#ffffe0" }}>상품명</th>
                </tr>
              </thead>
              <tbody className="work-history">
                <tr>
                  <td>{orderSelectedInfo.user_id}</td>
                  <td>{orderSelectedInfo.user_name}</td>
                  <td>{orderSelectedInfo.user_phone}</td>
                  <td>
                    {`(${orderSelectedInfo.user_post}) ${orderSelectedInfo.user_addr1} ${orderSelectedInfo.user_addr2}`}
                  </td>
                  <td>
                    {moment(orderSelectedInfo.order_date).format("YYYY.MM.DD")}
                  </td>
                  <td>{orderSelectedInfo.item_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3>신청자정보</h3>
            <table className="boardTable" style={{ marginTop: "10px" }}>
              <thead>
                <tr>
                  <th style={{ background: "#daffda" }}>ID</th>
                  <th style={{ background: "#daffda" }}>이름</th>
                  <th style={{ background: "#daffda" }}>연락처</th>
                  <th style={{ background: "#daffda" }}>주소</th>
                  <th style={{ background: "#daffda" }}>신청일시</th>
                  <th style={{ background: "#daffda" }}>동의서 적절성</th>
                  <th style={{ background: "#daffda" }}>동의서 확인자</th>
                </tr>
              </thead>
              <tbody className="work-history">
                <tr>
                  <td>
                    {orderSelectedInfo.rqh_user_id
                      ? orderSelectedInfo.rqh_user_id
                      : "-"}
                  </td>
                  <td>
                    {orderSelectedInfo.rqh_user_name
                      ? orderSelectedInfo.rqh_user_name
                      : "-"}
                  </td>
                  <td>
                    {orderSelectedInfo.rqh_user_phone
                      ? orderSelectedInfo.rqh_user_phone
                      : "-"}
                  </td>
                  <td>
                    {orderSelectedInfo.rqh_user_post
                      ? `(${orderSelectedInfo.rqh_user_post}) ${orderSelectedInfo.rqh_user_addr1} ${orderSelectedInfo.rqh_user_addr2}`
                      : "-"}
                  </td>
                  <td>
                    {orderSelectedInfo.rqh_request_time
                      ? moment(orderSelectedInfo.rqh_request_time).format(
                          "YYYY.MM.DD"
                        )
                      : "-"}
                  </td>
                  <td>
                    {Number(orderSelectedInfo.is_appropriate) === 0 ? (
                      <div style={{ color: "#000" }}>심사전</div>
                    ) : Number(orderSelectedInfo.is_appropriate) === 1 ? (
                      <div style={{ color: "green" }}>적합</div>
                    ) : (
                      <div style={{ color: "red" }}>부적합</div>
                    )}
                  </td>
                  <td>
                    <div>
                      {orderSelectedInfo.is_appropriate_name
                        ? orderSelectedInfo.is_appropriate_name
                        : "-"}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "20px" }}>
            <h3>검사정보</h3>
            <table className="boardTable" style={{ marginTop: "10px" }}>
              <thead>
                <tr>
                  <th>키트번호</th>
                  <th style={{ background: "#f2f2f2" }}>운송장번호</th>
                  <th style={{ background: "#f2f2f2" }}>검체수령일</th>
                  <th>TAT</th>
                  <th>결과보고일</th>
                  <th>결과지검토</th>
                  <th>결과지검토자</th>
                  <th>결과확인여부</th>
                </tr>
              </thead>
              <tbody className="work-history">
                <tr>
                  <td>
                    {orderSelectedInfo.kit_number
                      ? orderSelectedInfo.kit_number
                      : "-"}
                  </td>
                  <td>
                    {orderSelectedInfo.tracking_number
                      ? orderSelectedInfo.tracking_number
                      : "-"}
                  </td>
                  <td>
                    {isReceiptDateEditing ? (
                      <div className="select-area">
                        <input
                          type="date"
                          style={{ margin: "0px" }}
                          label="날짜를 입력하세요."
                          defaultValue={receiptDate}
                          onChange={(e) => {
                            handleReceiptDateInputChange(e);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          disabled={userInfo?.user_type === "I"}
                        />
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateReceiptDate(e);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <ModeEditOutlineOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    ) : (
                      <div className="select-area">
                        <div className="date">
                          {receiptDate
                            ? moment(receiptDate).format("YY.MM.DD")
                            : "-"}
                        </div>
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            isUpdateReceiptDate(e);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <DrawOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    )}
                  </td>
                  <td
                    style={
                      orderSelectedInfo.order_complete_status === 6
                        ? null
                        : getDateStyle(orderSelectedInfo.receipt_date)
                    }
                  >
                    {orderSelectedInfo.receipt_date
                      ? moment(orderSelectedInfo.receipt_date)
                          .add(14, "days")
                          .format("YYYY.MM.DD")
                      : "-"}
                  </td>
                  <td>
                    {orderSelectedInfo.report_date
                      ? moment(orderSelectedInfo.report_date).format(
                          "YYYY.MM.DD"
                        )
                      : "-"}
                  </td>
                  <td>
                    {Number(orderSelectedInfo.is_delivery_approval) === 0 ? (
                      <div style={{ color: "#000" }}>심사전</div>
                    ) : Number(orderSelectedInfo.is_delivery_approval) === 1 ? (
                      <div style={{ color: "green" }}>적합</div>
                    ) : (
                      <div style={{ color: "red" }}>부적합</div>
                    )}
                  </td>
                  <td>
                    {orderSelectedInfo.name_delivery_approval
                      ? orderSelectedInfo.name_delivery_approval
                      : "-"}
                  </td>
                  <td>
                    {Number(orderSelectedInfo.user_register_report) === 0 ? (
                      <Close style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutline style={{ color: "green" }} />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSelectedInfoPage;
