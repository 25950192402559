import React from "react";
import "assets/css/report.css";

const ReportDAType9Page = (props) => {
  const title = props.title;
  const dtcData = props.dtcData;
  const content = props.content;
  const refence = props.refence;
  const percentDA = {
    VitaminC: {
      SLC23A1: [["0.00%", "0.34%", "99.66%"]],
    },
    VitaminD: {
      GC: [
        ["53.79%", "39.10%", "7.11%"],
        ["8.04%", "40.63%", "51.32%"],
      ],
      CYP24A1: [["0.35%", "11.10%", "88.55%"]],
      NADSYN1: [["13.56%", "46.53%", "39.90%"]],
      CYP2R1: [["35.55%", "48.15%", "16.31%"]],
    },
    VitaminA: {
      FFAR4: [["1.60%", "22.07%", "76.34%"]],
      BCO1: [["4.23%", "32.67%", "63.11%"]],
    },
    VitaminB6: {
      NBPF3: [["23.99%", "49.98%", "26.03%"]],
      ALPL: [["27.59%", "49.87%", "22.53%"]],
    },
    VitaminB12: {
      MS4A3: [["76.28%", "22.11%", "1.60%"]],
      FUT2: [
        ["99.66%", "0.34%", "0.00%"],
        ["99.66%", "0.34%", "0.00%"],
      ],
    },
    VitaminE: {
      ZPR1: [["62.69%", "32.97%", "4.33%"]],
      CYP4F2: [["43.16%", "45.07%", "11.76%"]],
    },
    VitaminK: {
      CYP4F2: [["43.16%", "45.07%", "11.76%"]],
      CTNNA2: [["57.78%", "36.47%", "5.76%"]],
    },
    Omega3: {
      FADS1: [["10.40%", "43.70%", "45.90%"]],
      BEST1: [["60.62%", "34.48%", "4.90%"]],
      FEN1: [["10.49%", "43.80%", "45.71%"]],
    },
    Omega6: {
      FADS1: [["45.90%", "43.70%", "10.40%"]],
      FADS2: [["58.49%", "35.98%", "5.53%"]],
      TMEM258: [["10.54%", "43.85%", "45.62%"]],
    },
    Arginine: {
      AGXT2: [["13.29%", "46.33%", "40.39%"]],
      SLC7A2: [["0.33%", "10.84%", "88.83%"]],
    },
    Calcium: {
      CASR: [["95.26%", "4.68%", "0.06%"]],
      CYP24A1: [
        ["1.08%", "18.64%", "80.28%"],
        ["14.43%", "47.12%", "38.45%"],
      ],
      DGKD: [["48.09%", "42.51%", "9.39%"]],
      GCKR: [["20.98%", "49.65%", "29.38%"]],
    },
    Magnesium: {
      MUC1: [["2.82%", "27.94%", "69.24%"]],
      SHROOM3: [["60.45%", "34.60%", "4.95%"]],
    },
    Zinc: {
      PPCDC: [["32.36%", "49.05%", "18.58%"]],
      CA1: [["18.26%", "48.94%", "32.80%"]],
    },
    Iron: {
      TF: [
        ["33.58%", "48.74%", "17.68%"],
        ["35.71%", "48.09%", "16.19%"],
      ],
      TMPRSS6: [
        ["25.44%", "50.00%", "24.56%"],
        ["99.94%", "0.06%", "0.00%"],
      ],
    },
    Tyrosine: {
      SLC16A10: [
        ["29.30%", "49.66%", "21.04%"],
        ["84.49%", "14.85%", "0.65%"],
      ],
    },
    Betaine: {
      DMGDH: [
        ["1.81%", "23.28%", "74.91%"],
        ["0.00%", "0.06%", "99.94%"],
      ],
    },
    Selenium: {
      DMGDH: [
        ["99.94%", "0.06%", "0.00%"],
        ["74.86%", "23.33%", "1.82%"],
      ],
    },
    Lutein: {
      SCARB1: [
        ["3.58%", "30.67%", "65.76%"],
        ["82.26%", "16.87%", "0.86%"],
      ],
    },
    Fat: {
      FTO: [["1.71%", "22.72%", "75.57%"]],
      NEGR1: [["85.49%", "13.94%", "0.57%"]],
    },
    BodyFatPer: {
      TMEM18: [["81.49%", "17.57%", "0.95%"]],
      COBLL1: [["1.13%", "19.02%", "79.85%"]],
      NYAP2: [["0.26%", "9.72%", "90.02%"]],
      PLA2G6: [["6.66%", "38.29%", "55.06%"]],
    },
    Bmi: {
      FTO: [
        ["2.76%", "27.72%", "69.52%"],
        ["1.71%", "22.72%", "75.57%"],
        ["1.69%", "22.62%", "75.69%"],
      ],
      BDNF: [
        ["1.21%", "49.31%", "19.47%"],
        ["30.72%", "49.41%", "19.86%"],
      ],
      MC4R: [
        ["5.63%", "36.19%", "58.19%"],
        ["4.89%", "34.45%", "60.65%"],
      ],
    },
    Abdominal: {
      GRB14: [["79.78%", "19.08%", "1.14%"]],
      HOXC13: [["5.20%", "35.20%", "59.60%"]],
      CPEB4: [["0.44%", "12.40%", "87.16%"]],
    },
    NFatConcentration: {
      DOCK7: [["69.01%", "28.13%", "2.87%"]],
      FADS1: [["10.34%", "43.63%", "46.04%"]],
      TRIB1: [
        ["20.14%", "49.48%", "30.38%"],
        ["20.12%", "49.47%", "30.42%"],
      ],
    },
    LDL: {
      APOC1: [["1.14%", "19.08%", "79.78%"]],
      PSRC1: [["87.89%", "11.72%", "0.39%"]],
      LDLR: [["99.58%", "0.42%", "0.00%"]],
    },
    HDL: {
      CETP: [
        ["82.17%", "16.95%", "0.87%"],
        ["2.85%", "28.07%", "69.07%"],
        ["70.12%", "27.23%", "2.64%"],
      ],
      LIPC: [
        ["24.26%", "49.99%", "25.76%"],
        ["62.39%", "33.19%", "4.41%"],
      ],
    },
    EffectLosingWeight: {
      FTO: [
        ["75.62%", "22.68%", "1.70%"],
        ["2.75%", "27.68%", "69.57%"],
        ["1.72%", "22.78%", "75.50%"],
      ],
    },
    BloodSugar: {
      MTNR1B: [["19.20%", "49.24%", "31.56%"]],
      G6PC2: [["93.76%", "6.14%", "0.10%"]],
      CDKN2A: [["32.60%", "48.99%", "18.40%"]],
      GCK: [
        ["59.23%", "35.46%", "5.31%"],
        ["3.41%", "30.10%", "66.49%"],
      ],
      GCKR: [
        ["22.02%", "49.81%", "28.16%"],
        ["20.98%", "49.65%", "29.38%"],
      ],
    },
    BloodPressure: {
      ATP2B1: [
        ["38.88%", "46.95%", "14.18%"],
        ["38.75%", "47.00%", "14.25%"],
      ],
      CSK: [["67.63%", "29.21%", "3.15%"]],
      NT5C2: [["56.69%", "37.21%", "6.11%"]],
      CASZ1: [["40.95%", "46.09%", "12.97%"]],
    },
    BoneMass: {
      COLEC10: [
        ["39.48%", "46.71%", "13.82%"],
        ["41.09%", "46.02%", "12.89%"],
      ],
      AKAP11: [["0.54%", "13.64%", "85.82%"]],
      WNT16: [["74.79%", "23.38%", "1.83%"]],
    },
    Degenerative: {
      GNL3: [["24.26%", "49.99%", "25.76%"]],
      PTHLH: [["0.00%", "0.06%", "99.94%"]],
    },
    UricAcid: {
      GCKR: [["29.38%", "49.65%", "20.98%"]],
      ABCG2: [["7.74%", "40.16%", "52.10%"]],
      SLC2A9: [
        ["8.50%", "41.31%", "50.20%"],
        ["97.14%", "2.84%", "0.02%"],
      ],
    },
    TravelSickness: {
      LINGO2: [["36.32%", "47.89%", "15.78%"]],
    },
    AlcoholMetabolism: {
      ADH1B: [["5.82%", "36.61%", "57.56%"]],
    },
    AlcoholDependence: {
      ALDH2: [["68.46%", "28.56%", "2.98%"]],
      ADH1B: [["5.82%", "36.61%", "57.56%"]],
    },
    AlcoholFlush: {
      ALDH2: [["2.98%", "28.56%", "68.46%"]],
    },
    NicotineMetabolism: {
      CYP2B6: [["19.39%", "49.29%", "31.33%"]],
      CYP2A6: [
        ["52.32%", "40.03%", "7.66%"],
        ["100.00%", "0.00%", "0.00%"],
      ],
    },
    NicotineDependence: {
      CHRNA4: [["2.47%", "26.51%", "71.01%"]],
      CHRNA3: [["0.05%", "4.48%", "95.47%"]],
      CHRNA5: [["0.05%", "4.55%", "95.39%"]],
    },
    CaffeineMetabolism: {
      CYP1A2: [
        ["99.86%", "0.14%", "0.00%"],
        ["99.86%", "0.14%", "0.00%"],
      ],
    },
    CaffeineDependence: {
      AHR: [["14.69%", "47.28%", "38.03%"]],
    },
    Insomnia: {
      MEIS1: [["0.00%", "0.00%", "100.00%"]],
      NEGR1: [["98.09%", "1.90%", "0.01%"]],
    },
    MorningPerson: {
      VIP: [["53.35%", "39.38%", "7.27%"]],
      FBXL3: [["1.49%", "21.45%", "77.05%"]],
      RASD1: [["0.96%", "17.68%", "81.36%"]],
      RNASEL: [
        ["100.00%", "0.00%", "0.00%"],
        ["99.99%", "0.01%", "0.00%"],
      ],
    },
    PainSensitivity: {
      SCN10A: [
        ["62.58%", "33.05%", "4.36%"],
        ["71.30%", "26.28%", "2.42%"],
      ],
    },
    Freckles: {
      MC1R: [
        ["0.00%", "0.34%", "99.66%"],
        ["1.98%", "24.18%", "73.84%"],
      ],
      IRF4: [["0.00%", "0.02%", "99.98%"]],
    },
    Pigmentation: {
      OCA2: [["21.93%", "49.80%", "28.27%"]],
      SLC45A2: [["0.00%", "0.14%", "99.86%"]],
    },
    Acne: {
      FST: [["5.50%", "35.90%", "58.60%"]],
      RNASEH2C: [["26.80%", "49.94%", "23.26%"]],
      TGFB2: [["20.79%", "49.61%", "29.59%"]],
    },
    SkinAging: {
      IRF4: [["0.00%", "0.02%", "99.98%"]],
    },
    SkinInflammation: {
      PBX2: [["54.10%", "38.91%", "7.00%"]],
      ZNF365: [["16.55%", "48.26%", "35.19%"]],
      IL18R1: [["22.67%", "49.89%", "27.45%"]],
    },
    TanningReaction: {
      TYR: [["0.00%", "0.20%", "99.80%"]],
      PRDM15: [["24.09%", "49.98%", "25.93%"]],
      CPNE7: [["14.35%", "47.06%", "38.59%"]],
    },
    WhiteHair: {
      IRF4: [["0.00%", "0.02%", "99.98%"]],
    },
    HairLoss: {
      Chr20p11: [
        ["10.93%", "44.26%", "44.81%"],
        ["40.60%", "46.24%", "13.16%"],
      ],
      AR: [["100.00%", "0.00%", "0.00%"]],
      TWIST2: [
        ["19.60%", "49.34%", "31.06%"],
        ["18.28%", "48.95%", "32.76%"],
      ],
      C1orf127: [["44.04%", "44.65%", "11.32%"]],
    },
    CircularHairLoss: {
      IKZF4: [["4.10%", "32.29%", "63.62%"]],
      "HLA-DQB1": [["43.26%", "45.03%", "11.72%"]],
      IL2RA: [["27.27%", "49.90%", "22.83%"]],
    },
    HairThickness: {
      EDAR: [["1.00%", "18.00%", "81.00%"]],
    },
    MuscleFitness: {
      MTHER: [["67.96%", "28.95%", "3.08%"]],
      AGT: [["3.89%", "31.67%", "64.43%"]],
    },
    ExerciseFitness: {
      HIF1A: [["0.27%", "9.84%", "89.89%"]],
    },
    AerobicExercise: {
      "VEGF-A": [["31.98%", "49.14%", "18.88%"]],
    },
    ShortDistanceRunning: {
      SOD2: [["77.33%", "21.21%", "1.45%"]],
      ACTN3: [["30.23%", "49.50%", "20.27%"]],
      AMPD1: [["0.00%", "0.06%", "99.94%"]],
    },
    RecoverAfterExercising: {
      RBPMS: [["7.97%", "40.52%", "51.51%"]],
      YWHAQ: [["0.17%", "7.92%", "91.91%"]],
      CCDC141: [["0.00%", "0.00%", "100.00%"]],
      BCAT1: [["73.84%", "24.18%", "1.98%"]],
      GSTP1: [["66.70%", "29.94%", "3.36%"]],
    },
    MuscleDevelopment: {
      AGT: [["3.89%", "31.67%", "64.43%"]],
      VCAN: [["63.57%", "32.32%", "4.11%"]],
    },
    AnkleInjury: {
      ACTN3: [["30.23%", "49.50%", "20.27%"]],
    },
    SensitivitySweet: {
      FGF21: [["0.00%", "0.96%", "99.04%"]],
    },
    SensitivityBitter: {
      TAS2R19: [["23.36%", "49.94%", "26.70%"]],
      TAS2R38: [
        ["34.90%", "48.35%", "16.74%"],
        ["16.66%", "48.31%", "35.02%"],
        ["16.63%", "48.30%", "35.07%"],
      ],
    },
    SensitivitySalty: {
      TRPV1: [["42.11%", "45.57%", "12.33%"]],
    },
    FeelingFull: {
      LEPR: [["1.78%", "23.14%", "75.08%"]],
      LEP: [["47.25%", "42.98%", "9.77%"]],
    },
  };
  const percentDB = {
    VitaminC: {
      SLC23A1: [["0.00%", "0.34%", "99.66%"]],
    },
    VitaminD: {
      GC: [
        ["53.79%", "39.10%", "7.11%"],
        ["8.04%", "40.63%", "51.32%"],
      ],
      CYP24A1: [["0.35%", "11.10%", "88.55%"]],
      NADSYN1: [["13.56%", "46.53%", "39.90%"]],
      CYP2R1: [["35.55%", "48.15%", "16.31%"]],
    },
    VitaminA: {
      FFAR4: [["1.60%", "22.07%", "76.34%"]],
      BCO1: [["4.23%", "32.67%", "63.11%"]],
    },
    VitaminB6: {
      NBPF3: [["23.99%", "49.98%", "26.03%"]],
      ALPL: [["27.59%", "49.87%", "22.53%"]],
    },
    VitaminB12: {
      MS4A3: [["76.28%", "22.11%", "1.60%"]],
      FUT2: [
        ["99.66%", "0.34%", "0.00%"],
        ["99.66%", "0.34%", "0.00%"],
      ],
    },
    VitaminE: {
      ZPR1: [["62.69%", "32.97%", "4.33%"]],
      CYP4F2: [["43.16%", "45.07%", "11.76%"]],
    },
    VitaminK: {
      CYP4F2: [["43.16%", "45.07%", "11.76%"]],
      CTNNA2: [["57.78%", "36.47%", "5.76%"]],
    },
    Omega3: {
      FADS1: [["10.40%", "43.70%", "45.90%"]],
      BEST1: [["60.62%", "34.48%", "4.90%"]],
      FEN1: [["10.49%", "43.80%", "45.71%"]],
    },
    Omega6: {
      FADS1: [["45.90%", "43.70%", "10.40%"]],
      FADS2: [["58.49%", "35.98%", "5.53%"]],
      TMEM258: [["10.54%", "43.85%", "45.62%"]],
    },
    Arginine: {
      AGXT2: [["13.29%", "46.33%", "40.39%"]],
      SLC7A2: [["0.33%", "10.84%", "88.83%"]],
    },
    Calcium: {
      CASR: [["95.26%", "4.68%", "0.06%"]],
      CYP24A1: [
        ["1.08%", "18.64%", "80.28%"],
        ["14.43%", "47.12%", "38.45%"],
      ],
      DGKD: [["48.09%", "42.51%", "9.39%"]],
      GCKR: [["20.98%", "49.65%", "29.38%"]],
    },
    Magnesium: {
      MUC1: [["2.82%", "27.94%", "69.24%"]],
      SHROOM3: [["60.45%", "34.60%", "4.95%"]],
    },
    Zinc: {
      PPCDC: [["32.36%", "49.05%", "18.58%"]],
      CA1: [["18.26%", "48.94%", "32.80%"]],
    },
    Iron: {
      TF: [
        ["33.58%", "48.74%", "17.68%"],
        ["35.71%", "48.09%", "16.19%"],
      ],
      TMPRSS6: [
        ["25.44%", "50.00%", "24.56%"],
        ["99.94%", "0.06%", "0.00%"],
      ],
    },
    Tyrosine: {
      SLC16A10: [
        ["29.30%", "49.66%", "21.04%"],
        ["84.49%", "14.85%", "0.65%"],
      ],
    },
    Betaine: {
      DMGDH: [
        ["1.81%", "23.28%", "74.91%"],
        ["0.00%", "0.06%", "99.94%"],
      ],
    },
    Selenium: {
      DMGDH: [
        ["99.94%", "0.06%", "0.00%"],
        ["74.86%", "23.33%", "1.82%"],
      ],
    },
    Lutein: {
      SCARB1: [
        ["3.58%", "30.67%", "65.76%"],
        ["82.26%", "16.87%", "0.86%"],
      ],
    },
    Fat: {
      FTO: [["1.71%", "22.72%", "75.57%"]],
      NEGR1: [["85.49%", "13.94%", "0.57%"]],
    },
    BodyFatPer: {
      TMEM18: [["81.49%", "17.57%", "0.95%"]],
      COBLL1: [["1.13%", "19.02%", "79.85%"]],
      NYAP2: [["0.26%", "9.72%", "90.02%"]],
      PLA2G6: [["6.66%", "38.29%", "55.06%"]],
    },
    Bmi: {
      FTO: [
        ["2.76%", "27.72%", "69.52%"],
        ["1.71%", "22.72%", "75.57%"],
        ["1.69%", "22.62%", "75.69%"],
      ],
      BDNF: [
        ["1.21%", "49.31%", "19.47%"],
        ["30.72%", "49.41%", "19.86%"],
      ],
      MC4R: [
        ["5.63%", "36.19%", "58.19%"],
        ["4.89%", "34.45%", "60.65%"],
      ],
    },
    Abdominal: {
      GRB14: [["79.78%", "19.08%", "1.14%"]],
      HOXC13: [["5.20%", "35.20%", "59.60%"]],
      CPEB4: [["0.44%", "12.40%", "87.16%"]],
    },
    NFatConcentration: {
      DOCK7: [["69.01%", "28.13%", "2.87%"]],
      FADS1: [["10.34%", "43.63%", "46.04%"]],
      TRIB1: [
        ["20.14%", "49.48%", "30.38%"],
        ["20.12%", "49.47%", "30.42%"],
      ],
    },
    LDL: {
      APOC1: [["1.14%", "19.08%", "79.78%"]],
      PSRC1: [["87.89%", "11.72%", "0.39%"]],
      LDLR: [["99.58%", "0.42%", "0.00%"]],
    },
    HDL: {
      CETP: [
        ["82.17%", "16.95%", "0.87%"],
        ["2.85%", "28.07%", "69.07%"],
        ["70.12%", "27.23%", "2.64%"],
      ],
      LIPC: [
        ["24.26%", "49.99%", "25.76%"],
        ["62.39%", "33.19%", "4.41%"],
      ],
    },
    EffectLosingWeight: {
      FTO: [
        ["75.62%", "22.68%", "1.70%"],
        ["2.75%", "27.68%", "69.57%"],
        ["1.72%", "22.78%", "75.50%"],
      ],
    },
    BloodSugar: {
      MTNR1B: [["19.20%", "49.24%", "31.56%"]],
      G6PC2: [["93.76%", "6.14%", "0.10%"]],
      CDKN2A: [["32.60%", "48.99%", "18.40%"]],
      GCK: [
        ["59.23%", "35.46%", "5.31%"],
        ["3.41%", "30.10%", "66.49%"],
      ],
      GCKR: [
        ["22.02%", "49.81%", "28.16%"],
        ["20.98%", "49.65%", "29.38%"],
      ],
    },
    BloodPressure: {
      ATP2B1: [
        ["38.88%", "46.95%", "14.18%"],
        ["38.75%", "47.00%", "14.25%"],
      ],
      CSK: [["67.63%", "29.21%", "3.15%"]],
      NT5C2: [["56.69%", "37.21%", "6.11%"]],
      CASZ1: [["40.95%", "46.09%", "12.97%"]],
    },
    BoneMass: {
      COLEC10: [
        ["39.48%", "46.71%", "13.82%"],
        ["41.09%", "46.02%", "12.89%"],
      ],
      AKAP11: [["0.54%", "13.64%", "85.82%"]],
      WNT16: [["74.79%", "23.38%", "1.83%"]],
    },
    Degenerative: {
      GNL3: [["24.26%", "49.99%", "25.76%"]],
      PTHLH: [["0.00%", "0.06%", "99.94%"]],
    },
    UricAcid: {
      GCKR: [["29.38%", "49.65%", "20.98%"]],
      ABCG2: [["7.74%", "40.16%", "52.10%"]],
      SLC2A9: [
        ["8.50%", "41.31%", "50.20%"],
        ["97.14%", "2.84%", "0.02%"],
      ],
    },
    TravelSickness: {
      LINGO2: [["36.32%", "47.89%", "15.78%"]],
    },
    AlcoholMetabolism: {
      ADH1B: [["5.82%", "36.61%", "57.56%"]],
    },
    AlcoholDependence: {
      ALDH2: [["68.46%", "28.56%", "2.98%"]],
      ADH1B: [["5.82%", "36.61%", "57.56%"]],
    },
    AlcoholFlush: {
      ALDH2: [["2.98%", "28.56%", "68.46%"]],
    },
    NicotineMetabolism: {
      CYP2B6: [["19.39%", "49.29%", "31.33%"]],
      CYP2A6: [
        ["52.32%", "40.03%", "7.66%"],
        ["100.00%", "0.00%", "0.00%"],
      ],
    },
    NicotineDependence: {
      CHRNA4: [["2.47%", "26.51%", "71.01%"]],
      CHRNA3: [["0.05%", "4.48%", "95.47%"]],
      CHRNA5: [["0.05%", "4.55%", "95.39%"]],
    },
    CaffeineMetabolism: {
      CYP1A2: [
        ["99.86%", "0.14%", "0.00%"],
        ["99.86%", "0.14%", "0.00%"],
      ],
    },
    CaffeineDependence: {
      AHR: [["14.69%", "47.28%", "38.03%"]],
    },
    Insomnia: {
      MEIS1: [["0.00%", "0.00%", "100.00%"]],
      NEGR1: [["98.09%", "1.90%", "0.01%"]],
    },
    MorningPerson: {
      VIP: [["53.35%", "39.38%", "7.27%"]],
      FBXL3: [["1.49%", "21.45%", "77.05%"]],
      RASD1: [["0.96%", "17.68%", "81.36%"]],
      RNASEL: [
        ["100.00%", "0.00%", "0.00%"],
        ["99.99%", "0.01%", "0.00%"],
      ],
    },
    PainSensitivity: {
      SCN10A: [
        ["62.58%", "33.05%", "4.36%"],
        ["71.30%", "26.28%", "2.42%"],
      ],
    },
    Freckles: {
      MC1R: [
        ["0.00%", "0.34%", "99.66%"],
        ["1.98%", "24.18%", "73.84%"],
      ],
      IRF4: [["0.00%", "0.02%", "99.98%"]],
    },
    Pigmentation: {
      OCA2: [["21.93%", "49.80%", "28.27%"]],
      SLC45A2: [["0.00%", "0.14%", "99.86%"]],
    },
    Acne: {
      FST: [["5.50%", "35.90%", "58.60%"]],
      RNASEH2C: [["26.80%", "49.94%", "23.26%"]],
      TGFB2: [["20.79%", "49.61%", "29.59%"]],
    },
    SkinAging: {
      IRF4: [["0.00%", "0.02%", "99.98%"]],
    },
    SkinInflammation: {
      PBX2: [["54.10%", "38.91%", "7.00%"]],
      ZNF365: [["16.55%", "48.26%", "35.19%"]],
      IL18R1: [["22.67%", "49.89%", "27.45%"]],
    },
    TanningReaction: {
      TYR: [["0.00%", "0.20%", "99.80%"]],
      PRDM15: [["24.09%", "49.98%", "25.93%"]],
      CPNE7: [["14.35%", "47.06%", "38.59%"]],
    },
    WhiteHair: {
      IRF4: [["0.00%", "0.02%", "99.98%"]],
    },
    HairLoss: {
      Chr20p11: [
        ["10.93%", "44.26%", "44.81%"],
        ["40.60%", "46.24%", "13.16%"],
      ],
      AR: [["100.00%", "0.00%", "0.00%"]],
      TWIST2: [
        ["19.60%", "49.34%", "31.06%"],
        ["18.28%", "48.95%", "32.76%"],
      ],
      C1orf127: [["44.04%", "44.65%", "11.32%"]],
    },
    CircularHairLoss: {
      IKZF4: [["4.10%", "32.29%", "63.62%"]],
      "HLA-DQB1": [["43.26%", "45.03%", "11.72%"]],
      IL2RA: [["27.27%", "49.90%", "22.83%"]],
    },
    HairThickness: {
      EDAR: [["1.00%", "18.00%", "81.00%"]],
    },
    MuscleFitness: {
      MTHER: [["67.96%", "28.95%", "3.08%"]],
      AGT: [["3.89%", "31.67%", "64.43%"]],
    },
    ExerciseFitness: {
      HIF1A: [["0.27%", "9.84%", "89.89%"]],
    },
    AerobicExercise: {
      "VEGF-A": [["31.98%", "49.14%", "18.88%"]],
    },
    ShortDistanceRunning: {
      SOD2: [["77.33%", "21.21%", "1.45%"]],
      ACTN3: [["30.23%", "49.50%", "20.27%"]],
      AMPD1: [["0.00%", "0.06%", "99.94%"]],
    },
    RecoverAfterExercising: {
      RBPMS: [["7.97%", "40.52%", "51.51%"]],
      YWHAQ: [["0.17%", "7.92%", "91.91%"]],
      CCDC141: [["0.00%", "0.00%", "100.00%"]],
      BCAT1: [["73.84%", "24.18%", "1.98%"]],
      GSTP1: [["66.70%", "29.94%", "3.36%"]],
    },
    MuscleDevelopment: {
      AGT: [["3.89%", "31.67%", "64.43%"]],
      VCAN: [["63.57%", "32.32%", "4.11%"]],
    },
    AnkleInjury: {
      ACTN3: [["30.23%", "49.50%", "20.27%"]],
    },
    SensitivitySweet: {
      FGF21: [["0.00%", "0.96%", "99.04%"]],
    },
    SensitivityBitter: {
      TAS2R19: [["23.36%", "49.94%", "26.70%"]],
      TAS2R38: [
        ["34.90%", "48.35%", "16.74%"],
        ["16.66%", "48.31%", "35.02%"],
        ["16.63%", "48.30%", "35.07%"],
      ],
    },
    SensitivitySalty: {
      TRPV1: [["42.11%", "45.57%", "12.33%"]],
    },
    FeelingFull: {
      LEPR: [["1.78%", "23.14%", "75.08%"]],
      LEP: [["47.25%", "42.98%", "9.77%"]],
    },
  };

  return (
    <div className="grui-report">
      <div className="grui-page page10">
        <h2 className="grui-title border">유전자별 상세 정보</h2>
        <p className="grui-standfirst">
          분석한 유전자가 어떤 역할을 하는지 알아보세요!
        </p>
        <div className="grui-results-sheet">
          <h3>
            <div>
              <i
                className={
                  title === "영양소"
                    ? "gicon-fill color"
                    : title === "건강관리"
                    ? "gicon-health-care color"
                    : title === "개인특성"
                    ? "gicon-person color"
                    : title === "피부/모발"
                    ? "gicon-water color"
                    : title === "운동능력"
                    ? "gicon-athletic color"
                    : title === "식습관"
                    ? "gicon-eating color"
                    : null
                }
              ></i>
            </div>
            <span>{title}</span>
          </h3>
          <div className="grui-results-table">
            <div className="grui-rtable-head">
              <div className="rtable-col01">
                <span>검사항목(참고문헌 수)</span>
              </div>
              <div className="rtable-col02">
                <span>유전자명</span>
              </div>
              <div className="rtable-col03">
                <span>유전자의 기능</span>
              </div>
              <div className="rtable-col04">
                <span>나의 유전형</span>
              </div>
              <div className="rtable-col05">
                <span>한국인보유율</span>
              </div>
            </div>
            <div className="grui-rtable-body">
              {Array.isArray(dtcData) &&
                dtcData.map((dtc, index) => {
                  return (
                    <section key={dtc?.name || index}>
                      <div className="rtable-col00">
                        <h4
                          ref={props.forwardedRef?.[index]}
                          style={{ cursor: "pointer" }}
                          onClick={() => props.clickName?.(dtc?.name)}
                        >
                          {dtc?.name || "N/A"}
                        </h4>
                        <h2
                          style={{ marginTop: "10px", cursor: "pointer" }}
                          onClick={() => {
                            props.clickReferenceName?.(dtc?.name);
                          }}
                        >
                          ({refence?.[dtc?.dtc_id] || "N/A"})
                        </h2>
                      </div>
                      <div>
                        {dtc?.results
                          ?.filter(
                            (el, i) =>
                              dtc?.results?.findIndex(
                                (el2) => el.gene === el2.gene
                              ) === i
                          )
                          ?.map((dtcFilter, idx) => (
                            <article key={dtcFilter?.gene || idx}>
                              <h5 className="rtable-col02">
                                <span>{dtcFilter?.gene || "N/A"}</span>
                              </h5>
                              <p className="rtable-col03">
                                {content?.[dtc?.dtc_id]?.[idx] || "N/A"}
                              </p>
                              <div className="rtable-col04">
                                {dtc?.results
                                  ?.filter((x) => x.gene === dtcFilter?.gene)
                                  ?.map((data, index) => (
                                    <tr key={data?.own + index}>
                                      <td>
                                        <span
                                          style={
                                            data?.opposite?.indexOf(
                                              data?.own
                                            ) === 0
                                              ? {
                                                  color: "#bd0000",
                                                  fontWeight: "bold",
                                                }
                                              : data?.opposite?.indexOf(
                                                  data?.own
                                                ) === 1
                                              ? {
                                                  color: "#001E5C",
                                                  fontWeight: "bold",
                                                }
                                              : {
                                                  color: "#727171",
                                                  fontWeight: "bold",
                                                }
                                          }
                                        >
                                          {data?.own || "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                              </div>
                              <div className="rtable-col05">
                                {dtc?.results
                                  ?.filter((x) => x.gene === dtcFilter?.gene)
                                  ?.map((data, index) => (
                                    <tr key={data?.own + index}>
                                      <td>
                                        <span>
                                          {props.reportType === "DA"
                                            ? percentDA?.[dtc?.dtc_id]?.[
                                                data?.gene
                                              ]?.[index]?.[
                                                data?.opposite?.indexOf(
                                                  data?.own
                                                )
                                              ] || "N/A"
                                            : props.reportType === "DB"
                                            ? percentDB?.[dtc?.dtc_id]?.[
                                                data?.gene
                                              ]?.[index]?.[
                                                data?.opposite?.indexOf(
                                                  data?.own
                                                )
                                              ] || "N/A"
                                            : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                              </div>
                            </article>
                          ))}
                      </div>
                    </section>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType9Page;
