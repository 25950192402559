import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { CustomRegExp } from "helpers/customRegExp";
import InputUnit from "components/InputUnit";
import CustomModal from "../../components/popup/customModal";
import useAxiosFunction from "hooks/useAxios";
import "assets/css/board.css";
import moment from "moment";
import { ArticleOutlined, DeleteForeverOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 15px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const ReportList = (props) => {
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [reportList, setReportList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const getReportList = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getReportList,
      requestConfig: { user_id: userInfo.user_id },
    })
      .then((res) => {
        if (res.success) {
          setReportList(res.reportList);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  const goReport = (item) => {
    navigate("/newreportpage", {
      state: { reportInfo: item },
    });
  };

  const reportCheck = (e, item) => {
    e.preventDefault();
    setSelectedItem(item);
    openModal();
  };

  const reportDeleteHandler = (e, item) => {
    e.preventDefault();
    if (
      window.confirm(
        "해당 결과지와 유전자 동의서가 폐기되며 다시 복구 할 수 없습니다. 정말 폐기하시겠습니까?"
      )
    ) {
      axiosFetch({
        method: "post",
        url: apiUrls.delReportData,
        requestConfig: {
          user_id: userInfo.user_id,
          kit_number: item.kit_number,
          confirm_code: item.confirm_code,
        },
      })
        .then((res) => {
          if (res.success) {
            getReportList();
          }
          alert(res.message);
        })
        .catch((err) => {
          //console.log("[ERROR] login : ", err);
        });
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const checkData = (reqData) => {
    closeModal();
    if (
      reqData.confirm_name === selectedItem.user_name &&
      reqData.confirm_birth ===
        moment(selectedItem.user_birth).format("YYYYMMDD")
    ) {
      goReport(selectedItem);
    } else {
      alert("이름 혹은 생년월일을 확인해주세요.");
    }
  };

  useEffect(() => {
    if (userInfo.user_id !== null) {
      getReportList();
    }
  }, []);

  useEffect(() => {
    getReportList();
  }, [props.confirmed]);

  useEffect(() => {
    getReportList();
  }, [userInfo]);

  return (
    <>
      {reportList?.length === 0 ? (
        <>
          <div className="container">
            <div className="content-box4">
              <div className="sub-title">
                <span>검사결과가 없습니다. 검사 결과를 등록해주세요</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="content-box5">
              <div className="main-title">
                <span>검사결과 보기</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container-n-admin report-list">
            <div className="content-area">
              <div className="board-info">
                <div className="board-total">
                  <span>{userInfo.user_name}님 리포트 총 </span>
                  {reportList?.length}
                  <span>건</span>
                </div>
              </div>

              <div>
                <table className="boardTable">
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>키트번호</th>
                      <th>검사신청자</th>
                      <th>성별</th>
                      <th>검체수령일</th>
                      <th>결과보고일</th>
                      <th>결과확인</th>
                      <th>결과폐기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList?.map((item) => (
                      <tr
                        key={item.row_num}
                        style={{
                          height: "53px",
                        }}
                      >
                        <td>
                          <div>{reportList?.length - item.row_num + 1}</div>
                        </td>
                        <td>
                          <div>{item.kit_number}</div>
                        </td>
                        <td>
                          <div>{item.user_name}</div>
                        </td>
                        <td>
                          <div>
                            {Number(item.user_gender) === 1 ? "남자" : "여자"}
                          </div>
                        </td>
                        <td>
                          <div>
                            {moment(item.receipt_date).format("YYYY.MM.DD")}
                          </div>
                        </td>
                        <td>
                          <div>
                            {moment(item.report_date).format("YYYY.MM.DD")}
                          </div>
                        </td>
                        <td>
                          <div>
                            <button
                              style={{ background: "transparent" }}
                              onClick={(e) => {
                                reportCheck(e, item);
                              }}
                            >
                              <IconWrapper>
                                <ArticleOutlined fontSize="medium" />
                              </IconWrapper>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button
                              style={{ background: "transparent" }}
                              onClick={(e) => {
                                reportDeleteHandler(e, item);
                              }}
                            >
                              <IconWrapper>
                                <DeleteForeverOutlined fontSize="medium" />
                              </IconWrapper>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <CustomModal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            maxHeight="350px"
            minHeight="150px"
            minWidth="300px"
            header="결과지의 이름과 생년월일을 입력해주세요."
          >
            <main>
              <div className="login">
                <form className="input-area" onSubmit={handleSubmit(checkData)}>
                  <h3>결과지의 이름과 생년월일을 입력해주세요.</h3>
                  <InputUnit
                    label="Name"
                    holder="이름"
                    register={register("confirm_name", {
                      required: "이름은 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="Birth"
                    holder="생년월일 8자리 ex(1990년 01월 14일, 19900114)"
                    register={register("confirm_birth", {
                      required: "생년월일은 필수 입력 값 입니다.",
                      minLength: {
                        value: 8,
                        message: "8자리로 입력 해 주세요",
                      },
                      maxLength: {
                        value: 8,
                        message: "8자리로 입력 해 주세요",
                      },
                      pattern: CustomRegExp("birth"),
                    })}
                    errors={formErrors}
                  />
                  <div
                    className="board-btn-area"
                    style={{ margin: "20px 0px" }}
                  >
                    <button
                      className="btn"
                      type="submit"
                      disabled={isSubmitting}
                      style={{ margin: "0" }}
                    >
                      확인
                    </button>
                  </div>
                </form>
              </div>
            </main>
          </CustomModal>
        </>
      )}
    </>
  );
};

export default ReportList;
