import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";

const MarketListPage = () => {
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [itemList, setItemList] = useState([]);

  const getItemList = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getItemList,
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setItemList(res.itemList);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getItemList();
  }, []);

  return (
    <>
      <section id="sub">
        <div className="sub-common">
          {/* <div className="nav">
            <Link to="/">HOME</Link>
            <Link to="#">MARKET</Link>
          </div> */}
          <img src="/assets/images/common/genomecheck.png" alt="" />
          <div className="tit">Market</div>
          <div className="subTit">원하시는 상품을 선택 후 구매 주세요.</div>
        </div>
      </section>
      <section className="item-container">
        <div className="item-area">
          <ul>
            {itemList?.map((item, index) => (
              <li key={index}>
                <Link to={`/detail/${item.item_id}`}>
                  <div className="img">
                    {
                      <img
                        src={
                          item.thumbnail?.length > 0 &&
                          item.thumbnail[0].pathName
                            ? `/static/thumbnail/${item.thumbnail[0].pathName}`
                            : "../assets/images/default.png"
                        }
                        alt=""
                      />
                    }
                  </div>
                  <div className="text">
                    <div className="price-area">
                      <h3 className="price">
                        {Number(item.item_price).toLocaleString()}원
                      </h3>
                      <div className="icon-area">
                        <img src="/assets/images/market/new.png" alt="new" />
                        <img
                          src="/assets/images/market/recommand.png"
                          alt="recommand"
                        />
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="info">
                      <div className="name">{item.item_name}</div>
                      <div className="description">{item.item_description}</div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default MarketListPage;
