import React, { useEffect, useState } from "react";
import "assets/css/report.css";

const ReportDAType8Page = (props) => {
  const [newResult, setNewResult] = useState();
  const forwardedRef = props.forwardedRef;
  const description = props.dtcDescription;
  const dctInfo = props.dctInfo;
  const result = props.dctInfo?.results;
  const titleLength =
    description.heredity === "0"
      ? 0 + dctInfo.name.length
      : 5 + dctInfo.name.length;
  const chart = () => {
    return (
      <>
        {newResult?.map((item) => {
          return (
            <tr>
              {item?.gene === null ? null : (
                <th
                  style={{ cursor: "pointer" }}
                  rowspan={item?.len}
                  onClick={() => props.clickDetailName(dctInfo.name)}
                >
                  {item?.gene}
                </th>
              )}
              {item?.opposite?.map((data, index) => {
                return (
                  <td>
                    <span
                      classNameName={item.own === data ? "target" : null}
                      style={
                        item.own === data
                          ? index === 0
                            ? { color: "#bd0000", fontWeight: "bold" }
                            : index === 1
                            ? { color: "#001E5C", fontWeight: "bold" }
                            : index === 2
                            ? { color: "#000000", fontWeight: "bold" }
                            : null
                          : null
                      }
                    >
                      {data}
                    </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  };

  const clacScoreName = (dtc_id, value) => {
    let scoreName = "";
    if (
      dtc_id === "RecoveryAfterLoseWeight" ||
      dtc_id === "PainSensitivity" ||
      dtc_id === "TanningReaction" ||
      dtc_id === "AnkleInjury"
    ) {
      if (value >= 70) {
        scoreName = "낮음";
      } else if (value >= 30) {
        scoreName = "보통";
      } else {
        scoreName = "높음";
      }
    } else if (
      dtc_id === "EffectLosingWeight" ||
      dtc_id === "MuscleFitness" ||
      dtc_id === "ExerciseFitness" ||
      dtc_id === "AerobicExercise" ||
      dtc_id === "ShortDistanceRunning" ||
      dtc_id === "RecoverAfterExercising" ||
      dtc_id === "MuscleDevelopment" ||
      dtc_id === "FeelingFull" ||
      dtc_id === "SensitivitySweet" ||
      dtc_id === "SensitivityBitter" ||
      dtc_id === "SensitivitySalty"
    ) {
      if (value >= 70) {
        scoreName = "높음";
      } else if (value >= 30) {
        scoreName = "보통";
      } else {
        scoreName = "낮음";
      }
    } else if (dtc_id === "MorningPerson") {
      if (value >= 70) {
        scoreName = "아침형";
      } else if (value >= 30) {
        scoreName = "보통";
      } else {
        scoreName = "저녁형";
      }
    } else if (dtc_id === "HairThickness") {
      if (value >= 70) {
        scoreName = "굵음";
      } else if (value >= 30) {
        scoreName = "보통";
      } else {
        scoreName = "얇음";
      }
    } else {
      if (value >= 70) {
        scoreName = "양호";
      } else if (value >= 30) {
        scoreName = "관심";
      } else {
        scoreName = "주의";
      }
    }

    return scoreName;
  };

  const clacScore = (value) => {
    return Math.round(100 - value);
  };

  const tableHeader = (dtc_id) => {
    return dtc_id === "RecoveryAfterLoseWeight" ||
      dtc_id === "PainSensitivity" ||
      dtc_id === "TanningReaction" ||
      dtc_id === "AnkleInjury" ? (
      <tr className="title">
        <th></th>
        <td>높음</td>
        <td>보통</td>
        <td>낮음</td>
      </tr>
    ) : dtc_id === "EffectLosingWeight" ||
      dtc_id === "MuscleFitness" ||
      dtc_id === "ExerciseFitness" ||
      dtc_id === "AerobicExercise" ||
      dtc_id === "ShortDistanceRunning" ||
      dtc_id === "RecoverAfterExercising" ||
      dtc_id === "MuscleDevelopment" ||
      dtc_id === "FeelingFull" ||
      dtc_id === "SensitivitySweet" ||
      dtc_id === "SensitivityBitter" ||
      dtc_id === "SensitivitySalty" ? (
      <tr className="title">
        <th></th>
        <td>낮음</td>
        <td>보통</td>
        <td>높음</td>
      </tr>
    ) : dtc_id === "MorningPerson" ? (
      <tr className="title">
        <th></th>
        <td>저녁형</td>
        <td>보통</td>
        <td>아침형</td>
      </tr>
    ) : dtc_id === "HairThickness" ? (
      <tr className="title">
        <th></th>
        <td>얇음</td>
        <td>보통</td>
        <td>굵음</td>
      </tr>
    ) : (
      <tr className="title">
        <th></th>
        <td>주의</td>
        <td>관심</td>
        <td>양호</td>
      </tr>
    );
  };

  useEffect(() => {
    let list = [];
    let newResult = [];
    result.map((item) => {
      let newItem = item;
      let temp = list.find((e) => e === item.gene);
      if (temp === undefined) {
        newItem.len = result.filter((x) => x.gene === item.gene)?.length;
        list.push(item.gene);
      } else {
        newItem.gene = null;
        newItem.len = 0;
      }
      newResult.push(newItem);
    });
    setNewResult(newResult);
  }, [result]);

  return (
    <div className="grui-report" ref={forwardedRef}>
      <div className="grui-page page8">
        <div style={{ display: "flex" }}>
          <h2
            style={
              titleLength === 13
                ? { fontSize: "70px" }
                : titleLength >= 14 && titleLength < 16
                ? { fontSize: "62px" }
                : titleLength === 16
                ? { fontSize: "60px" }
                : titleLength === 17
                ? { fontSize: "60px" }
                : titleLength >= 18
                ? { fontSize: "52px" }
                : null
            }
          >
            {dctInfo.name}
          </h2>
          {description.heredity === "0" ? null : (
            <h3 style={{ margin: "34px 0 0 10px" }}>
              (유전율 : {description.heredity}%)
            </h3>
          )}
        </div>
        <div className="define">{description.define}</div>
        <h3>1. '{dctInfo.name}' 연관 유전자 검사 결과</h3>
        <div className="grui-page8-chart">
          <div className="graph-circle size2">
            <div className="inner-circle">
              <div
                className={
                  clacScore(dctInfo.genetic_danger) >= 70
                    ? "good"
                    : clacScore(dctInfo.genetic_danger) >= 30
                    ? "attention"
                    : "caution"
                }
              >
                <span className="state">
                  {clacScoreName(
                    dctInfo.dtc_id,
                    clacScore(dctInfo.genetic_danger)
                  )}
                </span>
                <span className="data">
                  {clacScore(dctInfo.genetic_danger)}점
                </span>
              </div>
              <svg viewBox="0 0 36 36">
                <path
                  className="circle"
                  stroke-dasharray={`${clacScore(dctInfo.genetic_danger)}, 100`}
                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
              </svg>
            </div>
          </div>
          <div className="graph-table-wrap">
            <table className="graph-table">
              <thead>
                <tr>
                  <th>유전자명</th>
                  <th colspan="3">대립 유전자형</th>
                </tr>
              </thead>
              <tbody>
                {tableHeader(dctInfo.dtc_id)}
                {chart()}
              </tbody>
            </table>
            {/* <p>
              *본 검사 결과는 영양, 생활습관 및 신체적 특징에 따른 질병의 예방을
              위한 유전자검사로 질병의 진단 및 치료의 목적으로 사용될 수 없으며,
              의학적인 소견이 필요한 경우 의사와 상담하시길 권장합니다.
            </p> */}
          </div>
        </div>
        <h3>2. '{dctInfo.name}' 관련 추천 솔루션</h3>
        <ul className="grui-icon-box-list">
          {description?.solution?.map((item, index) => (
            <li key={item.title}>
              <div className="grui-ib-item">
                <div className="grui-ib-icon">
                  <i>
                    <span>{index + 1}</span>
                  </i>
                </div>
                <div className="grui-ib-text">
                  <h4>
                    Solution {index + 1}. {item.title}
                  </h4>
                  <p>{item.content}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <p className="grui-page8-1-info">
          {description?.info?.map((item) => (
            <span>{item}</span>
          ))}
        </p>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType8Page;
