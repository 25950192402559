import React from "react";
import { Link } from "react-router-dom";
import "assets/css/login.css";

const ProductPage = () => {
  // const downloadDocument = () => {
  //   const link = document.createElement("a");
  //   link.href = "/api/download/document";
  //   link.style.display = "none";

  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // };

  const openNewWindow = (e) => {
    e.preventDefault();

    window.open(
      "./static/document/오브미365 유전자검사 서비스 설명문.pdf",
      "_blank",
      "width=900,height=1000"
    );
  };

  return (
    <section id="sub">
      <div className="sub-common">
        {/* <div className="nav">
          <Link to="/">HOME</Link>
          <Link to="#">PRODUCT</Link>
        </div> */}
        <img src="/assets/images/common/genomecheck.png" alt="" />
        <div className="tit">PRODUCT</div>
        <div className="subTit">
          본 검사는 개인의 유전자 정보를 분석하여 개인의 영양 상태, 개인 특성 및
          신체적 특징을 이해하는데 도움을 줍니다. 그렇지만, 분석에 포함되어 있지
          않는 유전자 부위나 환경적요인도 개인 신체 상태에 영향을 줄 수
          있습니다.
        </div>
      </div>

      <div className="product">
        <div className="genomecheck">
          <div className="ofme">
            <div className="sub-tit">오브미365</div>
            <div className="sub-subs">
              보건복지부로 부터 인증 받은 58개의 항목으로 판매가 허용된 상품으로
              SNP Microarray 기술을 이용하여 웰니스 관련 유전자검사를
              진행합니다.
            </div>

            <ul className="ofme-list">
              <li>
                <div className="ct">
                  <div className="ico">
                    <img src="/assets/images/ofme-ico1.png" alt="" />
                  </div>
                  <div className="en">Nutrition</div>
                  <div className="subj">영양소</div>
                  <div className="subs">
                    개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                    유전자에 대한 분석결과 입니다.
                  </div>
                </div>

                <div className="hover">
                  <span>
                    비타민 C 농도, 비타민 D 농도, 비타민 A 농도, 비타민 B6 농도,
                    비타민 B12 농도, 비타민 E 농도, 비타민 K 농도, 오메가-3
                    지방산 농도, 오메가-6 지방산 농도, 아르기닌 농도, 칼슘 농도,
                    마그네슘 농도, 아연 농도, 철 저장 농도, 타이로신 농도,
                    베타인 농도, 셀레늄 농도, 루테인&지아잔틴 농도
                  </span>
                </div>
              </li>

              <li>
                <div className="ct">
                  <div className="ico">
                    <img src="/assets/images/ofme-ico2.png" alt="" />
                  </div>
                  <div className="en">Health Care</div>
                  <div className="subj">건강관리</div>
                  <div className="subs">
                    개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                    유전자에 대한 분석결과 입니다.
                  </div>
                </div>

                <div className="hover">
                  <span>
                    비만, 체지방율, 체질량지수, 복부비만{"("}엉덩이허리비율{")"}
                    , 중성지방 농도, HDL 콜레스테롤 농도, LDL 콜레스테롤 농도,
                    운동에 의한 체중감량효과, 혈당, 혈압, 골질량, 퇴행성관절염증
                    감수성, 요산치, 멀미
                  </span>
                </div>
              </li>

              <li>
                <div className="ct">
                  <div className="ico">
                    <img src="/assets/images/ofme-ico3.png" alt="" />
                  </div>
                  <div className="en">Personal characteristics</div>
                  <div className="subj">개인특성</div>
                  <div className="subs">
                    개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                    유전자에 대한 분석결과 입니다.
                  </div>
                </div>

                <div className="hover">
                  <span>
                    알코올의존성, 알코올홍조, 니코틴대사, 니코틴의존성,
                    카페인대사, 카페인의존성, 불면증, 아침형, 저녁형 인간
                  </span>
                </div>
              </li>

              <li>
                <div className="ct">
                  <div className="ico">
                    <img src="/assets/images/ofme-ico4.png" alt="" />
                  </div>
                  <div className="en">Skin / Hair</div>
                  <div className="subj">피부 / 모발</div>
                  <div className="subs">
                    개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                    유전자에 대한 분석결과 입니다.
                  </div>
                </div>

                <div className="hover">
                  <span>
                    기미/주근깨, 색소침착, 여드름발생, 피부노화, 피부염증,
                    태양노출후 태닝반응, 새치, 원형탈모
                  </span>
                </div>
              </li>

              <li>
                <div className="ct">
                  <div className="ico">
                    <img src="/assets/images/ofme-ico5.png" alt="" />
                  </div>
                  <div className="en">Athletic ability</div>
                  <div className="subj">운동능력</div>
                  <div className="subs">
                    개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                    유전자에 대한 분석결과 입니다.
                  </div>
                </div>

                <div className="hover">
                  <span>
                    근력운동적합성, 지구력운동적합성, 유산소운동적합성,
                    단거리질주능력, 운동후회복능력, 근육발달능력
                  </span>
                </div>
              </li>

              <li>
                <div className="ct">
                  <div className="ico">
                    <img src="/assets/images/ofme-ico6.png" alt="" />
                  </div>
                  <div className="en">Eating habits</div>
                  <div className="subj">식습관</div>
                  <div className="subs">
                    개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                    유전자에 대한 분석결과 입니다.
                  </div>
                </div>

                <div className="hover">
                  <span>단맛민감도, 쓴맛민감도, 짠맛민감도, 포만감</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="banner">
            <div className="txA">
              <div className="subj">SNP Microarray</div>
              <div className="tx">
                보건복지부로 부터 인증 받은 항목으로 판매가 허용된 상품으로 SNP
                Microarray 기술을 이용하여 웰니스 관련 유전자검사를 진행합니다.
              </div>
            </div>
            <div className="thum">
              <img src="/assets/images/genomecheck.png" alt="" />
            </div>
          </div>

          <div className="downloadBtn" onClick={(e) => openNewWindow(e)}>
            <Link to="#">
              상세사용설명서 파일 다운로드
              <span className="ico">
                <img src="/assets/images/downloadBtn.png" alt="" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPage;
