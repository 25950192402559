import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomRegExp } from "helpers/customRegExp";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import SearchAddress from "components/common/searchAddress";
import "assets/css/board.css";

const UserInfoPage = (props) => {
  const [isAddress, setIsAddress] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const userpwd = useRef();
  userpwd.current = watch("user_pw");

  const searchAddr = () => {
    setIsAddress(!isAddress);
  };

  const handleCompanyPostNumberAddr = (data) => {
    setValue("user_post", data.user_post);
    setValue("user_addr1", data.user_addr1);
  };

  const updateUserInfo = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.updateUserInfo,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          navigate("/");
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const exitRequestUser = () => {
    if (window.confirm("회원 탈퇴를 하시겠습니까? ")) {
      axiosFetch({
        method: "post",
        url: apiUrls.exitRequestUser,
        requestConfig: { user_id: props.user?.user_id },
      })
        .then((res) => {
          if (res.success) {
            alert(res.message);
            navigate("/");
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {});
    }
  };

  const updatePassword = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.updatePassword,
      requestConfig: {
        user_id: props.user?.user_id,
        ori_pw: reqData.ori_pw,
        user_pw: reqData.user_pw,
      },
    })
      .then((res) => {
        alert(res.message);
        if (res.success) {
          navigate("/");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setValue("user_id", props.user?.user_id);
    setValue("user_post", props.user?.user_post);
    setValue("user_addr1", props.user?.user_addr1);
    setValue("user_addr2", props.user?.user_addr2);
    setValue("user_name", props.user?.user_name);
    setValue("user_birth", props.user?.user_birth);
    setValue("user_phone", props.user?.user_phone);
  }, [props.user]);

  return (
    <>
      {isAddress ? (
        <SearchAddress
          searchAddr={searchAddr}
          setAddr={handleCompanyPostNumberAddr}
        />
      ) : isPassword ? (
        <>
          <div className="container">
            <div className="content-box5">
              <img src="/assets/images/common/genomecheck.png" alt="" />
              <div className="main-title">
                <span>비밀번호 변경</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-box4">
              <div className="board-list">
                <div className="board-info">
                  <div className="add-sample-user">
                    <form
                      className="input-area"
                      onSubmit={handleSubmit(updatePassword)}
                    >
                      <InputUnit
                        label="ori_pw"
                        type="password"
                        holder="현재 비밀번호"
                        register={register("ori_pw", {
                          required: "필수 입력 값 입니다.",
                        })}
                        errors={formErrors}
                      />
                      <InputUnit
                        label="password"
                        type="password"
                        holder="새 비밀번호"
                        register={register("user_pw", {
                          required: "패스워드는 필수 입력 값 입니다.",
                          minLength: {
                            value: 8,
                            message: "패스워드는 최소 8글자 이상이어야 합니다.",
                          },
                        })}
                        errors={formErrors}
                      />
                      <InputUnit
                        label="password_confirm"
                        type="password"
                        holder="새 비밀번호 확인"
                        register={register("user_pw_confirm", {
                          required: "패스워드는 필수 입력 값 입니다.",
                          validate: (value) => value === userpwd.current,
                        })}
                        errors={formErrors}
                      />
                      <div className="board-btn-area">
                        <button className="btn2" type="submit">
                          변경하기
                        </button>
                        <button
                          className="btn2"
                          type="button"
                          style={{ background: "#515151" }}
                          onClick={(e) => setIsPassword(false)}
                        >
                          취소
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div className="content-box4">
              <div className="login">
                <form
                  className="input-area"
                  style={{ maxWidth: "600px", marginTop: "50px" }}
                  onSubmit={handleSubmit(updateUserInfo)}
                >
                  <InputUnit
                    disabled={true}
                    label="ID"
                    holder="ID"
                    register={register("user_id", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="Phone"
                    holder="Phone"
                    register={register("user_phone", {
                      required: true,
                      pattern: CustomRegExp("tel"),
                    })}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button className="btn-post" onClick={searchAddr}>
                      주소검색
                    </button>
                  </div>
                  <InputUnit
                    disabled={true}
                    label="Post"
                    holder="우편번호"
                    register={register("user_post", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Addr1"
                    holder="주소"
                    register={register("user_addr1", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="Addr2"
                    holder="상세주소"
                    register={register("user_addr2", {
                      required: "주소는 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Name"
                    holder="Name"
                    register={register("user_name", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Birth"
                    holder="생년월일 8자리 ex(1990년 01월 14일, 19900114)"
                    register={register("user_birth", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button
                      className="btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      회원정보수정
                    </button>
                  </div>
                  <div className="board-btn-area">
                    <button
                      className="btn"
                      type="button"
                      onClick={(e) => setIsPassword(true)}
                    >
                      비밀번호변경하기
                    </button>
                  </div>
                  <div className="board-btn-area">
                    <button
                      className="btn"
                      type="button"
                      style={{ backgroundColor: "red" }}
                      onClick={exitRequestUser}
                    >
                      회원탈퇴하기
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserInfoPage;
