import React from "react";
import "assets/css/report.css";

const ReportDAType5Page = (props) => {
  const { title, content, icon, clickName } = props;

  return (
    <div className="grui-report">
      <div className="grui-page page5">
        <div className="grui-tobira type1">
          <div className="grui-tobira-box">
            <div className="grui-tobira-title">
              <div className="grui-tobira-icon">
                <i className={icon}></i>
              </div>
              <div className="grui-tobira-text">
                <h2>{title}</h2>
                <p>항목별 유전자 분석 결과</p>
              </div>
            </div>
            {/* <div className="grui-tobira-logo">
              <img src="../assets/images/logo_bottom_white.png" alt="" />
            </div> */}
          </div>
          <div
            className="grui-tobira-navi"
            style={
              title === "영양소"
                ? { padding: "30px 40px 0 80px" }
                : title === "건강관리"
                ? { padding: "30px 40px 0 80px" }
                : null
            }
          >
            <div
              className="grui-tobira-navi-title"
              style={title === "영양소" ? { marginBottom: "25px" } : null}
            >
              Contents
            </div>
            <ul>
              {content?.map((item) => (
                <li
                  style={
                    title === "영양소"
                      ? { lineHeight: "0.95em", cursor: "pointer" }
                      : { cursor: "pointer" }
                  }
                  key={item}
                  onClick={() => clickName(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
            <div className="grui-tobira-bg">
              <i classNameName={icon}></i>
            </div>
          </div>
        </div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType5Page;
