import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userInfoState } from "recoil/userStore";
import { useRecoilState, useRecoilValue } from "recoil";
import { userListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import Select from "react-select";
import CustomModal from "components/popup/customModal";
import UserInfoDetailPage from "./user/userInfoDetailPage";
import "assets/css/board.css";
import moment from "moment";
import { SaveOutlined, PersonAddAltOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 15px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const UserListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userList, setUserList] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [userInfoModalIsOpen, setUserInfoModalIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [userListPage, setUserListPage] = useRecoilState(
    userListPageIndexState
  );
  const adminInfo = useRecoilValue(userInfoState);

  const selectUserTypeOptions = [
    { value: "U", label: "유저" },
    { value: "A", label: "총괄책임자" },
    { value: "D", label: "결과전달담당자" },
    { value: "S", label: "검사서비스담당자" },
    { value: "I", label: "결과정보처리담당자" },
  ];

  const openUserInfoModal = (e, item) => {
    e.preventDefault();
    setUserInfo(item);
    setUserInfoModalIsOpen(true);
  };

  const closeUserInfoModal = () => {
    setUserInfoModalIsOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const selectedUserTypeHandler = (e, data) => {
    const updatedItems = userList.map((item) => {
      if (item.user_id === data.user_id) {
        return { ...item, user_type: e.value };
      }
      return item;
    });

    // 업데이트된 아이템으로 상태를 업데이트합니다.
    setUserList(updatedItems);
  };

  const getUserList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getUserList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
      },
    })
      .then((res) => {
        if (res.success) {
          setUserResult(res.result);
          setUserList(res.userList);
          setPageList(res.pageList);
          setUserListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getUserList : ", err);
      });
  };

  const updateUserType = (e, reqData) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.updateUserType,
      requestConfig: reqData,
    })
      .then((res) => {
        alert(res.message);
      })
      .catch((err) => {
        //console.log("[ERROR] updateUserType : ", err);
      });
  };

  useEffect(() => {
    getUserList(userListPage);
  }, []);

  return (
    <div className="container-n-admin" style={{ margin: "0 300px" }}>
      <div className="content-area">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {userResult.totalrows}
            <span>건의 유저</span>
          </div>
          <div className="board-search-area">
            <div className="check">
              <label>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    width: "16px",
                    height: "16px",
                  }}
                  checked={isDateRangeVisible}
                  onChange={handleCheckboxChange}
                ></input>
                기간설정
              </label>
            </div>

            {isDateRangeVisible && (
              <div className="date-range">
                <div className="date-picker">
                  <label>
                    시작 날짜:
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>
                  <label>
                    종료 날짜:
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            )}

            <div className="board-search">
              <input
                type="text"
                placeholder="검색어를 입력해 주세요"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    getUserList(1);
                  }
                }}
              />
              <button
                type="button"
                onClick={(e) => {
                  getUserList(1);
                }}
              >
                검색
              </button>
            </div>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>고객번호</th>
                <th>ID</th>
                <th>이름</th>
                <th>생년월일</th>
                <th>가입일</th>
                <th>유저타입</th>
                <th>유저확인</th>
              </tr>
            </thead>
            <tbody>
              {userList?.map((item, index) => (
                <tr key={item.user_number}>
                  <td>
                    <div>{item.user_number}</div>
                  </td>
                  <td>
                    <div>{item.user_id}</div>
                  </td>
                  <td>
                    <div>{item.user_name}</div>
                  </td>
                  <td>
                    <div>{moment(item.user_birth).format("YYYY/MM/DD")}</div>
                  </td>
                  <td>
                    <div>{moment(item.signup_date).format("YYYY/MM/DD")}</div>
                  </td>
                  <td>
                    <div className="select-area">
                      <Select
                        className="select"
                        options={selectUserTypeOptions}
                        value={selectUserTypeOptions.filter(
                          (option) => option.value === item.user_type
                        )}
                        onChange={(e) => selectedUserTypeHandler(e, item)}
                        isDisabled={adminInfo?.user_type === "I"}
                      ></Select>
                      <button
                        style={{ background: "transparent" }}
                        onClick={(e) => {
                          updateUserType(e, item);
                        }}
                        disabled={adminInfo?.user_type === "I"}
                      >
                        <IconWrapper>
                          <SaveOutlined fontSize="medium" />
                        </IconWrapper>
                      </button>
                    </div>
                  </td>
                  <td>
                    <div>
                      <button
                        style={{ background: "transparent" }}
                        onClick={(e) => {
                          openUserInfoModal(e, item);
                        }}
                      >
                        <IconWrapper>
                          <PersonAddAltOutlined fontSize="medium" />
                        </IconWrapper>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <ul className="paging">
            <li>
              <Link
                onClick={() =>
                  getUserList(
                    userResult.currentPage - 10 < 1
                      ? 1
                      : userResult.currentPage - 10
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardFirst.png" alt="" />
              </Link>
            </li>
            {pageList?.map((item) => (
              <li
                key={item}
                className={item === userResult.currentPage ? "on" : null}
              >
                <Link
                  onClick={() => getUserList(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </Link>
              </li>
            ))}

            <li>
              <Link
                onClick={() =>
                  getUserList(
                    pageList[pageList.length - 1] + 1 < userResult.maxPage
                      ? pageList[pageList.length - 1] + 1
                      : pageList[pageList.length - 1]
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardLast.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>

        <CustomModal
          isOpen={userInfoModalIsOpen}
          onRequestClose={closeUserInfoModal}
          maxHeight="565px"
          minHeight="565px"
          minWidth="960px"
        >
          <UserInfoDetailPage
            userInfo={userInfo}
            closeUserInfoModal={closeUserInfoModal}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default UserListPage;
