import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";
import { CustomRegExp } from "helpers/customRegExp";
import InputUnit from "components/InputUnit";
import SearchAddress from "components/common/searchAddress";
import Signature from "components/common/signature";
import Select from "react-select";
import moment from "moment";
import "assets/css/board.css";
import { ContactSupportOutlined } from "@mui/icons-material";

const UserReqPage = (props) => {
  const [isAddress, setIsAddress] = useState(false);
  const [userData, setUserData] = useState(null);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [isAgree, setIsAgree] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });
  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "25px",
      height: "54px",
    }),
    control: (provided) => ({
      ...provided,
      margin: "10px 0",
      borderRadius: "0",
    }),
  };
  const signatureRef = useRef(null);
  const personalInfoAgreeRef = useRef(null);
  const sensitiveInfoAgreeRef = useRef(null);
  const userIdRef = useRef(null);
  const userPostRef = useRef(null);
  const userAddr1Ref = useRef(null);
  const userAddr2Ref = useRef(null);
  const userNameRef = useRef(null);
  const userBirthRef = useRef(null);
  const userGenderRef = useRef(null);
  const userPhoneRef = useRef(null);

  const handleInvalid = (errors) => {
    if (errors.user_id) {
      scrollToRef(userIdRef);
    } else if (errors.user_post) {
      scrollToRef(userPostRef);
    } else if (errors.user_addr1) {
      scrollToRef(userAddr1Ref);
    } else if (errors.user_addr2) {
      scrollToRef(userAddr2Ref);
    } else if (errors.user_name) {
      scrollToRef(userNameRef);
    } else if (errors.user_birth) {
      scrollToRef(userBirthRef);
    } else if (errors.user_gender) {
      scrollToRef(userGenderRef);
    } else if (errors.user_phone) {
      scrollToRef(userPhoneRef);
    } else if (errors.personal_information_agree1) {
      scrollToRef(personalInfoAgreeRef);
    } else if (errors.sensitive_information_agree) {
      scrollToRef(sensitiveInfoAgreeRef);
    }
  };

  const searchAddr = () => {
    setIsAddress(!isAddress);
  };

  const downloadDocument = (e) => {
    e.preventDefault();

    const link = document.createElement("a");
    link.href = "./static/document/오브미365-62종 상세사용 설명서.pdf"; // 다운로드할 PDF URL
    link.download = "오브미365-62종_상세사용_설명서.pdf"; // 다운로드할 파일 이름
    document.body.appendChild(link);
    link.click(); // 링크 클릭으로 다운로드 시작
    document.body.removeChild(link); // 링크 요소 제거
  };

  const handleCompanyPostNumberAddr = (data) => {
    setValue("user_post", data.user_post);
    setValue("user_addr1", data.user_addr1);
  };

  const getUserInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserInfo,
      requestConfig: { user_id: props.item?.user_id },
    })
      .then((res) => {
        if (res.success) {
          setUserData(res.userInfo);
        }
      })
      .catch((err) => {});
  };

  const handleCheckboxChange = (event) => {
    setIsAgree(event.target.checked);
    if (event.target.checked) setIsSign(true);
  };

  const handleYesNoChange = (event) => {
    if (event.target.value === "yes") {
      setValue("user_id", userData?.user_id);
      setValue("user_post", userData?.user_post);
      setValue("user_addr1", userData?.user_addr1);
      setValue("user_addr2", userData?.user_addr2);
      setValue("user_name", userData?.user_name);
      setValue("user_phone", userData?.user_phone);
      setValue(
        "user_gender",
        Number(userData?.user_gender) === 0
          ? { value: 0, label: "여자" }
          : { value: 1, label: "남자" }
      );
      setValue("user_birth", userData?.user_birth);
    } else {
      setValue("user_id", null);
      setValue("user_post", null);
      setValue("user_addr1", null);
      setValue("user_addr2", null);
      setValue("user_name", null);
      setValue("user_phone", null);
      setValue("user_gender", null);
      setValue("user_birth", null);
    }
  };

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const addRequest = (reqData) => {
    if (reqData.user_gender !== null) {
      reqData.user_gender = reqData.user_gender.value;
    }

    if (signatureData === "") {
      scrollToRef(signatureRef);
      alert("유전자검사동의 및 서명을 해주세요.");
      return;
    }
    if (reqData.personal_information_agree1 === "disagree") {
      scrollToRef(personalInfoAgreeRef);
      alert("개인정보수집‧이용‧제공 동의서 필수항목에 동의해주세요.");
      return;
    }
    if (reqData.sensitive_information_agree === "disagree") {
      scrollToRef(sensitiveInfoAgreeRef);
      alert("민감정보 동의서 필수항목에 동의해주세요.");
      return;
    }

    const requestDataWithSignature = {
      ...reqData,
      signatureData: signatureData,
      complete_id: props.item?.complete_id,
      item_name: props.item?.item_name,
    };

    axiosFetch({
      method: "post",
      url: apiUrls.addRequest,
      requestConfig: requestDataWithSignature,
    })
      .then((res) => {
        if (res.success) {
          alert("회수 신청 완료 되었습니다.");
          props.setIsReq(false);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      {isAddress === false ? (
        <>
          <div className="container">
            <div className="content-box3">
              <div>
                <form
                  className="input-area"
                  style={{ maxWidth: "100%" }}
                  onSubmit={handleSubmit(addRequest, handleInvalid)}
                >
                  <div className="agree-area">
                    <div className="more-detail">
                      <h1>키트에 동봉한 검체가 본인의 것인가요?</h1>
                      <div className="dl check">
                        <span>
                          <label>
                            <input
                              type="radio"
                              {...register("my_information_confirm")}
                              value="yes"
                              onChange={handleYesNoChange}
                            />
                            예
                          </label>
                        </span>
                        <span>
                          <label>
                            <input
                              type="radio"
                              {...register("my_information_confirm")}
                              value="no"
                              onChange={handleYesNoChange}
                            />
                            아니오
                          </label>
                        </span>
                      </div>
                    </div>

                    <div
                      className="board-btn-area"
                      style={{ paddingTop: "30px" }}
                    >
                      <button className="btn-post" onClick={searchAddr}>
                        주소검색
                      </button>
                    </div>
                    <div ref={userPostRef}>
                      <InputUnit
                        disabled={true}
                        label="Post"
                        holder="우편번호"
                        register={register("user_post", {
                          required: true,
                        })}
                        errors={formErrors}
                      />
                    </div>
                    <div ref={userAddr1Ref}>
                      <InputUnit
                        disabled={true}
                        label="Addr1"
                        holder="주소"
                        register={register("user_addr1", {
                          required: true,
                        })}
                        errors={formErrors}
                      />
                    </div>
                    <div ref={userAddr2Ref}>
                      <InputUnit
                        ref={userAddr2Ref}
                        label="Addr2"
                        holder="상세주소"
                        register={register("user_addr2", {
                          required: "주소는 필수 입력 값 입니다.",
                        })}
                        errors={formErrors}
                      />
                    </div>
                    <div ref={userNameRef}>
                      <InputUnit
                        label="Name"
                        holder="Name"
                        register={register("user_name", {
                          required: "이름은 필수 입력 값 입니다.",
                        })}
                        errors={formErrors}
                      />
                    </div>
                    <div ref={userBirthRef}>
                      <InputUnit
                        holder="생년월일 8자리 ex(1990년 01월 14일, 19900114)"
                        register={register("user_birth", {
                          required: "생년월일은 필수 입력 값 입니다.",
                          pattern: CustomRegExp("birth"),
                        })}
                        errors={formErrors}
                      />
                    </div>
                    <div ref={userGenderRef}>
                      <Controller
                        name="user_gender"
                        control={control}
                        rules={{ required: "성별을 선택해주세요." }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            className="select"
                            options={[
                              { value: 0, label: "여자" },
                              { value: 1, label: "남자" },
                            ]}
                            onChange={(selectedOption) => {
                              setValue("user_gender", selectedOption);
                              field.onChange(selectedOption);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div ref={userPhoneRef}>
                      <InputUnit
                        label="Phone"
                        holder="연락처"
                        register={register("user_phone", {
                          required: "연락처는 필수 입력 값 입니다.",
                          pattern: CustomRegExp("tel"),
                        })}
                        errors={formErrors}
                      />
                    </div>
                  </div>

                  <div className="agree-area">
                    <h1>개인정보수집‧이용‧제공 동의서</h1>
                    <div className="agree-detail">
                      디엑스앤브이엑스(검사기관)는 유전자검사서비스(DTC) 제공을
                      위하여 개인정보 보호법 제15조 및 제17조에 따라 아래와 같이
                      개인정보를 수집∙이용 제공하고자 합니다.
                      <br /> 내용을 자세히 읽으신 후, 동의 여부를 결정하여
                      주십시오. 수집된 정보는 동의한 내용 외 다른 목적으로
                      사용되지 않습니다.
                      <br /> 제공된 개인정보는 dtc@dxvx.com을 통해 열람, 정정,
                      삭제를 요구할 수 있습니다.
                    </div>
                    <div className="agree-content">
                      <span className="title">
                        1. <span className="red-color">[필수]</span> 개인정보
                        수집 및 이용에 대한 동의
                      </span>
                      <table className="agree-area-tb">
                        <thead>
                          <tr>
                            <th scope="cols">수집항목</th>
                            <th scope="cols">수집‧이용 목적</th>
                            <th scope="cols">이용 및 보유기간</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              성명, 전화번호, E-mail, 주소, 생년월일, 성별
                            </td>
                            <td>
                              본인 확인 및 고객관리, 검사결과지 제공, 본 서비스
                              제공
                            </td>
                            <td>검사 종료 후 10년 혹은 철회 시까지</td>
                          </tr>
                        </tbody>
                      </table>
                      <span className="content">
                        위의 개인정보 수집‧이용에 대한 동의를 거절할 권리가
                        있습니다.
                      </span>
                      <span className="content red-color">
                        동의를 거부할 경우 본 서비스 이용이 제한될 수 있습니다.
                      </span>
                      <div className="content check" ref={personalInfoAgreeRef}>
                        <span>
                          위와 같이 개인정보를 수집이용하는데 동의하십니까?
                        </span>

                        <span>
                          <label>
                            <input
                              name="personal_information_agree1"
                              type="radio"
                              {...register("personal_information_agree1", {
                                required: true,
                              })}
                              value="agree"
                            />
                            동의
                          </label>
                        </span>
                        <span>
                          <label>
                            <input
                              name="personal_information_agree1"
                              type="radio"
                              {...register("personal_information_agree1", {
                                required: true,
                              })}
                              value="disagree"
                            />
                            비동의
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="agree-content">
                      <span className="title">
                        2. 개인정보처리 위탁에 대한 정보
                      </span>
                      <table className="agree-area-tb">
                        <thead>
                          <tr>
                            <th scope="cols">개인정보를 제공 받는 자</th>
                            <th scope="cols">
                              제공 받는 자의 개인정보 이용 목적
                            </th>
                            <th scope="cols">
                              제공 받는 자의 개인정보 이용 및 보유기간
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>한진택배</td>
                            <td>제품(검체채취키트)의 배송</td>
                            <td>회원 탈퇴 또는 위탁계약 종료 시까지</td>
                          </tr>
                          <tr>
                            <td>NICE페이먼츠</td>
                            <td>안전결제 서비스</td>
                            <td>회원 탈퇴 또는 위탁계약 종료 시까지</td>
                          </tr>
                          <tr>
                            <td>ITEASY</td>
                            <td>SMS/MMS 발송</td>
                            <td>회원 탈퇴 또는 위탁계약 종료 시까지</td>
                          </tr>
                          <tr>
                            <td>NICE아이디</td>
                            <td>휴대폰 본인인증</td>
                            <td>회원 탈퇴 또는 위탁계약 종료 시까지</td>
                          </tr>
                          <tr>
                            <td>ITEASY cloud</td>
                            <td>인프라 제공</td>
                            <td>회원 탈퇴 또는 위탁계약 종료 시까지</td>
                          </tr>
                        </tbody>
                      </table>
                      <span className="content">
                        위와 같이 개인정보처리를 위탁합니다.
                      </span>
                    </div>

                    <div className="agree-content">
                      <span className="title">
                        3. [선택] 마케팅, 홍보를 위한 개인정보 수집 및 이용에
                        대한 동의
                      </span>
                      <table className="agree-area-tb">
                        <thead>
                          <tr>
                            <th scope="cols">수집항목</th>
                            <th scope="cols">수집 목적</th>
                            <th scope="cols">이용 및 보유기간</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>성명, 생년월일, 성별, 전화번호, E-mail</td>
                            <td>마케팅, 홍보</td>
                            <td>3년 혹은 철회 시까지</td>
                          </tr>
                        </tbody>
                      </table>
                      <span className="content">
                        위의 마케팅, 홍보를 위한 개인정보 수집‧이용에 대한
                        동의를 거부할 권리가 있습니다.
                      </span>
                      <span className="content">
                        거부하더라도 본 서비스를 제공 받을 수 있습니다.
                      </span>
                      <span className="content">
                        동의를 거부할 경우 추후 마케팅 및 홍보 정보를 받으실 수
                        없습니다.
                      </span>
                      <div className="content check">
                        <span>
                          위와 같이 개인정보를 수집이용하는데 동의하십니까?
                        </span>
                        <span>
                          <label>
                            <input
                              name="personal_information_agree2"
                              type="radio"
                              {...register("personal_information_agree2", {
                                required: true,
                              })}
                              value="agree"
                            />
                            동의
                          </label>
                        </span>
                        <span>
                          <label>
                            <input
                              name="personal_information_agree2"
                              type="radio"
                              {...register("personal_information_agree2", {
                                required: true,
                              })}
                              value="disagree"
                            />
                            비동의
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="agree-content">
                      <span className="title">
                        {moment().format("YYYY년 MM월 DD일")}
                      </span>
                      <span className="title">
                        검사대상자 {watch("user_name")}
                      </span>
                    </div>
                  </div>

                  <div className="agree-area">
                    <h1>민감정보수집‧이용 동의서</h1>
                    <div className="agree-detail">
                      디엑스앤브이엑스(검사기관)는 유전자검사서비스(DTC) 제공을
                      위하여 개인정보 보호법 제15조, 제17조, 제23조에 따라
                      아래와 같이 민감정보를 수집∙이용하고자 합니다.
                      <br /> 내용을 자세히 읽으신 후, 동의 여부를 결정하여
                      주십시오. 수집된 정보는 동의한 내용 외 다른 목적으로
                      사용되지 않습니다.
                    </div>
                    <div className="agree-content">
                      <span className="title">
                        <span className="red-color">[필수]</span> 민감정보 수집
                        및 이용에 대한 동의
                      </span>
                      <table className="agree-area-tb">
                        <thead>
                          <tr>
                            <th scope="cols">수집항목</th>
                            <th scope="cols">수집‧이용 목적</th>
                            <th scope="cols">이용 및 보유기간</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>유전정보</td>
                            <td>검사결과지 제공, 본 서비스 제공</td>
                            <td>검사 종료 후 10년 혹은 철회 시까지</td>
                          </tr>
                        </tbody>
                      </table>
                      <span className="content">
                        위의 민감정보 수집‧이용에 대한 동의를 거부할 권리가
                        있습니다. 그러나
                        <span className="red-color">
                          동의를 거부할 경우 본 DTC 유전자검사 서비스 제공이
                          제한 될 수 있습니다.
                        </span>
                      </span>
                      <div
                        className="content check"
                        ref={sensitiveInfoAgreeRef}
                      >
                        <span>
                          위와 같이 민감정보를 수집이용하는데 동의하십니까?
                        </span>

                        <span>
                          <label>
                            <input
                              name="sensitive_information_agree"
                              type="radio"
                              {...register("sensitive_information_agree", {
                                required: true,
                              })}
                              value="agree"
                            />
                            동의
                          </label>
                        </span>
                        <span>
                          <label>
                            <input
                              name="sensitive_information_agree"
                              type="radio"
                              {...register("sensitive_information_agree", {
                                required: true,
                              })}
                              value="disagree"
                            />
                            비동의
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="agree-content">
                      <span className="title">
                        {moment().format("YYYY년 MM월 DD일")}
                      </span>
                      <span className="title">
                        검사대상자 {watch("user_name")}
                      </span>
                    </div>
                  </div>

                  <div className="agree-area">
                    <h1>DTC 유전자검사 신청서</h1>
                    <div className="agree-content">
                      <span className="title">1. 검사대상자</span>
                      <table className="agree-area-tb">
                        <tbody>
                          <tr>
                            <td style={{ width: "25%" }} className="head">
                              성 명
                            </td>
                            <td style={{ width: "25%" }}>
                              <span>{watch("user_name")}</span>
                            </td>
                            <td style={{ width: "25%" }} className="head">
                              성별
                            </td>
                            <td style={{ width: "25%" }}>
                              <span>{watch("user_gender")?.label}</span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "25%" }} className="head">
                              생년월일
                            </td>
                            <td style={{ width: "25%" }}>
                              <span>{watch("user_birth")}</span>
                            </td>
                            <td style={{ width: "25%" }} className="head">
                              검사신청제품
                            </td>
                            <td style={{ width: "25%" }}>
                              {props.item?.item_name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="agree-content">
                      <span className="title">2. 검체정보</span>
                      <table className="agree-area-tb">
                        <tbody>
                          <tr>
                            <td className="head">검체 채취일</td>
                            <td>
                              <InputUnit
                                style={{ margin: "0px" }}
                                holder="검체 채취일  ex(2024년 05월 14일, 20240514)"
                                register={register("collection_date", {
                                  pattern: CustomRegExp("date"),
                                  required: true,
                                })}
                                errors={formErrors}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="head">검체종류</td>
                            <td style={{ textAlign: "start" }}>
                              <label>
                                <input
                                  type="checkbox"
                                  name="specimen_type"
                                  checked={true}
                                  disabled={true}
                                />
                                구강상피세포 (Buccal Swab)
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td className="head">인종</td>
                            <td
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "start",
                              }}
                            >
                              <label
                                style={{
                                  marginBottom: "8px",
                                  marginRight: "8px",
                                }}
                              >
                                <input
                                  type="radio"
                                  name="race"
                                  value="korean"
                                  {...register("race", {
                                    required: true,
                                  })}
                                />
                                한 국 계
                              </label>
                              <label style={{ marginBottom: "8px" }}>
                                <input
                                  type="radio"
                                  name="race"
                                  value="asian"
                                  {...register("race", {
                                    required: true,
                                  })}
                                />
                                아시아계
                              </label>
                              {watch("race") === "asian" ? (
                                <span style={{ marginBottom: "8px" }}>
                                  ※ 자세한 국적을 작성하여 주십시오.
                                  <InputUnit
                                    style={{ margin: "0px" }}
                                    holder="※ 자세한 국적을 작성하여 주십시오."
                                    register={register("race_info", {
                                      required: false,
                                    })}
                                    errors={formErrors}
                                  />
                                </span>
                              ) : null}
                              <label style={{ marginBottom: "8px" }}>
                                <input
                                  type="radio"
                                  name="race"
                                  value="european"
                                  {...register("race", {
                                    required: true,
                                  })}
                                />
                                유 럽 계
                              </label>
                              {watch("race") === "european" ? (
                                <span style={{ marginBottom: "8px" }}>
                                  ※ 자세한 국적을 작성하여 주십시오.
                                  <InputUnit
                                    style={{ margin: "0px" }}
                                    holder="※ 자세한 국적을 작성하여 주십시오."
                                    register={register("race_info", {
                                      required: false,
                                    })}
                                    errors={formErrors}
                                  />
                                </span>
                              ) : null}
                              <label style={{ marginBottom: "8px" }}>
                                <input
                                  type="radio"
                                  name="race"
                                  value="american"
                                  {...register("race", {
                                    required: true,
                                  })}
                                />
                                아메리카계
                              </label>
                              {watch("race") === "american" ? (
                                <span style={{ marginBottom: "8px" }}>
                                  ※ 자세한 국적을 작성하여 주십시오.
                                  <InputUnit
                                    style={{ margin: "0px" }}
                                    holder="※ 자세한 국적을 작성하여 주십시오."
                                    register={register("race_info", {
                                      required: false,
                                    })}
                                    errors={formErrors}
                                  />
                                </span>
                              ) : null}
                              <label style={{ marginBottom: "8px" }}>
                                <input
                                  type="radio"
                                  name="race"
                                  value="etc"
                                  {...register("race", {
                                    required: true,
                                  })}
                                />
                                기 타
                              </label>
                              {watch("race") === "etc" ? (
                                <span style={{ marginBottom: "8px" }}>
                                  ※ 자세한 국적을 작성하여 주십시오.
                                  <InputUnit
                                    style={{ margin: "0px" }}
                                    holder="※ 자세한 국적을 작성하여 주십시오."
                                    register={register("race_info", {
                                      required: false,
                                    })}
                                    errors={formErrors}
                                  />
                                </span>
                              ) : null}
                              ※ 획득된 국적에 의한 구분이 아닌, 생물학적 인종을
                              의미합니다.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="agree-content">
                      <span className="title">3. 신청서 작성방법</span>
                      <div className="content inner">
                        <span>
                          - 유전자검사 신청자는 본 유전자 검사 신청서의 기재항목
                          (키트번호, 성명, 성별, 생년월일, 검사신청 상품, 검체
                          채취일, 검체종류) 에 빠짐없이 작성하여 주십시오. 기재
                          누락 시 검사가 진행되지 않습니다.
                        </span>
                      </div>
                      <div className="content">
                        <span>
                          - 누락 항목에 대해서 관리담당자가 신청자 본인 신분을
                          확인하고 항목을 채울 수 있도록 문자나
                          전화(02-6964-8741)를 통하여 연락을 할 수 가 있습니다.
                        </span>
                      </div>
                      <div className="content">
                        <span>
                          - 검체 채취가 끝나면 지놈체크솔루션 페이지
                          (https://genomechecksolution.co.kr/)에서 회수 신청을
                          하여 주십시오.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="agree-area">
                    <h1>유전자검사동의</h1>
                    <div className="agree-detail">
                      1. 이 유전자검사의 결과는 10년간 보존되며, 법
                      제52조제2항에 따라 본인이나 법정대리인이 요청하는 경우
                      열람할 수 있습니다.
                      <br />
                      <br />
                      2. 유전자검사기관의 폐업 등 검사대상물 등을 보존할 수 없는
                      경우에는 검사대상물 및 검사동의서, 개인정보가 포함된
                      유전정보에 관한 기록물과 그 관리대장은 법에서 정한 절차에
                      따라 폐기됩니다. 다만, 보존 중에 검사대상자가 다른
                      검사기관이나 질병관리청으로 이관을 요청하는 경우에는
                      이관할 수 있습니다.
                      <br />
                      <br />
                      3. 유전자검사기관은 동의 받은 목적 외 검사대상물 및 관련
                      정보를 보존·이용 또는 제공할 수 없습니다. 검사 후 남은
                      검사대상물을 연구목적으로 이용하기 위해서는 연구목적 및
                      분야, 제공되는 정보의 범위 등에 관한 구체적인 설명을
                      충분히 듣고 인 체유래물연구 또는 허가받은 인체유래물은행에
                      기증하는 것에 동의하는 경우에 별지 제34호의 인체유래물연구
                      동의서 또는 별지 제41호의 인체유래물등의 기증 동의서를
                      추가로 작성하여야 합니다.
                      <br />
                      <br />
                      4. 유전자검사기관 또는 검사를 의뢰하는 의료기관은
                      검사대상자에게 시행하려는 유전자검사의 목적 및 검사항목,
                      검사대상물의 관리 방법, 동의의 철회 방법, 검사대상자의
                      권리 및 정보보호, 유전자검사기관의 휴업ㆍ폐업 시
                      검사대상물 및 관련 기록의 폐기 또는 이관에 관한 사항,
                      유전자검사 결과기록의 보존기간 및 관리 방법, 유전자검사
                      결과의 한계 등을 충분하게 설명해야 합니다.
                    </div>
                    <div className="agree-check" ref={signatureRef}>
                      <label>
                        <input
                          type="checkbox"
                          checked={isAgree}
                          onChange={handleCheckboxChange}
                        />
                        본인은 「생명윤리 및 안전에 관한 법률」 제51조 및 같은
                        법 시행규칙 제51조에 따라 해당 유전자 검사의 목적과
                        결과의 함의와 한계 등에 대하여 충분한 설명을 들어
                        이해하였으므로 위와 같이 본인에 대한 유전자검사에
                        자발적인 의사로 동의합니다.
                      </label>
                    </div>

                    {isSign ? (
                      <Signature
                        setIsAgree={setIsAgree}
                        setIsSign={setIsSign}
                        setSignatureData={setSignatureData}
                        signatureData={signatureData}
                      />
                    ) : null}

                    <div className="more-detail">
                      <h1>추가적인 설명을 원하시나요?</h1>
                      <div className="dl" onClick={(e) => downloadDocument(e)}>
                        <Link to="" onClick={(e) => e.preventDefault()}>
                          <span className="ico">
                            <img src="/assets/images/downloadBtn.png" alt="" />
                          </span>
                          [상품 설명서]
                        </Link>
                      </div>
                    </div>

                    <div className="more-detail">
                      <h1>
                        키트 상자 뚜껑 또는 보존액에 기재된 키트 번호를 입력해
                        주세요.
                      </h1>
                      <div className="dl">
                        <InputUnit
                          label="키트 번호"
                          holder="키트 번호"
                          register={register("kit_number", {
                            required: "키트번호는 필수 입력 값 입니다.",
                            pattern: CustomRegExp("kit_number"),
                          })}
                          errors={formErrors}
                        />
                      </div>
                    </div>

                    <div className="cp">
                      <span>검사기관명 : 디엑스앤브이엑스(주)</span>
                      <span>Tel. : 02-6964-8741 / E-mail : dtc@dxvx.com</span>
                    </div>
                  </div>

                  <div>
                    <div className="board-btn-area">
                      <button
                        className="btn"
                        onClick={(e) => props.setIsReq(false)}
                      >
                        돌아가기
                      </button>
                      <button
                        className="btn2"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        회수신청하기
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SearchAddress
          searchAddr={searchAddr}
          setAddr={handleCompanyPostNumberAddr}
        />
      )}
    </>
  );
};

export default UserReqPage;
