import React, { useState, useEffect } from "react";
import Modal from "./modal";
import moment from "moment";

const Popup = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const popupTime = localStorage.getItem("popupTime");

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const closeDayModal = () => {
    closeModal();
    let setTime = moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem("popupTime", setTime);
  };

  useEffect(() => {
    let timeNow = moment().format("YYYY-MM-DD HH:mm:ss");
    let diffTime = moment(timeNow).diff(moment(popupTime), "days");

    if (popupTime && diffTime <= 0) {
      return;
    } else {
      openModal();
    }
  }, []);

  return (
    <Modal
      open={modalOpen}
      closeDayModal={closeDayModal}
      close={closeModal}
      url={props.url}
    >
      <img
        src={
          process.env.NODE_ENV === "development"
            ? `http://localhost:3000/static/modal/${props.file_name}`
            : `/static/modal/${props.file_name}`
        }
        alt=""
      />
    </Modal>
  );
};

export default Popup;
