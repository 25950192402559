import React from "react";
// import { Link } from "react-router-dom";

const CustomerCenterPage = () => {
  // const downloadDocument = () => {
  //   const link = document.createElement("a");
  //   link.href = "/api/download/agree";
  //   link.style.display = "none";

  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // };

  return (
    <div id="container" className="container">
      <div className="customer">
        <h2>고객센터</h2>
        <span>
          본 검사 관련하여 궁금하신 부분이 있다면, 문의해 주시길 바랍니다.
        </span>
        <span>검사기관정보 </span>
        <span>
          검사기관명 : 디엑스앤브이엑스{"("}주{")"}
        </span>
        <span>주소 : 서울시 금천구 디지털로 173, 엘리시아빌딩 10층</span>
        <span>신고번호 : 152호</span>
        <span>인증유효기간 : 2025년 1월 1일 ~ 2027년 12월 31일</span>
        <span>DTC검사 문의전화번호 : 02-6964-8741</span>
        <span>DTC검사 문의메일주소 : dtc@dxvx.com</span>
        <span>
          디엑스앤브이엑스{"("}주{")"}는 개인정보보호법, 동법 시행령 및
          시행규칙, 표준 개인 정보 보호 지침에서 정의된 바를 준수하고 고객의
          개인 정보를 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전하게
          관리하고 있습니다.
        </span>
      </div>
      {/* <div id="sub">
        <div className="downloadBtn" onClick={(e) => downloadDocument(e)}>
          <Link to="#">
            유전자동의서 파일 다운로드
            <span class="ico">
              <img src="/assets/images/downloadBtn.png" alt="" />
            </span>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default CustomerCenterPage;
