import React, { useEffect, useState } from "react";
import useAxiosFunction from "hooks/useAxios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";
import SearchAddress from "components/common/searchAddress";

const MarketPaymentPage = (props) => {
  const itemDetail = props.itemDetail;
  const [isAgree, setIsAgree] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const handleCheckboxChange = (event) => {
    setIsAgree(event.target.checked);
  };

  const handleGoBackPage = (e) => {
    e.preventDefault();
    navigate("/market");
  };

  const searchAddr = () => {
    setIsAddress(!isAddress);
  };

  const handleCompanyPostNumberAddr = (data) => {
    setValue("user_post", data.user_post);
    setValue("user_addr1", data.user_addr1);
  };

  const callPaymentModuleHandler = (reqData) => {
    if (!isAgree) {
      alert("먼저 구매 동의를 해주세요.");
      return;
    }

    const merchant_uid = `mid_${new Date().getTime()}`;
    const item_id = itemDetail.item_id;
    const item_name = itemDetail.item_name;
    const item_price = itemDetail.item_price;

    axiosFetch({
      method: "post",
      url: apiUrls.orderRequestBefore,
      requestConfig: {
        merchant_uid: merchant_uid,
        item_id: item_id,
        item_name: item_name,
        item_price: item_price,
        user_id: userInfo.user_id,
        user_name: reqData.user_name,
        user_phone: reqData.user_phone,
      },
    })
      .then((res) => {
        if (res.success) {
          /* 1. 가맹점 식별하기 */
          const { IMP } = window;
          IMP.init("imp00711442");

          /* 2. 결제 데이터 정의하기 */
          const data = {
            pg: "nice_v2", // PG사
            pay_method: "card", // 결제수단
            merchant_uid: merchant_uid, // 주문번호
            amount: Number(itemDetail.item_price), // 결제금액
            name: itemDetail.item_name, // 주문명
            buyer_name: reqData.user_name, // 구매자 이름
            buyer_tel: reqData.user_tel, // 구매자 전화번호
            buyer_email: reqData.user_email, // 구매자 이메일
            buyer_addr: reqData.user_addr1, // 구매자 주소
            buyer_postcode: reqData.user_post, // 구매자 우편번호
          };

          /* 4. 결제 창 호출하기 */
          IMP.request_pay(data, callback_request_pay);
        } else {
          axiosFetch({
            method: "post",
            url: apiUrls.orderRequestRemove,
            requestConfig: { merchant_uid: merchant_uid },
          })
            .then((res) => {
              alert(res.message);
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {});
  };

  const getUserDetail = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserDetail,
      requestConfig: { user_id: props.userID },
    })
      .then((res) => {
        if (res.success) {
          setValue("user_post", res.userDetail.user_post);
          setValue("user_addr1", res.userDetail.user_addr1);
          setValue("user_addr2", res.userDetail.user_addr2);
          setValue("user_name", res.userDetail.user_name);
          setValue("user_phone", res.userDetail.user_phone);
          setUserInfo(res.userDetail);
        } else {
          alert(
            "로그인 정보 이상으로 이전화면으로 돌아갑니다. 관리자에게 문의해주세요."
          );
          props.paymentHandler(null, false);
        }
      })
      .catch((err) => {});
  };

  /* 3. 콜백 함수 정의하기 */
  function callback_request_pay(response) {
    const { success, merchant_uid, imp_uid, error_msg, error_code } = response;

    if (!error_code) {
      addOrder(merchant_uid, imp_uid);
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  const addOrder = (merchant_uid, imp_uid) => {
    const reqData = {
      user_id: props.userID,
      user_name: getValues("user_name"),
      user_phone: getValues("user_phone"),
      user_post: getValues("user_post"),
      user_addr1: getValues("user_addr1"),
      user_addr2: getValues("user_addr2"),
      item_id: itemDetail.item_id,
      item_name: itemDetail.item_name,
      item_price: itemDetail.item_price,
      order_method: "card",
      merchant_uid: merchant_uid,
      imp_uid: imp_uid,
      receipt_url: "",
      order_status: 1,
    };

    axiosFetch({
      method: "post",
      url: apiUrls.addOrder,
      requestConfig: reqData,
    })
      .then((res) => {
        alert(res.message);
        if (res.success) {
          addOrderWorkHistory(res.complete_id, "주문 완료");
          navigate("/");
        } else {
          props.paymentHandler(null, false);
        }
      })
      .catch((err) => {});
  };

  const addOrderWorkHistory = (complete_id, order_work_content) => {
    axiosFetch({
      method: "post",
      url: apiUrls.addOrderWorkHistory,
      requestConfig: {
        complete_id: complete_id,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
        order_work_content: order_work_content,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      {isAddress === false ? (
        <section className="item-container">
          <div className="item-payment">
            <div className="agree-area">
              <h3>개인정보 수집/이용 및 처리 동의 (필수)</h3>
              <div className="agree-content">
                <table className="agree-area-tb" style={{ textAlign: "start" }}>
                  <thead>
                    <tr>
                      <th
                        scope="cols"
                        colSpan={2}
                        style={{
                          textAlign: "start",
                          background: "#eee",
                          color: "#222",
                        }}
                      >
                        서비스 이용
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ background: "#fff", width: "150px" }}>
                        목적
                      </td>
                      <td style={{ background: "#fff" }}>
                        <ul>
                          <li>
                            ∙ 서비스 및 상품 제공에 관한 계약 이행 및 요금정산
                          </li>
                          <li>∙ 서비스 및 약관 변경 고지, 안내사항 전달</li>
                          <li>∙ 상품∙서비스 이용 실적 정보 통계∙분석</li>
                          <li>∙ 상품∙서비스 개선 및 추천</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ background: "#fff" }}>수집항목</td>
                      <td style={{ background: "#fff" }}>
                        <span>
                          서비스 이용 내역, 서비스 내 구매 및 결제 내역, 상담
                          내용
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ background: "#fff" }}>보유 및 이용기간</td>
                      <td style={{ background: "#fff" }}>
                        <span>
                          서비스이용일부터 보유 및 이용목적 달성시까지
                          보존합니다. 단, 관계 법령의 규정에 의하여 일정 기간
                          보존이 필요한 경우에는 해당 기간만큼 보관 후
                          삭제합니다.
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th
                        scope="cols"
                        colSpan={2}
                        style={{
                          textAlign: "start",
                          background: "#eee",
                          color: "#222",
                        }}
                      >
                        상품 구매
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ background: "#fff" }}>목적</td>
                      <td style={{ background: "#fff" }}>
                        <span>
                          서비스 및 상품 제공에 관한 계약 이행 및 서비스 제공에
                          따른 요금정산 판매자와 구매자의 거래의 원활한 진행,
                          본인의사의 확인 재화 또는 서비스 제공
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ background: "#fff" }}>수집항목</td>
                      <td style={{ background: "#fff" }}>
                        <span>
                          주문자 정보(성명, 주소, 휴대폰 번호, 전화번호), 수령인
                          정보(성명, 주소, 휴대폰번호, 전화번호), 구매 내역,
                          결제 내역
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ background: "#fff" }}>보유 및 이용기간</td>
                      <td style={{ background: "#fff" }}>
                        <span>
                          서비스이용일부터 보유 및 이용목적 달성시까지
                          보존합니다. 단, 관계 법령의 규정에 의하여 일정 기간
                          보존이 필요한 경우에는 해당 기간만큼 보관 후
                          삭제합니다.
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="agree-check">
                <label>
                  <input
                    type="checkbox"
                    checked={isAgree}
                    onChange={handleCheckboxChange}
                  />
                  서비스 제공을 위해 필요한 최소한의 개인정보 수집 및 이용에
                  대한 내용입니다. 동의하지 않을 권리가 있으며, 동의하지 않을
                  경우 서비스에 제한이 있을 수 있습니다.
                </label>
              </div>
            </div>
            <div className="payment-area">
              <h3>구매상품</h3>
              <div className="item-description">
                <div className="description">
                  <div className="thumbnail">
                    {
                      <img
                        src={
                          itemDetail.thumbnail[0].pathName
                            ? `/static/thumbnail/${itemDetail.thumbnail[0].pathName}`
                            : "../assets/images/default.png"
                        }
                        alt=""
                      />
                    }
                  </div>
                  <div className="name">{itemDetail.item_name}</div>
                  <div className="price">
                    {Number(itemDetail.item_price).toLocaleString()}원
                  </div>
                </div>
                <div className="delivery">
                  <h3>배송지</h3>
                  <form
                    className="input-area"
                    style={{ maxWidth: "600px" }}
                    onSubmit={handleSubmit(callPaymentModuleHandler)}
                  >
                    <div
                      className="board-btn-area"
                      style={{ justifyContent: "left" }}
                    >
                      <button className="btn-post" onClick={searchAddr}>
                        주소검색
                      </button>
                    </div>
                    <InputUnit
                      disabled={true}
                      label="Post"
                      holder="우편번호"
                      register={register("user_post", {
                        required: true,
                      })}
                      errors={formErrors}
                    />
                    <InputUnit
                      disabled={true}
                      label="Addr1"
                      holder="주소"
                      register={register("user_addr1", {
                        required: true,
                      })}
                      errors={formErrors}
                    />
                    <InputUnit
                      label="Addr2"
                      holder="상세주소"
                      register={register("user_addr2", {
                        required: "주소는 필수 입력 값 입니다.",
                      })}
                      errors={formErrors}
                    />
                    <InputUnit
                      label="Name"
                      holder="Name"
                      register={register("user_name", {
                        required: "이름은 필수 입력 값 입니다.",
                      })}
                      errors={formErrors}
                    />
                    <InputUnit
                      label="Phone"
                      holder="연락처"
                      register={register("user_phone", {
                        required: "연락처는 필수 입력 값 입니다.",
                        pattern: CustomRegExp("tel"),
                      })}
                      errors={formErrors}
                    />
                    <div className="board-btn-area">
                      <button
                        className="btn"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => handleGoBackPage(e)}
                      >
                        이전페이지
                      </button>
                      <button
                        className="btn"
                        type="submit"
                        style={{ marginLeft: "10px" }}
                        disabled={isSubmitting}
                      >
                        결제진행
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <SearchAddress
          searchAddr={searchAddr}
          setAddr={handleCompanyPostNumberAddr}
        />
      )}
    </>
  );
};

export default MarketPaymentPage;
