import React, { useState } from "react";
import useAxiosFunction from "hooks/useAxios";
import { useDropzone } from "react-dropzone";
import DatePickerComponent from "components/common/datePickerComponent";
import Select from "react-select";

const ModalUpdatePage = (props) => {
  const updateModalItem = props.updateModalItem;
  const [modalEndDate, setModalEndDate] = useState(
    new Date(updateModalItem.expire_date)
  );
  const [modalStatus, setModalStatus] = useState(
    updateModalItem.is_active === "0"
      ? { value: 0, label: "진행중" }
      : { value: 1, label: "마감" }
  );
  const [modalUrl, setModalUrl] = useState(updateModalItem.modal_url);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([updateModalItem]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const { apiUrls, axiosFetchForm } = useAxiosFunction();
  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: "58px !important",
    }),
  };

  const onDrop = (acceptedFiles) => {
    if (
      attachedFiles.length + acceptedFiles.length + uploadedFiles.length >
      1
    ) {
      alert("첨부파일의 최대 갯수는 1개입니다.");
      return;
    }
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
    }));
    setUploadedFiles([...uploadedFiles, ...newFiles]); // 배열로 설정
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: false,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  const handleRemoveFile = (e, indexToRemove) => {
    e.preventDefault();

    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const handleRemoveAttachedFile = (e, indexToRemove) => {
    e.preventDefault();

    setAttachedFiles((prevFiles) => {
      const removedFile = prevFiles[indexToRemove];
      setDeletedFiles((prevDeletedFiles) => {
        if (!prevDeletedFiles.some((file) => file === removedFile)) {
          return [...prevDeletedFiles, removedFile];
        }
        return prevDeletedFiles;
      });
      return prevFiles.filter((file, index) => index !== indexToRemove);
    });
  };

  const updateModal = (e) => {
    e.preventDefault();

    if (modalStatus === undefined || modalStatus === null) {
      alert("구분을 선택해주세요.");
      return;
    }

    // 파일 정보를 FormData에 추가
    const formData = new FormData();
    formData.append("modal_id", updateModalItem.modal_id);
    formData.append("modal_url", modalUrl);
    formData.append("expire_date", modalEndDate);
    formData.append("is_active", modalStatus.value);

    deletedFiles.forEach((file) => {
      formData.append("deleted_files[]", JSON.stringify(file));
    });

    // 업로드된 파일 추가
    uploadedFiles?.forEach((file) => {
      formData.append("files", file.file);
    });

    axiosFetchForm({
      method: "post",
      url: apiUrls.updateModalList,
      formData: formData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          props.setIsUpdate(false);
          props.getModalList();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] updateModal: ", err);
      });
  };

  return (
    <>
      <div className="admin-dialog">
        <div className="admin-dialog-box contract">
          <div className="admin-dialog-area" style={{ padding: "0" }}>
            <div className="dialog-content">
              <div className="admin-dialog-item">
                <h3 className="adi-title">URL</h3>
                <div className="adi-content">
                  <div style={{ display: "flex" }}>
                    <div className="input-unit">
                      <input
                        className="input-admin"
                        style={{
                          display: "inline-block",
                          width: "235px",
                        }}
                        noLable="true"
                        label="URL"
                        holder="URL을 입력해주세요"
                        value={modalUrl}
                        onChange={(e) => setModalUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-dialog-item">
                <h3 className="adi-title">만료일</h3>
                <div className="adi-content">
                  <div style={{ display: "flex" }}>
                    <DatePickerComponent
                      selected={modalEndDate}
                      onChange={setModalEndDate}
                      withPortal={true}
                    />
                    <span style={{ margin: "0 20px" }}> 까지 </span>
                  </div>
                </div>
              </div>

              <div className="admin-dialog-item">
                <h3 className="adi-title">구분</h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={[
                      { value: 0, label: "진행중" },
                      { value: 1, label: "마감" },
                    ]}
                    style={customStyles}
                    value={modalStatus}
                    onChange={(selectedOption) =>
                      setModalStatus(selectedOption)
                    }
                  ></Select>
                </div>
              </div>

              <div className="admin-dialog-item">
                <h3 className="adi-title">첨부파일</h3>
                <div className="adi-content">
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => onDrop(e.target.files)}
                    />
                    {isDragActive ? (
                      <p>파일을 여기에 놓아주세요!</p>
                    ) : (
                      <>
                        <p>클릭하거나 파일을 끌어다 놓아주세요.</p>
                      </>
                    )}
                  </div>
                  <div className="uploaded-files">
                    {attachedFiles?.length > 0 && (
                      <ul>
                        {attachedFiles?.map((attachedFile, index) => (
                          <li key={index}>
                            <img
                              src={
                                process.env.NODE_ENV === "development"
                                  ? `http://localhost:3000/static/modal/${attachedFile.file_name}`
                                  : `/static/modal/${attachedFile.file_name}`
                              }
                              alt={`홍보-${index}`}
                            />
                            <button
                              onClick={(e) =>
                                handleRemoveAttachedFile(e, index)
                              }
                            >
                              삭제
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="uploaded-files">
                    {uploadedFiles?.length > 0 && (
                      <ul>
                        {uploadedFiles?.map((uploadedFile, index) => (
                          <li key={index}>
                            <img
                              src={URL.createObjectURL(uploadedFile.file)}
                              alt={`홍보-${index}`}
                            />
                            <button onClick={(e) => handleRemoveFile(e, index)}>
                              삭제
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="board-btn-area">
        <button
          type="submit"
          className="write-btn"
          onClick={(e) => updateModal(e)}
        >
          수정
        </button>
        <button
          type="submit"
          className="write-btn"
          onClick={(e) => props.setIsUpdate(false)}
        >
          취소
        </button>
      </div>
    </>
  );
};

export default ModalUpdatePage;
