import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { adminOrderListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import "assets/css/board.css";
import moment from "moment";
import Select from "react-select";
import CustomModal from "components/popup/customModal";
import OrderWorkListPage from "./order/orderWorkListPage";
import OrderSelectedInfoPage from "./order/orderSelectedInfoPage";
import { CSVLink } from "react-csv";
import ExcelJS from "exceljs";

import {
  AssignmentOutlined,
  SettingsBackupRestoreOutlined,
  Close,
  CheckCircleOutline,
  WorkHistoryOutlined,
  FileDownloadOutlined,
  SaveOutlined,
  DriveFileRenameOutlineOutlined,
  InputOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 2px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const OrderListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sampleKitNumber, setSampleKitNumber] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [orderResult, setOrderResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [orderListPage, setOrderListPage] = useRecoilState(
    adminOrderListPageIndexState
  );
  const [transactionData, setTransactionData] = useState([]);
  const csvLink = useRef();
  const navigate = useNavigate();
  const [isKitNumberEditing, setIsKitNumberEditing] = useState([]);
  const [isTrackingNumberEditing, setIsTrackingNumberEditing] = useState([]);
  const [isReturnTrackingNumberEditing, setIsReturnTrackingNumberEditing] =
    useState([]);
  const [workHostoryModalIsOpen, setWorkHostoryModalIsOpen] = useState(false);
  const [orderWorkHistoryList, setOrderWorkHistoryList] = useState([]);
  const [orderSelectedInfo, setOrderSelectedInfo] = useState();
  const [orderSelectedInfoModalIsOpen, setOrderSelectedInfoModalIsOpen] =
    useState(false);
  const userInfo = useRecoilValue(userInfoState);
  const headers = [
    { label: "아이디", key: "user_id" },
    { label: "이름", key: "user_name" },
    { label: "연락처", key: "user_phone" },
    { label: "우편번호", key: "user_post" },
    { label: "주소", key: "user_addr1" },
    { label: "상세주소", key: "user_addr2" },
    { label: "주문일시", key: "order_date" },
    { label: "상품명", key: "item_name" },
    { label: "상품가격", key: "item_price" },
  ];
  const [selectOrderTypeSearch, setSelectOrderTypeSearch] = useState({
    value: 10,
    label: "주문상태전체",
  });

  const exportExcel = async (e) => {
    e.preventDefault();

    // 워크북 생성
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("주문내역");

    // 첫 번째 행: 두 개의 헤더로 구성, '구매' 범주를 병합
    worksheet.mergeCells("A1:F1");
    worksheet.getCell("A1").value = "구매";
    worksheet.getCell("G1").value = "출고";
    worksheet.mergeCells("H1:N1");
    worksheet.getCell("H1").value = "회수";
    worksheet.getCell("O1").value = "입고";
    worksheet.mergeCells("P1:U1");
    worksheet.getCell("P1").value = "검사";

    const headerCells = [
      worksheet.getCell("A1"),
      worksheet.getCell("G1"),
      worksheet.getCell("H1"),
      worksheet.getCell("O1"),
      worksheet.getCell("P1"),
    ];

    headerCells.forEach((cell) => {
      cell.alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    });

    // 두 번째 행: 실제 필드명
    const headerRow = worksheet.addRow([
      "주문일시",
      "상품종류",
      "ID",
      "이름",
      "연락처",
      "주소",
      "운송장번호",
      "신청일시",
      "동의서",
      "ID",
      "이름",
      "생년월일",
      "연락처",
      "주소",
      "운송장번호",
      "키트번호",
      "외관검사",
      "접수일",
      "TAT",
      "결과확인",
      "주문상태",
    ]);

    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
      cell.alignment = {
        horizontal: "center", // 수평 가운데 정렬
        vertical: "middle", // 수직 가운데 정렬
      };
    });

    // 데이터 추가
    const exportOrderList = orderList
      .filter((row) => row.isChecked)
      .map((row) => ({
        주문일시: moment(row.order_date).format("YY-MM-DD HH:mm"),
        상품종류: row.item_name,
        ID: row.user_id,
        이름: row.user_name,
        연락처: row.user_phone,
        주소: `(${row.user_post}) ${row.user_addr1} ${row.user_addr2}`,
        운송장번호: row.tracking_number,
        신청일시: moment(row.rqh_request_time).format("YY-MM-DD HH:mm"),
        동의서: row.is_appropriate === 1 ? "적합" : "부적합",
        ID2: row.rqh_user_id ? row.rqh_user_id : "-",
        이름2: row.rqh_user_name,
        생년월일: moment(row.rqh_user_birth).format("YYYY-MM-DD"),
        연락처2: row.rqh_user_phone,
        주소2: `(${row.rqh_user_post}) ${row.rqh_user_addr1} ${row.rqh_user_addr2}`,
        운송장번호2: row.return_tracking_number
          ? row.return_tracking_number
          : "-",
        키트번호: row.kit_number,
        외관검사:
          row.order_complete_status === 5 || row.order_complete_status === 6
            ? "P"
            : row.visual_inspection,
        접수일: moment(row.receipt_date).format("YY-MM-DD HH:mm"),
        TAT: moment(row.receipt_date).add(14, "days").format("YY.MM.DD"),
        결과확인: row.user_register_report === null ? "미확인" : "확인",
        주문상태: selectOrderTypeOptions[row.order_complete_status].label,
      }));

    // 데이터 삽입
    exportOrderList.forEach((data) => {
      const row = worksheet.addRow([
        data.주문일시,
        data.상품종류,
        data.ID,
        data.이름,
        data.연락처,
        data.주소,
        data.운송장번호,
        data.신청일시,
        data.동의서,
        data.ID2,
        data.이름2,
        data.생년월일,
        data.연락처2,
        data.주소2,
        data.운송장번호2,
        data.키트번호,
        data.외관검사,
        data.접수일,
        data.TAT,
        data.결과확인,
        data.주문상태,
      ]);

      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        };
        cell.alignment = {
          horizontal: "center", // 수평 가운데 정렬
          vertical: "middle", // 수직 가운데 정렬
        };
      });
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = (maxLength + 2) * 1.5;
    });

    // 엑셀 파일 저장
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // 다운로드 트리거 (브라우저 환경에서)
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "order_list.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCheckDateSelectChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const selectOrderTypeOptions = [
    { value: 0, label: "주문완료" },
    { value: 1, label: "배송중" },
    { value: 2, label: "배송완료" },
    { value: 3, label: "회수요청" },
    { value: 4, label: "회수중" },
    { value: 5, label: "검사중" },
    { value: 6, label: "검사완료" },
    { value: 7, label: "재검체요청" },
    { value: 8, label: "취소/환불" },
  ];

  const isValidTransition = (currentState, nextState, userType) => {
    const validTransitionsType2 = {
      0: [1, 8], // 주문완료 -> 배송중, 취소/환불
      1: [2, 8], // 배송중 -> 배송완료, 취소/환불
      2: [3, 8], // 배송완료 -> 회수요청, 취소/환불
      3: [2, 4, 8], // 회수요청 -> 배송완료, 회수중, 취소/환불
      4: [3, 5, 7, 8], // 회수중 -> 회수요청, 검사중, 재검체요청, 취소/환불
      5: [4, 6, 7, 8], // 검사중 -> 회수중, 검사완료, 재검체요청, 취소/환불
      6: [8, 7], // 검사완료 -> 취소/환불, 재검체요청
      7: [], // 재검체요청 -> 변경 불가
      8: [], // 취소/환불 -> 변경 불가
    };

    if (userType === "A") {
      return true; // 타입 A 관리자는 모든 상태 전환 가능
    }
    return validTransitionsType2[currentState].includes(nextState);
  };

  const selectOrderTypeOptionsSearch = [
    { value: 0, label: "주문완료" },
    { value: 1, label: "배송중" },
    { value: 2, label: "배송완료" },
    { value: 3, label: "회수요청" },
    { value: 4, label: "회수중" },
    { value: 5, label: "검사중" },
    { value: 6, label: "검사완료" },
    { value: 7, label: "재검체요청" },
    { value: 8, label: "취소/환불" },
    { value: 10, label: "주문상태전체" },
  ];

  const selectedOrderTypeHandler = (e, data) => {
    const nextState = e.value;

    if (
      isValidTransition(
        data.order_complete_status,
        nextState,
        userInfo.user_type
      )
    ) {
      const updatedItems = orderList.map((orderItem) => {
        if (orderItem.complete_id === data.complete_id) {
          return { ...orderItem, order_complete_status: nextState };
        }
        return orderItem;
      });
      setOrderList(updatedItems);
    } else {
      alert("이 상태로 전환할 수 없습니다.");
    }
  };

  const updateOrderStatus = (e, reqData) => {
    e.preventDefault();

    const order_complete_status = Number(reqData.order_complete_status);
    const send_message = Number(reqData.send_message);
    const is_sample_receive = Number(reqData.is_sample_receive);

    if (order_complete_status === 5 && is_sample_receive === 0) {
      alert("검사중으로 변경시에 키트번호 입고처리가 우선 되어야합니다.");
      getOrderList(orderListPage);
      return;
    }

    if (order_complete_status === 5 && send_message === 0) {
      if (window.confirm("검사진행알림 문자가 발송됩니다. 계속하시겠습니까?")) {
        axiosFetchA({
          method: "post",
          url: apiUrls.sendMailReceipt,
          requestConfig: {
            complete_id: reqData.complete_id,
            customer_phone: reqData.rqh_user_phone,
            item_name: reqData.item_name,
          },
        })
          .then((res) => {})
          .catch((err) => {});
      } else {
        getOrderList(orderListPage);
        return;
      }
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateOrderStatus,
      requestConfig: {
        complete_id: reqData.complete_id,
        order_complete_status: reqData.order_complete_status,
        kit_number: reqData.kit_number,
      },
    })
      .then((res) => {
        addOrderWorkHistory(
          reqData.complete_id,
          `주문상태 ${
            selectOrderTypeOptions.find(
              (option) => option.value === reqData.order_complete_status
            )?.label
          }(으)로 변경`
        );
        alert(res.message);
        getOrderList(orderListPage);
      })
      .catch((err) => {});
  };

  const validateKitNumber = (kitNumber) => {
    const regex =
      /^(0[0-9]|1[0-9]|2[0-9]|3[0-1])(BC)(A|B|C|D|E|F|G|H|I|J|K|L)([0-9]{5})$/;
    return regex.test(kitNumber);
  };

  const handleKitNumberInputChange = (e, index) => {
    e.preventDefault();

    const newOrderList = [...orderList];
    newOrderList[index].kit_number = e.target.value;
    setOrderList(newOrderList);
  };

  const isUpdateKitNumber = (e, index) => {
    e.preventDefault();

    const newEditing = [...isKitNumberEditing];
    newEditing[index] = true;
    setIsKitNumberEditing(newEditing);
  };

  const updateKitNumber = (e, index, item) => {
    e.preventDefault();

    const kitNumber = orderList[index].kit_number;

    if (!validateKitNumber(kitNumber)) {
      alert("올바르지 않은 키트번호입니다. 확인 후 다시 입력해주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateKitNumber,
      requestConfig: {
        complete_id: item.complete_id,
        kit_number: kitNumber,
      },
    })
      .then((res) => {
        if (res.success) {
          if (item.tracking_number) {
            updateOrderStatus(e, {
              complete_id: item.complete_id,
              order_complete_status: 1,
              kit_number: kitNumber,
              send_message: 0,
            });
          } else {
            getOrderList(orderListPage);
          }
        }

        alert(res.message);
      })
      .catch((err) => {});
  };

  const handleTrackingNumberInputChange = (e, index) => {
    e.preventDefault();

    const newOrderList = [...orderList];
    newOrderList[index].tracking_number = e.target.value;
    setOrderList(newOrderList);
  };

  const isUpdateTrackingNumber = (e, index) => {
    e.preventDefault();

    const newEditing = [...isTrackingNumberEditing];
    newEditing[index] = true;
    setIsTrackingNumberEditing(newEditing);
  };

  const updateTrackingNumber = (e, item) => {
    e.preventDefault();

    const tracktingNumber = item.tracking_number;

    if (tracktingNumber.length === 0) {
      alert("올바르지 않은 운송장번호입니다. 확인 후 다시 입력해주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateTrackingNumber,
      requestConfig: {
        complete_id: item.complete_id,
        tracking_number: tracktingNumber,
      },
    })
      .then((res) => {
        if (res.success) {
          if (item.kit_number) {
            updateOrderStatus(e, {
              complete_id: item.complete_id,
              order_complete_status: 1,
              kit_number: item.kit_number,
              send_message: 0,
            });
          } else {
            getOrderList(orderListPage);
          }
        }

        alert(res.message);
      })
      .catch((err) => {});
  };

  const handleReturnTrackingNumberInputChange = (e, index) => {
    e.preventDefault();

    const newOrderList = [...orderList];
    newOrderList[index].return_tracking_number = e.target.value;
    setOrderList(newOrderList);
  };

  const isUpdateReturnTrackingNumber = (e, index) => {
    e.preventDefault();

    const newEditing = [...isReturnTrackingNumberEditing];
    newEditing[index] = true;
    setIsReturnTrackingNumberEditing(newEditing);
  };

  const updateReturnTrackingNumber = (e, item) => {
    e.preventDefault();

    const returnTracktingNumber = item.return_tracking_number;

    if (returnTracktingNumber.length === 0) {
      alert("올바르지 않은 운송장번호입니다. 확인 후 다시 입력해주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateReturnTrackingNumber,
      requestConfig: {
        complete_id: item.complete_id,
        tracking_number: returnTracktingNumber,
      },
    })
      .then((res) => {
        if (res.success) {
          getOrderList(orderListPage);
        }

        alert(res.message);
      })
      .catch((err) => {});
  };

  const getDateStyle = (receiptDate) => {
    if (!receiptDate) return {};
    const currentDate = moment();
    const date = moment(receiptDate);
    const diffDays = currentDate.diff(date, "days");

    if (diffDays >= 9 && diffDays <= 14) {
      return { backgroundColor: "yellow" };
    } else if (diffDays > 14) {
      return { backgroundColor: "pink" };
    } else {
      return {};
    }
  };

  const getOrderList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getOrderList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
        orderState: selectOrderTypeSearch.value,
      },
    })
      .then((res) => {
        if (res.success) {
          setOrderResult(res.result);
          setOrderList(res.orderList);
          setPageList(res.pageList);
          setOrderListPage(reqData);
          setIsKitNumberEditing(
            res.orderList.map((item) => (item.kit_number ? false : true))
          );
          setIsTrackingNumberEditing(
            res.orderList.map((item) => (item.tracking_number ? false : true))
          );
        }
      })
      .catch((err) => {});
  };

  const goAgree = (e, item) => {
    e.preventDefault();
    addOrderWorkHistory(item.complete_id, "동의서 열람");
    navigate("/agreepage", {
      state: { req_id: item.request_id },
    });
  };

  const goPostHandler = (e, item) => {
    e.preventDefault();

    const updatedItems = orderList.map((order) =>
      order.complete_id === item.complete_id
        ? { ...order, order_complete_status: 4 }
        : order
    );
    setOrderList(updatedItems);

    axiosFetchA({
      method: "post",
      url: apiUrls.getPostReturn,
      requestConfig: {
        complete_id: item.complete_id,
        user_name: item.rqh_user_name,
        user_phone: item.rqh_user_phone,
        user_addr1: item.rqh_user_addr1,
        user_addr2: item.rqh_user_addr2,
      },
    })
      .then((res) => {
        getOrderList(orderListPage);
      })
      .catch((err) => {});
  };

  const openWorkHistoryModal = (e, reqData) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.getOrderWorkHistory,
      requestConfig: {
        complete_id: reqData.complete_id,
      },
    })
      .then((res) => {
        if (res.success) {
          setOrderWorkHistoryList(res.workHistoryList);
          setWorkHostoryModalIsOpen(true);
        }
      })
      .catch((err) => {});
  };

  const closeWorkHostoryModal = () => {
    setWorkHostoryModalIsOpen(false);
  };

  const openOrderDetailModal = (e, item) => {
    e.preventDefault();
    setOrderSelectedInfo(item);
    setOrderSelectedInfoModalIsOpen(true);
  };

  const closeOrderDetailModal = () => {
    setOrderSelectedInfoModalIsOpen(false);
    getOrderList(orderListPage);
  };

  const addOrderWorkHistory = (complete_id, order_work_content) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.addOrderWorkHistory,
      requestConfig: {
        complete_id: complete_id,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
        order_work_content: order_work_content,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  const handleCheckboxChange = (e, index) => {
    e.stopPropagation();

    const newOrderList = [...orderList];
    newOrderList[index].isChecked = !newOrderList[index].isChecked;
    setOrderList(newOrderList);
  };

  const handleCheckboxAllChange = (e) => {
    const isChecked = e.target.checked;
    const newOrderList = orderList.map((item) => ({ ...item, isChecked }));
    setOrderList(newOrderList);
  };

  const confirmSampleKitNumber = () => {
    if (!validateKitNumber(sampleKitNumber)) {
      alert("올바르지 않은 키트번호입니다. 확인 후 다시 입력해주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.confirmKitNumber,
      requestConfig: {
        kit_number: sampleKitNumber,
      },
    })
      .then((res) => {
        if (res.success) {
          confirmSampleKitUpdateOrderStatus(res);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const confirmSampleKitUpdateOrderStatus = (res) => {
    const reqData = res.item;
    const message = res.message;
    const send_message = Number(reqData.send_message);

    if (send_message === 0) {
      if (
        window.confirm("검사진행알림 문자가 발송됩니다. 계속하시겠습니까? ")
      ) {
        axiosFetchA({
          method: "post",
          url: apiUrls.sendMailReceipt,
          requestConfig: {
            complete_id: reqData.complete_id,
            customer_phone: reqData.rqh_user_phone,
            item_name: reqData.item_name,
          },
        })
          .then((res) => {})
          .catch((err) => {});
      } else {
        return;
      }
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateOrderStatus,
      requestConfig: {
        complete_id: reqData.complete_id,
        order_complete_status: 5,
        kit_number: reqData.kit_number,
      },
    })
      .then((updateOrderStatusRes) => {
        addOrderWorkHistory(
          reqData.complete_id,
          `키트번호 입고 ${reqData.kit_number}, 주문상태 ${
            selectOrderTypeOptions.find((option) => option.value === 5)?.label
          }(으)로 변경`
        );

        axiosFetchA({
          method: "post",
          url: apiUrls.updateSampleReceive,
          requestConfig: {
            kit_number: sampleKitNumber,
          },
        })
          .then((res) => {
            alert(message);
            getOrderList(orderListPage);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const reInspectRequest = (e, item) => {
    e.preventDefault();

    if (
      window.confirm(
        "재검체 요청시 재등록되며 기존 신청 사항은 더 이상 변경 할 수 없습니다. 재검체 요청을 진행하시겠습니까?"
      )
    ) {
      axiosFetchA({
        method: "post",
        url: apiUrls.reInspectRequest,
        requestConfig: {
          item: item,
        },
      })
        .then((res) => {
          getOrderList(orderListPage);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getOrderList(orderListPage);
  }, []);

  useEffect(() => {
    if (transactionData.length > 0) {
      csvLink.current.link.click();
    }
  }, [transactionData]);

  return (
    <div className="container-n-admin">
      <div className="content-area">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {orderResult.totalrows}
            <span>건의 주문</span>
            <div style={{ marginLeft: "10px" }}>
              <CSVLink
                headers={headers}
                data={transactionData}
                filename={moment().format("YY_MM_DD_") + "주문상태관리.csv"}
                style={{ display: "none" }}
                ref={csvLink}
              >
                다운로드링크
              </CSVLink>
              <div onClick={(e) => exportExcel(e)}>
                <IconWrapper>
                  <FileDownloadOutlined fontSize="large" />
                </IconWrapper>
              </div>
            </div>
            <div className="board-search" style={{ width: "250px" }}>
              <input
                type="text"
                placeholder="입고된 키트번호를 입력하세요."
                value={sampleKitNumber}
                onChange={(e) => {
                  setSampleKitNumber(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    confirmSampleKitNumber();
                  }
                }}
              />
              <button
                style={{
                  background: "transparent",
                  textIndent: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(e) => confirmSampleKitNumber()}
              >
                <IconWrapper>
                  <InputOutlined fontSize="medium" />
                </IconWrapper>
              </button>
            </div>
          </div>
          <div className="board-search-area">
            <div className="check">
              <label>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    width: "16px",
                    height: "16px",
                  }}
                  checked={isDateRangeVisible}
                  onChange={handleCheckDateSelectChange}
                ></input>
                기간설정
              </label>
            </div>

            {isDateRangeVisible && (
              <div className="date-range">
                <div className="date-picker">
                  <label>
                    시작 날짜:
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>
                  <label>
                    종료 날짜:
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            )}

            <div style={{ width: "200px", marginRight: "30px" }}>
              <Select
                className="select"
                options={selectOrderTypeOptionsSearch}
                value={selectOrderTypeOptionsSearch.filter(
                  (option) => option.value === selectOrderTypeSearch.value
                )}
                onChange={(e) => setSelectOrderTypeSearch(e)}
              ></Select>
            </div>

            <div className="board-search">
              <input
                type="text"
                placeholder="검색어를 입력해 주세요"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    getOrderList(1);
                  }
                }}
              />
              <button
                type="button"
                onClick={(e) => {
                  getOrderList(1);
                }}
              >
                검색
              </button>
            </div>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th rowSpan="2">
                  <label style={{ display: "block", padding: "10px" }}>
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckboxAllChange(e)}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        width: "12px",
                        height: "12px",
                        border: "1px solid black",
                        backgroundColor: orderList.every(
                          (item) => item.isChecked
                        )
                          ? "#a2a2a2"
                          : "white",
                      }}
                    ></span>
                  </label>
                </th>
                <th rowSpan="2">번호</th>
                <th colSpan="6">구매</th>
                <th colSpan="1">출고</th>
                <th colSpan="6">회수</th>
                <th colSpan="1">입고</th>
                <th colSpan="3">검사</th>
                <th colSpan="3">결과</th>
                <th colSpan="3"></th>
              </tr>
              <tr>
                <th>주문일시</th>
                <th>상품종류</th>
                <th>ID</th>
                <th>이름</th>
                <th>연락처</th>
                <th>주소</th>
                <th>운송장번호</th>
                <th>신청일시</th>
                <th>동의서</th>
                <th>ID</th>
                <th>이름</th>
                <th>연락처</th>
                <th>주소</th>
                <th>운송장번호</th>
                <th>키트번호</th>
                <th>외관검사</th>
                <th>접수일</th>
                <th>TAT</th>
                <th>결과등록일</th>
                <th>결과확인</th>
                <th>검체입고</th>
                <th>작업이력</th>
                <th>주문상태</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.map((item, index) => (
                <tr
                  key={item.row_num}
                  onClick={(e) => openOrderDetailModal(e, item)}
                >
                  <td onClick={(e) => e.stopPropagation()}>
                    <label style={{ display: "block", padding: "10px" }}>
                      <input
                        type="checkbox"
                        checked={item.isChecked || false}
                        onClick={(e) => e.stopPropagation()} // 이벤트 전파 중단
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{ display: "none" }}
                        disabled={userInfo?.user_type === "I"}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          border: "1px solid black",
                          backgroundColor: item.isChecked ? "#a2a2a2" : "white",
                        }}
                      ></span>
                    </label>
                  </td>
                  <td> {orderResult.totalrows - item.row_num + 1}</td>
                  <td>{moment(item.order_date).format("YY.MM.DD")}</td>
                  <td>{item.item_name}</td>
                  <td>{item.user_id}</td>
                  <td>{item.user_name}</td>
                  <td>{item.user_phone}</td>
                  <td>{`(${item.user_post}) ${item.user_addr1} ${item.user_addr2}`}</td>
                  <td onClick={(e) => e.stopPropagation()}>
                    {isTrackingNumberEditing[index] ? (
                      <div className="select-area">
                        <input
                          style={{ margin: "0px" }}
                          label="운송장번호를 입력하세요."
                          defaultValue={item.tracking_number}
                          onChange={(e) => {
                            handleTrackingNumberInputChange(e, index);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          disabled={userInfo?.user_type === "I"}
                        />
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateTrackingNumber(e, item);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <SaveOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    ) : (
                      <div className="select-area">
                        <div className="text">
                          {item.tracking_number
                            ? item.tracking_number.replace(
                                /(.{4})(.{4})(.{4})/,
                                "$1-$2-$3"
                              )
                            : "-"}
                        </div>
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            isUpdateTrackingNumber(e, index);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <DriveFileRenameOutlineOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    )}
                  </td>
                  <td>
                    {item.rqh_request_time
                      ? moment(item.rqh_request_time).format("YY.MM.DD")
                      : "-"}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    {item.rqh_user_name ? (
                      <>
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            goAgree(e, item);
                          }}
                        >
                          <IconWrapper>
                            <AssignmentOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                        {item.is_appropriate === 1 ? (
                          <div style={{ color: "green" }}>적합</div>
                        ) : item.is_appropriate === 2 ? (
                          <div style={{ color: "red" }}>부적합</div>
                        ) : null}
                      </>
                    ) : null}
                  </td>
                  <td>{item.rqh_user_id ? item.rqh_user_id : "-"}</td>
                  <td>{item.rqh_user_name ? item.rqh_user_name : "-"}</td>
                  <td>{item.rqh_user_phone ? item.rqh_user_phone : "-"}</td>
                  <td>
                    {item.rqh_user_post
                      ? `(${item.rqh_user_post}) ${item.rqh_user_addr1} ${item.rqh_user_addr2}`
                      : "-"}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    {isReturnTrackingNumberEditing[index] ? (
                      <div className="select-area">
                        <input
                          style={{ margin: "0px" }}
                          label="운송장번호를 입력하세요."
                          defaultValue={item.return_tracking_number}
                          onChange={(e) => {
                            handleReturnTrackingNumberInputChange(e, index);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          disabled={userInfo?.user_type === "I"}
                        />
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateReturnTrackingNumber(e, item);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <SaveOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    ) : (
                      <div className="select-area">
                        <div className="text">
                          {item.return_tracking_number
                            ? item.return_tracking_number.replace(
                                /(.{4})(.{4})(.{4})/,
                                "$1-$2-$3"
                              )
                            : "-"}
                        </div>
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            isUpdateReturnTrackingNumber(e, index);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <DriveFileRenameOutlineOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    )}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    {isKitNumberEditing[index] ? (
                      <div className="select-area">
                        <input
                          style={{ margin: "0px" }}
                          label="키트번호를 입력하세요."
                          defaultValue={item.kit_number}
                          onChange={(e) => {
                            handleKitNumberInputChange(e, index);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          disabled={userInfo?.user_type === "I"}
                        />
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateKitNumber(e, index, item);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <SaveOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    ) : (
                      <div className="select-area">
                        <div className="text">{item.kit_number}</div>
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            isUpdateKitNumber(e, index);
                          }}
                          disabled={userInfo?.user_type === "I"}
                        >
                          <IconWrapper>
                            <DriveFileRenameOutlineOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    )}
                  </td>
                  <td>
                    {item.order_complete_status === 5 ||
                    item.order_complete_status === 6
                      ? "P"
                      : item.visual_inspection}
                  </td>
                  <td>
                    {item.receipt_date
                      ? moment(item.receipt_date).format("YY.MM.DD")
                      : "-"}
                  </td>
                  <td
                    style={
                      item.order_complete_status === 6
                        ? null
                        : getDateStyle(item.receipt_date)
                    }
                  >
                    <div className="date">
                      {item.receipt_date
                        ? moment(item.receipt_date)
                            .add(14, "days")
                            .format("YY.MM.DD")
                        : "-"}
                    </div>
                  </td>
                  <td>
                    {item.report_date ? (
                      <>
                        <p>
                          {`${moment(item.report_date).format("YY.MM.DD")}`}
                        </p>
                        <p>
                          {` ${moment(item.report_date).diff(
                            item.receipt_date,
                            "days"
                          )} 일`}
                        </p>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {Number(item?.user_register_report) === 0 ? (
                      <Close style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutline style={{ color: "green" }} />
                    )}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <button
                      style={{ background: "transparent" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        reInspectRequest(e, item);
                      }}
                    >
                      {Number(item.order_complete_status) === 6 ||
                      Number(item.order_complete_status) === 5 ? (
                        <IconWrapper>
                          <CheckCircleOutline style={{ color: "green" }} />
                        </IconWrapper>
                      ) : Number(item.is_sample_receive) === 0 ? (
                        <IconWrapper>
                          <Close style={{ color: "red" }} />
                        </IconWrapper>
                      ) : (
                        <IconWrapper>
                          <CheckCircleOutline style={{ color: "green" }} />
                        </IconWrapper>
                      )}
                    </button>
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <button
                      style={{ background: "transparent" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openWorkHistoryModal(e, item);
                      }}
                    >
                      <IconWrapper>
                        <WorkHistoryOutlined fontSize="medium" />
                      </IconWrapper>
                    </button>
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <div
                      className="select-area"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Select
                        className="select"
                        options={selectOrderTypeOptions.filter(
                          (option) =>
                            userInfo.user_type === "A" ||
                            isValidTransition(
                              item.order_complete_status,
                              option.value,
                              userInfo.user_type
                            )
                        )}
                        value={selectOrderTypeOptions.find(
                          (option) =>
                            option.value === item.order_complete_status
                        )}
                        onChange={(e) => selectedOrderTypeHandler(e, item)}
                        isDisabled={userInfo?.user_type === "I"}
                      ></Select>
                      <button
                        style={{ background: "transparent" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          updateOrderStatus(e, item);
                        }}
                        disabled={userInfo?.user_type === "I"}
                      >
                        <IconWrapper>
                          <SaveOutlined fontSize="medium" />
                        </IconWrapper>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <ul className="paging">
            <li>
              <Link
                onClick={() =>
                  getOrderList(
                    orderResult.currentPage - 10 < 1
                      ? 1
                      : orderResult.currentPage - 10
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardFirst.png" alt="" />
              </Link>
            </li>
            {pageList?.map((item) => (
              <li
                key={item}
                className={item === orderResult.currentPage ? "on" : null}
              >
                <Link
                  onClick={() => getOrderList(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </Link>
              </li>
            ))}

            <li>
              <Link
                onClick={() =>
                  getOrderList(
                    pageList[pageList.length - 1] + 1 < orderResult.maxPage
                      ? pageList[pageList.length - 1] + 1
                      : pageList[pageList.length - 1]
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardLast.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>

        <CustomModal
          isOpen={workHostoryModalIsOpen}
          onRequestClose={closeWorkHostoryModal}
          maxHeight="550px"
          minWidth="800px"
        >
          <OrderWorkListPage orderWorkHistoryList={orderWorkHistoryList} />
        </CustomModal>

        <CustomModal
          isOpen={orderSelectedInfoModalIsOpen}
          onRequestClose={closeOrderDetailModal}
          maxHeight="450px"
          minWidth="1200px"
        >
          <OrderSelectedInfoPage
            orderSelectedInfo={orderSelectedInfo}
            userInfo={userInfo}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default OrderListPage;
