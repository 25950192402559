import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";
import Popup from "components/popup/popup";
import "assets/css/login.css";

const MainPage = () => {
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [modalList, setModalList] = useState([]);

  const getMainInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getMainInfo,
      requestConfig: {},
    })
      .then((res) => {
        if (res.success) {
          setModalList(res.modalList);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getMainInfo();
  }, []);

  return (
    <>
      <section id="main">
        <div className="visual">
          <div className="tx-area">
            <div className="tit">HEALTHCARE & OFME365</div>
            <div className="subTit">
              DXVX의 오브미365에서 건강을 관리하세요.
            </div>
          </div>

          <div className="thum">
            <img src="/assets/images/visual-thum.png" alt="" />
          </div>
        </div>

        <div className="about">
          <div className="tit-area">
            <div className="tit">ABOUT OFME365</div>
          </div>

          <div className="banner">
            <div className="tx-area">
              <div className="subj">SNP Microarray 기술을 이용</div>
              <div className="subs">
                질병관리청으로부터 허용가능한 항목으로 판매가 허용된 상품으로
                SNP Microarray 기술을 이용하여 웰니스 관련 유전자 검사를
                진행합니다. 영양, 생활습관, 신체적 특징에 따른 질병의 예방을
                위한 유전자 검사입니다.
                <br />
                <br />
                그렇기 때문에 반드시 질병을 진단 또는 치료를 하기 위한 검사가
                아님을 알고계셔야해요!
              </div>
            </div>

            <div className="thum">
              <img src="/assets/images/about-banner.png" alt="" />
            </div>
          </div>

          <ul className="about-list">
            <li>
              <div className="ico">
                <img src="/assets/images/about-ico1.png" alt="" />
              </div>
              <div className="subj">Delivery & Receipt</div>
              <div className="period">1 ~ 2 Days</div>
            </li>

            <li>
              <div className="ico">
                <img src="/assets/images/about-ico2.png" alt="" />
              </div>
              <div className="subj">Experiment</div>
              <div className="period">5 Days</div>
            </li>

            <li>
              <div className="ico">
                <img src="/assets/images/about-ico3.png" alt="" />
              </div>
              <div className="subj">Analysis</div>
              <div className="period">1 ~ 2 Days</div>
            </li>

            <li>
              <div className="ico">
                <img src="/assets/images/about-ico4.png" alt="" />
              </div>
              <div className="subj">Result</div>
              <div className="period">Turnaround Time 14Days</div>
            </li>
          </ul>
        </div>

        <div className="product">
          <div className="tit-area">
            <div className="sub-tit"></div>
            <div className="tit mar_t20">Make Positive PRODUCT</div>
          </div>

          <ul className="product-list">
            <li>
              <div className="link-btn">
                <Link to="/product">
                  <img src="/assets/images/link-arrow.png" alt="" />
                </Link>
              </div>
              <div className="tx-area">
                <div className="subj">오브미365</div>
                <div className="subs">
                  보건복지부로 부터 인증 받은 항목으로 판매가 허용된 상품으로
                  SNP Microarray 기술을 이용하여 웰니스 관련 유전자검사를
                  진행합니다.
                </div>

                <div className="logo">
                  <img src="/assets/images/logo.png" alt="" />
                </div>
              </div>

              <div className="thum">
                <img src="/assets/images/product-thum1.png" alt="" />
              </div>
            </li>

            <li style={{ background: "rgba(0,0,0,0.7)" }}>
              <div
                style={{
                  position: "absolute",
                  fontSize: "83px",
                  verticalAlign: "middle",
                  color: "#FFFFFF",
                  fontWeight: "700",
                }}
              >
                Comming Soon...
              </div>
              <div className="link-btn">
                <Link to="/product">
                  <img src="/assets/images/link-arrow.png" alt="" />
                </Link>
              </div>
              <div className="tx-area">
                <div className="subj">오브미365</div>
                <div className="subs">
                  보건복지부로 부터 인증 받은 62개의 항목으로 판매가 허용된
                  상품으로 SNP Microarray 기술을 이용하여 웰니스 관련
                  유전자검사를 진행합니다.
                </div>

                <div className="logo">
                  <img src="/assets/images/logo.png" alt="" />
                </div>
              </div>

              <div className="thum">
                <img src="/assets/images/product-thum2.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </section>
      {modalList.map((item) => (
        <Popup file_name={item.file_name} url={item.modal_url} />
      ))}
    </>
  );
};

export default MainPage;
