import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div id="container" className="container">
      <div className="policy">
        <h2>디엑스앤브이엑스 주식회사 개인정보 처리방침</h2>
        <span className="title">
          디엑스앤브이엑스 주식회사{"("}이하 "회사"라 함{")"}는 정보주체의
          자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를
          준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.
          이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보의 처리와
          보호에 관한 절차 및 기준을 안내하고, 이와 관련된 고충을 신속하고
          원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립하여
          공개합니다.
        </span>
        <span className="title">1. 개인정보의 처리목적</span>
        <div className="inner">
          <span className="content">
            회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
            개인정보는 다음의 목적이외의 용도로는 이용하지 않으며, 이용목적이
            변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를
            받는 등 필요한 조치를 이행할 예정입니다.
          </span>
          <span className="content">1{")"}지놈체크 회원 가입 및 관리</span>
          <div className="inner">
            <span className="content">
              회원 가입 의사 확인, 본인 식별·인증, 회원자격의 유지·관리, 서비스
              부정이용 방지, 각종 고지, 고충처리 목적으로 개인정보를 처리합니다.
            </span>
          </div>
          <span className="content">
            2{")"}유전자검사{"("}DTC{")"} 서비스 제공
          </span>
          <div className="inner">
            <span className="content">
              물품 배송, 유전자검사 동의서 요건 확인, 서비스 제공, 청구서 발송,
              본인인증, 연령 인증, 요금결제·정산, 검사결과의 확인의 목적으로
              개인정보를 처리합니다.
            </span>
          </div>
        </div>
        <span className="title">2. 수집하는 개인정보의 항목 및 수집방법</span>
        <div className="inner">
          <span className="content">
            회사는 「개인정보 보호법」에 따라 서비스 제공을 위해 필요 최소한의
            범위에서 개인정보를 수집•이용합니다. 다음의 개인정보 항목을
            정보주체의 동의없이 처리하고 있습니다.
          </span>
          <table className="policy-tb">
            <thead>
              <tr>
                <th scope="cols">구분</th>
                <th scope="cols">수집항목</th>
                <th scope="cols">법적 근거 및 수집방법</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>동의서</td>
                <td>이름, 생년월일, 주소, 전화번호, 성별</td>
                <td>
                  <span>
                    ● 생명윤리 및 안전에 관한 법률 제51조 제1항{"("}
                    ‘유전자검사의 동의’{")"}
                  </span>
                  <span>● 검사 의뢰시 서면 동의</span>
                </td>
              </tr>
              <tr>
                <td>회원정보</td>
                <td>아이디, 주소, 생년월일, 이메일, 휴대폰번호</td>
                <td>
                  <span>
                    ● 개인정보 보호법 제15종 제1항 제4호{"("}‘계약이행’{")"}
                  </span>
                  <span>
                    ● 웹상 정보주체가 개인정보 수집에 대해 동의를 하고 본인
                    인증후 직접 정보를 입력하는 경우, 해당 개인정보를 수집
                  </span>
                </td>
              </tr>
              <tr>
                <td>주문 및 결제 처리</td>
                <td>아이디, 주문내역, 결제정보</td>
                <td>
                  <span>
                    ● 개인정보 보호법 제15종 제1항 제4호{"("}‘계약이행’{")"}
                  </span>
                  <span>● 주문 및 결제 처리</span>
                </td>
              </tr>
              <tr>
                <td>제품 배송</td>
                <td>성명, 전화번호, 주소, 결제번호</td>
                <td>
                  <span>
                    ● 개인정보 보호법 제15종 제1항 제4호{"("}‘계약이행’{")"}
                  </span>
                  <span>● 제품 배송, 회수처리, 반품 처리</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span className="title">3. 수집하는 개인정보의 처리 및 보유 기간</span>
        <div className="inner">
          <span className="content">
            회사는 법령에 따른 개인정보 보유•이용기간 또는 정보주체로부터
            개인정보를 수집 시에 동의 받은 개인정보 보유•이용기간 내에서
            개인정보를 처리•보유합니다. 각각의 개인정보 보유 및 처리기간은
            다음과 같습니다.
          </span>
        </div>
        <table className="policy-tb">
          <thead>
            <tr>
              <th scope="cols">보유정보</th>
              <th scope="cols">보유기간</th>
              <th scope="cols">근거법령</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>홈페이지 회원 가입 및 관리</td>
              <td>회원 탈퇴시까지</td>
              <td></td>
            </tr>
            <tr>
              <td>동의서</td>
              <td>10년</td>
              <td>
                생명윤리 및 안전에 관한 법률 시행규칙 제53조 제1항{"("}‘기록의
                관리 및 열람’{")"}
              </td>
            </tr>
            <tr>
              <td>대금결제 및 재화등의 공급에 관한 기록</td>
              <td>5년</td>
              <td rowSpan={4}>
                전자상거래 등에서의 소비자보호에 관한 법률 제 6조에 따른
                표시•광고, 계약내용 및 이행등 거래에 관한 기록
              </td>
            </tr>
            <tr>
              <td>계약 또는 청약철회에 관한 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
              <td>3년</td>
            </tr>
            <tr>
              <td>표시·광고에 관한 기록</td>
              <td>6개월</td>
            </tr>
            <tr>
              <td>웹페이지 방문 기록</td>
              <td>3개월</td>
              <td>통신비밀보호법</td>
            </tr>
            <tr>
              <td>전자금융 거래에 관한 기록</td>
              <td>5년</td>
              <td>전자금융거래법</td>
            </tr>
          </tbody>
        </table>
        <span className="title">3. 개인정보 처리의 위탁</span>
        <div className="inner">
          <span className="content">
            개인정보 처리의 위탁 계약 체결 시 「개인정보 보호법」 제 26조에 따라
            위탁업무 목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁
            제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서
            등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
            감독하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 바로
            수정 공개하도록 하겠습니다.
          </span>
        </div>
        <table className="policy-tb">
          <thead>
            <tr>
              <th scope="cols">수탁업체명</th>
              <th scope="cols">위탁업무</th>
              <th scope="cols">보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>한진택배</td>
              <td>
                제품{"("}검체채취키트{")"}의 배송
              </td>
              <td>회원탈퇴 또는 위탁계약 종료시까지</td>
            </tr>
            <tr>
              <td>NICE페이먼츠</td>
              <td>안전결제 서비스</td>
              <td>회원탈퇴 또는 위탁계약 종료시까지</td>
            </tr>
            <tr>
              <td>ITEASY</td>
              <td>SMS/MMS 발송</td>
              <td>회원탈퇴 또는 위탁계약 종료시까지</td>
            </tr>
            <tr>
              <td>NICE아이디</td>
              <td>휴대폰 본인인증</td>
              <td>회원탈퇴 또는 위탁계약 종료시까지</td>
            </tr>
            <tr>
              <td>ITEASY cloud</td>
              <td>인프라 제공</td>
              <td>회원탈퇴 또는 위탁계약 종료시까지</td>
            </tr>
          </tbody>
        </table>
        <span className="title">5. 개인정보의 파기</span>
        <span className="content">
          개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
          되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </span>
        <div className="inner">
          <span className="content">
            1{")"} 개인정보 동의 철회 시, 보유기간의 경과, 처리목적 달성, 검사
            서비스의 폐지, 폐업 등 개인정보가 불필요하게 되었을 때에는 지체 없이
            해당 개인정보를 파기합니다.
          </span>
          <span className="content">
            2{")"} 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나
            처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
            보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스{"("}DB
            {")"}로 옮기거나 보관 정보를 달리하여 보존합니다.
          </span>
          <span className="content">
            3{")"} 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </span>
          <div className="inner">
            <span className="content">
              ▪ 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
              개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
            </span>
            <span className="content">
              ▪ 파기방법 : 회사는 전자적 파일 형태로 기록·저장된 개인정보는
              기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된
              개인정보는 분쇄기로 소각하여 파기합니다.
            </span>
          </div>
        </div>
        <span className="title">
          6. 개인정보 보호책임자 및 고충사항을 처리하는 부서에 관한 사항
        </span>
        <div className="inner">
          <span className="content">
            개인정보보호 관련문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
            보호책임자 및 담당부서로 문의하실 수 있습니다. 정보주체의 문의에
            대해 지체없이 답변 및 처리해 드릴 것입니다.
          </span>
          <table className="policy-tb">
            <thead>
              <tr>
                <th scope="cols">구분</th>
                <th scope="cols">소속</th>
                <th scope="cols">성명 / 직책</th>
                <th scope="cols">문의</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>개인정보 보호책임자</td>
                <td>R&D 센터</td>
                <td>박상진 / 본부장</td>
                <td rowSpan={2}>E-mail. dtc@dxvx.com TEL. 02-6964-8741</td>
              </tr>
              <tr>
                <td>개인정보 관리담당자</td>
                <td>정밀의료팀</td>
                <td>이동하 / 팀원</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span className="title">
          7. 정보주체 및 법정대리인의 권리와 행사방법
        </span>
        <div className="inner">
          <span className="content">
            1{")"} 정보주체는 회사에 대해 언제든지 다음 각호의 개인정보보호 관련
            권리를 행사 할수 있습니다.
          </span>
          <div className="inner">
            <span className="content">① 개인정보 열람요구 </span>
            <span className="content">② 오류 등이 있을 경우 정정요구 </span>
            <span className="content">③ 삭제 요구 </span>
            <span className="content">④ 처리정지 요구</span>
          </div>
          <span className="content">
            2{")"} “1{")"}”에 따른 권리행사는 회사에 대해 개인정보보호법 시행령
            제41조 제1항에 따라 서면, 전화, 전자우편, 모사전송{"("}FAX{")"}
            등을 통하여 하실수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
          </span>
          <span className="content">
            3{")"} 정보주체가 개인정보의 오류등에 대한 정정 또는 삭제를 요구한
            경우에는 회사 홈페이지는 정정 또는 삭제를 완료할 때까지 당해
            개인정보를 이용하거나 제공하지 않습니다.
          </span>
          <span className="content">
            4{")"} “1{")"}”에 따른 권리행사는 정보주체의 법정대리인이나 위임을
            받은자 등 대리인을 통하여 하실수 있습니다. 이 경우 개인정보처리
            위임장을 제출하셔야 합니다.
          </span>
          <span className="content">
            5{")"} 개인정보열람 및 처리정지요구는 개인정보보호법 제35조 제4항,
            제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
          </span>
          <span className="content">
            6{")"} 개인정보의 정정 및 삭제요구는 다른 법령에서 그 개인정보가
            수집대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          </span>
          <span className="content">
            7{")"} 회사는 정보주체 권리에 따른 열람의 요구, 정정삭제의 요구,
            처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
          </span>
          <span className="content">
            8{")"} 열람 등의 요구에 대한 거절 조치에 대하여 불복이 있는 경우
            정보주체는 아래기관에 이의를 제기할 수 있습니다.
          </span>
          <div className="inner">
            <span className="content">
              ▪ 개인정보분쟁조정위원회{"("}국번없이{")"} 1833-6972
              http://www.kopico.go.kr
            </span>
            <span className="content">
              ▪ 개인정보침해신고센터{"("}국번없이{")"} 118
              http://privacy.kisa.or.kr
            </span>
            <span className="content">
              ▪ 대검찰청 과학수사부사이버수사과 {"("}국번없이{")"} 1301
              http://spo.go.kr{"("}cid@spo.go.kr{")"}
            </span>
            <span className="content">
              ▪ 경찰청 사이버안전국{"("}국번없이{")"} 182
              https://www.police.go.kr/www/security/cyber.jsp
            </span>
          </div>
        </div>
        <span className="title">8. 개인정보의 안정성 확보조치에 관한 사항</span>
        <div className="inner">
          <span className="content">
            개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
          </span>
          <span className="content">1{")"} 내부관리계획 수립 및 시행 </span>
          <div className="inner">
            <span className="content">
              ‘개인정보의 기술적∙관리적 보호조치 기준’{"("}
              개인정보보호위원회고시
              {")"} 및 ‘개인정보의 안전성 확보조치 기준’{"("}
              개인정보보호위원회고시
              {")"}에 의거하여 내부관리계획을 수립 및 시행합니다.
            </span>
          </div>
          <span className="content">2{")"} 개인정보에 대한 접근 제한 </span>
          <div className="inner">
            <span className="content">
              개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의
              부여∙변경∙말소를 통하여 개인정보에 대한 접근통제를 위한 필요한
              조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
              접근을 통제하고 있습니다.
            </span>
          </div>
          <span className="content">3{")"} 접속기록의 보관 및 위‧변조 </span>
          <div className="inner">
            <span className="content">
              {/* <span style={{ color: "red" }}> */}
              개인정보처리시스템에 접속한 기록을 최소 2년 이상 보관∙관리하고
              있으며,
              {/* </span> */}
              접속기록이 위‧변조 및 도난, 분실되지 않도록 관리하고 있습니다.
            </span>
          </div>
          <span className="content">4{")"} 개인정보의 암호화 </span>
          <div className="inner">
            <span className="content">
              개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다.
              또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도
              보안기능을 사용하고 있습니다.
            </span>
          </div>
          <span className="content">
            5{")"} 보안프로그램 설치 및 주기적 점검∙갱신
          </span>
          <div className="inner">
            <span className="content">
              해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
              위하여 보안프로그램을 설치하고 주기적으로 점검∙갱신하고 있습니다.
            </span>
          </div>
          <span className="content">6{")"} 비인가자에 대한 출입 통제 </span>
          <div className="inner">
            <span className="content">
              개인정보를 보관하고 있는 개인정보처리시스템의 물리적 보관 장소를
              별도로 두고 이에 대해 출입통제 절차를 수립∙운영하고 있습니다.
            </span>
          </div>
        </div>
        <span className="title">9. 개인정보 처리방침 변경</span>
        <div className="inner">
          <span className="content">
            본 개인정보 처리방침은 2024. 05. 20부터 적용됩니다.
          </span>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
