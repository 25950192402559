import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { orderListPageIndexState } from "recoil/atoms";
import useAxiosFunction from "hooks/useAxios";
import moment from "moment";
import Select from "react-select";
import UserReqPage from "pages/user/user/userReqPage";
import UserReqDBPage from "pages/user/user/userReqDBPage";
import UserReqCompletePage from "pages/user/user/userReqCompletePage";
import CustomModal from "components/popup/customModal";
import PostHistoryPage from "./postHistoryPage";
import "assets/css/board.css";
import "assets/css/newAdmin.css";

const UserOrderListPage = (props) => {
  const user_id = props.user_id;
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [isReq, setIsReq] = useState(false);
  const [isCompleteReq, setIsCompleteReq] = useState(false);
  const [item, setItem] = useState();
  const [orderList, setOrderList] = useState([]);
  const [orderResult, setOrderResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [selectedCompleteID, setSelectedCompleteID] = useState(null);
  const [postHistoryModalIsOpen, setPostHistoryModalIsOpen] = useState(false);
  const [orderListPage, setOrderListPage] = useRecoilState(
    orderListPageIndexState
  );

  const selectOrderTypeOptions = [
    { value: 0, label: "주문완료" },
    { value: 1, label: "배송중" },
    { value: 2, label: "배송완료" },
    { value: 3, label: "회수요청" },
    { value: 4, label: "회수중" },
    { value: 5, label: "검사중" },
    { value: 6, label: "검사완료" },
  ];

  const openPostHistoryModal = (e, data) => {
    e.preventDefault();

    setSelectedCompleteID(data.complete_id);
    setPostHistoryModalIsOpen(true);
  };

  const closePostHistoryModal = () => {
    setSelectedCompleteID(null);
    setPostHistoryModalIsOpen(false);
  };

  const selectedOrderTypeHandler = (e, data) => {
    const updatedItems = orderList.map((item) => {
      if (item.complete_id === data.complete_id) {
        return { ...item, order_complete_status: e.value };
      }
      return item;
    });

    setOrderList(updatedItems);
  };

  const reqReturnHandler = (e, flag, reqData) => {
    e.preventDefault();
    setItem(reqData);

    if (flag) {
      setIsReq(true);
    } else {
      setIsCompleteReq(true);
    }
  };

  const reqPostHandler = (e, reqData) => {
    e.preventDefault();
    setItem(reqData);

    setIsCompleteReq(true);
  };

  const getOrderList = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserOrderList,
      requestConfig: { currentNavPage: reqData, user_id: user_id },
    })
      .then((res) => {
        if (res.success) {
          setOrderResult(res.result);
          setOrderList(res.orderList);
          setPageList(res.pageList);
          setOrderListPage(reqData);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getOrderList(orderListPage);
  }, []);

  useEffect(() => {
    getOrderList(orderListPage);
  }, [isReq]);

  return (
    <>
      {isReq === true ? (
        Number(item.item_id) === 1 ? (
          <UserReqPage item={item} setIsReq={setIsReq} />
        ) : Number(item.item_id) === 10 ? (
          <UserReqDBPage item={item} setIsReq={setIsReq} />
        ) : null
      ) : isCompleteReq === true ? (
        <UserReqCompletePage item={item} setIsCompleteReq={setIsCompleteReq} />
      ) : (
        <div>
          <div className="container-n-admin" style={{ margin: "0 300px" }}>
            <div className="content-area">
              <div className="board-info">
                <div className="board-total">
                  <span>총 </span>
                  {orderResult.totalrows}
                  <span>건의 주문</span>
                </div>
              </div>
              <table className="boardTable">
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>이름</th>
                    <th>연락처</th>
                    <th>주소</th>
                    <th>상세주소</th>
                    <th>주문일시</th>
                    <th>상품명</th>
                    <th>주문상태</th>
                    <th>회수신청</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList?.map((item, index) => (
                    <tr key={item.row_num}>
                      <td>
                        <div>{orderResult.totalrows - item.row_num + 1}</div>
                      </td>
                      <td>
                        <div>{item.user_name}</div>
                      </td>
                      <td>
                        <div>{item.user_phone}</div>
                      </td>
                      <td>
                        <div>
                          {"("}
                          {item.user_post}
                          {")"}
                          {item.user_addr1}
                        </div>
                      </td>
                      <td>
                        <div>{item.user_addr2}</div>
                      </td>
                      <td>
                        <div>
                          {moment(item.order_date).format("YYYY/MM/DD")}
                        </div>
                      </td>
                      <td>
                        <div>{item.item_name}</div>
                      </td>
                      <td>
                        <div className="select-area">
                          <Select
                            className="select"
                            options={selectOrderTypeOptions}
                            value={selectOrderTypeOptions.filter(function (
                              option
                            ) {
                              return (
                                option.value === item.order_complete_status
                              );
                            })}
                            isDisabled={true}
                            onChange={(e) => selectedOrderTypeHandler(e, item)}
                          ></Select>
                        </div>
                      </td>
                      <td>
                        {item.order_complete_status === 0 ? (
                          <span style={{ padding: "0 10px" }}>배송준비중</span>
                        ) : item.order_complete_status === 1 ? (
                          <span style={{ padding: "0 10px" }}>
                            <button
                              className="update-btn"
                              onClick={(e) => openPostHistoryModal(e, item)}
                            >
                              배송현황
                            </button>
                          </span>
                        ) : item.order_complete_status === 2 ? (
                          <button
                            className="update-btn"
                            onClick={(e) => reqReturnHandler(e, true, item)}
                          >
                            회수신청
                          </button>
                        ) : (
                          <button
                            className="update-btn"
                            onClick={(e) => reqReturnHandler(e, false, item)}
                          >
                            회수내역
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <ul className="paging">
            <li>
              <Link
                onClick={() =>
                  getOrderList(
                    orderResult.currentPage - 10 < 1
                      ? 1
                      : orderResult.currentPage - 10
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardFirst.png" alt="" />
              </Link>
            </li>
            {pageList?.map((item) => (
              <li
                key={item}
                className={item === orderResult.currentPage ? "on" : null}
              >
                <Link
                  onClick={() => getOrderList(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </Link>
              </li>
            ))}

            <li>
              <Link
                onClick={() =>
                  getOrderList(
                    pageList[pageList.length - 1] + 1 < orderResult.maxPage
                      ? pageList[pageList.length - 1] + 1
                      : pageList[pageList.length - 1]
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardLast.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>
      )}

      <CustomModal
        isOpen={postHistoryModalIsOpen}
        onRequestClose={closePostHistoryModal}
        maxHeight="550px"
        minWidth="800px"
      >
        <PostHistoryPage complete_id={selectedCompleteID} />
      </CustomModal>
    </>
  );
};

export default UserOrderListPage;
