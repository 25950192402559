import React, { useState, useEffect } from "react";
import useAxiosFunction from "hooks/useAxios";

const FaqPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const FaqItem = ({ question, answer, isOpen, onItemClick }) => {
    return (
      <div className="faq-item">
        <div className="faq-title" onClick={onItemClick}>
          <img
            className={`arrow-icon ${isOpen ? "open" : "closed"}`}
            src="assets/images/sub/right_arrow_m.png"
            alt="Arrow"
          />
          Q. {question}
        </div>
        <div className={`faq-content ${isOpen ? "open" : ""}`}>A. {}
          {answer.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== answer.split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const handleItemClick = (index) => {
    setOpenIndex(index === openIndex ? null : index); // 클릭한 항목이 이미 열려있는지 확인하고, 열려있는 항목이면 닫습니다.
  };

  const getFaqList = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getFaqList,
      requestConfig: null,
    })
      .then((res) => {
        setFaqs(Object.keys(res).map((key) => res[key]));
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <div id="container" className="container">
      <div className="container">
        <div className="content-box5">
          <img src="/assets/images/common/genomecheck.png" alt="" />
          <div className="main-title">
            <span>자주묻는질문</span>
            <div className="rt" />
          </div>
        </div>

        <div className="customer">
          <section className="sub">
            <div className="sub-content">
              <div className="faq-container">
                {faqs.map((faq, index) => (
                  <FaqItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={index === openIndex}
                    onItemClick={() => handleItemClick(index)}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
