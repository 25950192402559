import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";
import { menuData } from "data/menuData";

const Header = () => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [isHome, setIsHome] = useState(false);
  const [mobileBtn, setMobileBtn] = useState(false);
  const [isSubMenuVisible, setSubMenuVisible] = useState(null);

  const navigate = useNavigate();

  const handleMouseOver = (e, index) => {
    e.preventDefault();
    setSubMenuVisible(index);
  };

  const handleMouseLeave = () => {
    setSubMenuVisible(null);
  };

  const Logout = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      user_id: null,
      user_name: null,
      user_type: null,
      user_birth: null,
      user_phone: null,
    });
    sessionStorage.removeItem("rToken");
    setMobileBtn(false);
    navigate("/");
  };

  const mobileMenuBtn = () => {
    setMobileBtn(!mobileBtn);
  };

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/product") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location]);

  return (
    <header id="header">
      <div className="logo">
        <Link to="/">
          <img src="/assets/images/logo.png" alt="" />
        </Link>
      </div>

      <div className="menu">
        <ul>
          {menuData.map((item, index) => (
            <>
              <li
                className={isSubMenuVisible === index ? "active" : ""}
                onMouseOver={(e) => handleMouseOver(e, index)}
                onMouseLeave={handleMouseLeave}
              >
                <Link to={item.link} state={item.state}>
                  {item.name}
                </Link>
                <ul className="lnb_sub">
                  {item.data.map((data, idx) => (
                    <li>
                      <Link to={data.link}>{data.subName}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            </>
          ))}
          {userInfo?.user_type === "A" ||
          userInfo?.user_type === "S" ||
          userInfo?.user_type === "I" ||
          userInfo?.user_type === "D" ? (
            <>
              <li>
                <Link to="/admin">관리자</Link>
              </li>
            </>
          ) : null}
        </ul>
        {/* <!-- mainMenu --> */}
      </div>

      <div className="rt-menu">
        {userInfo?.isLogin === false ? null : (
          <ul class="shop-menu">
            <li>
              <Link to="myinfo">
                <div>
                  <img src="/assets/images/user-info.png" alt="" />
                  <span>회원정보</span>
                </div>
              </Link>
            </li>
          </ul>
        )}
        <ul className="mem-menu">
          {userInfo?.isLogin === false ? (
            <>
              <li>
                <Link to="/login">로그인</Link>
              </li>
              <li>
                <Link to="/signUp">회원가입</Link>
              </li>
            </>
          ) : (
            <>
              <li onClick={() => Logout()}>
                <Link>로그아웃</Link>
              </li>
            </>
          )}
        </ul>
      </div>

      <div class="moMenu" onClick={mobileMenuBtn}>
        <img src="/assets/images/moMenu.png" alt="" />
      </div>

      {mobileBtn ? (
        <div class="moSlideMenu">
          <div class="topA">
            <div class="logo">
              <Link to="/" onClick={mobileMenuBtn}>
                <img src="/assets/images/footLogo.png" alt="" />
              </Link>
            </div>
            <div class="close" onClick={mobileMenuBtn}>
              <img src="/assets/images/close.png" alt="" />
            </div>
          </div>

          <ul class="menuList">
            <li onClick={mobileMenuBtn}>
              <Link to="/product">상품설명</Link>
            </li>
            <li onClick={mobileMenuBtn}>
              <Link to="/customer">고객센터</Link>
            </li>
            <li onClick={mobileMenuBtn}>
              <Link to="/mypage">결과확인</Link>
            </li>
            <li onClick={mobileMenuBtn}>
              <Link to="/market">구매하기</Link>
            </li>
            {userInfo?.user_type === "A" ? (
              <li onClick={mobileMenuBtn}>
                <Link to="/admin">관리자</Link>
              </li>
            ) : null}
            {userInfo?.isLogin === false ? (
              <>
                <li onClick={mobileMenuBtn}>
                  <Link to="/login" className="button-primary size-l">
                    <span>로그인</span>
                  </Link>
                </li>
                <li onClick={mobileMenuBtn}>
                  <Link to="/signUp" className="button-primary size-l">
                    <span>회원가입</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li onClick={mobileMenuBtn}>
                  <Link to="/myinfo" className="button-primary size-l">
                    <span>My Info</span>
                  </Link>
                </li>

                <li onClick={() => Logout()}>
                  <Link to="/" className="button-primary size-l">
                    <span>로그아웃</span>
                  </Link>
                </li>
              </>
            )}
          </ul>

          {/* <ul className="mobile-utility">
            {userInfo?.isLogin === false ? (
              <>
                <li onClick={mobileMenuBtn}>
                  <Link to="/login" className="button-primary size-l">
                    <span>로그인</span>
                  </Link>
                </li>
                <li onClick={mobileMenuBtn}>
                  <Link to="/signUp" className="button-primary size-l">
                    <span>회원가입</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li onClick={mobileMenuBtn}>
                  <Link to="/myinfo" className="button-primary size-l">
                    <span>My Info</span>
                  </Link>
                </li>

                <li onClick={() => Logout()}>
                  <Link to="/" className="button-primary size-l">
                    <span>로그아웃</span>
                  </Link>
                </li>
              </>
            )}
          </ul> */}

          <div class="copy">Copyright 2023 Dx&Vx All rights reserved.</div>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
