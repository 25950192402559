import React from "react";
import "assets/css/board.css";
import moment from "moment";

const OrderWorkListPage = (props) => {
  const orderWorkHistoryList = props.orderWorkHistoryList;
  const selectUserTypeOptions = [
    { value: "U", label: "유저" },
    { value: "A", label: "총괄책임자" },
    { value: "D", label: "결과전달담당자" },
    { value: "S", label: "검사서비스담당자" },
    { value: "I", label: "결과정보처리담당자" },
  ];

  return (
    <>
      <div className="container-n-admin" style={{ margin: "0" }}>
        <div className="content-area">
          <div className="board-info" style={{ margin: "0" }}>
            <div className="board-total">
              <span>총 </span>
              {orderWorkHistoryList.length}
              <span>건의 작업내역</span>
            </div>
          </div>

          <div>
            <table className="boardTable">
              <thead>
                <tr>
                  <th>번호</th>
                  <th style={{ background: "#f2f2f2" }}>ID</th>
                  <th style={{ background: "#f2f2f2" }}>이름</th>
                  <th>유저타입</th>
                  <th>작업내용</th>
                  <th>일시</th>
                </tr>
              </thead>
              <tbody className="work-history">
                {orderWorkHistoryList?.map((item, index) => (
                  <tr key={item.row_num}>
                    <td> {orderWorkHistoryList.length - item.row_num + 1}</td>
                    <td>{item.user_id}</td>
                    <td>{item.user_name}</td>
                    <td>
                      {
                        selectUserTypeOptions.find(
                          (option) => option.value === item.user_type
                        )?.label
                      }
                    </td>
                    <td>{item.order_work_content}</td>
                    <td>
                      {moment(item.order_work_date).format(
                        "YYYY/MM/DD HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderWorkListPage;
