import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/modal.css";

const Modal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, closeDayModal, url } = props;

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? "openModal modal" : "modal"}>
      <section>
        <Link to={url} onClick={close}>
          <main>{props.children}</main>
        </Link>
        <footer>
          <button className="btn1" onClick={closeDayModal}>
            하루동안보이지않기
          </button>
          <span>|</span>
          <button className="btn2" onClick={close}>
            닫기
          </button>
        </footer>
      </section>
    </div>
  );
};

export default Modal;
