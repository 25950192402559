import React, { useEffect, useState } from "react";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import ModalWritePage from "./modalWritePage";
import ModalUpdatePage from "./modalUpdatePage";

const ModalListPage = () => {
  const [isWrite, setIsWrite] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [modalList, setModalList] = useState([]);
  const [updateModalItem, setUpdateModalItem] = useState(null);

  const setWriteMode = (e, data) => {
    e.preventDefault();
    setIsWrite(data);
  };

  const handleRemoveModal = (e, item) => {
    e.preventDefault();
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      axiosFetchA({
        method: "post",
        url: apiUrls.delModalList,
        requestConfig: { modal_id: item.modal_id, file_name: item.file_name },
      })
        .then((res) => {
          if (res.success) {
            alert(res.message);
            getModalList();
          }
        })
        .catch((err) => {
          // console.log("[ERROR] handleRemoveModal: ", err);
        });
    }
  };

  const handleUpdateModal = (e, flag, item) => {
    e.preventDefault();
    setIsUpdate(flag);
    setUpdateModalItem(item);
  };

  const getModalList = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getModalList,
      requestConfig: {},
    })
      .then((res) => {
        if (res.success) {
          setModalList(res.modalList);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] getModalList: ", err);
      });
  };

  useEffect(() => {
    getModalList();
  }, []);

  return (
    <div className="container">
      <div className="content-box">
        <div className="board-write">
          {isWrite === true ? (
            <ModalWritePage
              setIsWrite={setIsWrite}
              getModalList={getModalList}
            />
          ) : isUpdate === true ? (
            <ModalUpdatePage
              setIsUpdate={setIsUpdate}
              getModalList={getModalList}
              updateModalItem={updateModalItem}
            />
          ) : (
            <>
              <button
                style={{ marginLeft: "0" }}
                className="add-btn"
                onClick={(e) => setWriteMode(e, true)}
              >
                팝업등록
              </button>
              <div
                className="modal-container"
                style={{ padding: "50px 0px 150px 0px" }}
              >
                <ul>
                  {modalList.map((item, index) => (
                    <li>
                      <img
                        src={
                          process.env.NODE_ENV === "development"
                            ? `http://localhost:3000/static/modal/${item.file_name}`
                            : `/static/modal/${item.file_name}`
                        }
                        alt={`홍보-${index}`}
                      />
                      <div className="btn-area">
                        <button
                          style={{ background: "#345a2b" }}
                          onClick={(e) => handleUpdateModal(e, true, item)}
                        >
                          수정
                        </button>
                        <button onClick={(e) => handleRemoveModal(e, item)}>
                          삭제
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalListPage;
