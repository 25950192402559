import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import InputUnit from "components/InputUnit";
import "assets/css/board.css";

const ReportControl = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [reportList, setReportList] = useState([]);
  const { userID } = useParams();
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const getUserReportList = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getUserReportList,
      requestConfig: { user_id: userID },
    })
      .then((res) => {
        if (res.success) {
          setReportList(res.data);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getUserReportList : ", err);
      });
  };

  const addSampleID = (reqData) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.addSampleID,
      requestConfig: {
        user_id: userID,
        report_code: reqData.report_code,
        confirm_code: reqData.confirm_code,
      },
    })
      .then((res) => {
        if (res.success === true) {
          getUserReportList();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  const delSampleID = (reqData, e) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.delSampleID,
      requestConfig: {
        user_id: userID,
        report_code: reqData.report_code,
        confirm_code: reqData.confirm_code,
      },
    })
      .then((res) => {
        if (res.success === true) {
          getUserReportList();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUserReportList();
  }, []);

  return (
    <>
      <div className="container">
        <div className="content-box5">
          <img src="/assets/images/common/genomecheck.png" alt="" />
          <div className="main-title">
            <span>{userID}님 결과 등록</span>
            <div className="rt" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="content-box2">
          <div className="half">
            <div className="board-info">
              <div className="add-sample">
                <form
                  className="input-area"
                  onSubmit={handleSubmit(addSampleID)}
                >
                  <InputUnit
                    label="Kit Number"
                    holder="Kit Number"
                    register={register("kit_number", {
                      required: "필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="Confirm Code"
                    holder="Confirm Code"
                    register={register("confirm_code", {
                      required: "필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button className="btn" type="submit">
                      추가
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="board-list">
              <ul className="boardList">
                <li className="head">
                  <div className="sample-id">
                    <strong>ID</strong>
                  </div>
                  <div className="sample-id">
                    <strong>키트번호</strong>
                  </div>
                  <div className="sample-id">
                    <strong>확인코드</strong>
                  </div>
                  <div className="sample-id">
                    <strong>삭제</strong>
                  </div>
                </li>
                {reportList?.map((item, index) => (
                  <>
                    <li
                      key={item.report_code + index}
                      className="mobile-board-li"
                    >
                      <div className="sample-id">{item.user_id}</div>
                      <div className="sample-id">{item.report_code}</div>
                      <div className="sample-id">{item.confirm_code}</div>
                      <div
                        className="sample-id"
                        onClick={(e) => delSampleID(item, e)}
                      >
                        <img src="/assets/images/common/delete.png" alt="" />
                      </div>
                    </li>
                    <li key={item.report_code} className="pc-board-li">
                      <div className="sample-id">{item.user_id}</div>
                      <div className="sample-id">{item.report_code}</div>
                      <div className="sample-id">{item.confirm_code}</div>
                      <div
                        className="sample-id"
                        onClick={(e) => delSampleID(item, e)}
                      >
                        <img src="/assets/images/common/delete.png" alt="" />
                      </div>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportControl;
