import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footLogo">
        <img src="/assets/images/logo.png" alt="" />
      </div>
      <div className="infoA">
        <div className="nm">
          <div>사업자정보</div>
        </div>
        <div className="info">디엑스앤브이엑스㈜ | 대표자 : 권규찬, 이용구</div>
        <div className="info">
          주소 : 서울특별시 금천구 디지털로 173, 1003호(가산동, 엘리시아)
        </div>
        <div className="info">
          사업자등록번호 : 101-81-71321
          <button
            style={{
              background: "transparent",
              marginLeft: "10px",
              verticalAlign: "text-top",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                "http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1018171321",
                "_blank"
              )
            }
          >
            [사업자정보확인]
          </button>
        </div>
        <div className="info">통신판매업 : 2013-서울금천-0229</div>
        <div className="info">개인정보보호책임자 : 박상진</div>
        <div className="info">
          Copyright 2023 Dx&Vx All rights reserved. Version 1.0.0
        </div>
      </div>
      <div className="infoA">
        <div className="nm">
          <Link to="/tos">이용약관</Link>
        </div>
      </div>
      <div className="infoA">
        <div className="nm">
          <Link to="/policy">개인정보처리방침</Link>
        </div>
      </div>
      <div className="rtA">
        <ul className="sns">
          <li>
            <Link to="#">
              <img src="/assets/images/facebook.png" alt="" />
            </Link>
          </li>
          <li>
            <Link to="#">
              <img src="/assets/images/twitter.png" alt="" />
            </Link>
          </li>
          <li>
            <Link to="#">
              <img src="/assets/images/naver.png" alt="" />
            </Link>
          </li>
        </ul>

        <div className="brandSite">
          <button onClick={() => window.open("http://www.dxvx.com/", "_blank")}>
            Dx&Vx Site
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
