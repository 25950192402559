export const apiUrls = {
  login: "user/userLogin",
  signup: "user/userSignUp",
  getReportList: "user/getReportList",
  getReportData: "user/getReportData",
  downloadReportData: "user/downloadReportData",
  getNewReportData: "user/getNewReportData",
  delReportData: "user/delReportData",
  getAgreeData: "user/getAgreeData",
  addReport: "user/addReport",
  addRequest: "user/addRequest",
  updateRequest: "user/updateRequest",
  updatePassword: "user/updatePassword",
  getUserInfo: "user/getUserInfo",
  updateUserInfo: "user/updateUserInfo",
  getFaqList: "user/getFaqList",
  addInquiry: "user/addInquiry",
  getInquiryUList: "user/getInquiryUList",
  getUserOrderList: "user/getUserOrderList",
  getUserPostList: "user/getUserPostList",
  getUserRequest: "user/getUserRequest",
  getUserId: "user/getUserId",
  sendAuthMessage: "user/sendAuthMessage",
  updateDirectPw: "user/updateDirectPw",
  exitRequestUser: "user/exitRequestUser",

  getInquiryList: "admin/getInquiryList",
  updateInquiry: "admin/updateInquiry",
  getAllReportList: "admin/getAllReportList",
  getNewAllReportList: "admin/getNewAllReportList",
  getUserList: "admin/getUserList",
  getUserInfoDetail: "admin/getUserInfoDetail",
  getExitUserList: "admin/getExitUserList",
  delExitUserList: "admin/delExitUserList",
  updateUserType: "admin/updateUserType",
  getUserReportList: "admin/getUserReportList",
  getLoginHistoryList: "admin/getLoginHistoryList",
  getReportHistoryList: "admin/getReportHistoryList",
  getRequestHistoryList: "admin/getRequestHistoryList",
  getAllRequestHistoryList: "admin/getAllRequestHistoryList",
  addSampleID: "admin/addSampleID",
  delSampleID: "admin/delSampleID",
  addFaqList: "admin/addFaqList",
  delFaqList: "admin/delFaqList",
  updateFaqList: "admin/updateFaqList",
  getOrderList: "admin/getOrderList",
  getAllOrderList: "admin/getAllOrderList",
  updateOrderStatus: "admin/updateOrderStatus",
  getAdminItemList: "admin/getAdminItemList",
  deleteItem: "admin/deleteItem",
  getModalList: "admin/getModalList",
  addModalList: "admin/addModalList",
  delModalList: "admin/delModalList",
  updateModalList: "admin/updateModalList",
  sendMail: "admin/sendMail",
  sendMailReceipt: "admin/sendMailReceipt",
  RequestModification: "admin/requestModification",
  updateAgreeData: "admin/updateAgreeData",
  updateKitNumber: "admin/updateKitNumber",
  confirmKitNumber: "admin/confirmKitNumber",
  updateSampleReceive: "admin/updateSampleReceive",
  updateTrackingNumber: "admin/updateTrackingNumber",
  updateReturnTrackingNumber: "admin/updateReturnTrackingNumber",
  updateReceiptDate: "admin/updateReceiptDate",
  getOrderWorkHistory: "admin/getOrderWorkHistory",
  updateDeliveryApprovalStatus: "admin/updateDeliveryApprovalStatus",
  updateChiefApprovalStatus: "admin/updateChiefApprovalStatus",
  reInspectRequest: "admin/reInspectRequest",

  addItem: "market/addItem",
  updateItem: "market/updateItem",
  getItemList: "market/getItemList",
  getItemDetail: "market/getItemDetail",
  getUserDetail: "market/getUserDetail",
  saveWriteImg: "market/saveWriteImg",
  orderRequestBefore: "market/orderRequestBefore",
  orderRequestRemove: "market/orderRequestRemove",
  addOrder: "market/addOrder",
  getPostReturn: "market/getPostReturn",

  getDocument: "download/document",

  aTokenCheck: "token/aTokenCheck",
  rTokenCheck: "token/rTokenCheck",

  getNiceInit: "nice/getNiceInfo",
  getNiceDecodeData: "nice/getNiceDecodeData",

  getMainInfo: "etc/getMainInfo",
  addOrderWorkHistory: "etc/addOrderWorkHistory",
  addOrderWorkHistoryWithKitnumber: "etc/addOrderWorkHistoryWithKitnumber",
  updateReportConfirm: "etc/updateReportConfirm",
};
