import React, { useEffect, useState } from "react";
import "assets/css/board.css";
import moment from "moment";
import useAxiosFunction from "hooks/useAxios";

const PostHistoryPage = (props) => {
  const complete_id = props.complete_id;
  const [postList, setPostList] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const getPostList = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserPostList,
      requestConfig: { complete_id: complete_id },
    })
      .then((res) => {
        if (res.success) {
          setPostList(res.postList);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPostList();
  }, []);

  return (
    <>
      <div className="container-n-admin" style={{ margin: "0" }}>
        <div className="content-area">
          <div className="board-info" style={{ margin: "0" }}>
            <div className="board-total">
              <span>총 </span>
              {postList?.length}
              <span> 건의 배송정보</span>
            </div>
          </div>

          <table className="boardTable">
            <thead>
              <tr>
                <th>날짜</th>
                <th>상품위치</th>
                <th>배송진행상황</th>
              </tr>
            </thead>
            <tbody className="work-history">
              {postList?.map((item, index) => (
                <tr key={item.row_num}>
                  <td>{moment(item.post_date).format("YYYY/MM/DD HH:mm")}</td>
                  <td>{item.post_location}</td>
                  <td>{item.post_memo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PostHistoryPage;
