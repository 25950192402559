import React from "react";
import "assets/css/report.css";

const ReportDAType12Page = (props) => {
  const raceInfo = props.raceInfo;
  const dtcName = props.dtcName;

  return (
    <div className="grui-report">
      <div className="grui-page page10">
        <h2 className="grui-title border" style={{ marginBottom: "50px" }}>
          인종정보
        </h2>
        <div className="grui-results-sheet">
          <div className="grui-results-table">
            <div className="grui-rtable-head">
              <div className="rtable-col09">
                <span>항목명</span>
              </div>
              <div className="rtable-col07">
                <span>유전자명</span>
              </div>
              <div className="rtable-col07">
                <span>연구대상자수</span>
              </div>
              <div className="rtable-col08">
                <span>인종</span>
              </div>
            </div>
            <div className="grui-rtable-body">
              {raceInfo?.map((dtc, index) => {
                return (
                  <section>
                    <div className="rtable-col09">
                      <h4>{dtcName[index]}</h4>
                    </div>
                    <div>
                      {dtc.map((item) => {
                        return (
                          <article>
                            <div className="rtable-col07">
                              <p>{item.gene}</p>
                            </div>
                            <div className="rtable-col07">
                              <p>{item.number}</p>
                            </div>
                            <div className="rtable-col08">
                              <p>{item.race}</p>
                            </div>
                          </article>
                        );
                      })}
                    </div>
                  </section>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType12Page;
