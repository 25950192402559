import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Element } from "components/report/reportElement";
import FloatingBtn from "components/common/floatingBtn";
import moment from "moment";
import "assets/css/report.css";

const ReportDTypePage = (reportData) => {
  const reportInfo = reportData;
  const ref = useRef();

  const elBox = (data, size) => {
    switch (data) {
      case "양호":
        return <Element title={data} className={`${size} green`} />;
      case "관심":
        return <Element title={data} className={`${size} yellow`} />;
      case "주의":
        return <Element title={data} className={`${size} red`} />;
      default:
        return <></>;
    }
  };

  const elBox1 = (data, size) => {
    switch (data) {
      case "롱 슬리퍼":
        return <Element title={data} className={`${size} purple`} />;
      case "보통":
        return <Element title={data} className={`${size} yellow`} />;
      case "숏 슬리퍼":
        return <Element title={data} className={`${size} orange`} />;
      default:
        return <></>;
    }
  };

  const elBox2 = (data, size) => {
    switch (data) {
      case "저녁형":
        return <Element title={data} className={`${size} purple`} />;
      case "보통":
        return <Element title={data} className={`${size} yellow`} />;
      case "아침형":
        return <Element title={data} className={`${size} orange`} />;
      default:
        return <></>;
    }
  };

  const messageBox = (data) => {
    switch (data) {
      case "양호":
        return <span>양호 수준입니다.</span>;
      case "관심":
        return <span>관심 수준입니다.</span>;
      case "주의":
        return <span>주의 수준입니다.</span>;
      default:
        return <></>;
    }
  };

  const messageBox1 = (data) => {
    switch (data) {
      case "롱 슬리퍼":
        return <span>롱 슬리퍼입니다.</span>;
      case "보통":
        return <span>보통입니다.</span>;
      case "숏 슬리퍼":
        return <span>숏 슬리퍼입니다.</span>;
      default:
        return <></>;
    }
  };

  const messageBox3 = (data) => {
    switch (data) {
      case "롱 슬리퍼":
        return (
          <span>
            좋은 컨디션을 유지하기 위해 비교적 긴수면시간이 필요합니다.
            <br />
            올바른 수면습관을 위해 규칙적인 관리를 해야 합니다.
          </span>
        );
      case "보통":
        return <span>올바른 수면습관을 위해 규칙적인 관리를 해야 합니다.</span>;
      case "숏 슬리퍼":
        return (
          <span>
            좋은 컨디션을 유지하기 위해 짧은 수면 시간이 필요합니다.
            <br />
            올바른 수면습관을 위해 규칙적인 관리를 해야 합니다.
          </span>
        );
      default:
        return <></>;
    }
  };

  const messageBox2 = (data) => {
    switch (data) {
      case "저녁형":
        return <span>저녁형입니다.</span>;
      case "보통":
        return <span>보통입니다.</span>;
      case "아침형":
        return <span>아침형입니다.</span>;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="container">
        <div className="content-box4">
          <ReactToPrint
            trigger={() => (
              <button className="floating">
                <div>
                  <img src="/assets/images/common/print.png" alt="" />
                </div>
              </button>
            )}
            content={() => ref.current}
          />
          <div ref={ref}>
            <div className="report1">
              <img src="/assets/images/print/dxvxPrint_01.jpg" alt="" />
              <div className="report1-1">
                <span>{reportInfo.reportData?.hospital}</span>
              </div>
              <div className="report1-2">
                <span>{reportInfo.reportData?.hospital_doctor}</span>
              </div>
              <div className="report1-3">
                <span>{reportInfo.reportData?.name}</span>
              </div>
              <div className="report1-4">
                <span>
                  {reportInfo.reportData?.gender === 1 ? "남자" : "여자"}
                </span>
              </div>
              <div className="report1-5">
                <span>{reportInfo.reportData?.hospital_req_ID}</span>
              </div>
              <div className="report1-6">
                <span>{reportInfo.reportData?.hospital_req_number}</span>
              </div>
              <div className="report1-7">
                <span>
                  {moment(reportInfo.reportData?.receipt_date).format(
                    "YYYY/MM/DD"
                  )}
                </span>
              </div>
              <div className="report1-8">
                <span>
                  {moment(reportInfo.reportData?.report_date).format(
                    "YYYY/MM/DD"
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_02.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_03.jpg" alt="" />
            </div>

            <div className="report">
              <img src="/assets/images/print/dxvxPrint_04.jpg" alt="" />
            </div>

            <div className="report5">
              <img src="/assets/images/print/dxvxPrint_05.jpg" alt="" />
              <div className="report5-1">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminC?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-2">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminD?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-3">
                {elBox(
                  reportInfo.reportData?.dtc?.CoenzymeQ10?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-4">
                {elBox(
                  reportInfo.reportData?.dtc?.Magnesium?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-5">
                {elBox(
                  reportInfo.reportData?.dtc?.Zinc?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-6">
                {elBox(
                  reportInfo.reportData?.dtc?.Iron?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-7">
                {elBox(
                  reportInfo.reportData?.dtc?.Potassium?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-8">
                {elBox(
                  reportInfo.reportData?.dtc?.Calcium?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-9">
                {elBox(
                  reportInfo.reportData?.dtc?.Omega3?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-10">
                {elBox(
                  reportInfo.reportData?.dtc?.Omega6?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-11">
                {elBox(
                  reportInfo.reportData?.dtc?.Arginine?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-12">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminA?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-13">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminB6?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-14">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminB12?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-15">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminE?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-16">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminK?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-17">
                {elBox(
                  reportInfo.reportData?.dtc?.Tyrosine?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-18">
                {elBox(
                  reportInfo.reportData?.dtc?.Betaine?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-19">
                {elBox(
                  reportInfo.reportData?.dtc?.Selenium?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-20">
                {elBox(
                  reportInfo.reportData?.dtc?.Lutein?.analysis_result,
                  "small"
                )}
              </div>
            </div>

            <div className="report5">
              <img src="/assets/images/print/dxvxPrint_06.jpg" alt="" />
              <div className="report5-1">
                {elBox(
                  reportInfo.reportData?.dtc?.Degenerative?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-2">
                {elBox(
                  reportInfo.reportData?.dtc?.Fat?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-3">
                {elBox(
                  reportInfo.reportData?.dtc?.BodyFatPer?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-4">
                {elBox(
                  reportInfo.reportData?.dtc?.Bmi?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-5">
                {elBox(
                  reportInfo.reportData?.dtc?.Abdominal?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-6">
                {elBox(
                  reportInfo.reportData?.dtc?.RecoveryAfterLoseWeight
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-7">
                {elBox(
                  reportInfo.reportData?.dtc?.NFatConcentration
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-8">
                {elBox(
                  reportInfo.reportData?.dtc?.LDL?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-9">
                {elBox(
                  reportInfo.reportData?.dtc?.HDL?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-10">
                {elBox(
                  reportInfo.reportData?.dtc?.BloodSugar?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-11">
                {elBox(
                  reportInfo.reportData?.dtc?.BloodPressure?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-12">
                {elBox(
                  reportInfo.reportData?.dtc?.BoneMass?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-13">
                {elBox(
                  reportInfo.reportData?.dtc?.UricAcid?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-14">
                {elBox(
                  reportInfo.reportData?.dtc?.TravelSickness?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-15">
                {elBox(
                  reportInfo.reportData?.dtc?.EffectLosingWeight
                    ?.analysis_result,
                  "small"
                )}
              </div>
            </div>

            <div className="report5">
              <img src="/assets/images/print/dxvxPrint_07.jpg" alt="" />
              <div className="report5-1">
                {elBox(
                  reportInfo.reportData?.dtc?.AlcoholMetabolism
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-2">
                {elBox(
                  reportInfo.reportData?.dtc?.AlcoholDependence
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-3">
                {elBox(
                  reportInfo.reportData?.dtc?.AlcoholFlush?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-4">
                {elBox(
                  reportInfo.reportData?.dtc?.NicotineMetabolism
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-5">
                {elBox(
                  reportInfo.reportData?.dtc?.NicotineDependence
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-6">
                {elBox(
                  reportInfo.reportData?.dtc?.CaffeineMetabolism
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-7">
                {elBox(
                  reportInfo.reportData?.dtc?.CaffeineDependence
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-8">
                {elBox(
                  reportInfo.reportData?.dtc?.Insomnia?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-9">
                {elBox1(
                  reportInfo.reportData?.dtc?.SleepingHabits?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-10">
                {elBox2(
                  reportInfo.reportData?.dtc?.MorningPerson?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-101">
                {elBox(
                  reportInfo.reportData?.dtc?.PainSensitivity?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-11">
                {elBox(
                  reportInfo.reportData?.dtc?.Freckles?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-12">
                {elBox(
                  reportInfo.reportData?.dtc?.Pigmentation?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-13">
                {elBox(
                  reportInfo.reportData?.dtc?.Acne?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-14">
                {elBox(
                  reportInfo.reportData?.dtc?.SkinAging?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-15">
                {elBox(
                  reportInfo.reportData?.dtc?.SkinInflammation?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-16">
                {elBox(
                  reportInfo.reportData?.dtc?.DeadSkinCells?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-17">
                {elBox(
                  reportInfo.reportData?.dtc?.HairLoss?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-18">
                {elBox(
                  reportInfo.reportData?.dtc?.HairThickness?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-19">
                {elBox(
                  reportInfo.reportData?.dtc?.CircularHairLoss?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-20">
                {elBox(
                  reportInfo.reportData?.dtc?.TanningReaction?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-201">
                {elBox(
                  reportInfo.reportData?.dtc?.WhiteHair?.analysis_result,
                  "small"
                )}
              </div>
            </div>

            <div className="report5">
              <img src="/assets/images/print/dxvxPrint_08.jpg" alt="" />
              <div className="report5-1">
                {elBox(
                  reportInfo.reportData?.dtc?.MuscleFitness?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-2">
                {elBox(
                  reportInfo.reportData?.dtc?.ExerciseFitness?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-3">
                {elBox(
                  reportInfo.reportData?.dtc?.ShortDistanceRunning
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-4">
                {elBox(
                  reportInfo.reportData?.dtc?.RecoverAfterExercising
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-5">
                {elBox(
                  reportInfo.reportData?.dtc?.MuscleDevelopment
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-6">
                {elBox(
                  reportInfo.reportData?.dtc?.GrabbingPower?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-7">
                {elBox(
                  reportInfo.reportData?.dtc?.AerobicExercise?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-8">
                {elBox(
                  reportInfo.reportData?.dtc?.AnkleInjury?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-11">
                {elBox(
                  reportInfo.reportData?.dtc?.SensitivitySweet?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-12">
                {elBox(
                  reportInfo.reportData?.dtc?.SensitivityBitter
                    ?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-13">
                {elBox(
                  reportInfo.reportData?.dtc?.SensitivitySalty?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-14">
                {elBox(
                  reportInfo.reportData?.dtc?.Appetite?.analysis_result,
                  "small"
                )}
              </div>

              <div className="report5-15">
                {elBox(
                  reportInfo.reportData?.dtc?.FeelingFull?.analysis_result,
                  "small"
                )}
              </div>
            </div>

            <div className="report">
              <img src="/assets/images/print/dxvxPrint_09.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_10.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminC?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminC?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminC?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminC?.results[2].own}
                </span>
              </div>
              <div className="report-m report-m1">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminC?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_11.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminD?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminD?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminD?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminD?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminD?.results[3].own}
                </span>
              </div>
              <div className="report-m report-m1">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminD?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_12.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.CoenzymeQ10?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.CoenzymeQ10?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.CoenzymeQ10?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.CoenzymeQ10?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m2">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.CoenzymeQ10?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_13.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Magnesium?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Magnesium?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Magnesium?.results[1].own}
                </span>
              </div>
              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Magnesium?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_14.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Zinc?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>{reportInfo.reportData?.dtc?.Zinc?.results[0].own}</span>
              </div>
              <div className="report-m report-m4">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Zinc?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_15.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Iron?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>{reportInfo.reportData?.dtc?.Iron?.results[0].own}</span>
              </div>
              <div className="report10-2">
                <span>{reportInfo.reportData?.dtc?.Iron?.results[1].own}</span>
              </div>
              <div className="report10-3">
                <span>{reportInfo.reportData?.dtc?.Iron?.results[2].own}</span>
              </div>
              <div className="report-m report-m5">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Iron?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_16.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Potassium?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Potassium?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Potassium?.results[1].own}
                </span>
              </div>
              <div className="report-m report-m4">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Potassium?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_17.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Calcium?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Calcium?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Calcium?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Calcium?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.Calcium?.results[3].own}
                </span>
              </div>
              <div className="report-m report-m4">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Calcium?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_18.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Arginine?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Arginine?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Arginine?.results[1].own}
                </span>
              </div>
              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Arginine?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_19.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Omega3?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Omega3?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Omega3?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Omega3?.results[2].own}
                </span>
              </div>
              <div className="report-m report-m6">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Omega3?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_20.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Omega6?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Omega6?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Omega6?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Omega6?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m6">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Omega6?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_21.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminA?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminA?.results[0].own}
                </span>
              </div>
              <div className="report-m report-m1">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminA?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_22.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminB6?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminB6?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminB6?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminB6?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m5">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminB6?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_23.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminB12?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminB12?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminB12?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminB12?.results[2].own}
                </span>
              </div>
              <div className="report-m report-m7">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminB12?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_24.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminE?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminE?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminE?.results[1].own}
                </span>
              </div>
              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminE?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_25.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.VitaminK?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminK?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.VitaminK?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.VitaminK?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_26.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Tyrosine?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Tyrosine?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Tyrosine?.results[1].own}
                </span>
              </div>
              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Tyrosine?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_27.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Betaine?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Betaine?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Betaine?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Betaine?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_28.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Selenium?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Selenium?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Selenium?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Selenium?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_29.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Lutein?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Lutein?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Lutein?.results[1].own}
                </span>
              </div>
              <div className="report-m report-m9">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Lutein?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_30.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_31.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Degenerative?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Degenerative?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Degenerative?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Degenerative?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.Degenerative?.results[3].own}
                </span>
              </div>
              <div className="report10-5">
                <span>
                  {reportInfo.reportData?.dtc?.Degenerative?.results[4].own}
                </span>
              </div>

              <div className="report-m report-m9">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Degenerative?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_32.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Fat?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-11">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[0].own}</span>
              </div>
              <div className="report10-12">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[1].own}</span>
              </div>
              <div className="report10-13">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[2].own}</span>
              </div>
              <div className="report10-14">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[3].own}</span>
              </div>
              <div className="report10-15">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[4].own}</span>
              </div>
              <div className="report10-16">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[5].own}</span>
              </div>
              <div className="report10-17">
                <span>{reportInfo.reportData?.dtc?.Fat?.results[6].own}</span>
              </div>

              <div className="report-m report-m10">
                <span>
                  {messageBox(reportInfo.reportData?.dtc?.Fat?.analysis_result)}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_33.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.BodyFatPer?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.BodyFatPer?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.BodyFatPer?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.BodyFatPer?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.BodyFatPer?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_34.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Bmi?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>{reportInfo.reportData?.dtc?.Bmi?.results[0].own}</span>
              </div>
              <div className="report10-2">
                <span>{reportInfo.reportData?.dtc?.Bmi?.results[1].own}</span>
              </div>
              <div className="report10-3">
                <span>{reportInfo.reportData?.dtc?.Bmi?.results[2].own}</span>
              </div>
              <div className="report10-4">
                <span>{reportInfo.reportData?.dtc?.Bmi?.results[3].own}</span>
              </div>

              <div className="report-m report-m12">
                <span>
                  {messageBox(reportInfo.reportData?.dtc?.Bmi?.analysis_result)}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_35.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Abdominal?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Abdominal?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Abdominal?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Abdominal?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Abdominal?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_36.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.RecoveryAfterLoseWeight
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.RecoveryAfterLoseWeight
                      ?.results[0].own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.RecoveryAfterLoseWeight
                      ?.results[1].own
                  }
                </span>
              </div>

              <div className="report-m report-m13">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.RecoveryAfterLoseWeight
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_37.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.NFatConcentration
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NFatConcentration?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NFatConcentration?.results[1]
                      .own
                  }
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NFatConcentration?.results[2]
                      .own
                  }
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NFatConcentration?.results[3]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m1">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.NFatConcentration
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_38.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.LDL?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>{reportInfo.reportData?.dtc?.LDL?.results[0].own}</span>
              </div>
              <div className="report10-2">
                <span>{reportInfo.reportData?.dtc?.LDL?.results[1].own}</span>
              </div>
              <div className="report10-3">
                <span>{reportInfo.reportData?.dtc?.LDL?.results[2].own}</span>
              </div>
              <div className="report10-4">
                <span>{reportInfo.reportData?.dtc?.LDL?.results[3].own}</span>
              </div>

              <div className="report-m report-m14">
                <span>
                  {messageBox(reportInfo.reportData?.dtc?.LDL?.analysis_result)}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_39.jpg" alt="" />
              <div className="report10-large2">
                {elBox(
                  reportInfo.reportData?.dtc?.HDL?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-21">
                <span>{reportInfo.reportData?.dtc?.HDL?.results[0].own}</span>
              </div>
              <div className="report10-22">
                <span>{reportInfo.reportData?.dtc?.HDL?.results[1].own}</span>
              </div>
              <div className="report10-23">
                <span>{reportInfo.reportData?.dtc?.HDL?.results[2].own}</span>
              </div>
              <div className="report10-24">
                <span>{reportInfo.reportData?.dtc?.HDL?.results[3].own}</span>
              </div>
              <div className="report10-25">
                <span>{reportInfo.reportData?.dtc?.HDL?.results[4].own}</span>
              </div>
              <div className="report10-26">
                <span>{reportInfo.reportData?.dtc?.HDL?.results[5].own}</span>
              </div>

              <div className="report-m report-m14">
                <span>
                  {messageBox(reportInfo.reportData?.dtc?.HDL?.analysis_result)}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_40.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.BloodSugar?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.BloodSugar?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.BloodSugar?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.BloodSugar?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.BloodSugar?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m10">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.BloodSugar?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_41.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.BloodPressure?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.BloodPressure?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.BloodPressure?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.BloodPressure?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m10">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.BloodPressure?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_42.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.BoneMass?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.BoneMass?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.BoneMass?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.BoneMass?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m15">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.BoneMass?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_43.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.UricAcid?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.UricAcid?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.UricAcid?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.UricAcid?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.UricAcid?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m15">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.UricAcid?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_44.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.TravelSickness?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.TravelSickness?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.TravelSickness?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.TravelSickness?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.TravelSickness?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m10">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.TravelSickness?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_45.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.EffectLosingWeight
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.EffectLosingWeight?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.EffectLosingWeight?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m6">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.EffectLosingWeight
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_46.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_47.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.AlcoholMetabolism
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.AlcoholMetabolism?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.AlcoholMetabolism?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m12">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.AlcoholMetabolism
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_48.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.AlcoholDependence
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.AlcoholDependence?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.AlcoholDependence?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.AlcoholDependence
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_49.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.AlcoholFlush?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.AlcoholFlush?.results[0].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.AlcoholFlush?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_50.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.NicotineMetabolism
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NicotineMetabolism?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NicotineMetabolism?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m12">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.NicotineMetabolism
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_51.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.NicotineDependence
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NicotineDependence?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.NicotineDependence?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.NicotineDependence
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_52.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.CaffeineMetabolism
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.CaffeineMetabolism?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.CaffeineMetabolism?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m12">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.CaffeineMetabolism
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_53.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.CaffeineDependence
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.CaffeineDependence?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.CaffeineDependence?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m3">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.CaffeineDependence
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_54.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Insomnia?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Insomnia?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Insomnia?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Insomnia?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m16">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Insomnia?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_55.jpg" alt="" />
              <div className="report10-large3">
                {elBox1(
                  reportInfo.reportData?.dtc?.SleepingHabits?.analysis_result,
                  "x-large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.SleepingHabits?.results[0].own}
                </span>
              </div>
              <div className="report-m report-m20">
                {messageBox1(
                  reportInfo.reportData?.dtc?.SleepingHabits?.analysis_result
                )}
              </div>
              <div className="report-m report-m21">
                {messageBox3(
                  reportInfo.reportData?.dtc?.SleepingHabits?.analysis_result
                )}
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_56.jpg" alt="" />
              <div className="report10-large3">
                {elBox2(
                  reportInfo.reportData?.dtc?.MorningPerson?.analysis_result,
                  "x-large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.MorningPerson?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.MorningPerson?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.MorningPerson?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.MorningPerson?.results[3].own}
                </span>
              </div>
              <div className="report-m report-m22">
                {messageBox2(
                  reportInfo.reportData?.dtc?.MorningPerson?.analysis_result
                )}
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_57.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.PainSensitivity?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.PainSensitivity?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.PainSensitivity?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.PainSensitivity?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_58.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_59.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Freckles?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Freckles?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Freckles?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.Freckles?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.Freckles?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m1">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Freckles?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_60.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Pigmentation?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Pigmentation?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Pigmentation?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Pigmentation?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_61.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Acne?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>{reportInfo.reportData?.dtc?.Acne?.results[0].own}</span>
              </div>
              <div className="report10-2">
                <span>{reportInfo.reportData?.dtc?.Acne?.results[1].own}</span>
              </div>
              <div className="report10-3">
                <span>{reportInfo.reportData?.dtc?.Acne?.results[2].own}</span>
              </div>

              <div className="report-m report-m15">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Acne?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_62.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.SkinAging?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.SkinAging?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.SkinAging?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.SkinAging?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.SkinAging?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.SkinAging?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_63.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.SkinInflammation?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.SkinInflammation?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.SkinInflammation?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.SkinInflammation?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.SkinInflammation
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_64.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.DeadSkinCells?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.DeadSkinCells?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.DeadSkinCells?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.DeadSkinCells?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m12">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.DeadSkinCells?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_65.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.TanningReaction?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.TanningReaction?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.TanningReaction?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.TanningReaction?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m17">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.TanningReaction?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_66.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.WhiteHair?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.WhiteHair?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.WhiteHair?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.WhiteHair?.results[2].own}
                </span>
              </div>

              <div className="report-m report-m10">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.WhiteHair?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_67.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.HairLoss?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.HairLoss?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.HairLoss?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.HairLoss?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.HairLoss?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.HairLoss?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_68.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.HairThickness?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.HairThickness?.results[0].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.HairThickness?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_69.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.CircularHairLoss?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.CircularHairLoss?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.CircularHairLoss?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m11">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.CircularHairLoss
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_70.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_71.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.MuscleFitness?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.MuscleFitness?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.MuscleFitness?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m7">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.MuscleFitness?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_72.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.ExerciseFitness?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.ExerciseFitness?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.ExerciseFitness?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.ExerciseFitness?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.ExerciseFitness?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m18">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.ExerciseFitness?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_73.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.ShortDistanceRunning
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.ShortDistanceRunning?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.ShortDistanceRunning?.results[1]
                      .own
                  }
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {
                    reportInfo.reportData?.dtc?.ShortDistanceRunning?.results[2]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m14">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.ShortDistanceRunning
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_74.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.RecoverAfterExercising
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.RecoverAfterExercising
                      ?.results[0].own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.RecoverAfterExercising
                      ?.results[1].own
                  }
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {
                    reportInfo.reportData?.dtc?.RecoverAfterExercising
                      ?.results[2].own
                  }
                </span>
              </div>

              <div className="report-m report-m14">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.RecoverAfterExercising
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_75.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.MuscleDevelopment
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.MuscleDevelopment?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.MuscleDevelopment?.results[1]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m5">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.MuscleDevelopment
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_76.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.GrabbingPower?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.GrabbingPower?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.GrabbingPower?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m19">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.GrabbingPower?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_77.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.AerobicExercise?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.AerobicExercise?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.AerobicExercise?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m18">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.AerobicExercise?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_78.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.AnkleInjury?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.AnkleInjury?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.AnkleInjury?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m14">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.AnkleInjury?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_79.jpg" alt="" />
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_80.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.SensitivitySweet?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.SensitivitySweet?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.SensitivitySweet?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.SensitivitySweet
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_81.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.SensitivityBitter
                    ?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {
                    reportInfo.reportData?.dtc?.SensitivityBitter?.results[0]
                      .own
                  }
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {
                    reportInfo.reportData?.dtc?.SensitivityBitter?.results[1]
                      .own
                  }
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {
                    reportInfo.reportData?.dtc?.SensitivityBitter?.results[2]
                      .own
                  }
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {
                    reportInfo.reportData?.dtc?.SensitivityBitter?.results[3]
                      .own
                  }
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.SensitivityBitter
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_82.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.SensitivitySalty?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.SensitivitySalty?.results[0].own}
                </span>
              </div>

              <div className="report-m report-m8">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.SensitivitySalty
                      ?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_83.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.Appetite?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.Appetite?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.Appetite?.results[1].own}
                </span>
              </div>

              <div className="report-m report-m19">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.Appetite?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_84.jpg" alt="" />
              <div className="report10-large">
                {elBox(
                  reportInfo.reportData?.dtc?.FeelingFull?.analysis_result,
                  "large"
                )}
              </div>
              <div className="report10-1">
                <span>
                  {reportInfo.reportData?.dtc?.FeelingFull?.results[0].own}
                </span>
              </div>
              <div className="report10-2">
                <span>
                  {reportInfo.reportData?.dtc?.FeelingFull?.results[1].own}
                </span>
              </div>
              <div className="report10-3">
                <span>
                  {reportInfo.reportData?.dtc?.FeelingFull?.results[2].own}
                </span>
              </div>
              <div className="report10-4">
                <span>
                  {reportInfo.reportData?.dtc?.FeelingFull?.results[3].own}
                </span>
              </div>

              <div className="report-m report-m15">
                <span>
                  {messageBox(
                    reportInfo.reportData?.dtc?.FeelingFull?.analysis_result
                  )}
                </span>
              </div>
            </div>

            <div className="report84">
              <img src="/assets/images/print/dxvxPrint_85.jpg" alt="" />
              <div className="top report84-1">
                <span>{reportInfo.reportData?.hospital}</span>
              </div>
              <div className="top report84-2">
                <span>{reportInfo.reportData?.hospital_phone}</span>
              </div>
              <div className="top report84-3">
                <span>{reportInfo.reportData?.hospital_address}</span>
              </div>
              <div className="top report84-4">
                <span>{reportInfo.reportData?.report_code}</span>
              </div>
              <div className="top report84-5">
                <span>
                  {moment(reportInfo.reportData?.receipt_date).format(
                    "YYYY/MM/DD"
                  )}
                </span>
              </div>
              <div className="top report84-6">
                <span>
                  {moment(reportInfo.reportData?.report_date).format(
                    "YYYY/MM/DD"
                  )}
                </span>
              </div>
              <div className="top report84-7">
                <span>{reportInfo.reportData?.name}</span>
              </div>
              <div className="top report84-8">
                <span>
                  {reportInfo.reportData?.gender === 1 ? "남자" : "여자"}
                </span>
              </div>
              <div
                className="middle report84-11"
                style={
                  reportInfo.reportData?.standard_propriety === "PASS"
                    ? { color: "blue" }
                    : { color: "red" }
                }
              >
                <span>{reportInfo.reportData?.standard_propriety}</span>
              </div>
              <div
                className="middle report84-12"
                style={
                  reportInfo.reportData?.standard_propriety === "PASS"
                    ? { color: "blue" }
                    : { color: "red" }
                }
              >
                <span>{reportInfo.reportData?.standard_dna_quality}</span>
              </div>
              <div
                className="middle report84-13"
                style={
                  reportInfo.reportData?.standard_propriety === "PASS"
                    ? { color: "blue" }
                    : { color: "red" }
                }
              >
                <span>{reportInfo.reportData?.standard_array_quality}</span>
              </div>
              <div
                className="middle report84-14"
                style={
                  reportInfo.reportData?.standard_propriety === "PASS"
                    ? { color: "blue" }
                    : { color: "red" }
                }
              >
                <span>{reportInfo.reportData?.standard_material_result}</span>
              </div>
              <div
                className="middle report84-15"
                style={
                  reportInfo.reportData?.standard_propriety === "PASS"
                    ? { color: "blue" }
                    : { color: "red" }
                }
              >
                <span>
                  {reportInfo.reportData?.re_inspection === false
                    ? "PASS"
                    : "FAIL"}
                </span>
              </div>
              <div className="bottom report84-21">
                <span>최예진</span>
              </div>
              <div className="bottom report84-211">
                <img src="/assets/images/common/sign1.png" alt="" />
              </div>

              <div className="bottom report84-22">
                <span>이동하</span>
              </div>
              <div className="report84-221">
                <img src="/assets/images/common/sign2.png" alt="" />
              </div>

              <div className="bottom report84-23">
                <span>박상진</span>
              </div>
              <div className="bottom report84-231">
                <img src="/assets/images/common/sign3.png" alt="" />
              </div>
            </div>

            <div className="report10">
              <img src="/assets/images/print/dxvxPrint_86.jpg" alt="" />
            </div>

            <div className="report">
              <img src="/assets/images/print/dxvxPrint_87.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <FloatingBtn />
    </>
  );
};

export default ReportDTypePage;
