import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
// const { persistAtom } = recoilPersist({
//   key: "user-persist",
//    storage: sessionStorage,
//  });

const userInfoState = atom({
  key: "userInfoState",
  default: {
    aToken: null,
    isLogin: null,
    user_id: null,
    user_name: null,
    user_type: null,
    user_birth: null,
    user_phone: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export { userInfoState };
