import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const isUserState = atom({
  key: "isUserState",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

const isAdminState = atom({
  key: "isAdminState",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

const userListPageIndexState = atom({
  key: "userListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const userExitListPageIndexState = atom({
  key: "userExitListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const reportListPageIndexState = atom({
  key: "reportListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const dTypeReportListPageIndexState = atom({
  key: "dTypeReportListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const loginHistoryListPageIndexState = atom({
  key: "loginHistoryListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const reportHistoryListPageIndexState = atom({
  key: "reportHistoryListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const requestHistoryListPageIndexState = atom({
  key: "requestHistoryListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const inquiryListPageIndexState = atom({
  key: "inquiryListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const inquiryUListPageIndexState = atom({
  key: "inquiryUListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const orderListPageIndexState = atom({
  key: "orderListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const adminOrderListPageIndexState = atom({
  key: "adminOrderListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export {
  isUserState,
  isAdminState,
  userListPageIndexState,
  reportListPageIndexState,
  loginHistoryListPageIndexState,
  reportHistoryListPageIndexState,
  requestHistoryListPageIndexState,
  inquiryListPageIndexState,
  inquiryUListPageIndexState,
  orderListPageIndexState,
  adminOrderListPageIndexState,
  userExitListPageIndexState,
  dTypeReportListPageIndexState,
};
