import React, { useState, useEffect, useRef } from "react";
import InputUnit from "components/InputUnit";
import { useForm } from "react-hook-form";
import { CustomRegExp } from "helpers/customRegExp";
import { Link } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";
import { useNavigate } from "react-router-dom";

const FindIdPage = () => {
  const [findId, setFindId] = useState(false);
  const [findPw, setFindPw] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isUpdatePw, setIsUpdatePw] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const navigate = useNavigate();
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });
  const userpwd = useRef();
  userpwd.current = watch("user_pw");

  const checkfindIDHandler = () => {
    setFindId(true);
    setFindPw(false);
  };

  const checkfindPwHandler = () => {
    setFindId(false);
    setFindPw(true);
  };

  const checkAuthHandler = () => {
    if (verificationCode === watch("code")) {
      setIsUpdatePw(true);
    } else {
      alert("인증번호가 일치하지 않습니다.");
    }
  };

  const maskId = (id) => {
    const visibleChars = 2;
    const maskChar = "*";

    if (id.length <= visibleChars * 2) {
      return id
        .split("")
        .map((char, index) => (index % 2 !== 0 ? "*" : char))
        .join("");
    }

    const maskedPart = id
      .slice(visibleChars, id.length - visibleChars)
      .replace(/./g, maskChar);
    return (
      id.slice(0, visibleChars) +
      maskedPart +
      id.slice(id.length - visibleChars)
    );
  };

  const getUserId = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserId,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setUserInfo(res.user_info);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const sendAuthMessage = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.sendAuthMessage,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setVerificationCode(res.verificationCode);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const updateDirectPw = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.updateDirectPw,
      requestConfig: { user_id: watch("user_id"), user_pw: reqData.user_pw },
    })
      .then((res) => {
        alert(res.message);
        if (res.success) {
          navigate("/login");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="member-section">
      <div className="member-step step2">
        {userInfo !== null ? (
          <div className="member-area">
            <div className="title-area">
              <h2>아이디 찾기 결과</h2>
              <h3>{userInfo?.user_name}님의 정보와 일치하는 아이디 입니다.</h3>
              <h3>{maskId(userInfo?.user_id)}</h3>
            </div>
            <div className="button-area">
              <Link to="/login">
                <button
                  type="button"
                  className="button-primary size-l"
                  id="btn_dialog"
                >
                  <span>로그인하기</span>
                </button>
              </Link>
            </div>
          </div>
        ) : isUpdatePw === true ? (
          <div className="member-area">
            <div className="title-area">
              <h2>새로운 비밀번호로 변경해주세요.</h2>
              <p>기존에 쓰던 비밀번호는 사용할 수 없습니다.</p>
            </div>
            <form
              className="input-area"
              style={{ maxWidth: "max-content" }}
              onSubmit={handleSubmit(updateDirectPw)}
            >
              <InputUnit
                label="password"
                type="password"
                holder="Password"
                register={register("user_pw", {
                  required: "패스워드는 필수 입력 값 입니다.",
                  minLength: {
                    value: 8,
                    message: "패스워드는 최소 8글자 이상이어야 합니다.",
                  },
                })}
                errors={formErrors}
              />
              <InputUnit
                label="password_confirm"
                type="password"
                holder="Password 확인"
                register={register("user_pw_confirm", {
                  required: "패스워드는 필수 입력 값 입니다.",
                  validate: (value) => value === userpwd.current,
                })}
                errors={formErrors}
              />

              <div className="board-btn-area">
                <button className="btn" type="submit" disabled={isSubmitting}>
                  변경하기
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="member-area">
            <div className="title-area">
              <h2>아이디 / 비번찾기</h2>
            </div>
            <div className="button-area">
              <button
                type="button"
                className="button-primary size-l"
                id="btn_dialog"
                onClick={checkfindIDHandler}
              >
                <span>아이디 찾기</span>
              </button>
            </div>
            <div className="button-area">
              <button
                type="button"
                className="button-primary size-l"
                id="btn_dialog"
                onClick={checkfindPwHandler}
              >
                <span>비밀번호 찾기</span>
              </button>
            </div>

            {findId ? (
              <form
                className="input-area"
                style={{ maxWidth: "max-content" }}
                onSubmit={handleSubmit(getUserId)}
              >
                <InputUnit
                  label="user_name"
                  holder="이름을 입력하세요."
                  register={register("user_name", {
                    required: "이름은 필수 입력 값 입니다.",
                  })}
                  errors={formErrors}
                />
                <InputUnit
                  label="user_phone"
                  holder="전화번호를 입력하세요."
                  register={register("user_phone", {
                    required: "연락처는 필수 입력 값 입니다.",
                    pattern: CustomRegExp("tel"),
                  })}
                  errors={formErrors}
                />

                <div className="board-btn-area">
                  <button className="btn" type="submit" disabled={isSubmitting}>
                    찾기
                  </button>
                </div>
              </form>
            ) : null}

            {findPw ? (
              <form
                className="input-area"
                style={{ maxWidth: "max-content" }}
                onSubmit={handleSubmit(sendAuthMessage)}
              >
                <InputUnit
                  label="ID"
                  holder="ID"
                  register={register("user_id", {
                    required: "아이디는 필수 입력 값 입니다.",
                    minLength: {
                      value: 3,
                      message: "최소 3자 이상 입력 해 주세요",
                    },
                    maxLength: {
                      value: 22,
                      message: "최대 22자 이하로 입력 해 주세요",
                    },
                    pattern: {
                      value: /^[a-z0-9]+[a-z0-9]+$/i,
                      message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
                    },
                  })}
                  errors={formErrors}
                />
                <InputUnit
                  label="user_name"
                  holder="이름을 입력하세요."
                  register={register("user_name", {
                    required: "이름은 필수 입력 값 입니다.",
                  })}
                  errors={formErrors}
                />
                <InputUnit
                  label="user_phone"
                  holder="전화번호를 입력하세요."
                  register={register("user_phone", {
                    required: "연락처는 필수 입력 값 입니다.",
                    pattern: CustomRegExp("tel"),
                  })}
                  errors={formErrors}
                />
                <div
                  className="board-btn-area"
                  style={{ justifyContent: "left" }}
                >
                  <button
                    className="btn"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: "100px", fontSize: "14px", margin: "0" }}
                  >
                    인증번호보내기
                  </button>
                </div>
                {verificationCode ? (
                  <>
                    <InputUnit
                      label="code"
                      holder="인증코드를 입력해주세요."
                      register={register("code", {
                        required: "인증코드는 필수 입력 값 입니다.",
                        minLength: {
                          value: 6,
                          message: "인증코드는 6자리입니다.",
                        },
                        maxLength: {
                          value: 6,
                          message: "인증코드는 6자리입니다.",
                        },
                      })}
                      errors={formErrors}
                    />
                  </>
                ) : null}
                <div className="board-btn-area">
                  <button
                    className="btn"
                    type="button"
                    onClick={checkAuthHandler}
                    disabled={verificationCode === null ? true : false}
                    style={
                      verificationCode === null
                        ? { backgroundColor: "#e3e3e3", fontSize: "16px" }
                        : { fontSize: "16px" }
                    }
                  >
                    인증번호 확인
                  </button>
                </div>
              </form>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default FindIdPage;
