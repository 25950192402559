import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CSVLink } from "react-csv";
import { requestHistoryListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import "assets/css/board.css";
import moment from "moment";
import { AssignmentOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 15px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const RequestHistoryListPage = () => {
  const [transactionData, setTransactionData] = useState([]);
  const csvLink = useRef();
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [requestList, setRequestList] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [userListPage, setUserListPage] = useRecoilState(
    requestHistoryListPageIndexState
  );
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const headers = [
    { label: "아이디", key: "user_id" },
    { label: "이름", key: "user_name" },
    { label: "연락처", key: "user_phone" },
    { label: "우편번호", key: "user_post" },
    { label: "주소", key: "user_addr1" },
    { label: "상세주소", key: "user_addr2" },
    { label: "요청시간", key: "request_time" },
  ];

  const getRequestHistoryList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getRequestHistoryList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
      },
    })
      .then((res) => {
        if (res.success) {
          setUserResult(res.result);
          setRequestList(res.requestList);
          setPageList(res.pageList);
          setUserListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getRequestHistoryList : ", err);
      });
  };

  const exportExcel = (e) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.getAllRequestHistoryList,
    })
      .then((res) => {
        if (res.success) {
          res.requestList = res.requestList.map((row) => ({
            ...row,
            user_phone: '=""' + row.user_phone + '""',
            user_post: '=""' + row.user_post + '""',
            request_time: moment(row.request_time).format("YYYY-MM-DD HH:mm"),
          }));
          setTransactionData(res.requestList);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const goAgree = (e, item) => {
    e.preventDefault();
    navigate("/agreepage", {
      state: { req_id: item.request_id },
    });
  };

  useEffect(() => {
    getRequestHistoryList(userListPage);
  }, []);

  useEffect(() => {
    if (transactionData.length > 0) {
      csvLink.current.link.click();
    }
  }, [transactionData]);

  return (
    <div className="container-n-admin" style={{ margin: "0 300px" }}>
      <div className="content-area">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {userResult.totalrows}
            <span>건의 회수요청</span>
            <div style={{ marginLeft: "10px" }}>
              <CSVLink
                headers={headers}
                data={transactionData}
                filename="회수신청리스트.csv"
                style={{ display: "none" }}
                ref={csvLink}
              >
                다운로드링크
              </CSVLink>
              <div onClick={(e) => exportExcel(e)}>
                <IconWrapper>
                  <FileDownloadOutlined fontSize="large" />
                </IconWrapper>{" "}
              </div>
            </div>
          </div>
          <div className="board-search-area">
            <div className="check">
              <label>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    width: "16px",
                    height: "16px",
                  }}
                  checked={isDateRangeVisible}
                  onChange={handleCheckboxChange}
                ></input>
                기간설정
              </label>
            </div>

            {isDateRangeVisible && (
              <div className="date-range">
                <div className="date-picker">
                  <label>
                    시작 날짜:
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>
                  <label>
                    종료 날짜:
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            )}
            <div className="board-search">
              <input
                type="text"
                placeholder="검색어를 입력해 주세요"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    getRequestHistoryList(1);
                  }
                }}
              />
              <button
                type="button"
                onClick={(e) => {
                  getRequestHistoryList(1);
                }}
              >
                검색
              </button>
            </div>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>번호</th>
                <th>ID</th>
                <th>이름</th>
                <th>연락처</th>
                <th>주소</th>
                <th>신청일시</th>
                <th>동의서</th>
              </tr>
            </thead>
            <tbody>
              {requestList?.map((item) => (
                <tr
                  key={item.row_num}
                  style={{
                    height: "53px",
                  }}
                >
                  <td>
                    <div>{userResult.totalrows - item.row_num + 1}</div>
                  </td>
                  <td>
                    <div>{item.user_id}</div>
                  </td>
                  <td>
                    <div>{item.user_name}</div>
                  </td>
                  <td>
                    <div>{item.user_phone}</div>
                  </td>
                  <td>
                    <div>{`(${item.user_post}) ${item.user_addr1} ${item.user_addr2}`}</div>
                  </td>
                  <td>
                    <div>
                      {moment(item.request_time).format("YYYY/MM/DD HH:mm")}
                    </div>
                  </td>
                  <td>
                    <button
                      style={{ background: "transparent" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        goAgree(e, item);
                      }}
                    >
                      <IconWrapper>
                        <AssignmentOutlined fontSize="medium" />
                      </IconWrapper>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <ul className="paging">
            <li>
              <Link
                onClick={() =>
                  getRequestHistoryList(
                    userResult.currentPage - 10 < 1
                      ? 1
                      : userResult.currentPage - 10
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardFirst.png" alt="" />
              </Link>
            </li>
            {pageList?.map((item) => (
              <li
                key={item}
                className={item === userResult.currentPage ? "on" : null}
              >
                <Link
                  onClick={() => getRequestHistoryList(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </Link>
              </li>
            ))}

            <li>
              <Link
                onClick={() =>
                  getRequestHistoryList(
                    pageList[pageList.length - 1] + 1 < userResult.maxPage
                      ? pageList[pageList.length - 1] + 1
                      : pageList[pageList.length - 1]
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardLast.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RequestHistoryListPage;
