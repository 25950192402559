import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // 접근성 설정

const CustomModal = ({
  isOpen,
  onRequestClose,
  children,
  contentLabel,
  minWidth,
  minHeight,
  maxHeight,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
      minWidth: minWidth ? minWidth : "500px",
      minHeight: minHeight ? minHeight : "300px",
      maxHeight: maxHeight ? maxHeight : "500px",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel={contentLabel}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
