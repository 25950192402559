import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import { CustomRegExp } from "helpers/customRegExp";
import InputUnit from "components/InputUnit";
import Select from "react-select";
import SearchAddress from "components/common/searchAddress";
import "assets/css/board.css";

const UserReqComplete = (props) => {
  const [isAddress, setIsAddress] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });
  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "25px",
      height: "54px",
    }),
    control: (provided) => ({
      ...provided,
      margin: "10px 0",
      borderRadius: "0",
    }),
  };

  const searchAddr = () => {
    setIsAddress(!isAddress);
  };

  const changeIsUpdate = (e) => {
    e.preventDefault();
    setIsUpdate(!isUpdate);
  };

  const handleCompanyPostNumberAddr = (data) => {
    setValue("user_post", data.user_post);
    setValue("user_addr1", data.user_addr1);
  };

  const updateRequest = (reqData) => {
    reqData.user_gender = reqData.user_gender.value;

    axiosFetch({
      method: "post",
      url: apiUrls.updateRequest,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setIsUpdate(false);
          alert(res.message);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const getRequest = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserRequest,
      requestConfig: { complete_id: props.item.complete_id },
    })
      .then((res) => {
        if (res.success) {
          setValue("request_id", res.reqInfo?.request_id);
          setValue("user_post", res.reqInfo?.user_post);
          setValue("user_addr1", res.reqInfo?.user_addr1);
          setValue("user_addr2", res.reqInfo?.user_addr2);
          setValue("user_name", res.reqInfo?.user_name);
          setValue("user_phone", res.reqInfo?.user_phone);
          setValue("user_birth", res.reqInfo?.user_birth);
          setValue(
            "user_gender",
            Number(res.reqInfo?.user_gender) === 0
              ? { value: 0, label: "여자" }
              : { value: 1, label: "남자" }
          );
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getRequest();
  }, []);

  return (
    <>
      {isAddress === false ? (
        <>
          <div className="container">
            <div className="content-box4">
              <h2>
                {isUpdate
                  ? "회수신청내역 수정"
                  : "하기와 같은 내용으로 회수신청이 완료되었습니다."}
              </h2>
              <div className="login">
                <form
                  className="input-area"
                  onSubmit={handleSubmit(updateRequest)}
                >
                  <div className="board-btn-area">
                    <button
                      className="btn-post"
                      onClick={searchAddr}
                      style={{ display: isUpdate === false ? "none" : null }}
                    >
                      주소검색
                    </button>
                  </div>
                  <InputUnit
                    type="hidden"
                    label="ID"
                    holder="ID"
                    register={register("request_id", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Post"
                    holder="우편번호"
                    register={register("user_post", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Addr1"
                    holder="주소"
                    register={register("user_addr1", {
                      required: true,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={!isUpdate}
                    label="Addr2"
                    holder="상세주소"
                    register={register("user_addr2", {
                      required: "주소는 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={!isUpdate}
                    label="Name"
                    holder="Name"
                    register={register("user_name", {
                      required: "이름은 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={!isUpdate}
                    label="user_birth"
                    holder="생년월일 8자리 ex(1990년 01월 14일, 19900114)"
                    register={register("user_birth", {
                      pattern: CustomRegExp("birth"),
                    })}
                    errors={formErrors}
                  />
                  <Controller
                    name="user_gender"
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={!isUpdate}
                        {...field}
                        styles={customStyles}
                        className="select"
                        options={[
                          { value: 0, label: "여자" },
                          { value: 1, label: "남자" },
                        ]}
                      />
                    )}
                  />
                  <InputUnit
                    disabled={!isUpdate}
                    label="Phone"
                    holder="연락처"
                    register={register("user_phone", {
                      required: "연락처는 필수 입력 값 입니다.",
                      pattern: CustomRegExp("tel"),
                    })}
                    errors={formErrors}
                  />
                  {isUpdate === false ? (
                    <div className="board-btn-area">
                      <button
                        className="btn"
                        type="button"
                        onClick={(e) => {
                          props.setIsCompleteReq(false);
                        }}
                      >
                        돌아가기
                      </button>
                      {props.item.order_complete_status === 3 ? (
                        <button
                          className="btn2"
                          type="button"
                          onClick={(e) => {
                            changeIsUpdate(e);
                          }}
                        >
                          신청내용변경
                        </button>
                      ) : null}
                    </div>
                  ) : (
                    <div className="board-btn-area">
                      <button
                        className="btn"
                        type="button"
                        onClick={(e) => {
                          props.setIsCompleteReq(false);
                        }}
                      >
                        돌아가기
                      </button>
                      <button
                        className="btn2"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        변경내용저장
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SearchAddress
          searchAddr={searchAddr}
          setAddr={handleCompanyPostNumberAddr}
        />
      )}
    </>
  );
};

export default UserReqComplete;
