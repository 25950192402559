import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { CustomRegExp } from "helpers/customRegExp";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import SearchAddress from "components/common/searchAddress";
import "assets/css/login.css";

const SignUp = (userData) => {
  const [isAddress, setIsAddress] = useState(false);
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const userpwd = useRef();
  userpwd.current = watch("user_pw");

  const searchAddr = () => {
    setIsAddress(!isAddress);
  };

  const handleCompanyPostNumberAddr = (data) => {
    setValue("user_post", data.user_post);
    setValue("user_addr1", data.user_addr1);
  };

  const signup = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.signup,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          navigate("/");
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!userData.userData.success) navigate("/");
    if (userInfo.userInfo != null) {
      navigate("/");
    }

    setValue("user_name", userData?.userData?.name);
    setValue("user_birth", userData?.userData?.birthdate);
    setValue("user_gender", userData?.userData?.gender);
    setValue("dupinfo", userData?.userData?.dupinfo);
    setValue("user_national_info", userData?.userData?.nationalinfo);
  });

  return (
    <>
      {isAddress === false ? (
        <>
          <div className="container">
            <div className="content-box5">
              <img src="/assets/images/common/genomecheck.png" alt="" />
              <div className="main-title">
                <span>회원가입</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-box4">
              <div className="login">
                <form className="input-area" onSubmit={handleSubmit(signup)}>
                  <InputUnit
                    label="ID"
                    holder="ID"
                    register={register("user_id", {
                      required: "아이디는 필수 입력 값 입니다.",
                      minLength: {
                        value: 3,
                        message: "최소 3자 이상 입력 해 주세요",
                      },
                      maxLength: {
                        value: 22,
                        message: "최대 22자 이하로 입력 해 주세요",
                      },
                      pattern: {
                        value: /^[a-z0-9]+[a-z0-9]+$/i,
                        message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
                      },
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="password"
                    type="password"
                    holder="Password"
                    register={register("user_pw", {
                      required: "패스워드는 필수 입력 값 입니다.",
                      minLength: {
                        value: 8,
                        message: "패스워드는 최소 8글자 이상이어야 합니다.",
                      },
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="password_confirm"
                    type="password"
                    holder="Password 확인"
                    register={register("user_pw_confirm", {
                      required: "패스워드는 필수 입력 값 입니다.",
                      validate: (value) => value === userpwd.current,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="phone"
                    type="phone"
                    holder="Phone"
                    register={register("user_phone", {
                      required: "연락처는 필수 입력 값 입니다.",
                      pattern: CustomRegExp("tel"),
                    })}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button className="btn-post" onClick={searchAddr}>
                      주소검색
                    </button>
                  </div>
                  <InputUnit
                    disabled={true}
                    label="Post"
                    holder="우편번호"
                    register={register("user_post", {
                      required: "주소는 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Addr1"
                    holder="주소"
                    register={register("user_addr1", {
                      required: "주소는 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="Addr2"
                    holder="상세주소"
                    register={register("user_addr2", {
                      required: "주소는 필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Name"
                    holder="Name"
                    register={register("user_name", {
                      value: userData?.userData?.name,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    disabled={true}
                    label="Birth"
                    holder="생년월일 8자리 ex(1990년 01월 14일, 19900114)"
                    register={register("user_birth", {
                      value: userData?.userData?.birthdate,
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    type="hidden"
                    label="gender"
                    holder="gender"
                    register={register("user_gender", {})}
                    errors={formErrors}
                  />
                  <InputUnit
                    type="hidden"
                    label="dupinfo"
                    holder="dupinfo"
                    register={register("dupinfo", {})}
                    errors={formErrors}
                  />
                  <InputUnit
                    type="hidden"
                    label="nationalinfo"
                    holder="nationalinfo"
                    register={register("user_national_info", {})}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button
                      className="btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      회원가입
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SearchAddress
          searchAddr={searchAddr}
          setAddr={handleCompanyPostNumberAddr}
        />
      )}
    </>
  );
};

export default SignUp;
