import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { dTypeReportListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import "assets/css/board.css";
import moment from "moment";
import { ArticleOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";

const IconWrapper = styled("div")({
  display: "inline-block",
  padding: "5px 15px",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#f50057", // 마우스 오버 시 색상
  },
  "&:active": {
    transform: "scale(1.2)",
    color: "#00AEEA", // 클릭 시 색상
  },
});

const DTypeReportListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportList, setReportList] = useState([]);
  const [reportResult, setReportResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const userInfo = useRecoilValue(userInfoState);
  const [reportListPage, setReportListPage] = useRecoilState(
    dTypeReportListPageIndexState
  );
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const getAllReportList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getAllReportList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
      },
    })
      .then((res) => {
        if (res.success) {
          setReportResult(res.result);
          setReportList(res.reportList);
          setPageList(res.pageList);
          setReportListPage(reqData);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] getAllReportList : ", err);
      });
  };

  const goReport = (e, item) => {
    e.preventDefault();
    addOrderWorkHistoryWithKitnumber(item.kit_number, "리포트 열람");
    navigate("/reportpage", {
      state: { reportInfo: item },
    });
  };

  const addOrderWorkHistoryWithKitnumber = (kit_number, order_work_content) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.addOrderWorkHistoryWithKitnumber,
      requestConfig: {
        kit_number: kit_number,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
        order_work_content: order_work_content,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    getAllReportList(reportListPage);
  }, []);

  return (
    <>
      <div className="container-n-admin">
        <div className="content-area">
          <div className="board-info">
            <div className="board-total">
              <span>총 </span>
              {reportResult.totalrows}
              <span>건의 리포트</span>
            </div>
            <div className="board-search-area">
              <div className="check">
                <label>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "5px",
                      width: "16px",
                      height: "16px",
                    }}
                    checked={isDateRangeVisible}
                    onChange={handleCheckboxChange}
                  ></input>
                  기간설정
                </label>
              </div>

              {isDateRangeVisible && (
                <div className="date-range">
                  <div className="date-picker">
                    <label>
                      시작 날짜:
                      <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </label>
                    <label>
                      종료 날짜:
                      <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              )}
              <div className="board-search">
                <input
                  type="text"
                  placeholder="검색어를 입력해 주세요"
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      getAllReportList(1);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={(e) => {
                    getAllReportList(1);
                  }}
                >
                  검색
                </button>
              </div>
            </div>
          </div>

          <div>
            <table className="boardTable">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>키트번호</th>
                  <th>확인코드</th>
                  <th>Type</th>
                  <th>이름</th>
                  <th>성별</th>
                  <th>검체수령일</th>
                  <th>결과보고일</th>
                  <th>리포트확인</th>
                </tr>
              </thead>
              <tbody>
                {reportList?.map((item, index) => (
                  <tr key={item.rownum}>
                    <td>
                      <div>{reportResult.totalrows - item.rownum}</div>
                    </td>
                    <td>
                      <div>{item.kit_number}</div>
                    </td>
                    <td>
                      <div>{item.confirm_code}</div>
                    </td>
                    <td>
                      <div>{item.customer_type}</div>
                    </td>
                    <td>
                      <div>{item.name}</div>
                    </td>
                    <td>
                      <div>{item.gender === 1 ? "남" : "여"}</div>
                    </td>
                    <td>
                      <div>
                        {moment(item.receipt_date).format("YYYY/MM/DD")}
                      </div>
                    </td>
                    <td>
                      <div>{moment(item.report_date).format("YYYY/MM/DD")}</div>
                    </td>
                    <td>
                      <div>
                        <button
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            goReport(e, item);
                          }}
                        >
                          <IconWrapper>
                            <ArticleOutlined fontSize="medium" />
                          </IconWrapper>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <ul className="paging">
              <li>
                <Link
                  onClick={() =>
                    getAllReportList(
                      reportResult.currentPage - 10 < 1
                        ? 1
                        : reportResult.currentPage - 10
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img src="/assets/images/common/boardFirst.png" alt="" />
                </Link>
              </li>
              {pageList?.map((item) => (
                <li
                  key={item}
                  className={item === reportResult.currentPage ? "on" : null}
                >
                  <Link
                    onClick={() => getAllReportList(item)}
                    style={{ cursor: "pointer" }}
                  >
                    {item}
                  </Link>
                </li>
              ))}

              <li>
                <Link
                  onClick={() =>
                    getAllReportList(
                      pageList[pageList.length - 1] + 1 < reportResult.maxPage
                        ? pageList[pageList.length - 1] + 1
                        : pageList[pageList.length - 1]
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img src="/assets/images/common/boardLast.png" alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DTypeReportListPage;
