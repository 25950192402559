import React, { useState, useEffect } from "react";
import InputUnit from "components/InputUnit";
import { useForm } from "react-hook-form";
import useAxiosAuthFunction from "hooks/useAxiosAuth";

const AdminFaqPage = () => {
  const [faqs, setFaqs] = useState([]);
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const FaqItem = ({
    faq_id,
    question,
    answer,
    isOpen,
    delFaqList,
    updateFaqList,
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editQuestion, setEditQuestion] = useState(question);
    const [editAnswer, setEditAnswer] = useState(answer);

    return (
      <div className="faq-item">
        <div className="faq-title">
          <img
            className={`arrow-icon ${isOpen ? "open" : "closed"}`}
            src="assets/images/sub/right_arrow_m.png"
            alt="Arrow"
          />
          Q. {question}
          {isEditing ? (
            <button
              className="edit-btn"
              onClick={(e) =>
                updateFaqList(e, faq_id, editQuestion, editAnswer)
              }
            >
              저장
            </button>
          ) : (
            <div className="edit-btn" onClick={() => setIsEditing(true)}>
              수정
            </div>
          )}
          {isEditing ? (
            <button className="rm-btn" onClick={() => setIsEditing(false)}>
              취소
            </button>
          ) : (
            <div className="rm-btn" onClick={(e) => delFaqList(e, faq_id)}>
              삭제
            </div>
          )}
        </div>
        <div className={`faq-content ${isOpen ? "open" : ""}`}>
          {isEditing ? (
            <div className="faq-area">
              <div className="input-unit">
                <input
                  style={{ width: "100%", padding: "10px 10px" }}
                  type="text"
                  placeholder="질문을 입력해 주세요."
                  value={editQuestion}
                  onChange={(e) => {
                    setEditQuestion(e.target.value);
                  }}
                />
              </div>
              <div className="input-unit">
                <textarea
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                  }}
                  type="text"
                  placeholder="답변을 입력해 주세요."
                  value={editAnswer}
                  onChange={(e) => {
                    setEditAnswer(e.target.value);
                  }}
                />
              </div>
            </div>
          ) : (
            answer.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {index === 0 ? "A." : null} {line}
                {index !== answer.split("\n").length - 1 && <br />}
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    );
  };

  const addFaqList = (reqData) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.addFaqList,
      requestConfig: reqData,
    })
      .then((res) => {
        alert(res.message);
        getFaqList();
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });

    setValue("question", "");
    setValue("answer", "");
  };

  const delFaqList = (e, faq_id) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.delFaqList,
      requestConfig: { faq_id: faq_id },
    })
      .then((res) => {
        alert(res.message);
        getFaqList();
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  const updateFaqList = (e, faq_id, editQuestion, editAnswer) => {
    e.preventDefault();

    if (editQuestion.trim() === "" || editAnswer.trim() === "") {
      alert("질문과 답변을 입력해주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.updateFaqList,
      requestConfig: {
        faq_id: faq_id,
        editQuestion: editQuestion,
        editAnswer: editAnswer,
      },
    })
      .then((res) => {
        alert(res.message);
        getFaqList();
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  const getFaqList = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getFaqList,
      requestConfig: null,
    })
      .then((res) => {
        setFaqs(res);
      })
      .catch((err) => {
        // console.log("[ERROR] getFaqList : ", err);
      });
  };

  useEffect(() => {
    getFaqList();
  }, []);

  return (
    <div id="container" className="container">
      <div className="container">
        <div className="faq">
          <section>
            <div className="faq-add">
              <h3>질문과 답변 추가</h3>
              <form className="faq-area" onSubmit={handleSubmit(addFaqList)}>
                <InputUnit
                  label="question"
                  holder="질문을 입력해 주세요."
                  register={register("question", {
                    required: "질문은 필수 입력 값 입니다.",
                  })}
                  errors={formErrors}
                />
                <InputUnit
                  label="answer"
                  holder="답변을 입력해 주세요."
                  register={register("answer", {
                    required: "답변은 필수 입력 값 입니다.",
                  })}
                  errors={formErrors}
                />
                <div className="board-btn-area">
                  <button className="btn" type="submit" disabled={isSubmitting}>
                    추가
                  </button>
                </div>
              </form>
            </div>
            <div className="faq-container">
              {faqs.map((faq) => (
                <FaqItem
                  faq_id={faq.faq_id}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={true}
                  delFaqList={delFaqList}
                  updateFaqList={updateFaqList}
                />
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AdminFaqPage;




