export const menuData = [
  {
    menuKey: "product",
    name: "상품설명",
    checked: false,
    link: "/product",
    data: [],
  },
  {
    menuKey: "customer",
    name: "고객센터",
    checked: false,
    link: "/customer",
    data: [
      { subName: "고객센터", link: "/customer" },
      { subName: "자주묻는질문", link: "/faq" },
      { subName: "1:1문의", link: "/inquiry" },
    ],
  },
  {
    menuKey: "mypage",
    name: "결과확인",
    checked: false,
    link: "/mypage",
    data: [],
  },
  {
    menuKey: "market",
    name: "구매하기",
    checked: false,
    link: "/market",
    data: [],
  },
  {
    menuKey: "return",
    name: "회수신청",
    checked: false,
    link: "/myinfo",
    state: { index: 2 },
    data: [],
  },
];
