import { useEffect, useCallback, useRef } from "react";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";

const useLogoutTimer = (logoutDelay = 30 * 60 * 1000) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const logoutTimerRef = useRef(null);

  const handleUserActivity = useCallback(() => {
    resetLogoutTimer();
  }, []);

  const resetLogoutTimer = useCallback(() => {
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    logoutTimerRef.current = setTimeout(logout, logoutDelay);
  }, [logoutDelay]);

  const logout = useCallback(() => {
    setUserInfo(null);
    sessionStorage.removeItem("rToken");
    alert("일정 시간동안 사용하지 않아 Logout되었습니다.");
    window.location.href = "/login";
  }, [setUserInfo]);

  useEffect(() => {
    if (userInfo?.isLogin) {
      const events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
      ];
      events.forEach((event) =>
        window.addEventListener(event, handleUserActivity)
      );

      resetLogoutTimer();

      return () => {
        events.forEach((event) =>
          window.removeEventListener(event, handleUserActivity)
        );

        clearTimeout(logoutTimerRef.current);
      };
    }
  }, [userInfo?.isLogin, handleUserActivity, resetLogoutTimer]);

  return { resetLogoutTimer, logout };
};

export default useLogoutTimer;
