import React from "react";
import "assets/css/report.css";

const ReportDAType14Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page1">
        <h2 className="grui-title border">소비자 만족도 조사</h2>
        <section style={{ margin: "0 140px" }}>
          <ul className="grui-icon-list">
            <li>
              <div className="grui-il-item">
                <div className="grui-il-text">
                  <h3>
                    저희 유전자 검사를 이용해 주셔서 진심으로 감사드립니다.
                    <br /> 검사 결과가 만족스러우셨나요? 고객님의 소중한 의견을
                    듣고 서비스 품질을 더욱 향상시키고자 고객 만족도 조사를
                    실시하고 있습니다.
                  </h3>
                </div>
              </div>
            </li>
            <li>
              <div className="grui-il-item">
                <div className="grui-il-text">
                  <h3>
                    아래 링크를 클릭 또는 QR 코드를 통해 하여 설문조사에 참여해
                    주시면, 고객님의 경험과 만족도를 반영하여 더 나은 서비스를
                    제공하는 데 큰 도움이 될 것입니다. 설문조사는 약 5분 정도
                    소요됩니다.
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </section>
        <section>
          <div className="qr-link">
            <a href="https://vvd.bz/emYF" target="_blank">
              만족도 조사하러 가기
            </a>
            <img src="/assets/images/page1/QR.png" alt="QR코드" />
          </div>
        </section>

        <div className="grui-logo">
          <img
            src="/assets/images/page1/logo.png"
            alt="Dx&Vx Diagnostics and Vaccine Science"
          />
        </div>
      </div>
    </div>
  );
};

export default ReportDAType14Page;
