import React from "react";
import "assets/css/report.css";

const ReportDAType13Page = (props) => {
  const referenceInfo = props.referenceInfo;
  const dtcName = props.dtcName;
  const forwardedRef = props.forwardedRef;

  return (
    <div className="grui-report">
      <div className="grui-page page10">
        <h2 className="grui-title border" style={{ marginBottom: "50px" }}>
          참고문헌
        </h2>
        <div className="grui-results-sheet">
          <div className="grui-results-table">
            <div className="grui-rtable-head">
              <div className="rtable-col09">
                <span>항목명</span>
              </div>
              <div className="rtable-col10">
                <span>참고문헌</span>
              </div>
            </div>
            <div className="grui-rtable-body">
              {referenceInfo?.map((dtc, index) => {
                return (
                  <section ref={forwardedRef[index]}>
                    <div className="rtable-col09">
                      <h4>{dtcName[index]}</h4>
                    </div>
                    <div>
                      {dtc.map((item) => {
                        return (
                          <article>
                            <div className="rtable-col10">
                              <p>{item}</p>
                            </div>
                          </article>
                        );
                      })}
                    </div>
                  </section>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType13Page;
