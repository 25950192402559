const contentDAType = {
  Nutrition: {
    title: "영양소",
    content: [
      "비타민 C 농도",
      "비타민 D 농도",
      "비타민 A 농도",
      "비타민 B6 농도",
      "비타민 B12 농도",
      "비타민 E 농도",
      "비타민 K 농도",
      "오메가-3 지방산 농도",
      "오메가-6 지방산 농도",
      "아르기닌 농도",
      "칼슘 농도",
      "마그네슘 농도",
      "아연 농도",
      "철 저장 농도",
      "타이로신 농도",
      "베타인 농도",
      "셀레늄 농도",
      "루테인&지아잔틴 농도",
    ],
    icon: "gicon-fill",
  },
  Health: {
    title: "건강관리",
    content: [
      "비만",
      "체지방율",
      "체질량지수",
      "복부비만(엉덩이허리비율)",
      "중성지방 농도",
      "LDL 콜레스테롤 농도",
      "HDL 콜레스테롤 농도",
      "운동에 의한 체중감량효과",
      "혈당",
      "혈압",
      "골질량",
      "퇴행성관절염증감수성",
      "요산치",
      "멀미",
    ],
    icon: "gicon-health-care",
  },
  Personal: {
    title: "개인특성",
    content: [
      "알코올대사",
      "알코올의존성",
      "알코올홍조",
      "니코틴대사",
      "니코틴의존성",
      "카페인대사",
      "카페인의존성",
      "불면증",
      "아침형, 저녁형인간",
      // "통증 민감성",
    ],
    icon: "gicon-person",
  },
  Skin: {
    title: "피부/모발",
    content: [
      "기미/주근깨",
      "색소침착",
      "여드름발생",
      "피부노화",
      "피부염증",
      "태양노출후 태닝반응",
      "새치",
      "남성형탈모",
      "원형탈모",
      "모발굵기",
    ],
    icon: "gicon-water",
  },
  Athletic: {
    title: "운동능력",
    content: [
      "근력운동적합성",
      "지구력운동적합성",
      "유산소운동적합성",
      "단거리질주능력",
      "운동후회복능력",
      "근육발달능력",
      "발목부상위험도",
    ],
    icon: "gicon-athletic",
  },
  Eating: {
    title: "식습관",
    content: ["단맛민감도", "쓴맛민감도", "짠맛민감도", "포만감"],
    icon: "gicon-eating",
  },
};

const contentDBType = {
  Nutrition: {
    title: "영양소",
    content: [
      "비타민 C 농도",
      "비타민 D 농도",
      "비타민 A 농도",
      "비타민 B6 농도",
      "비타민 B12 농도",
      "비타민 E 농도",
      "비타민 K 농도",
      "오메가-3 지방산 농도",
      "오메가-6 지방산 농도",
      "아르기닌 농도",
      "칼슘 농도",
      "마그네슘 농도",
      "아연 농도",
      "철 저장 농도",
      "타이로신 농도",
      "베타인 농도",
      "셀레늄 농도",
      "루테인&지아잔틴 농도",
    ],
    icon: "gicon-fill",
  },
  Health: {
    title: "건강관리",
    content: [
      "비만",
      "체지방율",
      "체질량지수",
      "복부비만(엉덩이허리비율)",
      "중성지방 농도",
      "LDL 콜레스테롤 농도",
      "HDL 콜레스테롤 농도",
      "운동에 의한 체중감량효과",
      "혈당",
      "혈압",
      "골질량",
      "퇴행성관절염증감수성",
      "요산치",
      "멀미",
    ],
    icon: "gicon-health-care",
  },
  Personal: {
    title: "개인특성",
    content: [
      "알코올의존성",
      "알코올홍조",
      "니코틴대사",
      "니코틴의존성",
      "카페인대사",
      "카페인의존성",
      "불면증",
      "아침형, 저녁형인간",
    ],
    icon: "gicon-person",
  },
  Skin: {
    title: "피부/모발",
    content: [
      "기미/주근깨",
      "색소침착",
      "여드름발생",
      "피부노화",
      "피부염증",
      "태양노출후 태닝반응",
      "새치",
      "원형탈모",
    ],
    icon: "gicon-water",
  },
  Athletic: {
    title: "운동능력",
    content: [
      "근력운동적합성",
      "지구력운동적합성",
      "유산소운동적합성",
      "단거리질주능력",
      "운동후회복능력",
      "근육발달능력",
    ],
    icon: "gicon-athletic",
  },
  Eating: {
    title: "식습관",
    content: ["단맛민감도", "쓴맛민감도", "짠맛민감도", "포만감"],
    icon: "gicon-eating",
  },
};

const dtcDescription = {
  VitaminC: {
    name: "비타민 C 농도",
    define:
      "비타민 C는 대표적인 수용성 비타민의 하나로 아스코로브산(Ascorbic acid)이라고도 합니다. 콜라겐 합성이나 에너지 대사에 관여하며, 화학 구조 상 산의 성질을 가지고 있어 항산화 작용을 하는 강력한 항산화제로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 C 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "비타민 C는 감귤류, 딸기류, 키위, 파파야, 브로콜리, 피망, 케일 등 다양한 음식에서 발견됩니다. 이러한 음식을 포함하는 다양한 식단을 섭취하면 일일 비타민 C 요구량을 충족하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "식단을 통해 충분히 섭취하는 데 어려움이 있는 경우 비타민 C 섭취를 늘리는 효과적인 방법이 될 수 있습니다. 그러나 소화 문제 및 기타 부작용을 일으킬 수 있는 고용량의 비타민 C 보충제를 복용하지 않는 것이 가장 좋습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "SLC23A1 유전자는 비타민 C를 세포로 능동적으로 수송하는 역할을 하는 단백질을 암호화합니다. 연구에 따르면 SLC23A1 유전자의 변이는 신체의 비타민 C 흡수 및 분포에 영향을 미쳐 개인마다 비타민 C 수준의 차이를 유발할 수 있습니다.",
    ],
    referenceInfo: [
      "Duell, Eric J et al. Genes & nutrition vol. 8,6 (2013): 549-60.",
      "Timpson, Nicholas J et al. The American journal of clinical nutrition vol. 92,2 (2010): 375-82.",
    ],
    raceInfo: [
      {
        gene: "SLC23A1",
        rsId: "rs33972313",
        number: "15,087명",
        race: "유럽인",
      },
    ],
  },
  VitaminD: {
    name: "비타민 D 농도",
    define:
      "비타민 D는 지용성 비타민 중 하나로, 체내 칼슘 농도의 항상성과 뼈 건강 유지, 면역 기능 등에 관여합니다고 알려져 있습니다. 식품을 통해 섭취되지만 피부 세포에서 햇빛 속 자외선을 받아 체내에서 합성되기 때문에 일상적인 야외 활동에서 합성할 수 있습니다. 과잉 섭취 시 구토, 설사, 고칼슘혈증 등이 나타날 수 있습니다.",
    reference: "8",
    heredity: "10",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 5종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 D 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "기름진 생선, 달걀 노른자, 버섯과 같이 비타민 D가 풍부한 식품을 섭취하면 비타민 D 수치를 높이는 데 도움이 될 수 있습니다. 이러한 음식은 신체에 쉽게 흡수되는 활성 형태의 비타민이 많기 때문에 비타민 D의 좋은 공급원입니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "햇볕 쬐기",
        content:
          "비타민 D를 얻는 가장 자연스러운 방법은 햇빛을 통해서 얻는 것 입니다. 태양의 자외선 B(UVB) 광선은 피부에서 발견되는 콜레스테롤 형태를 비타민 D로 변환합니다. 과도한 태양 노출은 피부 손상을 유발할 수 있으므로 특히 피크 시간대 (오전 10시 ~ 오후 2시)에는 태양 노출에 주의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 D 보충제는 비타민 D 섭취량이 적거나 햇빛 노출이 제한된 사람들에게 특히 유용할 수 있습니다. 비타민 D 보충제는 D2 및 D3를 포함하여 여러 형태로 제공됩니다. 비타민 D3는 신체가 자연적으로 생성하는 비타민 D의 형태와 가장 유사한 형태입니다. 흡수율을 높이기 위해 지방이 포함된 식사와 함께 비타민 D 보충제를 섭취하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "GC 유전자는 비타민 D 결합 단백질을 암호화하며, 이는 혈액에서 비타민 D와 그 대사 물질을 운반하는 역할을 합니다. GC 유전자의 변이는 해당 단백질에 영향을 미칠 수 있으므로 신체의 비타민 D 가용성에 영향을 미칩니다.",
      "CYP24A1 유전자는 비타민 D의 활성 형태(1,25-디하이드록시비타민 D)를 비활성 형태로 분해하는 역할을 하는 효소를 암호화합니다. 이 유전자의 변이는 비타민 D 분해 속도에 영향을 미치므로 체내에서 이용 가능한 활성 비타민 D의 양에 영향을 미칠 수 있습니다.",
      "NADSYN1 유전자는 비타민 D 대사에 필요한 코엔자임 니코틴아미드 아데닌 디뉴클레오티드(NAD+)의 합성에 관여하는 효소를 암호화합니다. 이 유전자의 변이는 NAD+ 생성에 영향을 줄 수 있으므로 비타민 D 대사에 영향을 미칩니다.",
      "CYP2R1 유전자는 비타민 D를 활성 형태로 전환하는 역할을 하는 비타민 D 25-하이드록실라제라는 효소를 암호화합니다. 이 유전자의 변이는 비타민 D 활성화 속도에 영향을 미칠 수 있으므로 신체에서 이용 가능한 활성 비타민 D의 양에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Hong, Jaeyoung et al. The Journal of clinical endocrinology and metabolism vol. 103,4 (2018): 1380-1392.",
      "Lafi, Zainab M et al. Genetic testing and molecular biomarkers vol. 19,11 (2015): 629-36.",
      "Revez, Joana A et al. Nature communications vol. 11,1 1647. 2 Apr. 2020,",
      "Sinnott-Armstrong, Nasa et al. Nature genetics vol. 53,2 (2021): 185-194.",
      "Ahn, Jiyoung et al. Human molecular genetics vol. 19,13 (2010): 2739-45.",
      "Jiang, Xia et al. Nature communications vol. 9,1 260. 17 Jan. 2018,",
      "Duan, Leizhen et al. Gene vol. 678 (2018): 361-369.",
    ],
    raceInfo: [
      {
        gene: "GC",
        rsId: "rs7041",
        number: "28,150명",
        race: "다인종",
      },
      {
        gene: "GC",
        rsId: "rs2282679",
        number: "4,501명",
        race: "유럽인",
      },
      {
        gene: "CYP24A1",
        rsId: "rs17216707",
        number: "79,366명",
        race: "유럽인",
      },
      {
        gene: "NADSYN1",
        rsId: "rs11234027",
        number: "363,228명",
        race: "영국인",
      },
      {
        gene: "CYP2R1",
        rsId: "rs10741657",
        number: "79,366명",
        race: "유럽계",
      },
    ],
  },
  VitaminA: {
    name: "비타민 A 농도",
    define:
      "비타민A는 지용성 비타민 중 하나로 생물의 성장과 발달, 눈 건강과 피부 건강 등에 관여합니다. 식품을 통해 섭취해야 하나 많이 섭취하게 되면 과잉증으로 세포막의 안전성 저해, 간 조직 손상, 기형아 출산 및 골격 약화 등의 부작용이 나타날 수 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 A 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "고구마, 당근, 시금치, 케일, 호박과 같은 비타민 A가 풍부한 음식을 섭취하면 체내 비타민 A 수치를 높이는 데 도움이 될 수 있습니다. 이러한 식품에는 신체가 비타민 A의 활성 형태로 전환할 수 있는 비타민 A의 일종인 카로티노이드가 풍부합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 A 보충제는 식품으로 비타민 A 섭취량을 늘리기 어려운 사람들에게 특히 유용할 수 있습니다. 비타민 A 보충제는 레티놀 및 베타카로틴과 같은 다양한 형태로 제공됩니다. 레티놀은 신체에서 쉽게 사용할 수 있는 비타민 A의 한 형태로 보충제 선택시 참고하면 좋습니다. 그러나 고용량의 비타민 A 보충제를 복용하는 것은 해로울 수 있으므로 보충제를 복용하기 전에 항상 의료 전문가와 상담하는 것이 가장 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "FFAR4 유전자는 소장과 간에서 레티노이드(비타민 A 및 그 유도체)의 결합 및 섭취에 관여하는 단백질을 암호화합니다. 신체의 비타민 A 항상성을 유지하는 역할을 하는 것으로 여겨집니다.",
      "BCO1 유전자는 식이성 베타카로틴을 레티노이드로 전환시키는 데 관여하는 효소를 암호화합니다. 이 과정은 신체의 적절한 비타민 A 수치를 유지하는 데 중요합니다.",
    ],
    referenceInfo: [
      "Hendrickson, Sara J et al. The American journal of clinical nutrition vol. 96,6 (2012): 1379-89.",
      "Ferrucci, Luigi et al. American journal of human genetics vol. 84,2 (2009): 123-33.",
      "Mondul, Alison M et al. Human molecular genetics vol. 20,23 (2011): 4724-31.",
      "Suzuki, Masako et al. Nutrients vol. 13,6 1743. 21 May. 2021,",
    ],
    raceInfo: [
      {
        gene: "BCO1",
        rsId: "rs6564851",
        number: "2,344명",
        race: "유럽인",
      },
      {
        gene: "FFAR4",
        rsId: "rs10882272",
        number: "4,082명",
        race: "미국인(다양한 인종)",
      },
    ],
  },
  VitaminB6: {
    name: "비타민 B6 농도",
    define:
      "비타민 B6는 수용성 비타민인 비타민 B군의 피리독신으로, 아미노산 대사의 주요 조효소로 생체 내 면역계나 대사과정 등에 광범위 하게 관여하는 영양소로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 B6 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "적절한 비타민 B6 섭취하는 가장 좋은 방법은 가금류, 생선, 통곡물, 콩류 및 견과류와 같은 비타민 B6가 풍부한 식품을 포함하는 다양하고 균형 잡힌 식단 먹는 것입니다. 이러한 음식을 포함하는 다양한 식단을 섭취하면 자연스럽게 비타민 B6를 충분히 섭취할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 B6 보충제는 식단을 통해 적절한 양의 비타민을 섭취하기 어려운 개인에게 유용할 수 있습니다. 잠재적인 부작용이나 독성을 피하기 위해 보충제 제조업체 또는 의료 제공자가 제공한 권장 복용량 지침을 따르는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "NBPF3 유전자는 신체에서 비타민 B6 제거와 관련된 것으로 밝혀진 호르몬인 NBPF3의 합성과 관련이 있습니다. 해당 유전자의 변이된 그룹은 혈장 PLP (Vitamin B6 활성상태) 수준이 더 낮은  경향이 있습니다.",
      "ALPL 유전자는 뼈 무기질화 및 인산염 대사에 관여하는 효소를 암호화합니다. 알칼리 포스파타제는 또한 비타민 B6의 활성 형태인 피리독살 5'-포스페이트(PLP)를 비활성 형태인 피리독살로 전환시키는 것을 촉진함으로써 비타민 B6 대사에서 역할을 하는 것으로 알려져 있습니다.",
    ],
    referenceInfo: [
      "Hazra, Aditi et al. Human molecular genetics vol. 18,23 (2009): 4677-87.",
      "Tanaka, Toshiko et al. American journal of human genetics vol. 84,4 (2009): 477-82.",
      "Keene, Keith L et al. Frontiers in public health vol. 2 112. 6 Aug. 2014,",
    ],
    raceInfo: [
      {
        gene: "NBPF3",
        rsId: "rs4654748",
        number: "4,763명",
        race: "미국인",
      },
      {
        gene: "ALPL",
        rsId: "rs1697421",
        number: "2,100명",
        race: "미국인",
      },
    ],
  },
  VitaminB12: {
    name: "비타민 B12 농도",
    define:
      "비타민 B12는 수용성 비타민인 비타민 B군의 코발아민으로 핵산 합성, 아미노산 및 지방 대사 등에 관여합니다. 구조적으로 중심부인 코린 고리에 코발트를 포함하고 있으며 체내에서 다양하게 사용됩니다. 다른 비타민과 달리 육식을 섭취해야만 하는 것으로 알려져 채식주의자의 결핍에 관한 논란이 있었으나, 최근 콩류나 전통발효식품, 해조류 등을 통해 섭취 가능한 것으로 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 B12 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 식품 섭취하기",
        content:
          "육류, 생선, 가금류, 계란 및 유제품과 같이 비타민 B12가 풍부한 음식을 섭취하는 것은 비타민 B12 섭취를 늘리는 가장 좋은 방법 중 하나입니다. 이러한 동물성 식품에는 신체에 쉽게 흡수되는 메틸코발라민이라는 활성 형태의 비타민 B12가 포함되어 있습니다. 이러한 다양한 음식을 포함하는 식단을 섭취하면 충분한 비타민 B12를 섭취하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 B12 보충제는 정제, 캡슐 및 설하(혀 아래에서 녹는) 정제와 같은 다양한 형태로 제공됩니다. 비타민 B12 보충제는 합성 형태와 천연 형태로 제공되며, 비타민 B12의 천연 형태는 메틸코발라민이고 합성 형태는 시아노코발라민입니다. 메틸코발라민은 몸에 가장 쉽게 흡수되는 형태이므로 제품 선택에 참고하면 좋습니다. 일부 사람들은 위 또는 장 문제로 인해 보충제에서 비타민 B12를 흡수하는 데 문제가 있을 수 있으므로 설하 형태와 같은 다른 형태의 비타민 B12를 사용해야 할 수도 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "MS4A3 유전자는 음식에서 세포로 비타민 B12를 운반하는 과정에 관여하는 단백질을 암호화합니다. 이 유전자의 더 높은 활성은 비타민 B12의 수송과 활용을 증가시킬 수 있습니다.",
      "FUT2 유전자는 비타민 B12의 적절한 흡수에 필요한 내인성 인자의 방출을 돕는 단백질을 암호화합니다. FUT2 유전자의 변이는 내인성 인자의 생성 및 방출에 영향을 미쳐 잠재적으로 체내 비타민 B12 흡수 및 수치에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Lin, Xiaoling et al. Human molecular genetics vol. 21,11 (2012): 2610-7.",
      "Surendran, S et al. Genes & nutrition vol. 13 2. 6 Feb. 2018,",
      "Nongmaithem, Suraj S et al. Human molecular genetics vol. 26,13 (2017): 2551-2564.",
      "Hazra, Aditi et al. Nature genetics vol. 40,10 (2008): 1160-2.",
    ],
    raceInfo: [
      {
        gene: "MS4A3",
        rsId: "rs2298585",
        number: "3,495명",
        race: "중국인",
      },
      {
        gene: "FUT2",
        rsId: "rs602662",
        number: "4,419명",
        race: "인도-유럽계",
      },
      {
        gene: "FUT2",
        rsId: "rs492602",
        number: "2,717명",
        race: "미국인",
      },
    ],
  },
  VitaminE: {
    name: "비타민 E 농도",
    define:
      "비타민 E는 지용성 비타민의 하나로 생체막에서 지방질 산화를 억제하여 세포막 산화를 저해하는 항산화물질로, 세포 노화를 예방하고 적혈구 보호, 헴(Heme) 합성 및 혈소판 응집 등에 관여합니다. 결핍되면 노환, 불임 등이 나타나지만 과잉 시에 피로, 출혈, 설사, 두통 등의 부작용도 나타나는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 E 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "비타민 E는 견과류, 씨앗, 식물성 기름, 잎이 많은 녹색 채소와 같은 식품에 풍부합니다. 이러한 식품 공급원을 포함하는 균형 잡힌 식단을 섭취함으로써 개인은 충분한 수준의 비타민 E를 쉽게 얻을 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "식단을 통해 충분한 비타민 E를 섭취할 수 없는 개인에게는 고품질 비타민 E 보충제가 도움이 될 수 있습니다. 비타민 E 화합물의 두 가지 주요 그룹인 토코페롤과 토코트리에놀이 혼합된 보충제를 선택하여 최대의 이점을 취하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "비타민 E 오일 사용하기",
        content:
          "비타민 E 오일은 피부 건강을 증진하고 잔주름과 주름을 줄이기 위해 사용됩니다. 비타민 E 오일을 피부에 직접 바르면 염증을 줄이고 자외선 및 기타 환경 요인으로 인한 손상으로부터 보호할 수 있습니다. 전체 부위에 바르기 전에 손등 같이 피부의 일부분에 테스트 후 사용하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ZPR1 유전자는 세포 구획 사이에서 비타민 E의 이동을 촉진하기 위해 수송 단백질과 상호 작용하는 단백질을 암호화합니다. 해당 단백질은 비타민 E를 간에서 혈장 및 기타 조직으로 효율적으로 전달하는 데 필요한 것으로 나타났습니다.",
      "CYP4F2는 비타민 E의 대사에 관여하는 유전자입니다. 이것은 비타민 E를 보다 극성인 형태로 전환하여 소변으로 배설될 수 있도록 촉매하는 효소를 암호화합니다. 이 유전자는 신진대사와 배설을 조절함으로써 신체의 비타민 E 수치를 조절하는 역할을 하는 것으로 나타났습니다.",
    ],
    referenceInfo: [
      "Major, Jacqueline M et al. Human molecular genetics vol. 20,19 (2011): 3876-83.",
    ],
    raceInfo: [
      {
        gene: "ZPR1",
        rsId: "rs964184",
        number: "4,014명",
        race: "핀란드인",
      },
      {
        gene: "CYP4F2",
        rsId: "rs2108622",
        number: "29,133명",
        race: "미국인, 핀란드인",
      },
    ],
  },
  VitaminK: {
    name: "비타민 K 농도",
    define:
      "비타민 K는 지용성 비타민의 하나로 혈액응고인자로 작용합니다. 지용성 비타민이므로 몸에 축적이 가능하며 인체 내에서 합성되는 비타민으로 알려져 있어 섭취 시 주의가 필요하다. 결핍 시 지혈에 어려움이 있을 수 있으나, 과잉 섭취 시에는 오히려 혈전을 일으킬 수 있다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 K 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "시금치, 브로콜리, 케일과 같은 잎이 많은 녹색 채소와 같이 비타민 K가 풍부한 음식을 섭취하는 것은 비타민 B12 섭취를 늘리는 가장 좋은 방법 중 하나입니다. 비타민 K는 지용성이므로 식이 지방이 있을 때 가장 잘 흡수됩니다. 비타민 K가 풍부한 음식이나 보충제를 섭취하는 동시에 지방이 많은 음식(예: 아보카도, 견과류 또는 치즈)을 섭취하면 몸이 비타민을 보다 효과적으로 흡수하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 K 보충제는 캡슐, 정제 및 액체와 같은 다양한 형태로 제공되며 종종 다른 비타민 및 미네랄과 결합됩니다. 보충제는 식단을 통해 충분한 비타민 K를 섭취할 수 없는 사람들에게 특히 유익합니다. 고용량의 비타민 K 보충제는 혈액 항응고제와 상호 작용할 수 있으므로 의료 전문가의 지도하에 비타민 K 보충제를 섭취해야 합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "CYP4F2는 비타민 K의 분해에 관여하는 효소입니다. CYP4F2 유전자의 변이가 비타민 K가 체내에서 대사되는 속도에 영향을 미칠 수 있으며, 이는 차례로 체내 비타민 K 수준에 영향을 미칠 수 있는 것으로 나타났습니다.",
      "CTNNA2는 세포 신호 전달 경로에 관여하는 단백질을 암호화하는 유전자입니다. CTNNA2 유전자의 변이는 비타민 K가 체내에서 운반되고 사용되는 방식에 영향을 미침으로써 혈중 비타민 K 수치에 영향을 미칠 수 있는 것으로 밝혀졌습니다.",
    ],
    referenceInfo: [
      "Dashti, Hassan S et al. The American journal of clinical nutrition vol. 100,6 (2014): 1462-9.",
      "Zhao, Sizheng Steven et al. Seminars in arthritis and rheumatism vol. 55 (2022): 152030.",
    ],
    raceInfo: [
      {
        gene: "CYP4F2",
        rsId: "rs2108622",
        number: "826,690명",
        race: "다인종",
      },
      {
        gene: "CTNNA2",
        rsId: "rs4852146",
        number: "826,690명",
        race: "다인종",
      },
    ],
  },
  Omega3: {
    name: "오메가-3 지방산 농도",
    define:
      "오메가-3 지방산은 불포화지방산의 한 종류로 메틸기(CH3)에서 3번째 탄소에 이중결합이 있는 지방산을 오메가-3 지방산이라 합니다.",
    reference: "8",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 오메가-3 지방산 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 오메가-3 섭취하기",
        content:
          "오메가-3가 풍부한 연어, 정어리, 멸치와 같은 지방이 많은 생선을 먹는 것은 오메가-3 지방산의 일일 권장 섭취량을 얻는 좋은 방법입니다. 이 생선에는 인체 건강에 가장 유익한 두 가지 유형의 오메가-3인 에이코사펜타엔산(EPA)과 도코사헥사엔산(DHA)이 풍부합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "식물성 오메가-3 섭취하기",
        content:
          "아마씨, 치아 씨, 호두와 같은 식물성 오메가-3 공급원도 식단에 포함될 수 있습니다. 이러한 식물 공급원에는 EPA와 DHA만큼 유익하지는 않지만 여전히 중요한 공급원인 오메가-3 유형인 알파-리놀렌산(ALA)이 포함되어 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "어유 보충제와 같은 오메가-3 보충제를 섭취하여 오메가-3 섭취를 늘릴 수도 있지만, 라벨을 읽고 권장 복용량을 따르는 것이 중요합니다. 이러한 보충제는 식단만으로는 섭취하기 어려운 EPA와 DHA의 섭취를 늘리는 좋은 방법입니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FADS1 유전자는 알파-리놀렌산(ALA)을 오메가-3 지방산의 일종인 에이코사펜타엔산(EPA)으로 전환하는 데 관여하는 효소를 암호화합니다. 이 효소의 활동은 ALA에서 EPA로의 전환율에 영향을 미치며, 이는 체내 오메가-3 지방산의 전반적인 수준에 영향을 미칠 수 있습니다. FADS1 유전자는 아라키돈산과 같은 오메가-6 지방산을 포함한 장쇄 고도불포화 지방산(LCPUFA)의 생산을 담당합니다. 이러한 LCPUFA는 다양한 생리적 과정의 적절한 성장, 발달 및 유지에 필수적입니다.",
      "BEST1 유전자는 세포 칼슘 수준의 조절에 관여하는 단백질을 암호화합니다. 연구에 따르면 이 단백질은 망막 세포에서 오메가-3 지방산의 수송 및 축적에 역할을 할 수 있습니다.",
    ],
    referenceInfo: [
      "Lemaitre, Rozenn N et al. PLoS genetics vol. 7,7 (2011): e1002193.",
      "Imaizumi, Akira et al. European journal of human genetics : EJHG vol. 27,4 (2019): 621-630.",
      "Xu, Bayi et al. BMC cardiovascular disorders vol. 21,1 532. 8 Nov. 2021,",
    ],
    raceInfo: [
      {
        gene: "FADS1",
        rsId: "rs174547",
        number: "1,361명",
        race: "중국계 싱가포르인",
      },
      {
        gene: "BEST1",
        rsId: "rs1109748",
        number: "694,649명",
        race: "유럽계",
      },
    ],
  },
  Omega6: {
    name: "오메가-6 지방산 농도",
    define:
      "오메가-6 지방산은 불포화지방산의 한 종류로 메틸기(CH3)에서 6번째 탄소에 이중결합이 있는 지방산을 오메가-6 지방산이라 합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 오메가-6 지방산 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "오메가-6 지방산의 가장 좋은 식품 공급원에는 옥수수 기름, 해바라기 기름, 콩기름과 같은 식물성 기름과 호두 및 호박씨와 같은 견과류와 씨앗이 포함됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "오메가-6 보충제는 리놀레산 또는 감마-리놀렌산과 같이 보다 생체 이용 가능한 형태의 농축된 양분을 제공합니다. 오메가-6 보충제는 결핍이 있거나 이러한 지방산 섭취를 늘리려는 사람들에게 도움이 될 수 있습니다. 오메가-6 보충제를 너무 많이 섭취하면 신체의 오메가-3와 오메가-6 균형에 부정적인 영향을 미칠 수 있다는 점에 유의해야 합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "균형 잡힌 식단 갖기",
        content:
          "오메가-6 지방산과 오메가-3 지방산 둘 중 하나가 너무 많으면 건강에 부정적인 영향을 미칠 수 있기 때문에 균형을 유지하는 것이 중요합니다. 오메가-6를 올바르게 섭취하는 가장 좋은 방법은 생선, 견과류, 씨앗, 식물성 기름과 같은 오메가-6 및 오메가-3 지방산이 풍부한 다양한 식품을 포함하는 균형 잡힌 식단을 구성하는 것입니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FADS1 유전자는 아라키돈산과 같은 오메가-6 지방산을 포함한 장쇄 고도불포화 지방산(LCPUFA)의 생산을 담당합니다. 이러한 LCPUFA는 다양한 생리적 과정의 적절한 성장, 발달 및 유지에 필수적입니다.",
      "FADS2 유전자는 아라키돈산과 같은 오메가-6 지방산을 포함한 장쇄 고도불포화 지방산(LCPUFA)의 생산을 담당합니다. 이러한 LCPUFA는 다양한 생리적 과정의 적절한 성장, 발달 및 유지에 필수적입니다.",
      "TMEM258 유전자는 FADS1 및 FADS2 효소의 활성 조절에 관여하는 유전자입니다. 특히 FADS 효소를 안정화하고 분해를 방지하는 데 도움이 되는 단백질을 암호화합니다. TMEM258 유전자의 변이는 또한 혈중 오메가-6 지방산 수치의 변화와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Dorajoo, Rajkumar et al. Genes & nutrition vol. 10,6 (2015): 53.",
      "Guan, Weihua et al. Circulation. Cardiovascular genetics vol. 7,3 (2014): 321-331.",
      "Coltell, Oscar et al. Nutrients vol. 12,2 310. 24 Jan. 2020,",
      "Barman, Malin et al. Nutrients vol. 7,12 10100-15. 3 Dec. 2015,",
    ],
    raceInfo: [
      {
        gene: "FADS1",
        rsId: "rs174547",
        number: "1,361명",
        race: "중국계 싱가포르인",
      },
      {
        gene: "FADS2",
        rsId: "rs2727270",
        number: "8,631명",
        race: "백인",
      },
      {
        gene: "TMEM258",
        rsId: "rs102275",
        number: "8,631명",
        race: "백인",
      },
    ],
  },
  Arginine: {
    name: "아르기닌 농도",
    define:
      "아르기닌은 단백질의 구성성분인 아미노산 중 하나로 강한 염기성을 띤다. 체내에서 암모니아를 요소로 변환할 때 쓰이는 요소회로의 필수 요소로, 상피세포나 뇌세포, 일산화질소 등을 만든다. 이때 생성된 체내 일산화질소는 혈관 확장 및 근육 회복에 도움을 준다고 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 아르기닌 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "아르기닌은 육류, 해산물, 유제품, 견과류 및 씨앗과 같은 여러 식품에서 쉽게 구할 수 있습니다. 이러한 음식을 포함하는 균형 잡힌 식단을 섭취하면 아르기닌의 일일 요구량을 충족하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "아르기닌은 혈류와 근육 성장을 촉진하는 것으로 알려져 있기 때문에 이러한 보충제는 종종 운동 성능 향상제로 판매됩니다. 그러나 아르기닌 보충제의 효과는 여전히 논의 중이며 일부 개인에게는 부작용을 일으킬 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "AGXT2 유전자는 아르기닌 분해에 관여하는 효소를 암호화합니다. AGXT2 유전자의 변이는 아르기닌이 체내에서 분해되는 속도에 영향을 미쳐 전체 수준에 잠재적으로 영향을 미칠 수 있습니다.",
      "SLC7A2 유전자는 세포막을 가로지르는 아르기닌과 같은 양이온성 아미노산의 수송을 담당하는 단백질을 암호화합니다. 이 유전자는 신체에서 아르기닌의 섭취와 분포를 조절하는 역할을 합니다.",
    ],
    referenceInfo: [
      "Kleber, Marcus E et al. Circulation. Cardiovascular genetics vol. 6,5 (2013): 505-13.",
      "Seppälä, Ilkka et al. European heart journal vol. 35,8 (2014): 524-31.",
      "Lotta, Luca A et al. Nature genetics vol. 53,1 (2021): 54-64.",
      "Imaizumi, Akira et al. European journal of human genetics : EJHG vol. 27,4 (2019): 621-630.",
    ],
    raceInfo: [
      {
        gene: "AGXT2",
        rsId: "rs37369",
        number: "5,110명",
        race: "유럽인",
      },
      {
        gene: "SLC7A2",
        rsId: "rs56335308",
        number: "1,338명",
        race: "일본인",
      },
    ],
  },
  Calcium: {
    name: "칼슘 농도",
    define:
      "칼슘은 생체 내 가장 많은 무기질 성분으로 99%는 뼈와 치아의 구성성분으로 골격 유지에, 1%는 세포 내에서 신경 자극 전달, 근육과 혈관의 수축이완, 혈액 응고 및 삼투압 등 인체 내 순환에 관여합니다. 섭취량보다는 섭취율에 대한 고려가 중요하며 과다 섭취 시 요로결석이나 혈관 경화 관련 질환의 발병률을 높이는 것으로 알려져있어 섭취 시 주의가 필요하다.",
    reference: "5",
    heredity: "13",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 5종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 칼슘 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "유제품 섭취하기",
        content:
          "우유, 치즈, 요거트와 같은 유제품의 칼슘은 일반적으로 식물성 식품의 칼슘보다 생물학적 이용 가능성이 더 높습니다. 또한 일부 칼슘 식품 공급원에는 칼슘 흡수를 감소시킬 수 있는 옥살산염과 같은 미네랄 흡수를 방해할 수 있는 다른 물질이 포함될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "칼슘 보충제는 탄산칼슘, 구연산칼슘 또는 젖산칼슘과 같은 생체이용 가능한 형태의 농축된 양분을 제공합니다. 칼슘 보충제는 종종 결핍이 있거나 뼈 건강을 위해 필요한 사람들에게 권장됩니다. 그러나 칼슘 보충제를 과도하게 섭취할 경우 소화 불편, 변비 등의 부작용이 나타날 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CASR 유전자는 칼슘 항상성 조절을 돕는 단백질인 칼슘 감지 수용체를 암호화합니다. 혈중 칼슘 수치를 감지하고 신체의 칼슘 수치를 조절하는 부갑상선 호르몬(PTH) 또는 칼시토닌을 분비하도록 부갑상선에 신호를 보내 칼슘 균형을 유지하는 역할을 합니다.",
      "CYP24A1 유전자는 신체의 칼슘 수치를 조절하는 데 도움이 되는 칼시트리올이라는 호르몬 분해에 관여하는 효소를 암호화합니다. CYP24A1 유전자의 변이는 이 효소의 활성에 영향을 미쳐 잠재적으로 체내 칼슘 수치에 영향을 미칠 수 있습니다.",
      "DGKD 유전자는 신호 전달 경로에 관여하는 두 가지 중요한 2차 전령인 디아실글리세롤(DAG) 및 포스파티드산(PA)의 수준을 조절함으로써 칼슘 신호 전달에 역할하는 효소를 암호화합니다. DGKD는 특히 PTH 분비를 조절하는 부갑상샘에서 칼슘 항상성의 조절에 관여합니다.",
      "GCKR 유전자는 간에서 포도당을 포도당-6-인산으로 인산화하는 것을 촉매하는 효소인 글루코키나아제의 활성을 조절하는 글루코키나아제 조절 단백질을 암호화합니다. GCKR은 글루코스 항상성 및 지질 대사의 조절에 역할을 하며, 특히 뼈에서 칼슘 항상성의 조절과 관련되어 있습니다.",
    ],
    referenceInfo: [
      "Chang, Xiao et al. PloS one vol. 10,4 e0123499. 17 Apr. 2015,",
      "Yuan, Shuai et al. EBioMedicine vol. 76 (2022): 103865.",
      "Sinnott-Armstrong, Nasa et al. Nature genetics vol. 53,2 (2021): 185-194.",
      "Cerani, Agustin et al. BMJ (Clinical research ed.) vol. 366 l4410. 1 Aug. 2019,",
      "Young, William J et al. Circulation. Genomic and precision medicine vol. 14,3 (2021): e003231.",
    ],
    raceInfo: [
      {
        gene: "CASR",
        rsId: "rs1801725",
        number: "339,197명",
        race: "영국인",
      },
      {
        gene: "CYP24A1",
        rsId: "rs2762934",
        number: "339,197명",
        race: "영국인",
      },
      {
        gene: "CYP24A1",
        rsId: "rs1570669",
        number: "339,197명",
        race: "영국인",
      },
      {
        gene: "DGKD",
        rsId: "rs1550532",
        number: "61,079명",
        race: "다인종",
      },
      {
        gene: "GCKR",
        rsId: "rs1260326",
        number: "339,197명",
        race: "영국인",
      },
    ],
  },
  Magnesium: {
    name: "마그네슘 농도",
    define:
      "생체 내 마그네슘은 필수적인 물질이나 미량 존재하는 무기질입니다. 대부분 골격에 존재하면서 뼈나 관절 건강, 일부 에너지 대사와 근육 수축에 관여하는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "7",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 마그네슘 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "자연적으로 마그네슘이 많은 음식에는 잎이 많은 채소, 견과류 및 씨앗, 통곡물, 콩류 및 지방이 많은 생선이 포함됩니다. 식단에 이러한 음식을 포함하면 매일 적절한 마그네슘을 섭취할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "음식만으로 마그네슘 요구량을 충족할 수 없다면 마그네슘 보충제가 좋은 선택이 될 수 있습니다. 그러나 흡수가 잘되고 소화 장애를 일으키지 않는 고품질 보충제를 선택하는 것이 중요합니다. 즉, 흡수를 향상시키기 위해 킬레이트화 된 마그네슘 보충제를 찾으세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "마그네슘 오일 사용하기",
        content:
          "마그네슘 오일은 피부에 직접 바르면 흡수되어 활용될 수 있습니다. 이 보충 방법은 보충제로 인해 소화 불량을 경험하는 개인에게 특히 도움이 될 수 있습니다. 그러나 신체가 피부를 통해 많은 양의 마그네슘을 흡수할 수 없기 때문에 음식을 통해 얻은 마그네슘을 대체할 수 없다는 점에 유의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
    ],
    description: [
      "MUC1은 세포 부착, 신호 전달 및 분화 조절을 비롯한 많은 세포 과정에 관여하는 막관통 당단백질을 암호화합니다. 연구에 따르면 MUC1은 마그네슘 수송을 조절하는 세포막의 이온 채널과 상호 작용하여 마그네슘 항상성에 영향을 줄 수 있습니다.",
      "SHROOM3는 세포 모양과 구조에 관여하고 뇌와 심장을 포함한 많은 기관의 발달에 필요한 단백질을 암호화합니다. 최근 연구에 따르면 SHROOM3는 장에서 마그네슘 수송 및 흡수에 관여하는 단백질과의 상호 작용을 통해 체내 마그네슘 수치 조절에도 관여하는 것으로 나타났습니다.",
    ],
    referenceInfo: [
      "Tin, Adrienne et al. BMC genetics vol. 16 56. 29 May. 2015,",
      "Meyer, Tamra E et al. PLoS genetics vol. 6,8 e1001045. 5 Aug. 2010,",
      "Larsson, Susanna C, and Dipender Gill. Neurology vol. 97,4 (2021): e341-e344.",
    ],
    raceInfo: [
      {
        gene: "MUC1",
        rsId: "rs4072037",
        number: "15,366명",
        race: "유럽인",
      },
      {
        gene: "SHROOM3",
        rsId: "rs13146355",
        number: "79,429명",
        race: "유럽인",
      },
    ],
  },
  Zinc: {
    name: "아연 농도",
    define:
      "생체 내 아연은 핵산과 아미노산 대사에 반드시 필요한 무기질로 다양한 효소의 작용을 돕는 조효소이며, 소화와 호흡작용에 필요하다. 또한 호르몬과 근육생성에 영향을 미치기 때문에 면역기능 및 생식기능에도 관여합니다. 미량원소로 일상적 식단으로 결핍은 드물며, 영양제 형태의 추가 복용을 통한 과잉 섭취 시 신경통증 유발 등이 보고되어 있어 주의가 필요하다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 아연 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "보충제 복용하기",
        content:
          "아연 보충제는 널리 사용 가능하며 캡슐이나 정제 형태로 쉽게 섭취할 수 있습니다. 이러한 보충제는 글루콘산아연, 구연산아연, 아세트산아연 또는 피콜리네이트아연과 같은 보다 생체이용 가능한 형태의 농축된 영양소를 제공하며 섭취량을 늘리려는 사람이나 결핍증이 있는 사람에게 유용할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "식품으로 섭취하기",
        content:
          "아연 함량이 높은 식품을 섭취하면 체내 영양소 수치를 높이는 데 도움이 될 수 있습니다. 아연의 가장 좋은 식품 공급원에는 굴, 쇠고기, 돼지고기, 닭고기, 콩, 견과류 및 통곡물이 포함됩니다. 이러한 음식을 식단에 포함하면 아연 섭취량을 늘리는 데 도움이 될 수 있지만 일부 아연 식품 공급원에는 아연 흡수를 감소시킬 수 있는 피트산과 같이 미네랄 흡수를 방해할 수 있는 다른 물질이 포함되어 있을 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "PPCDC 유전자는 아연을 세포로 수송하는 것과 관련된 단백질을 암호화합니다. PPCDC 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 잠재적으로 세포의 아연 수준에 영향을 줄 수 있습니다.",
      "CA1 유전자는 세포막을 가로지르는 아연 이온의 이동 조절에 관여하는 단백질을 암호화합니다. CA1 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 잠재적으로 세포의 아연 수치에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Evans, David M et al. Human molecular genetics vol. 22,19 (2013): 3998-4006.",
    ],
    raceInfo: [
      {
        gene: "PPCDC",
        rsId: "rs2120019",
        number: "12,628명",
        race: "호주인, 영국인",
      },
      {
        gene: "CA1",
        rsId: "rs1532423",
        number: "12,628명",
        race: "호주인, 영국인",
      },
    ],
  },
  Iron: {
    name: "철 저장 농도",
    define:
      "생체 내 철은 필수 무기물 중 하나로 산소를 운반하는 역할을 하는 적혈구 내 헤모글로빈의 구성성분이기 때문에, 부족 시 빈혈이나 산소 공급이 원활하지 않아 생기는 피로감, 무기력감 등이 나타날 수 있습니다. 반면, 혈중 철분 농도가 급격히 증가하면 쇼크, 대사성산증, 혼수상태, 경련, 간 독성으로 인한 황달 등 철 중독증이 나타날 수 있어 주의가 필요하다. 철분은 섭취 후 헤모글로빈을 만들고 체내 정상적 기능 유지에 일차적으로 사용되나, 페리틴, 헤모시데린과 같은 저장철 형태로 가지고 있다 부족 시 방출하여 혈중 철분 농도에 관여할 수 있습니다.",
    reference: "2",
    heredity: "7",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 철 저장 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 식품 섭취하기",
        content:
          "육류, 가금류, 생선과 같은 동물성 식품의 철분은 일반적으로 잎이 많은 채소 및 콩류와 같은 식물성 식품의 철분보다 생물학적 이용 가능성이 더 높습니다. 이는 동물성 철분 공급원에 헴철이 포함되어 있어 신체에 더 쉽게 흡수되기 때문입니다. 일부 철분 식품 공급원에는 철분 흡수를 감소시킬 수 있는 탄닌 또는 피트산과 같은 미네랄 흡수를 방해할 수 있는 다른 물질이 포함되어 있을 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "철분 보충제는 황산제일철, 글루콘산제일철 또는 푸마르산제일철과 같은 생체 이용 가능한 형태의 농축된 양분을 제공합니다. 철분 보충제는 종종 결핍이 있거나 철분이 필요한 사람들에게 권장됩니다. 그러나 철분 보충제를 과도하게 섭취할 경우 소화 불편 및 변비와 같은 부작용이 나타날 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TF 유전자는 철분을 세포로 수송하고 세포에 저장하는 것과 관련된 단백질을 암호화합니다. TF 유전자의 변이는 이 단백질의 활동에 영향을 미쳐 잠재적으로 세포의 철 저장 수준에 영향을 줄 수 있습니다.",
      "TMPRSS6 유전자는 소장에서 음식으로부터 흡수되는 철분의 양을 조절하는 것과 관련된 단백질을 암호화합니다. TMPRSS6 유전자의 변이는 이 단백질의 활동에 영향을 미쳐 잠재적으로 체내 철 저장 수준에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Benyamin, Beben et al. Nature communications vol. 5 4926. 29 Oct. 2014,",
      "Pichler, Irene et al. Human molecular genetics vol. 20,6 (2011): 1232-40.",
    ],
    raceInfo: [
      {
        gene: "TF",
        rsId: "rs8177240",
        number: "48,972명",
        race: "유럽인",
      },
      {
        gene: "TF",
        rsId: "rs3811647",
        number: "9,090명",
        race: "오스트리아인, 이탈리아인",
      },
      {
        gene: "TMPRSS6",
        rsId: "rs4820268",
        number: "9,090명",
        race: "오스트리아인, 이탈리아인",
      },
      {
        gene: "TMPRSS6",
        rsId: "rs228916",
        number: "48,972명",
        race: "유럽인",
      },
    ],
  },
  Tyrosine: {
    name: "타이로신 농도",
    define:
      "타이로신은 단백질의 구성성분인 아미노산 중 하나이자 방향족 화합물로 페닐알라닌이라는 아미노산에서 체내 합성되기 때문에 비필수 아미노산으로 분류됩니다. 도파민, 아드레날린 등 신경전달물질로 변환되어 신진대사 조절에 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 타이로신 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 식품 섭취하기",
        content:
          "타이로신을 얻는 가장 좋은 방법은 육류, 생선, 가금류, 유제품, 계란 같은 식품입니다. 이러한 식품에는 전반적인 건강에 중요한 비타민 및 미네랄과 같은 다른 영양소가 포함되어 있으며 균형 잡힌 아미노산 공급원을 제공하는 데 도움이 될 수 있습니다. 단백질이 풍부한 다양한 식품을 포함하는 균형 잡힌 식단은 타이로신의 적절한 섭취를 보장하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "타이로신 보충제는 식단에서 충분한 타이로신을 섭취하지 못하는 개인에게 유용할 수 있습니다. 타이로신은 인지 기능을 향상시키고 기분을 좋게 하며 스트레스를 줄이는 능력이 있다고 알려져 있습니다. 그러나 티로신 보충의 잠재적 이점과 위험을 완전히 이해하려면 더 많은 연구가 필요하다는 점에 유의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "SLC16A10 유전자는 타이로신을 세포로 수송하는 것과 관련된 단백질을 암호화합니다. SLC16A10 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 잠재적으로 세포의 타이로신 수준에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Kettunen, Johannes et al. Nature communications vol. 7 11122. 23 Mar. 2016,",
      "Geidenstam, Nina et al. PloS one vol. 14,9 e0222445. 27 Sep. 2019,",
    ],
    raceInfo: [
      {
        gene: "SLC16A10",
        rsId: "rs14399",
        number: "24,925명",
        race: "유럽인",
      },
      {
        gene: "SLC16A10",
        rsId: "rs62421904",
        number: "2,276명",
        race: "미국인, 멕시코인",
      },
    ],
  },
  Betaine: {
    name: "베타인 농도",
    define:
      "베타인은 단백질의 구성성분인 아미노산 중 하나이자 식품에서 감칠맛 성분으로 알려져 있습니다. 메티오닌(Methionine) 합성을 촉진하여 혈압 강하, 항혈당 작용, 해독 작용 등에 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 베타인 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "베타인은 사탕무, 시금치, 통곡물, 새우, 연어, 대구와 같은 해산물을 포함한 다양한 식품에서 발견됩니다. 이러한 음식이 풍부한 식단을 섭취하면 베타인 섭취를 늘리는 데 도움이 될 수 있습니다. 이러한 음식을 섭취하면 베타인 및 기타 영양소의 천연 공급원을 제공하여 신체가 더 쉽게 흡수할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "베타인 보충제는 식단에서 충분한 베타인을 섭취하지 않거나 추가 베타인 섭취가 필요한 개인에게 유용할 수 있습니다. 베타인 보충제는 위산의 pH를 낮춰 소화 불량에 도움을 줄 수 있습니다. 새로운 건강 보조 식품을 복용하기 전에 보조 식품 라벨의 권장 복용량을 따르고 의료 제공자와 상의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "DMGDH 유전자는 트리메틸글리신(베타인)에서 디메틸글리신(DMG)으로의 대사 전환에 관여하는 단백질을 암호화합니다. DMGDH 유전자의 변이는 이 단백질의 활동에 영향을 미쳐 잠재적으로 체내 베타인 수치에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Sookoian, Silvia et al. Liver international : official journal of the International Association for the Study of the Liver vol. 37,4 (2017): 611-619.",
      "Hartiala, Jaana A et al. Nature communications vol. 7 10558. 29 Jan. 2016,",
    ],
    raceInfo: [
      {
        gene: "DMGDH",
        rsId: "rs1805074",
        number: "390명",
        race: "아르헨티나인, 미국인",
      },
      {
        gene: "DMGDH",
        rsId: "rs16876394",
        number: "86,995명",
        race: "미국인",
      },
    ],
  },
  Selenium: {
    name: "셀레늄 농도",
    define:
      "셀레늄은 체내에 필수적인 미량 무기질 중 하나로 강력한 항산화력을 가지는 항산화 물질로 해독작용 및 면역 기능 증진 등에 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 셀레늄 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "보충제 복용하기",
        content:
          "보충제는 셀레노메티오닌 또는 아셀렌산 나트륨과 같이 생체 이용률이 더 높은 형태로 농축된 양의 영양소를 제공하기 때문에 셀레늄을 더 효율적으로 흡수할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "셀렌 효모 이용하기",
        content:
          "셀렌 효모는 셀레늄이 풍부한 효모의 한 형태이며 식이 보충제로 사용할 수 있습니다. 천연 및 식품 기반 셀레늄 공급원을 찾는 사람들에게 좋은 선택입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식품으로 섭취하기",
        content:
          "셀레늄의 가장 좋은 식품 공급원에는 참치, 쇠고기, 칠면조, 브라질 너트, 해바라기 씨 및 현미가 포함됩니다. 일부 셀레늄 식품 공급원에는 셀레늄 흡수를 감소시킬 수 있는 피트산과 같이 미네랄 흡수를 방해할 수 있는 다른 물질이 포함되어 있을 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "DMGDH 유전자는 세포에서 셀레늄을 활용하는 데 필요한 분자 생산에 관여하는 효소를 암호화합니다. DMGDH 유전자의 변이는 이 효소의 활성에 영향을 미쳐 잠재적으로 세포의 셀레늄 활용에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Cornelis, Marilyn C et al. Human molecular genetics vol. 24,5 (2015): 1469-77.",
      "Evans, David M et al. Human molecular genetics vol. 22,19 (2013): 3998-4006.",
    ],
    raceInfo: [
      {
        gene: "DMGDH",
        rsId: "rs17823744",
        number: "4,162명",
        race: "유럽계 미국인",
      },
      {
        gene: "DMGDH",
        rsId: "rs921943",
        number: "12,618명",
        race: "영국인, 호주인",
      },
    ],
  },
  Lutein: {
    name: "루테인&지아잔틴",
    define:
      "루테인과 지아잔틴은 유기색소로 체내에서 유용한 생리활성물질로 알려져 있는 카로티노이드 성분입니다. 구조적으로 둘다 강력한 항산화제로 눈 건강과 시력 보호에 매우 중요한 역할을 하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 루테인&지아잔틴 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "루테인과 지아잔틴이 풍부한 식품에는 케일과 시금치와 같은 잎이 많은 채소와 당근과 같은 다채로운 과일과 채소가 포함됩니다. 생과일과 채소를 먹는 것이 일반적으로 좋은 생각이지만 연구에 따르면 일부 채소를 요리하면 실제로 루테인과 지아잔틴의 생체 이용률을 높이는 데 도움이 될 수 있습니다. 예를 들어 케일과 시금치를 요리하면 세포벽을 분해하고 더 나은 흡수를 위해 카로티노이드를 방출하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "음식에서 영양소를 충분히 섭취하기 힘든 경우 보충제를 선택할 수 있습니다. 메리골드 꽃 추출물과 같은 루테인과 지아잔틴의 천연 공급원을 함유한 보충제를 찾으세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "지방과 함께 섭취하기",
        content:
          "루테인/지아잔틴은 지용성이므로 지방이 있을 때 더 잘 흡수됩니다. 따라서 이러한 음식을 아보카도, 견과류, 씨앗 또는 올리브 오일과 같은 건강한 지방 공급원과 함께 사용하면 흡수를 높이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "SCARB1 유전자는 간에서 루테인과 지아잔틴 수송에 관여하는 단백질을 암호화합니다. SCARB1 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 간에서 루테인과 지아잔틴의 수송에 잠재적으로 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Yonova-Doing, Ekaterina et al. Experimental eye research vol. 115 (2013): 172-7.",
      "Meyers, Kristin J et al. Investigative ophthalmology & visual science vol. 55,1 587-99. 29 Jan. 2014,",
    ],
    raceInfo: [
      {
        gene: "SCARB1",
        rsId: "rs11057841",
        number: "310명",
        race: "영국인",
      },
      {
        gene: "SCARB1",
        rsId: "rs10846744",
        number: "1,663명",
        race: "미국인",
      },
    ],
  },
  Fat: {
    name: "비만",
    define:
      "비만은 체내에 지방이 비정상적으로 많이 축적되는 것으로, 에너지 소비와 공급의 불균형을 유발하는 운동 부족 및 식생활, 다양한 유전적, 환경적, 사회적 요인이 복합적으로 작용하여 생기는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 비만 위험이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "균형 잡힌 식사하기",
        content:
          "칼로리가 낮고 영양이 풍부한 음식을 섭취하면 체중을 관리하고 건강을 유지하는 데 도움이 됩니다. 과일, 채소, 통곡물, 그리고 저지방 단백질을 많이 섭취하면 배고픔을 조절하고 포만감을 유지하며 칼로리 섭취를 조절하는 데 유익할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "신체 활동 늘리기",
        content:
          "규칙적인 신체 활동에 참여하는 것은 칼로리를 태우고 체중을 관리하는 효과적인 방법입니다. 신체 활동에는 걷기, 자전거 타기, 수영 또는 조깅과 같이 심박수를 높이는 모든 유형의 움직임이 포함될 수 있습니다. 대부분의 요일에 최소 30분의 중간 강도 운동을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식사 기록하기",
        content:
          "먹고 마시는 것을 추적하면 칼로리 섭취를 제어하는 ​​데 도움이 될 수 있습니다. 체중 관리 목표에 맞는 적절한 양의 칼로리를 섭취하고 있는지 확인하기 위해 음식 일기 또는 앱을 사용해보세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FTO 유전자는 체중과 체지방량 조절에 관여합니다. 식욕 조절과 에너지 균형에 중요한 역할을 하는 것으로 나타났습니다. 이 유전자 변이는 비만과 높은 체질량 지수(BMI) 경향성과 관련이 있습니다.",
      "NEGR1 유전자는 비만과 관련이 있습니다. 에너지 균형과 지방 저장에 영향을 주어 체중을 조절하는 것으로 나타났습니다. 이 유전자 변이는 비만과 높은 체질량 지수(BMI) 경향성과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Monnereau, C et al. International journal of obesity (2005) vol. 42,4 (2018): 897-904.",
      "Berndt, Sonja I et al. Nature genetics vol. 45,5 (2013): 501-12.",
      "Thorleifsson, Gudmar et al. Nature genetics vol. 41,1 (2009): 18-24.",
    ],
    raceInfo: [
      {
        gene: "FTO",
        rsId: "rs7185735",
        number: "1,995명",
        race: "네덜란드인",
      },
      {
        gene: "NEGR1",
        rsId: "rs2568958",
        number: "263,407명",
        race: "유럽인",
      },
    ],
  },
  BodyFatPer: {
    name: "체지방률",
    define:
      "체지방률은 체중에서 체지방 무게가 차지하는 비율로, 같은 체중이라도 근육량이나 골격의 비율 등에 따라 체지방률이 달라질 수 있습니다.",
    reference: "3",
    heredity: "23",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 체지방률이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적으로 운동하는 것은 체지방률을 관리하는 효과적인 방법입니다. 일주일에 5일, 하루 30분 이상 운동하면 근육량 증가, 신진대사 촉진, 과도한 지방 연소에 도움이 됩니다. 이것은 전반적인 체지방 감소와 체성분 개선으로 이어질 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 만들기",
        content:
          "건강하고 균형 잡힌 식단을 채택하는 것은 체지방률 관리를 위한 핵심 솔루션입니다. 가공 식품, 설탕 및 건강에 해로운 지방의 소비를 줄이고 식이섬유가 풍부한 야채, 해조류와 저지방 단백질 섭취를 늘리는 것이 좋습니다. 이러한 식단 변화를 통해 개인은 칼로리 섭취량을 조절하고 신진대사를 조절하며 체지방 수치를 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "스트레스는 코르티솔 수치 상승과 관련이 있으며, 이는 체지방 축적 증가로 이어질 수 있습니다. 마음 챙김, 명상, 요가 그리고 충분한 수면은 스트레스 수준을 줄이고 더 건강한 체지방률을 촉진하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TMEM18 유전자는 에너지 섭취와 에너지 소비 간의 균형인 에너지 항상성 조절에 관여합니다. 지방 생성(지방 세포의 발달)에서 역할을 하는 것으로 생각되며 여러 인구에서 체질량 지수(BMI) 및 비만과 관련이 있습니다.",
      "COBLL1 유전자는 지방 생성에 관련되어 있으며 여러 연구에서 BMI 및 체지방률과 관련이 있습니다. 지방 조직 발달 및 기능 조절에 역할을 하는 것으로 생각됩니다.",
      "NYAP2 유전자는 인슐린 신호 및 포도당 대사 조절에 관여합니다. 여러 인구에서 BMI, 체지방률 및 비만과 관련이 있습니다. NYAP2는 지방세포 분화 및 인슐린 민감성의 조절에 역할을 하는 것으로 생각됩니다.",
      "PLA2G6 유전자는 세포막 파괴에 관여하는 포스포리파제 효소를 암호화합니다. 여러 연구에서 BMI, 체지방률 및 비만과 관련이 있습니다. PLA2G6은 지방세포 분화 및 지질 대사 조절에 역할을 하는 것으로 생각됩니다.",
    ],
    referenceInfo: [
      "Lu, Yingchang et al. Nature communications vol. 7 10495. 1 Feb. 2016,",
      "Schnurr, Theresia M et al. PloS one vol. 11,11 e0166738. 15 Nov. 2016,",
      "Huang, Lam O et al. Nature metabolism vol. 3,2 (2021): 228-243.",
    ],
    raceInfo: [
      {
        gene: "TMEM18",
        rsId: "rs6755502",
        number: "100,716명",
        race: "다인종",
      },
      {
        gene: "COBLL1",
        rsId: "rs6738627",
        number: "100,716명",
        race: "다인종",
      },
      {
        gene: "PLA2G6",
        rsId: "rs3761445",
        number: "50만명 이상",
        race: "유럽인",
      },
    ],
  },
  Bmi: {
    name: "체질량지수",
    define:
      "체질량지수(BMI)는 체중(㎏)을 신장(m)의 제곱으로 나눈 값으로 대략적인 비만의 추정 지표로 활용됩니다. 체질량지수는 인종, 성별, 연령 및 개인의 근육량 등에 따라 다르게 판단할 수 있습니다.",
    reference: "6",
    heredity: "25",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 6종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 체질량지수가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "유산소 운동하기",
        content:
          "달리기, 걷기, 자전거 타기 또는 수영과 같은 유산소 운동은 체지방을 줄이고, 근육량을 증가시켜 BMI를 감소시킬는 효과를 볼 수 있습니다. 효과적인 BMI 관리를 위해서는 주 5회, 최소 30분 동안 평소보다 숨이 조금 더 찰 정도로 운동을 하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "균형있는 식단 유지하기",
        content:
          "과일, 채소, 통곡물, 저지방 단백질이 풍부한 균형 잡힌 식단을 섭취하면 체중을 조절하고 건강한 BMI를 유지하는 데 도움이 됩니다. 고칼로리, 가공식품을 피하고 설탕과 포화 지방 섭취를 제한하세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "생활습관 바꾸기",
        content:
          "스트레스 감소, 충분한 수면, 흡연과 같은 건강에 해로운 습관 피하기와 같은 일상 습관을 바꾸면 BMI에 큰 영향을 미칠 수 있습니다. 이러한 작은 변화는 장기적인 체중 관리 성공으로 이어질 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FTO 유전자는 BMI 조절에서의 역할에 대해 광범위하게 연구되었습니다. FTO는 DNA와 RNA를 탈메틸화하고 음식 섭취와 에너지 균형을 조절하는 뇌 영역인 시상하부에서 발현되는 효소를 암호화합니다. 이 유전자 변이는 비만과 높은 체질량 지수(BMI) 경향성과 관련이 있습니다.",
      "BDNF 유전자는 뇌에서 뉴런의 생존과 성장에 중요한 단백질인 뇌 유래 신경 영양 인자를 암호화합니다. BDNF는 음식 섭취 및 체중 조절을 포함하여 다양한 생리적 과정에 관여합니다. 여러 연구에서 BDNF 변이와 BMI 사이의 연관성이 보고되었습니다.",
      "MC4R 유전자는 음식 섭취와 에너지 소비 조절에 관여하는 단백질을 암호화합니다. MC4R은 시상하부에서 발현되며 이 유전자의 돌연변이는 심각한 비만과 관련이 있습니다. 여러 연구에서 MC4R의 일반적인 변이와 다른 집단의 BMI 사이의 연관성이 보고되었습니다.",
    ],
    referenceInfo: [
      "Loos, Ruth J F et al. Nature genetics vol. 40,6 (2008): 768-75.",
      "Willer, Cristen J et al. Nature genetics vol. 41,1 (2009): 25-34.",
      "Wojcik, Genevieve L et al. Nature vol. 570,7762 (2019): 514-518.",
      "Locke, Adam E et al. Nature vol. 518,7538 (2015): 197-206.",
      "Speliotes, Elizabeth K et al. Nature genetics vol. 42,11 (2010): 937-48.",
      "Berndt, Sonja I et al. Nature genetics vol. 45,5 (2013): 501-12.",
    ],
    raceInfo: [
      {
        gene: "FTO",
        rsId: "rs1121980",
        number: "83,216명",
        race: "유럽인",
      },
      {
        gene: "FTO",
        rsId: "rs9939609",
        number: "81,000명 이상",
        race: "유럽인",
      },
      {
        gene: "FTO",
        rsId: "rs11075990",
        number: "49,335명",
        race: "비유럽계",
      },
      {
        gene: "BDNF",
        rsId: "rs11030104",
        number: "339,224명",
        race: "유럽인",
      },
      {
        gene: "MC4R",
        rsId: "rs571312",
        number: "249,796명",
        race: "유럽인",
      },
      {
        gene: "MC4R",
        rsId: "rs8089364",
        number: "263,407명",
        race: "유럽인",
      },
    ],
  },
  Abdominal: {
    name: "복부비만(엉덩이허리비율)",
    define:
      "복부비만(엉덩이허리비율)은 체지방이 과도하게 축적된 비만의 유형을 판단하기 위해 허리와 엉덩이의 비율(Waist-hip raito)을 이용하여 비만을 판단하는 여러 수치 중 하나입니다.",
    reference: "3",
    heredity: "21",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 복부비만(엉덩이허리비율) 위험이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "유산소 운동하기",
        content:
          "주 5회, 최소 30분 동안 평소보다 숨이 조금 더 찰 정도로 운동을 하면 복부 지방을 줄이고 전반적인 건강을 개선하는 데 도움이 될 수 있습니다. 달리기, 걷기, 자전거 타기 또는 수영과 같은 유산소 운동을 추천합니다. 규칙적인 운동은 신진대사를 높이고 칼로리를 태우며 뱃살을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식습관 갖기",
        content:
          "허리 둘레를 줄이기 위해서는 과일, 채소, 통곡물, 저지방 단백질과 같이 섬유질이 많은 음식을 섭취하는 것이 좋습니다. 단 음식, 가공 음식, 고지방 음식을 제한하세요. 설탕이 든 음료 대신 물을 마시는 것과 같은 작은 변화가 전반적인 건강과 허리둘레에 상당한 영향을 미칠 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "만성 스트레스는 체내에서 뱃살을 증가시키는 호르몬인 코르티솔을 생성하게 할 수 있습니다. 요가, 명상과 같은 스트레스 감소 활동을 실천하거나 단순히 자기 관리를 위한 시간을 갖는 것은 코르티솔 수치를 낮추고 복부 비만을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "GRB14 유전자는 인슐린 신호에 관여하고 다양한 집단에서 체질량 지수(BMI) 및 비만과 관련이 있는 단백질을 암호화합니다. 연구에 따르면 GRB14는 복부 비만에 기여할 수 있는 지방 세포 분화 및 인슐린 민감성을 조절하는 역할을 할 수 있습니다.",
      "HOXC13 유전자는 모낭의 발달에 관여하는 유전자이지만, 최근 연구에 따르면 지방세포 분화 및 체중 조절에도 역할을 할 수 있다고 합니다. 일부 연구에서는 HOXC13의 변이가 복부 비만의 지표인 BMI 및 허리 둘레와 관련이 있음을 보여주었습니다.",
      "CPEB4 유전자는 mRNA 번역 조절에 관여하는 단백질을 암호화하며, 최근 연구에 따르면 지방 생성 및 비만 발달에도 역할을 할 수 있는 것으로 나타났습니다. CPEB4의 변이는 BMI 및 허리 둘레와 연관되어 있어 복부 비만 발달에 잠재적인 역할을 시사합니다.",
    ],
    referenceInfo: [
      "Heid, Iris M et al. Nature genetics vol. 42,11 (2010): 949-60.",
      "Shungin, Dmitry et al. Nature vol. 518,7538 (2015): 187-196.",
      "Lotta, Luca A et al. JAMA vol. 320,24 (2018): 2553-2563.",
    ],
    raceInfo: [
      {
        gene: "GRB14",
        rsId: "rs10195252",
        number: "224,459명",
        race: "다인종",
      },
      {
        gene: "HOXC13",
        rsId: "rs1443512",
        number: "224,459명",
        race: "다인종",
      },
      {
        gene: "CPEB4",
        rsId: "rs6861681",
        number: "452,302명",
        race: "유럽인",
      },
    ],
  },
  NFatConcentration: {
    name: "중성지방농도",
    define:
      "중성지방은 체내 지방조직에서 분비되어 에너지원으로 사용되는데, 필요치 않은 에너지가 지방으로 전환될 때 혈중에 중성지방 농도가 증가합니다. 혈중 중성지방 농도의 증가는 심혈관 질환이나 말초혈관질환, 비만이나 조절되지 않는 당뇨의 위험 요소로 알려져 있습니다.",
    reference: "5",
    heredity: "22",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 중성지방농도가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식단 주의하기",
        content:
          "포화 지방과 트랜스 지방, 설탕, 정제된 탄수화물이 많은 식단을 섭취하면 중성지방 수치가 높아질 수 있습니다. 섬유질, 과일, 채소, 저지방 단백질, 오메가-3 지방산과 같은 건강한 지방이 풍부한 식단을 섭취하는 데 집중하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "술자리 줄이기",
        content:
          "유전적으로 중성지방 농도가 높은 경우 건강한 지질 수준을 유지하기 위해 알코올 소비를 제한하는 것이 중요합니다. 알코올은 칼로리가 높고 간에서 중성 지방 생성을 증가시켜 혈중 수치를 높일 수 있기 때문입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "신체 활동은 중성지방 수치를 낮추고 전반적인 심장 건강을 개선하는 데 도움이 될 수 있습니다. 일주일에 5일, 하루 30분 이상 운동하면 근육량 증가, 신진대사 촉진, 과도한 지방 연소에 도움이 됩니다. 운동은 중성지방 수치를 낮출 뿐만 아니라 전반적인 건강과 웰빙도 향상시킵니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "DOCK7 유전자는 세포 이동 및 침입을 조절하는 단백질을 암호화합니다. 중성지방 수치를 포함한 지질 대사 조절과 관련이 있습니다.",
      "FADS1 유전자는 지질 대사에서 중요한 역할을 하는 필수 지방산을 장쇄 고도불포화 지방산으로 전환하는 데 관여하는 효소를 암호화합니다. 이 유전자의 변이는 중성지방 수치의 변화와 관련이 있습니다.",
      "TRIB1 유전자는 중성지방 합성 및 분해에 관여하는 효소를 포함하여 지질 대사에 관여하는 여러 효소의 활성을 조절하는 단백질을 암호화합니다. 이 유전자의 변이는 중성지방 수치의 변화와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Kamatani, Yoichiro et al. Nature genetics vol. 42,3 (2010): 210-5.",
      "Bryant, Emily K et al. PloS one vol. 8,5 e63469. 22 May. 2013,",
      "Kathiresan, Sekar et al. Nature genetics vol. 40,2 (2008): 189-97.",
      "Willer, Cristen J et al. Nature genetics vol. 45,11 (2013): 1274-1283.",
    ],
    raceInfo: [
      {
        gene: "DOCK7",
        rsId: "rs10889353",
        number: "14,700명",
        race: "일본인",
      },
      {
        gene: "TRIB1",
        rsId: "rs17321515",
        number: "27,370명",
        race: "유럽인",
      },
      {
        gene: "TRIB1",
        rsId: "rs2954029",
        number: "188,577명",
        race: "다인종",
      },
    ],
  },
  LDL: {
    name: "LDL 콜레스테롤 농도",
    define:
      "LDL 콜레스테롤은 말초 조직으로 콜레스테롤을 운반하는 역할을 하는 콜레스테롤입니다.",
    reference: "9",
    heredity: "8",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 LDL 콜레스테롤 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 섭취하기",
        content:
          "과일, 채소, 통곡물 및 기름기 없는 단백질이 풍부한 식단은 LDL 콜레스테롤 수치를 낮추고 심장 건강을 개선하는 데 도움이 될 수 있습니다. 포화 지방과 트랜스 지방이 적은 음식 섭취에 집중하세요. 이러한 유형의 지방은 LDL 콜레스테롤 수치를 높이는 것으로 알려져 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적정 체중 유지하기",
        content:
          "과체중 또는 비만은 LDL 콜레스테롤 수치를 증가시킬 수 있으므로 건강한 체중을 유지하는 것이 중요합니다. 적정 체중을 유지하기 위해 칼로리 섭취를 줄이고 신체 활동을 늘리는 데 집중하세요. 콜레스테롤 수치를 낮추고 심혈관계 기능을 개선하는데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 신체 활동은 LDL 콜레스테롤 수치를 낮추어 심혈관계 기능을 개선하는 것으로 나타났습니다. 일주일에 5일, 하루 30분 이상 운동하면 근육량 증가, 신진대사 촉진, 과도한 지방 연소에 도움이 됩니다. 운동은 LDL 콜레스테롤 수치를 낮출 뿐만 아니라 전반적인 건강과 웰빙도 향상시킵니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "APOC1 유전자는 LDL 콜레스테롤의 구성 요소인 아포지단백 C-I 생성에 관여합니다. 이 유전자의 변이는 아포지단백 C-I 수치에 영향을 미칠 수 있으며, 결과적으로 LDL 콜레스테롤 수치에 영향을 미칩니다.",
      "PSRC1 유전자는 혈류에서 LDL 콜레스테롤을 제거하는 역할을 하는 LDL 수용체의 활동을 조절하는 데 도움이 됩니다. 이 유전자의 변이는 LDL 수용체의 활동에 변화를 일으켜 LDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
      "LDLR 유전자 혈류에서 LDL 콜레스테롤을 제거하는 LDL 수용체를 암호화합니다. 이 유전자의 변이는 LDL 콜레스테롤 수치에 영향을 줄 수 있는 LDL 수용체 기능의 변화를 초래할 수 있습니다.",
      "CETP 유전자는 고밀도 지단백질(HDL)과 저밀도 지단백질(LDL)을 포함한 지단백질 사이에서 콜레스테롤 에스테르를 전달하는 역할을 하는 단백질을 암호화합니다. CETP는 콜레스테롤 에스테르가 HDL에서 LDL로 이동하는 것을 촉진하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
      "LIPC 유전자는 HDL 콜레스테롤을 비롯한 지질 대사에 관여하는 단백질을 암호화합니다. LIPC는 간에서 발현되며 HDL에서 트리글리세리드와 인지질의 가수분해에 역할을 하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Teslovich, Tanya M et al. Nature vol. 466,7307 (2010): 707-13.",
      "Kathiresan, Sekar et al. Nature genetics vol. 41,1 (2009): 56-65.",
      "Sandhu, Manjinder S et al. Lancet (London, England) vol. 371,9611 (2008): 483-91.",
      "Willer, Cristen J et al. Nature genetics vol. 40,2 (2008): 161-9.",
    ],
    raceInfo: [
      {
        gene: "APOC1",
        rsId: "rs4420638",
        number: "100,000명 이상",
        race: "유럽계",
      },
      {
        gene: "PSRC1",
        rsId: "rs599839",
        number: "16,664명",
        race: "유럽계",
      },
      {
        gene: "LDLR",
        rsId: "rs6511720",
        number: "100,000명 이상",
        race: "유럽계",
      },
    ],
  },
  HDL: {
    name: "HDL 콜레스테롤 농도",
    define:
      "HDL 콜레스테롤은 잉여 콜레스테롤을 간으로 운반하여 배설하도록 돕는 역할을 하는 콜레스테롤입니다.",
    reference: "5",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 HDL 콜레스테롤 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "금연하기",
        content:
          "흡연은 HDL 콜레스테롤 수치에 부정적인 영향을 미치므로 금연은 심장 건강에 필수적입니다. 금연에 도움이 필요하면 니코틴 대체 요법이나 지원 그룹과 같은 이용 가능한 자원에 대해 의사와 상담하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 섭취하기",
        content:
          "과일, 채소, 통곡물, 저지방 단백질이 풍부한 식단은 콜레스테롤 수치를 개선하는 데 도움이 되는 것으로 나타났습니다. LDL 콜레스테롤을 높이고 HDL 콜레스테롤을 낮출 수 있는 포화 지방과 트랜스 지방의 섭취를 제한하세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "유산소 운동하기",
        content:
          "정기적인 유산소 운동은 HDL 콜레스테롤 수치를 증가시켜 심혈관계 기능을 개선하는 데 도움이 되는 것으로 나타났습니다. 빠르게 걷기나 자전거 타기와 같은 중간 정도의 운동을 주 5회, 최소 30분 이상 하는 것을 목표로 하십시오. 운동은 HDL 콜레스테롤 수치를 증가 시킬 뿐만 아니라 전반적인 건강과 웰빙도 향상시킵니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CETP 유전자는 고밀도 지단백질(HDL)과 저밀도 지단백질(LDL)을 포함한 지단백질 사이에서 콜레스테롤 에스테르를 전달하는 역할을 하는 단백질을 암호화합니다. CETP는 콜레스테롤 에스테르가 HDL에서 LDL로 이동하는 것을 촉진하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
      "LIPC 유전자는 HDL 콜레스테롤을 비롯한 지질 대사에 관여하는 단백질을 암호화합니다. LIPC는 간에서 발현되며 HDL에서 트리글리세리드와 인지질의 가수분해에 역할을 하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Oh, Seung-Won et al. PloS one vol. 15,1 e0227357. 7 Jan. 2020,",
      "Lee, Ying-Hui et al. Genetics and molecular biology vol. 45,1 e20210280. 21 Feb. 2022,",
      "Willer, Cristen J et al. Nature genetics vol. 40,2 (2008): 161-9.",
    ],
    raceInfo: [
      {
        gene: "CETP",
        rsId: "rs7499892",
        number: "9,676명",
        race: "한국인",
      },
      {
        gene: "CETP",
        rsId: "rs17231506",
        number: "41,526명",
        race: "타이완인",
      },
      {
        gene: "LIPC",
        rsId: "rs4775041",
        number: "17,637명",
        race: "유럽인종",
      },
    ],
  },
  EffectLosingWeight: {
    name: "운동에 의한 체중감량효과",
    define:
      "체중은 에너지 소비와 공급의 불균형으로 증가 또는 감소합니다. 체중 감량의 기본 원칙이 섭취하는 에너지보다 더 많은 양의 에너지를 소모하는 것이므로 가장 대표적인 에너지 소모 활동인 운동을 통해 체중 감량을 하나, 그 효과는 에너지 대사에 관여하는 건강 상태나 평소 활동량 등 신체적, 환경적, 사회적 원인에 따라 다를 수 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 운동에 의한 체중감량효과가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "가공 식품 피하기",
        content:
          "가공 식품에는 액상과당, 건강에 해로운 지방 및 인공 성분이 많이 들어 있습니다. 이러한 음식의 섭취를 줄임으로써 칼로리 섭취를 줄이고 식욕과 혈당 수치를 조절하며 건강한 체중을 유지할 수 있습니다. 건강한 체중을 유지하기 위해 신선한 과일과 채소, 저지방 단백질, 통곡물을 선택하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "충분한 수면 취하기",
        content:
          "수면은 체중 관리의 핵심 요소이며 적절한 수면은 배고픔과 포만감을 조절하는 렙틴과 그렐린과 같은 호르몬을 조절하는 데 도움이 됩니다. 매일 밤 7-8시간의 수면을 취하고 체중 감량 노력을 지원하기 위해 일관된 수면 일정을 세우세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 감소시키기",
        content:
          "스트레스는 과식으로 이어질 수 있으며, 특히 편안한 음식을 찾게 만드는 경우 더욱 그렇습니다. 심호흡, 명상 또는 운동과 같이 스트레스를 관리하는 건강한 방법을 찾으세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FTO 유전자는 누군가가 먹고 싶어하는 음식의 양과 그들이 소모하는 에너지의 양에 영향을 미침으로써 체중을 ​​조절하는 역할을 하는 것으로 생각됩니다. 일부 연구에서는 FTO 유전자의 특정 변이를 가진 개인이 운동을 통해 체중을 줄이는 데 어려움을 겪을 수 있다고 제안했습니다.",
    ],
    referenceInfo: [
      "Mitchell, Jonathan A et al. Obesity (Silver Spring, Md.) vol. 18,3 (2010): 641-3.",
      "Graff, Mariaelisa et al. PLoS genetics vol. 13,4 e1006528. 27 Apr. 2017",
      "Graff, Mariaelisa et al. PLoS genetics vol. 13,8 e1006972. 23 Aug. 2017",
    ],
    raceInfo: [
      {
        gene: "FTO",
        rsId: "rs8050136",
        number: "234명",
        race: "백인여성",
      },
      {
        gene: "FTO",
        rsId: "rs9941349",
        number: "200,452명",
        race: "유럽인",
      },
      {
        gene: "FTO",
        rsId: "rs3751812",
        number: "200,452명",
        race: "유럽인",
      },
    ],
  },
  BloodSugar: {
    name: "혈당",
    define:
      "혈당은 혈액 속에 함유된 포도당 농도를 말합니다. 여기서 탄수화물이 대사된 단당류인 포도당은 뇌와 적혈구의 에너지원이므로 생명 활동 유지에 필수적입니다. 혈액 내 당 수치의 항상성을 유지하는 것이 중요한데, 혈당 농도는 인슐린 호르몬에 의해 조절됩니다.",
    reference: "7",
    heredity: "9",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 5개의 관련 유전자에 대한 대립유전자형 7종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 적정 혈당인 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "꾸준히 운동하기",
        content:
          "규칙적인 운동은 혈당 수치를 조절하는 데 도움이 되는 인슐린 감수성을 개선하는 데 도움이 됩니다. 빠르게 걷기, 자전거 타기, 등산, 에어로빅 등 심박수를 높이고 활동성을 유지할 수 있는 운동이 좋습니다. 일주일에 5일, 하루 30분 이상 운동하는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "식사량 조절하기",
        content:
          "한 번에 많은 양의 음식을 섭취하면 혈당 수치가 급상승할 수 있습니다. 대신, 혈당 수치를 조절하는 데 도움이 되도록 하루 종일 더 작고 더 자주 식사하는 것을 목표로 하십시오. 또한, 혈당 수치를 높일 수 있는 고지방, 고당 식품을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "혈당 수치 모니터링 하기",
        content:
          "혈당 수치를 정기적으로 모니터링하는 것은 혈당을 더 잘 관리하는 데 필수적입니다. 혈당 수치를 기록하면 패턴이나 추세를 파악하고 필요에 따라 식단과 생활 방식을 조정하는 데 도움이 될 수 있습니다. 의사와 상의하여 혈당 모니터링의 빈도와 시기를 결정하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "MTNR1B 유전자는 수면과 신체의 내부 시계 조절에 관여하는 멜라토닌 호르몬의 생성을 조절하는 데 도움을 줍니다. 이 유전자의 변이는 혈당 수치에 영향을 줄 수 있는 인슐린을 조절하는 신체의 능력에 영향을 줄 수 있습니다.",
      "G6PC2 유전자는 포도당 대사 및 인슐린 생산 조절에 관여합니다. 이 유전자의 변이는 인슐린 수치와 혈당 수치를 조절하는 신체의 능력에 영향을 미칠 수 있습니다.",
      "CDKN2A 유전자는 세포 주기와 세포 성장을 조절하는 데 도움이 되는 단백질을 암호화합니다. 이 유전자의 변이는 높은 혈당 수치와 관련이 있습니다.",
      "GCK 유전자는 포도당 대사 조절에 관여하는 글루코키나제라는 효소를 암호화합니다. 이 유전자의 변이는 혈당 수치를 조절하는 신체의 능력에 영향을 미칠 수 있습니다.",
      "GCKR 유전자는 포도당과 지방 대사 조절에 관여합니다. 이 유전자의 변이는 혈당 수치에 영향을 줄 수 있는 인슐린 저항성과 포도당 내성에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Oh, Seung-Won et al. PloS one vol. 15,1 e0227357. 7 Jan. 2020,",
      "Kim, Minjoo et al. Scientific reports vol. 8,1 7828. 18 May. 2018,",
      "Shi, Yuanyuan et al. PloS one vol. 12,7 e0181232. 13 Jul. 2017,",
      "Downie, Carolina G et al. Diabetologia vol. 65,3 (2022): 477-489.",
      "Hwang, Joo-Yeon et al. Diabetes vol. 64,1 (2015): 291-8.",
      "Wu, Ying et al. Diabetes vol. 57,10 (2008): 2834-42.",
      "Sinnott-Armstrong, Nasa et al. Nature genetics vol. 53,2 (2021): 185-194.",
    ],
    raceInfo: [
      {
        gene: "MTNR1B",
        rsId: "rs10830963",
        number: "9,676명",
        race: "한국인",
      },
      {
        gene: "G6PC2",
        rsId: "rs560887",
        number: "100,000명 이상",
        race: "다인종",
      },
      {
        gene: "CDKN2A",
        rsId: "rs10811661",
        number: "46,085명",
        race: "동아시아인",
      },
      {
        gene: "GCK",
        rsId: "rs16881016",
        number: "363,228명",
        race: "영국인",
      },
      {
        gene: "GCK",
        rsId: "rs730497",
        number: "46,085명",
        race: "동아시아인",
      },
      {
        gene: "GCKR",
        rsId: "rs780094",
        number: "46,085명",
        race: "동아시아인",
      },
      {
        gene: "GCKR",
        rsId: "rs1260326",
        number: "10만명 이상",
        race: "다인종",
      },
    ],
  },
  BloodPressure: {
    name: "혈압",
    define:
      "혈압은 심장의 수축으로 방출된 혈액이 혈관을 따라 흐르며 혈액이 혈관벽에 가하는 압력을 말하며, 심박, 체온, 호흡과 함께 가장 중요한 생명징후(vital sign)로 본다. 혈압은 심장 박동에 따라 심장 주기 동안 측정된 최대값을 수축기 혈압, 최소값을 이완기 혈압이라고 합니다.",
    reference: "7",
    heredity: "15",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 5종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 적정 혈압인 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 유지하기",
        content:
          "과일, 채소, 통곡물, 저지방 유제품이 풍부한 식단은 혈압을 낮추는 데 도움이 될 수 있습니다. 과도한 염분 섭취를 피하고 덜 가공된 음식을 섭취하고 포화 지방 및 트랜스 지방 섭취를 줄이는 것도 혈압 조절에 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적정 체중 유지하기",
        content:
          "과체중과 비만은 고혈압의 주요 위험 요소이므로 건강한 체중을 유지하는 것이 혈압 관리에 중요합니다. 균형 잡힌 식사를 하고 규칙적인 운동을 하면 개인이 건강한 체중을 달성하고 유지하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술과 담배 줄이기",
        content:
          "알코올과 니코틴을 과도하게 섭취하면 혈압이 상승할 수 있으므로 이러한 물질을 제한하는 것이 중요합니다. 금연하고 알코올 소비를 적당한 수준으로 줄이면 건강한 혈압 수준을 유지하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ATP2B1 유전자는 혈관 평활근 세포에서 칼슘 신호 조절을 통해 혈압 조절에 관여하는 칼슘 수송 ATPase를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 높은 혈압과 관련이 있습니다.",
      "CSK 유전자는 키나아제의 Src 계열의 활성을 억제함으로써 혈압 조절에 관여하는 티로신 키나아제를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 높은 혈압과 관련이 있습니다.",
      "NT5C2 유전자는 혈관 긴장도와 혈압의 중요한 조절자인 세포외 뉴클레오티드의 대사에 관여하는 뉴클레오티다아제를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 높은 혈압과 관련이 있습니다.",
      "CASZ1 유전자는 심혈관계 발달에 관여하는 전사 인자를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 혈압 조절 및 높은 혈압과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Ehret, Georg B et al. Nature genetics vol. 48,10 (2016): 1171-1184.",
      "Wain, Louise V et al. Nature genetics vol. 43,10 1005-11. 11 Sep. 2011,",
      "International Consortium for Blood Pressure Genome-Wide Association Studies et al. Nature vol. 478,7367 103-9. 11 Sep. 2011,",
      "Qian, Xinling et al. Clinica chimica acta; international journal of clinical chemistry vol. 487 (2018): 202-209.",
      "Kelly, Tanika N et al. Hypertension (Dallas, Tex. : 1979) vol. 62,5 (2013): 853-9.",
      "Takeuchi, Fumihiko et al. Nature communications vol. 9,1 5052. 28 Nov. 2018,",
      "Lu, Xiangfeng et al. Human molecular genetics vol. 24,3 (2015): 865-74.",
    ],
    raceInfo: [
      {
        gene: "ATP2B1",
        rsId: "rs11105354",
        number: "342,415명",
        race: "유럽인",
      },
      {
        gene: "ATP2B1",
        rsId: "rs17249754",
        number: "122,671명",
        race: "유럽인",
      },
      {
        gene: "CSK",
        rsId: "rs1378942",
        number: "30,000명 이상",
        race: "아시아인",
      },
      {
        gene: "NT5C2",
        rsId: "rs11191593",
        number: "54,783명",
        race: "동아시아인",
      },
      {
        gene: "CASZ1",
        rsId: "rs880315",
        number: "289,038명",
        race: "유럽인, 동아시아인",
      },
    ],
  },
  BoneMass: {
    name: "골질량",
    define:
      "골질량은 절대적인 뼈의 무게를 의미하므로 골밀도와 달리 뼈를 얼마나 많이 가지고 있는가를 나타낸다.",
    reference: "5",
    heredity: "32",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 적정 골질량인 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "규칙적인 운동하기",
        content:
          "걷기, 달리기, 춤, 근력 운동과 같은 체중 부하 운동은 골밀도를 높이고 유지하는 데 도움이 될 수 있습니다. 이러한 운동은 뼈 형성 세포를 자극하여 새로운 뼈 조직을 생성하여 뼈를 더 강하게 만듭니다. 운동은 또한 균형, 조정 및 자세를 개선하여 낙상 및 골절의 위험을 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 유지하기",
        content:
          "칼슘과 비타민 D의 적절한 섭취는 튼튼한 뼈를 만들고 유지하는 데 필수적입니다. 칼슘이 풍부한 식품에는 유제품, 잎이 많은 녹색 채소 및 강화 식품이 포함됩니다. 비타민 D는 지방이 많은 생선, 달걀 노른자 및 강화 식품에서 발견되지만 신체는 피부가 햇빛에 노출될 때도 비타민 D를 생성할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술과 담배 줄이기",
        content:
          "과도한 흡연과 음주는 뼈 건강에 부정적인 영향을 미칠 수 있으며, 이는 뼈 손상 위험을 증가시킬 수 있습니다. 특히 유전적으로 골질량이 낮은 경향이 있는 사람들은 흡연을 피하고 알코올 섭취를 제한하는 것이 중요합니다. 이러한 조치는 뼈 건강을 유지하고 골질량 감소로 인한 잠재적인 문제를 최소화하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "COLEC10 유전자는 뼈 성장 및 형성 조절에 관여합니다. 이 유전자의 변이는 뼈 성장 속도에 영향을 주어 뼈를 더 약하게 만들고 골절에 더 취약하게 만듭니다.",
      "AKAP11 유전자는 뼈 성장 및 리모델링에 중요한 칼슘 신호를 조절하는 데 도움이 됩니다. AKAP11의 돌연변이는 골밀도 감소와 관련이 있습니다.",
      "WNT16 유전자는 뼈 형성에 관여하는 세포의 생산을 조절하는 데 도움을 줍니다. 이 유전자의 변이는 생성되는 뼈 조직의 양에 영향을 주어 골량을 감소시킬 수 있습니다.",
    ],
    referenceInfo: [
      "Medina-Gomez, Carolina et al. American journal of human genetics vol. 102,1 (2018): 88-102.",
      "Kemp, John P et al. PLoS genetics vol. 10,6 e1004423. 19 Jun. 2014,",
      "Estrada, Karol et al. Nature genetics vol. 44,5 491-501. 15 Apr. 2012,",
      "Rivadeneira, Fernando et al. Nature genetics vol. 41,11 (2009): 1199-206.",
      "Zhu, Xiaowei et al. Bone research vol. 9,1 23. 29 Apr. 2021,",
    ],
    raceInfo: [
      {
        gene: "COLEC10",
        rsId: "rs11995824",
        number: "66,628명",
        race: "다인종",
      },
      {
        gene: "COLEC10",
        rsId: "rs2450083",
        number: "4,890명",
        race: "영국인",
      },
      {
        gene: "AKAP11",
        rsId: "rs9533090",
        number: "83,897명",
        race: "유럽아시아인",
      },
      {
        gene: "WNT16",
        rsId: "rs3801387",
        number: "83,897명",
        race: "유럽아시아인",
      },
    ],
  },
  Degenerative: {
    name: "퇴행성관절염증감수성",
    define:
      "퇴행성관절염증감수성이란 퇴행성관절염증에 잘 걸리는 정도를 의미합니다. 퇴행성 관절염은 관절을 보호하고 있는 연골의 손상이나 퇴행성 변화로 인해 관절을 이루는 뼈와 인대 등에 손상이 생겨 염증과 통증이 발생합니다.",
    reference: "3",
    heredity: "7",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 퇴행성관절염증 감수성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "균형 잡힌 식단 섭취하기",
        content:
          "과일, 채소, 통곡물, 저지방 단백질 및 건강한 지방과 같은 영양이 풍부한 식품이 풍부한 균형 잡힌 식단을 섭취하면 건강한 관절 기능을 유지하고 신체의 염증을 줄이는 데 도움이 될 수 있습니다. 또한 글루코사민 및 콘드로이틴과 같은 보충제는 관절 통증을 줄이고 운동성을 향상시키는 것으로 나타나 퇴행성 관절염 환자에게 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "체중 조절 및 운동하기",
        content:
          "과체중이나 비만은 관절, 특히 무릎, 엉덩이, 그리고 허리에 추가적인 스트레스를 줄 수 있습니다. 이로 인해 관절의 염증과 통증이 발생할 수 있습니다. 정기적인 운동은 관절 염증을 완화하고 유지 관리하는 데 중요합니다. 그러나 운동 후 통증이 발생하면 너무 많은 부담을 주지 않고 적절한 휴식과 회복 기간을 확보하는 것이 필요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "GNL3 유전자는 뼈의 성장과 회복 과정을 조절합니다. GNL3 유전자의 변이는 뼈 구조의 변화로 이어지고 퇴행성관절염증감수성에 영향을 줄 수 있습니다.",
      "PTHLH 유전자는 뼈의 성장과 재형성을 조절하는 역할을 합니다. 관절염 예방에 중요한 뼈의 강도와 안정성에 영향을 주어 퇴행성 관절염에 대한 감수성에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "arcOGEN Consortium et al. Lancet (London, England) vol. 380,9844 (2012): 815-23.",
      "Styrkarsdottir, Unnur et al. Nature genetics vol. 50,12 (2018): 1681-1687.",
      "Tachmazidou, Ioanna et al. Nature genetics vol. 51,2 (2019): 230-236.",
    ],
    raceInfo: [
      {
        gene: "GNL3",
        rsId: "rs11177",
        number: "50만명 이상",
        race: "유럽인",
      },
      {
        gene: "PTHLH",
        rsId: "rs10492367",
        number: "40만명 이상",
        race: "영국인",
      },
    ],
  },
  UricAcid: {
    name: "요산치",
    define:
      "요산은 세포가 수명을 다하고 핵산이 유리된 후 핵산의 구성성분인 퓨린이라는 물질이 간에서 대사되면서 생기는 최종 분해 산물로 신장을 거쳐 소변으로 배설됩니다. 체내 요산 생성이 증가하거나 배설이 감소하는 등 체내 요산이 축적되면 혈액 내 요산이 정상보다 높아 고요산혈증이 생기는 것으로 알려져 있습니다.",
    reference: "6",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 요산치가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식단에 주의하기",
        content:
          "붉은 고기, 내장육 및 특정 해산물과 같은 퓨린이 많은 음식은 요산 수치를 증가시킬 수 있습니다. 따라서 식단에서 이러한 음식을 제한하고 대신 과일, 채소 및 통곡물과 같은 저 퓨린 식품을 더 많이 섭취하는 데 집중하는 것이 중요합니다. 또한 충분한 양의 물을 마셔 수분을 유지하면 신체내 과도한 요산을 제거하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 신체 활동은 더 나은 순환을 촉진하고 신체가 요산을 포함한 노폐물을 보다 효과적으로 제거할 수 있도록 하여 요산 수치를 낮추는 데 도움이 될 수 있습니다. 일주일에 5일, 30분 이상 빠르게 걷기 또는 자전거 타기와 같은 적당한 운동을 하는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술자리 피하기",
        content:
          "알코올, 특히 맥주와 주류는 요산 수치를 높일 수 있습니다. 술을 마시는 경우 적당한 양으로 제한하고 폭음을 피하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "GCKR 유전자는 포도당 대사 조절에 영향을 주어 요산 수치에 영향을 줄 수 있습니다. GCKR 유전자의 변이체는 더 높은 요산 수치를 유발할 수 있는 포도당 대사의 변화와 관련이 있습니다.",
      "ABCG2 유전자는 요산을 비롯한 세포막을 통한 물질 수송에 관여합니다. ABCG2 유전자의 변이는 요산 운반 능력을 변경하여 혈중 요산 수치에 영향을 줄 수 있습니다.",
      "SLC2A9 유전자는 요산을 세포로 흡수하는 데 관여합니다. SLC2A9 유전자의 변이는 요산 수치의 변화로 이어질 수 있는 요산 수송의 변화와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Nakatochi, Masahiro et al. Communications biology vol. 2 115. 8 Apr. 2019,",
      "Ho, Li-Ju et al. Scientific reports vol. 12,1 5519. 1 Apr. 2022,",
      "Yang, Binyao et al. BMC medical genomics vol. 7 10. 11 Feb. 2014,",
      "Cheng, Shih-Tsung et al. Journal of the Formosan Medical Association = Taiwan yi zhi vol. 116,1 (2017): 18-23.",
      "Voruganti, V Saroja et al. European journal of human genetics : EJHG vol. 22,7 (2014): 938-43.",
      "Dehghan, Abbas et al. Lancet (London, England) vol. 372,9654 (2008): 1953-61.",
    ],
    raceInfo: [
      {
        gene: "GCKR",
        rsId: "rs1260326",
        number: "121,745명",
        race: "일본인",
      },
      {
        gene: "ABCG2",
        rsId: "rs2231142",
        number: "12,281명",
        race: "중국인",
      },
      {
        gene: "SLC2A9",
        rsId: "rs10805346",
        number: "3,604명",
        race: "아메리카 인디언",
      },
      {
        gene: "SLC2A9",
        rsId: "rs16890979",
        number: "26,718명",
        race: "미국인, 네덜란드인",
      },
    ],
  },
  TravelSickness: {
    name: "멀미",
    define:
      "멀미는 운송수단의 이용 등과 같은 어떤 자극에 의해 신체의 균형을 담당하는 전정 감각기관의 정보가 시각 정보와 일치하지 않는 등의 자율신경계 이상으로 인한 불편함을 말하며, 어지러움, 메스꺼움, 구토, 두통 등의 증상이 일반적으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 멀미 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "생활 습관 주의하기",
        content:
          "간단한 라이프스타일 변화는 멀미 증상을 완화하는 데 도움이 될 수 있습니다. 예를 들어 자동차나 비행기의 앞좌석에 앉아 앞을 똑바로 바라보면 멀미 증상을 줄이는 데 도움이 될 수 있습니다. 여행 전과 여행 중에 과식, 음주, 흡연을 피하는 것도 도움이 될 수 있습니다. 또한 여행 전에 충분한 휴식을 취하고 수분을 유지하면 멀미를 경험할 가능성을 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "약물의 도움받기",
        content:
          "특정 일반의약품 및 처방약은 멀미 증상을 완화하는 데 도움이 될 수 있습니다. 그러나 어떤 약물이든 부작용을 일으키고 다른 약물과 상호 작용할 수 있으므로 약물을 복용하기 전에 의사나 약사와 상담하는 것이 필수적입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "민간 요법 실천하기",
        content:
          "멀미 증상을 완화하는 데 도움이 되는 몇 가지 민간 요법이 있습니다. 예를 들어 손목의 특정 지점을 지압하거나 생강이 함유된 생강차, 캡슐, 사탕 등을 섭취하면 메스꺼움과 구토를 줄이는 데 효과적일 수 있습니다. 그러나 민간 요법이 도움이 될 수는 있지만 약물만큼 강력하지 않을 수 있으며 그 효능은 사람마다 다를 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "LINGO2 유전자는 신경계의 발달과 유지에 관여하는 유전자입니다. 우리의 균형 감각에 관여하는 내이를 포함하여 신경 세포의 기능을 조절하는 역할을 합니다. 연구에 따르면 LINGO2 유전자의 변이가 멀미에 대한 감수성에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Hromatka, Bethann S et al. Human molecular genetics vol. 24,9 (2015): 2700-8.",
    ],
    raceInfo: [
      {
        gene: "LINGO2",
        rsId: "rs2150864",
        number: "80,494명",
        race: "다인종",
      },
    ],
  },
  AlcoholMetabolism: {
    name: "알코올대사",
    define:
      "술의 주성분인 에틸 알코올이 체내에 들어오면 일부는 위에서, 대부분은 소장에서 흡수되고 장 점막을 통해 혈액 내로 들어와 혈관을 통해 순환하다, 간에서 대사되어 체외로 배출되는데 이러한 일련의 과정을 알코올대사라 합니다. 이때 알코올이 분해효소에 의해 아세트알데하이드, 아세트산으로 전환되고 최종적으로 이산화탄소와 물로 분해되는 과정에 관여하는 분해효소의 활성 정도 등은 신체적, 환경적, 유전적 요인에 의해 영향을 받는 것으로 알려져 있습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 알코올대사가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "폭음 피하기",
        content:
          "알코올 대사에 관한 해결책으로는 폭음을 피하는 것이 중요합니다. 폭음은 알코올을 느리게 대사하는 사람들에게 특히 위험할 수 있습니다. 여성의 경우 폭음이란 하루에 5잔 이상의 술을, 남성의 경우 7잔 이상의 술을 마시는 것을 의미합니다. 이는 건강에 심각한 위험을 초래할 수 있기 때문에 폭음을 피하는 것이 권장됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 마시기",
        content:
          "알코올대사가 낮은 경우 물을 많이 마시면 ​​신체 내 알코올을 희석하고 효과를 줄이는 데 도움이 될 수 있습니다. 술을 마시기 전, 술을 마시는 동안, 술을 마신 후에 적어도 한 잔의 물을 마시는 것을 목표로 하십시오. 이것은 또한 알코올의 영향을 악화시킬 수 있는 탈수 위험을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식사하기",
        content:
          "술을 마시기 전에 식사를 하면 알코올의 흡수를 늦추어 신체에 미치는 영향을 줄일 수 있습니다. 식사에 포함된 탄수화물과 단백질이 알코올이 혈류로 흡수되는 속도를 늦출 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ADH1B 유전자는 알코올을 아세트알데히드로 분해하는 데 도움을 주는 효소를 암호화합니다. ADH1B 유전자의 다양한 변이는 이 분해가 얼마나 빨리 발생하는지에 영향을 미쳐 알코올대사 및 내성의 차이로 이어질 수 있습니다.",
    ],
    referenceInfo: [
      "Matoba, Nana et al. Nature human behaviour vol. 4,3 (2020): 308-316.",
    ],
    raceInfo: [
      {
        gene: "ADH1B",
        rsId: "rs1229984",
        number: "5,632명/58,610명",
        race: "마국인/일본인",
      },
    ],
  },
  AlcoholDependence: {
    name: "알코올의존성",
    define:
      "알코올의존성이란, 알코올 섭취에 의한 정신적 효과를 얻기 위해 또는 알코올을 섭취하지 않았을 때의 불편함을 피하기 위해 지속적으로 알코올을 섭취하지 않으면 안되는 경향 또는 상태를 의미합니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 알코올의존성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "음주 유발 요인 피하기",
        content:
          "유발 요인을 피하는 것은 알코올 의존도를 줄이는 데 중요한 부분이며 새로운 습관을 개발하려면 시간과 노력이 필요합니다. 음주를 하고 싶은 욕구를 유발하는 상황, 감정 및 사람을 기억하고 피하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "대체재 찾기",
        content:
          "술을 끊으면 스트레스와 불안감이 커질 수 있으므로 이러한 감정을 관리할 수 있는 다른 방법을 찾는 것이 중요합니다. 음주와 관련되지 않은 운동, 새로운 취미 시도 또는 사랑하는 사람과 시간을 보내는 것처럼 새롭고 신나는 활동으로 대체해 보세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 줄이기",
        content:
          "스트레스는 알코올 소비의 주요 원인이 될 수 있습니다. 심호흡, 마음 챙김 또는 운동과 같은 스트레스 관리 기술을 연습하면 스트레스와 갈망을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ALDH2 유전자는 알코올이 대사될 때 생성되는 아세트알데히드라는 독성 물질을 분해하는 데 관여하는 효소를 암호화합니다. ALDH2 유전자의 일부 변이는 이 효소의 활성을 감소시켜 안면 홍조, 빠른 심장 박동 및 음주를 덜 즐겁게 만드는 기타 증상을 유발할 수 있습니다. 이러한 변이가 있는 사람들은 많은 양의 알코올을 마실 가능성이 낮으므로 알코올 의존증이 발생할 가능성이 적습니다.",
      "ADH1B 유전자는 간에서 알코올 분해에 관여하는 효소를 암호화합니다. ADH1B 유전자의 일부 변이는 이 효소의 활성을 증가시켜 알코올대사를 촉진하고 알코올의존성 발생 영향을 감소시킵니다.",
    ],
    referenceInfo: [
      "Jorgenson, E et al. Molecular psychiatry vol. 22,9 (2017): 1359-1367.",
      "Sun, Yan et al. Translational psychiatry vol. 9,1 249. 7 Oct. 2019,",
      "Walters, Raymond K et al. Nature neuroscience vol. 21,12 (2018): 1656-1669.",
    ],
    raceInfo: [
      {
        gene: "ALDH2",
        rsId: "rs671",
        number: "86,627명",
        race: "비히스패닉 백인, 히스패닉/라틴계, 동아시아인 및 아프리카계 미국인",
      },
      {
        gene: "ADH1B",
        rsId: "rs1229984",
        number: "52,848명",
        race: "유럽인, 아프리카인",
      },
    ],
  },
  AlcoholFlush: {
    name: "알코올 홍조",
    define:
      "홍조는 피부가 붉어지는 현상으로 알코올홍조는 알코올대사가 원활하지 않아 분해되지 못한 독소의 영향으로 얼굴 등에 있는 혈관이 팽창되고 혈관 확장으로 달아오르는 느낌과 함께 붉어지는 현상을 말합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 알코올홍조 경험 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "술 천천히 마시기",
        content:
          "술을 천천히 마시면 알코올홍조 증상의 강도를 줄이는 데 도움이 될 수 있습니다. 알코올홍조가 느껴진다면 속도를 늦추는 것이 가장 좋습니다. 음주 사이에 음료수를 마거나 휴식을 취하는 것도 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 마시기",
        content:
          "알코올홍조가 쉽게 일어나는 경우 물을 많이 마시면 ​​알코올홍조 증상을 완화하는 데 도움이 될 수 있습니다. 술을 마시기 전, 술을 마시는 동안, 술을 마신 후에 적어도 한 잔의 물을 마시는 것을 목표로 하십시오. 이것은 또한 알코올의 영향을 악화시킬 수 있는 탈수 위험을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "증상 인지하기",
        content:
          "발적, 가려움, 홍조와 같은 알코올성 홍조 증상을 인지하고 증상이 발생하면 이를 완화하기 위한 조치를 취하세요. 물을 마시거나 신선한 공기를 마시면 알코올홍조 증상을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ALDH2 유전자는 알코올이 대사될 때 생성되는 아세트알데히드라는 독성 물질을 분해하는 데 관여하는 효소를 암호화합니다. ALDH2 유전자의 일부 변이는 이 효소의 활성을 감소시켜 안면 홍조, 빠른 심장 박동 및 음주를 덜 즐겁게 만드는 기타 증상을 유발할 수 있습니다.",
    ],
    referenceInfo: [
      "Cho, Yoonsu et al. Scientific reports vol. 8,1 458. 11 Jan. 2018,",
      "Wall, T L et al. Alcoholism, clinical and experimental research vol. 16,5 (1992): 991-5.",
    ],
    raceInfo: [
      {
        gene: "ALDH2",
        rsId: "rs671",
        number: "2,011명",
        race: "한국인",
      },
    ],
  },
  NicotineMetabolism: {
    name: "니코틴대사",
    define:
      "니코틴은 담배의 주성분으로 알려져 있어 흡연을 통해 체내로 들어와 우리 뇌의 중추와 말초의 신경을 흥분시키는 물질로 작용하는데, 니코틴대사는 니코틴이 체내에서 제거되어 배출되는 일련의 과정을 말합니다. 관련 효소 활성도, 간 기능 등에 영향을 받는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 니코틴대사가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "니코틴 줄이기",
        content:
          "니코틴대사가 낮은 사람들은 니코틴 함유 제품을 사용할 때 추가 예방 조치를 취하고 건강을 개선하기 위해 금연을 고려하는 것이 중요합니다. 라이트 또는 울트라 라이트 담배와 같이 니코틴 농도가 낮은 니코틴 제품 사용을 고려하세요. 니코틴 껌, 사탕 또는 패치와 같은 니코틴 대체 요법 제품도 금단 증상을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "대체재 찾기",
        content:
          "니코틴을 끊으면 스트레스와 불안감이 커질 수 있으므로 운동, 심호흡 또는 취미 등 이러한 감정을 관리할 수 있는 다른 방법을 찾는 것이 중요합니다. 흡연 대신 할 수 있는 건강한 대체 활동을 찾으세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 줄이기",
        content:
          "스트레스는 흡연의 일반적인 원인이며 금연을 더 어렵게 만들 수 있습니다. 심호흡, 마음 챙김 또는 운동과 같은 스트레스 관리 기술을 연습하면 스트레스와 갈망을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CYP2B6 유전자는 약물 대사에서 중요한 역할을 하는 효소를 암호화합니다. 특히 CYP2B6는 담배 연기에서 발견되는 니코틴 및 기타 화학 물질의 대사에 관여합니다. CYP2B6 유전자의 변이는 개인의 니코틴대사 능력에 영향을 미칠 수 있으며, 이는 흡연 행동 및 중독 감수성에 영향을 미칠 수 있습니다.",
      "CYP2A6 유전자는 간에서 니코틴대사를 주로 담당하는 효소를 암호화하며. 이 효소는 니코틴을 주요 대사 산물로 전환하는 데 관여합니다. CYP2A6 유전자의 변이는 니코틴이 대사되는 속도에 영향을 미치는 것으로 나타났으며, 이는 니코틴 대체 요법 및 금연 노력의 효능에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Loukola, Anu et al. PLoS genetics vol. 11,9 e1005498. 25 Sep. 2015,",
      "Chenoweth, Meghan J et al. Scientific reports vol. 11,1 19572. 1 Oct. 2021,",
    ],
    raceInfo: [
      {
        gene: "CYP2B6",
        rsId: "rs7260329",
        number: "1,518명",
        race: "핀란드인",
      },
      {
        gene: "CYP2A6",
        rsId: "rs56113850",
        number: "1,785명",
        race: "유럽계 미국인(EA) 및 아프리카계 미국인(AA)",
      },
    ],
  },
  NicotineDependence: {
    name: "니코틴의존성",
    define:
      "니코틴의존성이란, 니코틴 흡수에 의한 정신적 효과를 얻기 위해 또는 금연으로 인한 금단현상 등의 불편함을 피하기 위해 지속적으로 흡연을 하지 않으면 안되는 경향 또는 상태를 의미합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 니코틴의존성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "흡연 유발 요인 피하기",
        content:
          "유발 요인을 피하는 것은 니코틴 의존도를 줄이는 데 중요한 부분이며 새로운 습관을 개발하려면 시간과 노력이 필요합니다. 담배를 피우고 싶은 욕구를 유발하는 상황, 감정 및 사람을 기억하고 피하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "니코틴 대체요법 수행하기",
        content:
          "니코틴 대체요법은 니코틴 의존 자체를 치료하기보다는 니코틴을 흡연 이외의 방법으로 신체에 공급해 니코틴 금단증상을 감소시키고 갈망을 줄이는 방법입니다. 니코틴 패치, 니코틴 껌, 니코틴 사탕, 니코틴 비강분무제, 흡입기 등으로 이용할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 줄이기",
        content:
          "스트레스는 흡연의 일반적인 원인이며 금연을 더 어렵게 만들 수 있습니다. 심호흡, 마음 챙김 또는 운동과 같은 스트레스 관리 기술을 연습하면 스트레스와 갈망을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CHRNA4 유전자는 뇌에서 니코틴이 표적으로 삼는 주요 수용체인 니코틴성 아세틸콜린 수용체의 서브유닛을 암호화합니다. 이 유전자의 변이는 니코틴의존성 및 다양한 흡연 관련 행동과 관련이 있습니다.",
      "CHRNA3 유전자는 뇌에서 니코틴이 표적으로 삼는 주요 수용체인 니코틴성 아세틸콜린 수용체의 서브유닛을 암호화합니다. 이 유전자의 변이는 니코틴의존성 및 다양한 흡연 관련 행동과 관련이 있습니다.",
      "CHRNA5 유전자는 뇌에서 니코틴이 표적으로 삼는 주요 수용체인 니코틴성 아세틸콜린 수용체의 서브유닛을 암호화합니다. 이 유전자의 변이는 니코틴의존성 및 다양한 흡연 관련 행동과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Hancock, D B et al. Translational psychiatry vol. 5,10 e651. 6 Oct. 2015,",
      "Munafò, Marcus R et al. Nicotine & tobacco research : official journal of the Society for Research on Nicotine and Tobacco vol. 13,10 (2011): 982-8.",
      "Thorgeirsson, Thorgeir E et al. Nature vol. 452,7187 (2008): 638-642.",
      "Quach, Bryan C et al. Nature communications vol. 11,1 5562. 3 Nov. 2020,",
    ],
    raceInfo: [
      {
        gene: "CHRNA4",
        rsId: "rs2273500",
        number: "58,000명",
        race: "유럽계 혹은 아프리카계 흡연자",
      },
      {
        gene: "CHRNA3",
        rsId: "rs1051730",
        number: "10,995명",
        race: "유럽인",
      },
      {
        gene: "CHRNA5",
        rsId: "rs16969968",
        number: "58,000명",
        race: "유럽계 혹은 아프리카계 흡연자",
      },
    ],
  },
  CaffeineMetabolism: {
    name: "카페인대사",
    define:
      "커피 등의 주성분으로 알려진 카페인은 체내에서 중추신경계를 자극하여 뇌의 각성시킴으로써 집중력을 높이는 효과가 있는 물질로 알려져 있으며, 이렇게 섭취되어 체내에 흡수된 카페인이 제거되어 체외로 배출되는 일련의 과정을 말합니다. 카페인대사는 관련 효소 활성도 등에 영향을 받는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 카페인대사가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "카페인 섭취 줄이기",
        content:
          "내 몸이 카페인에 어떻게 반응하는지 주의를 기울이고 그에 따라 섭취량을 조절하세요. 이를 통해 개인의 내성을 확인하고 낮은 카페인대사와 관련된 증상을 최소화할 수 있습니다. 부정적인 영향을 경험하면 카페인 섭취를 줄이거나 끊는 것을 고려하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "비타민 C 섭취하기 ",
        content:
          "비타민 C는 카페인을 대사하는 신체의 능력을 지원하는 데 도움이 되는 것으로 나타났습니다. 이것은 유전적으로 카페인대사가 낮은 사람들에게 특히 유익할 수 있습니다. 오렌지, 레몬, 피망, 고추 등을 식단에 포함 시켜보세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "물 자주 마시기",
        content:
          "유전적으로 카페인대사가 낮은 경우 체내에서 카페인을 제거하는 데 도움이 되도록 물 섭취를 늘리는 것이 좋습니다. 정기적으로 물을 마시면 두통, 피로 및 인지 기능 저하와 같은 부작용을 유발할 수 있는 탈수를 예방하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CYP1A2 유전자는 카페인을 포함한 많은 화합물의 대사에 관여하는 효소를 암호화합니다. CYP1A2 유전자의 다른 변이체는 카페인이 신체에서 얼마나 빨리 또는 느리게 대사되는지에 영향을 미쳐 개인에 대한 카페인의 영향에 차이가 있습니다.",
    ],
    referenceInfo: [
      "Cornelis, Marilyn C et al. Human molecular genetics vol. 25,24 (2016): 5472-5482.",
      "Amin, N et al. Molecular psychiatry vol. 17,11 (2012): 1116-29.",
    ],
    raceInfo: [
      {
        gene: "CYP1A2",
        rsId: "rs2472297",
        number: "9,876명",
        race: "유럽인",
      },
      {
        gene: "CYP1A2",
        rsId: "rs2470893",
        number: "26,105명",
        race: "백인",
      },
    ],
  },
  CaffeineDependence: {
    name: "카페인 의존성",
    define:
      "카페인의존성이란, 카페인 섭취를 통한 정신적 효과를 얻기 위해 또는 섭취하지 않았을 때 느껴지는 불편함을 피하기 위해 지속적으로 섭취를 하지 않으면 안되는 경향 또는 상태를 의미합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 카페인의존성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "카페인 섭취 줄이기",
        content:
          "내 몸이 카페인에 어떻게 반응하는지 주의를 기울이고 그에 따라 섭취량을 조절하세요. 이를 통해 개인의 내성을 확인하고 높은 카페인의존성과 관련된 증상을 최소화할 수 있습니다. 부정적인 영향을 경험하면 카페인 섭취를 줄이거나 끊는 것을 고려하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 자주 마시기",
        content:
          "유전적으로 카페인의존성이 높은 경우 체내에서 카페인을 제거하는 데 도움이 되도록 물 섭취를 늘리는 것이 좋습니다. 정기적으로 물을 마시면 두통, 피로 및 인지 기능 저하와 같은 부작용을 유발할 수 있는 탈수를 예방하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 운동은 카페인의존성에 대한 유전적 소인이 높은 사람들이 카페인의존성을 줄이는 효과적인 방법이 될 수 있습니다. 운동은 스트레스를 줄이고 에너지 수준을 높이며 더 나은 수면을 촉진하여 카페인의 필요성을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "AHR 유전자는 체내에서 카페인을 포함한 독성 물질의 대사 및 제거 조절에 관여합니다. AHR 유전자의 변이는 카페인이 대사되고 제거되는 속도에 영향을 미쳐 잠재적으로 카페인 민감성과 의존성의 차이를 초래할 수 있습니다.",
    ],
    referenceInfo: [
      "Coffee and Caffeine Genetics Consortium et al. Molecular psychiatry vol. 20,5 (2015): 647-656.",
      "Cornelis, Marilyn C et al. PLoS genetics vol. 7,4 (2011): e1002033.",
    ],
    raceInfo: [
      {
        gene: "AHR",
        rsId: "rs4410790",
        number: "47,341명",
        race: "유럽계 미국인",
      },
    ],
  },
  Insomnia: {
    name: "불면증",
    define:
      "불면증은 적절한 시간과 기회가 주어지는데도 불구하고 충분한 수면의 시작과 지속 등에 문제가 생겨 수면의 양과 질에 문제가 생기는 상태를 의미합니다.",
    reference: "4",
    heredity: 6,
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 불면증 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "일정한 수면 습관 만들기",
        content:
          "불면증을 극복하기 위해선 일관된 수면 일정을 유지하는 것이 중요합니다. 주말 또는 평일에 상관없이 매일 같은 시간에 잠자리에 들고 일어나는 것을 의미합니다. 이것은 신체의 내부 시계를 조절하고 수면의 질을 향상시키는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "잠들기 전 딴짓하지 않기",
        content:
          "잠자리에 들기 전에 전자기기 사용은 피하는 것이 좋습니다. 전자기기에서 방출되는 청색광은 신체의 자연스러운 수면-각성 주기를 방해할 수 있기 때문입니다. 또한, 운동과 같은 자극적인 활동도 잠들기 어렵게 만들 수 있으므로 피하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술, 담배, 커피 피하기",
        content:
          "잠자리에 들기 전에 카페인, 알코올 및 니코틴을 피하는 것도 수면의 질을 개선하는 데 도움이 될 수 있습니다. 이러한 물질은 신체의 자연스러운 수면 패턴을 방해할 수 있기 때문입니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "MEIS1 유전자는 신체 내부의 24시간 시계인 일주기 리듬을 조절하는 데 관여하는 유전자입니다. 이 유전자의 변이는 신체의 수면-각성 주기에 영향을 미쳐 잠들거나 계속 잠드는 데 어려움을 겪게 하여 불면증을 유발할 수 있습니다.",
      "NEGR1 유전자는 일주기 리듬과 수면-각성 주기를 조절하는 역할을 합니다. NEGR1 유전자의 변이는 불면증을 포함한 수면 장애와 관련이 있습니다. 특정 NEGR1 변이를 가진 사람들은 잠들고 잠드는 데 어려움을 겪어 불면증 증상을 유발할 수 있습니다.",
    ],
    referenceInfo: [
      "Lane, Jacqueline M et al. Nature genetics vol. 49,2 (2017): 274-281.",
      "Hammerschlag, Anke R et al. Nature genetics vol. 49,11 (2017): 1584-1592.",
      "Liu, Xinhui et al. Journal of the American Heart Association vol. 10,15 (2021): e020187.",
      "Watanabe, Kyoko et al. Nature genetics vol. 54,8 (2022): 1125-1132.",
    ],
    raceInfo: [
      {
        gene: "MEIS1",
        rsId: "rs113851554",
        number: "112,586명",
        race: "유럽인",
      },
      {
        gene: "NEGR1",
        rsId: "rs2815757",
        number: "2백만명 이상",
        race: "다인종",
      },
    ],
  },
  MorningPerson: {
    name: "아침형, 저녁형 인간",
    define:
      "원인은 정확하지 않지만 우리 몸은 특정한 시간에 대하여 수면, 식욕, 운동, 선호도 등 영향을 받는 성향을 가질 수 있습니다. 개인에게 맞는 수면 시간과 각성 시간에 의한 생체 리듬에 따라 각성 시간이 아침에 있는 아침형과 저녁에 있는 저녁형으로 구분할 수 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 아침형 인간 성향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "일관된 수면 시간 정하기",
        content:
          "아침형인 경향이 있는 사람들은 매일 같은 시간에 일어나고 늦잠을 피하는 것을 목표로 해야 합니다. 이는 일주기 리듬을 방해할 수 있기 때문입니다. 반대로 저녁형에 유전적으로 소인이 있는 사람들은 선호하는 수면 패턴에 맞는 일관된 수면 일정을 수립하는 것을 목표로 해야 합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적절한 빛 노출하기",
        content:
          "빛 노출은 일주기 리듬을 조절하는 데 필수적인 요소입니다. 아침형은 아침에 자연광을, 저녁형은 늦은 오후와 저녁에 밝은 빛에 더 많이 노출되는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "생체 시계에 맞게 라이프스타일 조정하기",
        content:
          "아침형은 가장 정신이 맑을 때 아침에 가장 힘든 일을 계획하는 것이 유익할 수 있는 반면, 저녁형은 저녁에 더 생산적일 수 있습니다. 또한 개인은 운동 루틴, 식습관 및 사회 활동을 자신의 생체 시계에 맞게 조정하면 전반적인 웰빙을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FBXL3 유전자는 생체 시계의 중요한 구성 요소인 PER 단백질의 분해에 관여하는 단백질을 암호화합니다. FBXL3의 변이는 일주기 리듬 및 생체 시계의 차이와 관련이 있습니다.",
      "RASD1 유전자는 일주기 시계를 포함하여 세포 활동의 조절에 관여하는 작은 G 단백질을 암호화합니다. RASD1의 변이는 수면 시간과 아침형 또는 저녁형의 차이와 관련이 있습니다.",
      "RNASEL 유전자는 세포 성장 및 염증 조절에 관여하는 효소를 암호화합니다. 일부 연구에서는 RNASEL의 변이를 수면 시간 및 생체 시계의 차이와 관련성을 발견했니다.",
    ],
    referenceInfo: [
      "Jones, Samuel E et al. PLoS genetics vol. 12,8 e1006125. 5 Aug. 2016,",
      "Hu, Youna et al. Nature communications vol. 7 10448. 2 Feb. 2016,",
    ],
    raceInfo: [
      {
        gene: "FBXL3",
        rsId: "rs9565309",
        number: "89,283명",
        race: "영국인",
      },
      {
        gene: "RASD1",
        rsId: "rs11545787",
        number: "89,283명",
        race: "영국인",
      },
      {
        gene: "RNASEL",
        rsId: "rs516134",
        number: "89,283명",
        race: "영국인",
      },
      {
        gene: "RNASEL",
        rsId: "rs12736689",
        number: "128,266명",
        race: "영국인",
      },
    ],
  },
  PainSensitivity: {
    name: "통증",
    define: "개인이 통증에 대해 느끼는 감각적인 반응의 정도나 강도를 말합니다",
    reference: "2",
    heredity: 10,
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 C 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "스트레스 관리 기술 연습하기",
        content:
          "식욕은 식욕촉진호르몬 그렐린과 포만감 호르몬 렙틴의 균형이 깨져 비만으로 이어질 수 있습니다. 수면시간이 충분하지 않을 경우, 렙틴 분비가 억제되고 식욕이 증가할 수 있습니다. 따라서 하루 7시간이상 충분한 수면을 취하는 것 이 중요합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "생활 습관 바꾸기",
        content:
          "규칙적인 운동은 근육을 강화하고 통증을 줄이는 데 도움이 되며, 건강한 식단을 유지하면 염증을 줄이고 치유를 촉진하는 데 도움이 될 수 있습니다. 또한 좋은 수면 위생을 실천하면 통증 증상을 줄이고 전반적인 건강을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "SCN10A 유전자는 통증 신호 전달에 관여하는 단백질을 암호화합니다. SCN10A 유전자의 변이는 이 단백질의 기능과 통증 신호가 전달되는 방식에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [""],
    raceInfo: [
      {
        gene: "MS4A3",
        rsId: "rs2298585",
        number: "3,495명",
        race: "중국인",
      },
      {
        gene: "FUT2",
        rsId: "rs602662",
        number: "4,419명",
        race: "인도-유럽계",
      },
      {
        gene: "FUT2",
        rsId: "rs492602",
        number: "2,717명",
        race: "미국인",
      },
    ],
  },
  Freckles: {
    name: "기미/주근깨",
    define:
      "기미와 주근깨는 정확한 원인은 밝혀져 있지 않지만 주로 햇빛의 자외선에 의해 피부에 생기는 황갈색의 색소성 반점을 의미합니다. 대개 기미는 좌우 대칭적으로 빰과 이마 눈 밑에 불규칙한 모양과 크기로 나타나며, 주근깨는 주로 빰과 팔의 윗부분, 앞가슴 등에 작은 황갈색 점으로 나타납니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 기미/주근깨 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "SPF 30 이상의 광범위 자외선 차단제를 매일 바르면 유해한 자외선으로부터 피부를 보호하고 기미와 주근깨가 생기는 것을 예방할 수 있습니다. 2시간마다 다시 발라주는 것이 좋으며, 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "항산화제가 풍부한 식품 섭취하기",
        content:
          "항산화제는 태양 노출로 인한 피부 손상을 예방할 수 있습니다. 대표적인 항산화제 식품으로는 시금치, 딸기, 토마토, 견과류가 있으며 모두 피부 건강을 유지하는 데 도움이 되는 선택입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "전문 치료 고려하기",
        content:
          "자가 관리 조치가 효과적이지 않은 경우 화학 박피, 레이저 요법과 같은 전문적인 치료를 고려하세요. 이런 시술은 색소 피부 세포를 제거하고 피부 회춘을 촉진하는 데 도움이 될 수 있습니다. 그러나 전문적인 치료를 받기 전에 피부과 전문의와 상담하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "MC1R 유전자는 피부 세포에서 생성되는 멜라닌의 종류와 양을 결정하는 역할을 합니다. 이 유전자의 변이는 멜라닌 생성을 증가시켜 주근깨나 기미를 증가에 에 영향을 줄 수 있습니다.",
      "IRF4 유전자는 멜라닌 세포의 활성 조절에 관여하며 피부에서 멜라닌 생성을 조절하는 역할을 하는 것으로 생각됩니다. 이 유전자의 변이는 멜라닌 생성 증가와 주근깨 또는 기미에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Sulem, Patrick et al. Nature genetics vol. 39,12 (2007): 1443-52.",
      "Yamaguchi, Kyoko et al. Journal of human genetics vol. 57,11 (2012): 700-8.",
      "Eriksson, Nicholas et al. PLoS genetics vol. 6,6 e1000993. 24 Jun. 2010,",
      "Praetorius, Christian et al. Cell vol. 155,5 (2013): 1022-33.",
    ],
    raceInfo: [
      {
        gene: "MC1R",
        rsId: "rs1805007",
        number: "6,900명",
        race: "유럽인",
      },
      {
        gene: "MC1R",
        rsId: "rs2228479",
        number: "653명",
        race: "일본인",
      },
      {
        gene: "IRF4",
        rsId: "rs12203592",
        number: "97,315명",
        race: "유럽인",
      },
    ],
  },
  Pigmentation: {
    name: "색소침착",
    define:
      "색소침착은 피부 등을 둘러싸고 있는 막에 멜라닌이 증가하여 색이 검은색이나 갈색 등으로 변하는 현상을 말합니다. 유전적 요인, 약물, 외상 및 일광 노출 등 다양한 원인에 의해 발생할 수 있으나, 자외선에 지속적으로 노출되는 외적인 요인과 스트레스, 호르몬 등과 같은 내적인 요인으로 발생할 수 있다고 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 색소침착 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "태양에 노출되면 색소 침착이 악화될 수 있습니다. 유해한 자외선으로부터 피부를 보호하기 위해 SPF가 높은 자외선 차단제를 사용하고 2시간마다 다시 발라주는 것이 좋습니다. 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 유지하기",
        content:
          "과일, 채소 및 통곡물이 풍부한 식단을 섭취하면 피부의 전반적인 건강을 개선하는 데 도움이 될 수 있습니다. 이런 식품에는 피부 손상을 방지하고 건강한 피부를 촉진하는 데 도움이 되는 항산화제가 포함되어 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "국소 치료제 사용하기",
        content:
          "색소 침착을 줄이는 데 도움이 되는 다양한 국소 치료제가 있습니다. 가장 일반적인 치료법으로는 하이드로퀴논, 레티노이드, 코직산 및 비타민 C가 있습니다. 이러한 치료법은 피부의 멜라닌 생성을 감소시키는 작용을 합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "OCA2는 멜라닌 생성에 관여하며 생성되는 멜라닌의 양과 종류를 결정하는 역할을 합니다. OCA2 유전자의 변이는 다양한 멜라닌 생성 수준과 유형을 초래하여 색소 침착에 영향을 줄 수 있습니다.",
      "SLC45A2는 멜라닌 생성에 필요한 물질을 운반하는 단백질을 암호화합니다. SLC45A2 유전자의 변이는 또한 생성되는 멜라닌의 양과 유형에 영향을 미쳐 색소 침착에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Eaton, Katherine et al. American journal of human biology : the official journal of the Human Biology Council vol. 27,4 (2015): 520-5.",
      "Edwards, Melissa et al. PLoS genetics vol. 6,3 e1000867. 5 Mar. 2010,",
      "Stokowski, Renee P et al. American journal of human genetics vol. 81,6 (2007): 1119-32.",
      "Hernandez-Pacheco, Natalia et al. Scientific reports vol. 7 44548. 16 Mar. 2017,",
    ],
    raceInfo: [
      {
        gene: "OCA2",
        rsId: "rs1800414",
        number: "419명",
        race: "동아시아인",
      },
      {
        gene: "SLC45A2",
        rsId: "rs16891982",
        number: "778명",
        race: "남아시아인",
      },
    ],
  },
  Acne: {
    name: "여드름발생",
    define:
      "여드름은 모낭에 있는 피지선에서 발생하는 염증에 의한 것으로 주로 얼굴, 목, 가슴, 어깨 등에 피지선이 많이 분포하기 때문에 그 부위에서 주로 나타납니다. 보통 호르몬 변화가 많은 청소년기에 발생하지만 성인에게도 발생할 수 있으며, 세균 감염, 스트레스, 수면 부족 등의 여러 가지 원인이 작용하는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 여드름발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 챙기기",
        content:
          "가공 식품, 설탕 및 유제품이 많은 식단은 여드름 증상을 악화시킬 수 있습니다. 과일과 채소, 저지방 단백질, 통곡물이 풍부한 식단은 여드름 발병의 심각성을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "스트레스 최소화하기",
        content:
          "높은 스트레스 수준은 여드름 발병을 유발할 수 있는 호르몬의 증가로 이어질 수 있습니다. 심호흡, 요가 또는 명상과 같은 스트레스 관리 기술을 연습하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "피부 청결 유지하기",
        content:
          "여드름 발생을 줄이기 위해 피부를 정기적으로 클렌징하여 먼지, 유분 및 메이크업을 효과적으로 제거하는 것이 중요합니다. 순한 클렌저를 사용하고 강한 스크럽이나 화학 물질을 피해야 추가적인 피부 자극과 건조를 예방할 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FST 유전자는 피부 세포 성장과 테스토스테론을 포함한 호르몬 생산을 촉진하는 성장 인자 생산 조절에 관여합니다. 테스토스테론 수치가 증가하면 피지 생성이 증가하고 모공이 막히며 여드름에 영향을 줄 수 있습니다.",
      "RNASEH2C 유전자는 DNA 복구에 관여하며 피부의 치유 및 손상 회복 능력을 조절하여 여드름에 영향을 미치며, 이는 여드름 흉터 형성에 영향을 줄 수 있습니다.",
      "유전자 TGFB2는 면역 체계와 세포의 성장 및 분화 조절에 관여합니다. TGFB2의 비정상적인 기능은 피부 세포의 성장과 피지 생성을 촉진하여 여드름 발병에 기여할 수 있습니다.",
    ],
    referenceInfo: [
      "Navarini, Alexander A et al. Nature communications vol. 5 4020. 13 Jun. 2014,",
      "Heng, Anna Hwee Sing et al. BMC medical genomics vol. 14,1 103. 13 Apr. 2021,",
    ],
    raceInfo: [
      {
        gene: "FST",
        rsId: "rs38055",
        number: "7,025명",
        race: "유럽인",
      },
      {
        gene: "RNASEH2C",
        rsId: "rs478304",
        number: "1,828명",
        race: "싱가포르인",
      },
      {
        gene: "TGFB2",
        rsId: "rs1159268",
        number: "1,828명",
        race: "싱가포르인",
      },
    ],
  },
  SkinAging: {
    name: "피부노화",
    define:
      "피부노화는 나이가 들면서 점차 피부의 두께가 얇아지고 탄력이 떨어지며 피하 지방과 피부의 부착이 약해져 중력 방향으로 늘어나고 처지며 피지 분비 감소 등으로 건조해지는 종합적인 현상을 의미합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 피부노화 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "자외선 차단제를 매일 바르면 노화를 가속화할 수 있는 자외선으로부터 피부를 보호하는 데 도움이 됩니다. SPF 30 이상의 광범위한 자외선 차단제를 사용하세요. 2시간마다, 특히 수영을 하거나 땀을 흘린 후에는 다시 바르는 것이 중요합니다. 또한, 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "충분한 수분 섭취하기",
        content:
          "물을 충분히 마시면 피부에 수분을 공급하고 주름과 잔주름을 예방할 수 있습니다. 하루에 적어도 8잔의 물을 마시는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "숙면하기",
        content:
          "충분한 수면은 건강한 피부를 유지하는 데 필수적입니다. 수면 부족은 다크 서클과 주름을 형성할 수 있습니다. 매일 밤 7-9시간의 수면을 취하도록 노력하십시오.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "IRF4 유전자는 피부에 색소를 제공하고 자외선으로부터 피부를 보호하는 멜라닌 생성을 조절하여 피부 노화에 영향을 줄 수 있습니다. IRF4 유전자에 변이가 있는 경우 멜라닌의 과잉 생산으로 이어져 과색소침착 및 잠재적으로 조기 피부 노화에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Chhabra, Yash et al. Pigment cell & melanoma research vol. 31,1 (2018): 51-63.",
      "Law, Matthew H et al. The Journal of investigative dermatology vol. 137,9 (2017): 1887-1894.",
    ],
    raceInfo: [
      {
        gene: "IRF4",
        rsId: "rs12203592",
        number: "5,087명",
        race: "호주인",
      },
    ],
  },
  SkinInflammation: {
    name: "피부염증",
    define:
      "피부염증은 피부 바깥층에서 발생하는 염증에 의해 가려움증이나 발진 등이 발생하는 것으로 그 형태와 양상이 다양하며, 원인이 정확하게 밝혀져 있지는 않으나 환경적 요인과 신체적 요인, 면역학적 요인으로 인해서 발생할 수 있다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 피부염증 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "피부 위생 관리하기",
        content:
          "피부를 깨끗하게 유지하고 과도한 유분과 박테리아가 없는 상태를 유지하면 염증 발병을 예방하는 데 도움이 될 수 있습니다. 순한 클렌저로 하루에 두 번 세안하고 강한 스크럽과 알코올 기반 제품을 피하십시오.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "스트레스는 피부 염증을 유발할 수 있으므로 스트레스 수준을 관리하는 방법을 찾는 것이 중요합니다. 스트레스를 줄이는 데 도움이 되는 요가, 명상 또는 심호흡 운동을 고려해 보십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식단에 주의하기",
        content:
          "잎이 많은 채소, 장과, 지방이 많은 생선, 견과류와 같은 항염증 식품이 풍부한 식단을 섭취하면 염증을 줄이는 데 도움이 될 수 있습니다. 가공 식품, 설탕 및 유제품을 피하는 것도 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "PBX2 유전자는 면역 및 염증 반응의 조절에 관여하는 전사 보조 인자를 암호화합니다. PBX2 유전자의 변이는 염증 자극에 반응하는 세포의 능력에 영향을 미쳐 피부 염증을 변화시킬 수 있습니다.",
      "ZNF365 유전자는 염증에 대한 반응뿐만 아니라 세포 증식 및 분화 조절에 관여합니다. ZNF365의 변이는 염증 자극에 반응하는 세포의 능력을 변경하여 피부 염증에 영향을 줄 수 있습니다.",
      "IL18R1 유전자는 염증에 관여하는 사이토카인인 인터루킨 18(IL-18)의 수용체 역할을 하는 단백질을 암호화합니다. IL18R1 유전자의 변이는 IL-18에 반응하는 세포의 능력에 영향을 미쳐 피부 염증을 변화시킬 수 있습니다.",
    ],
    referenceInfo: [
      "Hirota, Tomomitsu et al. Nature genetics vol. 44,11 (2012): 1222-6.",
      "Schaarschmidt, Heidi et al. The Journal of allergy and clinical immunology vol. 136,3 (2015): 802-6.",
    ],
    raceInfo: [
      {
        gene: "PBX2",
        rsId: "rs176095",
        number: "18,320명",
        race: "일본인",
      },
      {
        gene: "ZNF365",
        rsId: "rs10995251",
        number: "18,320명",
        race: "일본인",
      },
      {
        gene: "IL18R1",
        rsId: "rs13015714",
        number: "18,320명",
        race: "일본인",
      },
    ],
  },
  TanningReaction: {
    name: "태양노출후 태닝반응",
    define:
      "태양 노출로 인한 자외선은 피부 속의 멜라닌 색소를 자극하여 피부를 어두운 갈색으로 변화하게 하는데, 이를 인위적으로 하여 피부톤을 어둡게 하는 것을 태닝(Tanning)이라고 합니다. 태닝에 의한 피부 반응은 자외선 세기와 같은 환경적 요인에 영향을 받지만, 개인의 멜라닌 생성 능력이나 양 등에 따라 동일한 수준의 태양노출 후 태닝의 정도가 다를 수 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 태양노출후 태닝반응이 낮은 경향이 있음을 의미합니다. ",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "자외선 차단제를 매일 바르면 노화를 가속화할 수 있는 자외선으로부터 피부를 보호하는 데 도움이 됩니다. SPF 30 이상의 광범위한 자외선 차단제를 사용하세요. 2시간마다, 특히 수영을 하거나 땀을 흘린 후에는 다시 바르는 것이 중요합니다. 또한, 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "수분 섭취하기",
        content:
          "피부를 적절히 보호하려면 물을 충분히 마시고 수분을 지속적으로 유지하는 것이 필요합니다. 수분 섭취를 증가시켜 피부에 필요한 수분과 영양을 공급하면 피부 손상을 최소화하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "TYR 유전자는 피부, 모발 및 눈에 색을 부여하는 색소인 멜라닌 생성에 중요한 티로시나제 효소를 암호화합니다. TYR 유전자의 변이는 생성되는 멜라닌의 양과 유형에 영향을 미쳐 태닝 반응의 차이를 유발할 수 있습니다.",
      "PRDM15 유전자는 피부 색소 침착 조절에 관여합니다. PRDM15 유전자의 변이는 태양 노출에 대한 반응으로 멜라닌 생성을 변경하여 태닝 반응에 영향을 줄 수 있습니다.",
      "CPNE7 유전자는 멜라닌을 생성하고 저장하는 피부 세포의 구조인 멜라노솜의 형성을 제어하여 피부 색소 침착 생성을 조절하는 역할을 합니다. CPNE7 유전자의 변화는 태양 노출에 대한 반응으로 멜라닌 생성을 변경하여 태닝 반응에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Nan, Hongmei et al. The Journal of investigative dermatology vol. 129,9 (2009): 2250-7.",
      "Bonilla, Carolina et al. BMC public health vol. 14 597. 12 Jun. 2014,",
    ],
    raceInfo: [
      {
        gene: "TYR",
        rsId: "rs1393350",
        number: "9,000명",
        race: "미국인(유럽인종)",
      },
      {
        gene: "PRDM15",
        rsId: "rs7279297",
        number: "9,000명",
        race: "미국인(유럽인종)",
      },
      {
        gene: "CPNE7",
        rsId: "rs154659",
        number: "9,000명",
        race: "미국인(유럽인종)",
      },
    ],
  },
  WhiteHair: {
    name: "새치",
    define:
      "새치는 아직 흰색 머리카락이 나지 않을 나이에 본래 색의 머리카락에 섞여 흰색 머리카락이 나는 것으로 자연적 노화와 달리 드문드문 또는 불규칙적으로 나는 것을 말합니다. 원인은 불명이나, 스트레스, 비만, 영양 불균형 등 환경적 요인과 유전적, 신체적 요인 등이 다양하게 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 새치 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "균형 잡힌 식단 섭취하기",
        content:
          "건강한 모발 성장을 촉진하는 데 필요한 모든 영양소를 섭취하는 것이 중요합니다. 여기에는 비오틴, 비타민 C, 철분과 같은 비타민과 미네랄이 포함됩니다. 과일, 채소, 저지방 단백질이 풍부한 균형 잡힌 식단을 섭취하면 머리카락을 건강하게 유지하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "두피 마사지하기",
        content:
          "두피 마사지는 흰머리가 시작되는 속도를 늦출 수도 있습니다. 두피 마사지는 모낭으로의 혈류를 증가시키고 모발 성장을 자극하며 두피와 모발의 전반적인 건강을 향상시킬 수 있다고 믿어집니다. 둥근 빗이나 손가락으로 두피를 마사지하면 모발과 두피의 전반적인 건강을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "운동은 건강한 모발을 유지하는 데 중요한 순환 및 산소 공급을 개선하는 데 도움이 될 수 있습니다. 규칙적인 운동은 또한 스트레스를 줄이고 전반적인 건강을 증진시켜 모발에도 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "IRF4 유전자는 머리카락, 피부 및 눈에 색을 부여하는 색소인 멜라닌의 생성을 조절하는 데 관여합니다. IRF4 유전자의 변이는 생성되는 멜라닌의 양에 영향을 줄 수 있으며, 이는 모발 색상에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Pośpiech, Ewelina et al. BMC genomics vol. 21,1 538. 5 Aug. 2020,",
      "Adhikari, Kaustubh et al. Nature communications vol. 7 10815. 1 Mar. 2016,",
    ],
    raceInfo: [
      {
        gene: "IRF4",
        rsId: "rs12203592",
        number: "6,000명",
        race: "라틴아메리카인",
      },
    ],
  },
  HairLoss: {
    name: "남성형탈모",
    define:
      "남성형탈모는 대게 앞머리와 정수리 위주로 탈모가 발생하거나 모발이 가늘어지는 특징으로 나타나며, 남성 호르몬인 안드로겐이나 유전적 원인에 의해 탈모가 일어나는 것으로 알려져 있습니다.",
    reference: "10",
    heredity: "8~42",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 6종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 남성형탈모 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 유지하기",
        content:
          "영양소가 풍부한 식단, 특히 비타민 A, C, D, E와 같은 모발 건강에 도움이 되는 영양소와 철, 아연, 비오틴과 같은 미네랄은 건강한 모발을 유지하는 데 중요합니다. 모발 건강을 촉진하는 과일과 채소가 풍부한 균형 잡힌 식단을 유지하는 것이 남성형 탈모를 예방하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "스트레스는 남성형 탈모의 일반적인 원인 중 하나로 알려져 있습니다. 탈모 예방을 위해 요가, 명상, 정기적인 운동과 같은 활동을 통해 스트레스를 관리하는 것이 중요합니다. 스트레스 수준을 줄이면 전반적인 건강과 웰빙을 증진시킬 수 있으며, 이는 모발 성장을 촉진하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "의학적 치료 고려하기",
        content:
          "탈모가 걱정된다면 전문의와 상담하는 것이 도움이 될 수 있습니다. 그들은 탈모의 중증도를 평가하고 모발 이식 또는 두피 미세 색소 침착과 같은 적절한 치료를 권장하는 데 도움을 줄 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "Chr20p11 유전자는 모발 성장 조절에 관여하며 유전자의 변이는 모낭 순환에 영향을 미치고 탈모에 기여할 수 있습니다. 남성형탈모의 유전적 영향 인자로 확인되었습니다.",
      "AR 유전자는 테스토스테론과 같은 안드로겐 호르몬에 결합하는 단백질을 암호화함으로써 남성형탈모에서 중요한 역할을 합니다. 유전자의 변이는 모낭이 안드로겐에 얼마나 민감한지에 영향을 미칠 수 있으며, 이는 모발 성장에 영향을 미치고 대머리에 기여할 수 있습니다.",
      "TWIST2 유전자는 모낭 줄기 세포의 발생 및 분화 조절에 관여합니다. 유전자의 변이는 이러한 줄기 세포가 탈모에 기여할 수 있는 새로운 모낭을 생성하는 능력에 영향을 미칠 수 있습니다.",
      "C1orf127 유전자는 여러 유전 연구에서 남성형탈모와 관련이 있습니다. 탈모에 대한 구체적인 역할은 아직 잘 알려져 있지 않지만 모발 성장 및 모낭 순환 조절에 관여할 수 있습니다.",
    ],
    referenceInfo: [
      "Richards, J Brent et al. Nature genetics vol. 40,11 (2008): 1282-4.",
      "Lai, Ching-Huang et al. PloS one vol. 8,12 e79789. 30 Dec. 2013,",
      "Hillmer, Axel M et al. Nature genetics vol. 40,11 (2008): 1279-81.",
      "Liang, Bo et al. PloS one vol. 8,8 e71771. 26 Aug. 2013,",
      "Li, Rui et al. PLoS genetics vol. 8,5 (2012): e1002746.",
      "Brockschmidt, F F et al. The British journal of dermatology vol. 165,6 (2011): 1293-302.",
      "Hagenaars, Saskia P et al. PLoS genetics vol. 13,2 e1006594. 14 Feb. 2017,",
      "Heilmann-Heimbach, Stefanie et al. Nature communications vol. 8 14694. 8 Mar. 2017,",
      "Marcińska, Magdalena et al. PloS one vol. 10,5 e0127852. 22 May. 2015,",
    ],
    raceInfo: [
      {
        gene: "Chr20p11",
        rsId: "rs1160312",
        number: "4,961명",
        race: "유럽인",
      },
      {
        gene: "Chr20p11",
        rsId: "rs2180439",
        number: "990명",
        race: "중국인",
      },
      {
        gene: "AR",
        rsId: "rs2497938",
        number: "12,806명",
        race: "유럽인",
      },
      {
        gene: "TWIST2",
        rsId: "rs11684254",
        number: "52,874명",
        race: "영국인",
      },
      {
        gene: "TWIST2",
        rsId: "rs9287638",
        number: "22,518명",
        race: "유럽인",
      },
      {
        gene: "C1orf127",
        rsId: "rs12565727",
        number: "12,806명",
        race: "유럽인",
      },
    ],
  },
  CircularHairLoss: {
    name: "원형탈모",
    define:
      "원형탈모는 갑자기 원형 또는 타원형으로 모발이 빠지는 특징으로 나타나며, 원인은 확실히 밝혀지지 않았으나 스트레스 등 환경적 요인, 자가면역 질환 등이 영향을 미치는 것으로 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 원형탈모 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "영양이 풍부한 식단 섭취하기",
        content:
          "비타민, 미네랄 및 항산화제가 풍부한 식단을 섭취하면 건강한 모발 성장과 전반적인 건강을 지원하는 데 도움이 될 수 있습니다. 과일, 채소, 기름기 없는 단백질, 통곡물이 풍부한 균형 잡힌 식단을 섭취하면 원형탈모증의 증상을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "모발 관리하기",
        content:
          "원형탈모를 방지하기 위해 머리카락을 부드럽게 다루는 것이 중요합니다. 너무 긴 시간 동안 머리를 꽉 조여놓는 헤어스타일을 피하고, 머리를 부드럽게 빗으며, 빗살이 넓은 빗을 사용하는 것이 좋습니다. 또한, 헤어 드라이어나 다른 열 도구를 사용할 때는 저온 설정을 사용하고 머리카락을 부드럽게 다루는 것이 중요합니다. 이와 같은 습관을 유지하면 모발 건강을 유지하고 모발 성장을 촉진하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "전문의와 상담하기",
        content:
          "원형탈모증에 대한 유전적 소인이 있는 개인은 탈모 징후를 발견하는 즉시 전문적인 의학적 조언을 구하는 것이 중요합니다. 조기 진단 및 치료는 질병의 진행을 늦추고 모발 성장 회복의 기회를 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "IKZF4 유전자는 면역계 기능 조절에 관여하는 유전자입니다. 일부 연구에서는 IKZF4 유전자의 변이가 원형탈모증의 면역 반응에 영향을 미치고 상태의 발달에 기여할 수 있음을 보여주었습니다.",
      "HLA-DQB1 유전자는 자기 세포와 비자기 세포를 구별하는 면역 체계의 능력에 역할을 하는 유전자입니다. HLA-DQB1 유전자의 변이는 원형탈모증과 관련이 있습니다.",
      "IL2RA 유전자는 감염과 염증에 대한 면역 체계의 반응에서 역할을 하는 단백질을 암호화하는 유전자입니다. IL2RA 유전자의 변이는 원형탈모증과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Petukhova, Lynn et al. Nature vol. 466,7302 (2010): 113-7.",
      "Jagielska, Dagny et al. The Journal of investigative dermatology vol. 132,9 (2012): 2192-7.",
      "Jabbari, Ali et al. Dermatologic clinics vol. 31,1 (2013): 109-17.",
      "Petukhova, Lynn, and Angela M Christiano. The Journal of investigative dermatology vol. 136,1 (2016): 314-317.",
    ],
    raceInfo: [
      {
        gene: "IKZF4",
        rsId: "rs1701704",
        number: "4,332명",
        race: "미국인",
      },
      {
        gene: "HLA-DQB1",
        rsId: "rs9275572",
        number: "4,332명",
        race: "미국인",
      },
      {
        gene: "IL2RA",
        rsId: "rs3118470",
        number: "10,796명",
        race: "미국인",
      },
    ],
  },
  HairThickness: {
    name: "모발굵기",
    define:
      "모발굵기는 개별 모발 가닥의 직경으로 모발을 생성하는 피부의 구조인 모낭의 크기에 의하여 결정된다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 모발굵기가 굵은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "영양 개선하기",
        content:
          "비타민과 미네랄, 특히 비오틴, 철분, 비타민 D가 풍부한 식단은 건강한 모발 성장을 촉진하고 모발 두께를 개선할 수 있습니다. 계란, 연어, 그릭 요거트와 같이 단백질이 풍부한 음식을 섭취하는 것도 모발을 강화하고 두께를 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "모발 관리하기",
        content:
          "컨디셔닝 트리트먼트, 볼류마이징 샴푸, 헤어 오일과 같은 제품을 정기적으로 사용하면 모발과 두피에 영양을 공급하고 보호하여 모발 두께를 개선하는 데 도움이 될 수 있습니다. 또한 열 스타일링 도구는 모발을 손상시켜 끊어지고 가늘게 만들 수 있습니다. 열을 사용해야 하는 경우 열 보호 스프레이를 사용하고 온도를 낮게 유지하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "EDAR 유전자는 모발의 두께와 밀도에 중요한 역할을 합니다. 특히, EDAR 유전자의 변이는 모낭의 발달에 영향을 미쳐 모발의 두께와 질감의 차이로 이어질 수 있습니다.",
    ],
    referenceInfo: [
      "Fujimoto, Akihiro et al. Human molecular genetics vol. 17,6 (2008): 835-43.",
      "Riddell, Jon et al. European journal of human genetics : EJHG vol. 28,12 (2020): 1694-1702.",
    ],
    raceInfo: [
      {
        gene: "EDAR",
        rsId: "rs3827760",
        number: "10,640명",
        race: "중국인",
      },
    ],
  },
  MuscleFitness: {
    name: "근력운동적합성",
    define:
      "근력운동적합성이란, 근력운동에 대한 목표와 예상 반응의 관계를 의미합니다. 객관적으로 표준화된 측정 또는 평가방법은 없으며 근력운동능력은 근 섬유의 수, 크기 및 활성화시키는 신경의 능력이나, 나이 및 운동습관 등 다양한 요소에 영향을 받습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 근력운동적합성이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "다른 유형의 운동 같이하기",
        content:
          "근력 운동이 유전적으로 적합하지 않은 경우 건강상의 이점을 제공할 수 있는 다른 유형의 운동이 있습니다. 요가, 필라테스, 수영 또는 자전거 타기와 같은 활동은 근육에 과도한 부담을 주지 않고 지구력, 유연성 및 심혈관 건강을 구축하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "영양에 집중하기",
        content:
          "충분한 단백질과 건강한 지방이 포함된 균형 잡힌 식단을 섭취하는 것은 근육 성장에 필수적입니다. 유전적으로 근육 발달이 좋지 않은 경우 근육이 회복되고 성장할 수 있도록 영양이 풍부한 음식을 섭취하는 데 집중해야 합니다. 크레아틴 일수화물 및 베타알라닌과 같은 보충제도 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "꾸준히 운동하기",
        content:
          "유전적으로 근육 발달이 좋지 않은 경우 처음에는 진행 상황을 확인하기 어려울 수 있습니다. 그러나 근력 운동 프로그램을 일관성 있게 유지하고 인내심을 가진다면 근육 발달은 느릴 수 있지만 시간이 지남에 따라 결과를 볼 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "MTHFR 유전자는 에너지 생산과 세포 성장에 중요한 영양소인 엽산의 대사에 관여하는 효소를 암호화합니다. MTHFR 유전자의 변이는 이 과정의 효율성에 영향을 미쳐 근력 훈련 중에 에너지 수준이 감소하고 근육 피로가 증가할 가능성이 있습니다.",
      "AGT 유전자는 혈압과 체액 균형 조절에 관여합니다. AGT 유전자의 변이는 혈압을 조절하는 호르몬의 전구체인 안지오텐시노겐 생산에 영향을 미칠 수 있습니다. 이는 신체적 스트레스에 반응하고 운동에서 회복하는 신체의 능력에 영향을 미쳐 근력 훈련을 위한 전반적인 체력에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Zarebska, Aleksandra et al. Journal of sports sciences vol. 32,4 (2014): 375-82.",
      "Cięszczyk, Paweł et al. Journal of human kinetics vol. 54 43-53. 15 Dec. 2016,",
      "Gomez-Gallego, Felix et al. Applied physiology, nutrition, and metabolism = Physiologie appliquee, nutrition et metabolisme vol. 34,6 (2009): 1108-11.",
      "Aleksandra, Zarębska et al..” Journal of sports science & medicine vol. 15,4 616-624. 1 Dec. 2016",
    ],
    raceInfo: [
      {
        gene: "MTHFR",
        rsId: "rs1801131",
        number: "2,684명",
        race: "폴란드인, 러시아인",
      },
      {
        gene: "AGT",
        rsId: "rs699",
        number: "211명",
        race: "폴란드인",
      },
    ],
  },
  ExerciseFitness: {
    name: "지구력운동적합성",
    define:
      "지구력운동적합성이란, 지구력운동에 대한 목표와 예상 반응의 관계를 의미합니다. 객관적으로 표준화된 측정 또는 평가방법은 없으며 지구력운동능력은 이용할 수 있는 산소의 최대량과 산소 운반에 도움을 주는 근 섬유발달 능력과 나이, 운동습관 등 다양한 요소에 영향을 받습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 지구력운동적합성이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "인터벌 트레이닝 수행하기",
        content:
          "인터벌 트레이닝은 고강도 운동 기간과 휴식 또는 저강도 운동 기간을 번갈아 가며 하는 운동 유형입니다. 연구에 따르면 인터벌 트레이닝은 유전적으로 지구력 운동에 적합하지 않은 사람들의 경우에도 지구력을 향상시키고 심혈관 시스템의 효율성을 높일 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "근력 운동에 집중하기",
        content:
          "지구력 운동이 당신의 강점은 아닐 수 있지만 근력 운동은 여전히 ​​많은 건강상의 이점을 제공하고 전반적인 체력 수준을 향상시킬 수 있습니다. 사실, 연구에 따르면 근력 운동은 근육의 효율성을 높여 지구력을 향상시킬 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "영양 및 회복에 집중하기",
        content:
          "지구력 운동은 특히 신체에 부담이 될 수 있으므로 적절한 영양 및 회복에 집중하는 것이 중요합니다. 운동에 연료를 공급하기에 충분한 단백질, 탄수화물 및 건강한 지방을 섭취하고 있는지 확인하고, 운동 사이에 몸이 회복되고 재건될 수 있도록 휴식과 회복을 우선시하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "HIF1A 유전자는 낮은 산소 수준에 대한 신체의 반응을 조절하는 데 도움이 되는 유전자입니다. 이 유전자는 강렬한 신체 활동 중에 낮은 산소 수준에 적응하는 신체의 능력에 영향을 미침으로써 지구력운동적합성과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Döring, Frank et al. Journal of applied physiology (Bethesda, Md. : 1985) vol. 108,6 (2010): 1497-500.",
    ],
    raceInfo: [
      {
        gene: "HIF1A",
        rsId: "rs11549465",
        number: "620명",
        race: "백인",
      },
    ],
  },
  AerobicExercise: {
    name: "유산소운동적합성",
    define:
      "유산소운동적합성이란 유산소운동에 대한 목표와 예상 반응의 관계를 의미합니다. 객관적으로 표준화된 측정 또는 평가방법은 없으며 유산소운동능력은 개인의 심폐 능력이나 산소 운반 능력, 최대 산소 섭취량 등의 신체적 특징이나, 나이, 운동 습관 등 다양한 요소에 영향을 받습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 유산소운동적합성이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "다른 지구력 운동 고려하기",
        content:
          "장거리 달리기나 자전거 타기와 같은 전통적인 지구력 운동은 어려울 수 있지만 유산소운동적합성이 낮은 개인에게 더 적합한 대체 운동이 있습니다. 예를 들어 수영은 충격이 적고 전신 운동을 제공할 수 있으므로 훌륭한 대안이 될 수 있습니다. 걷기나 하이킹도 몸에 무리한 스트레스를 주지 않고 심혈관 건강을 개선하는 데 효과적인 운동이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "근력 운동에 집중하기",
        content:
          "지구력 운동이 중요하지만 근력 운동은 유산소운동적합성이 낮은 개인에게도 도움이 될 수 있습니다. 근육을 키우면 전반적인 체력이 향상될 수 있으며, 연구에 따르면 근력 운동은 유산소 능력을 향상시킬 수 있습니다. 스쿼트, 런지, 데드리프트, 벤치 프레스와 같이 여러 근육 그룹에 작용하는 복합 운동을 통합하면 심혈관 건강을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "VEGF-A 유전자는 혈관 성장 및 혈관 신생에 중요한 성장 인자를 암호화합니다. VEGF-A의 변화는 혈류와 근육으로의 산소 전달에 영향을 미쳐 유산소 운동 성능에 잠재적으로 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Ahmetov, Ildus I et al. European journal of applied physiology vol. 107,1 (2009): 95-103.",
    ],
    raceInfo: [
      {
        gene: "VEGF-A",
        rsId: "rs1870377",
        number: "1,074명",
        race: "러시아인",
      },
    ],
  },
  ShortDistanceRunning: {
    name: "단거리질주능력",
    define:
      "단거리질주능력은 짧은 거리를 전속력으로 달리는 능력을 의미합니다. 객관적으로 표준화된 측정 또는 평가 방법은 없으나 순간적인 속도를 위해 강하고 빠른 근 수축과 기술, 폭발적인 힘을 내도록 돕는 근 섬유의 능력과 함께 순발력과 심폐지구력 등에 영향을 받습니다.",
    reference: "6",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 단거리질주능력이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "근력 운동하기",
        content:
          "근력 운동은 단거리 달리기에 필요한 근력과 폭발력을 개발하는 데 도움이 될 수 있습니다. 스쿼트, 데드리프트, 런지와 같은 운동은 하체의 근력과 힘을 향상시켜 전력 질주 능력을 향상시킬 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "기술 훈련하기",
        content:
          "단거리 질주 기술 훈련은 최적 보폭, 팔 스윙, 신체 위치 유지 등을 포함합니다. 적절한 보폭은 빠른 발걸음과 가속을 가능하게 하며, 팔 스윙은 균형과 안정성을 제공합니다. 몸의 기울임과 무게 분배를 조절하여 신체 위치를 최적화하여 안정성을 유지해야 합니다. 이러한 기술 훈련은 유전적 소인과 상관없이 단거리질주능력을 향상 시키는데 도움이 됩니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "SOD2 유전자는 산화 스트레스로부터 세포를 보호하는 데 도움이 되는 항산화 효소를 암호화합니다. 이 유전자의 변이는 전력 질주 능력에 영향을 미칠 수 있는 근지구력 및 출력의 차이와 관련이 있습니다.",
      "ACTN3 유전자는 백색근 섬유에서 발견되는 단백질을 암호화합니다. 이 섬유는 전력 질주와 같은 폭발적인 고강도 운동에 중요합니다. 이 유전자의 특정 변이를 가진 개인은 해당 단백질이 감소할 수 있으며 전력 질주에 필요한 힘을 생성할 수 없을 수 있습니다.",
      "AMPD1 유전자는 에너지 대사에서 역할을 하는 효소를 암호화합니다. 이 유전자의 변이는 전력질주 능력에 영향을 미칠 수 있는 근육 대사 및 지구력의 차이와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Ahmetov, I I et al. Free radical research vol. 48,8 (2014): 948-55.",
      "Weyerstraß, Jan et al. Journal of science and medicine in sport vol. 21,2 (2018): 213-220.",
      "Baumert, Philipp et al. European journal of applied physiology vol. 116,9 (2016): 1595-625.",
      "Yang, Ruoyu et al. Journal of strength and conditioning research vol. 31,4 (2017): 1107-1115.",
      "Ginevičienė, Valentina et al. BMC genetics vol. 15 58. 17 May. 2014,",
      "Murtagh, Conall F et al. PloS one vol. 15,6 e0234458. 22 Jun. 2020,",
    ],
    raceInfo: [
      {
        gene: "SOD2",
        rsId: "rs4880",
        number: "3,581명",
        race: "백인",
      },
      {
        gene: "ACTN3",
        rsId: "rs1815739",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "AMPD1",
        rsId: "rs17602729",
        number: "686명",
        race: "영국인",
      },
    ],
  },
  RecoverAfterExercising: {
    name: "운동후회복능력",
    define:
      "운동후회복능력은 운동으로 인해 야기된 신체 내 심박수 증가, 심폐 기능, 근육 조직 등의 피로 등의 상태를 운동 전의 상태로 회복하는 능력을 의미합니다. 객관적으로 표준화된 측정 또는 평가 방법은 없으나 개인의 건강 상태 및 운동 습관 등에 종합적으로 영향을 받습니다.",
    reference: "5",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 운동후회복능력이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "운동 강도 및 빈도 조정하기",
        content:
          "회복 능력이 낮은 경우은 적절한 회복 시간을 허용하기 위해 운동 강도 및 빈도를 조정해야 할 수 있습니다. 여기에는 고강도 운동의 빈도 감소, 더 많은 저강도 운동 통합 또는 운동 사이에 휴식을 취하는 것이 포함될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "폼 롤링 및 스트레칭 하기",
        content:
          "폼 롤링 및 스트레칭은 근육으로의 혈류를 증가시키고 회복을 촉진하는 효과적인 기술입니다. 이러한 기술을 운동 루틴에 포함하므로써 근육통을 줄이고 전반적인 유연성과 운동 범위를 개선하는 데 도움을 줄 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "보충제 고려하기",
        content:
          "오메가-3 지방산, 크레아틴 및 BCAA(분지쇄 아미노산)와 같은 특정 보충제는 근육 회복 및 복구에 도움이 되는 것으로 나타났습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "RBPMS 유전자는 근육 성장 및 복구와 관련된 다른 유전자의 발현 조절에 관여합니다. 이 유전자의 변이는 운동 후 근육 섬유가 복구되고 성장하는 속도에 영향을 줄 수 있습니다.",
      "YWHAQ 유전자는 단백질 활동 및 수송 조절을 포함하여 다양한 세포 과정에 관여합니다. 일부 연구에서는 이 유전자의 변이가 운동 후 근육 손상 및 염증과 관련이 있을 수 있다고 제안합니다.",
      "CCDC141 유전자는 운동 후 근육 회복에 필요한 새로운 혈관 형성에 관여합니다. 이 유전자의 변이는 새로운 혈관이 형성되고 근육 섬유가 복구되는 속도에 영향을 줄 수 있습니다.",
      "BCAT1 유전자는 운동 후 근육 회복에 중요한 분지 사슬 아미노산의 대사에 관여합니다. 이 유전자의 변이는 근육 회복을 위한 이러한 아미노산의 가용성에 영향을 줄 수 있습니다.",
      "GSTP1 유전자는 체내 유해 물질의 해독에 관여합니다. 이 유전자의 변이는 운동 중에 생성된 유해 물질을 해독하는 신체의 능력에 영향을 미쳐 근육 회복에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Verweij, Niek et al. Nature communications vol. 9,1 898. 1 Mar. 2018,",
      "Zarebska, Aleksandra et al. Physiological genomics vol. 49,3 (2017): 127-131.",
    ],
    raceInfo: [
      {
        gene: "CCDC141",
        rsId: "rs17362588",
        number: "58,818명",
        race: "영국인",
      },
      {
        gene: "BCAT1",
        rsId: "rs4963772",
        number: "58,818명",
        race: "영국인",
      },
      {
        gene: "GSTP1",
        rsId: "rs1695",
        number: "2,263명",
        race: "러시아인, 폴란드인",
      },
    ],
  },
  MuscleDevelopment: {
    name: "근육발달능력",
    define:
      "근육발달능력은 근섬유의 굵기 증가와 근력 및 활동량 증가 등으로 발휘되는 근력의 발달하는 능력을 의미합니다. 객관적으로 표준화된 측정 또는 평가 방법은 없으나 단백질 합성 능력이나, 운동량, 생활습관 및 환경적 요인 등에 영향을 받습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 근육발달능력이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "복합 운동에 집중하기",
        content:
          "스쿼트, 데드리프트, 벤치 프레스와 같은 복합 운동은 한 번에 여러 근육 그룹을 대상으로 하며 근육 성장을 최대화하는 데 도움이 될 수 있습니다. 이러한 운동을 균형 잡힌 운동 루틴에 포함하면 전체 근육량을 늘리는 효과적인 방법이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적절한 영양 섭취하기",
        content:
          "적절한 영양은 근육 성장에 매우 중요합니다. 충분한 단백질, 탄수화물 및 건강한 지방을 섭취하면 근육 회복 및 성장을 지원할 수 있습니다. 또한 수분을 유지하고 영양이 풍부한 식품으로 몸에 연료를 공급하면 운동 중 에너지 수준과 성능을 최적화하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "꾸준히 운동하기",
        content:
          "일관성은 근육을 발달시킬 때 핵심입니다. 적절한 양(즉, 수행되는 세트 및 반복 횟수)으로 정기적으로 저항 훈련 운동을 수행하면 시간이 지남에 따라 근육이 성장할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "AGT 유전자는 혈압과 체액 균형 조절에 관여하는 안지오텐시노겐이라는 단백질을 암호화합니다. AGT 유전자의 변이는 근육량과 근력의 차이와 관련이 있습니다.",
      "VCAN 유전자는 연골, 피부 및 혈관과 같은 조직의 형성 및 유지에 관여하는 베르시칸이라는 단백질을 암호화합니다. VCAN 유전자의 변이는 근육량 및 근력의 차이와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Gomez-Gallego, Felix et al. Applied physiology, nutrition, and metabolism = Physiologie appliquee, nutrition et metabolisme vol. 34,6 (2009): 1108-11.",
      "Weyerstraß, Jan et al. Journal of science and medicine in sport vol. 21,2 (2018): 213-220.",
      "Karasik, David et al. The American journal of clinical nutrition vol. 109,2 (2019): 276-287.",
      "Zillikens, M Carola et al. Nature communications vol. 8,1 80. 19 Jul. 2017,",
    ],
    raceInfo: [
      {
        gene: "AGT",
        rsId: "rs699",
        number: "19,852명",
        race: "다인종",
      },
      {
        gene: "VCAN",
        rsId: "rs2287926",
        number: "38,292명",
        race: "유럽인",
      },
    ],
  },
  AnkleInjury: {
    name: "발목부상위험도",
    define:
      "발목부상위험도는 신체 지탱에서 중요한 역할을 하는 발목이 동일한 부상의 원인으로 인하여 실제 부상으로 이어지는 위험 정도를 의미합니다.객관적으로 표준화된 측정 또는 평가방법은 없습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 발목부상위험도가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "발목 근육 강화하기",
        content:
          "발목 부상을 예방하는 가장 좋은 방법 중 하나는 발목 관절 주변의 근육을 강화하는 것입니다. 강화 운동은 균형과 안정성을 향상시켜 부상 위험을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "올바른 신발 착용하기",
        content:
          "올바른 신발을 신는 것도 발목 부상을 예방하는 데 도움이 됩니다. 발목 지지력과 쿠션이 좋은 신발은 충격을 흡수하고 발이 구르거나 비틀리는 것을 방지하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "저강도 운동 찾기",
        content:
          "수영이나 자전거 타기와 같은 관절에 부담이 적은 운동은 발목 부상 위험을 줄이는 데 매우 효과적입니다. 이러한 운동은 발목에 과도한 스트레스를 줄이면서도 전신 근력과 유연성을 향상시키고, 심혈관 건강을 증진시키는 데도 도움이 됩니다. 특히, 물에서의 운동은 관절에 부담을 덜 주면서도 근력을 강화하고 유지할 수 있는 장점이 있습니다. ",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "ACTN3 유전자는 근육 섬유에서 발견되는 단백질을 암호화하는 유전자입니다. 연구에 따르면 해당 단백질의 발현이 낮으면 발목 부상 위험이 더 높은 것으로 나타났습니다. 이는 근육 섬유의 구조적 안정성에 역할을 하고 부상으로부터 보호하는 데 도움이 되기 때문입니다.",
    ],
    referenceInfo: [
      "Shang, Xuya et al. Journal of sports sciences vol. 33,17 (2015): 1775-9.",
      "Kim, Jun Ho et al. Journal of exercise nutrition & biochemistry vol. 18,2 (2014): 205-14.",
    ],
    raceInfo: [
      {
        gene: "ACTN3",
        rsId: "rs1815739",
        number: "300명",
        race: "한국인",
      },
    ],
  },
  SensitivitySweet: {
    name: "단맛민감도",
    define:
      "단맛민감도란 단맛을 인지하는 정도를 나타낸다. 낮은 역치는 높은 민감도를 의미합니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 단맛민감도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "설탕 섭취량 줄이기",
        content:
          "일일 설탕 섭취량을 제한하면 단맛에 대한을 욕망을 줄이고 단맛에 대한 민감성을 개선하는 데 도움이 될 수 있습니다. 식단에서 설탕의 양을 점차적으로 줄임으로써 미각은 과일 및 기타 건강한 소스에서 자연적인 단맛을 인식하도록 개선될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "균형있는 식단 유지하기",
        content:
          "식단에 더 많은 섬유질과 건강한 지방을 포함하면 배고픔을 조절하고 단맛에 대한 민감도를 개선하는 데 도움이 될 수 있습니다. 균형 잡힌 식단을 섭취하면 혈당 수치를 조절하고 포만감을 촉진하여 단 음식에 대한 욕구를 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "더 많은 단백질 섭취하기",
        content:
          "더 많은 단백질을 섭취하면 배고픔을 조절하고 단맛에 대한 민감도를 높일 수 있습니다. 단백질이 풍부한 음식을 먹으면 포만감을 촉진하여 단 음식, 고칼로리 음식에 대한 욕구를 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FGF21은 단맛 감수성과 관련된 호르몬을 암호화 합니다. FGF21 유전자의 변이는 사람이 다른 음식과 음료를 얼마나 달콤하게 인식하는지에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Janzi, Suzanne et al. Nutrients vol. 13,11 3954. 5 Nov. 2021,",
    ],
    raceInfo: [
      {
        gene: "FGF21",
        rsId: "rs838133",
        number: "22,794명",
        race: "스웨덴인",
      },
    ],
  },
  SensitivityBitter: {
    name: "쓴맛민감도",
    define:
      "쓴맛민감도란 쓴맛을 인지하는 정도를 나타낸다. 낮은 역치는 높은 민감도를 의미합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 쓴맛민감도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "흡연량 줄이기",
        content:
          "담배를 피우면 쓴 맛에 대한 민감도에 영향을 미칠 수 있는 니코틴을 포함한 유해 화학 물질에 대한 노출이 증가합니다. 금연은 이러한 유해한 화학 물질에 대한 노출을 줄이고 쓴맛에 대한 민감성을 개선하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "음주량 줄이기",
        content:
          "알코올 소비를 줄이거나 알코올 함량이 낮은 음료로 전환하면 쓴맛에 대한 민감도를 개선하는 데 도움이 될 수 있습니다. 알코올은 또한 쓴 맛을 느끼는 능력을 포함하여 전반적인 미각 인식에 부정적인 영향을 미칠 수 있으므로 적당히 조절하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "카페인 줄이기",
        content:
          "유전적으로 쓴맛에 대한 민감성이 낮은 경우 커피 섭취를 줄이면 카페인의 부정적인 영향을 줄이는 데 도움이 될 수 있습니다. 커피 섭취량을 하루에 한두 잔으로 제한하고 공복에 마시는 것을 피하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TAS2R19 유전자는 음식에서 쓴맛 화합물을 감지하는 데 관여하는 쓴맛 수용체를 암호화합니다. TAS2R19 유전자의 변이는 쓴맛민감도의 차이와 관련이 있습니다.",
      "TAS2R38 유전자는 음식에서 쓴맛 화합물을 감지하는 데 관여하는 쓴맛 수용체를 암호화합니다. TAS2R19 유전자의 변이는 쓴맛민감도의 차이와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Chamoun, Elie et al. Critical reviews in food science and nutrition vol. 58,2 (2018): 194-207.",
      "Graham, Catherine Anna-Marie et al. Chemical senses vol. 46 (2021): bjab029.",
      "Reed, Danielle R et al. Human molecular genetics vol. 19,21 (2010): 4278-85.",
      "Hwang, Liang-Dar et al. BMC genomics vol. 19,1 678. 17 Sep. 2018,",
    ],
    raceInfo: [
      {
        gene: "TAS2R38",
        rsId: "rs713598",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "TAS2R38",
        rsId: "rs10246939",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "TAS2R38",
        rsId: "rs1726866",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "TAS2R19",
        rsId: "rs10772420",
        number: "1,999명",
        race: "백인",
      },
    ],
  },
  SensitivitySalty: {
    name: "짠맛민감도",
    define:
      "짠맛민감도란 짠맛을 인지하는 정도를 나타낸다. 낮은 역치는 높은 민감도를 의미합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 짠맛민감도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "소금 섭취 줄이기",
        content:
          "섭취하는 소금의 양을 제한하면 소금 맛에 대한 전반적인 민감도를 낮추는 데 도움이 될 수 있습니다. 염분이 많은 가공 식품을 피하고 대신 신선한 재료를 선택하십시오.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 많이 마시기",
        content:
          "물을 마시면 신체에서 과도한 염분을 제거하는 데 도움이 될 수 있으며, 이는 소금 맛에 대한 민감도를 낮추는 데 도움이 될 수 있습니다. 하루에 적어도 8잔의 물을 마시는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "균형 잡힌 식단 섭취하기",
        content:
          "신선한 과일과 채소, 저지방 단백질, 통곡물을 포함하는 균형 잡힌 식단을 일상에 통합하면 전반적인 소금 섭취를 줄이고 짠 음식의 과다 섭취를 예방할 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TRPV1은 일시적 수용체 전위 바닐로이드 1이라는 단백질을 코딩하는 유전자입니다. 이 단백질은 다른 맛과 온도뿐만 아니라 염분에 대한 민감성을 조절하는 역할을 합니다. TRPV1 유전자의 변이는 단백질 발현에 영향을 미쳐 짠맛민감도의 차이를 유발할 수 있습니다. ",
    ],
    referenceInfo: [
      "Dias, Andre G et al. Chemical senses vol. 38,2 (2013): 137-45.",
      "Chamoun, Elie et al. Critical reviews in food science and nutrition vol. 58,2 (2018): 194-207.",
    ],
    raceInfo: [
      {
        gene: "TRPV1",
        rsId: "rs8065080",
        number: "95명 / 95명",
        race: "캐나다인 / 영국인",
      },
    ],
  },
  FeelingFull: {
    name: "포만감",
    define:
      "포만감은 음식 섭취 후 느끼는 배가 부른 느낌으로 충분히 차서 만족스러운 느낌을 말합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 포만감이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 운동은 배고픔과 포만감을 조절하는 뇌의 뉴런 감도를 향상시키는 것으로 나타났습니다. 이것은 포만감이 낮은 개인이 식사 후 더 만족감을 느끼고 과식을 줄이는 데 도움이 될 수 있습니다. 또한 운동과 균형 잡힌 식단을 결합하면 보다 지속 가능하고 효과적인 체중 감량으로 이어질 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "규칙적인 식사하기",
        content:
          "평소 식사를 자주 건너뛰거나 굶는 다이어트를 지속할 경우 뇌신경을 더 강하게 자극하여 다량의 그렐린이 분비되게 됩니다. 그렇게 되면 식사량을 조절하기 힘들게 되어 과식이나 폭식을 유발하는 요인이 됩니다. 식사를 할 경우, 되도록 천천히 먹는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "충분한 수면 취하기",
        content:
          "수면 부족은 렙틴 수치를 방해하여 과식과 체중 증가로 이어질 수 있습니다. 적절한 호르몬 균형을 유지하기 위해 매일 밤 최소 7시간의 수면을 목표로 하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "LEPR 유전자는 음식 섭취를 줄이고 에너지 소비를 늘리도록 뇌에 신호를 보내 에너지 균형을 조절하는 단백질인 렙틴 수용체를 암호화합니다. 이 유전자의 돌연변이는 렙틴 수용체의 기능에 영향을 미쳐 렙틴 효과에 대한 저항성을 유발하고 결과적으로 식욕과 포만감에 영향을 줄 수 있습니다.",
      "LEP 유전자는 지방 세포에서 분비되고 신체의 에너지 상태에 대해 뇌에 신호를 보내는 렙틴 호르몬을 암호화합니다. 이 유전자의 돌연변이는 렙틴의 생산 또는 기능에 영향을 미쳐 비만과 포만감에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Furusawa, Takuro et al. Human genetics vol. 127,3 (2010): 287-94.",
      "Kilpeläinen, Tuomas O et al. Nature communications vol. 7 10494. 1 Feb. 2016,",
      "Dallner, Olof S et al. Nature medicine vol. 25,3 (2019): 507-516.",
    ],
    raceInfo: [
      {
        gene: "LEPR",
        rsId: "rs1137101",
        number: "745명",
        race: "오스트로네이사어족",
      },
      {
        gene: "LEP",
        rsId: "rs10487505",
        number: "52,140명",
        race: "유럽계",
      },
    ],
  },
};

const dtcDBDescription = {
  VitaminC: {
    name: "비타민 C 농도",
    define:
      "비타민 C는 대표적인 수용성 비타민의 하나로 아스코로브산(Ascorbic acid)이라고도 합니다. 콜라겐 합성이나 에너지 대사에 관여하며, 화학 구조 상 산의 성질을 가지고 있어 항산화 작용을 하는 강력한 항산화제로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 C 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "비타민 C는 감귤류, 딸기류, 키위, 파파야, 브로콜리, 피망, 케일 등 다양한 음식에서 발견됩니다. 이러한 음식을 포함하는 다양한 식단을 섭취하면 일일 비타민 C 요구량을 충족하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "식단을 통해 충분히 섭취하는 데 어려움이 있는 경우 비타민 C 섭취를 늘리는 효과적인 방법이 될 수 있습니다. 그러나 소화 문제 및 기타 부작용을 일으킬 수 있는 고용량의 비타민 C 보충제를 복용하지 않는 것이 가장 좋습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "SLC23A1 유전자는 비타민 C를 세포로 능동적으로 수송하는 역할을 하는 단백질을 암호화합니다. 연구에 따르면 SLC23A1 유전자의 변이는 신체의 비타민 C 흡수 및 분포에 영향을 미쳐 개인마다 비타민 C 수준의 차이를 유발할 수 있습니다.",
    ],
    referenceInfo: [
      "Duell, Eric J et al. Genes & nutrition vol. 8,6 (2013): 549-60.",
      "Timpson, Nicholas J et al. The American journal of clinical nutrition vol. 92,2 (2010): 375-82.",
    ],
    raceInfo: [
      {
        gene: "SLC23A1",
        rsId: "rs33972313",
        number: "15,087명",
        race: "유럽인",
      },
    ],
  },
  VitaminD: {
    name: "비타민 D 농도",
    define:
      "비타민 D는 지용성 비타민 중 하나로, 체내 칼슘 농도의 항상성과 뼈 건강 유지, 면역 기능 등에 관여합니다고 알려져 있습니다. 식품을 통해 섭취되지만 피부 세포에서 햇빛 속 자외선을 받아 체내에서 합성되기 때문에 일상적인 야외 활동에서 합성할 수 있습니다. 과잉 섭취 시 구토, 설사, 고칼슘혈증 등이 나타날 수 있습니다.",
    reference: "8",
    heredity: "10",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 5종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 D 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "기름진 생선, 달걀 노른자, 버섯과 같이 비타민 D가 풍부한 식품을 섭취하면 비타민 D 수치를 높이는 데 도움이 될 수 있습니다. 이러한 음식은 신체에 쉽게 흡수되는 활성 형태의 비타민이 많기 때문에 비타민 D의 좋은 공급원입니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "햇볕 쬐기",
        content:
          "비타민 D를 얻는 가장 자연스러운 방법은 햇빛을 통해서 얻는 것 입니다. 태양의 자외선 B(UVB) 광선은 피부에서 발견되는 콜레스테롤 형태를 비타민 D로 변환합니다. 과도한 태양 노출은 피부 손상을 유발할 수 있으므로 특히 피크 시간대 (오전 10시 ~ 오후 2시)에는 태양 노출에 주의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 D 보충제는 비타민 D 섭취량이 적거나 햇빛 노출이 제한된 사람들에게 특히 유용할 수 있습니다. 비타민 D 보충제는 D2 및 D3를 포함하여 여러 형태로 제공됩니다. 비타민 D3는 신체가 자연적으로 생성하는 비타민 D의 형태와 가장 유사한 형태입니다. 흡수율을 높이기 위해 지방이 포함된 식사와 함께 비타민 D 보충제를 섭취하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "GC 유전자는 비타민 D 결합 단백질을 암호화하며, 이는 혈액에서 비타민 D와 그 대사 물질을 운반하는 역할을 합니다. GC 유전자의 변이는 해당 단백질에 영향을 미칠 수 있으므로 신체의 비타민 D 가용성에 영향을 미칩니다.",
      "CYP24A1 유전자는 비타민 D의 활성 형태(1,25-디하이드록시비타민 D)를 비활성 형태로 분해하는 역할을 하는 효소를 암호화합니다. 이 유전자의 변이는 비타민 D 분해 속도에 영향을 미치므로 체내에서 이용 가능한 활성 비타민 D의 양에 영향을 미칠 수 있습니다.",
      "NADSYN1 유전자는 비타민 D 대사에 필요한 코엔자임 니코틴아미드 아데닌 디뉴클레오티드(NAD+)의 합성에 관여하는 효소를 암호화합니다. 이 유전자의 변이는 NAD+ 생성에 영향을 줄 수 있으므로 비타민 D 대사에 영향을 미칩니다.",
      "CYP2R1 유전자는 비타민 D를 활성 형태로 전환하는 역할을 하는 비타민 D 25-하이드록실라제라는 효소를 암호화합니다. 이 유전자의 변이는 비타민 D 활성화 속도에 영향을 미칠 수 있으므로 신체에서 이용 가능한 활성 비타민 D의 양에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Hong, Jaeyoung et al. The Journal of clinical endocrinology and metabolism vol. 103,4 (2018): 1380-1392.",
      "Lafi, Zainab M et al. Genetic testing and molecular biomarkers vol. 19,11 (2015): 629-36.",
      "Revez, Joana A et al. Nature communications vol. 11,1 1647. 2 Apr. 2020,",
      "Sinnott-Armstrong, Nasa et al. Nature genetics vol. 53,2 (2021): 185-194.",
      "Ahn, Jiyoung et al. Human molecular genetics vol. 19,13 (2010): 2739-45.",
      "Jiang, Xia et al. Nature communications vol. 9,1 260. 17 Jan. 2018,",
      "Duan, Leizhen et al. Gene vol. 678 (2018): 361-369.",
    ],
    raceInfo: [
      {
        gene: "GC",
        rsId: "rs7041",
        number: "28,150명",
        race: "다인종",
      },
      {
        gene: "GC",
        rsId: "rs2282679",
        number: "4,501명",
        race: "유럽인",
      },
      {
        gene: "CYP24A1",
        rsId: "rs17216707",
        number: "79,366명",
        race: "유럽인",
      },
      {
        gene: "NADSYN1",
        rsId: "rs11234027",
        number: "363,228명",
        race: "영국인",
      },
      {
        gene: "CYP2R1",
        rsId: "rs10741657",
        number: "79,366명",
        race: "유럽계",
      },
    ],
  },
  VitaminA: {
    name: "비타민 A 농도",
    define:
      "비타민A는 지용성 비타민 중 하나로 생물의 성장과 발달, 눈 건강과 피부 건강 등에 관여합니다. 식품을 통해 섭취해야 하나 많이 섭취하게 되면 과잉증으로 세포막의 안전성 저해, 간 조직 손상, 기형아 출산 및 골격 약화 등의 부작용이 나타날 수 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 A 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "고구마, 당근, 시금치, 케일, 호박과 같은 비타민 A가 풍부한 음식을 섭취하면 체내 비타민 A 수치를 높이는 데 도움이 될 수 있습니다. 이러한 식품에는 신체가 비타민 A의 활성 형태로 전환할 수 있는 비타민 A의 일종인 카로티노이드가 풍부합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 A 보충제는 식품으로 비타민 A 섭취량을 늘리기 어려운 사람들에게 특히 유용할 수 있습니다. 비타민 A 보충제는 레티놀 및 베타카로틴과 같은 다양한 형태로 제공됩니다. 레티놀은 신체에서 쉽게 사용할 수 있는 비타민 A의 한 형태로 보충제 선택시 참고하면 좋습니다. 그러나 고용량의 비타민 A 보충제를 복용하는 것은 해로울 수 있으므로 보충제를 복용하기 전에 항상 의료 전문가와 상담하는 것이 가장 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "FFAR4 유전자는 소장과 간에서 레티노이드(비타민 A 및 그 유도체)의 결합 및 섭취에 관여하는 단백질을 암호화합니다. 신체의 비타민 A 항상성을 유지하는 역할을 하는 것으로 여겨집니다.",
      "BCO1 유전자는 식이성 베타카로틴을 레티노이드로 전환시키는 데 관여하는 효소를 암호화합니다. 이 과정은 신체의 적절한 비타민 A 수치를 유지하는 데 중요합니다.",
    ],
    referenceInfo: [
      "Hendrickson, Sara J et al. The American journal of clinical nutrition vol. 96,6 (2012): 1379-89.",
      "Ferrucci, Luigi et al. American journal of human genetics vol. 84,2 (2009): 123-33.",
      "Mondul, Alison M et al. Human molecular genetics vol. 20,23 (2011): 4724-31.",
      "Suzuki, Masako et al. Nutrients vol. 13,6 1743. 21 May. 2021,",
    ],
    raceInfo: [
      {
        gene: "BCO1",
        rsId: "rs6564851",
        number: "2,344명",
        race: "유럽인",
      },
      {
        gene: "FFAR4",
        rsId: "rs10882272",
        number: "4,082명",
        race: "미국인(다양한 인종)",
      },
    ],
  },
  VitaminB6: {
    name: "비타민 B6 농도",
    define:
      "비타민 B6는 수용성 비타민인 비타민 B군의 피리독신으로, 아미노산 대사의 주요 조효소로 생체 내 면역계나 대사과정 등에 광범위 하게 관여하는 영양소로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 B6 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "적절한 비타민 B6 섭취하는 가장 좋은 방법은 가금류, 생선, 통곡물, 콩류 및 견과류와 같은 비타민 B6가 풍부한 식품을 포함하는 다양하고 균형 잡힌 식단 먹는 것입니다. 이러한 음식을 포함하는 다양한 식단을 섭취하면 자연스럽게 비타민 B6를 충분히 섭취할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 B6 보충제는 식단을 통해 적절한 양의 비타민을 섭취하기 어려운 개인에게 유용할 수 있습니다. 잠재적인 부작용이나 독성을 피하기 위해 보충제 제조업체 또는 의료 제공자가 제공한 권장 복용량 지침을 따르는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "NBPF3 유전자는 신체에서 비타민 B6 제거와 관련된 것으로 밝혀진 호르몬인 NBPF3의 합성과 관련이 있습니다. 해당 유전자의 변이된 그룹은 혈장 PLP (Vitamin B6 활성상태) 수준이 더 낮은  경향이 있습니다.",
      "ALPL 유전자는 뼈 무기질화 및 인산염 대사에 관여하는 효소를 암호화합니다. 알칼리 포스파타제는 또한 비타민 B6의 활성 형태인 피리독살 5'-포스페이트(PLP)를 비활성 형태인 피리독살로 전환시키는 것을 촉진함으로써 비타민 B6 대사에서 역할을 하는 것으로 알려져 있습니다.",
    ],
    referenceInfo: [
      "Hazra, Aditi et al. Human molecular genetics vol. 18,23 (2009): 4677-87.",
      "Tanaka, Toshiko et al. American journal of human genetics vol. 84,4 (2009): 477-82.",
      "Keene, Keith L et al. Frontiers in public health vol. 2 112. 6 Aug. 2014,",
    ],
    raceInfo: [
      {
        gene: "NBPF3",
        rsId: "rs4654748",
        number: "4,763명",
        race: "미국인",
      },
      {
        gene: "ALPL",
        rsId: "rs1697421",
        number: "2,100명",
        race: "미국인",
      },
    ],
  },
  VitaminB12: {
    name: "비타민 B12 농도",
    define:
      "비타민 B12는 수용성 비타민인 비타민 B군의 코발아민으로 핵산 합성, 아미노산 및 지방 대사 등에 관여합니다. 구조적으로 중심부인 코린 고리에 코발트를 포함하고 있으며 체내에서 다양하게 사용됩니다. 다른 비타민과 달리 육식을 섭취해야만 하는 것으로 알려져 채식주의자의 결핍에 관한 논란이 있었으나, 최근 콩류나 전통발효식품, 해조류 등을 통해 섭취 가능한 것으로 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 B12 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 식품 섭취하기",
        content:
          "육류, 생선, 가금류, 계란 및 유제품과 같이 비타민 B12가 풍부한 음식을 섭취하는 것은 비타민 B12 섭취를 늘리는 가장 좋은 방법 중 하나입니다. 이러한 동물성 식품에는 신체에 쉽게 흡수되는 메틸코발라민이라는 활성 형태의 비타민 B12가 포함되어 있습니다. 이러한 다양한 음식을 포함하는 식단을 섭취하면 충분한 비타민 B12를 섭취하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 B12 보충제는 정제, 캡슐 및 설하(혀 아래에서 녹는) 정제와 같은 다양한 형태로 제공됩니다. 비타민 B12 보충제는 합성 형태와 천연 형태로 제공되며, 비타민 B12의 천연 형태는 메틸코발라민이고 합성 형태는 시아노코발라민입니다. 메틸코발라민은 몸에 가장 쉽게 흡수되는 형태이므로 제품 선택에 참고하면 좋습니다. 일부 사람들은 위 또는 장 문제로 인해 보충제에서 비타민 B12를 흡수하는 데 문제가 있을 수 있으므로 설하 형태와 같은 다른 형태의 비타민 B12를 사용해야 할 수도 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "MS4A3 유전자는 음식에서 세포로 비타민 B12를 운반하는 과정에 관여하는 단백질을 암호화합니다. 이 유전자의 더 높은 활성은 비타민 B12의 수송과 활용을 증가시킬 수 있습니다.",
      "FUT2 유전자는 비타민 B12의 적절한 흡수에 필요한 내인성 인자의 방출을 돕는 단백질을 암호화합니다. FUT2 유전자의 변이는 내인성 인자의 생성 및 방출에 영향을 미쳐 잠재적으로 체내 비타민 B12 흡수 및 수치에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Lin, Xiaoling et al. Human molecular genetics vol. 21,11 (2012): 2610-7.",
      "Surendran, S et al. Genes & nutrition vol. 13 2. 6 Feb. 2018,",
      "Nongmaithem, Suraj S et al. Human molecular genetics vol. 26,13 (2017): 2551-2564.",
      "Hazra, Aditi et al. Nature genetics vol. 40,10 (2008): 1160-2.",
    ],
    raceInfo: [
      {
        gene: "MS4A3",
        rsId: "rs2298585",
        number: "3,495명",
        race: "중국인",
      },
      {
        gene: "FUT2",
        rsId: "rs602662",
        number: "4,419명",
        race: "인도-유럽계",
      },
      {
        gene: "FUT2",
        rsId: "rs492602",
        number: "2,717명",
        race: "미국인",
      },
    ],
  },
  VitaminE: {
    name: "비타민 E 농도",
    define:
      "비타민 E는 지용성 비타민의 하나로 생체막에서 지방질 산화를 억제하여 세포막 산화를 저해하는 항산화물질로, 세포 노화를 예방하고 적혈구 보호, 헴(Heme) 합성 및 혈소판 응집 등에 관여합니다. 결핍되면 노환, 불임 등이 나타나지만 과잉 시에 피로, 출혈, 설사, 두통 등의 부작용도 나타나는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 E 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "비타민 E는 견과류, 씨앗, 식물성 기름, 잎이 많은 녹색 채소와 같은 식품에 풍부합니다. 이러한 식품 공급원을 포함하는 균형 잡힌 식단을 섭취함으로써 개인은 충분한 수준의 비타민 E를 쉽게 얻을 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "식단을 통해 충분한 비타민 E를 섭취할 수 없는 개인에게는 고품질 비타민 E 보충제가 도움이 될 수 있습니다. 비타민 E 화합물의 두 가지 주요 그룹인 토코페롤과 토코트리에놀이 혼합된 보충제를 선택하여 최대의 이점을 취하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "비타민 E 오일 사용하기",
        content:
          "비타민 E 오일은 피부 건강을 증진하고 잔주름과 주름을 줄이기 위해 사용됩니다. 비타민 E 오일을 피부에 직접 바르면 염증을 줄이고 자외선 및 기타 환경 요인으로 인한 손상으로부터 보호할 수 있습니다. 전체 부위에 바르기 전에 손등 같이 피부의 일부분에 테스트 후 사용하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ZPR1 유전자는 세포 구획 사이에서 비타민 E의 이동을 촉진하기 위해 수송 단백질과 상호 작용하는 단백질을 암호화합니다. 해당 단백질은 비타민 E를 간에서 혈장 및 기타 조직으로 효율적으로 전달하는 데 필요한 것으로 나타났습니다.",
      "CYP4F2는 비타민 E의 대사에 관여하는 유전자입니다. 이것은 비타민 E를 보다 극성인 형태로 전환하여 소변으로 배설될 수 있도록 촉매하는 효소를 암호화합니다. 이 유전자는 신진대사와 배설을 조절함으로써 신체의 비타민 E 수치를 조절하는 역할을 하는 것으로 나타났습니다.",
    ],
    referenceInfo: [
      "Major, Jacqueline M et al. Human molecular genetics vol. 20,19 (2011): 3876-83.",
    ],
    raceInfo: [
      {
        gene: "ZPR1",
        rsId: "rs964184",
        number: "4,014명",
        race: "핀란드인",
      },
      {
        gene: "CYP4F2",
        rsId: "rs2108622",
        number: "29,133명",
        race: "미국인, 핀란드인",
      },
    ],
  },
  VitaminK: {
    name: "비타민 K 농도",
    define:
      "비타민 K는 지용성 비타민의 하나로 혈액응고인자로 작용합니다. 지용성 비타민이므로 몸에 축적이 가능하며 인체 내에서 합성되는 비타민으로 알려져 있어 섭취 시 주의가 필요하다. 결핍 시 지혈에 어려움이 있을 수 있으나, 과잉 섭취 시에는 오히려 혈전을 일으킬 수 있다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 K 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "시금치, 브로콜리, 케일과 같은 잎이 많은 녹색 채소와 같이 비타민 K가 풍부한 음식을 섭취하는 것은 비타민 B12 섭취를 늘리는 가장 좋은 방법 중 하나입니다. 비타민 K는 지용성이므로 식이 지방이 있을 때 가장 잘 흡수됩니다. 비타민 K가 풍부한 음식이나 보충제를 섭취하는 동시에 지방이 많은 음식(예: 아보카도, 견과류 또는 치즈)을 섭취하면 몸이 비타민을 보다 효과적으로 흡수하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "비타민 K 보충제는 캡슐, 정제 및 액체와 같은 다양한 형태로 제공되며 종종 다른 비타민 및 미네랄과 결합됩니다. 보충제는 식단을 통해 충분한 비타민 K를 섭취할 수 없는 사람들에게 특히 유익합니다. 고용량의 비타민 K 보충제는 혈액 항응고제와 상호 작용할 수 있으므로 의료 전문가의 지도하에 비타민 K 보충제를 섭취해야 합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "CYP4F2는 비타민 K의 분해에 관여하는 효소입니다. CYP4F2 유전자의 변이가 비타민 K가 체내에서 대사되는 속도에 영향을 미칠 수 있으며, 이는 차례로 체내 비타민 K 수준에 영향을 미칠 수 있는 것으로 나타났습니다.",
      "CTNNA2는 세포 신호 전달 경로에 관여하는 단백질을 암호화하는 유전자입니다. CTNNA2 유전자의 변이는 비타민 K가 체내에서 운반되고 사용되는 방식에 영향을 미침으로써 혈중 비타민 K 수치에 영향을 미칠 수 있는 것으로 밝혀졌습니다.",
    ],
    referenceInfo: [
      "Dashti, Hassan S et al. The American journal of clinical nutrition vol. 100,6 (2014): 1462-9.",
      "Zhao, Sizheng Steven et al. Seminars in arthritis and rheumatism vol. 55 (2022): 152030.",
    ],
    raceInfo: [
      {
        gene: "CYP4F2",
        rsId: "rs2108622",
        number: "826,690명",
        race: "다인종",
      },
      {
        gene: "CTNNA2",
        rsId: "rs4852146",
        number: "826,690명",
        race: "다인종",
      },
    ],
  },
  Omega3: {
    name: "오메가-3 지방산 농도",
    define:
      "오메가-3 지방산은 불포화지방산의 한 종류로 메틸기(CH3)에서 3번째 탄소에 이중결합이 있는 지방산을 오메가-3 지방산이라 합니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 오메가-3 지방산 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 오메가-3 섭취하기",
        content:
          "오메가-3가 풍부한 연어, 정어리, 멸치와 같은 지방이 많은 생선을 먹는 것은 오메가-3 지방산의 일일 권장 섭취량을 얻는 좋은 방법입니다. 이 생선에는 인체 건강에 가장 유익한 두 가지 유형의 오메가-3인 에이코사펜타엔산(EPA)과 도코사헥사엔산(DHA)이 풍부합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "식물성 오메가-3 섭취하기",
        content:
          "아마씨, 치아 씨, 호두와 같은 식물성 오메가-3 공급원도 식단에 포함될 수 있습니다. 이러한 식물 공급원에는 EPA와 DHA만큼 유익하지는 않지만 여전히 중요한 공급원인 오메가-3 유형인 알파-리놀렌산(ALA)이 포함되어 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "어유 보충제와 같은 오메가-3 보충제를 섭취하여 오메가-3 섭취를 늘릴 수도 있지만, 라벨을 읽고 권장 복용량을 따르는 것이 중요합니다. 이러한 보충제는 식단만으로는 섭취하기 어려운 EPA와 DHA의 섭취를 늘리는 좋은 방법입니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FADS1 유전자는 알파-리놀렌산(ALA)을 오메가-3 지방산의 일종인 에이코사펜타엔산(EPA)으로 전환하는 데 관여하는 효소를 암호화합니다. 이 효소의 활동은 ALA에서 EPA로의 전환율에 영향을 미치며, 이는 체내 오메가-3 지방산의 전반적인 수준에 영향을 미칠 수 있습니다. FADS1 유전자는 아라키돈산과 같은 오메가-6 지방산을 포함한 장쇄 고도불포화 지방산(LCPUFA)의 생산을 담당합니다. 이러한 LCPUFA는 다양한 생리적 과정의 적절한 성장, 발달 및 유지에 필수적입니다.",
      "BEST1 유전자는 세포 칼슘 수준의 조절에 관여하는 단백질을 암호화합니다. 연구에 따르면 이 단백질은 망막 세포에서 오메가-3 지방산의 수송 및 축적에 역할을 할 수 있습니다.",
    ],
    referenceInfo: [
      "Lemaitre, Rozenn N et al. PLoS genetics vol. 7,7 (2011): e1002193.",
      "Imaizumi, Akira et al. European journal of human genetics : EJHG vol. 27,4 (2019): 621-630.",
      "Xu, Bayi et al. BMC cardiovascular disorders vol. 21,1 532. 8 Nov. 2021,",
    ],
    raceInfo: [
      {
        gene: "FADS1",
        rsId: "rs174547",
        number: "1,361명",
        race: "중국계 싱가포르인",
      },
      {
        gene: "BEST1",
        rsId: "rs1109748",
        number: "694,649명",
        race: "유럽계",
      },
    ],
  },
  Omega6: {
    name: "오메가-6 지방산 농도",
    define:
      "오메가-6 지방산은 불포화지방산의 한 종류로 메틸기(CH3)에서 6번째 탄소에 이중결합이 있는 지방산을 오메가-6 지방산이라 합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 오메가-6 지방산 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "오메가-6 지방산의 가장 좋은 식품 공급원에는 옥수수 기름, 해바라기 기름, 콩기름과 같은 식물성 기름과 호두 및 호박씨와 같은 견과류와 씨앗이 포함됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "오메가-6 보충제는 리놀레산 또는 감마-리놀렌산과 같이 보다 생체 이용 가능한 형태의 농축된 양분을 제공합니다. 오메가-6 보충제는 결핍이 있거나 이러한 지방산 섭취를 늘리려는 사람들에게 도움이 될 수 있습니다. 오메가-6 보충제를 너무 많이 섭취하면 신체의 오메가-3와 오메가-6 균형에 부정적인 영향을 미칠 수 있다는 점에 유의해야 합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "균형 잡힌 식단 갖기",
        content:
          "오메가-6 지방산과 오메가-3 지방산 둘 중 하나가 너무 많으면 건강에 부정적인 영향을 미칠 수 있기 때문에 균형을 유지하는 것이 중요합니다. 오메가-6를 올바르게 섭취하는 가장 좋은 방법은 생선, 견과류, 씨앗, 식물성 기름과 같은 오메가-6 및 오메가-3 지방산이 풍부한 다양한 식품을 포함하는 균형 잡힌 식단을 구성하는 것입니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FADS1 유전자는 아라키돈산과 같은 오메가-6 지방산을 포함한 장쇄 고도불포화 지방산(LCPUFA)의 생산을 담당합니다. 이러한 LCPUFA는 다양한 생리적 과정의 적절한 성장, 발달 및 유지에 필수적입니다.",
      "FADS2 유전자는 아라키돈산과 같은 오메가-6 지방산을 포함한 장쇄 고도불포화 지방산(LCPUFA)의 생산을 담당합니다. 이러한 LCPUFA는 다양한 생리적 과정의 적절한 성장, 발달 및 유지에 필수적입니다.",
      "TMEM258 유전자는 FADS1 및 FADS2 효소의 활성 조절에 관여하는 유전자입니다. 특히 FADS 효소를 안정화하고 분해를 방지하는 데 도움이 되는 단백질을 암호화합니다. TMEM258 유전자의 변이는 또한 혈중 오메가-6 지방산 수치의 변화와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Dorajoo, Rajkumar et al. Genes & nutrition vol. 10,6 (2015): 53.",
      "Guan, Weihua et al. Circulation. Cardiovascular genetics vol. 7,3 (2014): 321-331.",
      "Coltell, Oscar et al. Nutrients vol. 12,2 310. 24 Jan. 2020,",
      "Barman, Malin et al. Nutrients vol. 7,12 10100-15. 3 Dec. 2015,",
    ],
    raceInfo: [
      {
        gene: "FADS1",
        rsId: "rs174547",
        number: "1,361명",
        race: "중국계 싱가포르인",
      },
      {
        gene: "FADS2",
        rsId: "rs2727270",
        number: "8,631명",
        race: "백인",
      },
      {
        gene: "TMEM258",
        rsId: "rs102275",
        number: "8,631명",
        race: "백인",
      },
    ],
  },
  Arginine: {
    name: "아르기닌 농도",
    define:
      "아르기닌은 단백질의 구성성분인 아미노산 중 하나로 강한 염기성을 띤다. 체내에서 암모니아를 요소로 변환할 때 쓰이는 요소회로의 필수 요소로, 상피세포나 뇌세포, 일산화질소 등을 만든다. 이때 생성된 체내 일산화질소는 혈관 확장 및 근육 회복에 도움을 준다고 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 아르기닌 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "아르기닌은 육류, 해산물, 유제품, 견과류 및 씨앗과 같은 여러 식품에서 쉽게 구할 수 있습니다. 이러한 음식을 포함하는 균형 잡힌 식단을 섭취하면 아르기닌의 일일 요구량을 충족하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "아르기닌은 혈류와 근육 성장을 촉진하는 것으로 알려져 있기 때문에 이러한 보충제는 종종 운동 성능 향상제로 판매됩니다. 그러나 아르기닌 보충제의 효과는 여전히 논의 중이며 일부 개인에게는 부작용을 일으킬 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "AGXT2 유전자는 아르기닌 분해에 관여하는 효소를 암호화합니다. AGXT2 유전자의 변이는 아르기닌이 체내에서 분해되는 속도에 영향을 미쳐 전체 수준에 잠재적으로 영향을 미칠 수 있습니다.",
      "SLC7A2 유전자는 세포막을 가로지르는 아르기닌과 같은 양이온성 아미노산의 수송을 담당하는 단백질을 암호화합니다. 이 유전자는 신체에서 아르기닌의 섭취와 분포를 조절하는 역할을 합니다.",
    ],
    referenceInfo: [
      "Kleber, Marcus E et al. Circulation. Cardiovascular genetics vol. 6,5 (2013): 505-13.",
      "Seppälä, Ilkka et al. European heart journal vol. 35,8 (2014): 524-31.",
      "Lotta, Luca A et al. Nature genetics vol. 53,1 (2021): 54-64.",
      "Imaizumi, Akira et al. European journal of human genetics : EJHG vol. 27,4 (2019): 621-630.",
    ],
    raceInfo: [
      {
        gene: "AGXT2",
        rsId: "rs37369",
        number: "5,110명",
        race: "유럽인",
      },
      {
        gene: "SLC7A2",
        rsId: "rs56335308",
        number: "1,338명",
        race: "일본인",
      },
    ],
  },
  Calcium: {
    name: "칼슘 농도",
    define:
      "칼슘은 생체 내 가장 많은 무기질 성분으로 99%는 뼈와 치아의 구성성분으로 골격 유지에, 1%는 세포 내에서 신경 자극 전달, 근육과 혈관의 수축이완, 혈액 응고 및 삼투압 등 인체 내 순환에 관여합니다. 섭취량보다는 섭취율에 대한 고려가 중요하며 과다 섭취 시 요로결석이나 혈관 경화 관련 질환의 발병률을 높이는 것으로 알려져있어 섭취 시 주의가 필요하다.",
    reference: "5",
    heredity: "13",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 칼슘 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "유제품 섭취하기",
        content:
          "우유, 치즈, 요거트와 같은 유제품의 칼슘은 일반적으로 식물성 식품의 칼슘보다 생물학적 이용 가능성이 더 높습니다. 또한 일부 칼슘 식품 공급원에는 칼슘 흡수를 감소시킬 수 있는 옥살산염과 같은 미네랄 흡수를 방해할 수 있는 다른 물질이 포함될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "칼슘 보충제는 탄산칼슘, 구연산칼슘 또는 젖산칼슘과 같은 생체이용 가능한 형태의 농축된 양분을 제공합니다. 칼슘 보충제는 종종 결핍이 있거나 뼈 건강을 위해 필요한 사람들에게 권장됩니다. 그러나 칼슘 보충제를 과도하게 섭취할 경우 소화 불편, 변비 등의 부작용이 나타날 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CASR 유전자는 칼슘 항상성 조절을 돕는 단백질인 칼슘 감지 수용체를 암호화합니다. 혈중 칼슘 수치를 감지하고 신체의 칼슘 수치를 조절하는 부갑상선 호르몬(PTH) 또는 칼시토닌을 분비하도록 부갑상선에 신호를 보내 칼슘 균형을 유지하는 역할을 합니다.",
      "CYP24A1 유전자는 신체의 칼슘 수치를 조절하는 데 도움이 되는 칼시트리올이라는 호르몬 분해에 관여하는 효소를 암호화합니다. CYP24A1 유전자의 변이는 이 효소의 활성에 영향을 미쳐 잠재적으로 체내 칼슘 수치에 영향을 미칠 수 있습니다.",
      "DGKD 유전자는 신호 전달 경로에 관여하는 두 가지 중요한 2차 전령인 디아실글리세롤(DAG) 및 포스파티드산(PA)의 수준을 조절함으로써 칼슘 신호 전달에 역할하는 효소를 암호화합니다. DGKD는 특히 PTH 분비를 조절하는 부갑상샘에서 칼슘 항상성의 조절에 관여합니다.",
      "GCKR 유전자는 간에서 포도당을 포도당-6-인산으로 인산화하는 것을 촉매하는 효소인 글루코키나아제의 활성을 조절하는 글루코키나아제 조절 단백질을 암호화합니다. GCKR은 글루코스 항상성 및 지질 대사의 조절에 역할을 하며, 특히 뼈에서 칼슘 항상성의 조절과 관련되어 있습니다.",
    ],
    referenceInfo: [
      "Chang, Xiao et al. PloS one vol. 10,4 e0123499. 17 Apr. 2015,",
      "Yuan, Shuai et al. EBioMedicine vol. 76 (2022): 103865.",
      "Sinnott-Armstrong, Nasa et al. Nature genetics vol. 53,2 (2021): 185-194.",
      "Cerani, Agustin et al. BMJ (Clinical research ed.) vol. 366 l4410. 1 Aug. 2019,",
      "Young, William J et al. Circulation. Genomic and precision medicine vol. 14,3 (2021): e003231.",
    ],
    raceInfo: [
      {
        gene: "CASR",
        rsId: "rs1801725",
        number: "339,197명",
        race: "영국인",
      },
      {
        gene: "CYP24A1",
        rsId: "rs2762934",
        number: "339,197명",
        race: "영국인",
      },
      {
        gene: "DGKD",
        rsId: "rs1550532",
        number: "61,079명",
        race: "다인종",
      },
      {
        gene: "GCKR",
        rsId: "rs1260326",
        number: "339,197명",
        race: "영국인",
      },
    ],
  },
  Magnesium: {
    name: "마그네슘 농도",
    define:
      "생체 내 마그네슘은 필수적인 물질이나 미량 존재하는 무기질입니다. 대부분 골격에 존재하면서 뼈나 관절 건강, 일부 에너지 대사와 근육 수축에 관여하는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "7",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 마그네슘 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "자연적으로 마그네슘이 많은 음식에는 잎이 많은 채소, 견과류 및 씨앗, 통곡물, 콩류 및 지방이 많은 생선이 포함됩니다. 식단에 이러한 음식을 포함하면 매일 적절한 마그네슘을 섭취할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "음식만으로 마그네슘 요구량을 충족할 수 없다면 마그네슘 보충제가 좋은 선택이 될 수 있습니다. 그러나 흡수가 잘되고 소화 장애를 일으키지 않는 고품질 보충제를 선택하는 것이 중요합니다. 즉, 흡수를 향상시키기 위해 킬레이트화 된 마그네슘 보충제를 찾으세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "마그네슘 오일 사용하기",
        content:
          "마그네슘 오일은 피부에 직접 바르면 흡수되어 활용될 수 있습니다. 이 보충 방법은 보충제로 인해 소화 불량을 경험하는 개인에게 특히 도움이 될 수 있습니다. 그러나 신체가 피부를 통해 많은 양의 마그네슘을 흡수할 수 없기 때문에 음식을 통해 얻은 마그네슘을 대체할 수 없다는 점에 유의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
    ],
    description: [
      "MUC1은 세포 부착, 신호 전달 및 분화 조절을 비롯한 많은 세포 과정에 관여하는 막관통 당단백질을 암호화합니다. 연구에 따르면 MUC1은 마그네슘 수송을 조절하는 세포막의 이온 채널과 상호 작용하여 마그네슘 항상성에 영향을 줄 수 있습니다.",
      "SHROOM3는 세포 모양과 구조에 관여하고 뇌와 심장을 포함한 많은 기관의 발달에 필요한 단백질을 암호화합니다. 최근 연구에 따르면 SHROOM3는 장에서 마그네슘 수송 및 흡수에 관여하는 단백질과의 상호 작용을 통해 체내 마그네슘 수치 조절에도 관여하는 것으로 나타났습니다.",
    ],
    referenceInfo: [
      "Tin, Adrienne et al. BMC genetics vol. 16 56. 29 May. 2015,",
      "Meyer, Tamra E et al. PLoS genetics vol. 6,8 e1001045. 5 Aug. 2010,",
      "Larsson, Susanna C, and Dipender Gill. Neurology vol. 97,4 (2021): e341-e344.",
    ],
    raceInfo: [
      {
        gene: "MUC1",
        rsId: "rs4072037",
        number: "15,366명",
        race: "유럽인",
      },
      {
        gene: "SHROOM3",
        rsId: "rs13146355",
        number: "79,429명",
        race: "유럽인",
      },
    ],
  },
  Zinc: {
    name: "아연 농도",
    define:
      "생체 내 아연은 핵산과 아미노산 대사에 반드시 필요한 무기질로 다양한 효소의 작용을 돕는 조효소이며, 소화와 호흡작용에 필요하다. 또한 호르몬과 근육생성에 영향을 미치기 때문에 면역기능 및 생식기능에도 관여합니다. 미량원소로 일상적 식단으로 결핍은 드물며, 영양제 형태의 추가 복용을 통한 과잉 섭취 시 신경통증 유발 등이 보고되어 있어 주의가 필요하다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 아연 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "보충제 복용하기",
        content:
          "아연 보충제는 널리 사용 가능하며 캡슐이나 정제 형태로 쉽게 섭취할 수 있습니다. 이러한 보충제는 글루콘산아연, 구연산아연, 아세트산아연 또는 피콜리네이트아연과 같은 보다 생체이용 가능한 형태의 농축된 영양소를 제공하며 섭취량을 늘리려는 사람이나 결핍증이 있는 사람에게 유용할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "식품으로 섭취하기",
        content:
          "아연 함량이 높은 식품을 섭취하면 체내 영양소 수치를 높이는 데 도움이 될 수 있습니다. 아연의 가장 좋은 식품 공급원에는 굴, 쇠고기, 돼지고기, 닭고기, 콩, 견과류 및 통곡물이 포함됩니다. 이러한 음식을 식단에 포함하면 아연 섭취량을 늘리는 데 도움이 될 수 있지만 일부 아연 식품 공급원에는 아연 흡수를 감소시킬 수 있는 피트산과 같이 미네랄 흡수를 방해할 수 있는 다른 물질이 포함되어 있을 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "PPCDC 유전자는 아연을 세포로 수송하는 것과 관련된 단백질을 암호화합니다. PPCDC 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 잠재적으로 세포의 아연 수준에 영향을 줄 수 있습니다.",
      "CA1 유전자는 세포막을 가로지르는 아연 이온의 이동 조절에 관여하는 단백질을 암호화합니다. CA1 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 잠재적으로 세포의 아연 수치에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Evans, David M et al. Human molecular genetics vol. 22,19 (2013): 3998-4006.",
    ],
    raceInfo: [
      {
        gene: "PPCDC",
        rsId: "rs2120019",
        number: "12,628명",
        race: "호주인, 영국인",
      },
      {
        gene: "CA1",
        rsId: "rs1532423",
        number: "12,628명",
        race: "호주인, 영국인",
      },
    ],
  },
  Iron: {
    name: "철 저장 농도",
    define:
      "생체 내 철은 필수 무기물 중 하나로 산소를 운반하는 역할을 하는 적혈구 내 헤모글로빈의 구성성분이기 때문에, 부족 시 빈혈이나 산소 공급이 원활하지 않아 생기는 피로감, 무기력감 등이 나타날 수 있습니다. 반면, 혈중 철분 농도가 급격히 증가하면 쇼크, 대사성산증, 혼수상태, 경련, 간 독성으로 인한 황달 등 철 중독증이 나타날 수 있어 주의가 필요하다. 철분은 섭취 후 헤모글로빈을 만들고 체내 정상적 기능 유지에 일차적으로 사용되나, 페리틴, 헤모시데린과 같은 저장철 형태로 가지고 있다 부족 시 방출하여 혈중 철분 농도에 관여할 수 있습니다.",
    reference: "2",
    heredity: "7",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 철 저장 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 식품 섭취하기",
        content:
          "육류, 가금류, 생선과 같은 동물성 식품의 철분은 일반적으로 잎이 많은 채소 및 콩류와 같은 식물성 식품의 철분보다 생물학적 이용 가능성이 더 높습니다. 이는 동물성 철분 공급원에 헴철이 포함되어 있어 신체에 더 쉽게 흡수되기 때문입니다. 일부 철분 식품 공급원에는 철분 흡수를 감소시킬 수 있는 탄닌 또는 피트산과 같은 미네랄 흡수를 방해할 수 있는 다른 물질이 포함되어 있을 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "철분 보충제는 황산제일철, 글루콘산제일철 또는 푸마르산제일철과 같은 생체 이용 가능한 형태의 농축된 양분을 제공합니다. 철분 보충제는 종종 결핍이 있거나 철분이 필요한 사람들에게 권장됩니다. 그러나 철분 보충제를 과도하게 섭취할 경우 소화 불편 및 변비와 같은 부작용이 나타날 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TF 유전자는 철분을 세포로 수송하고 세포에 저장하는 것과 관련된 단백질을 암호화합니다. TF 유전자의 변이는 이 단백질의 활동에 영향을 미쳐 잠재적으로 세포의 철 저장 수준에 영향을 줄 수 있습니다.",
      "TMPRSS6 유전자는 소장에서 음식으로부터 흡수되는 철분의 양을 조절하는 것과 관련된 단백질을 암호화합니다. TMPRSS6 유전자의 변이는 이 단백질의 활동에 영향을 미쳐 잠재적으로 체내 철 저장 수준에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Benyamin, Beben et al. Nature communications vol. 5 4926. 29 Oct. 2014,",
      "Pichler, Irene et al. Human molecular genetics vol. 20,6 (2011): 1232-40.",
    ],
    raceInfo: [
      {
        gene: "TF",
        rsId: "rs8177240",
        number: "48,972명",
        race: "유럽인",
      },
      {
        gene: "TF",
        rsId: "rs3811647",
        number: "9,090명",
        race: "오스트리아인, 이탈리아인",
      },
      {
        gene: "TMPRSS6",
        rsId: "rs4820268",
        number: "9,090명",
        race: "오스트리아인, 이탈리아인",
      },
      {
        gene: "TMPRSS6",
        rsId: "rs228916",
        number: "48,972명",
        race: "유럽인",
      },
    ],
  },
  Tyrosine: {
    name: "타이로신 농도",
    define:
      "타이로신은 단백질의 구성성분인 아미노산 중 하나이자 방향족 화합물로 페닐알라닌이라는 아미노산에서 체내 합성되기 때문에 비필수 아미노산으로 분류됩니다. 도파민, 아드레날린 등 신경전달물질로 변환되어 신진대사 조절에 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 타이로신 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "동물성 식품 섭취하기",
        content:
          "타이로신을 얻는 가장 좋은 방법은 육류, 생선, 가금류, 유제품, 계란 같은 식품입니다. 이러한 식품에는 전반적인 건강에 중요한 비타민 및 미네랄과 같은 다른 영양소가 포함되어 있으며 균형 잡힌 아미노산 공급원을 제공하는 데 도움이 될 수 있습니다. 단백질이 풍부한 다양한 식품을 포함하는 균형 잡힌 식단은 타이로신의 적절한 섭취를 보장하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "타이로신 보충제는 식단에서 충분한 타이로신을 섭취하지 못하는 개인에게 유용할 수 있습니다. 타이로신은 인지 기능을 향상시키고 기분을 좋게 하며 스트레스를 줄이는 능력이 있다고 알려져 있습니다. 그러나 티로신 보충의 잠재적 이점과 위험을 완전히 이해하려면 더 많은 연구가 필요하다는 점에 유의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "SLC16A10 유전자는 타이로신을 세포로 수송하는 것과 관련된 단백질을 암호화합니다. SLC16A10 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 잠재적으로 세포의 타이로신 수준에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Kettunen, Johannes et al. Nature communications vol. 7 11122. 23 Mar. 2016,",
      "Geidenstam, Nina et al. PloS one vol. 14,9 e0222445. 27 Sep. 2019,",
    ],
    raceInfo: [
      {
        gene: "SLC16A10",
        rsId: "rs14399",
        number: "24,925명",
        race: "유럽인",
      },
      {
        gene: "SLC16A10",
        rsId: "rs62421904",
        number: "2,276명",
        race: "미국인, 멕시코인",
      },
    ],
  },
  Betaine: {
    name: "베타인 농도",
    define:
      "베타인은 단백질의 구성성분인 아미노산 중 하나이자 식품에서 감칠맛 성분으로 알려져 있습니다. 메티오닌(Methionine) 합성을 촉진하여 혈압 강하, 항혈당 작용, 해독 작용 등에 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 베타인 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "베타인은 사탕무, 시금치, 통곡물, 새우, 연어, 대구와 같은 해산물을 포함한 다양한 식품에서 발견됩니다. 이러한 음식이 풍부한 식단을 섭취하면 베타인 섭취를 늘리는 데 도움이 될 수 있습니다. 이러한 음식을 섭취하면 베타인 및 기타 영양소의 천연 공급원을 제공하여 신체가 더 쉽게 흡수할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "베타인 보충제는 식단에서 충분한 베타인을 섭취하지 않거나 추가 베타인 섭취가 필요한 개인에게 유용할 수 있습니다. 베타인 보충제는 위산의 pH를 낮춰 소화 불량에 도움을 줄 수 있습니다. 새로운 건강 보조 식품을 복용하기 전에 보조 식품 라벨의 권장 복용량을 따르고 의료 제공자와 상의하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "DMGDH 유전자는 트리메틸글리신(베타인)에서 디메틸글리신(DMG)으로의 대사 전환에 관여하는 단백질을 암호화합니다. DMGDH 유전자의 변이는 이 단백질의 활동에 영향을 미쳐 잠재적으로 체내 베타인 수치에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Sookoian, Silvia et al. Liver international : official journal of the International Association for the Study of the Liver vol. 37,4 (2017): 611-619.",
      "Hartiala, Jaana A et al. Nature communications vol. 7 10558. 29 Jan. 2016,",
    ],
    raceInfo: [
      {
        gene: "DMGDH",
        rsId: "rs1805074",
        number: "390명",
        race: "아르헨티나인, 미국인",
      },
      {
        gene: "DMGDH",
        rsId: "rs16876394",
        number: "86,995명",
        race: "미국인",
      },
    ],
  },
  Selenium: {
    name: "셀레늄 농도",
    define:
      "셀레늄은 체내에 필수적인 미량 무기질 중 하나로 강력한 항산화력을 가지는 항산화 물질로 해독작용 및 면역 기능 증진 등에 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 셀레늄 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "보충제 복용하기",
        content:
          "보충제는 셀레노메티오닌 또는 아셀렌산 나트륨과 같이 생체 이용률이 더 높은 형태로 농축된 양의 영양소를 제공하기 때문에 셀레늄을 더 효율적으로 흡수할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "셀렌 효모 이용하기",
        content:
          "셀렌 효모는 셀레늄이 풍부한 효모의 한 형태이며 식이 보충제로 사용할 수 있습니다. 천연 및 식품 기반 셀레늄 공급원을 찾는 사람들에게 좋은 선택입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식품으로 섭취하기",
        content:
          "셀레늄의 가장 좋은 식품 공급원에는 참치, 쇠고기, 칠면조, 브라질 너트, 해바라기 씨 및 현미가 포함됩니다. 일부 셀레늄 식품 공급원에는 셀레늄 흡수를 감소시킬 수 있는 피트산과 같이 미네랄 흡수를 방해할 수 있는 다른 물질이 포함되어 있을 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "DMGDH 유전자는 세포에서 셀레늄을 활용하는 데 필요한 분자 생산에 관여하는 효소를 암호화합니다. DMGDH 유전자의 변이는 이 효소의 활성에 영향을 미쳐 잠재적으로 세포의 셀레늄 활용에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Cornelis, Marilyn C et al. Human molecular genetics vol. 24,5 (2015): 1469-77.",
      "Evans, David M et al. Human molecular genetics vol. 22,19 (2013): 3998-4006.",
    ],
    raceInfo: [
      {
        gene: "DMGDH",
        rsId: "rs17823744",
        number: "4,162명",
        race: "유럽계 미국인",
      },
      {
        gene: "DMGDH",
        rsId: "rs921943",
        number: "12,618명",
        race: "영국인, 호주인",
      },
    ],
  },
  Lutein: {
    name: "루테인&지아잔틴",
    define:
      "루테인과 지아잔틴은 유기색소로 체내에서 유용한 생리활성물질로 알려져 있는 카로티노이드 성분입니다. 구조적으로 둘다 강력한 항산화제로 눈 건강과 시력 보호에 매우 중요한 역할을 하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 루테인&지아잔틴 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식품으로 섭취하기",
        content:
          "루테인과 지아잔틴이 풍부한 식품에는 케일과 시금치와 같은 잎이 많은 채소와 당근과 같은 다채로운 과일과 채소가 포함됩니다. 생과일과 채소를 먹는 것이 일반적으로 좋은 생각이지만 연구에 따르면 일부 채소를 요리하면 실제로 루테인과 지아잔틴의 생체 이용률을 높이는 데 도움이 될 수 있습니다. 예를 들어 케일과 시금치를 요리하면 세포벽을 분해하고 더 나은 흡수를 위해 카로티노이드를 방출하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
      {
        title: "보충제 복용하기",
        content:
          "음식에서 영양소를 충분히 섭취하기 힘든 경우 보충제를 선택할 수 있습니다. 메리골드 꽃 추출물과 같은 루테인과 지아잔틴의 천연 공급원을 함유한 보충제를 찾으세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "지방과 함께 섭취하기",
        content:
          "루테인/지아잔틴은 지용성이므로 지방이 있을 때 더 잘 흡수됩니다. 따라서 이러한 음식을 아보카도, 견과류, 씨앗 또는 올리브 오일과 같은 건강한 지방 공급원과 함께 사용하면 흡수를 높이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "SCARB1 유전자는 간에서 루테인과 지아잔틴 수송에 관여하는 단백질을 암호화합니다. SCARB1 유전자의 변이는 이 단백질의 활성에 영향을 미쳐 간에서 루테인과 지아잔틴의 수송에 잠재적으로 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Yonova-Doing, Ekaterina et al. Experimental eye research vol. 115 (2013): 172-7.",
      "Meyers, Kristin J et al. Investigative ophthalmology & visual science vol. 55,1 587-99. 29 Jan. 2014,",
    ],
    raceInfo: [
      {
        gene: "SCARB1",
        rsId: "rs11057841",
        number: "310명",
        race: "영국인",
      },
      {
        gene: "SCARB1",
        rsId: "rs10846744",
        number: "1,663명",
        race: "미국인",
      },
    ],
  },
  Fat: {
    name: "비만",
    define:
      "비만은 체내에 지방이 비정상적으로 많이 축적되는 것으로, 에너지 소비와 공급의 불균형을 유발하는 운동 부족 및 식생활, 다양한 유전적, 환경적, 사회적 요인이 복합적으로 작용하여 생기는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 비만 위험이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "균형 잡힌 식사하기",
        content:
          "칼로리가 낮고 영양이 풍부한 음식을 섭취하면 체중을 관리하고 건강을 유지하는 데 도움이 됩니다. 과일, 채소, 통곡물, 그리고 저지방 단백질을 많이 섭취하면 배고픔을 조절하고 포만감을 유지하며 칼로리 섭취를 조절하는 데 유익할 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "신체 활동 늘리기",
        content:
          "규칙적인 신체 활동에 참여하는 것은 칼로리를 태우고 체중을 관리하는 효과적인 방법입니다. 신체 활동에는 걷기, 자전거 타기, 수영 또는 조깅과 같이 심박수를 높이는 모든 유형의 움직임이 포함될 수 있습니다. 대부분의 요일에 최소 30분의 중간 강도 운동을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식사 기록하기",
        content:
          "먹고 마시는 것을 추적하면 칼로리 섭취를 제어하는 ​​데 도움이 될 수 있습니다. 체중 관리 목표에 맞는 적절한 양의 칼로리를 섭취하고 있는지 확인하기 위해 음식 일기 또는 앱을 사용해보세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FTO 유전자는 체중과 체지방량 조절에 관여합니다. 식욕 조절과 에너지 균형에 중요한 역할을 하는 것으로 나타났습니다. 이 유전자 변이는 비만과 높은 체질량 지수(BMI) 경향성과 관련이 있습니다.",
      "NEGR1 유전자는 비만과 관련이 있습니다. 에너지 균형과 지방 저장에 영향을 주어 체중을 조절하는 것으로 나타났습니다. 이 유전자 변이는 비만과 높은 체질량 지수(BMI) 경향성과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Monnereau, C et al. International journal of obesity (2005) vol. 42,4 (2018): 897-904.",
      "Berndt, Sonja I et al. Nature genetics vol. 45,5 (2013): 501-12.",
      "Thorleifsson, Gudmar et al. Nature genetics vol. 41,1 (2009): 18-24.",
    ],
    raceInfo: [
      {
        gene: "FTO",
        rsId: "rs7185735",
        number: "1,995명",
        race: "네덜란드인",
      },
      {
        gene: "NEGR1",
        rsId: "rs2568958",
        number: "263,407명",
        race: "유럽인",
      },
    ],
  },
  BodyFatPer: {
    name: "체지방률",
    define:
      "체지방률은 체중에서 체지방 무게가 차지하는 비율로, 같은 체중이라도 근육량이나 골격의 비율 등에 따라 체지방률이 달라질 수 있습니다.",
    reference: "3",
    heredity: "23",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 체지방률이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적으로 운동하는 것은 체지방률을 관리하는 효과적인 방법입니다. 일주일에 5일, 하루 30분 이상 운동하면 근육량 증가, 신진대사 촉진, 과도한 지방 연소에 도움이 됩니다. 이것은 전반적인 체지방 감소와 체성분 개선으로 이어질 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 만들기",
        content:
          "건강하고 균형 잡힌 식단을 채택하는 것은 체지방률 관리를 위한 핵심 솔루션입니다. 가공 식품, 설탕 및 건강에 해로운 지방의 소비를 줄이고 식이섬유가 풍부한 야채, 해조류와 저지방 단백질 섭취를 늘리는 것이 좋습니다. 이러한 식단 변화를 통해 개인은 칼로리 섭취량을 조절하고 신진대사를 조절하며 체지방 수치를 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "스트레스는 코르티솔 수치 상승과 관련이 있으며, 이는 체지방 축적 증가로 이어질 수 있습니다. 마음 챙김, 명상, 요가 그리고 충분한 수면은 스트레스 수준을 줄이고 더 건강한 체지방률을 촉진하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TMEM18 유전자는 에너지 섭취와 에너지 소비 간의 균형인 에너지 항상성 조절에 관여합니다. 지방 생성(지방 세포의 발달)에서 역할을 하는 것으로 생각되며 여러 인구에서 체질량 지수(BMI) 및 비만과 관련이 있습니다.",
      "COBLL1 유전자는 지방 생성에 관련되어 있으며 여러 연구에서 BMI 및 체지방률과 관련이 있습니다. 지방 조직 발달 및 기능 조절에 역할을 하는 것으로 생각됩니다.",
      "NYAP2 유전자는 인슐린 신호 및 포도당 대사 조절에 관여합니다. 여러 인구에서 BMI, 체지방률 및 비만과 관련이 있습니다. NYAP2는 지방세포 분화 및 인슐린 민감성의 조절에 역할을 하는 것으로 생각됩니다.",
      "PLA2G6 유전자는 세포막 파괴에 관여하는 포스포리파제 효소를 암호화합니다. 여러 연구에서 BMI, 체지방률 및 비만과 관련이 있습니다. PLA2G6은 지방세포 분화 및 지질 대사 조절에 역할을 하는 것으로 생각됩니다.",
    ],
    referenceInfo: [
      "Lu, Yingchang et al. Nature communications vol. 7 10495. 1 Feb. 2016,",
      "Schnurr, Theresia M et al. PloS one vol. 11,11 e0166738. 15 Nov. 2016,",
      "Huang, Lam O et al. Nature metabolism vol. 3,2 (2021): 228-243.",
    ],
    raceInfo: [
      {
        gene: "TMEM18",
        rsId: "rs6755502",
        number: "100,716명",
        race: "다인종",
      },
      {
        gene: "COBLL1",
        rsId: "rs6738627",
        number: "100,716명",
        race: "다인종",
      },
      {
        gene: "PLA2G6",
        rsId: "rs3761445",
        number: "50만명 이상",
        race: "유럽인",
      },
    ],
  },
  Bmi: {
    name: "체질량지수",
    define:
      "체질량지수(BMI)는 체중(㎏)을 신장(m)의 제곱으로 나눈 값으로 대략적인 비만의 추정 지표로 활용됩니다. 체질량지수는 인종, 성별, 연령 및 개인의 근육량 등에 따라 다르게 판단할 수 있습니다.",
    reference: "6",
    heredity: "25",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 6종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 체질량지수가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "유산소 운동하기",
        content:
          "달리기, 걷기, 자전거 타기 또는 수영과 같은 유산소 운동은 체지방을 줄이고, 근육량을 증가시켜 BMI를 감소시킬는 효과를 볼 수 있습니다. 효과적인 BMI 관리를 위해서는 주 5회, 최소 30분 동안 평소보다 숨이 조금 더 찰 정도로 운동을 하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "균형있는 식단 유지하기",
        content:
          "과일, 채소, 통곡물, 저지방 단백질이 풍부한 균형 잡힌 식단을 섭취하면 체중을 조절하고 건강한 BMI를 유지하는 데 도움이 됩니다. 고칼로리, 가공식품을 피하고 설탕과 포화 지방 섭취를 제한하세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "생활습관 바꾸기",
        content:
          "스트레스 감소, 충분한 수면, 흡연과 같은 건강에 해로운 습관 피하기와 같은 일상 습관을 바꾸면 BMI에 큰 영향을 미칠 수 있습니다. 이러한 작은 변화는 장기적인 체중 관리 성공으로 이어질 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FTO 유전자는 BMI 조절에서의 역할에 대해 광범위하게 연구되었습니다. FTO는 DNA와 RNA를 탈메틸화하고 음식 섭취와 에너지 균형을 조절하는 뇌 영역인 시상하부에서 발현되는 효소를 암호화합니다. 이 유전자 변이는 비만과 높은 체질량 지수(BMI) 경향성과 관련이 있습니다.",
      "BDNF 유전자는 뇌에서 뉴런의 생존과 성장에 중요한 단백질인 뇌 유래 신경 영양 인자를 암호화합니다. BDNF는 음식 섭취 및 체중 조절을 포함하여 다양한 생리적 과정에 관여합니다. 여러 연구에서 BDNF 변이와 BMI 사이의 연관성이 보고되었습니다.",
      "MC4R 유전자는 음식 섭취와 에너지 소비 조절에 관여하는 단백질을 암호화합니다. MC4R은 시상하부에서 발현되며 이 유전자의 돌연변이는 심각한 비만과 관련이 있습니다. 여러 연구에서 MC4R의 일반적인 변이와 다른 집단의 BMI 사이의 연관성이 보고되었습니다.",
    ],
    referenceInfo: [
      "Loos, Ruth J F et al. Nature genetics vol. 40,6 (2008): 768-75.",
      "Willer, Cristen J et al. Nature genetics vol. 41,1 (2009): 25-34.",
      "Wojcik, Genevieve L et al. Nature vol. 570,7762 (2019): 514-518.",
      "Locke, Adam E et al. Nature vol. 518,7538 (2015): 197-206.",
      "Speliotes, Elizabeth K et al. Nature genetics vol. 42,11 (2010): 937-48.",
      "Berndt, Sonja I et al. Nature genetics vol. 45,5 (2013): 501-12.",
    ],
    raceInfo: [
      {
        gene: "FTO",
        rsId: "rs1121980",
        number: "83,216명",
        race: "유럽인",
      },
      {
        gene: "FTO",
        rsId: "rs9939609",
        number: "81,000명 이상",
        race: "유럽인",
      },
      {
        gene: "FTO",
        rsId: "rs11075990",
        number: "49,335명",
        race: "비유럽계",
      },
      {
        gene: "BDNF",
        rsId: "rs11030104",
        number: "339,224명",
        race: "유럽인",
      },
      {
        gene: "MC4R",
        rsId: "rs571312",
        number: "249,796명",
        race: "유럽인",
      },
      {
        gene: "MC4R",
        rsId: "rs8089364",
        number: "263,407명",
        race: "유럽인",
      },
    ],
  },
  Abdominal: {
    name: "복부비만(엉덩이허리비율)",
    define:
      "복부비만(엉덩이허리비율)은 체지방이 과도하게 축적된 비만의 유형을 판단하기 위해 허리와 엉덩이의 비율(Waist-hip raito)을 이용하여 비만을 판단하는 여러 수치 중 하나입니다.",
    reference: "3",
    heredity: "21",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 복부비만(엉덩이허리비율) 위험이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "유산소 운동하기",
        content:
          "주 5회, 최소 30분 동안 평소보다 숨이 조금 더 찰 정도로 운동을 하면 복부 지방을 줄이고 전반적인 건강을 개선하는 데 도움이 될 수 있습니다. 달리기, 걷기, 자전거 타기 또는 수영과 같은 유산소 운동을 추천합니다. 규칙적인 운동은 신진대사를 높이고 칼로리를 태우며 뱃살을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식습관 갖기",
        content:
          "허리 둘레를 줄이기 위해서는 과일, 채소, 통곡물, 저지방 단백질과 같이 섬유질이 많은 음식을 섭취하는 것이 좋습니다. 단 음식, 가공 음식, 고지방 음식을 제한하세요. 설탕이 든 음료 대신 물을 마시는 것과 같은 작은 변화가 전반적인 건강과 허리둘레에 상당한 영향을 미칠 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "만성 스트레스는 체내에서 뱃살을 증가시키는 호르몬인 코르티솔을 생성하게 할 수 있습니다. 요가, 명상과 같은 스트레스 감소 활동을 실천하거나 단순히 자기 관리를 위한 시간을 갖는 것은 코르티솔 수치를 낮추고 복부 비만을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "GRB14 유전자는 인슐린 신호에 관여하고 다양한 집단에서 체질량 지수(BMI) 및 비만과 관련이 있는 단백질을 암호화합니다. 연구에 따르면 GRB14는 복부 비만에 기여할 수 있는 지방 세포 분화 및 인슐린 민감성을 조절하는 역할을 할 수 있습니다.",
      "HOXC13 유전자는 모낭의 발달에 관여하는 유전자이지만, 최근 연구에 따르면 지방세포 분화 및 체중 조절에도 역할을 할 수 있다고 합니다. 일부 연구에서는 HOXC13의 변이가 복부 비만의 지표인 BMI 및 허리 둘레와 관련이 있음을 보여주었습니다.",
      "CPEB4 유전자는 mRNA 번역 조절에 관여하는 단백질을 암호화하며, 최근 연구에 따르면 지방 생성 및 비만 발달에도 역할을 할 수 있는 것으로 나타났습니다. CPEB4의 변이는 BMI 및 허리 둘레와 연관되어 있어 복부 비만 발달에 잠재적인 역할을 시사합니다.",
    ],
    referenceInfo: [
      "Heid, Iris M et al. Nature genetics vol. 42,11 (2010): 949-60.",
      "Shungin, Dmitry et al. Nature vol. 518,7538 (2015): 187-196.",
      "Lotta, Luca A et al. JAMA vol. 320,24 (2018): 2553-2563.",
    ],
    raceInfo: [
      {
        gene: "GRB14",
        rsId: "rs10195252",
        number: "224,459명",
        race: "다인종",
      },
      {
        gene: "HOXC13",
        rsId: "rs1443512",
        number: "224,459명",
        race: "다인종",
      },
      {
        gene: "CPEB4",
        rsId: "rs6861681",
        number: "452,302명",
        race: "유럽인",
      },
    ],
  },
  NFatConcentration: {
    name: "중성지방농도",
    define:
      "중성지방은 체내 지방조직에서 분비되어 에너지원으로 사용되는데, 필요치 않은 에너지가 지방으로 전환될 때 혈중에 중성지방 농도가 증가합니다. 혈중 중성지방 농도의 증가는 심혈관 질환이나 말초혈관질환, 비만이나 조절되지 않는 당뇨의 위험 요소로 알려져 있습니다.",
    reference: "5",
    heredity: "22",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 중성지방농도가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식단 주의하기",
        content:
          "포화 지방과 트랜스 지방, 설탕, 정제된 탄수화물이 많은 식단을 섭취하면 중성지방 수치가 높아질 수 있습니다. 섬유질, 과일, 채소, 저지방 단백질, 오메가-3 지방산과 같은 건강한 지방이 풍부한 식단을 섭취하는 데 집중하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "술자리 줄이기",
        content:
          "유전적으로 중성지방 농도가 높은 경우 건강한 지질 수준을 유지하기 위해 알코올 소비를 제한하는 것이 중요합니다. 알코올은 칼로리가 높고 간에서 중성 지방 생성을 증가시켜 혈중 수치를 높일 수 있기 때문입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "신체 활동은 중성지방 수치를 낮추고 전반적인 심장 건강을 개선하는 데 도움이 될 수 있습니다. 일주일에 5일, 하루 30분 이상 운동하면 근육량 증가, 신진대사 촉진, 과도한 지방 연소에 도움이 됩니다. 운동은 중성지방 수치를 낮출 뿐만 아니라 전반적인 건강과 웰빙도 향상시킵니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "DOCK7 유전자는 세포 이동 및 침입을 조절하는 단백질을 암호화합니다. 중성지방 수치를 포함한 지질 대사 조절과 관련이 있습니다.",
      "FADS1 유전자는 지질 대사에서 중요한 역할을 하는 필수 지방산을 장쇄 고도불포화 지방산으로 전환하는 데 관여하는 효소를 암호화합니다. 이 유전자의 변이는 중성지방 수치의 변화와 관련이 있습니다.",
      "TRIB1 유전자는 중성지방 합성 및 분해에 관여하는 효소를 포함하여 지질 대사에 관여하는 여러 효소의 활성을 조절하는 단백질을 암호화합니다. 이 유전자의 변이는 중성지방 수치의 변화와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Kamatani, Yoichiro et al. Nature genetics vol. 42,3 (2010): 210-5.",
      "Bryant, Emily K et al. PloS one vol. 8,5 e63469. 22 May. 2013,",
      "Kathiresan, Sekar et al. Nature genetics vol. 40,2 (2008): 189-97.",
      "Willer, Cristen J et al. Nature genetics vol. 45,11 (2013): 1274-1283.",
    ],
    raceInfo: [
      {
        gene: "DOCK7",
        rsId: "rs10889353",
        number: "14,700명",
        race: "일본인",
      },
      {
        gene: "TRIB1",
        rsId: "rs17321515",
        number: "27,370명",
        race: "유럽인",
      },
      {
        gene: "TRIB1",
        rsId: "rs2954029",
        number: "188,577명",
        race: "다인종",
      },
    ],
  },
  LDL: {
    name: "LDL 콜레스테롤 농도",
    define:
      "LDL 콜레스테롤은 말초 조직으로 콜레스테롤을 운반하는 역할을 하는 콜레스테롤입니다.",
    reference: "4",
    heredity: "8",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 LDL 콜레스테롤 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 섭취하기",
        content:
          "과일, 채소, 통곡물 및 기름기 없는 단백질이 풍부한 식단은 LDL 콜레스테롤 수치를 낮추고 심장 건강을 개선하는 데 도움이 될 수 있습니다. 포화 지방과 트랜스 지방이 적은 음식 섭취에 집중하세요. 이러한 유형의 지방은 LDL 콜레스테롤 수치를 높이는 것으로 알려져 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적정 체중 유지하기",
        content:
          "과체중 또는 비만은 LDL 콜레스테롤 수치를 증가시킬 수 있으므로 건강한 체중을 유지하는 것이 중요합니다. 적정 체중을 유지하기 위해 칼로리 섭취를 줄이고 신체 활동을 늘리는 데 집중하세요. 콜레스테롤 수치를 낮추고 심혈관계 기능을 개선하는데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 신체 활동은 LDL 콜레스테롤 수치를 낮추어 심혈관계 기능을 개선하는 것으로 나타났습니다. 일주일에 5일, 하루 30분 이상 운동하면 근육량 증가, 신진대사 촉진, 과도한 지방 연소에 도움이 됩니다. 운동은 LDL 콜레스테롤 수치를 낮출 뿐만 아니라 전반적인 건강과 웰빙도 향상시킵니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "APOC1 유전자는 LDL 콜레스테롤의 구성 요소인 아포지단백 C-I 생성에 관여합니다. 이 유전자의 변이는 아포지단백 C-I 수치에 영향을 미칠 수 있으며, 결과적으로 LDL 콜레스테롤 수치에 영향을 미칩니다.",
      "PSRC1 유전자는 혈류에서 LDL 콜레스테롤을 제거하는 역할을 하는 LDL 수용체의 활동을 조절하는 데 도움이 됩니다. 이 유전자의 변이는 LDL 수용체의 활동에 변화를 일으켜 LDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
      "LDLR 유전자 혈류에서 LDL 콜레스테롤을 제거하는 LDL 수용체를 암호화합니다. 이 유전자의 변이는 LDL 콜레스테롤 수치에 영향을 줄 수 있는 LDL 수용체 기능의 변화를 초래할 수 있습니다.",
      "CETP 유전자는 고밀도 지단백질(HDL)과 저밀도 지단백질(LDL)을 포함한 지단백질 사이에서 콜레스테롤 에스테르를 전달하는 역할을 하는 단백질을 암호화합니다. CETP는 콜레스테롤 에스테르가 HDL에서 LDL로 이동하는 것을 촉진하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
      "LIPC 유전자는 HDL 콜레스테롤을 비롯한 지질 대사에 관여하는 단백질을 암호화합니다. LIPC는 간에서 발현되며 HDL에서 트리글리세리드와 인지질의 가수분해에 역할을 하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Teslovich, Tanya M et al. Nature vol. 466,7307 (2010): 707-13.",
      "Kathiresan, Sekar et al. Nature genetics vol. 41,1 (2009): 56-65.",
      "Sandhu, Manjinder S et al. Lancet (London, England) vol. 371,9611 (2008): 483-91.",
      "Willer, Cristen J et al. Nature genetics vol. 40,2 (2008): 161-9.",
    ],
    raceInfo: [
      {
        gene: "APOC1",
        rsId: "rs4420638",
        number: "100,000명 이상",
        race: "유럽계",
      },
      {
        gene: "PSRC1",
        rsId: "rs599839",
        number: "16,664명",
        race: "유럽계",
      },
      {
        gene: "LDLR",
        rsId: "rs6511720",
        number: "100,000명 이상",
        race: "유럽계",
      },
    ],
  },
  HDL: {
    name: "HDL 콜레스테롤 농도",
    define:
      "HDL 콜레스테롤은 잉여 콜레스테롤을 간으로 운반하여 배설하도록 돕는 역할을 하는 콜레스테롤입니다.",
    reference: "5",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 HDL 콜레스테롤 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "금연하기",
        content:
          "흡연은 HDL 콜레스테롤 수치에 부정적인 영향을 미치므로 금연은 심장 건강에 필수적입니다. 금연에 도움이 필요하면 니코틴 대체 요법이나 지원 그룹과 같은 이용 가능한 자원에 대해 의사와 상담하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 섭취하기",
        content:
          "과일, 채소, 통곡물, 저지방 단백질이 풍부한 식단은 콜레스테롤 수치를 개선하는 데 도움이 되는 것으로 나타났습니다. LDL 콜레스테롤을 높이고 HDL 콜레스테롤을 낮출 수 있는 포화 지방과 트랜스 지방의 섭취를 제한하세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "유산소 운동하기",
        content:
          "정기적인 유산소 운동은 HDL 콜레스테롤 수치를 증가시켜 심혈관계 기능을 개선하는 데 도움이 되는 것으로 나타났습니다. 빠르게 걷기나 자전거 타기와 같은 중간 정도의 운동을 주 5회, 최소 30분 이상 하는 것을 목표로 하십시오. 운동은 HDL 콜레스테롤 수치를 증가 시킬 뿐만 아니라 전반적인 건강과 웰빙도 향상시킵니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CETP 유전자는 고밀도 지단백질(HDL)과 저밀도 지단백질(LDL)을 포함한 지단백질 사이에서 콜레스테롤 에스테르를 전달하는 역할을 하는 단백질을 암호화합니다. CETP는 콜레스테롤 에스테르가 HDL에서 LDL로 이동하는 것을 촉진하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
      "LIPC 유전자는 HDL 콜레스테롤을 비롯한 지질 대사에 관여하는 단백질을 암호화합니다. LIPC는 간에서 발현되며 HDL에서 트리글리세리드와 인지질의 가수분해에 역할을 하여 HDL 콜레스테롤 수치에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Oh, Seung-Won et al. PloS one vol. 15,1 e0227357. 7 Jan. 2020,",
      "Lee, Ying-Hui et al. Genetics and molecular biology vol. 45,1 e20210280. 21 Feb. 2022,",
      "Willer, Cristen J et al. Nature genetics vol. 40,2 (2008): 161-9.",
    ],
    raceInfo: [
      {
        gene: "CETP",
        rsId: "rs7499892",
        number: "9,676명",
        race: "한국인",
      },
      {
        gene: "CETP",
        rsId: "rs17231506",
        number: "41,526명",
        race: "타이완인",
      },
      {
        gene: "LIPC",
        rsId: "rs4775041",
        number: "17,637명",
        race: "유럽인종",
      },
    ],
  },
  EffectLosingWeight: {
    name: "운동에 의한 체중감량효과",
    define:
      "체중은 에너지 소비와 공급의 불균형으로 증가 또는 감소합니다. 체중 감량의 기본 원칙이 섭취하는 에너지보다 더 많은 양의 에너지를 소모하는 것이므로 가장 대표적인 에너지 소모 활동인 운동을 통해 체중 감량을 하나, 그 효과는 에너지 대사에 관여하는 건강 상태나 평소 활동량 등 신체적, 환경적, 사회적 원인에 따라 다를 수 있습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 운동에 의한 체중감량효과가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "가공 식품 피하기",
        content:
          "가공 식품에는 액상과당, 건강에 해로운 지방 및 인공 성분이 많이 들어 있습니다. 이러한 음식의 섭취를 줄임으로써 칼로리 섭취를 줄이고 식욕과 혈당 수치를 조절하며 건강한 체중을 유지할 수 있습니다. 건강한 체중을 유지하기 위해 신선한 과일과 채소, 저지방 단백질, 통곡물을 선택하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "충분한 수면 취하기",
        content:
          "수면은 체중 관리의 핵심 요소이며 적절한 수면은 배고픔과 포만감을 조절하는 렙틴과 그렐린과 같은 호르몬을 조절하는 데 도움이 됩니다. 매일 밤 7-8시간의 수면을 취하고 체중 감량 노력을 지원하기 위해 일관된 수면 일정을 세우세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 감소시키기",
        content:
          "스트레스는 과식으로 이어질 수 있으며, 특히 편안한 음식을 찾게 만드는 경우 더욱 그렇습니다. 심호흡, 명상 또는 운동과 같이 스트레스를 관리하는 건강한 방법을 찾으세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FTO 유전자는 누군가가 먹고 싶어하는 음식의 양과 그들이 소모하는 에너지의 양에 영향을 미침으로써 체중을 ​​조절하는 역할을 하는 것으로 생각됩니다. 일부 연구에서는 FTO 유전자의 특정 변이를 가진 개인이 운동을 통해 체중을 줄이는 데 어려움을 겪을 수 있다고 제안했습니다.",
    ],
    referenceInfo: [
      "Mitchell, Jonathan A et al. Obesity (Silver Spring, Md.) vol. 18,3 (2010): 641-3.",
    ],
    raceInfo: [
      {
        gene: "FTO",
        rsId: "rs8050136",
        number: "234명",
        race: "백인여성",
      },
    ],
  },
  BloodSugar: {
    name: "혈당",
    define:
      "혈당은 혈액 속에 함유된 포도당 농도를 말합니다. 여기서 탄수화물이 대사된 단당류인 포도당은 뇌와 적혈구의 에너지원이므로 생명 활동 유지에 필수적입니다. 혈액 내 당 수치의 항상성을 유지하는 것이 중요한데, 혈당 농도는 인슐린 호르몬에 의해 조절됩니다.",
    reference: "7",
    heredity: "9",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 6종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 적정 혈당인 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "꾸준히 운동하기",
        content:
          "규칙적인 운동은 혈당 수치를 조절하는 데 도움이 되는 인슐린 감수성을 개선하는 데 도움이 됩니다. 빠르게 걷기, 자전거 타기, 등산, 에어로빅 등 심박수를 높이고 활동성을 유지할 수 있는 운동이 좋습니다. 일주일에 5일, 하루 30분 이상 운동하는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "식사량 조절하기",
        content:
          "한 번에 많은 양의 음식을 섭취하면 혈당 수치가 급상승할 수 있습니다. 대신, 혈당 수치를 조절하는 데 도움이 되도록 하루 종일 더 작고 더 자주 식사하는 것을 목표로 하십시오. 또한, 혈당 수치를 높일 수 있는 고지방, 고당 식품을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "혈당 수치 모니터링 하기",
        content:
          "혈당 수치를 정기적으로 모니터링하는 것은 혈당을 더 잘 관리하는 데 필수적입니다. 혈당 수치를 기록하면 패턴이나 추세를 파악하고 필요에 따라 식단과 생활 방식을 조정하는 데 도움이 될 수 있습니다. 의사와 상의하여 혈당 모니터링의 빈도와 시기를 결정하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "MTNR1B 유전자는 수면과 신체의 내부 시계 조절에 관여하는 멜라토닌 호르몬의 생성을 조절하는 데 도움을 줍니다. 이 유전자의 변이는 혈당 수치에 영향을 줄 수 있는 인슐린을 조절하는 신체의 능력에 영향을 줄 수 있습니다.",
      "G6PC2 유전자는 포도당 대사 및 인슐린 생산 조절에 관여합니다. 이 유전자의 변이는 인슐린 수치와 혈당 수치를 조절하는 신체의 능력에 영향을 미칠 수 있습니다.",
      "CDKN2A 유전자는 세포 주기와 세포 성장을 조절하는 데 도움이 되는 단백질을 암호화합니다. 이 유전자의 변이는 높은 혈당 수치와 관련이 있습니다.",
      "GCK 유전자는 포도당 대사 조절에 관여하는 글루코키나제라는 효소를 암호화합니다. 이 유전자의 변이는 혈당 수치를 조절하는 신체의 능력에 영향을 미칠 수 있습니다.",
      "GCKR 유전자는 포도당과 지방 대사 조절에 관여합니다. 이 유전자의 변이는 혈당 수치에 영향을 줄 수 있는 인슐린 저항성과 포도당 내성에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Oh, Seung-Won et al. PloS one vol. 15,1 e0227357. 7 Jan. 2020,",
      "Kim, Minjoo et al. Scientific reports vol. 8,1 7828. 18 May. 2018,",
      "Hwang, Joo-Yeon et al. Diabetes vol. 64,1 (2015): 291-8.",
      "Wu, Ying et al. Diabetes vol. 57,10 (2008): 2834-42.",
      "Sinnott-Armstrong, Nasa et al. Nature genetics vol. 53,2 (2021): 185-194.",
    ],
    raceInfo: [
      {
        gene: "MTNR1B",
        rsId: "rs10830963",
        number: "9,676명",
        race: "한국인",
      },
      {
        gene: "CDKN2A",
        rsId: "rs10811661",
        number: "46,085명",
        race: "동아시아인",
      },
      {
        gene: "GCK",
        rsId: "rs16881016",
        number: "363,228명",
        race: "영국인",
      },
      {
        gene: "GCK",
        rsId: "rs730497",
        number: "46,085명",
        race: "동아시아인",
      },
      {
        gene: "GCKR",
        rsId: "rs780094",
        number: "46,085명",
        race: "동아시아인",
      },
      {
        gene: "GCKR",
        rsId: "rs1260326",
        number: "10만명 이상",
        race: "다인종",
      },
    ],
  },
  BloodPressure: {
    name: "혈압",
    define:
      "혈압은 심장의 수축으로 방출된 혈액이 혈관을 따라 흐르며 혈액이 혈관벽에 가하는 압력을 말하며, 심박, 체온, 호흡과 함께 가장 중요한 생명징후(vital sign)로 본다. 혈압은 심장 박동에 따라 심장 주기 동안 측정된 최대값을 수축기 혈압, 최소값을 이완기 혈압이라고 합니다.",
    reference: "2",
    heredity: "15",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 적정 혈압인 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 유지하기",
        content:
          "과일, 채소, 통곡물, 저지방 유제품이 풍부한 식단은 혈압을 낮추는 데 도움이 될 수 있습니다. 과도한 염분 섭취를 피하고 덜 가공된 음식을 섭취하고 포화 지방 및 트랜스 지방 섭취를 줄이는 것도 혈압 조절에 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적정 체중 유지하기",
        content:
          "과체중과 비만은 고혈압의 주요 위험 요소이므로 건강한 체중을 유지하는 것이 혈압 관리에 중요합니다. 균형 잡힌 식사를 하고 규칙적인 운동을 하면 개인이 건강한 체중을 달성하고 유지하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술과 담배 줄이기",
        content:
          "알코올과 니코틴을 과도하게 섭취하면 혈압이 상승할 수 있으므로 이러한 물질을 제한하는 것이 중요합니다. 금연하고 알코올 소비를 적당한 수준으로 줄이면 건강한 혈압 수준을 유지하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ATP2B1 유전자는 혈관 평활근 세포에서 칼슘 신호 조절을 통해 혈압 조절에 관여하는 칼슘 수송 ATPase를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 높은 혈압과 관련이 있습니다.",
      "CSK 유전자는 키나아제의 Src 계열의 활성을 억제함으로써 혈압 조절에 관여하는 티로신 키나아제를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 높은 혈압과 관련이 있습니다.",
      "NT5C2 유전자는 혈관 긴장도와 혈압의 중요한 조절자인 세포외 뉴클레오티드의 대사에 관여하는 뉴클레오티다아제를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 높은 혈압과 관련이 있습니다.",
      "CASZ1 유전자는 심혈관계 발달에 관여하는 전사 인자를 암호화합니다. 이 유전자의 변이체는 여러 집단에서 혈압 조절 및 높은 혈압과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Ehret, Georg B et al. Nature genetics vol. 48,10 (2016): 1171-1184.",
      "Wain, Louise V et al. Nature genetics vol. 43,10 1005-11. 11 Sep. 2011,",
    ],
    raceInfo: [
      {
        gene: "ATP2B1",
        rsId: "rs11105354",
        number: "342,415명",
        race: "유럽인",
      },
      {
        gene: "ATP2B1",
        rsId: "rs17249754",
        number: "122,671명",
        race: "유럽인",
      },
    ],
  },
  BoneMass: {
    name: "골질량",
    define:
      "골질량은 절대적인 뼈의 무게를 의미하므로 골밀도와 달리 뼈를 얼마나 많이 가지고 있는가를 나타낸다.",
    reference: "2",
    heredity: "32",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 적정 골질량인 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "규칙적인 운동하기",
        content:
          "걷기, 달리기, 춤, 근력 운동과 같은 체중 부하 운동은 골밀도를 높이고 유지하는 데 도움이 될 수 있습니다. 이러한 운동은 뼈 형성 세포를 자극하여 새로운 뼈 조직을 생성하여 뼈를 더 강하게 만듭니다. 운동은 또한 균형, 조정 및 자세를 개선하여 낙상 및 골절의 위험을 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 유지하기",
        content:
          "칼슘과 비타민 D의 적절한 섭취는 튼튼한 뼈를 만들고 유지하는 데 필수적입니다. 칼슘이 풍부한 식품에는 유제품, 잎이 많은 녹색 채소 및 강화 식품이 포함됩니다. 비타민 D는 지방이 많은 생선, 달걀 노른자 및 강화 식품에서 발견되지만 신체는 피부가 햇빛에 노출될 때도 비타민 D를 생성할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술과 담배 줄이기",
        content:
          "과도한 흡연과 음주는 뼈 건강에 부정적인 영향을 미칠 수 있으며, 이는 뼈 손상 위험을 증가시킬 수 있습니다. 특히 유전적으로 골질량이 낮은 경향이 있는 사람들은 흡연을 피하고 알코올 섭취를 제한하는 것이 중요합니다. 이러한 조치는 뼈 건강을 유지하고 골질량 감소로 인한 잠재적인 문제를 최소화하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "COLEC10 유전자는 뼈 성장 및 형성 조절에 관여합니다. 이 유전자의 변이는 뼈 성장 속도에 영향을 주어 뼈를 더 약하게 만들고 골절에 더 취약하게 만듭니다.",
      "AKAP11 유전자는 뼈 성장 및 리모델링에 중요한 칼슘 신호를 조절하는 데 도움이 됩니다. AKAP11의 돌연변이는 골밀도 감소와 관련이 있습니다.",
      "WNT16 유전자는 뼈 형성에 관여하는 세포의 생산을 조절하는 데 도움을 줍니다. 이 유전자의 변이는 생성되는 뼈 조직의 양에 영향을 주어 골량을 감소시킬 수 있습니다.",
    ],
    referenceInfo: [
      "Medina-Gomez, Carolina et al. American journal of human genetics vol. 102,1 (2018): 88-102.",
      "Kemp, John P et al. PLoS genetics vol. 10,6 e1004423. 19 Jun. 2014,",
    ],
    raceInfo: [
      {
        gene: "COLEC10",
        rsId: "rs11995824",
        number: "66,628명",
        race: "다인종",
      },
      {
        gene: "COLEC10",
        rsId: "rs2450083",
        number: "4,890명",
        race: "영국인",
      },
    ],
  },
  Degenerative: {
    name: "퇴행성관절염증감수성",
    define:
      "퇴행성관절염증감수성이란 퇴행성관절염증에 잘 걸리는 정도를 의미합니다. 퇴행성 관절염은 관절을 보호하고 있는 연골의 손상이나 퇴행성 변화로 인해 관절을 이루는 뼈와 인대 등에 손상이 생겨 염증과 통증이 발생합니다.",
    reference: "3",
    heredity: "7",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 퇴행성관절염증 감수성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "균형 잡힌 식단 섭취하기",
        content:
          "과일, 채소, 통곡물, 저지방 단백질 및 건강한 지방과 같은 영양이 풍부한 식품이 풍부한 균형 잡힌 식단을 섭취하면 건강한 관절 기능을 유지하고 신체의 염증을 줄이는 데 도움이 될 수 있습니다. 또한 글루코사민 및 콘드로이틴과 같은 보충제는 관절 통증을 줄이고 운동성을 향상시키는 것으로 나타나 퇴행성 관절염 환자에게 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "체중 조절 및 운동하기",
        content:
          "과체중이나 비만은 관절, 특히 무릎, 엉덩이, 그리고 허리에 추가적인 스트레스를 줄 수 있습니다. 이로 인해 관절의 염증과 통증이 발생할 수 있습니다. 정기적인 운동은 관절 염증을 완화하고 유지 관리하는 데 중요합니다. 그러나 운동 후 통증이 발생하면 너무 많은 부담을 주지 않고 적절한 휴식과 회복 기간을 확보하는 것이 필요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "GNL3 유전자는 뼈의 성장과 회복 과정을 조절합니다. GNL3 유전자의 변이는 뼈 구조의 변화로 이어지고 퇴행성관절염증감수성에 영향을 줄 수 있습니다.",
      "PTHLH 유전자는 뼈의 성장과 재형성을 조절하는 역할을 합니다. 관절염 예방에 중요한 뼈의 강도와 안정성에 영향을 주어 퇴행성 관절염에 대한 감수성에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "arcOGEN Consortium et al. Lancet (London, England) vol. 380,9844 (2012): 815-23.",
      "Styrkarsdottir, Unnur et al. Nature genetics vol. 50,12 (2018): 1681-1687.",
      "Tachmazidou, Ioanna et al. Nature genetics vol. 51,2 (2019): 230-236.",
    ],
    raceInfo: [
      {
        gene: "GNL3",
        rsId: "rs11177",
        number: "50만명 이상",
        race: "유럽인",
      },
      {
        gene: "PTHLH",
        rsId: "rs10492367",
        number: "40만명 이상",
        race: "영국인",
      },
    ],
  },
  UricAcid: {
    name: "요산치",
    define:
      "요산은 세포가 수명을 다하고 핵산이 유리된 후 핵산의 구성성분인 퓨린이라는 물질이 간에서 대사되면서 생기는 최종 분해 산물로 신장을 거쳐 소변으로 배설됩니다. 체내 요산 생성이 증가하거나 배설이 감소하는 등 체내 요산이 축적되면 혈액 내 요산이 정상보다 높아 고요산혈증이 생기는 것으로 알려져 있습니다.",
    reference: "6",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 요산치가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "식단에 주의하기",
        content:
          "붉은 고기, 내장육 및 특정 해산물과 같은 퓨린이 많은 음식은 요산 수치를 증가시킬 수 있습니다. 따라서 식단에서 이러한 음식을 제한하고 대신 과일, 채소 및 통곡물과 같은 저 퓨린 식품을 더 많이 섭취하는 데 집중하는 것이 중요합니다. 또한 충분한 양의 물을 마셔 수분을 유지하면 신체내 과도한 요산을 제거하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 신체 활동은 더 나은 순환을 촉진하고 신체가 요산을 포함한 노폐물을 보다 효과적으로 제거할 수 있도록 하여 요산 수치를 낮추는 데 도움이 될 수 있습니다. 일주일에 5일, 30분 이상 빠르게 걷기 또는 자전거 타기와 같은 적당한 운동을 하는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술자리 피하기",
        content:
          "알코올, 특히 맥주와 주류는 요산 수치를 높일 수 있습니다. 술을 마시는 경우 적당한 양으로 제한하고 폭음을 피하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "GCKR 유전자는 포도당 대사 조절에 영향을 주어 요산 수치에 영향을 줄 수 있습니다. GCKR 유전자의 변이체는 더 높은 요산 수치를 유발할 수 있는 포도당 대사의 변화와 관련이 있습니다.",
      "ABCG2 유전자는 요산을 비롯한 세포막을 통한 물질 수송에 관여합니다. ABCG2 유전자의 변이는 요산 운반 능력을 변경하여 혈중 요산 수치에 영향을 줄 수 있습니다.",
      "SLC2A9 유전자는 요산을 세포로 흡수하는 데 관여합니다. SLC2A9 유전자의 변이는 요산 수치의 변화로 이어질 수 있는 요산 수송의 변화와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Nakatochi, Masahiro et al. Communications biology vol. 2 115. 8 Apr. 2019,",
      "Ho, Li-Ju et al. Scientific reports vol. 12,1 5519. 1 Apr. 2022,",
      "Yang, Binyao et al. BMC medical genomics vol. 7 10. 11 Feb. 2014,",
      "Cheng, Shih-Tsung et al. Journal of the Formosan Medical Association = Taiwan yi zhi vol. 116,1 (2017): 18-23.",
      "Voruganti, V Saroja et al. European journal of human genetics : EJHG vol. 22,7 (2014): 938-43.",
      "Dehghan, Abbas et al. Lancet (London, England) vol. 372,9654 (2008): 1953-61.",
    ],
    raceInfo: [
      {
        gene: "GCKR",
        rsId: "rs1260326",
        number: "121,745명",
        race: "일본인",
      },
      {
        gene: "ABCG2",
        rsId: "rs2231142",
        number: "12,281명",
        race: "중국인",
      },
      {
        gene: "SLC2A9",
        rsId: "rs10805346",
        number: "3,604명",
        race: "아메리카 인디언",
      },
      {
        gene: "SLC2A9",
        rsId: "rs16890979",
        number: "26,718명",
        race: "미국인, 네덜란드인",
      },
    ],
  },
  TravelSickness: {
    name: "멀미",
    define:
      "멀미는 운송수단의 이용 등과 같은 어떤 자극에 의해 신체의 균형을 담당하는 전정 감각기관의 정보가 시각 정보와 일치하지 않는 등의 자율신경계 이상으로 인한 불편함을 말하며, 어지러움, 메스꺼움, 구토, 두통 등의 증상이 일반적으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 멀미 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "생활 습관 주의하기",
        content:
          "간단한 라이프스타일 변화는 멀미 증상을 완화하는 데 도움이 될 수 있습니다. 예를 들어 자동차나 비행기의 앞좌석에 앉아 앞을 똑바로 바라보면 멀미 증상을 줄이는 데 도움이 될 수 있습니다. 여행 전과 여행 중에 과식, 음주, 흡연을 피하는 것도 도움이 될 수 있습니다. 또한 여행 전에 충분한 휴식을 취하고 수분을 유지하면 멀미를 경험할 가능성을 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "약물의 도움받기",
        content:
          "특정 일반의약품 및 처방약은 멀미 증상을 완화하는 데 도움이 될 수 있습니다. 그러나 어떤 약물이든 부작용을 일으키고 다른 약물과 상호 작용할 수 있으므로 약물을 복용하기 전에 의사나 약사와 상담하는 것이 필수적입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "민간 요법 실천하기",
        content:
          "멀미 증상을 완화하는 데 도움이 되는 몇 가지 민간 요법이 있습니다. 예를 들어 손목의 특정 지점을 지압하거나 생강이 함유된 생강차, 캡슐, 사탕 등을 섭취하면 메스꺼움과 구토를 줄이는 데 효과적일 수 있습니다. 그러나 민간 요법이 도움이 될 수는 있지만 약물만큼 강력하지 않을 수 있으며 그 효능은 사람마다 다를 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "LINGO2 유전자는 신경계의 발달과 유지에 관여하는 유전자입니다. 우리의 균형 감각에 관여하는 내이를 포함하여 신경 세포의 기능을 조절하는 역할을 합니다. 연구에 따르면 LINGO2 유전자의 변이가 멀미에 대한 감수성에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Hromatka, Bethann S et al. Human molecular genetics vol. 24,9 (2015): 2700-8.",
      "Astros Th. Skuladottir et al. Commun Biol. 2021 Oct 7;4(1):1148",
    ],
    raceInfo: [
      {
        gene: "LINGO2",
        rsId: "rs2150864",
        number: "80,494명",
        race: "다인종",
      },
    ],
  },
  AlcoholMetabolism: {
    name: "알코올대사",
    define:
      "술의 주성분인 에틸 알코올이 체내에 들어오면 일부는 위에서, 대부분은 소장에서 흡수되고 장 점막을 통해 혈액 내로 들어와 혈관을 통해 순환하다, 간에서 대사되어 체외로 배출되는데 이러한 일련의 과정을 알코올대사라 합니다. 이때 알코올이 분해효소에 의해 아세트알데하이드, 아세트산으로 전환되고 최종적으로 이산화탄소와 물로 분해되는 과정에 관여하는 분해효소의 활성 정도 등은 신체적, 환경적, 유전적 요인에 의해 영향을 받는 것으로 알려져 있습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 알코올대사가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "폭음 피하기",
        content:
          "알코올 대사에 관한 해결책으로는 폭음을 피하는 것이 중요합니다. 폭음은 알코올을 느리게 대사하는 사람들에게 특히 위험할 수 있습니다. 여성의 경우 폭음이란 하루에 5잔 이상의 술을, 남성의 경우 7잔 이상의 술을 마시는 것을 의미합니다. 이는 건강에 심각한 위험을 초래할 수 있기 때문에 폭음을 피하는 것이 권장됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 마시기",
        content:
          "알코올대사가 낮은 경우 물을 많이 마시면 ​​신체 내 알코올을 희석하고 효과를 줄이는 데 도움이 될 수 있습니다. 술을 마시기 전, 술을 마시는 동안, 술을 마신 후에 적어도 한 잔의 물을 마시는 것을 목표로 하십시오. 이것은 또한 알코올의 영향을 악화시킬 수 있는 탈수 위험을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식사하기",
        content:
          "술을 마시기 전에 식사를 하면 알코올의 흡수를 늦추어 신체에 미치는 영향을 줄일 수 있습니다. 식사에 포함된 탄수화물과 단백질이 알코올이 혈류로 흡수되는 속도를 늦출 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ADH1B 유전자는 알코올을 아세트알데히드로 분해하는 데 도움을 주는 효소를 암호화합니다. ADH1B 유전자의 다양한 변이는 이 분해가 얼마나 빨리 발생하는지에 영향을 미쳐 알코올대사 및 내성의 차이로 이어질 수 있습니다.",
    ],
    referenceInfo: [
      "Matoba, Nana et al. Nature human behaviour vol. 4,3 (2020): 308-316.",
    ],
    raceInfo: [
      {
        gene: "ADH1B",
        rsId: "rs1229984",
        number: "5,632명/58,610명",
        race: "마국인/일본인",
      },
    ],
  },
  AlcoholDependence: {
    name: "알코올의존성",
    define:
      "알코올의존성이란, 알코올 섭취에 의한 정신적 효과를 얻기 위해 또는 알코올을 섭취하지 않았을 때의 불편함을 피하기 위해 지속적으로 알코올을 섭취하지 않으면 안되는 경향 또는 상태를 의미합니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 알코올의존성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "음주 유발 요인 피하기",
        content:
          "유발 요인을 피하는 것은 알코올 의존도를 줄이는 데 중요한 부분이며 새로운 습관을 개발하려면 시간과 노력이 필요합니다. 음주를 하고 싶은 욕구를 유발하는 상황, 감정 및 사람을 기억하고 피하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "대체재 찾기",
        content:
          "술을 끊으면 스트레스와 불안감이 커질 수 있으므로 이러한 감정을 관리할 수 있는 다른 방법을 찾는 것이 중요합니다. 음주와 관련되지 않은 운동, 새로운 취미 시도 또는 사랑하는 사람과 시간을 보내는 것처럼 새롭고 신나는 활동으로 대체해 보세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 줄이기",
        content:
          "스트레스는 알코올 소비의 주요 원인이 될 수 있습니다. 심호흡, 마음 챙김 또는 운동과 같은 스트레스 관리 기술을 연습하면 스트레스와 갈망을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ALDH2 유전자는 알코올이 대사될 때 생성되는 아세트알데히드라는 독성 물질을 분해하는 데 관여하는 효소를 암호화합니다. ALDH2 유전자의 일부 변이는 이 효소의 활성을 감소시켜 안면 홍조, 빠른 심장 박동 및 음주를 덜 즐겁게 만드는 기타 증상을 유발할 수 있습니다. 이러한 변이가 있는 사람들은 많은 양의 알코올을 마실 가능성이 낮으므로 알코올 의존증이 발생할 가능성이 적습니다.",
      "ADH1B 유전자는 간에서 알코올 분해에 관여하는 효소를 암호화합니다. ADH1B 유전자의 일부 변이는 이 효소의 활성을 증가시켜 알코올대사를 촉진하고 알코올의존성 발생 영향을 감소시킵니다.",
    ],
    referenceInfo: [
      "Jorgenson, E et al. Molecular psychiatry vol. 22,9 (2017): 1359-1367.",
      "Sun, Yan et al. Translational psychiatry vol. 9,1 249. 7 Oct. 2019,",
      "Walters, Raymond K et al. Nature neuroscience vol. 21,12 (2018): 1656-1669.",
    ],
    raceInfo: [
      {
        gene: "ALDH2",
        rsId: "rs671",
        number: "86,627명",
        race: "비히스패닉 백인, 히스패닉/라틴계, 동아시아인 및 아프리카계 미국인",
      },
      {
        gene: "ADH1B",
        rsId: "rs1229984",
        number: "52,848명",
        race: "유럽인, 아프리카인",
      },
    ],
  },
  AlcoholFlush: {
    name: "알코올 홍조",
    define:
      "홍조는 피부가 붉어지는 현상으로 알코올홍조는 알코올대사가 원활하지 않아 분해되지 못한 독소의 영향으로 얼굴 등에 있는 혈관이 팽창되고 혈관 확장으로 달아오르는 느낌과 함께 붉어지는 현상을 말합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 알코올홍조 경험 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "술 천천히 마시기",
        content:
          "술을 천천히 마시면 알코올홍조 증상의 강도를 줄이는 데 도움이 될 수 있습니다. 알코올홍조가 느껴진다면 속도를 늦추는 것이 가장 좋습니다. 음주 사이에 음료수를 마거나 휴식을 취하는 것도 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 마시기",
        content:
          "알코올홍조가 쉽게 일어나는 경우 물을 많이 마시면 ​​알코올홍조 증상을 완화하는 데 도움이 될 수 있습니다. 술을 마시기 전, 술을 마시는 동안, 술을 마신 후에 적어도 한 잔의 물을 마시는 것을 목표로 하십시오. 이것은 또한 알코올의 영향을 악화시킬 수 있는 탈수 위험을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "증상 인지하기",
        content:
          "발적, 가려움, 홍조와 같은 알코올성 홍조 증상을 인지하고 증상이 발생하면 이를 완화하기 위한 조치를 취하세요. 물을 마시거나 신선한 공기를 마시면 알코올홍조 증상을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "ALDH2 유전자는 알코올이 대사될 때 생성되는 아세트알데히드라는 독성 물질을 분해하는 데 관여하는 효소를 암호화합니다. ALDH2 유전자의 일부 변이는 이 효소의 활성을 감소시켜 안면 홍조, 빠른 심장 박동 및 음주를 덜 즐겁게 만드는 기타 증상을 유발할 수 있습니다.",
    ],
    referenceInfo: [
      "Cho, Yoonsu et al. Scientific reports vol. 8,1 458. 11 Jan. 2018,",
      "Wall, T L et al. Alcoholism, clinical and experimental research vol. 16,5 (1992): 991-5.",
    ],
    raceInfo: [
      {
        gene: "ALDH2",
        rsId: "rs671",
        number: "2,011명",
        race: "한국인",
      },
    ],
  },
  NicotineMetabolism: {
    name: "니코틴대사",
    define:
      "니코틴은 담배의 주성분으로 알려져 있어 흡연을 통해 체내로 들어와 우리 뇌의 중추와 말초의 신경을 흥분시키는 물질로 작용하는데, 니코틴대사는 니코틴이 체내에서 제거되어 배출되는 일련의 과정을 말합니다. 관련 효소 활성도, 간 기능 등에 영향을 받는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 니코틴대사가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "니코틴 줄이기",
        content:
          "니코틴대사가 낮은 사람들은 니코틴 함유 제품을 사용할 때 추가 예방 조치를 취하고 건강을 개선하기 위해 금연을 고려하는 것이 중요합니다. 라이트 또는 울트라 라이트 담배와 같이 니코틴 농도가 낮은 니코틴 제품 사용을 고려하세요. 니코틴 껌, 사탕 또는 패치와 같은 니코틴 대체 요법 제품도 금단 증상을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "대체재 찾기",
        content:
          "니코틴을 끊으면 스트레스와 불안감이 커질 수 있으므로 운동, 심호흡 또는 취미 등 이러한 감정을 관리할 수 있는 다른 방법을 찾는 것이 중요합니다. 흡연 대신 할 수 있는 건강한 대체 활동을 찾으세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 줄이기",
        content:
          "스트레스는 흡연의 일반적인 원인이며 금연을 더 어렵게 만들 수 있습니다. 심호흡, 마음 챙김 또는 운동과 같은 스트레스 관리 기술을 연습하면 스트레스와 갈망을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CYP2B6 유전자는 약물 대사에서 중요한 역할을 하는 효소를 암호화합니다. 특히 CYP2B6는 담배 연기에서 발견되는 니코틴 및 기타 화학 물질의 대사에 관여합니다. CYP2B6 유전자의 변이는 개인의 니코틴대사 능력에 영향을 미칠 수 있으며, 이는 흡연 행동 및 중독 감수성에 영향을 미칠 수 있습니다.",
      "CYP2A6 유전자는 간에서 니코틴대사를 주로 담당하는 효소를 암호화하며. 이 효소는 니코틴을 주요 대사 산물로 전환하는 데 관여합니다. CYP2A6 유전자의 변이는 니코틴이 대사되는 속도에 영향을 미치는 것으로 나타났으며, 이는 니코틴 대체 요법 및 금연 노력의 효능에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Loukola, Anu et al. PLoS genetics vol. 11,9 e1005498. 25 Sep. 2015,",
      "Chenoweth, Meghan J et al. Scientific reports vol. 11,1 19572. 1 Oct. 2021,",
    ],
    raceInfo: [
      {
        gene: "CYP2B6",
        rsId: "rs7260329",
        number: "1,518명",
        race: "핀란드인",
      },
      {
        gene: "CYP2A6",
        rsId: "rs56113850",
        number: "1,785명",
        race: "유럽계 미국인(EA) 및 아프리카계 미국인(AA)",
      },
    ],
  },
  NicotineDependence: {
    name: "니코틴의존성",
    define:
      "니코틴의존성이란, 니코틴 흡수에 의한 정신적 효과를 얻기 위해 또는 금연으로 인한 금단현상 등의 불편함을 피하기 위해 지속적으로 흡연을 하지 않으면 안되는 경향 또는 상태를 의미합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 니코틴의존성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "흡연 유발 요인 피하기",
        content:
          "유발 요인을 피하는 것은 니코틴 의존도를 줄이는 데 중요한 부분이며 새로운 습관을 개발하려면 시간과 노력이 필요합니다. 담배를 피우고 싶은 욕구를 유발하는 상황, 감정 및 사람을 기억하고 피하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "니코틴 대체요법 수행하기",
        content:
          "니코틴 대체요법은 니코틴 의존 자체를 치료하기보다는 니코틴을 흡연 이외의 방법으로 신체에 공급해 니코틴 금단증상을 감소시키고 갈망을 줄이는 방법입니다. 니코틴 패치, 니코틴 껌, 니코틴 사탕, 니코틴 비강분무제, 흡입기 등으로 이용할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "스트레스 줄이기",
        content:
          "스트레스는 흡연의 일반적인 원인이며 금연을 더 어렵게 만들 수 있습니다. 심호흡, 마음 챙김 또는 운동과 같은 스트레스 관리 기술을 연습하면 스트레스와 갈망을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CHRNA4 유전자는 뇌에서 니코틴이 표적으로 삼는 주요 수용체인 니코틴성 아세틸콜린 수용체의 서브유닛을 암호화합니다. 이 유전자의 변이는 니코틴의존성 및 다양한 흡연 관련 행동과 관련이 있습니다.",
      "CHRNA3 유전자는 뇌에서 니코틴이 표적으로 삼는 주요 수용체인 니코틴성 아세틸콜린 수용체의 서브유닛을 암호화합니다. 이 유전자의 변이는 니코틴의존성 및 다양한 흡연 관련 행동과 관련이 있습니다.",
      "CHRNA5 유전자는 뇌에서 니코틴이 표적으로 삼는 주요 수용체인 니코틴성 아세틸콜린 수용체의 서브유닛을 암호화합니다. 이 유전자의 변이는 니코틴의존성 및 다양한 흡연 관련 행동과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Hancock, D B et al. Translational psychiatry vol. 5,10 e651. 6 Oct. 2015,",
      "Munafò, Marcus R et al. Nicotine & tobacco research : official journal of the Society for Research on Nicotine and Tobacco vol. 13,10 (2011): 982-8.",
      "Thorgeirsson, Thorgeir E et al. Nature vol. 452,7187 (2008): 638-642.",
      "Quach, Bryan C et al. Nature communications vol. 11,1 5562. 3 Nov. 2020,",
    ],
    raceInfo: [
      {
        gene: "CHRNA4",
        rsId: "rs2273500",
        number: "58,000명",
        race: "유럽계 혹은 아프리카계 흡연자",
      },
      {
        gene: "CHRNA3",
        rsId: "rs1051730",
        number: "10,995명",
        race: "유럽인",
      },
      {
        gene: "CHRNA5",
        rsId: "rs16969968",
        number: "58,000명",
        race: "유럽계 혹은 아프리카계 흡연자",
      },
    ],
  },
  CaffeineMetabolism: {
    name: "카페인대사",
    define:
      "커피 등의 주성분으로 알려진 카페인은 체내에서 중추신경계를 자극하여 뇌의 각성시킴으로써 집중력을 높이는 효과가 있는 물질로 알려져 있으며, 이렇게 섭취되어 체내에 흡수된 카페인이 제거되어 체외로 배출되는 일련의 과정을 말합니다. 카페인대사는 관련 효소 활성도 등에 영향을 받는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 카페인대사가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "카페인 섭취 줄이기",
        content:
          "내 몸이 카페인에 어떻게 반응하는지 주의를 기울이고 그에 따라 섭취량을 조절하세요. 이를 통해 개인의 내성을 확인하고 낮은 카페인대사와 관련된 증상을 최소화할 수 있습니다. 부정적인 영향을 경험하면 카페인 섭취를 줄이거나 끊는 것을 고려하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "비타민 C 섭취하기 ",
        content:
          "비타민 C는 카페인을 대사하는 신체의 능력을 지원하는 데 도움이 되는 것으로 나타났습니다. 이것은 유전적으로 카페인대사가 낮은 사람들에게 특히 유익할 수 있습니다. 오렌지, 레몬, 피망, 고추 등을 식단에 포함 시켜보세요.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "물 자주 마시기",
        content:
          "유전적으로 카페인대사가 낮은 경우 체내에서 카페인을 제거하는 데 도움이 되도록 물 섭취를 늘리는 것이 좋습니다. 정기적으로 물을 마시면 두통, 피로 및 인지 기능 저하와 같은 부작용을 유발할 수 있는 탈수를 예방하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "CYP1A2 유전자는 카페인을 포함한 많은 화합물의 대사에 관여하는 효소를 암호화합니다. CYP1A2 유전자의 다른 변이체는 카페인이 신체에서 얼마나 빨리 또는 느리게 대사되는지에 영향을 미쳐 개인에 대한 카페인의 영향에 차이가 있습니다.",
    ],
    referenceInfo: [
      "Cornelis, Marilyn C et al. Human molecular genetics vol. 25,24 (2016): 5472-5482.",
      "Amin, N et al. Molecular psychiatry vol. 17,11 (2012): 1116-29.",
    ],
    raceInfo: [
      {
        gene: "CYP1A2",
        rsId: "rs2472297",
        number: "9,876명",
        race: "유럽인",
      },
      {
        gene: "CYP1A2",
        rsId: "rs2470893",
        number: "26,105명",
        race: "백인",
      },
    ],
  },
  CaffeineDependence: {
    name: "카페인 의존성",
    define:
      "카페인의존성이란, 카페인 섭취를 통한 정신적 효과를 얻기 위해 또는 섭취하지 않았을 때 느껴지는 불편함을 피하기 위해 지속적으로 섭취를 하지 않으면 안되는 경향 또는 상태를 의미합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 카페인의존성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "카페인 섭취 줄이기",
        content:
          "내 몸이 카페인에 어떻게 반응하는지 주의를 기울이고 그에 따라 섭취량을 조절하세요. 이를 통해 개인의 내성을 확인하고 높은 카페인의존성과 관련된 증상을 최소화할 수 있습니다. 부정적인 영향을 경험하면 카페인 섭취를 줄이거나 끊는 것을 고려하세요.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 자주 마시기",
        content:
          "유전적으로 카페인의존성이 높은 경우 체내에서 카페인을 제거하는 데 도움이 되도록 물 섭취를 늘리는 것이 좋습니다. 정기적으로 물을 마시면 두통, 피로 및 인지 기능 저하와 같은 부작용을 유발할 수 있는 탈수를 예방하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 운동은 카페인의존성에 대한 유전적 소인이 높은 사람들이 카페인의존성을 줄이는 효과적인 방법이 될 수 있습니다. 운동은 스트레스를 줄이고 에너지 수준을 높이며 더 나은 수면을 촉진하여 카페인의 필요성을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "AHR 유전자는 체내에서 카페인을 포함한 독성 물질의 대사 및 제거 조절에 관여합니다. AHR 유전자의 변이는 카페인이 대사되고 제거되는 속도에 영향을 미쳐 잠재적으로 카페인 민감성과 의존성의 차이를 초래할 수 있습니다.",
    ],
    referenceInfo: [
      "Coffee and Caffeine Genetics Consortium et al. Molecular psychiatry vol. 20,5 (2015): 647-656.",
      "Cornelis, Marilyn C et al. PLoS genetics vol. 7,4 (2011): e1002033.",
    ],
    raceInfo: [
      {
        gene: "AHR",
        rsId: "rs4410790",
        number: "47,341명",
        race: "유럽계 미국인",
      },
    ],
  },
  Insomnia: {
    name: "불면증",
    define:
      "불면증은 적절한 시간과 기회가 주어지는데도 불구하고 충분한 수면의 시작과 지속 등에 문제가 생겨 수면의 양과 질에 문제가 생기는 상태를 의미합니다.",
    reference: "4",
    heredity: 6,
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 불면증 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "일정한 수면 습관 만들기",
        content:
          "불면증을 극복하기 위해선 일관된 수면 일정을 유지하는 것이 중요합니다. 주말 또는 평일에 상관없이 매일 같은 시간에 잠자리에 들고 일어나는 것을 의미합니다. 이것은 신체의 내부 시계를 조절하고 수면의 질을 향상시키는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "잠들기 전 딴짓하지 않기",
        content:
          "잠자리에 들기 전에 전자기기 사용은 피하는 것이 좋습니다. 전자기기에서 방출되는 청색광은 신체의 자연스러운 수면-각성 주기를 방해할 수 있기 때문입니다. 또한, 운동과 같은 자극적인 활동도 잠들기 어렵게 만들 수 있으므로 피하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "술, 담배, 커피 피하기",
        content:
          "잠자리에 들기 전에 카페인, 알코올 및 니코틴을 피하는 것도 수면의 질을 개선하는 데 도움이 될 수 있습니다. 이러한 물질은 신체의 자연스러운 수면 패턴을 방해할 수 있기 때문입니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "MEIS1 유전자는 신체 내부의 24시간 시계인 일주기 리듬을 조절하는 데 관여하는 유전자입니다. 이 유전자의 변이는 신체의 수면-각성 주기에 영향을 미쳐 잠들거나 계속 잠드는 데 어려움을 겪게 하여 불면증을 유발할 수 있습니다.",
      "NEGR1 유전자는 일주기 리듬과 수면-각성 주기를 조절하는 역할을 합니다. NEGR1 유전자의 변이는 불면증을 포함한 수면 장애와 관련이 있습니다. 특정 NEGR1 변이를 가진 사람들은 잠들고 잠드는 데 어려움을 겪어 불면증 증상을 유발할 수 있습니다.",
    ],
    referenceInfo: [
      "Lane, Jacqueline M et al. Nature genetics vol. 49,2 (2017): 274-281.",
      "Hammerschlag, Anke R et al. Nature genetics vol. 49,11 (2017): 1584-1592.",
      "Liu, Xinhui et al. Journal of the American Heart Association vol. 10,15 (2021): e020187.",
      "Watanabe, Kyoko et al. Nature genetics vol. 54,8 (2022): 1125-1132.",
    ],
    raceInfo: [
      {
        gene: "MEIS1",
        rsId: "rs113851554",
        number: "112,586명",
        race: "유럽인",
      },
      {
        gene: "NEGR1",
        rsId: "rs2815757",
        number: "2백만명 이상",
        race: "다인종",
      },
    ],
  },
  MorningPerson: {
    name: "아침형, 저녁형 인간",
    define:
      "원인은 정확하지 않지만 우리 몸은 특정한 시간에 대하여 수면, 식욕, 운동, 선호도 등 영향을 받는 성향을 가질 수 있습니다. 개인에게 맞는 수면 시간과 각성 시간에 의한 생체 리듬에 따라 각성 시간이 아침에 있는 아침형과 저녁에 있는 저녁형으로 구분할 수 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 아침형 인간 성향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "일관된 수면 시간 정하기",
        content:
          "아침형인 경향이 있는 사람들은 매일 같은 시간에 일어나고 늦잠을 피하는 것을 목표로 해야 합니다. 이는 일주기 리듬을 방해할 수 있기 때문입니다. 반대로 저녁형에 유전적으로 소인이 있는 사람들은 선호하는 수면 패턴에 맞는 일관된 수면 일정을 수립하는 것을 목표로 해야 합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적절한 빛 노출하기",
        content:
          "빛 노출은 일주기 리듬을 조절하는 데 필수적인 요소입니다. 아침형은 아침에 자연광을, 저녁형은 늦은 오후와 저녁에 밝은 빛에 더 많이 노출되는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "생체 시계에 맞게 라이프스타일 조정하기",
        content:
          "아침형은 가장 정신이 맑을 때 아침에 가장 힘든 일을 계획하는 것이 유익할 수 있는 반면, 저녁형은 저녁에 더 생산적일 수 있습니다. 또한 개인은 운동 루틴, 식습관 및 사회 활동을 자신의 생체 시계에 맞게 조정하면 전반적인 웰빙을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FBXL3 유전자는 생체 시계의 중요한 구성 요소인 PER 단백질의 분해에 관여하는 단백질을 암호화합니다. FBXL3의 변이는 일주기 리듬 및 생체 시계의 차이와 관련이 있습니다.",
      "RASD1 유전자는 일주기 시계를 포함하여 세포 활동의 조절에 관여하는 작은 G 단백질을 암호화합니다. RASD1의 변이는 수면 시간과 아침형 또는 저녁형의 차이와 관련이 있습니다.",
      "RNASEL 유전자는 세포 성장 및 염증 조절에 관여하는 효소를 암호화합니다. 일부 연구에서는 RNASEL의 변이를 수면 시간 및 생체 시계의 차이와 관련성을 발견했니다.",
    ],
    referenceInfo: [
      "Hu, Youna et al. Nature communications vol. 7 10448. 2 Feb. 2016,",
    ],
    raceInfo: [
      {
        gene: "FBXL3",
        rsId: "rs9565309",
        number: "89,283명",
        race: "영국인",
      },
      {
        gene: "RASD1",
        rsId: "rs11545787",
        number: "89,283명",
        race: "영국인",
      },
    ],
  },
  PainSensitivity: {
    name: "통증",
    define: "개인이 통증에 대해 느끼는 감각적인 반응의 정도나 강도를 말합니다",
    reference: "2",
    heredity: 10,
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 혈중 비타민 C 농도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "스트레스 관리 기술 연습하기",
        content:
          "식욕은 식욕촉진호르몬 그렐린과 포만감 호르몬 렙틴의 균형이 깨져 비만으로 이어질 수 있습니다. 수면시간이 충분하지 않을 경우, 렙틴 분비가 억제되고 식욕이 증가할 수 있습니다. 따라서 하루 7시간이상 충분한 수면을 취하는 것 이 중요합니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "생활 습관 바꾸기",
        content:
          "규칙적인 운동은 근육을 강화하고 통증을 줄이는 데 도움이 되며, 건강한 식단을 유지하면 염증을 줄이고 치유를 촉진하는 데 도움이 될 수 있습니다. 또한 좋은 수면 위생을 실천하면 통증 증상을 줄이고 전반적인 건강을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "SCN10A 유전자는 통증 신호 전달에 관여하는 단백질을 암호화합니다. SCN10A 유전자의 변이는 이 단백질의 기능과 통증 신호가 전달되는 방식에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [""],
    raceInfo: [
      {
        gene: "MS4A3",
        rsId: "rs2298585",
        number: "3,495명",
        race: "중국인",
      },
      {
        gene: "FUT2",
        rsId: "rs602662",
        number: "4,419명",
        race: "인도-유럽계",
      },
      {
        gene: "FUT2",
        rsId: "rs492602",
        number: "2,717명",
        race: "미국인",
      },
    ],
  },
  Freckles: {
    name: "기미/주근깨",
    define:
      "기미와 주근깨는 정확한 원인은 밝혀져 있지 않지만 주로 햇빛의 자외선에 의해 피부에 생기는 황갈색의 색소성 반점을 의미합니다. 대개 기미는 좌우 대칭적으로 빰과 이마 눈 밑에 불규칙한 모양과 크기로 나타나며, 주근깨는 주로 빰과 팔의 윗부분, 앞가슴 등에 작은 황갈색 점으로 나타납니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 기미/주근깨 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "SPF 30 이상의 광범위 자외선 차단제를 매일 바르면 유해한 자외선으로부터 피부를 보호하고 기미와 주근깨가 생기는 것을 예방할 수 있습니다. 2시간마다 다시 발라주는 것이 좋으며, 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "항산화제가 풍부한 식품 섭취하기",
        content:
          "항산화제는 태양 노출로 인한 피부 손상을 예방할 수 있습니다. 대표적인 항산화제 식품으로는 시금치, 딸기, 토마토, 견과류가 있으며 모두 피부 건강을 유지하는 데 도움이 되는 선택입니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "전문 치료 고려하기",
        content:
          "자가 관리 조치가 효과적이지 않은 경우 화학 박피, 레이저 요법과 같은 전문적인 치료를 고려하세요. 이런 시술은 색소 피부 세포를 제거하고 피부 회춘을 촉진하는 데 도움이 될 수 있습니다. 그러나 전문적인 치료를 받기 전에 피부과 전문의와 상담하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "MC1R 유전자는 피부 세포에서 생성되는 멜라닌의 종류와 양을 결정하는 역할을 합니다. 이 유전자의 변이는 멜라닌 생성을 증가시켜 주근깨나 기미를 증가에 에 영향을 줄 수 있습니다.",
      "IRF4 유전자는 멜라닌 세포의 활성 조절에 관여하며 피부에서 멜라닌 생성을 조절하는 역할을 하는 것으로 생각됩니다. 이 유전자의 변이는 멜라닌 생성 증가와 주근깨 또는 기미에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Sulem, Patrick et al. Nature genetics vol. 39,12 (2007): 1443-52.",
      "Yamaguchi, Kyoko et al. Journal of human genetics vol. 57,11 (2012): 700-8.",
    ],
    raceInfo: [
      {
        gene: "MC1R",
        rsId: "rs1805007",
        number: "6,900명",
        race: "유럽인",
      },
      {
        gene: "MC1R",
        rsId: "rs2228479",
        number: "653명",
        race: "일본인",
      },
    ],
  },
  Pigmentation: {
    name: "색소침착",
    define:
      "색소침착은 피부 등을 둘러싸고 있는 막에 멜라닌이 증가하여 색이 검은색이나 갈색 등으로 변하는 현상을 말합니다. 유전적 요인, 약물, 외상 및 일광 노출 등 다양한 원인에 의해 발생할 수 있으나, 자외선에 지속적으로 노출되는 외적인 요인과 스트레스, 호르몬 등과 같은 내적인 요인으로 발생할 수 있다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 색소침착 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "태양에 노출되면 색소 침착이 악화될 수 있습니다. 유해한 자외선으로부터 피부를 보호하기 위해 SPF가 높은 자외선 차단제를 사용하고 2시간마다 다시 발라주는 것이 좋습니다. 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "건강한 식단 유지하기",
        content:
          "과일, 채소 및 통곡물이 풍부한 식단을 섭취하면 피부의 전반적인 건강을 개선하는 데 도움이 될 수 있습니다. 이런 식품에는 피부 손상을 방지하고 건강한 피부를 촉진하는 데 도움이 되는 항산화제가 포함되어 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "국소 치료제 사용하기",
        content:
          "색소 침착을 줄이는 데 도움이 되는 다양한 국소 치료제가 있습니다. 가장 일반적인 치료법으로는 하이드로퀴논, 레티노이드, 코직산 및 비타민 C가 있습니다. 이러한 치료법은 피부의 멜라닌 생성을 감소시키는 작용을 합니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "OCA2는 멜라닌 생성에 관여하며 생성되는 멜라닌의 양과 종류를 결정하는 역할을 합니다. OCA2 유전자의 변이는 다양한 멜라닌 생성 수준과 유형을 초래하여 색소 침착에 영향을 줄 수 있습니다.",
      "SLC45A2는 멜라닌 생성에 필요한 물질을 운반하는 단백질을 암호화합니다. SLC45A2 유전자의 변이는 또한 생성되는 멜라닌의 양과 유형에 영향을 미쳐 색소 침착에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Stokowski, Renee P et al. American journal of human genetics vol. 81,6 (2007): 1119-32.",
      "Hernandez-Pacheco, Natalia et al. Scientific reports vol. 7 44548. 16 Mar. 2017,",
    ],
    raceInfo: [
      {
        gene: "SLC45A2",
        rsId: "rs16891982",
        number: "778명",
        race: "남아시아인",
      },
    ],
  },
  Acne: {
    name: "여드름발생",
    define:
      "여드름은 모낭에 있는 피지선에서 발생하는 염증에 의한 것으로 주로 얼굴, 목, 가슴, 어깨 등에 피지선이 많이 분포하기 때문에 그 부위에서 주로 나타납니다. 보통 호르몬 변화가 많은 청소년기에 발생하지만 성인에게도 발생할 수 있으며, 세균 감염, 스트레스, 수면 부족 등의 여러 가지 원인이 작용하는 것으로 알려져 있습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 여드름발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 챙기기",
        content:
          "가공 식품, 설탕 및 유제품이 많은 식단은 여드름 증상을 악화시킬 수 있습니다. 과일과 채소, 저지방 단백질, 통곡물이 풍부한 식단은 여드름 발병의 심각성을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "스트레스 최소화하기",
        content:
          "높은 스트레스 수준은 여드름 발병을 유발할 수 있는 호르몬의 증가로 이어질 수 있습니다. 심호흡, 요가 또는 명상과 같은 스트레스 관리 기술을 연습하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "피부 청결 유지하기",
        content:
          "여드름 발생을 줄이기 위해 피부를 정기적으로 클렌징하여 먼지, 유분 및 메이크업을 효과적으로 제거하는 것이 중요합니다. 순한 클렌저를 사용하고 강한 스크럽이나 화학 물질을 피해야 추가적인 피부 자극과 건조를 예방할 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FST 유전자는 피부 세포 성장과 테스토스테론을 포함한 호르몬 생산을 촉진하는 성장 인자 생산 조절에 관여합니다. 테스토스테론 수치가 증가하면 피지 생성이 증가하고 모공이 막히며 여드름에 영향을 줄 수 있습니다.",
      "RNASEH2C 유전자는 DNA 복구에 관여하며 피부의 치유 및 손상 회복 능력을 조절하여 여드름에 영향을 미치며, 이는 여드름 흉터 형성에 영향을 줄 수 있습니다.",
      "유전자 TGFB2는 면역 체계와 세포의 성장 및 분화 조절에 관여합니다. TGFB2의 비정상적인 기능은 피부 세포의 성장과 피지 생성을 촉진하여 여드름 발병에 기여할 수 있습니다.",
    ],
    referenceInfo: [
      "Navarini, Alexander A et al. Nature communications vol. 5 4020. 13 Jun. 2014,",
      "Heng, Anna Hwee Sing et al. BMC medical genomics vol. 14,1 103. 13 Apr. 2021,",
    ],
    raceInfo: [
      {
        gene: "RNASEH2C",
        rsId: "rs478304",
        number: "1,828명",
        race: "싱가포르인",
      },
      {
        gene: "TGFB2",
        rsId: "rs1159268",
        number: "1,828명",
        race: "싱가포르인",
      },
    ],
  },
  SkinAging: {
    name: "피부노화",
    define:
      "피부노화는 나이가 들면서 점차 피부의 두께가 얇아지고 탄력이 떨어지며 피하 지방과 피부의 부착이 약해져 중력 방향으로 늘어나고 처지며 피지 분비 감소 등으로 건조해지는 종합적인 현상을 의미합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 피부노화 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "자외선 차단제를 매일 바르면 노화를 가속화할 수 있는 자외선으로부터 피부를 보호하는 데 도움이 됩니다. SPF 30 이상의 광범위한 자외선 차단제를 사용하세요. 2시간마다, 특히 수영을 하거나 땀을 흘린 후에는 다시 바르는 것이 중요합니다. 또한, 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "충분한 수분 섭취하기",
        content:
          "물을 충분히 마시면 피부에 수분을 공급하고 주름과 잔주름을 예방할 수 있습니다. 하루에 적어도 8잔의 물을 마시는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "숙면하기",
        content:
          "충분한 수면은 건강한 피부를 유지하는 데 필수적입니다. 수면 부족은 다크 서클과 주름을 형성할 수 있습니다. 매일 밤 7-9시간의 수면을 취하도록 노력하십시오.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "IRF4 유전자는 피부에 색소를 제공하고 자외선으로부터 피부를 보호하는 멜라닌 생성을 조절하여 피부 노화에 영향을 줄 수 있습니다. IRF4 유전자에 변이가 있는 경우 멜라닌의 과잉 생산으로 이어져 과색소침착 및 잠재적으로 조기 피부 노화에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Chhabra, Yash et al. Pigment cell & melanoma research vol. 31,1 (2018): 51-63.",
      "Law, Matthew H et al. The Journal of investigative dermatology vol. 137,9 (2017): 1887-1894.",
    ],
    raceInfo: [
      {
        gene: "IRF4",
        rsId: "rs12203592",
        number: "5,087명",
        race: "호주인",
      },
    ],
  },
  SkinInflammation: {
    name: "피부염증",
    define:
      "피부염증은 피부 바깥층에서 발생하는 염증에 의해 가려움증이나 발진 등이 발생하는 것으로 그 형태와 양상이 다양하며, 원인이 정확하게 밝혀져 있지는 않으나 환경적 요인과 신체적 요인, 면역학적 요인으로 인해서 발생할 수 있다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 피부염증 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "피부 위생 관리하기",
        content:
          "피부를 깨끗하게 유지하고 과도한 유분과 박테리아가 없는 상태를 유지하면 염증 발병을 예방하는 데 도움이 될 수 있습니다. 순한 클렌저로 하루에 두 번 세안하고 강한 스크럽과 알코올 기반 제품을 피하십시오.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "스트레스는 피부 염증을 유발할 수 있으므로 스트레스 수준을 관리하는 방법을 찾는 것이 중요합니다. 스트레스를 줄이는 데 도움이 되는 요가, 명상 또는 심호흡 운동을 고려해 보십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "식단에 주의하기",
        content:
          "잎이 많은 채소, 장과, 지방이 많은 생선, 견과류와 같은 항염증 식품이 풍부한 식단을 섭취하면 염증을 줄이는 데 도움이 될 수 있습니다. 가공 식품, 설탕 및 유제품을 피하는 것도 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "PBX2 유전자는 면역 및 염증 반응의 조절에 관여하는 전사 보조 인자를 암호화합니다. PBX2 유전자의 변이는 염증 자극에 반응하는 세포의 능력에 영향을 미쳐 피부 염증을 변화시킬 수 있습니다.",
      "ZNF365 유전자는 염증에 대한 반응뿐만 아니라 세포 증식 및 분화 조절에 관여합니다. ZNF365의 변이는 염증 자극에 반응하는 세포의 능력을 변경하여 피부 염증에 영향을 줄 수 있습니다.",
      "IL18R1 유전자는 염증에 관여하는 사이토카인인 인터루킨 18(IL-18)의 수용체 역할을 하는 단백질을 암호화합니다. IL18R1 유전자의 변이는 IL-18에 반응하는 세포의 능력에 영향을 미쳐 피부 염증을 변화시킬 수 있습니다.",
    ],
    referenceInfo: [
      "Hirota, Tomomitsu et al. Nature genetics vol. 44,11 (2012): 1222-6.",
      "Schaarschmidt, Heidi et al. The Journal of allergy and clinical immunology vol. 136,3 (2015): 802-6.",
    ],
    raceInfo: [
      {
        gene: "PBX2",
        rsId: "rs176095",
        number: "18,320명",
        race: "일본인",
      },
      {
        gene: "ZNF365",
        rsId: "rs10995251",
        number: "18,320명",
        race: "일본인",
      },
    ],
  },
  TanningReaction: {
    name: "태양노출후 태닝반응",
    define:
      "태양 노출로 인한 자외선은 피부 속의 멜라닌 색소를 자극하여 피부를 어두운 갈색으로 변화하게 하는데, 이를 인위적으로 하여 피부톤을 어둡게 하는 것을 태닝(Tanning)이라고 합니다. 태닝에 의한 피부 반응은 자외선 세기와 같은 환경적 요인에 영향을 받지만, 개인의 멜라닌 생성 능력이나 양 등에 따라 동일한 수준의 태양노출 후 태닝의 정도가 다를 수 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 태양노출후 태닝반응이 낮은 경향이 있음을 의미합니다. ",
    ],
    solution: [
      {
        title: "자외선 차단제 바르기",
        content:
          "자외선 차단제를 매일 바르면 노화를 가속화할 수 있는 자외선으로부터 피부를 보호하는 데 도움이 됩니다. SPF 30 이상의 광범위한 자외선 차단제를 사용하세요. 2시간마다, 특히 수영을 하거나 땀을 흘린 후에는 다시 바르는 것이 중요합니다. 또한, 태양이 가장 강한 오전 10시에서 오후 4시 사이에는 직접적인 태양 노출을 피하는 것이 좋습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "수분 섭취하기",
        content:
          "피부를 적절히 보호하려면 물을 충분히 마시고 수분을 지속적으로 유지하는 것이 필요합니다. 수분 섭취를 증가시켜 피부에 필요한 수분과 영양을 공급하면 피부 손상을 최소화하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "TYR 유전자는 피부, 모발 및 눈에 색을 부여하는 색소인 멜라닌 생성에 중요한 티로시나제 효소를 암호화합니다. TYR 유전자의 변이는 생성되는 멜라닌의 양과 유형에 영향을 미쳐 태닝 반응의 차이를 유발할 수 있습니다.",
      "PRDM15 유전자는 피부 색소 침착 조절에 관여합니다. PRDM15 유전자의 변이는 태양 노출에 대한 반응으로 멜라닌 생성을 변경하여 태닝 반응에 영향을 줄 수 있습니다.",
      "CPNE7 유전자는 멜라닌을 생성하고 저장하는 피부 세포의 구조인 멜라노솜의 형성을 제어하여 피부 색소 침착 생성을 조절하는 역할을 합니다. CPNE7 유전자의 변화는 태양 노출에 대한 반응으로 멜라닌 생성을 변경하여 태닝 반응에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Nan, Hongmei et al. The Journal of investigative dermatology vol. 129,9 (2009): 2250-7.",
      "Bonilla, Carolina et al. BMC public health vol. 14 597. 12 Jun. 2014,",
    ],
    raceInfo: [
      {
        gene: "TYR",
        rsId: "rs1393350",
        number: "9,000명",
        race: "미국인(유럽인종)",
      },
      {
        gene: "PRDM15",
        rsId: "rs7279297",
        number: "9,000명",
        race: "미국인(유럽인종)",
      },
      {
        gene: "CPNE7",
        rsId: "rs154659",
        number: "9,000명",
        race: "미국인(유럽인종)",
      },
    ],
  },
  WhiteHair: {
    name: "새치",
    define:
      "새치는 아직 흰색 머리카락이 나지 않을 나이에 본래 색의 머리카락에 섞여 흰색 머리카락이 나는 것으로 자연적 노화와 달리 드문드문 또는 불규칙적으로 나는 것을 말합니다. 원인은 불명이나, 스트레스, 비만, 영양 불균형 등 환경적 요인과 유전적, 신체적 요인 등이 다양하게 관여하는 것으로 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 새치 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "균형 잡힌 식단 섭취하기",
        content:
          "건강한 모발 성장을 촉진하는 데 필요한 모든 영양소를 섭취하는 것이 중요합니다. 여기에는 비오틴, 비타민 C, 철분과 같은 비타민과 미네랄이 포함됩니다. 과일, 채소, 저지방 단백질이 풍부한 균형 잡힌 식단을 섭취하면 머리카락을 건강하게 유지하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "두피 마사지하기",
        content:
          "두피 마사지는 흰머리가 시작되는 속도를 늦출 수도 있습니다. 두피 마사지는 모낭으로의 혈류를 증가시키고 모발 성장을 자극하며 두피와 모발의 전반적인 건강을 향상시킬 수 있다고 믿어집니다. 둥근 빗이나 손가락으로 두피를 마사지하면 모발과 두피의 전반적인 건강을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "규칙적인 운동하기",
        content:
          "운동은 건강한 모발을 유지하는 데 중요한 순환 및 산소 공급을 개선하는 데 도움이 될 수 있습니다. 규칙적인 운동은 또한 스트레스를 줄이고 전반적인 건강을 증진시켜 모발에도 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "IRF4 유전자는 머리카락, 피부 및 눈에 색을 부여하는 색소인 멜라닌의 생성을 조절하는 데 관여합니다. IRF4 유전자의 변이는 생성되는 멜라닌의 양에 영향을 줄 수 있으며, 이는 모발 색상에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Pośpiech, Ewelina et al. BMC genomics vol. 21,1 538. 5 Aug. 2020,",
      "Adhikari, Kaustubh et al. Nature communications vol. 7 10815. 1 Mar. 2016,",
    ],
    raceInfo: [
      {
        gene: "IRF4",
        rsId: "rs12203592",
        number: "6,000명",
        race: "라틴아메리카인",
      },
    ],
  },
  HairLoss: {
    name: "남성형탈모",
    define:
      "남성형탈모는 대게 앞머리와 정수리 위주로 탈모가 발생하거나 모발이 가늘어지는 특징으로 나타나며, 남성 호르몬인 안드로겐이나 유전적 원인에 의해 탈모가 일어나는 것으로 알려져 있습니다.",
    reference: "10",
    heredity: "8~42",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 4개의 관련 유전자에 대한 대립유전자형 6종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 남성형탈모 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "건강한 식단 유지하기",
        content:
          "영양소가 풍부한 식단, 특히 비타민 A, C, D, E와 같은 모발 건강에 도움이 되는 영양소와 철, 아연, 비오틴과 같은 미네랄은 건강한 모발을 유지하는 데 중요합니다. 모발 건강을 촉진하는 과일과 채소가 풍부한 균형 잡힌 식단을 유지하는 것이 남성형 탈모를 예방하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "스트레스 관리하기",
        content:
          "스트레스는 남성형 탈모의 일반적인 원인 중 하나로 알려져 있습니다. 탈모 예방을 위해 요가, 명상, 정기적인 운동과 같은 활동을 통해 스트레스를 관리하는 것이 중요합니다. 스트레스 수준을 줄이면 전반적인 건강과 웰빙을 증진시킬 수 있으며, 이는 모발 성장을 촉진하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "의학적 치료 고려하기",
        content:
          "탈모가 걱정된다면 전문의와 상담하는 것이 도움이 될 수 있습니다. 그들은 탈모의 중증도를 평가하고 모발 이식 또는 두피 미세 색소 침착과 같은 적절한 치료를 권장하는 데 도움을 줄 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "Chr20p11 유전자는 모발 성장 조절에 관여하며 유전자의 변이는 모낭 순환에 영향을 미치고 탈모에 기여할 수 있습니다. 남성형탈모의 유전적 영향 인자로 확인되었습니다.",
      "AR 유전자는 테스토스테론과 같은 안드로겐 호르몬에 결합하는 단백질을 암호화함으로써 남성형탈모에서 중요한 역할을 합니다. 유전자의 변이는 모낭이 안드로겐에 얼마나 민감한지에 영향을 미칠 수 있으며, 이는 모발 성장에 영향을 미치고 대머리에 기여할 수 있습니다.",
      "TWIST2 유전자는 모낭 줄기 세포의 발생 및 분화 조절에 관여합니다. 유전자의 변이는 이러한 줄기 세포가 탈모에 기여할 수 있는 새로운 모낭을 생성하는 능력에 영향을 미칠 수 있습니다.",
      "C1orf127 유전자는 여러 유전 연구에서 남성형탈모와 관련이 있습니다. 탈모에 대한 구체적인 역할은 아직 잘 알려져 있지 않지만 모발 성장 및 모낭 순환 조절에 관여할 수 있습니다.",
    ],
    referenceInfo: [
      "Richards, J Brent et al. Nature genetics vol. 40,11 (2008): 1282-4.",
      "Lai, Ching-Huang et al. PloS one vol. 8,12 e79789. 30 Dec. 2013,",
      "Hillmer, Axel M et al. Nature genetics vol. 40,11 (2008): 1279-81.",
      "Liang, Bo et al. PloS one vol. 8,8 e71771. 26 Aug. 2013,",
      "Li, Rui et al. PLoS genetics vol. 8,5 (2012): e1002746.",
      "Brockschmidt, F F et al. The British journal of dermatology vol. 165,6 (2011): 1293-302.",
      "Hagenaars, Saskia P et al. PLoS genetics vol. 13,2 e1006594. 14 Feb. 2017,",
      "Heilmann-Heimbach, Stefanie et al. Nature communications vol. 8 14694. 8 Mar. 2017,",
      "Marcińska, Magdalena et al. PloS one vol. 10,5 e0127852. 22 May. 2015,",
    ],
    raceInfo: [
      {
        gene: "Chr20p11",
        rsId: "rs1160312",
        number: "4,961명",
        race: "유럽인",
      },
      {
        gene: "Chr20p11",
        rsId: "rs2180439",
        number: "990명",
        race: "중국인",
      },
      {
        gene: "AR",
        rsId: "rs2497938",
        number: "12,806명",
        race: "유럽인",
      },
      {
        gene: "TWIST2",
        rsId: "rs11684254",
        number: "52,874명",
        race: "영국인",
      },
      {
        gene: "TWIST2",
        rsId: "rs9287638",
        number: "22,518명",
        race: "유럽인",
      },
      {
        gene: "C1orf127",
        rsId: "rs12565727",
        number: "12,806명",
        race: "유럽인",
      },
    ],
  },
  CircularHairLoss: {
    name: "원형탈모",
    define:
      "원형탈모는 갑자기 원형 또는 타원형으로 모발이 빠지는 특징으로 나타나며, 원인은 확실히 밝혀지지 않았으나 스트레스 등 환경적 요인, 자가면역 질환 등이 영향을 미치는 것으로 알려져 있습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 원형탈모 발생 가능성이 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "영양이 풍부한 식단 섭취하기",
        content:
          "비타민, 미네랄 및 항산화제가 풍부한 식단을 섭취하면 건강한 모발 성장과 전반적인 건강을 지원하는 데 도움이 될 수 있습니다. 과일, 채소, 기름기 없는 단백질, 통곡물이 풍부한 균형 잡힌 식단을 섭취하면 원형탈모증의 증상을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "모발 관리하기",
        content:
          "원형탈모를 방지하기 위해 머리카락을 부드럽게 다루는 것이 중요합니다. 너무 긴 시간 동안 머리를 꽉 조여놓는 헤어스타일을 피하고, 머리를 부드럽게 빗으며, 빗살이 넓은 빗을 사용하는 것이 좋습니다. 또한, 헤어 드라이어나 다른 열 도구를 사용할 때는 저온 설정을 사용하고 머리카락을 부드럽게 다루는 것이 중요합니다. 이와 같은 습관을 유지하면 모발 건강을 유지하고 모발 성장을 촉진하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "전문의와 상담하기",
        content:
          "원형탈모증에 대한 유전적 소인이 있는 개인은 탈모 징후를 발견하는 즉시 전문적인 의학적 조언을 구하는 것이 중요합니다. 조기 진단 및 치료는 질병의 진행을 늦추고 모발 성장 회복의 기회를 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "IKZF4 유전자는 면역계 기능 조절에 관여하는 유전자입니다. 일부 연구에서는 IKZF4 유전자의 변이가 원형탈모증의 면역 반응에 영향을 미치고 상태의 발달에 기여할 수 있음을 보여주었습니다.",
      "HLA-DQB1 유전자는 자기 세포와 비자기 세포를 구별하는 면역 체계의 능력에 역할을 하는 유전자입니다. HLA-DQB1 유전자의 변이는 원형탈모증과 관련이 있습니다.",
      "IL2RA 유전자는 감염과 염증에 대한 면역 체계의 반응에서 역할을 하는 단백질을 암호화하는 유전자입니다. IL2RA 유전자의 변이는 원형탈모증과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Petukhova, Lynn et al. Nature vol. 466,7302 (2010): 113-7.",
      "Jagielska, Dagny et al. The Journal of investigative dermatology vol. 132,9 (2012): 2192-7.",
      "Jabbari, Ali et al. Dermatologic clinics vol. 31,1 (2013): 109-17.",
      "Petukhova, Lynn, and Angela M Christiano. The Journal of investigative dermatology vol. 136,1 (2016): 314-317.",
    ],
    raceInfo: [
      {
        gene: "IKZF4",
        rsId: "rs1701704",
        number: "4,332명",
        race: "미국인",
      },
      {
        gene: "HLA-DQB1",
        rsId: "rs9275572",
        number: "4,332명",
        race: "미국인",
      },
      {
        gene: "IL2RA",
        rsId: "rs3118470",
        number: "10,796명",
        race: "미국인",
      },
    ],
  },
  HairThickness: {
    name: "모발굵기",
    define:
      "모발굵기는 개별 모발 가닥의 직경으로 모발을 생성하는 피부의 구조인 모낭의 크기에 의하여 결정된다고 알려져 있습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 모발굵기가 굵은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "영양 개선하기",
        content:
          "비타민과 미네랄, 특히 비오틴, 철분, 비타민 D가 풍부한 식단은 건강한 모발 성장을 촉진하고 모발 두께를 개선할 수 있습니다. 계란, 연어, 그릭 요거트와 같이 단백질이 풍부한 음식을 섭취하는 것도 모발을 강화하고 두께를 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "모발 관리하기",
        content:
          "컨디셔닝 트리트먼트, 볼류마이징 샴푸, 헤어 오일과 같은 제품을 정기적으로 사용하면 모발과 두피에 영양을 공급하고 보호하여 모발 두께를 개선하는 데 도움이 될 수 있습니다. 또한 열 스타일링 도구는 모발을 손상시켜 끊어지고 가늘게 만들 수 있습니다. 열을 사용해야 하는 경우 열 보호 스프레이를 사용하고 온도를 낮게 유지하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "EDAR 유전자는 모발의 두께와 밀도에 중요한 역할을 합니다. 특히, EDAR 유전자의 변이는 모낭의 발달에 영향을 미쳐 모발의 두께와 질감의 차이로 이어질 수 있습니다.",
    ],
    referenceInfo: [
      "Fujimoto, Akihiro et al. Human molecular genetics vol. 17,6 (2008): 835-43.",
      "Riddell, Jon et al. European journal of human genetics : EJHG vol. 28,12 (2020): 1694-1702.",
    ],
    raceInfo: [
      {
        gene: "EDAR",
        rsId: "rs3827760",
        number: "10,640명",
        race: "중국인",
      },
    ],
  },
  MuscleFitness: {
    name: "근력운동적합성",
    define:
      "근력운동적합성이란, 근력운동에 대한 목표와 예상 반응의 관계를 의미합니다. 객관적으로 표준화된 측정 또는 평가방법은 없으며 근력운동능력은 근 섬유의 수, 크기 및 활성화시키는 신경의 능력이나, 나이 및 운동습관 등 다양한 요소에 영향을 받습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 근력운동적합성이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "다른 유형의 운동 같이하기",
        content:
          "근력 운동이 유전적으로 적합하지 않은 경우 건강상의 이점을 제공할 수 있는 다른 유형의 운동이 있습니다. 요가, 필라테스, 수영 또는 자전거 타기와 같은 활동은 근육에 과도한 부담을 주지 않고 지구력, 유연성 및 심혈관 건강을 구축하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "영양에 집중하기",
        content:
          "충분한 단백질과 건강한 지방이 포함된 균형 잡힌 식단을 섭취하는 것은 근육 성장에 필수적입니다. 유전적으로 근육 발달이 좋지 않은 경우 근육이 회복되고 성장할 수 있도록 영양이 풍부한 음식을 섭취하는 데 집중해야 합니다. 크레아틴 일수화물 및 베타알라닌과 같은 보충제도 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "꾸준히 운동하기",
        content:
          "유전적으로 근육 발달이 좋지 않은 경우 처음에는 진행 상황을 확인하기 어려울 수 있습니다. 그러나 근력 운동 프로그램을 일관성 있게 유지하고 인내심을 가진다면 근육 발달은 느릴 수 있지만 시간이 지남에 따라 결과를 볼 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "MTHFR 유전자는 에너지 생산과 세포 성장에 중요한 영양소인 엽산의 대사에 관여하는 효소를 암호화합니다. MTHFR 유전자의 변이는 이 과정의 효율성에 영향을 미쳐 근력 훈련 중에 에너지 수준이 감소하고 근육 피로가 증가할 가능성이 있습니다.",
      "AGT 유전자는 혈압과 체액 균형 조절에 관여합니다. AGT 유전자의 변이는 혈압을 조절하는 호르몬의 전구체인 안지오텐시노겐 생산에 영향을 미칠 수 있습니다. 이는 신체적 스트레스에 반응하고 운동에서 회복하는 신체의 능력에 영향을 미쳐 근력 훈련을 위한 전반적인 체력에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Gomez-Gallego, Felix et al. Applied physiology, nutrition, and metabolism = Physiologie appliquee, nutrition et metabolisme vol. 34,6 (2009): 1108-11.",
      "Aleksandra, Zarębska et al..” Journal of sports science & medicine vol. 15,4 616-624. 1 Dec. 2016",
    ],
    raceInfo: [
      {
        gene: "AGT",
        rsId: "rs699",
        number: "211명",
        race: "폴란드인",
      },
    ],
  },
  ExerciseFitness: {
    name: "지구력운동적합성",
    define:
      "지구력운동적합성이란, 지구력운동에 대한 목표와 예상 반응의 관계를 의미합니다. 객관적으로 표준화된 측정 또는 평가방법은 없으며 지구력운동능력은 이용할 수 있는 산소의 최대량과 산소 운반에 도움을 주는 근 섬유발달 능력과 나이, 운동습관 등 다양한 요소에 영향을 받습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 지구력운동적합성이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "인터벌 트레이닝 수행하기",
        content:
          "인터벌 트레이닝은 고강도 운동 기간과 휴식 또는 저강도 운동 기간을 번갈아 가며 하는 운동 유형입니다. 연구에 따르면 인터벌 트레이닝은 유전적으로 지구력 운동에 적합하지 않은 사람들의 경우에도 지구력을 향상시키고 심혈관 시스템의 효율성을 높일 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "근력 운동에 집중하기",
        content:
          "지구력 운동이 당신의 강점은 아닐 수 있지만 근력 운동은 여전히 ​​많은 건강상의 이점을 제공하고 전반적인 체력 수준을 향상시킬 수 있습니다. 사실, 연구에 따르면 근력 운동은 근육의 효율성을 높여 지구력을 향상시킬 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "영양 및 회복에 집중하기",
        content:
          "지구력 운동은 특히 신체에 부담이 될 수 있으므로 적절한 영양 및 회복에 집중하는 것이 중요합니다. 운동에 연료를 공급하기에 충분한 단백질, 탄수화물 및 건강한 지방을 섭취하고 있는지 확인하고, 운동 사이에 몸이 회복되고 재건될 수 있도록 휴식과 회복을 우선시하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "HIF1A 유전자는 낮은 산소 수준에 대한 신체의 반응을 조절하는 데 도움이 되는 유전자입니다. 이 유전자는 강렬한 신체 활동 중에 낮은 산소 수준에 적응하는 신체의 능력에 영향을 미침으로써 지구력운동적합성과 관련이 있습니다.",
    ],
    referenceInfo: [
      "Döring, Frank et al. Journal of applied physiology (Bethesda, Md. : 1985) vol. 108,6 (2010): 1497-500.",
    ],
    raceInfo: [
      {
        gene: "HIF1A",
        rsId: "rs11549465",
        number: "620명",
        race: "백인",
      },
    ],
  },
  AerobicExercise: {
    name: "유산소운동적합성",
    define:
      "유산소운동적합성이란 유산소운동에 대한 목표와 예상 반응의 관계를 의미합니다. 객관적으로 표준화된 측정 또는 평가방법은 없으며 유산소운동능력은 개인의 심폐 능력이나 산소 운반 능력, 최대 산소 섭취량 등의 신체적 특징이나, 나이, 운동 습관 등 다양한 요소에 영향을 받습니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 유산소운동적합성이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "다른 지구력 운동 고려하기",
        content:
          "장거리 달리기나 자전거 타기와 같은 전통적인 지구력 운동은 어려울 수 있지만 유산소운동적합성이 낮은 개인에게 더 적합한 대체 운동이 있습니다. 예를 들어 수영은 충격이 적고 전신 운동을 제공할 수 있으므로 훌륭한 대안이 될 수 있습니다. 걷기나 하이킹도 몸에 무리한 스트레스를 주지 않고 심혈관 건강을 개선하는 데 효과적인 운동이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "근력 운동에 집중하기",
        content:
          "지구력 운동이 중요하지만 근력 운동은 유산소운동적합성이 낮은 개인에게도 도움이 될 수 있습니다. 근육을 키우면 전반적인 체력이 향상될 수 있으며, 연구에 따르면 근력 운동은 유산소 능력을 향상시킬 수 있습니다. 스쿼트, 런지, 데드리프트, 벤치 프레스와 같이 여러 근육 그룹에 작용하는 복합 운동을 통합하면 심혈관 건강을 개선하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "VEGF-A 유전자는 혈관 성장 및 혈관 신생에 중요한 성장 인자를 암호화합니다. VEGF-A의 변화는 혈류와 근육으로의 산소 전달에 영향을 미쳐 유산소 운동 성능에 잠재적으로 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Ahmetov, Ildus I et al. European journal of applied physiology vol. 107,1 (2009): 95-103.",
    ],
    raceInfo: [
      {
        gene: "VEGF-A",
        rsId: "rs1870377",
        number: "1,074명",
        race: "러시아인",
      },
    ],
  },
  ShortDistanceRunning: {
    name: "단거리질주능력",
    define:
      "단거리질주능력은 짧은 거리를 전속력으로 달리는 능력을 의미합니다. 객관적으로 표준화된 측정 또는 평가 방법은 없으나 순간적인 속도를 위해 강하고 빠른 근 수축과 기술, 폭발적인 힘을 내도록 돕는 근 섬유의 능력과 함께 순발력과 심폐지구력 등에 영향을 받습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 단거리질주능력이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "근력 운동하기",
        content:
          "근력 운동은 단거리 달리기에 필요한 근력과 폭발력을 개발하는 데 도움이 될 수 있습니다. 스쿼트, 데드리프트, 런지와 같은 운동은 하체의 근력과 힘을 향상시켜 전력 질주 능력을 향상시킬 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "기술 훈련하기",
        content:
          "단거리 질주 기술 훈련은 최적 보폭, 팔 스윙, 신체 위치 유지 등을 포함합니다. 적절한 보폭은 빠른 발걸음과 가속을 가능하게 하며, 팔 스윙은 균형과 안정성을 제공합니다. 몸의 기울임과 무게 분배를 조절하여 신체 위치를 최적화하여 안정성을 유지해야 합니다. 이러한 기술 훈련은 유전적 소인과 상관없이 단거리질주능력을 향상 시키는데 도움이 됩니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "SOD2 유전자는 산화 스트레스로부터 세포를 보호하는 데 도움이 되는 항산화 효소를 암호화합니다. 이 유전자의 변이는 전력 질주 능력에 영향을 미칠 수 있는 근지구력 및 출력의 차이와 관련이 있습니다.",
      "ACTN3 유전자는 백색근 섬유에서 발견되는 단백질을 암호화합니다. 이 섬유는 전력 질주와 같은 폭발적인 고강도 운동에 중요합니다. 이 유전자의 특정 변이를 가진 개인은 해당 단백질이 감소할 수 있으며 전력 질주에 필요한 힘을 생성할 수 없을 수 있습니다.",
      "AMPD1 유전자는 에너지 대사에서 역할을 하는 효소를 암호화합니다. 이 유전자의 변이는 전력질주 능력에 영향을 미칠 수 있는 근육 대사 및 지구력의 차이와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Ahmetov, I I et al. Free radical research vol. 48,8 (2014): 948-55.",
      "Weyerstraß, Jan et al. Journal of science and medicine in sport vol. 21,2 (2018): 213-220.",
    ],
    raceInfo: [
      {
        gene: "SOD2",
        rsId: "rs4880",
        number: "3,581명",
        race: "백인",
      },
    ],
  },
  RecoverAfterExercising: {
    name: "운동후회복능력",
    define:
      "운동후회복능력은 운동으로 인해 야기된 신체 내 심박수 증가, 심폐 기능, 근육 조직 등의 피로 등의 상태를 운동 전의 상태로 회복하는 능력을 의미합니다. 객관적으로 표준화된 측정 또는 평가 방법은 없으나 개인의 건강 상태 및 운동 습관 등에 종합적으로 영향을 받습니다.",
    reference: "3",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 3개의 관련 유전자에 대한 대립유전자형 3종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 운동후회복능력이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "운동 강도 및 빈도 조정하기",
        content:
          "회복 능력이 낮은 경우은 적절한 회복 시간을 허용하기 위해 운동 강도 및 빈도를 조정해야 할 수 있습니다. 여기에는 고강도 운동의 빈도 감소, 더 많은 저강도 운동 통합 또는 운동 사이에 휴식을 취하는 것이 포함될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "폼 롤링 및 스트레칭 하기",
        content:
          "폼 롤링 및 스트레칭은 근육으로의 혈류를 증가시키고 회복을 촉진하는 효과적인 기술입니다. 이러한 기술을 운동 루틴에 포함하므로써 근육통을 줄이고 전반적인 유연성과 운동 범위를 개선하는 데 도움을 줄 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "보충제 고려하기",
        content:
          "오메가-3 지방산, 크레아틴 및 BCAA(분지쇄 아미노산)와 같은 특정 보충제는 근육 회복 및 복구에 도움이 되는 것으로 나타났습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "RBPMS 유전자는 근육 성장 및 복구와 관련된 다른 유전자의 발현 조절에 관여합니다. 이 유전자의 변이는 운동 후 근육 섬유가 복구되고 성장하는 속도에 영향을 줄 수 있습니다.",
      "YWHAQ 유전자는 단백질 활동 및 수송 조절을 포함하여 다양한 세포 과정에 관여합니다. 일부 연구에서는 이 유전자의 변이가 운동 후 근육 손상 및 염증과 관련이 있을 수 있다고 제안합니다.",
      "CCDC141 유전자는 운동 후 근육 회복에 필요한 새로운 혈관 형성에 관여합니다. 이 유전자의 변이는 새로운 혈관이 형성되고 근육 섬유가 복구되는 속도에 영향을 줄 수 있습니다.",
      "BCAT1 유전자는 운동 후 근육 회복에 중요한 분지 사슬 아미노산의 대사에 관여합니다. 이 유전자의 변이는 근육 회복을 위한 이러한 아미노산의 가용성에 영향을 줄 수 있습니다.",
      "GSTP1 유전자는 체내 유해 물질의 해독에 관여합니다. 이 유전자의 변이는 운동 중에 생성된 유해 물질을 해독하는 신체의 능력에 영향을 미쳐 근육 회복에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Verweij, Niek et al. Nature communications vol. 9,1 898. 1 Mar. 2018,",
      "Zarebska, Aleksandra et al. Physiological genomics vol. 49,3 (2017): 127-131.",
    ],
    raceInfo: [
      {
        gene: "CCDC141",
        rsId: "rs17362588",
        number: "58,818명",
        race: "영국인",
      },
      {
        gene: "BCAT1",
        rsId: "rs4963772",
        number: "58,818명",
        race: "영국인",
      },
      {
        gene: "GSTP1",
        rsId: "rs1695",
        number: "2,263명",
        race: "러시아인, 폴란드인",
      },
    ],
  },
  MuscleDevelopment: {
    name: "근육발달능력",
    define:
      "근육발달능력은 근섬유의 굵기 증가와 근력 및 활동량 증가 등으로 발휘되는 근력의 발달하는 능력을 의미합니다. 객관적으로 표준화된 측정 또는 평가 방법은 없으나 단백질 합성 능력이나, 운동량, 생활습관 및 환경적 요인 등에 영향을 받습니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 2종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 근육발달능력이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "복합 운동에 집중하기",
        content:
          "스쿼트, 데드리프트, 벤치 프레스와 같은 복합 운동은 한 번에 여러 근육 그룹을 대상으로 하며 근육 성장을 최대화하는 데 도움이 될 수 있습니다. 이러한 운동을 균형 잡힌 운동 루틴에 포함하면 전체 근육량을 늘리는 효과적인 방법이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "적절한 영양 섭취하기",
        content:
          "적절한 영양은 근육 성장에 매우 중요합니다. 충분한 단백질, 탄수화물 및 건강한 지방을 섭취하면 근육 회복 및 성장을 지원할 수 있습니다. 또한 수분을 유지하고 영양이 풍부한 식품으로 몸에 연료를 공급하면 운동 중 에너지 수준과 성능을 최적화하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "꾸준히 운동하기",
        content:
          "일관성은 근육을 발달시킬 때 핵심입니다. 적절한 양(즉, 수행되는 세트 및 반복 횟수)으로 정기적으로 저항 훈련 운동을 수행하면 시간이 지남에 따라 근육이 성장할 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "AGT 유전자는 혈압과 체액 균형 조절에 관여하는 안지오텐시노겐이라는 단백질을 암호화합니다. AGT 유전자의 변이는 근육량과 근력의 차이와 관련이 있습니다.",
      "VCAN 유전자는 연골, 피부 및 혈관과 같은 조직의 형성 및 유지에 관여하는 베르시칸이라는 단백질을 암호화합니다. VCAN 유전자의 변이는 근육량 및 근력의 차이와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Gomez-Gallego, Felix et al. Applied physiology, nutrition, and metabolism = Physiologie appliquee, nutrition et metabolisme vol. 34,6 (2009): 1108-11.",
      "Weyerstraß, Jan et al. Journal of science and medicine in sport vol. 21,2 (2018): 213-220.",
      "Karasik, David et al. The American journal of clinical nutrition vol. 109,2 (2019): 276-287.",
      "Zillikens, M Carola et al. Nature communications vol. 8,1 80. 19 Jul. 2017,",
    ],
    raceInfo: [
      {
        gene: "AGT",
        rsId: "rs699",
        number: "19,852명",
        race: "다인종",
      },
      {
        gene: "VCAN",
        rsId: "rs2287926",
        number: "38,292명",
        race: "유럽인",
      },
    ],
  },
  AnkleInjury: {
    name: "발목부상위험도",
    define:
      "발목부상위험도는 신체 지탱에서 중요한 역할을 하는 발목이 동일한 부상의 원인으로 인하여 실제 부상으로 이어지는 위험 정도를 의미합니다.객관적으로 표준화된 측정 또는 평가방법은 없습니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 발목부상위험도가 낮은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "발목 근육 강화하기",
        content:
          "발목 부상을 예방하는 가장 좋은 방법 중 하나는 발목 관절 주변의 근육을 강화하는 것입니다. 강화 운동은 균형과 안정성을 향상시켜 부상 위험을 줄이는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "올바른 신발 착용하기",
        content:
          "올바른 신발을 신는 것도 발목 부상을 예방하는 데 도움이 됩니다. 발목 지지력과 쿠션이 좋은 신발은 충격을 흡수하고 발이 구르거나 비틀리는 것을 방지하는 데 도움이 될 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "저강도 운동 찾기",
        content:
          "수영이나 자전거 타기와 같은 관절에 부담이 적은 운동은 발목 부상 위험을 줄이는 데 매우 효과적입니다. 이러한 운동은 발목에 과도한 스트레스를 줄이면서도 전신 근력과 유연성을 향상시키고, 심혈관 건강을 증진시키는 데도 도움이 됩니다. 특히, 물에서의 운동은 관절에 부담을 덜 주면서도 근력을 강화하고 유지할 수 있는 장점이 있습니다. ",
        icon: "/assets/images/page8/icon02.png",
      },
    ],
    description: [
      "ACTN3 유전자는 근육 섬유에서 발견되는 단백질을 암호화하는 유전자입니다. 연구에 따르면 해당 단백질의 발현이 낮으면 발목 부상 위험이 더 높은 것으로 나타났습니다. 이는 근육 섬유의 구조적 안정성에 역할을 하고 부상으로부터 보호하는 데 도움이 되기 때문입니다.",
    ],
    referenceInfo: [
      "Shang, Xuya et al. Journal of sports sciences vol. 33,17 (2015): 1775-9.",
      "Kim, Jun Ho et al. Journal of exercise nutrition & biochemistry vol. 18,2 (2014): 205-14.",
    ],
    raceInfo: [
      {
        gene: "ACTN3",
        rsId: "rs1815739",
        number: "300명",
        race: "한국인",
      },
    ],
  },
  SensitivitySweet: {
    name: "단맛민감도",
    define:
      "단맛민감도란 단맛을 인지하는 정도를 나타낸다. 낮은 역치는 높은 민감도를 의미합니다.",
    reference: "1",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 단맛민감도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "설탕 섭취량 줄이기",
        content:
          "일일 설탕 섭취량을 제한하면 단맛에 대한을 욕망을 줄이고 단맛에 대한 민감성을 개선하는 데 도움이 될 수 있습니다. 식단에서 설탕의 양을 점차적으로 줄임으로써 미각은 과일 및 기타 건강한 소스에서 자연적인 단맛을 인식하도록 개선될 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "균형있는 식단 유지하기",
        content:
          "식단에 더 많은 섬유질과 건강한 지방을 포함하면 배고픔을 조절하고 단맛에 대한 민감도를 개선하는 데 도움이 될 수 있습니다. 균형 잡힌 식단을 섭취하면 혈당 수치를 조절하고 포만감을 촉진하여 단 음식에 대한 욕구를 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "더 많은 단백질 섭취하기",
        content:
          "더 많은 단백질을 섭취하면 배고픔을 조절하고 단맛에 대한 민감도를 높일 수 있습니다. 단백질이 풍부한 음식을 먹으면 포만감을 촉진하여 단 음식, 고칼로리 음식에 대한 욕구를 줄일 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "FGF21은 단맛 감수성과 관련된 호르몬을 암호화 합니다. FGF21 유전자의 변이는 사람이 다른 음식과 음료를 얼마나 달콤하게 인식하는지에 영향을 미칠 수 있습니다.",
    ],
    referenceInfo: [
      "Janzi, Suzanne et al. Nutrients vol. 13,11 3954. 5 Nov. 2021,",
    ],
    raceInfo: [
      {
        gene: "FGF21",
        rsId: "rs838133",
        number: "22,794명",
        race: "스웨덴인",
      },
    ],
  },
  SensitivityBitter: {
    name: "쓴맛민감도",
    define:
      "쓴맛민감도란 쓴맛을 인지하는 정도를 나타낸다. 낮은 역치는 높은 민감도를 의미합니다.",
    reference: "4",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 2개의 관련 유전자에 대한 대립유전자형 4종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 쓴맛민감도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "흡연량 줄이기",
        content:
          "담배를 피우면 쓴 맛에 대한 민감도에 영향을 미칠 수 있는 니코틴을 포함한 유해 화학 물질에 대한 노출이 증가합니다. 금연은 이러한 유해한 화학 물질에 대한 노출을 줄이고 쓴맛에 대한 민감성을 개선하는 데 도움이 됩니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "음주량 줄이기",
        content:
          "알코올 소비를 줄이거나 알코올 함량이 낮은 음료로 전환하면 쓴맛에 대한 민감도를 개선하는 데 도움이 될 수 있습니다. 알코올은 또한 쓴 맛을 느끼는 능력을 포함하여 전반적인 미각 인식에 부정적인 영향을 미칠 수 있으므로 적당히 조절하는 것이 중요합니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "카페인 줄이기",
        content:
          "유전적으로 쓴맛에 대한 민감성이 낮은 경우 커피 섭취를 줄이면 카페인의 부정적인 영향을 줄이는 데 도움이 될 수 있습니다. 커피 섭취량을 하루에 한두 잔으로 제한하고 공복에 마시는 것을 피하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TAS2R19 유전자는 음식에서 쓴맛 화합물을 감지하는 데 관여하는 쓴맛 수용체를 암호화합니다. TAS2R19 유전자의 변이는 쓴맛민감도의 차이와 관련이 있습니다.",
      "TAS2R38 유전자는 음식에서 쓴맛 화합물을 감지하는 데 관여하는 쓴맛 수용체를 암호화합니다. TAS2R19 유전자의 변이는 쓴맛민감도의 차이와 관련이 있습니다.",
    ],
    referenceInfo: [
      "Chamoun, Elie et al. Critical reviews in food science and nutrition vol. 58,2 (2018): 194-207.",
      "Graham, Catherine Anna-Marie et al. Chemical senses vol. 46 (2021): bjab029.",
      "Reed, Danielle R et al. Human molecular genetics vol. 19,21 (2010): 4278-85.",
      "Hwang, Liang-Dar et al. BMC genomics vol. 19,1 678. 17 Sep. 2018,",
    ],
    raceInfo: [
      {
        gene: "TAS2R38",
        rsId: "rs713598",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "TAS2R38",
        rsId: "rs10246939",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "TAS2R38",
        rsId: "rs1726866",
        number: "-",
        race: "리뷰논문_체계적 고찰",
      },
      {
        gene: "TAS2R19",
        rsId: "rs10772420",
        number: "1,999명",
        race: "백인",
      },
    ],
  },
  SensitivitySalty: {
    name: "짠맛민감도",
    define:
      "짠맛민감도란 짠맛을 인지하는 정도를 나타낸다. 낮은 역치는 높은 민감도를 의미합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 짠맛민감도가 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "소금 섭취 줄이기",
        content:
          "섭취하는 소금의 양을 제한하면 소금 맛에 대한 전반적인 민감도를 낮추는 데 도움이 될 수 있습니다. 염분이 많은 가공 식품을 피하고 대신 신선한 재료를 선택하십시오.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "물 많이 마시기",
        content:
          "물을 마시면 신체에서 과도한 염분을 제거하는 데 도움이 될 수 있으며, 이는 소금 맛에 대한 민감도를 낮추는 데 도움이 될 수 있습니다. 하루에 적어도 8잔의 물을 마시는 것을 목표로 하십시오.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "균형 잡힌 식단 섭취하기",
        content:
          "신선한 과일과 채소, 저지방 단백질, 통곡물을 포함하는 균형 잡힌 식단을 일상에 통합하면 전반적인 소금 섭취를 줄이고 짠 음식의 과다 섭취를 예방할 수 있습니다.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "TRPV1은 일시적 수용체 전위 바닐로이드 1이라는 단백질을 코딩하는 유전자입니다. 이 단백질은 다른 맛과 온도뿐만 아니라 염분에 대한 민감성을 조절하는 역할을 합니다. TRPV1 유전자의 변이는 단백질 발현에 영향을 미쳐 짠맛민감도의 차이를 유발할 수 있습니다. ",
    ],
    referenceInfo: [
      "Dias, Andre G et al. Chemical senses vol. 38,2 (2013): 137-45.",
      "Chamoun, Elie et al. Critical reviews in food science and nutrition vol. 58,2 (2018): 194-207.",
    ],
    raceInfo: [
      {
        gene: "TRPV1",
        rsId: "rs8065080",
        number: "95명 / 95명",
        race: "캐나다인 / 영국인",
      },
    ],
  },
  FeelingFull: {
    name: "포만감",
    define:
      "포만감은 음식 섭취 후 느끼는 배가 부른 느낌으로 충분히 차서 만족스러운 느낌을 말합니다.",
    reference: "2",
    heredity: "0",
    info: [
      "• 본 검사 결과는 질병의 진단 및 치료의 목적으로 사용될 수 없습니다. 의학적인 소견이 필요한 경우 의사와 상담하시기 바랍니다.",
      "• 본 검사 함목은 1개의 관련 유전자에 대한 대립유전자형 1종 기준으로 계산되었습니다.",
      "• 점수가 100점에 가까울수록 포만감이 높은 경향이 있음을 의미합니다.",
    ],
    solution: [
      {
        title: "규칙적인 운동하기",
        content:
          "규칙적인 운동은 배고픔과 포만감을 조절하는 뇌의 뉴런 감도를 향상시키는 것으로 나타났습니다. 이것은 포만감이 낮은 개인이 식사 후 더 만족감을 느끼고 과식을 줄이는 데 도움이 될 수 있습니다. 또한 운동과 균형 잡힌 식단을 결합하면 보다 지속 가능하고 효과적인 체중 감량으로 이어질 수 있습니다.",
        icon: "/assets/images/page8/icon01.png",
      },
      {
        title: "규칙적인 식사하기",
        content:
          "평소 식사를 자주 건너뛰거나 굶는 다이어트를 지속할 경우 뇌신경을 더 강하게 자극하여 다량의 그렐린이 분비되게 됩니다. 그렇게 되면 식사량을 조절하기 힘들게 되어 과식이나 폭식을 유발하는 요인이 됩니다. 식사를 할 경우, 되도록 천천히 먹는 것이 좋습니다.",
        icon: "/assets/images/page8/icon02.png",
      },
      {
        title: "충분한 수면 취하기",
        content:
          "수면 부족은 렙틴 수치를 방해하여 과식과 체중 증가로 이어질 수 있습니다. 적절한 호르몬 균형을 유지하기 위해 매일 밤 최소 7시간의 수면을 목표로 하세요.",
        icon: "/assets/images/page8/icon03.png",
      },
    ],
    description: [
      "LEPR 유전자는 음식 섭취를 줄이고 에너지 소비를 늘리도록 뇌에 신호를 보내 에너지 균형을 조절하는 단백질인 렙틴 수용체를 암호화합니다. 이 유전자의 돌연변이는 렙틴 수용체의 기능에 영향을 미쳐 렙틴 효과에 대한 저항성을 유발하고 결과적으로 식욕과 포만감에 영향을 줄 수 있습니다.",
      "LEP 유전자는 지방 세포에서 분비되고 신체의 에너지 상태에 대해 뇌에 신호를 보내는 렙틴 호르몬을 암호화합니다. 이 유전자의 돌연변이는 렙틴의 생산 또는 기능에 영향을 미쳐 비만과 포만감에 영향을 줄 수 있습니다.",
    ],
    referenceInfo: [
      "Kilpeläinen, Tuomas O et al. Nature communications vol. 7 10494. 1 Feb. 2016,",
      "Dallner, Olof S et al. Nature medicine vol. 25,3 (2019): 507-516.",
    ],
    raceInfo: [
      {
        gene: "LEP",
        rsId: "rs10487505",
        number: "52,140명",
        race: "유럽계",
      },
    ],
  },
};

const solutionReferenceInfo = {
  Nutrition: {
    name: "영양소",
    ref: [
      "Vitamin C - Fact Sheet for Health Professionals, 2021, 2-4",
      "Verywell health - Top Foods Rich in Vitamin D, 2023, 5",
      "Verywell health - How to Safely Get Vitamin D From Sunlight, 2024, 1",
      "Vitamin D - Fact Sheet for Health Professionals, 2024, 4-6 ",
      "The Nutrition Source, 2024; 3-3.",
      "Fact Sheet for Health Professionals, 2023; 4-4",
      "Korean Circulation J 2001;31(3):305-310",
      "Journal of the Korea Academia-Industrial Cooperation Society, 2017. 8; 18(8): 294-303.",
      "KOREAN J. FOOD SCI. TECHNOL., 2020 4; 52(4): 317-324.",
      "Journal of Nutrition and Health (J Nutr Health), 2017. 5; 50(5): 483-493.",
      "낭포성 섬유증 환자의 비타민E 보충제_Okebukola PO,. 2020",
      "2004년도 정기총회 및 춘계학술대회, 2004.04; (0): 87-88",
      "Analysis of Vitamin K1 in Commonly Consumed Foods in Korea, 2015, 1194-1199",
      "Am J Hematol. 2009 Sep;84(9):584-8.",
      "대한내과학회지, 2012; 83(6): ",
      "한국건강관리협회 건강소식 제32권 제7호, 2008; 32(7): 26-27.",
      "Journal of Medicine and Life Science, 2021. 4; 18(1): 1-10.",
      "한얼한얼, 2009.1; 제2권(1): 74-77",
      "Nutrition advance - 30 Foods High In Arginine (2024)",
      "European Journal of Medicinal Chemistry Reports",
      "J Chiropr Med. 2016 Sep 10;15(3):184–189.",
      "NIH - Calcium_Fact sheet for Health Professionals (2024)",
      "Nutr Res Rev. 2018 Dec;31(2):164-178. ",
      "Randomized Controlled Trial Am J Clin Nutr. 2008 Jun;87(6):1945-51.",
      "Published 29 September 2015 Cite this as: BMJ",
      "Magnesium, Nutrients 10(12), 2018:1863",
      "Nutrients. 2019 Jul 20;11(7):1663.",
      "Randomized Controlled Trial PLoS One. 2017 Apr 12;12(4):e0174817.",
      "GoodRx - Zinc Supplements: What Are They Good for and How Much Zinc Is Too Much? 2024;2",
      "Nutrients. 2023 Jun 20;15(12):2813. ",
      "Front. Nutr., 06 March 2024 Sec. Nutrition and Sustainable Diets Volume 11 - 2024",
      "J Nutr. 2016 Sep;146(9):1816S-48S. ",
      "Institute for Quality and Efficiency in Health Care (IQWiG), 2006",
      "Am J Clin Nutr. 2010 May;91(5):1461S-1467S",
      "Journal of Education and Health Promotion, 26 Nov 2022, 11:368 ",
      "Michelle P. Zeller, Hematology Am Soc Hematol Educ Program. 2019 Dec 6;2019(1):315-322. ",
      "Psychological Research (2019) 83:1097–1106 ",
      "J Psychiatry Neurosci. 2007 May;32(3):224. ",
      "B.J. Jongkees et al. / Journal of Psychiatric Research 70 (2015) 50-57",
      "Betaine in human nutrition, Stuart AS Craig (2004)",
      "한국식품영양과학회 학술대회발표집, 2017, Vol.2017 (10), p.300-301",
      "Molecules 2023, 28(12), 4824",
      "Journal of Korean Association of Cancer Prevention 2003; 8(1): 36-44 ",
      "J Korean Soc Food Sci Nutr, 2015; 44(9), 1249～1255",
      "Biol Trace Elem Res. 2019 Jan;187(1):328.",
      "J Nutr Health. 2022 Aug;55(4):430-440",
      "Molecules 2013, 18, 3292-3311",
      " J Nutr Health. 2022 Aug;55(4):430-440",
      "Nutrients. 2022 Feb 16;14(4):827",
      "Published online by Cambridge University Press:  08 September 2011",
      "Pornson Suwanklang, Molecules 2023, 28, 6025 ",
      "Effect of type of TAG fatty acids on lutein and zeaxanthin bioavailability",
      "Nutrients 2022, 14(4), 827",
    ],
  },
  HealthCare: {
    name: "건강관리",
    ref: [
      "대한비만학회 - 비만식사치료_균형식 섭취/식품구성자전거",
      "J Korean Soc Biol Ther Psychiatry 2002, vol.8, no.2, pp. 225-235",
      "대한가정의학회 (2002), 23(5), 599-612",
      "운동과학, 2015, 제24권 제2회",
      "J Nutr Health. 2023 Feb;56(1):97-111",
      "대한비만학회지:제4권 제1호 1995 ",
      "한국콘텐츠학회논문지 '20 Vol. 20 No. 8",
      "홀리스틱교육연구 제16권 제3호 pp. 85:99 (2012)",
      "한국생활환경학회지 2008, vol.15, no.4, pp. 573-578 (6 pages)",
      "Healthcare (Basel). 2018 Jun 28;6(3):73",
      "Nutr J. 2011 Jan 24:10:9",
      "Canadian Adult Obesity Clinical Practice Guidelines - Physical Activity in Obesity Management., Version 1, August 4, 2020",
      "Nutrients 2024, 16(14), 2373;",
      "생화학분자생물학회, 2007;6, KSBMB News, 27권 2호, 8:14",
      "J Clin Endocrinol Metab. 1998 Jun;83(6):1853-9",
      "Clinical Trial Lipids Health Dis. 2021 Sep 20;20(1):107",
      "Alcohol and Alcoholism, Volume 46, Issue 2, March-April 2011, Pages 113–116",
      "Int J Environ Res Public Health. 2021 Jul 5;18(13):7201",
      "DReview BMJ. 2018 Jun 13:361:k2139.",
      "ta-Analysis PLoS One. 2020 Nov 9;15(11):e0241993.",
      "Lipids Health Dis. 2017 Jul 5;16(1):132.",
      "Randomized Controlled Trial Am Heart J. 2011 Jan;161(1):145-51.",
      "Eur J Nutr. 2021 Jul 21;61(1):231–242.",
      "Arterioscler Thromb Vasc Biol. 2001 Jul;21(7):1226-32.",
      "Nutrients. 2022 Nov 11;14(22):4774",
      "Nutrients 2022, 14(8), 1549",
      "Clinical Trial Hypertension. 2000 Aug;36(2):171-6.",
      "Diabetes Metab Syndr Obes. 2013:6:113-22.",
      "PLoS One. 2024 Feb 29;19(2):e029853",
      "Diabetologia. 2024 May;67(5):798-810.",
      "Clinical Trial Am J Clin Nutr. 2016 Feb;103(2):341-7",
      "Nutrients. 2023 Jul 24;15(14):3261. doi: 10.3390/nu15143261",
      "Meta-Analysis Hypertension. 2003 Nov;42(5):878-84.",
      "J Clin Hypertens (Greenwich). 2023 Jun;25(6):509-520",
      "Hypertension. 2024 Aug;81(8):1701-1715.",
      "J Clin Hypertens (Greenwich). 2023 Jun;25(6):509-520.",
      "Endocrinol Metab (Seoul). 2018 Dec;33(4):435-444",
      "Journal of Epidemiology and Public Health (2024), 09(01): 25-36",
      "J Frailty Sarcopenia Falls. 2020 Mar 1;5(1):1–5. ",
      "Journal of Epidemiology and Public Health (2024), 09(01): 25-36 ",
      "Osteogenesis, 2012 February - Detrimental Effects of Alcohol on Bone Growth",
      "Nutrition & Dietetics. 2023; 80:21-43",
      "Osteoarthr Cartil Open. 2022 Aug 5;4(4):100298. ",
      "Osteoarthritis Cartilage. 2023 Mar;31(3):300-316. ",
      "N Engl J Med. 2004 Mar 11;350(11):1093-103.",
      "질병관리청 - 국가건강정보포털_건강정보_통풍 ",
      "PLoS One. 2020 Oct 1;15(10):e0237520.",
      "Korean Journal of Microbiology (2018) Vol. 54, No. 4, pp. 379-383",
      "Exp Brain Res. 2014 Aug;232(8):2493-510",
      "Drugs Context. 2019 Dec 13:8:2019-9-4.",
      "Clinical Trial Am J Physiol Gastrointest Liver Physiol. 2003 Mar;284(3):G481-9.",
    ],
  },
  PersonalCharacteristics: {
    name: "개인특성",
    ref: [
      "Korean Journal of Psychology, 2006; Vol14, No.2: 121-142",
      "Randomized Controlled Trial BMC Med. 2023 Oct 2;21(1):379.",
      "STRESS 2007;15(4):307-313",
      "Korean J Fam Med 2023;44:129-142",
      "Korean J Fam Med 2019;40:204-211",
      "Alcohol. 2024 Jul 26:121:9-18. doi:",
      "BMC Cancer. 2021 Mar 9;21(1):254. ",
      "Cochrane Database Syst Rev. 2018 May 31;5(5):CD000146.",
      "Front Physiol. 2023 Aug 8:14:1221898.",
      "Korean J Fam Med. 2009;30:494-502 ",
      "J Health Psychol. 2017 Dec;22(14):1841-1850",
      "국민건강보험공단(강원지사) - 건강 in '금연 성공률을 높여라'",
      "Observational Study PLoS One. 2022 May 17;17(5):e0268447.",
      "질병관리청 - 담배폐해앎, 호흡기 질환(발간등록번호: 11-1790387-000824-01)",
      "J Korean Med Assoc 2023 April; 66(4):258-266",
      "J Health Psychol. 2017 Dec;22(14):1841-1850.",
      "Journal of Nutrition and Health (J Nutr Health) 2018; 51(6): 538 ~ 555",
      "Journal of Nutrition and Health (J Nutr Health) 2019; 52(2): 227 ~ 241",
      "대한방사선종양학회지 20004;22(4): 280~287",
      "한국중독범죄학회 2002;03, 10(1) 53:67",
      "식품의약품안전처 - 식품위해안내, '[카페인] 적당한 섭취는두총 해소에 도움이 되지만…(카페인과 두통의 복잡한 관계)' (2020)",
      "Journal of life science, 2020; v.12 no.4 = no.53, pp.375 - 382  ",
      "불면증을 이기는 법_박경란 (2015)",
      "세브란스병원 - '불면증_올바른 수면 습관과 환경이 좋은 잠 만든다' (2013)",
      "Korean Journal of Health Education and Promotion, 2009; Vol.26, No.4",
      "대한수면의학회(저인과학), 수면·정신생리 2020; vol.27, no.1, pp.1:7",
      "Korean Journal of Psychology: General 2010; Vol. 29, No. 2, 355-370",
      "The science & technology, v.39 no.5 = no.444, 2006년, pp.54 - 56",
    ],
  },
  SkinHair: {
    name: "피부/모발",
    ref: [
      "식품의약품안전처 - 피부노화현상과 피부노화예방법에 대한 안내서, '나이를 잊은 젋고 건강한 피부' (2010)",
      "한국생명과학회 생명과학회지, 2022; vol.32, no.5, 통권 265호 pp. 331-339 (9 pages)",
      "Korean Society for Biotechnology and Bioengineering Journal 28(1): 36-41 (2013)",
      "한국미용학회지, 2012; 제18권 제2호, pp. 310-318",
      "Pol. J. Appl. Sci., 2023, 9, 28-34",
      "Biomed Pharmacother. 2019 Feb:110:582-593.",
      "Adv Drug Deliv Rev. 2020. 6; 1:153:65-71",
      "Nutrients 2023, 15(20), 4405",
      "Kor. J. Aesthet. Cosmetol., 2012 May; Vol. 10 No. 2, 361-369",
      "Aesth Cosmetol Med. 2023;12(6):207-212",
      "Verywellhealth -  Does Stress Cause Acne? (2023)",
      "J Invest Cosmetol 2019; Vol. 15, No. 3, pp. 315-323",
      "Medicor Journal of Health Informatics and Health Policy,  2023 October; 1(1):25-30",
      "대한화장품학회지 2017; 제43권(4): 321-327",
      "J Korean Med Assoc 2021 June; 64(6): 438-446",
      "식품의약품안전처 -  '자외선 차단제 바로 알고 올바르게 사용하세요.' (2014)",
      "Cosmetics 2023; 10(5), 142",
      "Clin Infect Dis. 1999 Nov;29(5):1287-94.",
      " Medicor : Journal of Health Informatics and Health Policy Volume. 2023 October; 1 Issue 1: Page No: 25-30",
      "Drug Targets, 2014, 13, 177-190",
      "Aesth Cosmetol Med. 2023; 12(6): 207-212.",
      "한방안이비인후피부과학회지, 2018 11; 제31권 제4호",
      "Nutrients 2023; 15, 4405",
      "식품의약품안전처 - 자외선 차단제 올바른 사용법 리플렛, '자외선 차단제 바로 알고 올바르게 사용하게요.' (2014)",
      "대한화장품협회 - 화장품성분!제대로알고있나요?_'자외선차단제: 라벨읽기, 전문가팁 등'",
      "Clin Cosmet Investig Dermatol. 2015 Aug 3; 8: 413-21",
      "Int J Trichology. 2009 Jan; 1(1): 6-14",
      "셀프 두피 마사지가 성인의 스트레스에 미치는 영향_김수현, 영산대학교 미용예술대학원, 2022. 2",
      "한국건강관리협회 - 건강운동/건강푸드_'흰머리 나는 이유 바로 '이것'. 새치 예방법 알아보고 미리 대비하세요'  (2023)",
      "Int J Trichology. 2009 Jan;1(1):2",
      "아주대학교 - 대학생활_학생지원_보건진료소|자료실, '건강한 모발을 위한 영양소'_유은영 (2023)",
      "Dermatol Ther (Heidelb). 2019 Mar;9(1):51-70.",
      "Wimpole Clinic Limited - Scalp Massage For Hair Growth: How Effective is it? (2024)",
      "분당울대학교병원_모발센터 - 모발질환 알기_원형탈모",
    ],
  },
  AthleticAbility: {
    name: "운동능력",
    ref: [
      "질병관리청 - 건강문제 및 상태 ",
      "Hoetal.BMCPublicHealth2012,12:704",
      "대한임상건강증진학회 추계학술대회, 2008; [연수강좌] ",
      "J Acad Nutr Diet. 2016 Mar;116(3):501-528.",
      "질병관리청 카드뉴스 2024년 3월 28일 '근육이 줄어들지 않게 주 3회, 1년 이상, 꾸준히 근력운동 실천해요!'",
      "Sports Med. 2016 Nov;46(11):1689-1697.",
      "코칭능력개발지 2021, 제23권, 제2호, pp238-245",
      "Int J Environ Res Public Health. 2021 Jul 5;18(13):7201",
      "하동군 보건소 - 근력과 근지구력 (2022)",
      "Journal of Applied Physiology: Respiratory, Environmental and Exercise Physiology, 1995 May; 86(5):1527-33",
      "Journal of Sports Sciences, 2011; 29(S1): S29–S38",
      "Sports Med. 2000 Jun;29(6):373-86. ",
      "Meta-Analysis Age Ageing. 2022 Jun 1;51(6):afac143",
      "저항운동프로그램이 단거리 엘리트 육상 선수들의 체력향상에 미치는 효과_한겨레, 조선대학교 교육대학원, 2015.02",
      "대한육상연맹(KFFA) - 달리기의 기초",
      "아시아 운동학 학술지 2013; 제15권 제3호",
      "신장성 운동 후 폼 롤러를 이용하 자가 근막이완 처치가 프로농구 선수들의 지연성 근육통증 및 수행력에 미치는 영향_김영오, 한국체육대학교 대학원 2020.02",
      "문화와 융합 2022 May; 제44권 5호(통권93집) ",
      "Asian Journal of Physical Education and Sport Science, 2022; Volume 10 No. 1, 83~92",
      "Nutrients 2021, 13(2), 294",
      "Med Sci Sports Exerc. 2018 Dec 14;51(1):94–103",
    ],
  },
  EatingHabits: {
    name: "식습관",
    ref: [
      "BMC Endocrine Disorders 2014; 14:67",
      "CDC - Fiber: The Carb That Helps You Manage Diabetes (2024)",
      "Br J Nutr. 2012 Aug:108 Suppl 2:S105-12.",
      "Tob Induc Dis. 2017 Feb 28:15:15",
      "Evol Med Public Health. 2021 Oct 13;9(1):431-447.",
      "Nutrients 2019, 11(1), 34 (2-13)",
      "한국생활과학회지, 2007; 제16권4호 837-848",
      "대한소아신장학회지, 2010; 제14권(2): 111-119",
      "Foods. 2024 May 25; 13(11): 1659. ",
      "CDC - Physical Activity Boosts Brain Health (2024)",
      "Front Neurosci. 2013 Jul 16:7:121.",
      "UCLA Health - The surprising connection between sleep and weight management (2024)",
    ],
  },
};

export {
  contentDAType,
  contentDBType,
  dtcDescription,
  dtcDBDescription,
  solutionReferenceInfo,
};
