import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import InputUnit from "components/InputUnit";
import SignUp from "pages/login/signUp";
import "assets/css/login.css";

const CustomerAuthPage = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [sEncData, setsEncData] = useState(false);
  const [popup, setPopup] = useState();
  const { apiUrls, axiosFetch } = useAxiosFunction();
  // const data =
  //   "AgAFQ0E1MjAlNmvMxzUMM58YQLur7ExWOWfBKi8Ln6jWBLeg2+fHz17F046PzbTWhGCCALFUTxY50QprZDVMn+1m7MSeFwgV6ErXCcJ1OzlYVXf+i+hwwfrK7Zi44N64SdneogTX/UTwwhFEaBzT20TnITUgESSNc6kzuwqkaOFdrLHpT1oyE1wn53lXF6xFL5qcIs+lVWp8Kt00VsrYI2KL77CXUmAtYexGc56kshPSUe/TDCU5RXlu5LFjc2d1HwBzeofvxbDYPgBvIOGL6ijlRQq0HPLzF5BhOqv6QwMbw2/WrAyUlkOYFqIT9gJr6811egY40WEuqbfi8AasL65y4h1xbi1gffXR6FtPtWW2W3lW5TzKBqOEIUogR52Et1npYz1e6iU/CqETfpK+X8PTFw1kZu+nCG31CRnmXTR6BIq1jeCgpqLUzEjjfHTc4KfiKGOhslUCPeUy8fE6t7SNdH6sWzMfOoiyc6hIgYdb6AFUd416lFNDqdJEyxQm4yCzikuJMcHIFVLVVraeB9KwYFgGUJXB";
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const getNiceInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getNiceInit,
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setLoading(true);
          setsEncData(res.sEncData);
        } else {
          alert(res.sRtnMSG);
        }
      })
      .catch((err) => {});
  };

  const getNiceDecodeData = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getNiceDecodeData,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setUserData(res);
        } else {
          alert(res.sRtnMSG);
        }
      })
      .catch((err) => {});
  };

  const authClick = () => {
    const popup = window.open(
      "",
      "popupChk",
      "width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no"
    );
    document.form_chk.action =
      "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    document.form_chk.target = "popupChk";
    document.form_chk.submit();

    setPopup(popup);
  };

  useEffect(() => {
    getNiceInfo();
  }, []);

  useEffect(() => {
    if (!popup) {
      return;
    }

    const timer = setInterval(() => {
      if (!popup || popup.closed) {
        clearInterval(timer); // 타이머 정리
        return;
      }

      const currentUrl = popup.location.href;
      if (!currentUrl) {
        return;
      }

      try {
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("EncodeData");
        if (code) {
          popup.close();
          getNiceDecodeData({ encode_data: code });
          clearInterval(timer); // 타이머 정리
        }
      } catch (error) {
        console.error("Error parsing URL:", error);
      }
    }, 500);
  }, [popup]);

  return (
    <>
      {!userData?.success ? (
        <>
          <div className="container">
            <div className="content-box5">
              <img src="/assets/images/common/genomecheck.png" alt="" />
              <div className="main-title">
                <span>본인인증</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-box4">
              <div className="login">
                <div className="auth">
                  <div className="sub-title">
                    <span>
                      계속하려면 본인 명의의
                      <br />
                      휴대전화 번호로 인증해야 합니다.
                    </span>
                  </div>
                  <form
                    name="form_chk"
                    method="post"
                    className="input-area"
                    onSubmit={handleSubmit(authClick)}
                  >
                    <InputUnit
                      type="hidden"
                      label="m"
                      holder="m"
                      register={register("m", {})}
                      errors={formErrors}
                      value="checkplusService"
                    />
                    <InputUnit
                      type="hidden"
                      label="EncodeData"
                      holder="EncodeData"
                      register={register("EncodeData", {})}
                      errors={formErrors}
                      value={sEncData}
                    />
                    <div className="board-btn-area">
                      <button className="btn" type="submit" disabled={!loading}>
                        다음
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SignUp userData={userData} />
      )}
    </>
  );
};

export default CustomerAuthPage;
