import React, { useState, useEffect } from "react";
import CustomerAuthPage from "pages/login/customerAuthPage";

const AgreePage = (props) => {
  const [personal, setPersonal] = useState(false);
  const [nextAuth, setNextAuth] = useState(false);

  const checkPersonalClick = () => {
    const state = !personal;
    setPersonal(state);
  };

  const nextStep = () => {
    if (personal) {
      setNextAuth(true);
    } else {
      alert("이용약관 및 이용동의에 동의하셔야 됩니다.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {nextAuth === true ? (
        <CustomerAuthPage />
      ) : (
        <div className="member-section">
          <div className="member-step step2">
            <div className="member-area">
              <div className="title-area">
                <h2>약관 동의</h2>
              </div>
              <section className="term-personal-info">
                <div className="agree-area">
                  <div className="radio-style">
                    <input
                      type="checkbox"
                      id="join_personal"
                      checked={personal}
                      onChange={checkPersonalClick}
                    />
                    <label for="join_personal">
                      동의함(필수) 개인정보수집 및 이용동의
                    </label>
                  </div>
                </div>
                <div className="terms-area">
                  '디엑스앤브이엑스(주)'는 (이하 '회사'는) 정보통신망 이용촉진
                  및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법,
                  전기통신사업법, 등 정보통신서비스 제공자가 준수하여야 할 관련
                  법령상의 개인정보보호 규정을 준수하며, 회사는
                  개인정보처리방침을 통하여 고객님께서 제공하시는 개인 정보가
                  어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해
                  어떠한 조치가 취해지고 있는지 알려드립니다. <br />
                  <br />
                  회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는
                  개별공지)을 통하여 공지할 것 입니다. <br />
                  <br />
                  1. 수집하는 개인정보 항목 첫째, 회사는 회원가입, 상담, 서비스
                  신청 등을 위해 최초 회원가입 당시 아래와 같은 최소한의
                  개인정보를 필수항목으로 수집하고 있습니다.
                  <br />
                  <br /> - 필수항목 : 이름, 생년월일, 휴대전화번호, 성별, 주소
                  <br />
                  <br />
                  둘째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은
                  정보들이 자동으로 생성되어 수집될 수 있습니다. <br />
                  <br />- IP Address, 쿠키, 방문 일시, 서비스 이용 기록 <br />
                  <br />
                  셋째, 아이디를 이용한 부가 서비스 및 이벤트 응모 과정, 주문에
                  의한 제품 배송을 위해 해당 서비스의 이용자에 한해서만 아래와
                  같은 정보들이 수집 될 수 있습니다. <br />
                  <br />- 주문자 정보(주문자 이름, 이메일, 전화번호), 배송지
                  정보(수령인, 배송지, 연락처) <br />
                  <br />
                  2. 개인정보의 수집 및 이용목적 <br />
                  <br />
                  회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. <br />
                  <br />
                  가. 서비스 제공에 관한 계약 이행 <br />
                  컨텐츠 제공, 본인인증 <br />
                  <br />
                  나. 회원관리
                  <br />
                  회원제 서비스 제공, 불만처리 등 민원처리, 고지사항 전달,
                  회원탈퇴 의사의 확인 <br />
                  <br />
                  3. 개인정보의 보유 및 이용기간 <br />
                  <br />
                  회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이
                  해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는
                  아래의 이유로 명시한 기간 동안 보존합니다. <br />
                  가. 관련법령에 의한 정보보유 사유. <br />
                  상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
                  규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한
                  일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는
                  보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
                  아래와 같습니다. <br />
                  <br />- 계약 또는 주문철회 등에 관한 기록 <br />
                  보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 5년 <br />
                  <br />- 대금결제 및 재화 등의 공급에 관한 기록 <br />
                  보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 5년 <br />
                  <br />- 전자금융 거래에 관한 기록 <br />
                  보존 이유 : 전자금융 거래법 <br />
                  보존 기간 : 5년 <br />
                  <br />- 소비자의 불만 또는 분쟁처리에 관한 기록 <br />
                  보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 3년 <br />
                  <br />- 웹사이트 방문기록 <br />
                  보존 이유 : 통신기밀보호법 <br />
                  보존 기간 : 3개월 <br />
                  <br />※ 위의 개인정보 수집∙이용에 대한 동의를 거부할 권리가
                  있습니다. 그러나 동의를 거부할 경우 서비스 이용에 대한 제한을
                  받을 수 있습니다.
                </div>
              </section>
              <div className="button-area">
                <button
                  type="button"
                  className="button-primary size-l"
                  id="btn_dialog"
                  onClick={nextStep}
                >
                  <span>다음단계</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AgreePage;
