import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { reportHistoryListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import "assets/css/board.css";
import moment from "moment";

const ReportHistoryListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportList, setReportList] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [userListPage, setUserListPage] = useRecoilState(
    reportHistoryListPageIndexState
  );

  const selectUserTypeOptions = [
    { value: "U", label: "유저" },
    { value: "A", label: "총괄책임자" },
    { value: "D", label: "결과전달담당자" },
    { value: "S", label: "검사서비스담당자" },
    { value: "I", label: "결과정보처리담당자" },
  ];

  const handleCheckboxChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const getReportHistoryList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getReportHistoryList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
      },
    })
      .then((res) => {
        if (res.success) {
          setUserResult(res.result);
          setReportList(res.reportList);
          setPageList(res.pageList);
          setUserListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getReportHistoryList : ", err);
      });
  };

  useEffect(() => {
    getReportHistoryList(userListPage);
  }, []);

  return (
    <div className="container-n-admin" style={{ margin: "0 300px" }}>
      <div className="content-area">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {userResult.totalrows}
            <span>건의 유저</span>
          </div>
          <div className="board-search-area">
            <div className="check">
              <label>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    width: "16px",
                    height: "16px",
                  }}
                  checked={isDateRangeVisible}
                  onChange={handleCheckboxChange}
                ></input>
                기간설정
              </label>
            </div>

            {isDateRangeVisible && (
              <div className="date-range">
                <div className="date-picker">
                  <label>
                    시작 날짜:
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>
                  <label>
                    종료 날짜:
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            )}
            <div className="board-search">
              <input
                type="text"
                placeholder="검색어를 입력해 주세요"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    getReportHistoryList(1);
                  }
                }}
              />
              <button
                type="button"
                onClick={(e) => {
                  getReportHistoryList(1);
                }}
              >
                검색
              </button>
            </div>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>번호</th>
                <th>ID</th>
                <th>이름</th>
                <th>키트번호</th>
                <th>확인코드</th>
                <th>유저타입</th>
                <th>리포트동작</th>
                <th>열람일시</th>
              </tr>
            </thead>
            <tbody>
              {reportList?.map((item) => (
                <tr
                  key={item.row_num}
                  style={{
                    height: "53px",
                  }}
                >
                  <td>
                    <div>{userResult.totalrows - item.row_num + 1}</div>
                  </td>
                  <td>
                    <div>{item.user_id}</div>
                  </td>
                  <td>
                    <div>{item.user_name}</div>
                  </td>
                  <td>
                    <div>{item.kit_number}</div>
                  </td>
                  <td>
                    <div>{item.confirm_code}</div>
                  </td>
                  <td>
                    <div>
                      {
                        selectUserTypeOptions.find(
                          (option) => option.value === item.user_type
                        )?.label
                      }
                    </div>
                  </td>
                  <td>
                    <div>
                      {Number(item.history_type) === 0 ? "열람" : "다운로드"}
                    </div>
                  </td>
                  <td>
                    <div>
                      {moment(item.report_time).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <ul className="paging">
            <li>
              <Link
                onClick={() =>
                  getReportHistoryList(
                    userResult.currentPage - 10 < 1
                      ? 1
                      : userResult.currentPage - 10
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardFirst.png" alt="" />
              </Link>
            </li>
            {pageList?.map((item) => (
              <li
                key={item}
                className={item === userResult.currentPage ? "on" : null}
              >
                <Link
                  onClick={() => getReportHistoryList(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </Link>
              </li>
            ))}

            <li>
              <Link
                onClick={() =>
                  getReportHistoryList(
                    pageList[pageList.length - 1] + 1 < userResult.maxPage
                      ? pageList[pageList.length - 1] + 1
                      : pageList[pageList.length - 1]
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardLast.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ReportHistoryListPage;
