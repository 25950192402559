import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import AdminUpdateItemPage from "./adminUpdateItemPage";
import "assets/css/board.css";

const AdminItemListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [itemList, setItemList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState();

  const updateItemHandler = (e, reqData) => {
    e.preventDefault();
    setIsUpdate(true);
    setUpdateItem(reqData);
  };

  const isUpdateCancelHandler = (reqData) => {
    setIsUpdate(reqData);
    getAdminItemList();
  };

  const deleteItemHandler = (e, reqData) => {
    e.preventDefault();

    if (window.confirm("해당 상품을 정말 삭제하시겠습니까?")) {
      axiosFetchA({
        method: "post",
        url: apiUrls.deleteItem,
        requestConfig: { item_id: reqData.item_id },
      })
        .then((res) => {
          if (res.success) {
            getAdminItemList();
          }
          alert(res.message);
        })
        .catch((err) => {});
    }
  };

  const getAdminItemList = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getAdminItemList,
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setItemList(res.itemList);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAdminItemList();
  }, []);

  return (
    <>
      {isUpdate === true ? (
        <AdminUpdateItemPage
          updateItem={updateItem}
          isUpdateCancelHandler={isUpdateCancelHandler}
        />
      ) : (
        <div className="board-list">
          <ul className="boardList">
            <li className="head">
              <div className="board-num">
                <strong>번호</strong>
              </div>
              <div className="thumbnail">
                <strong>이미지</strong>
              </div>
              <div className="customer-name">
                <strong>상품명</strong>
              </div>
              <div className="customer-name">
                <strong>가격</strong>
              </div>
              <div className="customer-name">
                <strong>판매상태</strong>
              </div>
              <div className="thumbnail">
                <strong>수정</strong>
              </div>
              <div className="thumbnail">
                <strong>삭제</strong>
              </div>
            </li>
            {itemList?.map((item) => (
              <>
                <li key={item.row_num} className="mobile-board-li">
                  <div className="thumbnail">
                    <img
                      src={
                        item.thumbnail?.length > 0 &&
                        item.thumbnail[0]?.pathName
                          ? item.thumbnail[0]?.pathName
                          : "../assets/images/default.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="customer-name">{item.item_name}</div>
                  <div className="customer-name">
                    {Number(item.item_price).toLocaleString()}원
                  </div>
                  <div className="customer-name">
                    {item.item_status === "0" ? "판매가능" : "판매불가"}
                  </div>
                  <div className="thumbnail">
                    <button
                      className="update-btn"
                      onClick={(e) => updateItemHandler(e, item)}
                    >
                      수정
                    </button>
                  </div>
                  <div className="thumbnail">
                    <button
                      className="update-btn"
                      onClick={(e) => deleteItemHandler(e, item)}
                    >
                      삭제
                    </button>
                  </div>
                </li>
                <li key={item.user_id} className="pc-board-li">
                  <div className="board-num">{item.item_id}</div>
                  <div className="thumbnail">
                    <img
                      src={
                        item.thumbnail?.length > 0 &&
                        item.thumbnail[0]?.pathName
                          ? `/static/thumbnail/${item.thumbnail[0]?.pathName}`
                          : "../assets/images/default.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="customer-name">{item.item_name}</div>
                  <div className="customer-name">
                    {Number(item.item_price).toLocaleString()}원
                  </div>
                  <div className="customer-name">
                    {item.item_status === "0" ? "판매가능" : "판매불가"}
                  </div>
                  <div className="thumbnail">
                    <button
                      className="update-btn"
                      onClick={(e) => updateItemHandler(e, item)}
                    >
                      수정
                    </button>
                  </div>
                  <div className="thumbnail">
                    <button
                      className="update-btn"
                      onClick={(e) => deleteItemHandler(e, item)}
                    >
                      삭제
                    </button>
                  </div>
                </li>
              </>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default AdminItemListPage;
