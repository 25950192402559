import React from "react";
import { Link } from "react-router-dom";

const goTop = (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
};

const FloatingBtn = () => {
  return (
    <>
      <div className="floating2">
        <div>
          <img src="/assets/images/common/top.png" onClick={goTop} alt="" />
        </div>
      </div>
    </>
  );
};

export default FloatingBtn;
