import React, { useEffect, useState } from "react";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const UserInfoDetailPage = (props) => {
  const userInfo = props.userInfo;
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [loginList, setLoginList] = useState();
  const [inquiryList, setInquiryList] = useState();
  const [reportRegisterList, setReportRegisterList] = useState();
  const [reportReadList, setReportReadList] = useState();
  const [openIndex, setOpenIndex] = useState(null);

  const selectUserTypeOptions = [
    { value: "U", label: "유저" },
    { value: "A", label: "총괄책임자" },
    { value: "D", label: "결과전달담당자" },
    { value: "S", label: "검사서비스담당자" },
    { value: "I", label: "결과정보처리담당자" },
  ];

  const handleItemClick = (e, index) => {
    e.preventDefault();
    setOpenIndex(index === openIndex ? null : index);
  };

  const memberInfo = (
    <div>
      <div className="admin-dialog">
        <div className="admin-dialog-box contract">
          <div className="admin-dialog-area" style={{ padding: "0" }}>
            <div className="dialog-content" style={{ margin: "0" }}>
              <div className="admin-dialog-item">
                <h3 className="adi-title">이름</h3>
                <div className="adi-content">{userInfo?.user_name}</div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">전화번호</h3>
                <div className="adi-content">{userInfo?.user_phone}</div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">주소</h3>
                <div className="adi-content">{`(${userInfo?.user_post}) ${userInfo?.user_addr1} ${userInfo?.user_addr2}`}</div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">생년월일</h3>
                <div className="adi-content">
                  {moment(userInfo?.user_birth).format("YYYY년 MM월 DD일")}
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">성별</h3>
                <div className="adi-content">
                  {Number(userInfo?.user_gender) === 1 ? "남자" : "여자"}
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">가입일</h3>
                <div className="adi-content">
                  {moment(userInfo?.signup_date).format("YYYY년 MM월 DD일")}
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">고객번호</h3>
                <div className="adi-content">{userInfo?.user_number}</div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">유저타입</h3>
                <div className="adi-content">
                  {
                    selectUserTypeOptions.find(
                      (option) => option.value === userInfo?.user_type
                    )?.label
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const loginHistoryList = (
    <div className="container-n-admin" style={{ margin: "0" }}>
      <div className="content-area">
        <div className="board-info" style={{ margin: "0" }}>
          <div className="board-total">
            <span>총 </span>
            {loginList?.length}
            <span>건의 접속이력</span>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>번호</th>
                <th>IP</th>
                <th>접속일시</th>
              </tr>
            </thead>
            <tbody>
              {loginList?.map((item) => (
                <tr
                  key={item.row_num}
                  style={{
                    height: "53px",
                  }}
                >
                  <td>
                    <div>{item.row_num}</div>
                  </td>
                  <td>
                    <div>{item.user_ip}</div>
                  </td>
                  <td>
                    <div>
                      {moment(item.login_time).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const inquiryHistory = (
    <div className="container-n-admin" style={{ margin: "0" }}>
      <div className="content-area">
        <div className="board-info" style={{ margin: "0" }}>
          <div className="board-total">
            <span>총 </span>
            {inquiryList?.length}
            <span>건의 문의</span>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>열기</th>
                <th>번호</th>
                <th>문의유형</th>
                <th>문의일시</th>
                <th>답변상태</th>
                <th>답변일시</th>
              </tr>
            </thead>
            <tbody>
              {inquiryList?.map((item, index) => (
                <>
                  <tr
                    key={item.user_id + index}
                    style={{
                      height: "53px",
                    }}
                    onClick={(e) => handleItemClick(e, index)}
                  >
                    <td>
                      <div>
                        <img
                          className={`arrow-icon ${
                            index === openIndex ? "open" : "closed"
                          }`}
                          src="assets/images/sub/right_arrow_m.png"
                          alt="Arrow"
                        />
                      </div>
                    </td>
                    <td>
                      <div>{inquiryList?.length - item.row_num + 1}</div>
                    </td>
                    <td>
                      <div>
                        {item.inquiry_type === "service" ? "서비스" : "결과"}
                      </div>
                    </td>
                    <td>
                      <div>
                        {moment(item.inquiry_date).format("YYYY/MM/DD HH:mm")}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.inquiry_state === "0" ? "미답변" : "답변완료"}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.inquiry_state === "0"
                          ? ""
                          : moment(item.answer_date).format("YYYY/MM/DD HH:mm")}
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`faq-content ${
                      index === openIndex ? "open" : ""
                    }`}
                    style={index === openIndex ? { display: "contents" } : null}
                  >
                    <td colSpan={7}>
                      <div className="inquiry">
                        <form id="contact-form">
                          <div class="form-group">
                            <label for="subject">제목</label>
                            <div className="answer">{item.subject}</div>
                          </div>
                          <div class="form-group">
                            <label for="message">내용</label>
                            <textarea
                              id="message"
                              name="message"
                              rows="10"
                              required
                              resize="none"
                              defaultValue={item.message}
                              disabled={true}
                            />
                          </div>
                          <div class="form-group">
                            <label for="answer">답변</label>
                            <textarea
                              id="answer"
                              name="answer"
                              rows="10"
                              required
                              resize="none"
                              defaultValue={item.answer}
                              disabled={true}
                            />
                          </div>
                          {/* <button
                            type="submit"
                            onClick={(e) => updateInquiry(e, item.number)}
                          >
                            답변전송
                          </button> */}
                        </form>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const reportRegisterHistory = (
    <div className="container-n-admin" style={{ margin: "0" }}>
      <div className="content-area">
        <div className="board-info" style={{ margin: "0" }}>
          <div className="board-total">
            <span>총 </span>
            {reportRegisterList?.length}
            <span>건의 등록이력</span>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>번호</th>
                <th>키트번호</th>
                <th>확인코드</th>
                <th>등록일시</th>
              </tr>
            </thead>
            <tbody>
              {reportRegisterList?.map((item) => (
                <tr
                  key={item.row_num}
                  style={{
                    height: "53px",
                  }}
                >
                  <td>
                    <div>{item.row_num}</div>
                  </td>
                  <td>
                    <div>{item.kit_number}</div>
                  </td>
                  <td>
                    <div>{item.confirm_code}</div>
                  </td>
                  <td>
                    <div>
                      {moment(item.register_date).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const reportReadHistory = (
    <div className="container-n-admin" style={{ margin: "0" }}>
      <div className="content-area">
        <div className="board-info" style={{ margin: "0" }}>
          <div className="board-total">
            <span>총 </span>
            {reportReadList?.length}
            <span>건의 열람이력</span>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>번호</th>
                <th>키트번호</th>
                <th>확인코드</th>
                <th>리포트동작</th>
                <th>열람일시</th>
              </tr>
            </thead>
            <tbody>
              {reportReadList?.map((item) => (
                <tr
                  key={item.row_num}
                  style={{
                    height: "53px",
                  }}
                >
                  <td>
                    <div>{item.row_num}</div>
                  </td>
                  <td>
                    <div>{item.kit_number}</div>
                  </td>
                  <td>
                    <div>{item.confirm_code}</div>
                  </td>
                  <td>
                    <div>
                      {Number(item.history_type) === 0 ? "열람" : "다운로드"}
                    </div>
                  </td>
                  <td>
                    <div>
                      {moment(item.report_time).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const getUserInfoDetail = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getUserInfoDetail,
      requestConfig: {
        user_id: userInfo.user_id,
      },
    })
      .then((res) => {
        if (res.success) {
          setLoginList(res.loginList);
          setInquiryList(res.inquiryList);
          setReportRegisterList(res.reportRegisterList);
          setReportReadList(res.reportReadList);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getUserList : ", err);
      });
  };

  useEffect(() => {
    getUserInfoDetail();
  }, []);

  return (
    <>
      <div>
        <h3>
          {`${userInfo.user_id}(${
            selectUserTypeOptions.find(
              (option) => option.value === userInfo.user_type
            )?.label
          }) `}
          Information
        </h3>
      </div>
      <Tabs style={{ marginTop: "10px" }}>
        <TabList>
          <Tab>회원정보</Tab>
          <Tab>접속이력</Tab>
          <Tab>문의내역</Tab>
          <Tab>리포트등록이력</Tab>
          <Tab>리포트열람이력</Tab>
        </TabList>

        <TabPanel>{memberInfo}</TabPanel>
        <TabPanel>{loginHistoryList}</TabPanel>
        <TabPanel>{inquiryHistory}</TabPanel>
        <TabPanel>{reportRegisterHistory}</TabPanel>
        <TabPanel>{reportReadHistory}</TabPanel>
      </Tabs>
    </>
  );
};

export default UserInfoDetailPage;
