import React from "react";
import moment from "moment";
import "assets/css/report.css";

const ReportDAType1Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page1">
        <h1 className="grui-cover">
          {props.reportType === "DA" ? (
            <img
              src="/assets/images/page1/cover_da.png"
              alt="지놈체크 다이어트 유전자 검사 결과안내"
            />
          ) : props.reportType === "DB" ? (
            <img
              src="/assets/images/page1/cover_db.png"
              alt="지놈체크 다이어트 유전자 검사 결과안내"
            />
          ) : null}
        </h1>
        <div className="grui-table-box">
          <ul>
            <li>
              <div className="grui-table-title">키트번호</div>
              <div className="grui-table-content">{props.kit_number}</div>
            </li>
            <li>
              <div className="grui-table-title">결과보고일</div>
              <div className="grui-table-content">
                {moment(props.reqDate).format("yyyy.MM.DD")}
              </div>
            </li>
            <li>
              <div className="grui-table-title">성명</div>
              <div className="grui-table-content">{props.name}</div>
            </li>
            <li>
              <div className="grui-table-title">성별</div>
              <div className="grui-table-content">
                {Number(props?.gender) === 1 ? "남자" : "여자"}
              </div>
            </li>
          </ul>
        </div>
        <div className="grui-logo">
          <img
            src="/assets/images/page1/logo.png"
            alt="Dx&Vx Diagnostics and Vaccine Science"
          />
        </div>
        <div className="admin-num">{props.version}</div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType1Page;
