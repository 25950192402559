import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import "assets/css/board.css";

const UpdateMyPassword = () => {
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });
  const userpwd = useRef();
  userpwd.current = watch("user_pw");

  const updatePassword = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.updatePassword,
      requestConfig: {
        user_id: userInfo.user_id,
        ori_pw: reqData.ori_pw,
        user_pw: reqData.user_pw,
      },
    })
      .then((res) => {
        alert(res.message);
        if (res.success) {
          navigate("/mypage");
        }
      })
      .catch((err) => {});
  };

  const goMyPage = () => {
    navigate("/mypage");
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="container">
        <div className="content-box5">
          <img src="/assets/images/common/genomecheck.png" alt="" />
          <div className="main-title">
            <span>비밀번호 설정이 6개월 지났습니다.</span>
            <div className="rt" />
          </div>
          <div className="main-title">
            <span>비밀번호를 변경해주세요.</span>
            <div className="rt" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content-box4">
          <div className="board-list">
            <div className="board-info">
              <div className="add-sample-user">
                <form
                  className="input-area"
                  onSubmit={handleSubmit(updatePassword)}
                >
                  <InputUnit
                    label="ori_pw"
                    type="password"
                    holder="현재 비밀번호"
                    register={register("ori_pw", {
                      required: "필수 입력 값 입니다.",
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="password"
                    type="password"
                    holder="새 비밀번호"
                    register={register("user_pw", {
                      required: "패스워드는 필수 입력 값 입니다.",
                      minLength: {
                        value: 8,
                        message: "패스워드는 최소 8글자 이상이어야 합니다.",
                      },
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="password_confirm"
                    type="password"
                    holder="새 비밀번호 확인"
                    register={register("user_pw_confirm", {
                      required: "패스워드는 필수 입력 값 입니다.",
                      validate: (value) => value === userpwd.current,
                    })}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button className="btn2" type="submit">
                      변경하기
                    </button>
                    <button
                      className="btn2"
                      type="button"
                      style={{ background: "#515151" }}
                      onClick={goMyPage}
                    >
                      다음에 변경하기
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMyPassword;
