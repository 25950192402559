import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";

const InquiryPage = () => {
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetchForm } = useAxiosFunction();
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const setInquiryClear = () => {
    setValue("inquiryType", "service");
    setValue("subject", "");
    setValue("message", "");
    setValue("email", "");
  };

  const addInquiry = (reqData) => {
    // 파일 정보를 FormData에 추가
    if (reqData.email === undefined) reqData.email = "";
    const formData = new FormData();
    formData.append("user_id", userInfo?.user_id);
    formData.append("inquiryType", reqData.inquiryType);
    formData.append("subject", reqData.subject);
    formData.append("message", reqData.message);
    formData.append("email", reqData.email);

    // 업로드된 파일 추가
    // uploadedFiles?.forEach((file) => {
    //   formData.append("files", file.file);
    // });

    axiosFetchForm({
      method: "post",
      url: apiUrls.addInquiry,
      formData: formData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          setInquiryClear();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] writeBoard: ", err);
      });
  };

  useEffect(() => {}, []);

  return (
    <div id="container" className="container">
      <div className="content-box5">
        <img src="/assets/images/common/genomecheck.png" alt="" />
        <div className="main-title">
          <span>{userInfo?.isLogin === false ? "비회원" : "회원"} 1:1문의</span>
          <div className="rt" />
        </div>
        <div className="inquiry">
          <form id="contact-form" onSubmit={handleSubmit(addInquiry)}>
            <div class="form-group">
              <label for="inquiryType">문의유형</label>
              <Controller
                name="inquiryType"
                control={control}
                defaultValue="service"
                render={({ field }) => (
                  <select id="inquiryType" name="inquiryType" {...field}>
                    <option value="service">서비스 문의</option>
                    <option value="result">결과 문의</option>
                  </select>
                )}
              />
            </div>

            <div class="form-group">
              <label for="subject">제목</label>
              <InputUnit
                type="text"
                label="subject"
                holder="제목"
                register={register("subject", {
                  required: "제목은 필수 입력 값 입니다.",
                })}
                errors={formErrors}
              />
            </div>
            {userInfo?.isLogin === false ? (
              <div class="form-group">
                <label for="email">이메일</label>
                <InputUnit
                  type="text"
                  label="email"
                  holder="email"
                  register={register("email", {
                    required: "이메일은 필수 입력 값 입니다.",
                    pattern: CustomRegExp("email"),
                  })}
                  errors={formErrors}
                />
              </div>
            ) : null}

            <div class="form-group">
              <label for="message">내용</label>
              <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <textarea
                    id="message"
                    name="message"
                    rows="10"
                    required
                    resize="none"
                    {...field}
                  />
                )}
              />
            </div>
            <button type="submit" disabled={isSubmitting}>
              전송
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InquiryPage;
