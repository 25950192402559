import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inquiryListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import "assets/css/board.css";
import moment from "moment";

const AdminInquiryPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openIndex, setOpenIndex] = useState(null);
  const [answer, setAnswer] = useState("");
  const [inquiryList, setInquiryList] = useState([]);
  const [inquiryResult, setInquiryResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [inquiryListPage, setInquiryListPage] = useRecoilState(
    inquiryListPageIndexState
  );

  const handleCheckboxChange = (e) => {
    setIsDateRangeVisible(e.target.checked);
  };

  const handleItemClick = (e, index) => {
    e.preventDefault();
    setAnswer(
      inquiryList[index].answer === null ? "" : inquiryList[index].answer
    );
    setOpenIndex(index === openIndex ? null : index);
  };

  const getInquiryList = (reqData) => {
    let endDatePlusOne = new Date(endDate);
    endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

    axiosFetchA({
      method: "post",
      url: apiUrls.getInquiryList,
      requestConfig: {
        currentNavPage: reqData,
        searchKeyword: searchKeyword,
        isDateRangeVisible: isDateRangeVisible,
        startDate: startDate,
        endDate: endDatePlusOne,
      },
    })
      .then((res) => {
        if (res.success) {
          setInquiryResult(res.result);
          setInquiryList(res.inquiryList);
          setPageList(res.pageList);
          setInquiryListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getUserList : ", err);
      });
  };

  const updateInquiry = (e, number) => {
    e.preventDefault();
    axiosFetchA({
      method: "post",
      url: apiUrls.updateInquiry,
      requestConfig: { number: number, answer: answer },
    })
      .then((res) => {
        alert(res.message);
        getInquiryList(inquiryListPage);
        setOpenIndex(null);
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getInquiryList(inquiryListPage);
  }, []);

  return (
    <div className="container-n-admin" style={{ margin: "0 300px" }}>
      <div className="content-area">
        <div className="board-info">
          <div className="board-total">
            <span>총 </span>
            {inquiryResult.totalrows}
            <span>건의 문의</span>
          </div>
          <div className="board-search-area">
            <div className="check">
              <label>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    width: "16px",
                    height: "16px",
                  }}
                  checked={isDateRangeVisible}
                  onChange={handleCheckboxChange}
                ></input>
                기간설정
              </label>
            </div>

            {isDateRangeVisible && (
              <div className="date-range">
                <div className="date-picker">
                  <label>
                    시작 날짜:
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </label>
                  <label>
                    종료 날짜:
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            )}
            <div className="board-search">
              <input
                type="text"
                placeholder="검색어를 입력해 주세요"
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    getInquiryList(1);
                  }
                }}
              />
              <button
                type="button"
                onClick={(e) => {
                  getInquiryList(1);
                }}
              >
                검색
              </button>
            </div>
          </div>
        </div>

        <div>
          <table className="boardTable">
            <thead>
              <tr>
                <th>열기</th>
                <th>번호</th>
                <th>ID/이메일</th>
                <th>문의유형</th>
                <th>문의일시</th>
                <th>답변상태</th>
                <th>답변일시</th>
              </tr>
            </thead>
            <tbody>
              {inquiryList?.map((item, index) => (
                <>
                  <tr
                    key={item.user_id + index}
                    style={{
                      height: "53px",
                    }}
                    onClick={(e) => handleItemClick(e, index)}
                  >
                    <td>
                      <div>
                        <img
                          className={`arrow-icon ${
                            index === openIndex ? "open" : "closed"
                          }`}
                          src="assets/images/sub/right_arrow_m.png"
                          alt="Arrow"
                        />
                      </div>
                    </td>
                    <td>
                      <div>{inquiryResult.totalrows - item.row_num + 1}</div>
                    </td>
                    <td>
                      <div>
                        {item.user_id !== "null" ? item.user_id : item.email}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.inquiry_type === "service" ? "서비스" : "결과"}
                      </div>
                    </td>
                    <td>
                      <div>
                        {moment(item.inquiry_date).format("YYYY/MM/DD HH:mm")}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.inquiry_state === "0" ? "미답변" : "답변완료"}
                      </div>
                    </td>
                    <td>
                      <div>
                        {item.inquiry_state === "0"
                          ? ""
                          : moment(item.answer_date).format("YYYY/MM/DD HH:mm")}
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`faq-content ${
                      index === openIndex ? "open" : ""
                    }`}
                    style={index === openIndex ? { display: "contents" } : null}
                  >
                    <td colSpan={7}>
                      <div className="inquiry">
                        <form id="contact-form">
                          <div class="form-group">
                            <label for="subject">제목</label>
                            <div className="answer">{item.subject}</div>
                          </div>
                          <div class="form-group">
                            <label for="message">내용</label>
                            <textarea
                              id="message"
                              name="message"
                              rows="10"
                              required
                              resize="none"
                              defaultValue={item.message}
                              disabled={true}
                            />
                          </div>
                          <div class="form-group">
                            <label for="answer">답변</label>
                            <textarea
                              id="answer"
                              name="answer"
                              rows="10"
                              required
                              resize="none"
                              value={answer}
                              onChange={(e) => setAnswer(e.target.value)}
                            />
                          </div>
                          <button
                            type="submit"
                            onClick={(e) => updateInquiry(e, item.number)}
                          >
                            답변전송
                          </button>
                        </form>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <ul className="paging">
            <li>
              <Link
                onClick={() =>
                  getInquiryList(
                    inquiryResult.currentPage - 10 < 1
                      ? 1
                      : inquiryResult.currentPage - 10
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardFirst.png" alt="" />
              </Link>
            </li>
            {pageList?.map((item) => (
              <li
                key={item}
                className={item === inquiryResult.currentPage ? "on" : null}
              >
                <Link
                  onClick={() => getInquiryList(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item}
                </Link>
              </li>
            ))}

            <li>
              <Link
                onClick={() =>
                  getInquiryList(
                    pageList[pageList.length - 1] + 1 < inquiryResult.maxPage
                      ? pageList[pageList.length - 1] + 1
                      : pageList[pageList.length - 1]
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src="/assets/images/common/boardLast.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminInquiryPage;
