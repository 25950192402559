import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import ReactToPrint from "react-to-print";
import FloatingBtn from "components/common/floatingBtn";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";

const AgreeDocumentPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [agreeData, setAgreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userInfoState);

  const ImageComponent = () => {
    return agreeData.signature_data !== null ? (
      <img
        src={`data:image/jpeg;base64,${agreeData.signature_data}`}
        alt="사인"
      />
    ) : null;
  };

  const getReportData = () => {
    setLoading(false);
    axiosFetchA({
      method: "post",
      url: apiUrls.getAgreeData,
      requestConfig: {
        req_id: location.state.req_id,
      },
    })
      .then((res) => {
        if (res.success) {
          setAgreeData(res.agreeData);
          setLoading(true);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  const agreementHandler = (e, reqData) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.updateAgreeData,
      requestConfig: {
        request_id: location.state.req_id,
        is_appropriate: reqData,
        is_appropriate_name: userInfo.user_name,
        complete_id: agreeData.complete_id,
      },
    })
      .then((res) => {
        if (Number(reqData) === 1) {
          //회수요청루틴
          goPostHandler(res.message);
        } else {
          //문자발송
          RequestModification(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  const goPostHandler = (message) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getPostReturn,
      requestConfig: {
        complete_id: agreeData.complete_id,
        user_name: agreeData.user_name,
        user_phone: agreeData.user_phone,
        user_addr1: agreeData.user_addr1,
        user_addr2: agreeData.user_addr2,
      },
    })
      .then(() => {
        alert(message);
        navigate(-1);
      })
      .catch((err) => {});
  };

  const RequestModification = (message) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.RequestModification,
      requestConfig: {
        customer_phone: agreeData.user_phone,
      },
    })
      .then((res) => {
        alert(message);
        navigate(-1);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (location.state) {
      getReportData();
    }
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <div className="container">
            <div className="content-box5">
              <img src="/assets/images/common/genomecheck.png" alt="" />
              <div className="main-title">
                <span>Loding...</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-box4">
              <div className="sub-title">
                <span>
                  동의서 로딩중...이 화면이 지속되면 관리자에게 문의해주세요.
                </span>
              </div>
            </div>
          </div>
        </>
      ) : agreeData.item_name === "오브미365-62종" ? (
        <>
          <div className="container">
            <div className="content-box4">
              <ReactToPrint
                trigger={() => (
                  <button className="floating">
                    <div>
                      <img src="/assets/images/common/print.png" alt="" />
                    </div>
                  </button>
                )}
                content={() => ref.current}
              />
              <div ref={ref}>
                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_01.jpg" alt="" />
                  <div className="agree1-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  {agreeData.personal_information_agree1 === "agree" ? (
                    <div className="agree1-1"></div>
                  ) : (
                    <div className="agree1-2"></div>
                  )}
                  {agreeData.personal_information_agree2 === "agree" ? (
                    <div className="agree1-5"></div>
                  ) : (
                    <div className="agree1-6"></div>
                  )}
                  <div className="agree1-7">
                    {moment(agreeData.request_time).format("YY")}
                  </div>
                  <div className="agree1-8">
                    {moment(agreeData.request_time).format("MM")}
                  </div>
                  <div className="agree1-9">
                    {moment(agreeData.request_time).format("DD")}
                  </div>
                  <div className="agree1-10">{agreeData.user_name}</div>
                  <div className="agree1-11">
                    <ImageComponent />
                  </div>
                </div>

                {/* <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_02.jpg" alt="" />
                  <div className="agree4-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  {agreeData.sensitive_information_agree === "agree" ? (
                    <div className="agree4-1"></div>
                  ) : (
                    <div className="agree4-2"></div>
                  )}
                  <div className="agree4-3">
                    {moment(agreeData.request_time).format("YY")}
                  </div>
                  <div className="agree4-4">
                    {moment(agreeData.request_time).format("MM")}
                  </div>
                  <div className="agree4-5">
                    {moment(agreeData.request_time).format("DD")}
                  </div>

                  <div className="agree4-6">{agreeData.user_name}</div>
                  <div className="agree4-7">
                    <ImageComponent />
                  </div>
                </div> */}

                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_03.jpg" alt="" />
                  <div className="agree2-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  <div className="agree2-1">{agreeData.user_name}</div>
                  {Number(agreeData.user_gender) === 1 ? (
                    <div className="agree2-2"></div>
                  ) : (
                    <div className="agree2-3"></div>
                  )}
                  {agreeData.user_birth ? (
                    <>
                      <div className="agree2-4">
                        {moment(agreeData.user_birth).format("YYYY")}
                      </div>
                      <div className="agree2-5">
                        {moment(agreeData.user_birth).format("MM")}
                      </div>
                      <div className="agree2-6">
                        {moment(agreeData.user_birth).format("DD")}
                      </div>
                    </>
                  ) : null}

                  <div className="agree2-7"></div>
                  {agreeData.collection_date ? (
                    <>
                      <div className="agree2-8">
                        {moment(agreeData.collection_date).format("YYYY")}
                      </div>
                      <div className="agree2-9">
                        {moment(agreeData.collection_date).format("MM")}
                      </div>
                      <div className="agree2-10">
                        {moment(agreeData.collection_date).format("DD")}
                      </div>
                    </>
                  ) : null}

                  <div className="agree2-11"></div>

                  {agreeData.race === "korean" ? (
                    <>
                      <div className="agree2-12"></div>
                    </>
                  ) : null}

                  {agreeData.race === "asian" ? (
                    <>
                      <div className="agree2-13"></div>
                      <div className="agree2-13-1">{agreeData.race_info}</div>
                    </>
                  ) : null}

                  {agreeData.race === "european" ? (
                    <>
                      <div className="agree2-14"></div>
                      <div className="agree2-14-1">{agreeData.race_info}</div>
                    </>
                  ) : null}

                  {agreeData.race === "american" ? (
                    <>
                      <div className="agree2-15"></div>
                      <div className="agree2-15-1">{agreeData.race_info}</div>
                    </>
                  ) : null}

                  {agreeData.race === "etc" ? (
                    <>
                      <div className="agree2-16"></div>
                      <div className="agree2-16-1">{agreeData.race_info}</div>
                    </>
                  ) : null}
                </div>

                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_04.jpg" alt="" />
                  <div className="agree3-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  <div className="agree3-1">
                    <span>{agreeData.user_name}</span>
                  </div>
                  <div className="agree3-2">
                    <span>
                      {agreeData.user_birth
                        ? moment(agreeData.user_birth, "YYYYMMDD").format(
                            "YYYY년 MM월 DD일"
                          )
                        : null}
                    </span>
                  </div>
                  <div className="agree3-3">
                    <span>{`(${agreeData.user_post}) ${agreeData.user_addr1} ${agreeData.user_addr2}`}</span>
                  </div>
                  <div className="agree3-4">
                    <span>
                      {agreeData.user_phone?.replace(
                        /(\d{3})(\d{4})(\d{4})/,
                        "$1-$2-$3"
                      )}
                    </span>
                  </div>
                  <div className="agree3-5">
                    <span>
                      {Number(agreeData.user_gender) === 1 ? "남" : "여"}
                    </span>
                  </div>
                </div>

                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_05.jpg" alt="" />
                  <div className="agree3-10">
                    <span>{moment(agreeData.request_time).format("YYYY")}</span>
                  </div>
                  <div className="agree3-11">
                    <span>{moment(agreeData.request_time).format("MM")}</span>
                  </div>
                  <div className="agree3-12">
                    <span>{moment(agreeData.request_time).format("DD")}</span>
                  </div>
                  <div className="agree3-13">
                    <span>{agreeData.user_name}</span>
                  </div>
                  <div className="agree3-14">
                    <ImageComponent />
                  </div>
                  <div className="agree3-15">
                    <span>디엑스앤브이엑스</span>
                  </div>
                  <div className="agree3-16">
                    <img
                      src="/assets/images/common/agree_sign.png"
                      alt="사인"
                    />
                  </div>
                </div>
              </div>
              <FloatingBtn />
              <div className="agree-btn-area">
                {agreeData.is_appropriate !== 1 ? (
                  <button
                    className="btn1"
                    onClick={(e) => agreementHandler(e, 1)}
                  >
                    적합
                  </button>
                ) : null}

                {agreeData.is_appropriate !== 2 ? (
                  <button
                    className="btn2"
                    onClick={(e) => agreementHandler(e, 2)}
                  >
                    부적합
                  </button>
                ) : null}

                <button className="btn3" onClick={(e) => navigate(-1)}>
                  닫기
                </button>
              </div>
            </div>
          </div>
        </>
      ) : agreeData.item_name === "오브미365-58종" ? (
        <>
          <div className="container">
            <div className="content-box4">
              <ReactToPrint
                trigger={() => (
                  <button className="floating">
                    <div>
                      <img src="/assets/images/common/print.png" alt="" />
                    </div>
                  </button>
                )}
                content={() => ref.current}
              />
              <div ref={ref}>
                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_db_01.jpg" alt="" />
                  <div className="agree-db-1-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  {agreeData.personal_information_agree1 === "agree" ? (
                    <div className="agree-db-1-1"></div>
                  ) : (
                    <div className="agree-db-1-2"></div>
                  )}
                  {/* {agreeData.personal_information_agree3 === "agree" ? (
                    <div className="agree-db-1-3"></div>
                  ) : (
                    <div className="agree-db-1-4"></div>
                  )} */}
                  {agreeData.personal_information_agree2 === "agree" ? (
                    <div className="agree-db-1-5"></div>
                  ) : (
                    <div className="agree-db-1-6"></div>
                  )}
                  <div className="agree-db-1-7">
                    {moment(agreeData.request_time).format("YY")}
                  </div>
                  <div className="agree-db-1-8">
                    {moment(agreeData.request_time).format("MM")}
                  </div>
                  <div className="agree-db-1-9">
                    {moment(agreeData.request_time).format("DD")}
                  </div>
                  <div className="agree-db-1-10">{agreeData.user_name}</div>
                  <div className="agree-db-1-11">
                    <ImageComponent />
                  </div>
                </div>

                {/* <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_db_02.jpg" alt="" />
                  <div className="agree-db-4-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  {agreeData.sensitive_information_agree === "agree" ? (
                    <div className="agree-db-4-1"></div>
                  ) : (
                    <div className="agree-db-4-2"></div>
                  )}
                  <div className="agree-db-4-3">
                    {moment(agreeData.request_time).format("YY")}
                  </div>
                  <div className="agree-db-4-4">
                    {moment(agreeData.request_time).format("MM")}
                  </div>
                  <div className="agree-db-4-5">
                    {moment(agreeData.request_time).format("DD")}
                  </div>

                  <div className="agree-db-4-6">{agreeData.user_name}</div>
                  <div className="agree-db-4-7">
                    <ImageComponent />
                  </div>
                </div> */}

                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_db_03.jpg" alt="" />
                  <div className="agree-db-2-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  <div className="agree-db-2-1">{agreeData.user_name}</div>
                  {Number(agreeData.user_gender) === 1 ? (
                    <div className="agree-db-2-2"></div>
                  ) : (
                    <div className="agree-db-2-3"></div>
                  )}
                  {agreeData.user_birth ? (
                    <>
                      <div className="agree-db-2-4">
                        {moment(agreeData.user_birth).format("YYYY")}
                      </div>
                      <div className="agree-db-2-5">
                        {moment(agreeData.user_birth).format("MM")}
                      </div>
                      <div className="agree-db-2-6">
                        {moment(agreeData.user_birth).format("DD")}
                      </div>
                    </>
                  ) : null}

                  <div className="agree-db-2-19">{agreeData.user_email} </div>

                  {/* {agreeData.individuals !== "Foreigner" ? (
                    <div className="agree-db-2-17"></div>
                  ) : (
                    <div className="agree-db-2-18"></div>
                  )} */}

                  <div className="agree-db-2-7"></div>
                  {agreeData.collection_date ? (
                    <>
                      <div className="agree-db-2-8">
                        {moment(agreeData.collection_date).format("YYYY")}
                      </div>
                      <div className="agree-db-2-9">
                        {moment(agreeData.collection_date).format("MM")}
                      </div>
                      <div className="agree-db-2-10">
                        {moment(agreeData.collection_date).format("DD")}
                      </div>
                    </>
                  ) : null}

                  <div className="agree-db-2-11"></div>

                  {/* {agreeData.race === "korean" ? (
                    <>
                      <div className="agree-db-2-12"></div>
                    </>
                  ) : null}

                  {agreeData.race === "asian" ? (
                    <>
                      <div className="agree-db-2-13"></div>
                      <div className="agree-db-2-13-1">
                        {agreeData.race_info}
                      </div>
                    </>
                  ) : null}

                  {agreeData.race === "european" ? (
                    <>
                      <div className="agree-db-2-14"></div>
                      <div className="agree-db-2-14-1">
                        {agreeData.race_info}
                      </div>
                    </>
                  ) : null}

                  {agreeData.race === "american" ? (
                    <>
                      <div className="agree-db-2-15"></div>
                      <div className="agree-db-2-15-1">
                        {agreeData.race_info}
                      </div>
                    </>
                  ) : null}

                  {agreeData.race === "etc" ? (
                    <>
                      <div className="agree-db-2-16"></div>
                      <div className="agree-db-2-16-1">
                        {agreeData.race_info}
                      </div>
                    </>
                  ) : null} */}
                </div>

                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_db_04.jpg" alt="" />
                  <div className="agree-db-3-0">
                    <span>{agreeData.kit_number}</span>
                  </div>
                  <div className="agree-db-3-1">
                    <span>{agreeData.user_name}</span>
                  </div>
                  <div className="agree-db-3-2">
                    <span>
                      {agreeData.user_birth
                        ? moment(agreeData.user_birth, "YYYYMMDD").format(
                            "YYYY년 MM월 DD일"
                          )
                        : null}
                    </span>
                  </div>
                  <div className="agree-db-3-3">
                    <span>{`(${agreeData.user_post}) ${agreeData.user_addr1} ${agreeData.user_addr2}`}</span>
                  </div>
                  <div className="agree3-4">
                    <span>
                      {agreeData.user_phone?.replace(
                        /(\d{3})(\d{4})(\d{4})/,
                        "$1-$2-$3"
                      )}
                    </span>
                  </div>
                  <div className="agree-db-3-5">
                    <span>
                      {Number(agreeData.user_gender) === 1 ? "남" : "여"}
                    </span>
                  </div>
                </div>

                <div
                  className="report1"
                  style={{ border: "1px solid #e3e3e3" }}
                >
                  <img src="/assets/images/agree/agree_db_05.jpg" alt="" />
                  <div className="agree-db-3-10">
                    <span>{moment(agreeData.request_time).format("YYYY")}</span>
                  </div>
                  <div className="agree-db-3-11">
                    <span>{moment(agreeData.request_time).format("MM")}</span>
                  </div>
                  <div className="agree-db-3-12">
                    <span>{moment(agreeData.request_time).format("DD")}</span>
                  </div>
                  <div className="agree-db-3-13">
                    <span>{agreeData.user_name}</span>
                  </div>
                  <div className="agree-db-3-14">
                    <ImageComponent />
                  </div>
                  <div className="agree-db-3-15">
                    <span>{agreeData.is_appropriate_name}</span>
                  </div>
                  <div className="agree3-16">
                    {agreeData.is_appropriate_name === "류란숙" ? (
                      <img src="/assets/images/common/ryu.png" alt="사인" />
                    ) : agreeData.is_appropriate_name === "권영은" ? (
                      <img src="/assets/images/common/kwon.png" alt="사인" />
                    ) : null}
                  </div>
                </div>
              </div>
              <FloatingBtn />
              <div className="agree-btn-area">
                {agreeData.is_appropriate !== 1 ? (
                  <button
                    className="btn1"
                    onClick={(e) => agreementHandler(e, 1)}
                  >
                    적합
                  </button>
                ) : null}

                {agreeData.is_appropriate !== 2 ? (
                  <button
                    className="btn2"
                    onClick={(e) => agreementHandler(e, 2)}
                  >
                    부적합
                  </button>
                ) : null}

                <button className="btn3" onClick={(e) => navigate(-1)}>
                  닫기
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AgreeDocumentPage;
