import React from "react";
import "assets/css/report.css";

const ReportDAType4Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page4">
        <h2 className="grui-title">검사항목 리스트</h2>
        <div className="grui-page4-content">
          <ul className="grui-icon-top-list type1">
            <li>
              <div className="grui-box-item">
                <span>
                  <i className="gicon-fill"></i>
                </span>
                <h3>영양소</h3>
                <div>Nutrition</div>
                <p>
                  개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                  유전자에 대한 분석 결과입니다
                </p>
              </div>
            </li>
            <li>
              <div className="grui-box-item">
                <span>
                  <i className="gicon-health-care"></i>
                </span>
                <h3>건강관리</h3>
                <div>Health care</div>
                <p>
                  개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                  유전자에 대한 분석 결과입니다
                </p>
              </div>
            </li>
            <li>
              <div className="grui-box-item">
                <span>
                  <i className="gicon-person"></i>
                </span>
                <h3>개인특성</h3>
                <div>Personal characteristics</div>
                <p>
                  개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                  유전자에 대한 분석 결과입니다
                </p>
              </div>
            </li>
          </ul>
          <ul className="grui-icon-top-list type2">
            <li>
              <div className="grui-box-item">
                <span>
                  <i className="gicon-water"></i>
                </span>
                <h3>피부/모발</h3>
                <div>Skin/Hair</div>
                <p>
                  개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                  유전자에 대한 분석 결과입니다
                </p>
              </div>
            </li>
            <li>
              <div className="grui-box-item">
                <span>
                  <i className="gicon-athletic"></i>
                </span>
                <h3>운동능력</h3>
                <div>Athletic ability</div>
                <p>
                  개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                  유전자에 대한 분석 결과입니다
                </p>
              </div>
            </li>
            <li>
              <div className="grui-box-item">
                <span>
                  <i className="gicon-eating"></i>
                </span>
                <h3>식습관</h3>
                <div>Eating habits</div>
                <p>
                  개인특성 관련 유전정보 분석 결과는 각 개인의 특성에 관여하는
                  유전자에 대한 분석 결과입니다
                </p>
              </div>
            </li>
          </ul>
        </div>
        {/* <div className="grui-logo">
          <img src="../assets/images/logo_bottom.png" alt="" />
        </div> */}
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType4Page;
