import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import ReportList from "./reportList";
import { CustomRegExp } from "helpers/customRegExp";
import "assets/css/board.css";

const AddMyReport = () => {
  const userInfo = useRecoilValue(userInfoState);
  const [confirmed, setConfirmed] = useState(false);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const addReport = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.addReport,
      requestConfig: {
        user_id: userInfo.user_id,
        kit_number: reqData.kit_number,
        confirm_code: reqData.confirm_code,
        user_birth: userInfo.user_birth,
        user_name: userInfo.user_name,
      },
    })
      .then((res) => {
        if (res.success) {
          setConfirmed(!confirmed);
          addOrderWorkHistoryWithKitnumber(
            reqData.kit_number,
            "검사결과 등록성공"
          );
        }
        alert(res.message);
      })
      .catch((err) => {});
  };

  const addOrderWorkHistoryWithKitnumber = (kit_number, order_work_content) => {
    axiosFetch({
      method: "post",
      url: apiUrls.addOrderWorkHistoryWithKitnumber,
      requestConfig: {
        kit_number: kit_number,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
        order_work_content: order_work_content,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="container">
        <div className="content-box5">
          <img src="/assets/images/common/genomecheck.png" alt="" />
          <div className="main-title">
            <span>검사결과 확인하기</span>
            <div className="rt" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="content-box4">
          <div className="board-list">
            <div className="board-info">
              <div className="add-sample-user">
                <form className="input-area" onSubmit={handleSubmit(addReport)}>
                  <InputUnit
                    label="키트번호"
                    holder="키트번호"
                    register={register("kit_number", {
                      required: "필수 입력 값 입니다.",
                      pattern: CustomRegExp("kit_number"),
                    })}
                    errors={formErrors}
                  />
                  <InputUnit
                    label="Confirm Code"
                    holder="확인코드"
                    register={register("confirm_code", {
                      required: "필수 입력 값 입니다.",
                      minLength: {
                        value: 6,
                        message: "확인코드는 6자리입니다.",
                      },
                      maxLength: {
                        value: 6,
                        message: "확인코드는 6자리입니다.",
                      },
                    })}
                    errors={formErrors}
                  />
                  <div className="board-btn-area">
                    <button className="btn" type="submit">
                      검사결과 확인하기
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportList confirmed={confirmed} setConfirmed={setConfirmed} />
    </>
  );
};

export default AddMyReport;
