import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const Signature = (props) => {
  const canvasRef = useRef();
  const [isSigned, setIsSigned] = useState(false);
  const setIsAgree = props.setIsAgree;
  const setIsSign = props.setIsSign;
  const setSignatureData = props.setSignatureData;
  const signatureData = props.signatureData;

  const cancelSignature = () => {
    setIsSign(false);
    setIsAgree(false);
    canvasRef.current.clear();
  };

  const clearSignature = () => {
    setIsSigned(false);
    canvasRef.current.clear();
  };

  const saveSignature = () => {
    setSignatureData(canvasRef.current.toDataURL());
    setIsSign(false);
  };

  useEffect(() => {
    if (signatureData) {
      const canvas = canvasRef.current.getCanvas();
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = signatureData;
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
      };
      setIsSigned(true);
    }
  }, []);

  return (
    <div className="dialog-wrap">
      <div className="dialog-overlay" onClick={() => cancelSignature()} />
      <div className="sign-box">
        <div className="canvasContainer">
          <div className="canvasStyle">
            {!isSigned && <div className="holder">여기에 서명을 해주세요.</div>}
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                width: 400,
                height: 195,
                className: "signature-canvas",
              }}
              onBegin={() => {
                setIsSigned(true);
              }}
            />
          </div>
          <div className="btn-area">
            <button onClick={() => cancelSignature()}>취소</button>
            <button onClick={() => clearSignature()}>초기화</button>
            <button disabled={!isSigned} onClick={() => saveSignature()}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;
