import React, { useState, useEffect } from "react";
import useAxiosFunction from "hooks/useAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import { isUserState } from "recoil/atoms";
import UserInfoPage from "pages/user/user/userInfoPage";
import UserInquiryPage from "pages/user/user/userInquiryPage";
import UserOrderListPage from "./user/userOrderListPage";
import { useLocation } from "react-router-dom";
import "assets/css/board.css";

const UserPage = () => {
  const location = useLocation();
  const [menuIndex, setMenuIndex] = useRecoilState(isUserState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const userInfo = useRecoilValue(userInfoState);
  const [user, setUser] = useState();
  const ChangeMenu = (e, select) => {
    e.preventDefault();
    setMenuIndex(select);
  };

  const getUserInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getUserInfo,
      requestConfig: { user_id: userInfo.user_id },
    })
      .then((res) => {
        if (res.success) {
          setUser(res.userInfo);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (location?.state?.index) {
      setMenuIndex(location?.state?.index);
    }
  }, [location]);

  return (
    <>
      <div className="container">
        <div className="content-box5">
          <img src="/assets/images/common/genomecheck.png" alt="" />
          <div className="main-menu">
            <div className="menu-title" onClick={(e) => ChangeMenu(e, 0)}>
              <span className={menuIndex === 0 ? "isHit" : ""}>회원 정보</span>
              {menuIndex === 0 ? <div className="rt" /> : null}
            </div>
            <div className="menu-title" onClick={(e) => ChangeMenu(e, 1)}>
              <span className={menuIndex === 1 ? "isHit" : ""}>
                1:1문의내역
              </span>
              {menuIndex === 1 ? <div className="rt" /> : null}
            </div>
            <div className="menu-title" onClick={(e) => ChangeMenu(e, 2)}>
              <span className={menuIndex === 2 ? "isHit" : ""}>주문내역</span>
              {menuIndex === 2 ? <div className="rt" /> : null}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="line" />
      </div>

      {menuIndex === 0 ? (
        <UserInfoPage user={user} />
      ) : menuIndex === 1 ? (
        <UserInquiryPage user_id={userInfo.user_id} />
      ) : menuIndex === 2 ? (
        <UserOrderListPage user_id={userInfo.user_id} />
      ) : null}
    </>
  );
};

export default UserPage;
