import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { inquiryUListPageIndexState } from "recoil/atoms";
import useAxiosFunction from "hooks/useAxios";
import "assets/css/board.css";
import moment from "moment";

const UserInquiryPage = (props) => {
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [openIndex, setOpenIndex] = useState(null);
  const [inquiryList, setInquiryList] = useState([]);
  const [inquiryResult, setInquiryResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [inquiryListPage, setInquiryListPage] = useRecoilState(
    inquiryUListPageIndexState
  );

  const handleItemClick = (e, index) => {
    e.preventDefault();
    setOpenIndex(index === openIndex ? null : index);
  };

  const getInquiryList = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getInquiryUList,
      requestConfig: { currentNavPage: reqData, user_id: props.user_id },
    })
      .then((res) => {
        if (res.success) {
          setInquiryResult(res.result);
          setInquiryList(res.inquiryList);
          setPageList(res.pageList);
          setInquiryListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] getUserList : ", err);
      });
  };

  useEffect(() => {
    getInquiryList(inquiryListPage);
  }, []);

  return (
    <>
      <div className="container">
        <div className="content-box">
          <div className="board-list">
            <div className="board-info">
              <div className="board-total">
                <span>총 </span>
                {inquiryResult.totalrows}
                <span>건의 문의</span>
              </div>
            </div>
            <ul className="boardList">
              <li className="head">
                <div className="board-num">
                  <strong>열기</strong>
                </div>
                <div className="board-num">
                  <strong>번호</strong>
                </div>
                <div className="user-id">
                  <strong>ID/이메일</strong>
                </div>
                <div className="user-type">
                  <strong>문의유형</strong>
                </div>
                <div className="report-date">
                  <strong>문의일시</strong>
                </div>
                <div className="user-type">
                  <strong>답변상태</strong>
                </div>
                <div className="report-date">
                  <strong>답변일시</strong>
                </div>
              </li>
              {inquiryList?.map((item, index) => (
                <>
                  <li
                    key={item.row_num}
                    className="mobile-board-li"
                    onClick={(e) => handleItemClick(e, index)}
                  >
                    <div className="board-num">
                      <img
                        className={`arrow-icon ${
                          index === openIndex ? "open" : "closed"
                        }`}
                        src="assets/images/sub/right_arrow_m.png"
                        alt="Arrow"
                      />
                    </div>
                    <div className="board-num">{item.row_num}</div>
                    <div className="user-id">
                      {item.user_id}
                      {item.email}
                    </div>
                    <div className="user-type">
                      {item.inquiry_type === "service" ? "서비스" : "결과"}
                    </div>
                    <div className="report-date">
                      {moment(item.inquiry_date).format("YYYY/MM/DD HH:mm")}
                    </div>
                    <div className="user-type">
                      {item.inquiry_state === "0" ? "미답변" : "답변완료"}
                    </div>
                    <div className="report-date">
                      {moment(item.answer_date).format("YYYY/MM/DD HH:mm")}
                    </div>
                  </li>
                  <li
                    key={item.user_id}
                    className="pc-board-li"
                    onClick={(e) => handleItemClick(e, index)}
                  >
                    <div className="board-num">
                      <img
                        className={`arrow-icon ${
                          index === openIndex ? "open" : "closed"
                        }`}
                        src="assets/images/sub/right_arrow_m.png"
                        alt="Arrow"
                      />
                    </div>
                    <div className="board-num">{item.row_num}</div>
                    <div className="user-id">
                      {item.user_id}
                      {item.email}
                    </div>
                    <div className="user-type">
                      {item.inquiry_type === "service" ? "서비스" : "결과"}
                    </div>
                    <div className="report-date">
                      {moment(item.inquiry_date).format("YYYY/MM/DD HH:mm")}
                    </div>
                    <div className="user-type">
                      {item.inquiry_state === "0" ? "미답변" : "답변완료"}
                    </div>
                    <div className="report-date">
                      {item.inquiry_state === "0"
                        ? ""
                        : moment(item.answer_date).format("YYYY/MM/DD HH:mm")}
                    </div>
                  </li>
                  <li
                    className={`faq-content ${
                      index === openIndex ? "open" : ""
                    }`}
                  >
                    <div className="inquiry">
                      <form id="contact-form">
                        <div class="form-group">
                          <label for="subject">제목</label>
                          <div className="answer">{item.subject}</div>
                        </div>
                        <div class="form-group">
                          <label for="message">내용</label>
                          <textarea
                            id="message"
                            name="message"
                            rows="10"
                            required
                            resize="none"
                            defaultValue={item.message}
                            disabled="true"
                          />
                        </div>
                        <div class="form-group">
                          <label for="answer">답변</label>
                          <textarea
                            id="answer"
                            name="answer"
                            rows="10"
                            required
                            resize="none"
                            defaultValue={item.answer}
                            disabled="true"
                          />
                        </div>
                      </form>
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <ul className="paging">
        <li>
          <Link
            onClick={() =>
              getInquiryList(
                inquiryResult.currentPage - 10 < 1
                  ? 1
                  : inquiryResult.currentPage - 10
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/common/boardFirst.png" alt="" />
          </Link>
        </li>
        {pageList?.map((item) => (
          <li
            key={item}
            className={item === inquiryResult.currentPage ? "on" : null}
          >
            <Link
              onClick={() => getInquiryList(item)}
              style={{ cursor: "pointer" }}
            >
              {item}
            </Link>
          </li>
        ))}

        <li>
          <Link
            onClick={() =>
              getInquiryList(
                pageList[pageList.length - 1] + 1 < inquiryResult.maxPage
                  ? pageList[pageList.length - 1] + 1
                  : pageList[pageList.length - 1]
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="/assets/images/common/boardLast.png" alt="" />
          </Link>
        </li>
      </ul>
    </>
  );
};

export default UserInquiryPage;
