// imageHandler.js

const imageHandler = (quillRef, axiosFetchForm, apiUrls) => {
  let input;

  if (!input) {
    input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    // input이 클릭되면 파일 선택창이 나타난다.
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];
      if (file.size > 12 * 1024 * 1024) {
        alert("이미지 파일 크기는 12MB까지만 가능합니다.");
        return;
      } else if (!file.type.match("image/.*")) {
        alert("이미지 파일만 업로드 가능합니다.");
        return;
      }

      // multer에 맞는 형식으로 데이터 만들어준다.
      const formData = new FormData();
      formData.append("img", file); // formData는 키-밸류 구조

      // 백엔드 multer라우터에 이미지를 보낸다.
      axiosFetchForm({
        method: "post",
        url: apiUrls.saveWriteImg,
        formData: formData,
      })
        .then((res) => {
          // console.log("Image URL from server:", res.url);

          const IMG_URL = res.url;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", IMG_URL);
        })
        .catch((err) => {
          // console.log(err);
        });
    });
  }
};

export default imageHandler;
