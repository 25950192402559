import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";
import MarketDescriptionPage from "pages/market/marketDescriptionPage";
import MarketPaymentPage from "pages/market/marketPaymentPage";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import "assets/css/market.css";

const MarketDetailPage = () => {
  const { postId } = useParams();
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [itemDetail, setItemDetail] = useState();
  const [isPayment, setIsPayment] = useState(false);
  const userInfo = useRecoilValue(userInfoState);

  const getItemDetail = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getItemDetail,
      requestConfig: { item_id: postId },
    })
      .then((res) => {
        if (res.success) {
          setItemDetail(res.itemDetail);
        }
      })
      .catch((err) => {});
  };

  const paymentHandler = (e, data) => {
    if (e) {
      e.preventDefault();
    }

    if (userInfo?.isLogin) {
      setIsPayment(data);
    } else {
      alert("로그인후 결제해주세요.");
    }
  };

  useEffect(() => {
    getItemDetail();
  }, []);

  return (
    <>
      <section id="sub">
        <div className="sub-common">
          <div className="nav">
            <Link to="/">HOME</Link>
            <Link to="#">MARKET</Link>
          </div>
          <div className="tit">
            {!isPayment ? "GenomeCheck Buying" : "GenomeCheck Payment"}
          </div>
          <div className="subTit">
            {!isPayment
              ? "상품 설명을 보시고 구매해 주세요."
              : "구매 동의 및 배송지를 확인 후 결제 해주세요."}
          </div>
        </div>
      </section>
      {!isPayment ? (
        <MarketDescriptionPage
          itemDetail={itemDetail}
          paymentHandler={paymentHandler}
        />
      ) : (
        <MarketPaymentPage
          itemDetail={itemDetail}
          paymentHandler={paymentHandler}
          userID={userInfo?.user_id}
        />
      )}
    </>
  );
};

export default MarketDetailPage;
