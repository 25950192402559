import React from "react";
import "assets/css/report.css";

const ReportDDType15Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page2">
        <h2 className="grui-title border">유전자 검사 이해하기</h2>
        <p className="grui-standfirst">
          나의 결과를 정확하게 이해하도록 결과 확인 전 꼭 읽어주세요!
        </p>
        <section>
          <h3 className="grui-title size2">유전적 경향성 계산법</h3>
          <ul className="grui-icon-list">
            <li>
              <div
                className="grui-il-item"
                style={{
                  alignItems: "flex-start",
                  padding: "40px 0 40px 20px",
                }}
              >
                <div className="grui-il-icon">
                  <img src="../assets/images/page2/icon04.png" alt="" />
                </div>
                <div className="grui-il-text">
                  <h4>항목별 영향인자 확인</h4>
                  <p>
                    이 유전자 검사는 최신 연구 결과와 논문에서 발표된 특정
                    유전형과 그 유전적 특성 사이의 관계를 조사한 결과를 기반으로
                    합니다.
                    <br />각 유전형은 특정 상태와 얼마나 연결되어 있는지를
                    알려줍니다.
                    <br />
                    예를 들어, 어떤 유전형은 탈모나 비만과 관련이 있을 수
                    있습니다.
                    <br />
                    따라서 이 검사는 이런 연구 결과를 이용해서 개인의 유전적
                    특성을 찾아내고, 그것이 개인 수준에 어떤 영향을 미칠 수
                    있는지를 평가합니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div
                className="grui-il-item"
                style={{
                  alignItems: "flex-start",
                  padding: "40px 0 40px 20px",
                }}
              >
                <div className="grui-il-icon">
                  <img src="../assets/images/page2/icon05.png" alt="" />
                </div>
                <div className="grui-il-text">
                  <h4>위험 수치 계산법</h4>
                  <p>
                    이 유전자 검사는 각 항목에서 발견된 영향을 미치는 대립
                    유전자의 수를 전체 대립 유전자 수와 비교하여 개인의 특성을
                    예측하는 방법을 사용합니다.
                    <br />각 항목의 유전자를 분석하여, 특정 상태에 영향을 미칠
                    가능성이 있는 대립 유전자의 비율을 계산하고 이를 점수화하여
                    개인의 유전적 특성을 평가합니다.
                    <br />
                    이를 유전적 영향 점수라고합니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div
                className="grui-il-item"
                style={{
                  alignItems: "flex-start",
                  padding: "40px 0 40px 20px",
                }}
              >
                <div className="grui-il-icon">
                  <img src="../assets/images/page2/icon06.png" alt="" />
                </div>
                <div className="grui-il-text">
                  <h4>최종 등급 부여</h4>
                  <p>
                    검사 결과는 개인의 유전적 경향성을 평가하기 위해, 각
                    항목에서 계산된 유전적 영향 점수를 3:4:3 비율로 분류하여
                    등급을 부여합니다.
                    <br />
                    이를 통해 각 개인의 유전적 상태와 관련된 다양한 특성을
                    예측하고, 그에 따른 적절한 관리를 수행할 수 있습니다.
                    <br />
                    예를 들어, "주의" 항목에 해당하는 사람은 특별한 주의가
                    필요한 건강 관리 방법을 채택해야 할 수 있습니다.
                    <br />
                    반면에 "양호" 항목에 해당하는 사람은 현재 건강 상태를 잘
                    유지하기 위한 조치를 취할 수 있습니다.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDDType15Page;
