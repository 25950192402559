import React, { useState } from "react";
import moment from "moment";
import "assets/css/report.css";

const ReportDAType11Page = (props) => {
  const [pass, setPass] = useState("PASS");
  const reportInfo = props.reportInfo;

  return (
    <div className="grui-report">
      <div className="grui-page page12">
        <h2 className="grui-title border">분석 안내서</h2>
        <div className="grui-results-sheet fixed info-person">
          <h3>
            <span>검사 대상자 정보</span>
          </h3>
          <div className="grui-results-table">
            <div className="grui-rtable-body">
              <section>
                <div className="rtable-col01">
                  <strong>성명:</strong>
                  <span>
                    {reportInfo?.user_name ? reportInfo?.user_name : "-"}
                  </span>
                </div>
                <div className="rtable-col02">
                  <strong>성별:</strong>
                  <span>
                    {Number(reportInfo?.user_gender) === 1 ? "남자" : "여자"}
                  </span>
                </div>
                <div className="rtable-col03">
                  <strong>생년월일:</strong>
                  <span>
                    {reportInfo?.user_birth
                      ? moment(reportInfo?.user_birth).format("YYYY / MM / DD")
                      : "-"}
                  </span>
                </div>
                <div className="rtable-col06">
                  <strong>전화번호:</strong>
                  <span>
                    {reportInfo?.user_phone ? reportInfo?.user_phone : ""}
                  </span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>주소:</strong>
                  <span>
                    {reportInfo?.user_post
                      ? `(${reportInfo?.user_post}) ${reportInfo?.user_addr1} ${reportInfo?.user_addr2} `
                      : "-"}
                  </span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>검사 신청일:</strong>
                  <span>
                    {reportInfo?.receipt_date
                      ? moment(reportInfo?.receipt_date).format(
                          "YYYY / MM / DD"
                        )
                      : "-"}
                  </span>
                </div>
                <div className="rtable-col02">
                  <strong>동의서 작성일:</strong>
                  <span>
                    {reportInfo?.agree_date
                      ? moment(reportInfo?.agree_date).format("YYYY / MM / DD")
                      : "-"}
                  </span>
                </div>
                <div className="rtable-col03">
                  <strong>검체 접수일:</strong>
                  <span>
                    {reportInfo?.receipt_date
                      ? moment(reportInfo?.receipt_date).format(
                          "YYYY / MM / DD"
                        )
                      : "-"}
                  </span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>키트 번호:</strong>
                  <span>
                    {reportInfo?.kit_number ? reportInfo?.kit_number : "-"}
                  </span>
                </div>
                <div className="rtable-col02">
                  <strong>판매처:</strong>
                  <span>{reportInfo?.market ? reportInfo?.market : "-"}</span>
                </div>
                <div className="rtable-col03">
                  <strong>판매 경로:</strong>
                  <span>
                    {reportInfo?.market_path ? reportInfo?.market_path : "-"}
                  </span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>검체종류 :</strong>
                  <span>구강상피세포(Buccal Swab)</span>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="grui-results-sheet info-iqc">
          <h3>
            <span>내부정도관리</span>
          </h3>
          <div className="grui-results-table">
            <div className="grui-rtable-head">
              <div className="rtable-col01">
                <span>검체의 적절성</span>
              </div>
              <div className="rtable-col02">
                <span>DNA 품질</span>
              </div>
              <div className="rtable-col03">
                <span>표준물질결과</span>
              </div>
              <div className="rtable-col04">
                <span>재검체 필요</span>
              </div>
            </div>
            <div className="grui-rtable-body">
              <section>
                <div className="rtable-col01">
                  {/* <span className="text">{userInfo?.standard_propriety}</span> */}
                  <span className="text">{pass}</span>
                </div>
                <div className="rtable-col02">
                  {/* <span className="text">{userInfo?.standard_dna_quality}</span> */}
                  <span className="text">{pass}</span>
                </div>
                <div className="rtable-col03">
                  <span className="text">
                    {/* {userInfo?.standard_array_quality} */}
                    <span className="text">{pass}</span>
                  </span>
                </div>
                <div className="rtable-col04">
                  <span className="text">
                    {/* {userInfo?.standard_material_result} */}
                    <span className="text">{pass}</span>
                  </span>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="grui-results-sheet fixed info-agency">
          <h3>
            <span>검사 기관 정보</span>
          </h3>
          <div className="grui-results-table">
            <div className="grui-rtable-body">
              <section>
                <div className="rtable-col01">
                  <strong>검사명:</strong>
                  {props.reportType === "DA" ? (
                    <span>오브미365-62종</span>
                  ) : props.reportType === "DB" ? (
                    <span>오브미365-58종</span>
                  ) : null}
                </div>
                <div className="rtable-col02">
                  <strong>검사기관:</strong>
                  <span>디엑스앤브이엑스 (주)</span>
                </div>
                <div className="rtable-col03">
                  <strong>전화번호:</strong>
                  <span>02-6964-8741</span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>주소:</strong>
                  <span>
                    서울시 금천구 디지털로 173, 10층 1003호 (가산동, 엘리시아)
                  </span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>신고 번호:</strong>
                  <span>제 152호</span>
                </div>
                <div className="rtable-col02">
                  <strong>인증유효기간:</strong>
                  {props.reportType === "DA" ? (
                    <span>2024년 1월 1일 ~ 2024년 12월 31일</span>
                  ) : props.reportType === "DB" ? (
                    <span>2025년 1월 1일 ~ 2027년 12월 31일</span>
                  ) : null}
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="grui-results-sheet info-sign">
          <div className="grui-results-table">
            <div className="grui-rtable-head">
              <div className="rtable-col01">
                <span>검사 담당자 </span>
              </div>
              <div className="rtable-col02">
                <span>결과분석 책임자</span>
              </div>
              <div className="rtable-col03">
                <span>총괄 책임자</span>
              </div>
            </div>
            <div className="grui-rtable-body">
              <section>
                <div className="rtable-col01">
                  <div className="area">
                    <span className="text">최예진</span>
                    <img src="/assets/images/common/sign1.png" alt="" />
                  </div>
                </div>
                <div className="rtable-col02">
                  <div className="area">
                    <span className="text">강현웅</span>
                    <img src="/assets/images/common/sign2.png" alt="" />
                  </div>
                </div>
                <div className="rtable-col03">
                  <div className="area">
                    <span className="text">박상진</span>
                    <img src="/assets/images/common/sign3.png" alt="" />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="grui-results-sheet fixed info-responsible">
          <div className="grui-results-table">
            <div className="grui-rtable-body">
              <section>
                <div className="rtable-col01">
                  <strong>결과지 작성 책임자:</strong>
                  <span>강현웅</span>
                </div>
                <div className="rtable-col02">
                  <strong>결과지 상담자:</strong>
                  <span>백규흠</span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>전화번호:</strong>
                  <span>02-6964-8741</span>
                </div>
                <div className="rtable-col02">
                  <strong>이메일:</strong>
                  <span>dtc@dxvx.com</span>
                </div>
              </section>
              <section>
                <div className="rtable-col01">
                  <strong>작성일:</strong>
                  <span>
                    {reportInfo?.report_date
                      ? moment(reportInfo?.report_date).format("YYYY / MM / DD")
                      : "-"}
                  </span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType11Page;
