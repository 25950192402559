import React from "react";

const MarketDescriptionPage = (props) => {
  const itemDetail = props.itemDetail;

  function createMarkup() {
    console.log(itemDetail?.item_contents);

    return { __html: itemDetail?.item_contents };
  }

  return (
    <section className="item-container">
      <div className="item-detail">
        <div className="thumbnail">
          {
            <img
              src={
                itemDetail?.thumbnail[0]?.pathName
                  ? `/static/thumbnail/${itemDetail?.thumbnail[0]?.pathName}`
                  : "../assets/images/default.png"
              }
              alt=""
            />
          }
        </div>
        <div className="text-area">
          <div className="name">{itemDetail?.item_name}</div>
          <div className="description">
            <pre>{itemDetail?.item_description}</pre>
          </div>
          <div className="price">
            <div className="tx1">총 상품 금액</div>
            <div className="tx2">
              {Number(itemDetail?.item_price).toLocaleString()}원
            </div>
          </div>
          <div className="btn" onClick={(e) => props.paymentHandler(e, true)}>
            <div className="img"></div>
            <span>구매하기</span>
          </div>
        </div>
      </div>
      {itemDetail?.item_contents === null ? (
        <div className="item-desctiption">상품 상세내역 준비중 입니다.</div>
      ) : (
        <div
          className="item-desctiption"
          dangerouslySetInnerHTML={createMarkup()}
        ></div>
      )}
    </section>
  );
};

export default MarketDescriptionPage;
