import React from "react";
import { RequiredAuth } from "components/common/requiredAuth";
import { RequiredAdminAuth } from "components/common/requiredAdminAuth";

//로그인
import Login from "pages/login/login";
import AgreePage from "pages/login/agreePage";
import FindIdPage from "pages/login/findIdPage";

//마이페이지
import UserPage from "pages/user/userPage";

//관리자
import AdminPage from "pages/admin/adminPage";
import ReportControl from "pages/admin/reportControl";

//메인화면 리포트리스트
import ReportList from "pages/user/reportList";
import AddMyReport from "pages/user/addMyReport";
import UpdateMyPassword from "pages/user/updateMyPassword";

//리포트
import ReportPage from "pages/report/reportPage";
import ReportNewPage from "pages/report/reportNewPage";

//동의서
import AgreeDocumentPage from "pages/agree/agreeDocumentPage";

//메인
import MainPage from "pages/mainPage";

//상품설명
import ProductPage from "pages/productPage";

//고객센터
import CustomerCenterPage from "pages/customer/customerCenterPage";
import FaqPage from "pages/customer/faqPage";
import InquiryPage from "pages/customer/inquiryPage";

//개인정보처리방침
import PrivacyPolicyPage from "pages/privacyPolicyPage";

//이용약관
import TermsOfServicePage from "pages/termsOfServicePage";

//마켓
import MarketListPage from "pages/market/marketListPage";
import MarketDetailPage from "pages/market/marketDetailPage";

const router = [
  /* 메인페이지 */
  {
    path: "/",
    element: <MainPage />,
  },
  /* 상품설명 */
  {
    path: "/product",
    element: <ProductPage />,
  },
  /* 고객센터 */
  {
    path: "/customer",
    element: <CustomerCenterPage />,
  },
  /* faq */
  {
    path: "/faq",
    element: <FaqPage />,
  },
  /* 1:1문의 */
  {
    path: "/inquiry",
    element: <InquiryPage />,
  },
  /* 결과확인 */
  {
    path: "/mypage",
    element: (
      <RequiredAuth>
        <AddMyReport />
      </RequiredAuth>
    ),
  },
  {
    path: "/updatepw",
    element: (
      <RequiredAuth>
        <UpdateMyPassword />
      </RequiredAuth>
    ),
  },
  /* 메인페이지 */
  {
    path: "/myreport",
    element: (
      <RequiredAuth>
        <ReportList />
      </RequiredAuth>
    ),
  },
  /* 관리자페이지 */
  {
    path: "/admin",
    element: (
      <RequiredAdminAuth>
        <AdminPage />
      </RequiredAdminAuth>
    ),
  },
  {
    path: "/reportcontrol/:userID",
    element: (
      <RequiredAuth>
        <ReportControl />
      </RequiredAuth>
    ),
  },
  {
    path: "/reportpage",
    element: (
      <RequiredAuth>
        <ReportPage />
      </RequiredAuth>
    ),
  },
  {
    path: "/newreportpage",
    element: (
      <RequiredAuth>
        <ReportNewPage />
      </RequiredAuth>
    ),
  },
  {
    path: "/agreepage",
    element: (
      <RequiredAuth>
        <AgreeDocumentPage />
      </RequiredAuth>
    ),
  },
  {
    path: "/market",
    element: <MarketListPage />,
  },
  {
    path: "/detail/:postId",
    element: <MarketDetailPage />,
  },

  /* 유저 - 로그인 등 */
  { path: "/login", element: <Login /> },
  { path: "/signUp", element: <AgreePage /> },
  { path: "/findId", element: <FindIdPage /> },
  {
    path: "/myinfo",
    element: (
      <RequiredAuth>
        <UserPage />
      </RequiredAuth>
    ),
  },
  { path: "/checkplus_success", element: <Login /> },
  { path: "/checkplus_fail", element: <Login /> },

  /* 개인정보처리방침 */
  { path: "/policy", element: <PrivacyPolicyPage /> },
  /* 이용약관 */
  { path: "/tos", element: <TermsOfServicePage /> },
];

export default router;
