import Quill from "quill";
import ImageResize from "quill-image-resize";

if (!Quill.imports["modules/ImageResize"]) {
  Quill.register("modules/ImageResize", ImageResize);
}

const toolbarOptions = [
  ["link", "image"],
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
];

const fontSize = ["14px", "16px", "18px", "24px", "28px", "32px"];

// 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "background",
  "color",
  "link",
  "image",
  "width",
];

export { fontSize, formats, toolbarOptions };
