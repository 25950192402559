import React, { useEffect, useState, useRef, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import { formats, toolbarOptions } from "helpers/quill/quillOptions";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import imageHandler from "helpers/quill/imageHandler";
import "react-quill/dist/quill.snow.css";

const AdminAddItemPage = (props) => {
  const [quillValue, setQuillValue] = useState("");
  const [itemStatus, setItemStatus] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { apiUrls, axiosFetchForm } = useAxiosFunction();
  const quillRef = useRef();

  const {
    control,
    register,
    handleSubmit,
    /* watch,     */
    formState: { isSubmitting, errors: formErrors },
    reset,
  } = useForm({ mode: "onBlur" });

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: () => imageHandler(quillRef, axiosFetchForm, apiUrls),
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, []);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length + uploadedFiles.length > 5) {
      alert("첨부파일의 최대 갯수는 5개입니다.");
      return;
    }
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
    }));
    setUploadedFiles([...uploadedFiles, ...newFiles]); // 배열로 설정
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: false, // 클릭하여 파일 다이얼로그 열기 허용
  });

  const handleRemoveFile = (indexToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const setWriteClear = () => {
    reset();
    setQuillValue("");
    setUploadedFiles([]);
  };

  const writeBoard = (reqData) => {
    if (quillValue === undefined || quillValue === "") {
      alert("내용을 입력해주세요.");
      return false;
    }

    reqData.content = quillValue ? quillValue : "";
    reqData.item_status = itemStatus?.value;

    // 파일 정보를 FormData에 추가
    const formData = new FormData();
    formData.append("item_name", reqData.item_name);
    formData.append("item_description", reqData.item_description);
    formData.append("item_price", reqData.item_price);
    formData.append("item_status", reqData.item_status);
    formData.append("content", reqData.content);

    // 업로드된 파일 추가
    uploadedFiles?.forEach((file) => {
      formData.append("files", file.file);
    });

    axiosFetchForm({
      method: "post",
      url: apiUrls.addItem,
      formData: formData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          setWriteClear();
          props.setIsItemAdd(false);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        // console.log("[ERROR] writeBoard: ", err);
      });
  };

  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: "58px !important",
    }),
  };

  useEffect(() => {
    // console.log("AdminAddItemPage > ", props);
  }, []);

  return (
    <div className="board-write">
      <form className="input-area">
        <div className="admin-dialog">
          <div className="admin-dialog-box contract">
            <div className="admin-dialog-area" style={{ padding: "0" }}>
              <div className="dialog-content">
                <div className="admin-dialog-item">
                  <h3 className="adi-title">상품명</h3>
                  <div className="adi-content">
                    <InputUnit
                      style={{
                        display: "inline-block",
                      }}
                      noLable="true"
                      label="상품명"
                      holder="상품명을 입력해주세요"
                      register={register("item_name")}
                      clsName="item_name"
                      errors={formErrors}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h3 className="adi-title">상품설명</h3>
                  <div className="adi-content">
                    <Controller
                      name="item_description"
                      control={control}
                      defaultValue=""
                      label="상품설명"
                      render={({ field }) => (
                        <textarea
                          style={{ height: "auto" }}
                          id="message"
                          name="message"
                          rows="10"
                          required
                          resize="none"
                          {...field}
                        />
                      )}
                    />

                    {/* <InputUnit
                      style={{
                        display: "inline-block",
                      }}
                      noLable="true"
                      label="상품설명"
                      holder="상품설명을 입력해주세요"
                      register={register("item_description")}
                      clsName="item_description"
                      errors={formErrors}
                    /> */}
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h3 className="adi-title">상품가격</h3>
                  <div className="adi-content">
                    <InputUnit
                      style={{
                        display: "inline-block",
                        width: "235px",
                      }}
                      noLable="true"
                      label="상품가격"
                      holder="상품가격을 입력해주세요"
                      register={register("item_price", {
                        required: "상품 가격은 필수 입력 값입니다.",
                        min: {
                          value: 1,
                          message: "상품 가격은 0보다 커야합니다.",
                        },
                      })}
                      clsName="item_price"
                      errors={formErrors}
                      type="number"
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h3 className="adi-title">판매상태</h3>
                  <div className="adi-content">
                    <Select
                      className="admin-select adi-company-category"
                      options={[
                        { value: 0, label: "판매가능" },
                        { value: 1, label: "판매불가" },
                      ]}
                      style={customStyles}
                      value={itemStatus}
                      onChange={(selectedOption) =>
                        setItemStatus(selectedOption)
                      }
                    ></Select>
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h3 className="adi-title">썸네일</h3>
                  <div className="adi-content">
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => onDrop(e.target.files)}
                      />
                      {isDragActive ? (
                        <p>파일을 여기에 놓아주세요!</p>
                      ) : (
                        <>
                          <p>
                            클릭하거나 썸네일 이미지 파일을 끌어다 놓아주세요.
                          </p>
                        </>
                      )}
                    </div>
                    <div className="uploaded-files">
                      {uploadedFiles?.length > 0 && (
                        <ul>
                          {uploadedFiles?.map((uploadedFile, index) => (
                            <li key={index}>
                              {uploadedFile.name}
                              <button onClick={() => handleRemoveFile(index)}>
                                삭제
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <ReactQuill
                  className="writeContent"
                  placeholder="상품 설명을 입력해주세요."
                  theme="snow"
                  value={quillValue}
                  onChange={setQuillValue}
                  required="상품 설명은 필수 입력 값 입니다."
                  modules={modules}
                  formats={formats}
                  ref={quillRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="board-btn-area">
          <button
            type="submit"
            className="write-btn"
            onClick={handleSubmit(writeBoard)}
            disabled={isSubmitting}
          >
            등록
          </button>
          <button
            type="submit"
            className="write-btn"
            onClick={(e) => props.setIsItemAdd(false)}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminAddItemPage;
