import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import router from "./route/router";
import Header from "components/common/header";
import Footer from "components/common/footer";
import ScrollToTop from "api/scrollToTop";
import useLogoutTimer from "hooks/useLogoutTimer";
import "assets/css/style.css";
import "assets/css/sv/reset.css";
import "assets/css/sv/include.css";
import "assets/css/sv/mo_include.css";
import "assets/css/sv/main.css";
import "assets/css/sv/mo_main.css";
import "assets/css/sv/sub.css";
import "assets/css/sv/mo_sub.css";

const Routing = () => {
  let routes = useRoutes(router);
  return routes;
};

const App = () => {
  useLogoutTimer();

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Header />
        <Routing />
        <Footer />
      </Router>
    </>
  );
};

export default App;
