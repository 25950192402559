import React from "react";
import "assets/css/report.css";

const ReportDDType2Page = (props) => {
  return (
    <div className="grui-report">
      <div className="grui-page page2">
        <h2 className="grui-title border">유전자 검사 이해하기</h2>
        <p className="grui-standfirst">
          나의 결과를 정확하게 이해하도록 결과 확인 전 꼭 읽어주세요!
        </p>
        <section>
          {/* <h3 className="grui-title size2">1. 용어 도움말</h3> */}
          <ul className="grui-icon-list">
            <li>
              <div className="grui-il-item">
                <div className="grui-il-text">
                  <h4>검사 소개</h4>
                  <p>
                    이 검사는 유전자형 분석 기술을 사용하여 개인의 건강과 특성에
                    관련된 정보를 제공합니다. 이를 위해 게놈 내에서 알려진 특정
                    유전적 변이체를 감지하는 SNP Microarray 방법을 사용합니다.
                    모든 사람의 DNA는 기본적으로 동일하지만 개인에 따라 1%
                    정도의 미세한 차이가 있습니다. 이러한 차이를
                    단일염기다형성(Single Nucleotide Polymorphism, SNP)이라고
                    합니다. SNP는 일반적으로 DNA 염기 서열 1000개당 1개의 변이가
                    나타나며, 이러한 SNP는 사람마다 외모, 성격 등의 유전적
                    다양성을 나타냅니다.
                    <br />
                    <br /> 이 검사는 소비자들에게 쉽게 전달하기 위해, 개인의
                    유전 정보를 분석하여 건강과 특성과 관련된 유전적 변이를
                    알려줍니다. 이를 통해 개인은 자신의 유전적 다양성을
                    이해하고, 건강 관리 및 라이프스타일 선택에 도움을 받을 수
                    있습니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grui-il-item">
                <div className="grui-il-text">
                  <h4>사용 목적과 한계</h4>
                  <p>
                    본 유전자 검사는 당신의 유전자변이 결과를 컴퓨터로 분석하여
                    ‘당신과 같은 유전자형을 가진 그룹은 특정 경향을 보인다’등의
                    결과를 알려줍니다. <br />
                    <br />
                    그러면 유전자검사에서 ‘퇴행성관절염증감수성’이 ‘주의’로
                    나온다면 그것은 어떤 의미일까요? 그 의미는 예방을 목적으로
                    ‘당신과 같은 유전형을 가진 그룹은 퇴행성 관절염 감수성이
                    높은 경향성이 있으니 예방하기 위해서는 주의하여야 한다’ 라는
                    의미입니다. <br />
                    <br />
                    1) 이것은 많은 대상자를 연구하여 분석한 통계적인 수치이고 2)
                    각 개인에게 구체적 의미보다는 퇴행성관절염증감수성 정도 등의
                    여러 원인이 되는 가능성 중에 유전적 가능성을 제시 해주는
                    것이라고 할 수 있습니다. 추가 연구를 통하여 그 수치는 바뀔
                    수도 있습니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grui-il-item">
                <div className="grui-il-text">
                  <h4>동일 유전자형에서 상대적 형질 발생 가능 여부</h4>
                  <p>
                    유전학에서 표현도는 특정 유전적 변이가 개체에서 얼마나
                    강하게 나타나는지를 나타내는 용어입니다. 간단히 말해 유전적
                    변이의 형질 발현 정도를 설명하는 개념입니다. 표현도의 차이는
                    다른 유전자 변이 상호작용 또는 환경적 요인으로 인해 발생할
                    수 있습니다.
                    <br />
                    <br />
                    유전체는 여러 유전자의 조합으로 이루어져 있으며, 이들 간의
                    복잡한 상호작용은 형질의 발현을 결정하는 데 영향을 미칠 수
                    있습니다. 따라서, 동일한 유전적 변이를 가지더라도 다른
                    유전자 변이의 존재로 인해 표현도가 달라질 수 있습니다. 환경
                    요인은 형질의 발현에 큰 영향을 줄 수 있습니다. 동일한 유전적
                    변이를 가진 개체라도 서로 다른 환경에서 자라거나 서로 다른
                    생활 조건을 경험한다면, 형질의 발현 정도에 차이가 생길 수
                    있습니다. 예를 들어, 특정 유전적 변이가 암 발생의 위험을
                    증가시키는 경우라도, 개인의 식습관, 생활습관, 노출된 환경
                    또는 스트레스 수준 등의 요소에 따라 실제 암 발병 여부와 발생
                    시기가 다를 수 있습니다.
                    <br />
                    <br />
                    표현도의 정확한 원인은 많은 연구와 조사가 필요한 복잡한
                    문제이며, 개별적인 경우에 따라 다를 수 있습니다. 이러한
                    이유로, 유전검사 결과는 해당 형질이 발생할 가능성을
                    통계적으로 가늠할 뿐이기 때문에 동일 유전자형에서 상대적
                    형질 발생 가능성도 존재합니다.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="grui-il-item">
                <div className="grui-il-text">
                  <h4>부/모계 유전 여부</h4>
                  <p>
                    본 유전자 검사를 통해 부/모계 유전 여부를 확인하는 것은
                    불가능합니다. 본 유전자 검사는 개인의 유전체 정보를 분석하여
                    개인의 유전적 특성을 파악하는 것이 주 목적으로 가족 간의
                    유전적 관계를 확인할 수 없습니다. 부/모계 유전 여부를
                    확인하려면, 가족 구성원들의 유전자 정보를 분석하여 가족
                    관계를 추론하는 유전학적 검사를 실시해야 합니다.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDDType2Page;
