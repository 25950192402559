import React from "react";
import "assets/css/report.css";

const ReportDAType16Page = (props) => {
  const solutionReferenceInfo = props.solutionReferenceInfo;

  return (
    <div className="grui-report">
      <div className="grui-page page10">
        <h2 className="grui-title border" style={{ marginBottom: "50px" }}>
          솔루션 참고문헌
        </h2>
        <div className="grui-results-sheet">
          <div className="grui-results-table">
            <div className="grui-rtable-head">
              <div className="rtable-col09">
                <span>검사항목명</span>
              </div>
              <div className="rtable-col10">
                <span>근거문헌</span>
              </div>
            </div>
            <div className="grui-rtable-body">
              <section>
                <div className="rtable-col09">
                  <h4>{solutionReferenceInfo?.name}</h4>
                </div>
                <div>
                  {solutionReferenceInfo?.ref?.map((item) => {
                    return (
                      <article>
                        <div className="rtable-col10">
                          {solutionReferenceInfo?.name === "영양소" ||
                          solutionReferenceInfo?.name === "건강관리" ? (
                            <p style={{ padding: "7px", lineHeight: "0.5em" }}>
                              {item}
                            </p>
                          ) : (
                            <p style={{ padding: "7px", lineHeight: "1.1em" }}>
                              {item}
                            </p>
                          )}
                        </div>
                      </article>
                    );
                  })}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="page-num">{props.pageNum}</div>
    </div>
  );
};

export default ReportDAType16Page;
