import React from "react";
import "assets/css/report.css";

const Element = (props) => {
  return (
    <div className={props.className}>
      <div className="tx">
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export { Element };
