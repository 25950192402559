import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";

const SilentRefresh = ({ children }) => {
  const { urls, axiosFetch } = useAxiosFunction();
  const setUserInfo = useSetRecoilState(userInfoState);
  //const rToken = localStorage.getItem("rToken");
  const rToken = sessionStorage.getItem("rToken");

  useEffect(() => {
    if (rToken != null) {
      const reqData = {
        refresh_token: rToken,
      };

      axiosFetch({
        method: "POST",
        url: urls.rTokenCheck,
        requestConfig: reqData,
      })
        .then((res) => {
          setUserInfo({
            isLogin: true,
            aToken: res.access_token,
            user_id: res.user_id,
            user_name: res.user_name,
            user_type: res.user_type,
            user_birth: res.user_birth,
            user_phone: res.user_phone,
          });
        })
        .catch((err) => {
          setUserInfo({
            isLogin: false,
            aToken: null,
            user_id: null,
            user_name: null,
            user_type: null,
            user_birth: null,
            user_phone: null,
          });
        });
    } else {
      setUserInfo({
        isLogin: false,
        aToken: null,
        user_id: null,
        user_name: null,
        user_type: null,
        user_birth: null,
        user_phone: null,
      });
    }
  }, []);

  return children;
};

export { SilentRefresh };
