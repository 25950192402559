import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import ReportDTypePage from "./reportDTypePage";
import { useLocation, useNavigate } from "react-router-dom";

const ReportPage = () => {
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const reportInfo = location.state?.reportInfo;
  const navigate = useNavigate();

  const getReportData = () => {
    setLoading(false);
    axiosFetchA({
      method: "post",
      url: apiUrls.getReportData,
      requestConfig: {
        kit_number: reportInfo.kit_number,
        confirm_code: reportInfo.confirm_code,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
      },
    })
      .then((res) => {
        if (res.success) {
          setReportData(res.data);
          setLoading(true);
        } else {
          alert(res.message);
          navigate(-1);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    if (reportInfo?.confirm_code && reportInfo?.kit_number) {
      getReportData();
    }
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <div className="container">
            <div className="content-box5">
              <img src="/assets/images/common/genomecheck.png" alt="" />
              <div className="main-title">
                <span>Loding...</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-box4">
              <div className="sub-title">
                <span>
                  리포트데이터 로딩중...이 화면이 지속되면 관리자에게
                  문의해주세요.
                </span>
              </div>
            </div>
          </div>
        </>
      ) : reportData?.customer_type === "D" ? (
        <ReportDTypePage reportData={reportData} />
      ) : (
        <>
          <div className="container">
            <div className="content-box5">
              <img src="/assets/images/common/genomecheck.png" alt="" />
              <div className="main-title">
                <span>리포트데이터없음</span>
                <div className="rt" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="content-box4">
              <div className="sub-title">
                <span>리포트데이터가 없습니다. 관리자에게 문의해주세요.</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReportPage;
